.SystemMaintenanceSpecialFunctions-Dashboard,.ContractManagement-Dashboard {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxDashboard{
    .card{
      border: none;
      background-color:  #c0bcbc;
      .card-header{
        display: none;
      }
      .grpbxPreview{
        min-width: 100%;
        margin-top: 0.25rem;
        .card{
          background-color: white;
          // min-height: 800px;
          // max-height: 800px;
          min-height: 80vh;
          max-height: 80vh;
          .row{
            height: 79vh; 
          }         
          #docFrame{
            height: 100%;
          }
        }
      }
      .cmmndCntnrActions{
        min-width: 100%;
        .row{
          padding-top: 0.25rem;
          justify-content: center;
          background-color: #f3f0f0;
          .btnPrint,.btnClose{
            min-width: 10%;
            max-width: 10%;
            .row{
              justify-content: center;
              button{
                width: 90%;
              }
            }
          }
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
