.SystemMaintenanceMasterManagement-FarmProfile,
.ContractManagement-FarmProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #f3f0f0;
  background: transparent;
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
  .close {
    display: none;
  }
  .card {
    background-color: #c0bcbc;
    border: none;
  }
  .grpbxFarmProfile {
    label {
      width: 104px;
      text-align: right;
    }
  }
  .grpbxFarmDetails1 {
    .card{
      padding-top: 0.5rem;
    }
    label {
      width: 103px;
    }
    .card {
      border: none;
    }
    .ddBuyngPt,.ddState,.ddCountry,.txtFrmId,.txtFrmSffx,.txtFrmNm {
      color: red;
    }
    .ddBuyngPt,.txtFrmNm{
      max-width: 80%;
      margin-right: 15%;
    }
  }
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
  }

  .grpbxFarmDetails2  {
    .card {
      border: none;
    }
    label {
      width: 103px;
    }
    
  }
  .txtSortNm{
    max-width: 80%;
    margin-right: 15%;
  }
  .txtIsByng {
    max-width: 20%;
  }
  .ddState,
  .ddCountry {
    max-width: 50%;
    margin-right: 45%;
  }
  .txtIsState,
  .txtIsCntry {
    .d-flex {
      max-width: 30%;
    }
  }
  .txtFrmId,
  .txtFrmSffx {
    max-width: 24%;
  }

  .txtAddress {
    max-width: 67%;
  }
  .txtZip {
    input {
      max-width: 16%;
    }
  }
  .txtPhn,
  .txtCity {
    input {
      max-width: 20%;
    }
  }
  .txtAcres {
    .d-flex {
      max-width: 25%;
    }
  }
  .ddIrrigate {
    max-width: 50%;
    margin-right: 45%;
  }
  .txtIstLndTyp,
  .txtIstVndr,
  .txtIstSplit {
    max-width: 20%;
  }
  .grpbxVendors {
    .btnDown {
      max-width: 5%;
      button{
        width: 95%;
      }
    }
    .btnUp {
      max-width: 5%;
      button{
        width: 95%;
      }
    }
    .btnSaveOrder {
      max-width: 10%;
      margin-left: 18%;
    }
    .whtspc09 {
      min-width: 39%;
    }
    .btnDelete,
    .btnEdit,
    .btnAdd {
      max-width: 7.5%;
      button{
        width: 95%;
      }
    }
    .btnAdd{
      margin-left: auto;
    }
    .btnDelete{
      margin-right: 1%;
    }
    .lblVendors{
      max-width: 132px;
      label{
        text-align: left;
      }
    }
    .lstbxVendors{
      padding: 0;
    }
  }
.grpbxActions{
  .card{

    background-color: #f3f0f0;
  }
  .lblAddedBy,.lblChangedBy{
    max-width: 8%;
    padding-right: 0;
  }
  .btnOk {
    max-width: 8%;
    margin-left: 50%;
  }
  .txtChangedBy{
    input{
      display: block;
    }
  }
  .btnClose {
    max-width: 8%;
  }
} 
  /*END_USER_CODE_FOR_.SCSS*/
}
// Hidding cross btn as of now Impliment after phase 2 GO Live.
#SystemMaintenanceMasterManagement_FarmProfilePopUp {
  .close{
    display: none;
  }
}
