.SystemMaintenanceMasterManagement-BuyingPointProfile,.ContractManagement-BuyingPointProfile{
  /*START_USER_CODE_FOR_.SCSS*/  h1{
   display:none
 }
//  background-color: #333E48;
 background: transparent;
 .grpbxBuyingPointProfile {
   .dropdown-menu.show {
     overflow-y: scroll !important;
     max-height: 25vh !important;
     }
   .card{
     padding-top: 0.5%;
     background-color: #c0bcbc;
     border: none;
     .card-header{
       display:none
     }
     .grpbxCompanyArea {
       max-width: 50%;
       .lblBegCheck ,.lblNext1007 ,.lblBeg1007 ,.lblNextCheck ,.lblEndCheck,
       .lblNextWhouseCntl ,.lblBeg11007 ,.lblEnd1007 ,.lblEnd11007 {
         display: none !important;
       }

       .lblCompany ,.lblArea{
         max-width: 100px;
         min-width: 100px;
         label{
           width: 100%;
           text-align: right;
         }
       }
       .lblCompanyValue ,.lblAreaValue{
         min-width: 70%;
         max-width: 70%;
         padding-left: 0;
         color: blue;
         label{
           display: none;
         }
         .col{
          position: relative;
          bottom: 7px;
          right: 23px;
       }
     }
    }
     .lblLocationRecordIsInactive {
       label {
         font-weight: bold;
         font-size: 38px;
       }
       max-height: 40px;
       font-size: 210%;
    visibility: visible;
    font-weight: bolder;
    color: #c02020;
    label{
      position: relative;
      top: 22px;
      left: 2%;
    }
     }
     .grpbxFTPAdmin {
       max-width: 40%;
       min-width: 40%;
       padding-right: 0;
       .card{
         border: 1px solid #f3f0f0;
         height: 100%;
         .lblFTPAdmin{
           min-width: 100%;
           label{
             font-weight: bold;
             margin-bottom: 0 !important;
           }
         }
         .radioLogging {
           min-width: 35%;
           max-width: 35%;
           .form-check{
             input{
               bottom: 2px;
             }
           }
         }
         .txtTimerInterval,.txtRetries {
           .d-flex{
             display: block !important;
           }
           label{
             margin-bottom: 0 !important;
             width: 100%;
             text-align: center;
           }
           .col{
             padding: 0;
           }
         }
         .txtTimerInterval{
           min-width: 40%;
           max-width: 40%;
         }
         .txtRetries{
           min-width: 25%;
           max-width: 25%;
         }
         .radioFTPMethod{
           min-width: 100%;
           .mb-3{
             display: flex;
             position: relative;
             top: 5px;
             .form-check{
               min-width: 25%;
               input{
                 bottom: 2px;
               }
             }
           }
         }
       }
     }
     .grpbxCharacteristic {
       max-width: 60%;
       min-width: 60%;
       .card{
         border: 1px solid #f3f0f0;
         .lblCharacteristics {
           min-width: 100%;
           label{
             font-weight: bold;
             margin-bottom: 0 !important;
             color: #c02020;
           }
         }
         .chkbocCharacteristics {
           min-width: 100%;
           max-height: 95px;
           .mb-3{
             display: flex !important;
             flex-wrap: wrap;
             .form-check:nth-child(1),
             .form-check:nth-child(4),
             .form-check:nth-child(5),
             .form-check:nth-child(8){
               min-width: 30%;
               max-width: 30%;
             }
             .form-check:nth-child(2),
             .form-check:nth-child(3),
             .form-check:nth-child(6),
             .form-check:nth-child(7){
               min-width: 20%;
               max-width: 20%;
             }
             
             .form-check:nth-child(9),
             .form-check:nth-child(10),
             .form-check:nth-child(11),
             .form-check:nth-child(8){
               min-width: 50%;
               max-width: 50%;
             }
             input{
               bottom: 2px;
             }
           }
         }
       }
     }
     .grpbxBuyingPoint {
       max-width: 45%;
       min-width: 45%;
       .card{
         border: 1px solid #f3f0f0;
         label{
           width: 70px;
           text-align: right;
         }
         .lblBuyingPoint {
           min-width: 100%;
           label{
             width: 100%;
             font-weight: bold;
             margin-bottom: 0 !important;
             text-align: left;
           }
         }
         .txtProfitCenter ,.ddCollectionPt {
           label{
             color: #c02020;
           }
         }
       }
     }
     .grpbxContactInfo {
       max-width: 30%;
       min-width: 30%;
       padding-left: 0;
       .card{
         border: 1px solid #f3f0f0;
         height: 100%;
         .lblContactInfo {
           min-width: 100%;
           label{
             width: 100%;
             font-weight: bold;
             margin-bottom: 0 !important;
             text-align: left;
           }
         }
         .textboxwidget-container{
           min-width: 100%;
           padding: 0;
           label{
             width: 70px;
             text-align: right;
           }
         }
       }
     }
     .grpbxGovernmentIssued {
       max-width: 25%;
       min-width: 25%;
       padding-left: 0;
       .card{
         border: 1px solid #f3f0f0;
         height: 100%;
         .lblGovernmentIssued {
           min-width: 100%;
           label{
             width: 100%;
             font-weight: bold;
             margin-bottom: 0 !important;
             text-align: left;
           }
         }
         .textboxwidget-container,.dropdownfieldwidget-container{
           min-width: 100%;
           padding: 0;
           label{
             width: 80px;
             text-align: right;
           }
         }
         .txtFedBuyPt{
           label{
            color: #c02020;
           }
         }
       }
     }
     .grpbxCntnr1{
       max-width: 50%;
       padding: 0;
       .card{
         .grpbxPhysicalAddress {
           min-width: 100%;
           padding-left: 0.6rem;
           padding-right: 0.1rem;
           .card{
             border: 1px solid #f3f0f0;
             .lblPhysicalAddress  {
               min-width: 100%;
               label{
                 width: 100%;
                 font-weight: bold;
                 margin-bottom: 0 !important;
                 text-align: left;
               }
             }
             .textboxwidget-container,.dropdownfieldwidget-container{
               padding: 0;
               label{
                 width: 85px;
                 text-align: right;
               }
             }
             .txtAddressPA{
               min-width: 80%;
               max-width: 80%;
             }
             .txtCityPA,.ddStatePA{
               min-width: 60%;
               max-width: 60%;
               margin-right: 40%;
             }
             .ddCountyPA{
               min-width: 60%;
               max-width: 60%;
             }
             .txtZipPA{
               min-width: 35%;
               label{
                 width: 50px;
               }
             }
           }
         }
         .grpbxMailingAddress {
           min-width: 100%;
           padding-left: 0.6rem;
           padding-right: 0.1rem;
           .card{
             border: 1px solid #f3f0f0;
             .lblMailingAddress  {
               min-width: 100%;
               label{
                 width: 100%;
                 font-weight: bold;
                 margin-bottom: 0 !important;
                 text-align: left;
               }
             }
             .textboxwidget-container,.dropdownfieldwidget-container{
               padding: 0;
               label{
                 width: 85px;
                 text-align: right;
               }
             }
             .txtAddressMA{
               min-width: 80%;
               max-width: 80%;
             }
             .txtCityMA{
               min-width: 60%;
               max-width: 60%;
               margin-right: 40%;
             }
             .ddStateMA{
               min-width: 60%;
               max-width: 60%;
             }
             .txtZipMA{
               max-width: 35%;
               label{
                 width: 50px;
               }
             }
           }
         }
         .grpbxCuredCleanVendor {
           min-width: 100%;
           padding-left: 0.6rem;
           padding-right: 0.1rem;
           .card{
             border: 1px solid #f3f0f0;
             .lblCuredCleanVendor {
               min-width: 100%;
                 label{
                   width: 100%;
                   font-weight: bold;
                   margin-bottom: 0 !important;
                   text-align: left;
                 }
             }
             .txtVendorCCV {
               min-width: 33%;
               max-width: 33%;
               padding: 0;
               label{
                 width: 85px;
                 text-align: right;
               }
             }
             .btnVendorCCV {
               max-width: 5%;
               margin-bottom: 0.3rem !important;
             }
             .txtVendorNameCCV{
               padding: 0;
               max-width: 60%;
               input{
                 box-shadow: none;
                 border: none;
                 color: blue;
                 background-color: #c0bcbc;
               }
             }
             .ddRemitToCCV {
               min-width: 100%;
               padding: 0;
               label{
                 width: 85px;
                 text-align: right;
               }
             }
           }
         }
         .grpbxDefaultCMAVendor {
           min-width: 100%;
           padding-left: 0.6rem;
           padding-right: 0.1rem;
           .card{
             border: 1px solid #f3f0f0;
             .lblDefaultCMAVendor {
               min-width: 100%;
                 label{
                   width: 100%;
                   font-weight: bold;
                   margin-bottom: 0 !important;
                   text-align: left;
                 }
             }
             .txtVendorDCV {
               min-width: 33%;
               max-width: 33%;
               padding: 0;
               label{
                 width: 85px;
                 text-align: right;
               }
             }
             .btnVendorDCV {
               margin-bottom: 0.3rem !important;
               max-width: 5%;
             }
             .txtVndr{
               padding: 0;
               max-width: 60%;
               input{
                 box-shadow: none;
                 border: none;
                 color: blue;
                 background-color: #c0bcbc;
               }
             }
             .ddRemitToDCV {
               min-width: 100%;
               padding: 0;
               label{
                 width: 85px;
                 text-align: right;
               }
             }
           }
         }
       }
     }
     .grpbxCntnr2{
       max-width: 50%;
       padding: 0;
       .card{
         .grpbxControlNumbers {
           min-width: 100%;
           padding-right: 0.6rem;
           padding-left: 0.1rem;
           .card{
             border: 1px solid #f3f0f0;
             .lblControlNumbers {
               min-width: 100%;
               label{
                 width: 100%;
                 font-weight: bold;
                 margin-bottom: 0 !important;
                 text-align: left;
               }
             }
             .lbl1007 {
               max-width: 20%;
               padding-right: 0;
               label{
                 width: 100%;
                 text-align: right;
               }
             }
             .lblAdditional1007 {
               max-width: 30%;
               min-width: 30%;
               padding-right: 0;
               label{
                 width: 100%;
                 text-align: right;
               }
             }
             .chkboxAdditional1007 {
               max-width: 15%;
               min-width: 15%;
               .col{
                 bottom: 5px;
                 padding: 0;
               }
             }
             .txtEnd,.txtBeg,.txtEnd1,.txtBeg1,.txtNext,.txtBegCheck ,.txtEndCheck {
               max-width:25%;
               min-width: 25%;
               padding-right: 0;
               .d-flex{
                 display: block !important;
                 .col{
                   padding: 0;
                 }
                 label{
                   width: 100%;
                   text-align: center;
                   margin-bottom: 0;
                 }
               }
             }
             .txtWhouse ,.txtCheck {
               max-width: 45%;
               min-width: 45%;
               padding-right: 0;
               .col{
                 padding-right: 0;
               }
               label{
                 width: 43.5%;
                 text-align: right;
               }
             }
             .chkboxUseAutomaticTransfer {
               padding: 0;
               .col{
                 bottom: 5px;
                 .mb-3{
                   input{
                     bottom: 2px;
                   }
                 }
               }
             }
           }
         }
         .grpbxMiscellaneuos {
           min-width: 100%;
           padding-right: 0.6rem;
           padding-left: 0.1rem;
           .card{
             border: 1px solid #f3f0f0;
             .lblMiscellaneous {
               min-width: 100%;
               label{
                 width: 100%;
                 font-weight: bold;
                 margin-bottom: 0 !important;
                 text-align: left;
               }
             }
             .textboxwidget-container{
               padding: 0;
               min-width: 40%;
               max-width: 40%;
               label{
                 width: 100px;
                 text-align: right;
                 color: #c02020;
               }
             }
             .dropdownfieldwidget-container{
               padding: 0;
               min-width: 60%;
               max-width: 60%;
               label{
                 width: 140px;
                 text-align: right;
                 color: #c02020;
               }
             }
             .ddVicamLab {
               max-width: 40%;
               min-width: 40%;
               margin-right: 60%;
               label{
                 width: 100px;
               }
             }
             .ddInternetType ,.txtServiceProvider {
               max-width: 100%;
               min-width: 100%;
               margin-top: 0.75rem;
               label{
                 width: 100px;
                 color: #495057;
               }
             }
           }
         }
       }
     }
   }
 }

 .grpbxActions {
   min-width: 100%;
   padding-left: 1rem;
   padding-right: 1rem;
   .card{
     background-color: #f3f0f0;
     .card-header{
       display: none;
     }
       .lblAddedBy ,.lblChangedBy {
         min-width: 10%;
         max-width: 10%;
         label{
           width: 100%;
           text-align: right;
         }
       }
       .lblAddedByValue ,.lblChangedByValue {
         min-width: 20%;
         max-width: 20%;
         padding-left: 0;
         label{
           text-align: left;
           display: none;
          }
          .col{
          position: relative;
          bottom: 7px;
          right: 23px;
         }
       }
       .lblChangedBy,.lblChangedByValue{
         bottom: 10px;
       }
       .buttonwidget-container{
         min-width: 10%;
         max-width: 10%;
         top: 15px;
         button{
           width: 95%;
         }
       }
       .btn1 {
         display: none;
       }
       .btnPrintScreen{
         margin-left: 15%;
         margin-right: 10%;
       }
       .btnMakeInactive {
         margin-right: 5%;
       }
   }
 }
    .textboxWidgetClass{
      box-shadow: none !important;
     }
     .textboxWidgetClass:focus, .form-control:focus{
      box-shadow: none !important;
    }
    .dropdown-toggle{
      box-shadow: none !important
    }
    .buttonWidgetClass {
      box-shadow: none !important;
    }
  
 /*END_USER_CODE_FOR_.SCSS*/
}
