.Settlements-ReportPreviewInspection,.ContractManagement-ReportPreviewInspection {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxHeader{
    padding: 0 !important;
  }
  .grpbxReportPreviewInspection     {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #f3f0f0;
    }

    .buttonwidget-container{
      min-width: 10%;
      max-width: 10%;
      button{
        width: 90%;
      }
    }
    .btnPrintSetup{
      margin-left: 2%;
    }
    padding: 0;
    .btnSaveAsPDF{
      margin-left: auto;
    }
    .chkboxReprint {
      input{
        bottom: 2px;
      }
       
      
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
