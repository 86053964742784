
.ContractManagement-ExceptionPremiumSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
  .card {
    background-color: #f3f0f0;
  }
  .grpbxExcPremSrch {
    .txtPayReq,.ddLoc ,.ddPrmCode ,.ddStatus {
      min-width: 37%;
      margin-right: 8%;
    }
    label {
      width: 130px;
      text-align: right;
    }
    .txtPayReq {
      input {
        width: 40%;
      }
    }
    .ddLoc{
      max-width: 33.33%;
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
    }
    }
    .txtVndr {
      min-width: 22.3%;
      padding-right: 0;
      input {
        width: 100%;
      }
    }
    .ddPrmCode{
      max-width: 33.33%;
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
    }
    }
    .txt1007 {
      label {
        width: 129px;
      }
    }
    .txt1007,
    .txtsc95,
    .txtContrct,
    .txtFrm,
    .txtFrmSfx {
      max-width: 24%;
    }
    .btnPopupVendr {
      // margin-right: 4px;
      max-width: 2%;
      padding:0;
      padding-left: 0.5%;
      margin-bottom: 0 !important;
      bottom: 6px;
    }
    .txtVndor {
      min-width: 20.7%;
      padding-left: 0.5%;
      padding-right: 0;
      .col{
        padding-right: 0;
        padding-left: 0;
      }
      label{
        display: none;
      }
      input{
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        color: blue;
        border: none;
        background: transparent;
        font-weight: 600;
      }
    }
    .ddOleic {
      label {
        width: 133px;
      }
      margin-left: 68.8%;
      max-width: 30.3%;
    }
    .btnSrch {
      margin-left: 49%;
    max-width: 19%;
    }
    .btnOpen {
      max-width: 5%;
    }
    .btnContrctPyReq {
      max-width: 11.5%
    }
    .btnCrtExcPrem {
      max-width: 13.5%    }

    .ddSegTyp,
    .ddGen,
    .ddSeed,
    .ddPnutVrty,
    .ddPnutTyp{
      max-width: 30%;
    }

     .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
    }
   
  }
  .btnExport {
    max-width: 12%;
    left: 46%;
  }
  .btnPrntSrch {
    max-width: 10%;
    left: 46%;
  }
  .btnExit {
    max-width: 6%;
    left: 46%;
  }
  .txtTotlAmt {
    left: 38%;
    bottom: 9px;
    position: relative;
    top: 2px;
  }
  .lstExecPrem {
    min-width: 100%;
    select {
      min-height: 230px;
    }
  }
  .gridExceptionPremiumSearch {
    .card-header{
      display: block;
    }
    label {
      text-align: left;
    }
    // .rdt_TableCol:nth-child(1),.rdt_TableCell:nth-child(1) {
    //   min-width: 100px;
    //   max-width: 100px;
    // }
    // .rdt_TableCol:nth-child(2),.rdt_TableCell:nth-child(2) {
    //   min-width: 100px;
    //   max-width: 100px;
    // }
    // .rdt_TableCol:nth-child(3),.rdt_TableCell:nth-child(3) {
    //   min-width: 210px;
    //   max-width: 210px;
    //   justify-content: left;
    // }
    // .rdt_TableCol:nth-child(5),.rdt_TableCell:nth-child(5) {
    //   min-width: 180px;
    //   max-width: 180px;
    // }
    // .rdt_TableCol:nth-child(4),.rdt_TableCell:nth-child(4) {
    //   min-width: 160px;
    //   max-width: 160px;
    //   justify-content: left;
    // }
    // .rdt_TableCol:nth-child(6),.rdt_TableCell:nth-child(6) {
    //   min-width: 120px;
    //   max-width: 120px;
    //   justify-content: right;
    // }
    // .rdt_TableCol:nth-child(7),.rdt_TableCell:nth-child(7) {
    //   min-width: 140px;
    //   max-width: 140px;
    // }
    // .rdt_TableBody{
    //   height: 200px;
    // }

  }

  
  /*END_USER_CODE_FOR_.SCSS*/
}