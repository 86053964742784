.WarehouseReceipts-ViewWRPremiumDeductions,.ContractManagement-ViewWRPremiumDeductions {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333e48;
  background: transparent;
  h1 {
    display: none;
  }
  .card {
    background-color: #f3f0f0;
    border: none;
  }
  .card-header{
    display: none;
  }
  .GridWidget .collapse > div {
    overflow-y: overlay !important;
    max-height: 300px !important;
    min-height: auto !important;
}
  .gridPremsDeducts{
    .rdt_TableRow{
      padding-right: 8px;
    }
    .expandable-area{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-right: 8px !important;
    }
    .ExpandRow{
      padding-right: 2%;
    padding-left: 2%;
    border-bottom: 0.7px solid #eeeede;
    }
    .extraRowCell_2{
        min-height: 35px;
          min-width: 31.82%;
          border-right: 1px solid #eeeede;
          label{
            width: 100%;
            text-align: center;
          }
        }
        .extraRowCell_4{
          min-height: 35px;
          min-width: 33.67%;
          border-right: 1px solid #eeeede;
          label{
            width: 100%;
            text-align: center;
          }
        }
        .extraRowCell_5{
          min-height: 35px;
          min-width: 34.5%;
          label{
            width: 100%;
            text-align: right;
          }
        }
        .rdt_TableCell:nth-child(2),.rdt_TableCol:nth-child(2){
          min-width: 315px;
          max-width: 315px;
          font-weight: bolder;
        }
        .rdt_TableCell:nth-child(3),.rdt_TableCol:nth-child(3){
          min-width: 361px;
          max-width: 361px;
          padding: 0;
        }
        .rdt_TableCell:nth-child(4),.rdt_TableCol:nth-child(4){
          min-width: 387px;
          max-width: 387px;

          font-weight: bolder;
          justify-content: right;
          border-right: none !important;
        }
        .rdt_TableCol:nth-child(4){
          justify-content: center;
        }
  }
  .grpbxActions{
    .btnCancel{
      min-width: 100%;
    }
    .row{
      justify-content: center;
    }
  }
    /*END_USER_CODE_FOR_.SCSS*/
}