.ContractManagement-ContractExceptPremProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #f3f0f0;
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
  .grpbxBuyingPoint {
    .card {
      background-color: #c0bcbc;
    }
    label {
      width: 185px;
      text-align: right;
    }
    .lblBuyingPoint {
      margin-left: 16.2%;
      max-width: 52%;
      margin-top: 3%;
      label{
        width: 95px;
      }
      input{
        background: transparent;
        color: #0000FF;
        border: none;
      }
    }
    .ddExcPrmCd {
      margin-top: 4%;
    }
    .ddDelLoadType,
    .txtRate,
    .ddExcPrmCd {
      max-width: 70%;
      margin-left: 15%;
      // margin-top:5px;
      .d-flex {
        width: 75% !important;
      }
    }
    .txtRateBase {
      max-width: 12%;
      padding-left: 0;
    }
    .ddRateBase {
      min-width: 53%;
      margin-left: 15%;
    }
    .txtarExplanation {
      max-width: 53%;
      margin-left: 15%;
      textarea {
        height: 225px;
      }
    }
    .ddExcPrmCd,.txtRate,.ddRateBase,.ddDelLoadType{
      color: red;
    }
  }
  .grpbxAddCancel {
    .card {
      border: none;
      background-color: #f3f0f0;
    }
    
    .lblAddedBy,.lblChangedBy {
      max-width: 8%;
      padding-right: 0%;

    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 72%;
      padding-left: 0;
    }
    .btnAddProfile,
    .btnCancel {
      max-width: 10%;
      min-width: 10%;
      button{
        width: 80%;
      }
    }
  }
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
}

  /*END_USER_CODE_FOR_.SCSS*/
}
