.Settlements-1007Settlement,.ContractManagement-1007Settlement {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background-image: url(../../../../assets/img/BgLogo.png);
  .grpbx1007Settlements      {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #f3f0f0;
    }
    .lblControlInformation {
      label{
        width: 100%;
        text-align: center;
        font-weight: bold;
      }
    }
    .txtSC95{
      min-width: 25%;
      max-width: 25%;
      margin-left: 30%;
      label{
        width: 100px;
        text-align: right;
      }
    }
    .btnLookup{
      max-width: 10%;
      button{
        width: 90%;
      }
    }
    .txt1007{
      min-width: 25%;
      max-width: 25%;
      margin-left: 30%;
      label{
        width: 100px;
        text-align: right;
      }

    }
    .txtFarm,.txtBuyingPoint{
      min-width: 40%;
      max-width: 40%;
      margin-left: 30%;
      label{
        width: 100px;
        text-align: right;
      }
    }

    .grpbxVendorSplits {
      border: 1px solid #ccc;
      padding: 0;
      .textboxwidget-container,.dropdownfieldwidget-container{
        .col, input{
          padding-left: 0.2rem;
          padding-right: 0.2rem;
        }
        padding: 0;
      }
      .chkboxPurchaseAll ,.chkboxStoreAll {
        max-width: 12%;
        padding: 0;
        input{
          bottom: 2px;
        }
      }
      .chkboxPurchaseAll{
        margin-left: auto;
      }
      .labelwidget-container{
        label{
          width: 100%;
          text-align: center;
        }
      }
      [class ^= "grpbxVendorSplitRow"]{
        padding: 0;
      }
      [class ^= "lblVendor"]{
        
        margin-left: 16.5%;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        color:blue;
        label{
        //  display: none;
        width: 0;
        }
        .col{
          width: 100%;
        }
      }
      [class ^= "ddWRNum"],.lblWhseRcptuic0 {
        max-width: 10%;
        min-width: 10%;
      }
      [class ^= "ddPS"],.lblPOrSuic0 {
        max-width: 7.5%;
        min-width: 7.5%;
      }
      [class ^= "txtVendor"],.lblVendoruic0 {
        max-width: 12.5%;
        min-width: 12.5%;
        margin-left: 0;
        label{
          width: 100%;
          color: black;
        }
      }
      [class ^= "txtRemitTo"],.lblRemitTo0uic0  {
        max-width: 40%;
        min-width: 40%;
      }
      [class ^= "txtSharePct"],.lblSharePctuic0 {
        max-width: 8%;
        min-width: 8%;
      }
      [class ^= "txtPounds"],.lblPoundsuic0{
        max-width: 10%;
        min-width: 10%;
      }
      [class ^= "txtValue"],.lblValue0uic0 {
        max-width: 12%;
        min-width: 12%;
      }
      .lblVendorSplitsuic0{
        max-width: 71.25% !important;
        margin-left: 0;
        label{
          width: 100%;
          text-align: left !important;
          font-weight: bold;
          color: black
        }
      }
      [class ^= "txtAdditionalVendorInfo"]{
        max-width: 10%;
        min-width: 10%;
        margin-left: 48%;
      }
      [class ^= "txtCMAParticipant"]{
        max-width: 12%;
        min-width: 12%;
      }
    }
  }
  .grpbxInspectorRemarks {
  
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #f3f0f0;
      
    }
  }

  .grpbxPrchsStrgWtCert {
    // bottom: 283px;
  
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:   #f3f0f0;;
    }
    .grpbxPurchases {
      .card{
        border: none;
        background-color: #f3f0f0;
        min-height: 400px;
      }
      padding-left: 0;
      padding-right: 0.2%;
      max-width: 50%;
      label{
        width: 130px;
        text-align: right;
      }
      .lblPurchases{
        label{
          width: 100% !important;
          text-align: center;
          font-weight: bold;
        }
      }

    }
    .grpbxStorage {
      .card{
        border: none;
        background-color:  #f3f0f0;
        min-height: 400px;
      }
      padding-left: 0;
      padding-right: 0.2%;
      max-width: 50%;
      label{
        width: 130px;
        text-align: right;
      }
      .lblStorage{
        label{
          width: 100% !important;
          text-align: center;
          font-weight: bold;
        }
      }
    }

  }
  .grpbxValueCalculation  {
    // bottom: 851px;
    // bottom: 597px;
    padding-left: 0;
    max-width: 33.33%;
    min-width: 33.33%;
    .card-header{
      display:none
    }
    .card{
      min-height: 940px;
      border: none;
      background-color: #f3f0f0;
    }
   
    
    

    .textboxwidget-container{
      label{
        width: 175px;
        text-align: right;
      }
      input{
        width: 75%;
      }
      padding: 0;
    }

    
    // .txtKernelValueTon  ,.txtELKPremiumTon ,
    // .txtTotalTon ,.txtDamageTon ,.txtAccessFMTon ,.txtExcessSplitsTon ,.txtAFlavusTon ,.txtTotalDiscountsTon,.txtValPerPoundExclSLK ,.txtG 
    // // .
    // {
    //   .col{
    //     // padding-right: 0;
    //   }
    // }
  
    .txtG {
      min-width: 87.5%;
      input{
        width: 100% !important;
      }
    }
    .lblValueCalculation {
      max-width: 100%;
      label{
        text-align: center;
        width: 100%;
        font-weight: bold;
      }
    }

    .lblPerTon {
      max-width: 30%;
      margin-left: 50%;
      label{
        text-align: center;
        width: 100%;
      }
    }
    .lblPerLb3 {
      max-width: 12.5%;
      label{
        text-align: center;
      }
      
    }

    .txtValPerPoundInclSLK {
      white-space: nowrap;
    }
  }

  .grpbxActions {
    .card-header{
      display:none
    }
    // margin-top: 1rem;
    
    .card{
      border: none;
      background-color:#f3f0f0;
      // padding-top: 1rem;
    }
    .lblChangedBy,.lblAddedBy {
      max-width: 10%;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblChangedByValue,.lblAddedByValue{
      min-width: 40%;
      margin-right: 20%;
      label{
        display: none;
      }
    }
   .lblAddedByValue,.lblChangedByValue{
    .col{
      position: relative;
      bottom: 7px;
      right: 36px;
    }
  }
    .lblChangedBy,.lblChangedByValue{
      bottom: 10px;
    } 
    .lblAddedByValue,.lblAddedBy{
      bottom: 10px;
    }
    .btnCancel{
      max-width: 10%;
      top: 15px;
      button{
        width: 85%;
        height: 40px;
      }
    }
    .btnCntrctAppPremDeduct {
      margin-left: auto;
      max-width: 20%;
      top: 15px;
      button{
        width: 65%;
        height: 48px;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
