.ContractManagement-ExceptPremiumAdjustments {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
 // background-color: #f3f0f0;
  .card {
    background-color: #f3f0f0;
    border: none;
  }
  .grpbxReqDetails {
    label {
      width: 111px;
      text-align: right;
    }
    .grpbxLabels {
      max-width: 36%;
      left: 63%;
      height: 37px;
    }
  }
  .txtRatebaseID {
    max-width: 53%;
    margin-left: 48%;
    input {
      max-width: 37%;
    }
  }
  .txtRate {
    max-width: 25%;
  }
  .ddRateBase {
    max-width: 46%;
  }
  .grpbxActions {
    .btnUpdate {
      max-width: 15%;
      margin-left: 73%;
    }
    .btnCancel,
    .btnDelete {
      max-width: 6%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
