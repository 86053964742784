.SystemMaintenanceMasterManagement-VoidReasonProfile,.ContractManagement-VoidReasonProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxVoidReasonProfile{
    .card-header{
      display:none;
    }
    .card{
      background-color: #c0bcbc;
    }
    label{
      width:200px;
      text-align: right;
    }
    .txtCode,.txtTransmitCode{
      input{
        max-width: 15%;
      }
      label{
        color:red;
      }
    }
    .ddAction{
      .dropdown{
        max-width: 25%;
      }
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      button{
        height:30px;
        }
      label{
        color:red;
      }
    }
    .chkboxLimitAccess{
      .col{
        left:58%;
      }
      max-width: 30%;
      .form-check{
        label{
          text-align: left;
        }
      }
    }
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 8%;
      padding: 0;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 77%;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 7px;
      }
    }
    .btnOk,.btnCancel{
       max-width: 7.5%;
       min-width: 7.5%;
       top: 10px;
       button{
         width: 90%;
       }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
