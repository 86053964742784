.SystemMaintenanceMasterManagement-ContractLimitCollPt,.ContractManagement-ContractLimitCollPt {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
   display:none
 }
//  background-color: #333E48;
background: transparent;
 .card{
   .card-header{
     display: none;
   }
 }
 .grpbxCntrctLimit {
   margin-bottom: 0 !important;
   .card{
     padding-top: 0.2rem !important;
     border: none;
     background-color: #c0bcbc;
     .card-header{
       display:none
     }
     .checkboxwidget-container{
       display: none !important;
     }
     .txtPrcngTyp,.txtArea ,.txtPnutTyp,
     .txtDesc ,.txtVrty{
       max-width: 50%;
       min-width: 50%;
     }
     .txtPriceTon ,.txtOptPrcCap,.txtSeg{
       max-width: 30%;
       min-width: 30%;
     }
     .txtPriceMtchEnd,.txtPayRebt,.txtOleic{
       max-width: 45%;
       min-width: 45%;
     }
     .txtOleic,.txtPayRebt {
       margin-right: 25%;
     }      
     .txtArea{
       margin-right: 50%;
     }
     .txtRebtRate {
       max-width: 25%;
     }
     
     .grpbxMarket {
       max-width: 75%;
       min-width: 75%;
       padding: 0;
       .card{
         .card-body{
           padding-left: 0.95rem;
           padding-right: 0.95rem;
           .txtMrktFloor ,.txtMrktGain ,.txtLoanRepay {
             max-width: 40%;
             min-width: 40%;
           }
           .txtMrktCeiling ,.txtMultiPricings ,.txtMrktWeeksPriorMatrty  {
             max-width: 60%;
             min-width: 60%;
             label{
               width: 58%;
             }
           }
         }
       }
     }
     
     label{
       width: 120px;
       text-align: right;
     }
     .txtDesc,.txtVrty{
       label{
         width: 9.75%;
       }
     }
     .txtOleic,.txtPayRebt ,.txtPriceMtchEnd{
       label{
         width: 58%;
       }
     }
 
     .lstboxLockIn {
       max-width: 25%;
       max-height: 85px;
       padding-left: 0;
       label{
         width: 50px;
       }
     }
   }
 }
 .gridContrctLimit {
   min-width: 100%;
   .card-header{
     display: block;
   }
   div#row-0 {
     pointer-events: none;
     input{
       border: none;
       box-shadow: none;
     }
     button{
       display: none;
     }
 }
 }
 .grpbxActions {
   .card{
     padding-top: 0.2rem !important;
     border: none;
     background-color: #f3f0f0;
     .btnIcon{
       display: none;
     }
     .txtAvailTons {
       max-width: 30%;
       min-width: 30%;
     }
     .buttonwidget-container{
       max-width: 10%;
       min-width: 10%;
       .row{
         justify-content: center;
         button{
           width: 95%;
         }
       }
     }
     .btnPrintGrid {
       margin-left: 40%;
     }
     .btnSave {
       margin-left: 70%;
     }
   }
 }
 /*END_USER_CODE_FOR_.SCSS*/
 }
 