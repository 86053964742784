.SystemMaintenanceSpecialFunctions-BankHolidaySetup,.ContractManagement-BankHolidaySetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
   display:none
 }
//  background-color: #333E48;
background: transparent;
 .grpbxBankHolidaySetup {
   .card{
     padding-top: 0.2rem !important;
     border: none;
     background-color: #c0bcbc;
     .card-header{
       display:none
     }
     .lblCompanyList {
       min-width: 30%;
       max-width: 30%;
       label{
         margin-bottom: 0;
       }
     }
     .buttonwidget-container{
       max-width: 7.5%;
       margin-bottom: 0.3rem !important;
      .row{
       justify-content: center;
       button{
         width: 95%;
       }
      }
     }
     .btnAdd{
       margin-left: 84%;
     }
     .btnDelete{
       margin-right: 1%;
     }
     .lstBankHolidayList {
       min-width: 100%;
       padding: 0;
       label{
         display: none;
       }
       select{
         height:247px;
       }
     }
   }
   
 }
 
 
 .cmmndCntnrClose{
   
   .row{
     background-color: #f3f0f0;
     padding-top: 0.2rem;
   }
   .btnClose{
     max-width: 10%;
     min-width: 10%;
     margin-left: 45%;
     button{
       width: 100%;
     }
   }
   
 }
 /*END_USER_CODE_FOR_.SCSS*/
 }
 