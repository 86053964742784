.WarehouseReceipts-DelvSettleProfile,.ContractManagement-DelvSettleProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #c0bcbc;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxDeliveryDetails {
    .card{
      .txtDelvInspectSC95,.txtInspectDateTime ,.txtDelvSettle1007 ,.txtSettleDateTime {
        max-width: 40%;
        min-width: 40%;
        label{
          width: 130px;
          text-align: right;
        }
      }
      .txtStatus{
        max-width: 20%;
        min-width: 20%;
      }
      .radioDelvInOut{
        max-width: 20%;
        min-width: 20%;
        max-height: 30px;
        position: relative;
        top: 1rem;
        .col{
          padding-left: 3rem;
        }
      }
      .ddShipFromToLocation,.ddCategory {
        max-width: 40%;
        min-width: 40%;
        label{
          width: 127px;
          text-align: right;
        }
        .dropdown{
          width: 80%;
        }
      }
      .ddCategory {
        #ddItem_{
          height: 25px;
        }
        .dropdown-toggle:empty::after {
          margin-left: auto;
        }
        button{
        height:30px;
        }
      }
      .txt1007Handler ,.txt1007HandlerName{
        max-width: 40%;
        min-width: 40%;
        margin-left: 20%;
        label{
          width: 125px;
          text-align: right;
        }
      }
      .txt1007Handler{
        input{
          width: 40%;
        }
      }
      .grpbxVendorSplits {
        padding-top: 0.25rem;
        max-width: 60%;
        min-width: 60%;
        padding-right: 0;
        .card{
          border: 1px solid rgba(0, 0, 0, 0.125);
          .lblVendorSplits  {
            min-width: 100%;
            label{
              width: 100%;
              font-weight: bold;
            }
          }
          .lblVendor {
            max-width: 20%;
            min-width: 20%;
            margin-right: 5%;
            label{
              width: 100%;
              text-align: center;
            }
          }
          .lblRemitTo  {
            max-width: 55%;
            min-width: 55%;
            label{
              width: 100%;
              text-align: center;
            }
          }
          .lblSharePercentage  {
            max-width: 15%;
            min-width: 15%;
            label{
              width: 100%;
              text-align: center;
            }
          }
          .grpbxVendorSplitsRows {
            padding: 0;
            .card{
              .card-body{
                padding-top: 0.25rem;
                [class^=grpbxVendorSplitRow]{
                  padding: 0;
                  .card{
                    border: none;
                    .card-body{
                      padding-top: 0;
                    }
                  }
                }
                [class^="txtVendor"]{
                  max-width: 20%;
                  min-width: 20%;
                  padding: 0;
                }
                .buttonwidget-container{
                  max-width: 5%;
                  min-width: 5%;
                  margin-bottom: 0.3rem !important;
                }
                [class^="ddRemitTo"]{
                  max-width: 55%;
                  min-width: 55%;
                  padding: 0;
                }
                [class^="txtSharePercentage"]{
                  max-width: 19.9%;
                  min-width: 19.9%;
                  padding: 0;
                  .col{
                    padding-left: 0;
                  }
                }
                [class^="lblXX"]{
                  max-width: 100%;
                  min-width: 100%;
                  label{
                    margin-bottom: 0 !important;
                  }
                  .col{
                    color: blue;
                    padding-left: 0;
                  }
                }

              }
            }
          }
        }
      }
      .grpbxPntShippd  {
        padding-top: 0.25rem;
        max-width: 40%;
        min-width: 40%;
        padding-right: 0;
        padding-left: 0;
        .card{
          .grpbxPeanutInfo {
            padding-left: 0;
            .card{
              border: 1px solid rgba(0, 0, 0, 0.125);
              .lblPeanutInformation {
                min-width: 100%;
                label{
                  width: 100%;
                  text-align: center;
                  font-weight: bold;
                }
              }
              .dropdownfieldwidget-container{
                min-width: 100%;
                max-width: 100%;
                label{
                  width: 100px;
                  text-align: right;
                }
                #ddItem_{
                  height: 25px;
                }	  
                .dropdown-toggle:empty::after {
                 margin-left: auto;
                }
                 button{
                 height:30px;
                }
              }
            }
          }
          .grpbxShippedFrom {
            padding-left: 0;
            .card{
              border: 1px solid rgba(0, 0, 0, 0.125);
              .lblShippedFrom  {
                min-width: 100%;
                label{
                  width: 100%;
                  text-align: center;
                  font-weight: bold;
                }
              }
              .ddWhseBinNum {
                min-width: 100%;
                .d-flex{
                  display: block !important;
                  label{
                    color: red;
                    padding-top: 0 !important;
                    margin-bottom: 0;
                  }
                  .col{
                    padding: 0;
                  }
                }
              }
            }
          }
          .grpbxShippedTo {
            padding-left: 0;
            .card{
              border: 1px solid rgba(0, 0, 0, 0.125);
              .lblShippedTo {
                min-width: 100%;
                label{
                  width: 100%;
                  text-align: center;
                  font-weight: bold;
                }
              }
              .txtarShippedTo {
                min-width: 100%;
                .col{
                  padding: 0;
                }
              }
            }
          }
        }
      }
      .grpbxScaleTickets {
        padding-top: 0.25rem;
        .card{
          padding-top: 0.25rem;
          border: 1px solid rgba(0, 0, 0, 0.125);
          .lblScaleTickets {
            max-width: 50%;
            min-width: 50%;
            label{
              margin-bottom: 0 !important;
              font-weight: bold;
            }
          }
          .btnAddST,.btnEditST,.btnDeleteST{
            max-width: 10%;
            min-width: 10%;
            margin-bottom: 0.3rem !important;
            .row{
              justify-content: center;
              button{
                width: 95%;
              }
            }
          }
          .btnAddST{
            margin-left: 19%;
          }
          .gridScaleTickets {
            min-width: 100%;
          }
          .txtTckt {
            max-width: 20%;
            min-width: 20%;
            input{
              background-color: #c0bcbc;
            }
          }
          .txtTotalLBS {
            max-width: 20%;
            min-width: 20%;
            margin-left: 60%;
            input{
              background-color: #c0bcbc;
            }
          }
        }
      }
      .grpbxDelvSettleProfile {
        padding: 0;
        .grpbxReGrading {
          max-width: 65%;
          padding-left: 0.65rem;
          padding-right: 0.25rem;
          .card{
            border: 1px solid rgba(0, 0, 0, 0.125);
            .lblReGrading {
              min-width: 100%;
              label{
                font-weight: bold;
              }
            }
            .radioGradePricingMethod{
              max-height: 50px;
              max-width: 50%;
              .col{
                padding-top: 0.15rem;
              }
            }
            .radioGramsPercentage{
              min-width: 35%;
              max-width: 35%;
              padding: 0;
              padding-top: 4rem;
              margin-left: 25%;
              margin-right: 40%;
              .col{
                .form-check{
                  display: inline;
                  padding-right: 25%;
                }
              }
            }
            .textboxwidget-container{
              max-width: 37.5%;
              min-width: 37.5%;
              padding: 0;
              label{
                width: 66.67%;
                text-align: right;
              }
              .col{
                padding-left: 0.2rem;
                padding-right: 0.2rem;
              }
              input{
                text-align: right;
              }
            }
            [class*="Pct"]{
              max-width: 12.5%;
              min-width: 12.5%;
            }
           
            .txtFMSampleWt ,.txtCleanSampleWt {
              max-width: 50%;
              min-width: 50%;
              input{
                text-align: right;
              }
            }
            .txtTotalSMK ,.txtOKFallThru ,.txtLSKFallThru ,.txtTotalKernels ,.txtOKDamageRiding,
            .txtLSKDamageRiding ,.txtMoisture,.txtTotalKernelsAndHulls ,.txtVicamReading ,.txtSeedMeetingSpec
            {
              min-width: 50%;
              max-width: 50%;
              label{
                width: 50%;
              }
              .col{
                padding-left: 0.4rem;
                input{
                  width: 49%;
                  margin-left: 51%;
                  text-align: right;
                }
              }
            }
            .txtDamageSplits ,.txtProbingPattern ,.txtKernelsREL ,.txtMeterReading ,.txtKernelsRPS ,
            .txtBluePanWT ,.txtDamagedKRS ,.txtELKDamaged {
              min-width: 50%;
              max-width: 50%;
              label{
                width: 50%;
              }
              .col{
                input{
                  width: 49%;
                  text-align: right;
                }
              }
            }
            .txtSMK1Gr,.txtSMK2Gr,.txtSMK3Gr,.txtSMK4Gr,.txtOKRidingGr,.txtLSKRidingGr{
              label{
                width: 50%;
              }
              input{
                width: 35%;
                text-align: right;
              }
            }
            .grpbxDamage {
              min-width: 50%;
              max-width: 50%;
              padding: 0;
              .card{
                border: none;
                width: 101.2%;
                .textboxwidget-container{
                  max-width: 75%;
                  min-width: 75%;
                  padding: 0;
                  label{
                    width: 66.67%;
                    text-align: right;
                  }
                  .col{
                    padding-left: 0.2rem;
                    padding-right: 0.2rem;
                  }
                }
                [class*="Pct"]{
                  max-width:25%;
                  min-width: 25%;
                }
              }
            }
            .chkboxReGrading {
              max-width: 50%;
              min-width: 50%;
              max-height: 80px;
              .d-flex{
                padding-left: 4rem;
                .col{
                  .form-check-label{
                    width: 45%;
                    text-align: right;
                  }
                  input{
                    margin-left: 45%;
                    bottom: 1px;
                  }
                }
              }
            }
            .txtVicamReading{
              margin-left: 50%;
            }

          }
          
        }
        .grpbxValCalSettlInfo {
          padding-left: 0;
            padding-right: 0.4rem;
            max-width: 35%;
            min-width: 35%;
          .grpbxValueCalculation  {
            max-width: 100%;
            min-width: 100%;
            padding: 0;
            .card-header{
              display:none
            }
            .card{
              border: 1px solid rgba(0, 0, 0, 0.125);
              background-color: #c0bcbc;
            }
            .textboxwidget-container{
              label{
                width: 66.67%;
                text-align: right;
              }
              .col{
                padding-right: 0.2rem;
                padding-left: 0.2rem;
              }
              input{
                text-align: right;
              }
              padding: 0;
              margin-bottom: 0 !important;
            }
            [class*="PerTon"]{
              min-width: 75%;
              max-width: 75%;
            }
            [class*="PerPound"]{
              max-width: 25%;
              min-width: 25%;
            }
            .txtWtIncludingVehicle ,.txtNetWtExclLSK ,.txtLSK  ,.txtNetWt ,.txtExcessMoisture ,.txtWeightLessFM  ,.txtForeignMaterial ,.txtGrossWeight ,.txtVehicleWt ,.txtNetWtExcludingLSK ,
            .txtOI ,.txtH ,.txtTotalLoadVal ,.txtNetValPerTonExclSLK ,.txtValPerPoundInclSLK,.txtPerG  {
              min-width: 75%;
              max-width: 75%;
            }
            .txtTKC {
              min-width: 25%;
              max-width: 25%;
              .col{
                padding-left: 0;
              }
            }
            .lblValueCalculation {
              max-width: 60%;
              min-width: 60%;
              label{
                text-align: left;
                font-weight: bold;
              }
            }
            .btnCalculate{
              margin-bottom: 0.3rem !important;
              padding-top: 0.25rem;
              max-width: 40%;
            }
            .lblPerTon {
              max-width: 25%;
              min-width: 25%;
              margin-left: 50%;
              label{
                text-align: center;
                width: 100%;
                margin-bottom: 0 !important;
              }
            }
            .lblPerLb,.lblPerLb2,.lblPerLb3 {
              max-width: 25%;
              min-width: 25%;
              label{
                text-align: center;
                width: 100%;
                margin-bottom: 0 !important;
              }
            }
            .txtValPerPoundExclSLK{
              min-width: 75%;
              max-width: 75%;
            }
          }
          .grpbxSettlementInformation {
            padding: 0;
            .card{
              border: 1px solid rgba(0, 0, 0, 0.125);
              .lblSettlementInformation {
                min-width: 100%;
                label{
                  width: 100%;
                  text-align: center;
                  font-weight: bold;
                }
              }
              .textboxwidget-container{
                min-width: 100%;
                margin-bottom: 0 !important;
                label{
                  width: 60%;
                  text-align: right;
                }
                input{
                  background-color: #c0bcbc;
                  text-align: right;
                }
              }
            }
          }
        }
        
        .grpbxSeg {
          padding: 0;
          .card{
            padding-top: 0.25rem;
            border: 1px solid rgba(0, 0, 0, 0.125);
            .lblPrintSeg {
              max-width: 7%;
              min-width: 7%;
              padding-right: 0;
              max-height: 30px;
              label{
                width: 100%;
                text-align: right;
              }
            }
            .chkboxPrintSeg {
              max-width: 5%;
              padding: 0;
              max-height: 30px;
              .col{
                input{
                  bottom: 5px;
                }
              }
            }
            .txtLoadNum {
              max-width: 20%;
              min-width: 20%;
              label{
                width: 100px;
                text-align: right;
              }
            }
            .txtLotNum {
              max-width: 30%;
              min-width: 30%;
              label{
                width: 50px;
                text-align: right;
              }
            }
            .txtStartDateTime {
              max-width: 38%;
              min-width: 38%;
            }
            .txtWormDamagePct {
              max-width: 20%;
              min-width: 20%;
              margin-left: 12%;
              label{
                width: 100px;
                text-align: right;
              }
            }
            .txtSealNum {
              max-width: 68%;
              min-width: 68%;
              label{
                width: 50px;
                text-align: right;
              }
            
            }
          }
        }
        .txtarInspectionRemarks ,.txtarSettleRemarks {
          min-height: 100%;
          padding: 0;
          .d-flex{
            display: block !important;
            label{
              margin-bottom: 0 !important;
            }
            .col{
              padding: 0;
            }
          }
        }
      }
     
    }
  }
  .grpbxActions {
    .card{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
      .row{
        height: 120px;
      }
      .lblCropYear2{
        min-width: 14%;
        max-width: 14%;
        margin-left: 20%;
        margin-right: 60%;
        background-color: #c0bcbc;
        color: red;
        font-size: 14px;
        font-weight: 500;
        .col{
          position: relative;
          top: 5px;
          right: 10px;
        }
      }
      .lblAddedBy ,.lblChangedBy{
        max-width: 10%;
        padding-right: 0;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .lblAddedByValue ,.lblChangedByValue{
        max-width: 30%;
        min-width: 30%;
        label{
          width: 100%;
          text-align: left;
          display: none;
        }
 
      }
    //   // .btnReversal{
    //   //   display: none;
    //   // }
    .btnCreateSettlement ,.btnReversal,.btnPrintSettlement,.btnSave{
      button{
        height: 72px;
      }
    }
    .btnSave{
      button{
        position: relative;
        top: 39px;
      }
    }
    .lblAddedByValue,.lblAddedBy{
      max-height: 32px;
    }
      .buttonwidget-container{
        max-width: 10%;
        min-width: 10%;
        .row{
          height: revert-layer;
          button{
            width: 95%;
          }
        }
      }
    //   // .btnUnvoid{
    //   //   display: none;
    //   // }
      .btnDelete{
        margin-left: 10%;
      }
      .btnAgreeAppl {
        margin-left: 20%;
      }
      .btnClose{
        margin-left: 10%;
      }
      .btnAgreeAppl,.btnDelete,.btnClose,.btnUnvoid,.btnVoid,.btnSave,.lblChangedByValue,.lblChangedBy{
        bottom: 39px;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
