.modal-dialog :has(.model-content) :has(#WarehouseReceipts_WarehouseReceiptInquiryPopUpPopUp){
 
  box-shadow: none !important;
}
.modal-content:has(#WarehouseReceipts_WarehouseReceiptInquiryPopUpPopUp){
  background-color: transparent !important;
  border: none !important;
 
}
#WarehouseReceipts_WarehouseReceiptInquiryPopUpPopUp{
  border: none;
  .close{
    display: none !important;
  }
}

.WarehouseReceipts-WarehouseReceiptInquiryPopUp,.ContractManagement-WarehouseReceiptInquiryPopUp {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .modal-content{
    background-color: transparent !important;
    border: none !important;
  
  }
  form{
    background: transparent !important;
    max-width: 50%;
  }
  .grpbxPopUp{
    .card-header{
       display: none;
    }
    .lblConfirmMsg{
      font-size: larger;
    }
    .txtDoYouWant{
      input{
        background: transparent;
        border: none;
        font-size: 16px;
      }
    }
    .btnYes,.btnNo{
      max-width: 15%;
      button{
        width: 80%;
      }
    }
    .btnYes{
      margin-left: 70%;
    }

  }
  /*END_USER_CODE_FOR_.SCSS*/
}
