.SystemMaintenanceMasterManagement-PeanutVarietySetup,.ContractManagement-PeanutVarietySetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxPeanutVarietySetup{
    .row{
      justify-content: center;
    }
    .card-header{
      display: none;
    }
    .card{
      background-color: #c0bcbc;
    }
    .btnAdd,.btnEdit,.btnDelete,.btnAddV,.btnEditV,.btnDeleteV{
      max-width: 4.2%;
      }
      .btnAdd,.btnAddV{
        margin-left: 50%;
      }
      .btnEdit,.btnEditV{
        position: relative;
        right:2px;
      }
    .ddType,.ddArea,.lstVariety{
      min-width: 68%;
      max-width: 68%;
      // .dropdown{
      //   max-width: 58%;
      // }
      select{
        height: 130px;
      }
    }
    .lstVariety{
      margin-right: 13px;
    min-width: 69%;
    max-width: 69%;
    }
  }
  
 .cmmndCntnrClose{
  .row{
    justify-content: center;
    background-color: #f3f0f0;
  }
 
 } 
  /*END_USER_CODE_FOR_.SCSS*/
}
