.SystemMaintenanceMasterManagement-WarehouseBinSetup ,.ContractManagement-WarehouseBinSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333E48;
  background: transparent;
  h1{
    display: none;
  }
.grpbxWarehouseBinSetup{
  .card {
    background-color: #c0bcbc;
  }
  .card-header{
    display: none;
  }
  h1{
    display: none;
  }
  .ddBuyingPoint{
    min-width: 84%;
    .dropdown{
      max-width: 35%;
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
  }
  .btnAdd,.btnEdit{
    max-width: 5%;
  }
  .btnDelete{
    max-width: 6%;
  }
  .gridCol {
    .rdt_TableCol:nth-child(1),
    .rdt_TableCell:nth-child(1){
      min-width: 52px;
      max-width: 52px;
    }
    .rdt_TableCol:nth-child(2),
    .rdt_TableCell:nth-child(2){
      min-width: 52px;
      max-width: 52px;
    }
    .rdt_TableCol:nth-child(3),
    .rdt_TableCell:nth-child(3){
      min-width: 178px;
      max-width: 178px;
    }
    .rdt_TableCol:nth-child(4),
    .rdt_TableCell:nth-child(4){
      min-width: 58px;
      max-width: 58px;
    }
    .rdt_TableCol:nth-child(5),
    .rdt_TableCell:nth-child(5){
      min-width: 58px;
      max-width: 58px;
    }
    .rdt_TableCol:nth-child(6),
    .rdt_TableCell:nth-child(6){
      min-width: 58px;
      max-width: 58px;
    }
    .rdt_TableCol:nth-child(7),
    .rdt_TableCell:nth-child(7){
      min-width: 58px;
      max-width: 58px;
    }
    .rdt_TableCol:nth-child(8),
    .rdt_TableCell:nth-child(8){
      min-width: 58px;
      max-width: 58px;
    }
    .rdt_TableCol:nth-child(9),
    .rdt_TableCell:nth-child(9){
      min-width: 58px;
      max-width: 58px;
    }
    .rdt_TableCol:nth-child(10),
    .rdt_TableCell:nth-child(10){
      min-width: 58px;
      max-width: 58px;
    }
    .rdt_TableCol:nth-child(11),
    .rdt_TableCell:nth-child(11){
      min-width: 118px;
      max-width: 118px;
    }
    .rdt_TableCol:nth-child(12),
    .rdt_TableCell:nth-child(12){
      min-width:118px;
      max-width: 118px;
    }
    .rdt_TableCol:nth-child(13),
    .rdt_TableCell:nth-child(13){
      min-width: 102px;
      max-width: 102px;
    }
    .rdt_TableCol:nth-child(14),
    .rdt_TableCell:nth-child(14){
      min-width: 98px;
      max-width: 98px;
    }
    .rdt_TableCol:nth-child(15),
    .rdt_TableCell:nth-child(15){
      min-width: 105px;
      max-width: 105px;
    }
  }
}
.cmmndCntnrActions{
  background-color: #f3f0f0;
  .btnPrint,.btnCopy,.btnClose{
    max-width: 5%;
  }
  .btnExportToExcel {
    min-width: 82%;
  }
  .btn1{
    max-width: 3%;
  }
}
  /*END_USER_CODE_FOR_.SCSS*/
}
