.SystemMaintenanceApplicationSupport-OpenAccountAdjustments,.ContractManagement-OpenAccountAdjustments {
   /*START_USER_CODE_FOR_.SCSS*/
   h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxOpenAccountAdjustments {
    margin-bottom: 0.2rem !important;
    .card-header{
      display:none
    }
    .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #c0bcbc;
    }

    .gridAdjustments {
      .card{
        padding-top: 0;
      }
      min-width: 100%;
      .card-header{
        display: block;
      }
    }
    .lblOPBalAdj ,.lblSRBalAdj ,.lblARBalAdj ,.lblCRBalAdj,.lblTotals {
      max-width: 10%;
      font-weight: bolder;
    }
    .lblOPBalAdj ,.lblSRBalAdj ,.lblARBalAdj ,.lblCRBalAdj{
      position: relative;
      bottom: 7px;
      label{
        display: none;
      }
    }
    .lblOPBalAdj{
      margin-left: 11%;
    }
    .lblSRBalAdj{
      position: relative;
      right: 16px;
    }
    .lblCRBalAdj{
      position: relative;
      right: 46px;
    }
    .lblARBalAdj{
      position: relative;
      right: 50px;
    }
    .lblAdjustments {
      label{
        margin-bottom: 0;
      }
    }
  }

  .grpbxActions {
    .card-header{
      display:none
    }
    .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #f3f0f0;
    }
    .lblViewOpenAdjustmentsDetail {
      min-width: 20%;
      max-width: 20%;
    }
    .buttonwidget-container{
      max-width: 10%;
      min-width: 10%;
      button{
        width: 90%;
      }
    }
    .btnClose{
      margin-left: 26%;
    }
    .btnExportToExcel{
      margin-left: 11%;
      // max-width: 12%;
    }
    .btnPrint{
      margin-left: 1%;
    }
    .btn1{
      display: none;
    }
  }

  /*END_USER_CODE_FOR_.SCSS*/
}
