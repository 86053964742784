.loader-container {
    // margin-left: -156px;
    // margin-top: -53px;
    // min-width: 120%;
    // height: 750px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 2005;
}

.spinner {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid black;
    width: 80px;  
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin-anim 2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}