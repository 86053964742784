.SystemMaintenanceApplicationSupport-ManualRTPHistSearch,.ContractManagement-ManualRTPHistSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxManualRTPHistSearch {
    margin-bottom: 0.2rem !important;
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card-header{
      display:none
    }
    .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #f3f0f0;
    }
    label{
      width: 120px;
      text-align: right;
    }
    
    .ddLocation {
      min-width: 55%;
      max-width: 55%;
      padding-right: 0;
      margin-right: 5%;
    }
    .txtVendor{
      max-width: 20% !important;
      min-width: 20% !important;
      padding-right: 0;
      .col{
        padding-right: 0;
      }
      input{
        width: 100% !important;
      }
    }
    .ddRemitTo {
      min-width: 35%;
      padding: 0;
    }
    .btnVendor{
      max-width: 5%;
      margin-bottom: 0.3rem !important;
    }
    .txtContract{
      min-width: 25%;
      padding-right: 0;
      input{
        width: 100% !important;
      }
    }
    .txtVndr  {
      max-width: 35%;
      min-width: 35%;
      padding: 0;
      input{
        color: blue;
        box-shadow: none;
        border: none;
        background: transparent;
      }
    }
    .txtSC95 ,.txtSettlement1007 ,.txtFreightMemo {
      max-width: 25%;
      min-width: 25%;
      margin-right: 35%;
      padding-right: 0;
    }
    .txtTradeInspection ,.txtTradeSettlement ,.txtWarehouseReceipt,
    .txtDeliveryAgreement,.txtProceeds1 {
      max-width: 25%;
      min-width: 25%;
      margin-right: 15%;
      padding-right: 0;
    }
    .txtProceeds1 {
      input{
        text-align: right;
      }
    }
    .txtFarm {
      max-width: 25%;
      min-width: 25%;
      padding-right: 0;
    }
    .txtFarmSuffix {
      max-width: 15%;
      min-width: 15%;
      padding: 0;
      label{
        width: 80px;
      }
      input{
        width: 100%;
      }
    }



    .cmmndCntnrSearchView  {
      .btnSearch{
        max-width: 10%;
        min-width: 10% !important;
        margin-left: 45%;
        button{
          width: 100%;
        }
      }
      .btnView{
        max-width: 7.5%;
        min-width: 7.5%;
        margin-left: 37.5%;
        margin-bottom: 0 !important;
        button{
          width: 95%;
        }
      }
    }
    .gridManualRTPHist {
      min-width: 100%;
        label{
          display: none;
        }
        .card{
          border: 1px solid #c0bcbc;
          padding-top: 0 !important;
        }
        .rdt_TableCol:nth-child(1),
        .rdt_TableCell:nth-child(1){
          min-width: 125px;
          max-width: 125px;
        }
        .rdt_TableCol:nth-child(10),
        .rdt_TableCell:nth-child(10){
          min-width: 250px;
          max-width: 250px;
        }
        .rdt_TableCell:nth-child(8),.rdt_TableCell:nth-child(10){
          justify-content: left;
        }
        
        .rdt_TableCell:nth-child(9){
          justify-content: left;
        }
        .rdt_TableCell:nth-child(11){
          justify-content: right;
        }
    }
    .txtTotalProceeds {
      max-width: 30%;
      min-width: 30%;
      margin-left: 70%;
      input{
        width: 100%;
        color: blue;
        text-align: right;
      }
      label{
        color: blue;
      }
      padding-right: 0;
    }

  }
  .cmmndCntnrClose {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
      padding-bottom: 0%;
      .btnClose{
        max-width: 10%;
        min-width: 10%; 
        margin-left: 45%;
        button{
          width: 100%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
