.AccountPayment-ReviewCheck,.ContractManagement-ReviewCheck{
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  h1 {
    display: none;
  }
  .grpbxCheckDetails {
    .card-header {
      display: none;
    }
    .card {
      border: none;
      // background-color: #c0bcbc; (Default colour)
      background-color: #f3f0f0;

    }
    .labelwidget-container{
      margin-bottom: 0 !important;
      padding: 0;
    }
     
    
    .grpbxDetails1{
      min-width: 60%;
      max-width: 60%;
      
      .lblRemitTo,.lblVendor,.lblLocation,.lblCheck{
        max-width: 13%;
        min-width: 13%;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .txtLienHolder{
        label{
        width: 13.25%;
        text-align: right; 
        padding-top: 0 !important;
        }
        padding-left: 0;
        textarea{
          // left: 5%;
          padding: 0;
          position: relative;
          color: blue;
          background-color: transparent;
          border: none;
          box-shadow: none;
        }
      }
     
      .lblRemitToValue,.lblVendorValue,.lblLocationValue,.lblCheckValue{
        color:blue;
        min-width: 82%;
        bottom: 7px;
        label{
          width: 100%;
          text-align: left;
        }
      }
      .lblRemitToValue2,.lblRemitToValue3,.lblRemitToValue4{
        color:blue;
        min-width: 82%;
        left: 13%;
        bottom: 7px;
      }
    }
    .grpbxDetails2{
      max-width: 25%;
      margin-left: 15%;
      padding-top: 0.6rem;
      .lblSpecialType,.lblInterestAgree,.lblStatus,.lblProceeds{
        max-width: 32%;
        max-height: 20px;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .lblSpecialTypeValue,.lblInterestAgreeValue,.lblStatusValue,.lblProceedsValue{
        color:blue;
        min-width: 68%;
        bottom: 7px;
        label{
          width: 100%;
          text-align: left;
        }
      }
      .lblInterestAgree{
        label{
          max-width: 140%;
          min-width: 112%;
          position: relative;
          right: 9px;
        }
      }
    }
    .grpbxDetails3{
      min-width: 42%;
      max-width: 42%;
      padding-right: 0;
      margin-top: 1rem;
      .lblVoidUser,.lblVoidDate,.lblClearDate,.lblReconDate,.lblIssueUser,.lblIssueDate{
        max-height: 20px;
        max-width: 18%;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .lblIssueDateValue,.lblIssueUserValue,.lbllblReconDateValue,.lblClearDateValue,.lblVoidDateValue,.lblVoidUserValue{
        color:blue;
        min-width: 82%;
        max-height: 20px;
        bottom: 7px;
        label{
          width: 100%;
          text-align: left;
        }
      
      }
    }
    .grpbxdetails4{
      max-width: 33%;
      margin-top: 1rem;
     padding-left: 0;
  
      .lblAcknowledgement,.lblWirePaymentID,.lblWireFileID,.lblWireData{
        max-width: 36%;
        max-height: 20px;
        label{
          width: 120px;
          min-width: 115px;
          // width: 100%;
          // min-width: 120%;
          text-align: right;
        }
      
      }
      .lblWireDataValue,.lblWireFileIDValue,.lblWirePaymentIDValue,.lblAcknowledgementValue{
        color:blue;
        min-width: 64%;
        max-height: 20px;
        bottom: 7px;
        label{
          width: 100%;
          text-align: left;
        }
      
      }
      .lblWireDataValue{
        .col{
          div{
            max-width: 130%;
            min-width: 160%;
          }
        }
      }
      .btnAuditHistory {
        margin-left: 7.5%;
        max-width: 35%;
      }
    }
    .grpbxDetails5{
      .lblFederalReserveRef,.lblWellsFargoRef,.lblWireErrorMsg{
        max-width: 12%;
        max-height: 20px;
        label{
          width: 100%;
          // min-width: 120%;
          text-align: right;
        }
      }
      .lblFederalReserveRefValue,.lblWellsFargoRefValue,.lblWireErrorMsgValue{
        min-width: 88%;
        max-height: 20px;
        color:blue;
        bottom: 7px;
        label{
          width: 100%;
          text-align: left;
        }
      }
    }
  }
  .grpbxPaymentActivityRemarks {
    .card-header {
      display: none;
    }
    .card {
      border: none;
      // background-color: #c0bcbc; (Default colour)
      background-color: #f3f0f0;
    }
    .gridPaymentActivity {
      .card-header {
        display: none;
      }
       .rdt_TableCell:nth-child(6)
       {
         justify-content:right;
       }
      // .rdt_TableBody{
      //   overflow-y: auto;
      //   max-height: 200px;
      // }
      .activeRow{
        background-color: transparent !important;
        color: black !important;
      }
    }
    .txtarRemarks {
      // padding: 0;
      .d-flex {
        display: grid !important;
      }
      .col{
        padding: 0;
      }
    }
  }
  .cmmndCntnrActions {
   
      .row{
        background-color: #f3f0f0;
        padding-top: 0.25rem;
      }
      button {
        width: 90%;
        height: 55px;
      }
    .btnCorrectCheck {
      max-width: 8%;
      margin-left: 2%;
      margin-right: 1%;
      button{
        width: 100%;
      }
      
    }
    .btnVoid,
    .btnAcctDist,
    .btnPrintStub,
    .btnClose {
      max-width: 8%;
      margin-right: 1%;
      button{
        width: 100%;
      }
    }
    .btnDeductuionTracking,
    .btnResendWireConfirmation {
      max-width: 10%;
      margin-right: 1%;
      button{
        width: 100%;
      }
    }
    // .btnPrinter{
    //   max-width: 4%;
    // min-width: 30%;
    //   button {
    //     width: 15%;
    //     // height: 62px;
    //   }
    // }
    .btnClose{
      margin-left: auto;
      margin-right: 2.5%;
    }
    
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
