.AccountPayment-ReviewCheckAuditDetail,.ContractManagement-ReviewCheckAuditDetail {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-image: url(../../../../assets/img/BgLogo.png);
.cmmndCntnrClose{
  .row{
    justify-content: center;
    background-color: #f3f0f0;
  }
}
.card{
  .card-header{
    display: none;
  }
}
.gridCheckAuditDetails{
  padding-top: 0.3%
}
  /*END_USER_CODE_FOR_.SCSS*/
}
