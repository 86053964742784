.autocompletewidget-container{
    .icon-container{
        position: relative;
    }
    .fa-caret-down{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
    button.close.rbt-close {
        right: 10px;
        position: relative;
    }
}