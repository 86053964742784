.WarehouseReceipts-WarehouseReceiptInquiry,.ContractManagement-WarehouseReceiptInquiry {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxWarehouseReceiptInquiry {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .grpbxDetails1 {
      max-width: 40%;
      min-width: 40%;
      padding: 0;
      label{
        width: 90px;
        text-align: right;
      }
      .textboxwidget-container{
        max-width: 45%;
        min-width: 45%;
        padding-right: 0;
      }
      .btnVendor {
        max-width: 7%;
        min-width: 7%;
        margin-bottom: 0.3rem !important;
      }
      .txtVendr{
        max-width: 70%;
        min-width: 48%;
        padding: 0;
        input{
          color: blue;
          background: transparent;
          border: none;
          box-shadow: none;
        }
      }
      .chkboxWireXferData {
        max-width: 90px;
        padding: 0;
        bottom: 5px;
        .col{
          padding: 0;
          input{
            bottom: 2px;
          }
          .form-check-label{
            width: 85px;
          }
        }
      }
      .txtWireXfer {
        max-width: 70%;
        min-width: 70%;
        z-index: 2;
        border: none;
        .col{
          padding-right: 0;
        }
        input{
          padding-right: 0;
          border: none;
          padding-right: 0;
          padding-left: 10px;
          max-height: 31px;
        }
        input:focus{
          border: none;
          box-shadow: none;
        }
      }
      .ddWireXferData{
        max-width: 94%;
        min-width: 76%;
        // padding-left: 105px;
        padding-right: 0;
        bottom: 34px;
        left:18%;
        z-index: 1;
        .col{
          padding-right: 0;
        }
        .dropdown {
          background-color: #fff;
        }
        button {
          background: transparent;
          background-image: none;
          background-color:#e3e3e3;
          border: none;
        }
        .dropdown-menu.show{
          z-index: 1;
        }
      }
    }
    .grpbxDetails2{
      max-width: 30%;
      min-width: 30%;
      padding: 0;
      .dropdownfieldwidget-container,.textboxwidget-container{
        padding: 0;
      }
      label{
        width: 67px;
        text-align: right;
      }
      .ddGeneration{
        min-width: 60%;
        max-width: 60%;
      }
      .ddSeed{
        min-width: 40%;
        max-width: 40%;
        label{
          width: 40px;
        }
      }
      .ddOleic{
        min-width: 55%;
        max-width: 55%;
      }
      .ddOrganic{
        min-width: 45%;
        max-width: 45%;
        label{
          width: 45px;
        }
      }
      .ddSeg{
        button{
          width:63%;
        }
      }
    }
    .grpbxDetails3{
      max-width: 30%;
      min-width: 30%;
      padding: 0;
      .dropdownfieldwidget-container,.textboxwidget-container,.datewidget-container{
        padding: 0;
      }
      label{
        width: 97px;
        text-align: right;
      }
      .datewidget-container{
        .col{
          input{
            min-width: 74%;
            max-width: 74%;
          }
          .input-group-text{
            padding: 0.3rem;
          }
        }
      }
      [class*="DateFrom"]{
        max-width: 62.5%;
        .col{
          padding-right: 0;
        }
      }
      [class*="DateTo"]{
        min-width: 37.5%;
        label{
          width: 5px;
          left: 6px;
          position: relative;
        }
      }
      .txtLoanNum1 {
        max-width: 45%;
        min-width: 45%;
      }
      .txtLoanNum2 {
        max-width: 15%;
        .col{
          padding: 0;
        }
      }
      .txtLoanNum3 {
        max-width: 30%;
      }
      .ddCCC500 {
        .dropdown{
          width: 60%;
        }
      }
    }
    .chkboxAllFSA{
      max-width: 3%;
      min-width: 3%;
      padding-right: 0;
      padding-bottom: 15px;
      .d-flex{
        display: grid !important;
      }
      .form-check{
        label{
          display: none;
        }
      }
    }
    .grpbxWhsRcptList {
      padding: 0;
      .lblWhsRcptList {
        max-width: 8%;
        min-width: 8%;
        padding-right: 0;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .grpbxFSADocument {
        max-width: 30%;
        min-width: 30%;
        padding: 0;
        border: 1px solid #ccc;
        .lblFSADocument {
          min-width: 100%;
          margin-bottom: 0 !important;
          label{
            margin-bottom: 0 !important;
            position: relative;
            bottom: 3px;
          }
        }
        .buttonwidget-container{
          min-width: 32%;
          max-width: 32%;
          margin-right: 1.33%;
          button{
            width: 100%;
          }
        }
      }
      .btnSearch{
        min-width: 6%;
        max-width: 6%;
        margin-left: 1%;
        button{
          width: 90%;
        }
      }
      .btnExtRcptForfeiture {
        min-width: 16%;
        max-width: 16%;
        button{
          width: 100%;
        }
      }
      .btnCreateWarehouseReceipt {
        min-width: 16%;
        max-width: 16%;
        button{
          width: 90%;
        }
      }
      .lblCropYear{
        min-width: 13%;
        max-width: 13%;
        font-weight: 500;
        color: red;  
        font-size: 16px;
        .col{
          padding-left: 0;
          div{
            top: 5px;
            position: relative;
          }
        }
        .d-flex {
          background-color: lightgray;
        }
      }
      .btnOpen{
        min-width: 7%;
        max-width: 7%;
        button{
          width: 90%;
        }
      }
      .gridWarehouseReceiptList {
        .popover-body{
          button:nth-child(1){
            display: none;
          }
        }
        min-width: 100%;
        .card-header{
          display: block;
        }
        .rdt_TableRow{
          min-height: 35px !important;
        }
        .rdt_ExpanderRow{
          .py-3{
            background-color: #e7f9ff;
            padding-bottom: 0rem !important;
            padding-top: 0rem !important;
          }}
          .expandIconSpc{
            min-width: 47px;
            max-width: 47px;
          }
        
          .extraRowCell_1,.extraRowCell_2,.extraRowCell_3,.extraRowCell_4,.extraRowCell_5,.extraRowCell_6,.extraRowCell_7,.extraRowCell_8,.extraRowCell_9,.extraRowCell_10,.extraRowCell_11,.extraRowCell_12,.extraRowCell_13,.extraRowCell_14,.extraRowCell_15,.extraRowCell_16,.extraRowCell_17,.extraRowCell_18,.extraRowCell_19,.extraRowCell_20,.extraRowCell_21,.extraRowCell_22,.extraRowCell_23,.extraRowCell_24,.extraRowCell_25,.extraRowCell_26,.extraRowCell_27,.extraRowCell_28,.extraRowCell_29,.extraRowCell_30,.extraRowCell_31,.extraRowCell_32,.extraRowCell_33,.extraRowCell_34{
          min-width: 275px;
          max-width: 275px;
          width: 200px;
          label{
            text-align: center;
            width: 100%;
          }
        }
        .rdt_TableCol,.rdt_TableCell{
          min-width: 275px;
          max-width: 275px;
        }
        
      }
      div[id^="cell-txtcolNetWt"], div[id^="cell-txtcolShrunkNetWt"], div[id^="cell-txtcolValue"],div[id^="cell-txtcolNetRepay"]{
        justify-content: end;
      }
      #txtTotalNetWt, #txtTotalShrunkNetWt, #txtTotalValue, #txtTotalNetRepay{
        text-align: end;
      }
      .textboxwidget-container{
        max-width: 22%;
        min-width: 22%;
        padding-right: 0;
        label{
          width: 110px;
          text-align: right;
        }
      }
      .txtTotalNetWt {
        margin-left: 12%;
      }
    }
  }
  .grpbxActions {
    .grpbxBtnCntnr {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0.5rem;
      min-width: 90%;
      max-width: 90%;
      .btnViewEWRLog ,.btnUploadLockIn,.btnClearRPS ,.btnClose,.btnCancel,.btnExportToExcel,.btnPrintSearch{
        max-width: 12%;
        min-width: 12%;
        button{
          width: 90%;
        }
      }
      .btnResetToEWRAccepted,.btnRedeemByLoan  {
        max-width: 17%;
        min-width: 17%;
        button{
          width: 90%;
        }
      }
      .btnExportToExcel{
        margin-left: 23%;
      }
      .btnExportToExcel,.btnPrintSearch{
        position: relative;
        top: 10px;
      }
    }
   
    .radioOptPrint {
      max-width: 10%;
      min-width: 10%;
      padding: 0;
      margin-bottom: 0.5rem !important;
      .col{
        input{
          bottom: 1px;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
