.SystemMaintenanceSpecialFunctions-LoanInterestProfile,.ContractManagement-LoanInterestProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbcLoanInterestProfile {
    margin-bottom: 0.2rem !important;
    .card{
      padding-top: 0.2rem;
      border: none;
      height: 200px;
      background-color: #c0bcbc;
      .card-header{
        display:none;
      }
      label{
        width: 125px;
        text-align: right;
      }
      .row{
        position: relative;
        top: 60px;
      }
      .txtEffectivedate{
        min-width: 100%;
        .input-group{
          width: 40%;
        }
        label{
          color: rgb(224, 15, 15);
        }
      }
      .txtEffectivedate{
        .dropdown-menu{
         top: -125px !important;
         left: 370px !important;
        }
      }
      .txtInterestRate {
        min-width: 100%;
        input{
          width: 25%;
        }
        label{
          color: rgb(224, 15, 15);
        }
      }
    }
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 8%;
      padding: 0;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 77%;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 7px;
      }
    }
    .btnOk,.btnCancel{
       max-width: 7.5%;
       min-width: 7.5%;
       top: 10px;
       button{
         width: 90%;
       }
    }
  }
  
  /*END_USER_CODE_FOR_.SCSS*/
}
