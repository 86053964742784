.StockTransfer-PremiumDeductionsFrtAccrl,.ContractManagement-PremiumDeductionsFrtAccrl {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxPremiumDeductionsFrtAccrl {
    .grpbxDetails1 {
      max-width: 50%;
      .textboxwidget-container{
        label{
          width: 100px;
          text-align: right;
        }
        input{
          width: 50%;
        }
      }
      .txtShippedDate,.txtCarrier{
       input{
         text-align: center;
       }
      }
      .txtMiles,.txtFuelPct,.txtTrailerLease{
        input{
          text-align: right;
        }
      }
    }
    .grpbxDetails2{
      max-width: 50%;
      .txtRatingOption {
        min-height: 100%;
        label{
          width: 100px;
          text-align: right;
        }
        input{
          text-align: center;
        }
      }
      .txtFreightWeight,.txtFreightRate ,.txtFreightAmount  {
        min-width: 50%;
        max-width: 50%;
        // padding-left: 0;
        padding-right: 0;
        label{
          width: 100px;
          text-align: right;
        }
        input{
          text-align: right;
        }
      }
      .lblLbsPer,.lblTons,.lblPerTon {
        max-width: 5%;
        min-width: 5%;
        padding-left: 0;
        padding-right: 0;
      }
      .txtTons{
        min-width: 25%;
        max-width: 25%;
        padding-left: 0;
        padding-right: 0;
        input{
          text-align: right;
        }
      }

    }
    .grpbxPremsDeducts{
      padding-top: 1rem;
      
      .lblPremDeduct {
        label{
          margin-bottom: 0;
        }
      }
      .txtAccrualAmount {
        max-width: 30%;
        min-width: 30%;
        margin-left: 70%;
        .col{
          padding-right: 0;
        }
      }
      .gridPremsDeducts{
        .card-header{
          display: block;
        }
      }
    }

    .cmmndcntnrActions {
      background-color:  #f3f0f0;
      padding-top: 1rem;
      padding-right: 0;
      .buttonwidget-container{
        min-width: 20%;
        max-width: 20%;
        button{
          // width: 90%;
        }
      }
      .btnComplete,.btnCancel,.btmViewTransferDetail ,.btnReverseFrtAccrl {
        button{
          // height: 70px;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
