.SystemMaintenanceSpecialFunctions-PriceScheduleAuditDetail,.ContractManagement-PriceScheduleAuditDetail {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
   display:none
 }
//  background-color: #333E48;
background: transparent;
 .grpbxPriceScheduleAuditDetail {
   .card{
     padding-top: 0.2rem !important;
     border: none;
     background-color: #c0bcbc;
     .card-header{
       display:none
     }
     .textboxwidget-container{
       padding-right: 0;
     }
     .txtAction {
       min-width: 20%;
       max-width: 20%;
       input{
         width: 80%;
       }
     }
     .txtRevision{
       min-width: 20%;
       max-width: 20%;
       input{
         width: 60%;
       }
     }
     .txtChangeUser{
       min-width: 25%;
       max-width: 25%;
       input{
         width: 80%;
       }
     }
     .txtChangeDateTime{
       min-width: 35%;
       max-width: 35%;
       input{
         width: 100%;
       }
     }
     .gridPriceScheduleAuditDetail {
       min-width: 100%;
       label{
         display: none;
       }
       .card{
         border: 1px solid #f3f0f0;
         padding-top: 0 !important;
       }
       .rdt_TableRow{
        border-bottom: none !important;
        .rdt_TableCell{
            border-bottom: 1px solid rgba(0,0,0,.12);
        }
        // .rdt_TableCell:nth-child(2){
        //     background-color: rgba(0,0,0,.12);
        // }
     }
     .rdt_TableRow:nth-child(odd){
      .rdt_TableCell:nth-child(1),
      .rdt_TableCell:nth-child(2){
        border-bottom: none !important;
      }
     }
       .rdt_TableCol:nth-child(1),
       .rdt_TableCell:nth-child(1){
          background-color: #c0bcbc;
          max-width: 60px;
          min-width: 60px;
       }
       .rdt_TableCol:nth-child(2),
       .rdt_TableCell:nth-child(2){
        max-width: 50px;
        min-width: 50px;
        background-color: #c0bcbc;
       }
       .rdt_TableCol:nth-child(3),
       .rdt_TableCell:nth-child(3){
        max-width: 60px;
        min-width: 60px;
          background-color: #c0bcbc;
       }
       .rdt_TableCol:nth-child(4),
       .rdt_TableCell:nth-child(4){
        max-width: 80px;
        min-width: 80px;
       }
       .rdt_TableCol:nth-child(7),
       .rdt_TableCell:nth-child(7){
        max-width: 80px;
        min-width: 80px;
       }
       .rdt_TableCol:nth-child(12),
       .rdt_TableCell:nth-child(12){
        max-width: 80px;
        min-width: 80px;
       }
       .rdt_TableCol:nth-child(11),
       .rdt_TableCell:nth-child(11){
        max-width: 80px;
        min-width: 80px;
       }
       .rdt_TableCol:nth-child(6),
       .rdt_TableCell:nth-child(6){
        max-width: 80px;
        min-width: 80px;
       }
       .rdt_TableCol:nth-child(5),
       .rdt_TableCell:nth-child(5){
        max-width: 80px;
        min-width: 80px;
       }
       .rdt_TableCol:nth-child(9),
       .rdt_TableCell:nth-child(9){
        max-width: 80px;
        min-width: 80px;
       }
       .rdt_TableCol:nth-child(14),
       .rdt_TableCell:nth-child(14){
        max-width: 90px;
        min-width: 90px;
       }
       .rdt_TableCol:nth-child(10),
       .rdt_TableCell:nth-child(10){
        max-width: 80px;
        min-width: 80px;
       }
       .rdt_TableCell:nth-child(3){
        color: #3f00ff;
        font-weight: 700;
     }
     .rdt_TableCell:nth-child(1),.rdt_TableCell:nth-child(2){
      color: #080708;
   }
     }
   }
 }
 
 .cmmndCntnrClose{
   .row{
     background-color: #f3f0f0;
     padding-top: 0.2rem;
   }
   .lblNote{
    max-width: 36%;
    min-width: 36%;
    font-size: small;
    label{
      color: #094be1;
      font-weight: bold;
    }
   }
   .btnClose{
     max-width: 10%;
     min-width: 10%;
     margin-left: 10%;
     button{
       width: 100%;
     }
   }
 }
 /*END_USER_CODE_FOR_.SCSS*/
 }