.ContractManagement-TradeSettlePaymentView,.Settlements-TradeSettlePaymentView {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
  }
  // .GridWidget {
  //   .collapse {
  //     >div {
  //       height: 250px;
  //     }
  //   }
  // }
  .card-header,
  h1 {
    display: none;
  }
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .grpbxViewPayment {
    .card {
      background-color: #c0bcbc;
    }
    .gridPaymentView {
      .card-header {
        display: none;
      }
    }
    .txtPayments {
      min-width: 50%;
      label {
        width: 267px;
      }
    }
  }
  .grpbxviewPaymentButtons {
    .card {
      background-color: #f3f0f0;
    }
  }
  .btnClose {
    max-width: 7%;
    left: 45%;
  }

  //   // .btnSetting {
  //    min-width: 40%;
  //   // }
  //   .btnClose {
  //     min-width: 43%;
  //     //left:44%;
  //   }
    .btnExportExcel {
      max-width: 12%;
    left: 66%;
    }
    .btnPrint {
      max-width: 6%;
      left: 85%;
    }
  // }
  /*END_USER_CODE_FOR_.SCSS*/
}
