.SystemMaintenanceSpecialFunctions-CompanyProfile,.ContractManagement-CompanyProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .cmmndCntnrClose{
  .row{
    background-color: #f3f0f0;
    }
  }
  .grpbxCompanyProfile{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card{
      background-color: #c0bcbc;
      border: none;
    }
    
    .card-header{
      display: none;
    }
  }
  .grpbxCompanyDetails,.grpbxWireTransferData{
    
    label{
      width: 95px;
      text-align: right;
    }
    .txtCompanyID{
      input{
        max-width: 25%;
      }
      label{
        color: rgb(224, 15, 15);
      }
    }
    .txtADMComapnyID{
      label{
        width: 70%;
      }
    }
    .chkboxAdmInterface{
      label{
        display: none;
      }
      .form-check{
        label{
          display: block;
        }
      }
    }
    
    .txtComapnyName {
      input{
        max-width: 50%;
      }
      label{
        color: rgb(224, 15, 15);
      }
    }
    .txtlZip,.ddState,.txtZip1,.ddState1,.txtZip2,.ddState2{
      label{
        width: 35px;
      
      }
    }
    .txtCity,.txtCity1,.txtCity2{
      min-width: 50%;
    }
    .ddState,.ddState1,.ddState2{
      max-width: 30%;
    }
    .txtlZip,.txtZip1,.txtZip2{
      max-width: 20%;
    }
    .txtPhone {
      input{
        max-width: 25%;
      }
    }
    .txtEWRID{
      input{
        max-width: 18.5%;
      }
    }
    .txtVendor{
      input{
        max-width: 14%;
      }
    }
    .txtSortOrder{
      input{
        max-width: 7%;
      }
    }
  }
  .grpbxWireTransferData{
    
    .txtLastFile,.txtPayerName,.txtAddress1,.txtPayerBank,.txtAddress2,.txtAddress12{
      input{
        max-width: 70%;
      }
    }
    .lblWireTransferData{
      font-weight: bolder;
      label{
        width: 100%;
        text-align: left;
      }
    }
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 8%;
      padding: 0;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 77%;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 7px;
      }
    }
    .btnOk,.btnCancel{
       max-width: 7.5%;
       min-width: 7.5%;
       top: 10px;
       button{
         width: 90%;
       }
    }
  }
  
  /*END_USER_CODE_FOR_.SCSS*/
}
