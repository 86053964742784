.ContractManagement-TradeSettleApply ,.Settlements-TradeSettleApply {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
  }
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
  .grpbxTradeAgrmntApp {
    .card {
      background-color: #c0bcbc;
    }
    .gridAgreementApplication {
      .card-header {
        display: block;
      }
      .btn.btn-link {
        color: black;
      }
    }
    .txtTradeInspect,
    .txtPurchaseSale,
    .txtPeanutType,
    .txtNetWeight,
    .txtNetWeight,
    .txtValue {
      max-width: 11.5%;
      text-align: center;
      input {
        background-color: #c0bcbc;
      }
    }

    .txtSeg,.txtSeedYN{
      max-width:7%;
      min-width:3%;
      text-align: center;
      input {
        background-color: #c0bcbc;
      }
    }
    .txt1stVendorName {
      min-width: 27%;
      text-align: center;
      input {
        background-color: #c0bcbc;
      }
    }
    .txtLoanRepaymentRate {
      label {
        font-weight: 700;
      }
      input {
        max-width: 13%;
      }
    }
  }
  .cmmndCntnrActions {
    min-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    .row{
      // justify-content: end;
      padding-top: 0.25rem;
      background-color: #f3f0f0;
    }
    .btnCancel,
    .btnOk {
      max-width: 10%;
      button{
        width: 90%;
      }  
    }
    .btnOk{
      margin-left: 70%;
    }
   
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
