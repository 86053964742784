.WarehouseReceipts-WhouseReceiptStorage,.ContractManagement-WhouseReceiptStorage {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxWhouseReceiptStorage {
    .grpbxDetails {
      padding-top: 0.25rem;
      .card{
        border: 1px solid #ccc;
        .lblDetails {
          min-width: 100%;
          label{
            font-weight: bold;
          }
        }
        .txtCompID,.txtBuyPtID  {
          min-width: 25%;
          max-width: 25%;
        }
        .txtCropYear {
          min-width: 20%;
          max-width: 20%;
        }
        .txtWhouseRcptNum {
          max-width: 30%;
          min-width: 30%;
        }
      }
    }
    .grpbxAddRemoveDetails {
      padding-top: 0.25rem;
      .card{
        border: 1px solid #ccc;
        .lblAddRemoveDetails {
          min-width: 100%;
          label{
            font-weight: bold;
          }
        }
        .cmmndCntnrEditDelete {
          padding-top: 0.5rem;
          .btnAdd{
            max-width: 10%;
            min-width: 10%;
            margin-left: 80%;
            button{
              width: 90%;
            }
          }
          .btnDelete{
            max-width: 10%;
            min-width: 10%;
            button{
              width: 90%;
            }
          }
        }
        
      }
    }
    .grpbxStrgDetails {
      padding-top: 0.25rem;
      .gridStrgDetails {
        min-width: 100%;
        padding: 0;
        .rdt_TableCell{
          justify-content: end !important;
        }
      }
      .txtTotalDays {
        max-width: 20%;
        min-width: 20%;
        margin-left: 40%;
        input{
          box-shadow: 1px 1px 0px gray inset;
          background-color: #f3f0f0;
          text-align: right;
        }
      }
      .txtTotalAmt {
        max-width: 20%;
        min-block-size: 20%;
        margin-left: 20%;
        input{
          box-shadow: 1px 1px 0px gray inset;
          background-color: #f3f0f0;
          text-align: right;
        }
      }
    }
  }
  .cmmndCntnrClose{
    .row{
      background-color: #f3f0f0;
      padding-top: 0.25rem;
      .btnClose{
        max-width: 10%;
        min-width: 10%;
        margin-left: 90%;
        button{
          width: 90%;
        }
      }
    }
    
  }
  /*END_USER_CODE_FOR_.SCSS*/
}