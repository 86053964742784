.WarehouseReceipts-1007GrpSelect,.ContractManagement-1007GrpSelect{
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333e48;
  background: transparent;
  h1 {
    display: none;
  }
  .card {
    background-color: #f3f0f0;
    border: none;
  }
  .card-header{
    display: none;
  }
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
    }
  .grpbxDetails1{
    label{
      width: 65px;
      text-align: right;
    }
    .txtVendorNum{
      min-width: 70%;
    }
    .btnVendor{
      max-width: 20%;
    }
    .txtVendor{
      max-width: 70%;
      left: 65px;
      .col{
        input{
          padding-left: 0;
          border: none;
          box-shadow: none;
          background: transparent;
          color:blue;
        }
      }
    }
    .ddLocation {
      padding-right: 0;
      .col{
        padding-right: 0;
        button{
          padding-right: 0.2rem;
        }
      }
    }
    .ddFarmNum,.ddCounty,.ddState{
      .dropdown{
        width: 80%;
      }
    }
        .ddFarmNum {
          #ddItem_ {
            height: 25px;
          }
    
          .dropdown-toggle:empty::after {
            margin-left: auto;
          }
    
          button {
            height: 30px;
          }
        }
  }
  .grpbxDetails2{
    label{
      width: 80px;
      text-align: right;
    }
    .radioAppliedTo{
      label{
        width: 100%;
        text-align: left;
      }
    }
    .lblGoupingNumber{
      label{
      display: none;
      }
    }
    .btnSearch{
      .row{
        justify-content: center;
      }
    }
  }
  .grpbxAvailable1007s{
    .lblAvailable1007s{
      max-width: 15%;
      font-weight: 700;
    }
    .txtTotalNetWeight ,.txtTotalValue {
      min-width: 20%;
      max-width: 20%;
      input{
        background: transparent;
        border: none;
        box-shadow: none;
      }
      .col{
        position: relative;
        bottom: 3px;
      }
    }
    .txtTotalNetWeight{
      margin-left: 60%;
    }
      .gridAvailable1007s{
      .card-header{
        display: block;
      }
      .rdt_TableCol:nth-child(1),.rdt_TableCol:nth-child(2),.rdt_TableCol:nth-child(10),
      .rdt_TableCol:nth-child(3),.rdt_TableCol:nth-child(11),
      .rdt_TableCol:nth-child(4),.rdt_TableCol:nth-child(12),
      .rdt_TableCol:nth-child(5),.rdt_TableCol:nth-child(13),
      .rdt_TableCol:nth-child(6),.rdt_TableCol:nth-child(14),
      .rdt_TableCol:nth-child(7),.rdt_TableCol:nth-child(15),
      .rdt_TableCol:nth-child(8),.rdt_TableCol:nth-child(16),
      .rdt_TableCol:nth-child(9),.rdt_TableCol:nth-child(17),
      .rdt_TableCol:nth-child(18),.rdt_TableCol:nth-child(19),
      .rdt_TableCol:nth-child(20),.rdt_TableCol:nth-child(21),
      .rdt_TableCol:nth-child(22),.rdt_TableCol:nth-child(23),
      .rdt_TableCol:nth-child(24)
      {
        position: relative;
        top: 15px;
      }
      .rdt_TableCell:nth-child(2),.rdt_TableCol:nth-child(2){
        max-width: 60px;
        min-width: 60px;
      }
      .rdt_TableCell:nth-child(4),.rdt_TableCol:nth-child(4){
        max-width: 110px;
        min-width: 110px;
      }
            .btn-primary{
              text-shadow: 0 0 black;
              background-image: linear-gradient(#e00f0f, red 60%, #e00f0f);
              box-shadow: 1px 1px 0px grey;
              // font-size: 0.75rem;
              // color: white;
              // background-color: #bbbbbb;
            }
            .popover-body{
              button:nth-child(1){
                display: none;
              }
            }
            .rdt_TableCell:nth-child(13),
            .rdt_TableCell:nth-child(14) {
              justify-content: right !important;
            }
    }
  }
  .grpbxActions{
    .btnGroupAndPrint,.btnReprintGrouping{
      max-width: 11%;
      margin-bottom: 0.3rem !important;
    }
    .ddHolderID{
      padding-left: 0;
      min-width: 20%;
      .d-flex{
        display: grid !important;
        .col{
          padding: 0;
        }
      }
    }
    .txtNumber {
      padding-left: 0.5rem;
    }
    .grpbxchkbox{
      padding: 0;
      max-width: 19%;
      .card{
        // top: 17px;
        .chkboxCMA ,.chkboxDMA {
          max-width: 50%;
          min-width: 50%
        }
        .chkboxDeferred {
          max-width: 100%;
          min-width: 100%
        }
      }
    }
    // .chkboxCmaDmaDefRed{
    //   min-width: 21%;
    //   .mb-3{
    //     display: flex;
    //     flex-wrap: wrap;
    //     .form-check:nth-child(1),
    //     .form-check:nth-child(2){
    //       width: 50%;
    //     }
    //     .form-check:nth-child(3){
    //       width: 100%;
    //     }
    //   }
    // }
    .lblCropYear{
      max-width: 15%;
      font-weight: 600;
      font-size: 16px;
      color: red;
      padding-bottom: 15px
    }
    .btnCancel{
      max-width: 7%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
