.SystemMaintenanceMasterManagement-PeanutSpecificationProfile,.ContractManagement-PeanutSpecificationProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxPeanutSpecificationProfile {
    .card-header{
      display:none
    }
    // margin-top: 1rem;
    padding-bottom: 0;
    .card{
      border: none;
      background-color: #c0bcbc;
    }
    
    .lblSeed {
      max-width: 180px;
      min-width: 180px;
      margin-left: 25%;
      label{
        text-align: right;
        width: 100%;
      }
    }
    .chkboxSeed{
      padding-left: 0;
      margin-bottom: 0.5%;
        .mb-3{
          margin-bottom: 0.5rem !important;

        }
        .col {
          padding-left: 0;
        }
    }
    .ddPeanutType,.ddPeanutVariety ,.ddSegmentType ,.ddEdibleOilIndicator,.txtArea {
      max-width: 50%;
      margin-left: 25%;
      label{
        width: 150px;
        text-align: right;
      }
    }
    .ddSegmentType{
      label{
        color: rgb(224, 15, 15);
      }
    }
    .txtArea{
      max-width: 50%;
      margin-left: 25%;
      label{
        width: 150px;
        text-align: right;
      }
      input{
        border: none;
        box-shadow: none;
        background: transparent;
        color: blue;
        padding-left: 0;
      }
    }


    .grpbxFlavusIndicator {
    
      .card-header{
        display:none
      }
      padding-top: 0;
      padding-right: 0;
      .card{
        padding-top: 1rem;
        border: none;
        background-color: #c0bcbc;
        min-height: 415px;
      }
      .card-body{
        width: 70%;
        margin-left: 30%;
      }
      label{
        width: 39%;
        text-align: right;
      }
      .ddFlavusIndicator{
        padding: 0;
        label{
          width: 26.5%;
        }
        .dropdown-toggle:empty::after {
          margin-left: auto;
        }
        button{
        height:30px;
        }
      }
      
      .lblMinimum {
        max-width:65%;
        .d-flex{
          justify-content: end;
        }
        label{
          width: 53.85%;
          text-align: center;
          margin-bottom: 0;
        }
      }
      .lblMaximum{
        max-width:35%;
        label{
          width: 100%;
          text-align: center;
          margin-bottom: 0;
        }
      }
      [class*="Min"] {
        max-width: 65%;
        min-width: 65%;
        .col{
          width: 53.85%;
          padding-right: 0;
        }
      }
      [class*="Max"],.txtSMKPercentMam  {
        max-width: 35%;
        min-width: 35%;
        padding: 0;
        .col{
          padding-left: 0;
        }
      }
    }
    .grpbxCornIndicator {
      .card-header{
        display:none
      }
      padding-top: 0;
      padding-left: 0;
      .card{
        padding-top: 1rem;
        border: none;
        background-color: #c0bcbc;
        min-height: 415px;
      }
      .card-body{
        width: 70%;
        
      }
      label{
        width: 39%;
        text-align: right;
      }
      .ddCornIndicator {
        padding: 0;
        label{
          width: 26.5%; 
        }
        .dropdown-toggle:empty::after {
          margin-left: auto;
        }
        button{
        height:30px;
        }
      }
      .lblMin {
        max-width:65%;
        .d-flex{
          justify-content: end;
        }
        label{
          width: 53.85%;
          text-align: center;
          margin-bottom: 0;
        }
      }
      .lblMax{
        max-width:35%;
        label{
          width: 100%;
          text-align: center;
          margin-bottom: 0;
        }
      }
      [class*="Min"] {
        max-width: 65%;
        min-width: 65%;
        .col{
          width: 53.85%;
          padding-right: 0;
        }
      }
      [class*="Max"],.txtSMKPercentMam  {
        max-width: 35%;
        min-width: 35%;
        padding: 0;
        .col{
          padding-left: 0;
        }
      }
      } 
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 8%;
      padding: 0;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 77%;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 7px;
      }
    }
    .btnOk,.btnCancel{
       max-width: 7.5%;
       min-width: 7.5%;
       top: 10px;
       button{
         width: 90%;
       }
    }
  }

  /*END_USER_CODE_FOR_.SCSS*/
}
