.WarehouseReceipts-ViewEWRLog,.ContractManagement-ViewEWRLog {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxViewEWRLog {
    .dropdown-menu.show{
      overflow-y:scroll!important;
      max-height:25vh!important;
      }
      .card{
        min-height: 100px;
      }
    .grpbxViewEWRLogDetails {
      .card{
        border: 1px solid #ccc;

        .radioSentReceived{
          max-width: 20%;
          min-width: 20%;
          padding-left: 0;
        }
        .ddBuyingPointID {
          min-width: 40%;
          max-width: 40%;
        }
        .txtReceiptNumber {
          min-width: 30%;
          max-width: 30%;
        }
        .btnSearch {
          min-width: 10%;
          max-width: 10%;
          button{
            width: 90%;
           
          }
        }
      }
    }
    .gridEWRLog{
      .rdt_TableCol:nth-child(1),
      .rdt_TableCell:nth-child(1){
        min-width: 170px;
        max-width: 170px;
      }
      .rdt_TableCol:nth-child(2),
      .rdt_TableCell:nth-child(2){
        min-width: 100px;
        max-width: 100px;
      }
      .rdt_TableCol:nth-child(3),
      .rdt_TableCell:nth-child(3){
        min-width: 100px;
        max-width: 100px;
      }
      .rdt_TableCol:nth-child(4),
      .rdt_TableCell:nth-child(4){
        min-width: 100px;
        max-width: 100px;
      }
      .rdt_TableCol:nth-child(5),
      .rdt_TableCell:nth-child(5){
        min-width: 100px;
        max-width: 100px;
      }
      .rdt_TableCol:nth-child(6),
      .rdt_TableCell:nth-child(6){
        min-width: 100px;
        max-width: 100px;
      }
      .rdt_TableCell:nth-child(1),
      .rdt_TableCell:nth-child(7){
        justify-content: left !important;
      }
      .rdt_TableCol:nth-child(1),
      .rdt_TableCol:nth-child(2), .rdt_TableCol:nth-child(3),
      .rdt_TableCol:nth-child(4), .rdt_TableCol:nth-child(6),
      .rdt_TableCol:nth-child(7),.rdt_TableCol:nth-child(5){
        position: relative;
        top: 15px;
      }
    }
    .cmmndCntnrActions {
      .buttonwidget-container{
        min-width: 15%;
        max-width: 15%;
        button{
          width: 95%;
        }
      }
      .btnClose{
        margin-left: 12.5%;
        margin-right: 12.5%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
