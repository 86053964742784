.SystemMaintenanceSpecialFunctions-BankHolidayProfile,.ContractManagement-BankHolidayProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxBankHolidayProfile {
    .card-header{
      display:none
    }
    .card-body{
      margin-bottom: 80px;
    }
    .row{
      position: relative;
      top: 40px;
    }
    .card{
      border: none;
      background-color: #c0bcbc; 
      padding-top: 0.2rem;
      label{
        width: 100px;
        text-align: right;
        color: #c02020;
      }
      .dpHolidayDate {
        min-width: 100%;
        .input-group{
          width: 30%;
        }
        .dropdown-menu{
          top: -125px !important;
          left: 300px !important;
         }
      }
      .txtHolidayName {
        min-width: 100%;
        input{
          width: 80%;
        }
      }
    }
  }
  .cmmndCntnrActions {
    .row{
      padding-top: 0.2rem;
      background-color: #f3f0f0;
      justify-content: center;
      .btnAdd,.btnClose{
        min-width: 10%;
        max-width: 10%;
        .row{
          justify-content: center;
          button{
            width: 90%;
          }
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
