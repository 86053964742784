.ContractManagement-ApplicationToContract {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  h1 {
    display: none !important;
  }
  background-color: #f3f0f0;
  .card-header {
    display: none;
  }
  .card {
    background-color: #aaa2a2;
  }
  .gridAppToContrct {
    .card {
      background-color: #c0bcbc;
    }
    padding-top: 8px;
    .rdt_TableHeadRow {
      background-color: lightgray;
    }
    .rdt_TableCol {
      border: solid rgba(#777474, 0.1);
    }
    
  }
  .grpbxCnclExprt {
    .card {
      border: none !important;
      background-color: #f3f0f0;
    }
    top: 37px;
    padding-left: 0;
    padding-right: 0;
  }
  .btnExportToExcel{
    max-width: 9%;
    margin-left: 14%;
  }
  .btnPrintSearch{
    max-width: 9%;
  }
  .btnCancel{
    left: 12%;
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
