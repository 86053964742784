.AccountPayment-CheckDetails,.ContractManagement-CheckDetails {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .grpbxCheckDetails   {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #c0bcbc;
    }
    .lblCheckInformation {
    min-width: 100%;
    label{
      width: 100%;
      text-align: center;
      font-weight: bold;
    }
    }
    .ddRemitTo,.txtLienholders{
      label{
        color: red;
        width: 70px;
        text-align: right;
      }
    }
    .lblBatchedReadyToPays,.lblRemarks{
      label{
        margin-bottom: 0;
      }
    }
    .txtarRemarks{
      padding-right: 0;
      padding-left: 0;
      .col{
        // padding: 0;
        height: 80px;
      }
      textarea{
        height: 100%;
      }
    }
  }

  .cmmndCntnrActions  {
    .row{
      border: none;
      background-color:  #f3f0f0;
      padding-top: 0.3rem;
    }
    .btnCancel,.btnOk{
      min-width: 10%;
      max-width: 10%;
      button{
        width: 90%;
      }
    }
    .btnUpdateRemarks,.btnReturnToHoldPay{
      min-width: 15%;
      max-width: 15%;
      button{
        width: 90%;
      }
    }
    .btnUpdateRemarks{
      margin-left: auto;
    }
    .btnCancel{
      margin-right: 1%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
