.ContractManagement-FlexMktVoid {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #c0bcbc;
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
  .card {
    background-color: #f3f0f0;
  }
  .grpbxCntrctPrc {
    // padding-top: 15px;
    .lblEnterPricingCntrct {
      font-size: 18px;
      font-weight: 500;
    }
    .txtCntrctPrc,
    .lblContractPricing {
      margin-left: 25%;
      max-width: 75%;
      input {
        width: 35% !important;
      }
    }
    button {
      width: 100%;
      height: 45px;
    }
    .btnVoid {
      max-width: 13%;
      margin-right: 15px;
      margin-left: 26%;
    }
    .btnExit {
      max-width: 13%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
