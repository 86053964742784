.SystemMaintenanceMasterManagement-ContractLimitSearch,
.ContractManagement-ContractLimitSearch {

  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none
  }

  // background-color: #333E48;
  background: transparent;
  .grpbxContractLimitSearch {
    .card-header {
      display: none;
    }

    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }

    .card {
      border: none;
      background-color: #f3f0f0;
    }

    .grpbxContractDetails {

      padding: 0;

      .card {
        padding-top: 0.2rem;
        border: none;
      }

      label {
        width: 95px;
        text-align: right;
      }

      .lblPeanut {
        font-weight: bold;
      }

      .ddArea,
      .ddCollectionPoint,
      .ddBuyingPoint {
        min-width: 60%;
        max-width: 60%;

        .col {
          .dropdown {
            width: 70%;
          }
        }
      }

      .ddPricingType {
        min-width: 60%;
        max-width: 60%;

        .col {
          .dropdown {
            width: 50%;
          }
        }
      }

      .txtContract {
        max-width: 20%;
        margin-right: 40%;
        padding-right: 0;
      }

      .txtVendor {
        max-width: 20%;
        min-width: 20%;
        padding-right: 0;
      }

      .btnVendor {
        max-width: 2.5%;
        margin-bottom: 0.3rem !important;
      }

      .txtVndr {
        padding-left: 0;
        min-width: 35%;

        input {
          background-color: #f3f0f0;
          border: none;
          box-shadow: none;
          color: blue;
        }
      }

      .lblPeanut,
      .ddPeanutType,
      .ddPeanutVariety,
      .ddSeg,
      .ddOrganic,
      .ddOleic {
        max-width: 40%;

        .dropdown {
          width: 70%;
        }
      }

      .ddOleic {
        margin-left: auto;
      }

      .btnSearch {
        max-width: 10%;
        margin-left: 45%;

        button {
          width: 90%;
        }
      }
    }

    .grpbxContractsSearch {
      .gridContractLimitSearchData {
        .card-header {
          display: block;
        }
      }

      .btnNewOffer,
      .btnEdit,
      .btnDelete {
        max-width: 10%;

        button {
          width: 95%
        }
      }

      .btnNewOffer {
        margin-left: auto;
      }

      .btnPrintGrid,
      .btnExportToExcel {
        max-width: 10%;
        min-width: 10%;
        margin-left: 1%;
        bottom: 50px;

        button {
          width: 90%;
        }
      }

      .btn1 {
        margin-left: 1%;
        bottom: 50px;

        button {
          width: 2.5%;
        }

        display: none;
      }

      .btnExit {
        max-width: 7.5%;
        min-width: 7.5%;
        bottom: 20px;

        .row {
          justify-content: end;

          button {
            width: 95%;
          }
        }
      }

      // .checkboxgroupfieldwidget-container {
      //   .d-flex {
      //     display: block !important;
      //   }
      // }

      .textboxwidget-container {
        .d-flex {
          display: block !important;
        }

        min-width: 10%;
        padding: 0;
        bottom: 20px;

        label {
          width: 100%;
          text-align: center;
          margin-bottom: 0 !important;
        }

        .col {
          padding-left: 0.2rem;
          padding-right: 0.2rem;

          input {
            width: 100%;
          }
        }
      }

      .txtTotalAcresContracted {
        label {
          width: 90%;
          margin-left: 5%;
        }
      }

      .txtTonsAllocated,
      .txtTonsContracted,
      .txtOpenTons,
      .txtIrrigatedAcresContracted,
      .txtDryLandAcresContracted,
      .txtTotalAcresContracted {
        input {
          text-align: right;
        }
      }

      .gridContractLimitSearchData {

        .rdt_TableCell:nth-child(10),
        .rdt_TableCell:nth-child(11),
        .rdt_TableCell:nth-child(12),
        .rdt_TableCell:nth-child(13),
        .rdt_TableCell:nth-child(14),
        .rdt_TableCell:nth-child(15),
        .rdt_TableCell:nth-child(16),
        .rdt_TableCell:nth-child(17),
        .rdt_TableCell:nth-child(18),
        .rdt_TableCell:nth-child(19),
        .rdt_TableCell:nth-child(20),
        .rdt_TableCell:nth-child(24) {
          justify-content: right;
        }

        .rdt_TableCell:nth-child(4) {
          justify-content: left;
        }
      }

      
      .grpbxExclude {
        max-width: 16%;
        padding: 0;
        .card-body{
          padding-left: 0;
        }
        .row{
          display: grid !important;
          width: fit-content;
        }
        .lblExcludeGrid{
          min-width: 100%;
        }
        .chkbxGroup{
          // column-count: 2;
          // column-gap: 0%;
          display: flex;
          flex-wrap: wrap;
        }
        // .chkboxFloor,.chkboxOption,.chkboxSeed{
        //   padding-left: 0;
        //   min-width: 100%;
        // }
        // .chkboxBasis,.chkboxFirm,.chkboxFlex{
        //   min-width: 100%;
        // }
        .checkboxwidget-container{
          max-width: 50%;
          min-width: 50%;
          padding: 0;
          .col{
            padding-right: 0;
          }
        }
      }

      .lblTotals {
        max-width: 5%;
        padding-left: 0;
        padding-right: 0.5rem;
        bottom: 20px;

        label {
          width: 100%;
          text-align: right;
        }
      }

      //    .rowspanResponsive-lg-2 {
      //      height: auto !important;
      //  }
    }
    .txtTotalAcresContracted,.txtDryLandAcresContracted,.txtIrrigatedAcresContracted,.txtOpenTons,.txtTonsContracted,.txtTonsAllocated,.lblTotals{
      margin-top: 20px;
    }
  }

  /*END_USER_CODE_FOR_.SCSS*/
}