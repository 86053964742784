.SystemMaintenanceApplicationSupport-ManualDPRInvSearch,.ContractManagement-ManualDPRInvSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxManualDPRInvSearch{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #f3f0f0;
      .card-header{
        display:none
      }

      .grpbxBuyPtDetails {
        min-width: 40%;
        max-width: 40%;
        padding: 0;
        .card{
          label{
            width: 70px;
            text-align: right;
          }
          .ddBuyPt {
            min-width: 100%;
            padding-right: 0;
            .col{
              padding-right: 0;
            }
          }
          .ddWhseBin {
            min-width: 100%;
          }
          .txtAuditDtFrom{
            min-width: 52.5%;
            max-width: 52.5%;
            padding-right: 0;
            .col{
              padding-right: 0;
            }
            input:focus{
              z-index: 0;
            }
          }
          .txtAuditDtTo {
            min-width: 47.5%;
            max-width: 47.5%;
            padding-left: 0;
            label{
              width: 40px;
              text-align: center;
            }
            .col{
              padding-left: 0;
            }
            .input-group{
              width: 100%;
            }
          }
          .txtSC95Num ,.txt1007Num ,.txtWhseRcptNum {
            min-width: 100%;
            input{
              width: 40%;
            }
          }
        }
      }
      .grpbxTransferDetails {
        min-width: 30%;
        max-width: 30%;
        padding: 0;
        .card{
          label{
            width: 120px;
            text-align: right;
          }
          .textboxwidget-container{
            min-width: 100%;
            input{
              width: 80%;
            }
          }
          .txtTransferNum {
            margin-top: 2.25rem;
          }
          .btnSearch {
            max-width: 30%;
            min-width: 30%;
            margin-left: 15%;
            margin-top: 2rem;
            button{
              width: 100%;
            }
          }
        }
      }
      .grpbxPnutDetails {
        min-width: 30%;
        max-width: 30%;
        padding: 0;
        .card{
          label{
            width: 70px;
            text-align: right;
          }
          .lblPeanut {
            label{
              font-weight: bold;
            }
          }
          .dropdownfieldwidget-container{
            min-width: 100%;
          }
        }
        .ddVariety{
          .dropdown-toggle:empty::after {
            margin-left: auto;
          }
          button{
          height:30px;
          }
          #ddItem__{
            height: 25px;
          }
        }
      }
      .grpbxAuditHistory  {
        min-width: 100%;
        padding: 0;
        .card{
          .lblAuditHistOfInvAdj {
            max-width: 30%;
            min-width: 30%;
            label{
              margin-bottom: 0;
              font-weight: bold;
            }
          }
          .buttonwidget-container{
            max-width: 7.5%;
            min-width: 7.5%;
            margin-bottom: 0.3rem !important;
            .row{
              justify-content: end;
              button{
                width: 90%;
              }
            }
          }
          .btnAdd{
            margin-left: auto;
          }
          .btnDelete{
            margin-right: 17px;
          }
          .gridAuditHistory {
            min-width: 100%;
            .card{
              padding-top: 0 !important;
              border: 1px solid #cbc0c0;
            }
            .card-header{
              display: block;
            }
            .rdt_TableCol:nth-child(1),
            .rdt_TableCell:nth-child(1){
              text-align: center !important;
            }
            .rdt_TableCol:nth-child(2),
            .rdt_TableCell:nth-child(2){
              min-width: 67px;
              max-width: 67px;
              text-align: center !important;
            }
            .rdt_TableCol:nth-child(3),
            .rdt_TableCell:nth-child(3){
              min-width: 87px;
              max-width: 87px;
              text-align: center !important;
            }
            .rdt_TableCol:nth-child(4),
            .rdt_TableCell:nth-child(4){
              min-width: 87px;
              max-width: 87px;
              text-align: center !important;
            }
            .rdt_TableCol:nth-child(5),
            .rdt_TableCell:nth-child(5){
              min-width: 87px;
              max-width: 87px;
              text-align: center !important;
            }
            .rdt_TableCol:nth-child(6),
            .rdt_TableCell:nth-child(6){
              min-width: 87px;
              max-width: 87px;
              text-align: center !important;
            }
            .rdt_TableCol:nth-child(7),
            .rdt_TableCell:nth-child(7){
              min-width: 87px;
              max-width: 87px;
              text-align: center !important;
            }
            .rdt_TableCol:nth-child(8),
            .rdt_TableCell:nth-child(8){
              min-width: 87px;
              max-width: 87px;
              text-align: center !important;
            }
            .rdt_TableCell:nth-child(10),.rdt_TableCell:nth-child(11),.rdt_TableCell:nth-child(12),
            .rdt_TableCell:nth-child(13),.rdt_TableCell:nth-child(14),.rdt_TableCell:nth-child(15),
            .rdt_TableCell:nth-child(16){
              justify-content: right !important;
              }
              .rdt_TableCell:nth-child(17),
                .rdt_TableCell:nth-child(19){
                justify-content: left !important;
              }
              .rdt_TableCol:nth-child(11),
              .rdt_TableCell:nth-child(11){
                text-align: center !important;
              }
              .rdt_TableCol:nth-child(13),
              .rdt_TableCell:nth-child(13){
                text-align: center !important;
              }
              .rdt_TableCol:nth-child(14),
              .rdt_TableCell:nth-child(14){
                text-align: center !important;
              }
              .rdt_TableCol:nth-child(15),
              .rdt_TableCell:nth-child(15){
                text-align: center !important;
              }
              .rdt_TableCol:nth-child(17),
              .rdt_TableCell:nth-child(17){
              min-width: 287px;
              max-width: 287px;
              text-align: center !important;
              }
              .rdt_TableCol:nth-child(19),
              .rdt_TableCell:nth-child(19){
              min-width: 200px;
              max-width: 200px;
              text-align: center !important;
            }
              // .rdt_TableCol:nth-child(23),
              // .rdt_TableCell:nth-child(23){
              //   text-align: center !important;
              // }
              .rdt_TableCol:nth-child(20),
              .rdt_TableCell:nth-child(20){
                text-align: center !important;
              }
              .rdt_TableCol:nth-child(21),
              .rdt_TableCell:nth-child(21){
                text-align: center !important;
              }
              .rdt_TableCol:nth-child(22),
              .rdt_TableCell:nth-child(22){
              text-align: center !important;
              }
              .rdt_TableCol:nth-child(23),
              .rdt_TableCell:nth-child(23){
              min-width: 200px;
              max-width: 200px;
              text-align: center !important;
              }
          }
          .lblNote{
            max-width: 20%;
            min-width: 20%;
            label{
              color: blue;
            }
          }
          .txtTotalPhysical,.txtTotalReceipted,.txtTotalShrink,.txtTotalUnreceipted,
          .txtTotalUndetermined,.txtTotalCompOwned,.txtTotalIntransit{
            input{
             text-align: right;
            }
          }
          .textboxwidget-container{
            min-width: 10%;
            max-width: 10%;
            padding: 0;
            label{
              display: none;
            }
            .col{
              padding: 0.1rem;
            }
          }
        }
      }
    }
  }
  
  .grpbxActions {
    min-width: 100%;
    .card{
      background-color: #f3f0f0;
      .card-header{
        display: none;
      }
      .grpbxPrintExport {
        min-width: 15%;
        max-width: 15%;
        .card{
          border: none;
          padding-top: 0.2rem;
        .buttonwidget-container{
          min-width: 100%;
          margin-bottom: 0.2rem !important;
          button{
            width: 100%;
          }
        }
        }
      }
      .btmExit{
        min-width: 10%;
        max-width: 10%;
        margin-left: 30%;
        margin-bottom: 1.25rem !important;
        button{
          width: 100%;
          height: 40px;
        }
      }
      .btnAuditHistory {
        min-width: 10%;
        max-width: 10%;
        margin-left: 33.5%;
        margin-bottom: 1.25rem !important;
        button{
          width: 100%;
          height: 40px;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
