.ContractManagement-FlexibleMarketPricings {
  /*START_USER_CODE_FOR_.SCSS*/
   // background-color: #f3f0f0;
   .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
  .card {
    background-color: #c0bcbc;
  }

  .GridWidget .rdt_TableBody {
    height: 300px;
    overflow-y: overlay;
  }
  .gridExistingPricing{
    .rdt_TableRow:last-child {
        button{
          visibility: hidden;
        }
     }
    .extraRowCell_2,.extraRowCell_3,.extraRowCell_4,.extraRowCell_5,.extraRowCell_6,
    .extraRowCell_7,.extraRowCell_8,.extraRowCell_9,.extraRowCell_10,
    .extraRowCell_11,.extraRowCell_12{
      padding-top: 6px !important;
      text-align: center;
      font-size: 0.7rem;
      border-right: 1px solid #eeeede;
    }
    .rdt_TableRow{
      min-height: 35px !important;
    }
    .rdt_ExpanderRow{
      .py-3{
        padding-bottom: 0rem !important;
        padding-top: 0rem !important;
      }
    }  
    .rdt_TableCell{
      border-right: 1px solid #eeeede;
      padding-right: 16px;
      padding-left: 16px;
    }
    .ExpandRow{
      border-bottom: 1px solid #eeeede;
    }
    .extraRowCell_2{
      margin-left: 3%;
    }
    .rdt_TableCell:nth-child(2),.rdt_TableCol:nth-child(2),.extraRowCell_2{
      min-width: 100px;
      max-width: 100px;
    }
    .rdt_TableCell:nth-child(6),.rdt_TableCol:nth-child(6),.extraRowCell_6{
      min-width: 200px;
      max-width: 200px;
      padding: 0;
    }
    .rdt_TableCell:nth-child(3),.rdt_TableCol:nth-child(3),.extraRowCell_3{
      min-width: 120px;
      max-width: 120px;
      padding: 0;
    }
    .rdt_TableCell:nth-child(4),.rdt_TableCol:nth-child(4),.extraRowCell_4{
      min-width: 120px;
      max-width: 120px;
    }
    .rdt_TableCell:nth-child(8),.rdt_TableCol:nth-child(8),.extraRowCell_8{
      min-width: 150px;
      max-width: 150px;
      
    }
    .rdt_TableCell:nth-child(5),.rdt_TableCol:nth-child(5),.extraRowCell_5{
      min-width: 120px;
      max-width: 120px;
    }
    .rdt_TableCell:nth-child(9),.rdt_TableCol:nth-child(9),.extraRowCell_9,
    .rdt_TableCell:nth-child(10),.rdt_TableCol:nth-child(10),.extraRowCell_10,
    .rdt_TableCell:nth-child(12),.rdt_TableCol:nth-child(12),.extraRowCell_12
    {
      min-width: 100px;
      max-width: 100px;
    }
    .rdt_TableCell:nth-child(7),.rdt_TableCol:nth-child(7),.extraRowCell_7{
      min-width: 120px;
      max-width: 120px;
      padding: 0;
    }
    .rdt_TableCell:nth-child(11),.rdt_TableCol:nth-child(11),.extraRowCell_11{
      min-width: 100px;
      max-width: 100px;
      padding: 0;
    }
  }
  .grpBoxTop {
    .txtContract,
    .txtMultiPricing,
    .txtRemainingLbs {
      max-width: 11%;
    }
    .txtAvailableLbs {
      max-width: 14%;
    }
    .txtMarketEndDate {
      max-width: 17%;
    }
  }
  .lblTotal{
    left: 10%;
    display: inline-flex;
    position: relative;
    margin-left: 7%;
  }
  .lblTotalVal{
    position: relative;
    // left: 2%;
    margin-right: 400%;
    font-weight: 600;
  }
  .grpboxMiddle {
    margin-bottom: 0 !important;
    .lblBoxPostingDate {
      max-width: 18%;
      padding-right: 0;
    }
    .txtCrntDate {
      padding-left: 0;
      bottom: 8px;
    }
    .txtPoundsPriced {
      min-width: 30%;
      // bottom: 50px;
      div {
        display: flex !important;
      }
      label {
        width: 345px;
      }
    }
    .chkboxOverrideRebate {
      max-width: 12%;
      .form-label {
        display: none;
      }
    }
    .txtOverride {
      max-width: 12%;
    }
    .radioMarketRebate {
      min-width: 45%;
    }
    .btnCreate {
      max-width: 8%;
    }
    .lblMrktRebate{
      min-width: 87%;
    }
  }
  .grpBoxBottom {
    .card {
      border: none;
      background-color: #f3f0f0;
    }
    .btnVoid {
      margin-left: 2%;
    }
    .btnVoid,
    .btnExit {
      max-width: 8%;
      button{
        
        width: 100%;
      }
    }
    .btnRescan {
      min-width: 44%;
      padding-left: 2%;
      button{
        
        width: 20%;
      }
    }
    .btnSign{
      max-width: 5%;
    padding-right: 0;
    }
    .btnApprv {
      max-width: 8%;
      padding-right: 2%;
      button{
        
        width: 100%;
      }
    }
    .btnPrntPrcng {
      max-width: 13%;
      padding-left: 2%;
    }
    .btnPrintWork {
      max-width: 12%;
    padding-left: 2%;
    padding-right: 2%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}