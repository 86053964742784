.WarehouseReceipts-PremiumDeductionsWRPurch,.ContractManagement-PremiumDeductionsWRPurch {
 /*START_USER_CODE_FOR_.SCSS*/
//  background-color: #333e48;
background: transparent;
 h1 {
   display: none;
 }
 .card {
   background-color: #f3f0f0;
   border: none;
 }
 .cmmndCntnrActions{
   .row{
     justify-content: center;
     background-color: #f3f0f0;
   }
   .btnPurchase,.btnCancel {
     max-width: 8%;
   }
   .btnOk{
    //display: none;
  }
 }
 .grpbxPremDeductWRPurch{
   .card-header{
     display: none;
   }
   .gridPremDeductWRP{
      .rdt_TableCell:nth-child(2){
       justify-content: left !important;
      }
      .rdt_TableCell:nth-child(3){
        justify-content: right !important;
       }
    }
 }
 /*END_USER_CODE_FOR_.SCSS*/
}