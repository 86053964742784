.ContractManagement-ReviewDeductionTracking,.Settlements-ReviewDeductionTracking {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
  }
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  h1,
  .card-header {
    display: none;
  }
  .gridReviewDedTrckng {
    margin-bottom: 0 !important;
    .card-header {
      display: block;
    }
    .rdt_TableCell:nth-child(6),
    .rdt_TableCell:nth-child(9)
   {
      justify-content: right !important;
    }
    .card {
      .card-header{
        display: none;
      }
      background-color: #c0bcbc;
    }
  }
  .grpbxAllLabels {
    .card {
      border: none;
      background-color: #c0bcbc;
    }
    .lblTotal {
      font-weight: 780;
      max-width: 4%;
      margin-left: 36.5%;
    }
    .lblTotal{
      position: relative;
    right: 37%;
    }
    .txt1 {
      min-width: 12%;
      max-width: 12%;
      margin-left: 15%;
      #txt1{
      text-align: center;
      }
    }
    .txt2 {
      min-width: 9%;
      max-width: 9%;
      padding: 0%;
      margin-left: 23%;
      #txt2{
      text-align: center;
      }
    }
  }
  .grpbxAllButtons {
    .card {
      border: none;
      background-color: #f3f0f0;
    }
    .btnWithImage {
      min-width: 41%;
      display:none;
    }
    .btnClose {
      min-width: 8%;
      max-width: 9%;
      margin-left: 42%;
      #btnClose{
        min-width: 100%;
      }
    }
    .lblsource,
    .lblbuypt {
      max-width: 8%;
      display: none !important;
    }
    .lblnumber1,
    .lblnumber2 {
      max-width: 10%;
      display: none !important;
    }
    .btnExportToExcel {
      max-width: 11%;
      margin-left: 200px;
    }
    .btnPrint {
      max-width: 11%;
      margin-left: 100px;
      padding:0.2%;
      #btnPrint {
        min-width: 70%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
