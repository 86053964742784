.SystemMaintenanceMasterManagement-PremiumDeductionContractProfile,.ContractManagement-PremiumDeductionContractProfile{
 /*START_USER_CODE_FOR_.SCSS*/
 h1{
  display:none
}
// background-color: #333E48;
background: transparent;
.grpbxPremiumDeductionContractProfile {
.card-header{
  display:none
}
margin-top: 1rem;
.card{
  padding-top: 0.5rem;
  border: none;
  background-color: #c0bcbc;
}
label{
  width: 255px;
  text-align: right;
}
.textboxwidget-container{
  min-width: 70%;
  max-width: 70%;
  margin-left: 15%;
}
.txtPremDeductCode ,.txtBuyingPoint {
  input{
    padding-left: 0;
    color: blue;
    background:transparent;
    border: none;
    box-shadow: none;
  }
  .col{
    position: relative;
    right: 7px;
    bottom: 3px;
  }
}
.txtContract {
  label{
    color: #c02020;
  }
  input{
    width: 50%;
  }
}

}

.grpbxActions{
.card-header{
display:none
}
.card{
border: none;
background-color: #f3f0f0;
}
.lblAddedBy,.lblAddedByValue,.lblChangedBy,.lblChangedByValue{
  label{
    display: none;
  }
}
.btnOk{
  max-width: 8%;
  margin-left: 34%;
  top: 5px;
}
.btnCancel{
  max-width: 7%;
  top: 5px;
}
}
/*END_USER_CODE_FOR_.SCSS*/
}
