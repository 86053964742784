.SystemMaintenanceMasterManagement-ContractLimitBuyPt,.ContractManagement-ContractLimitBuyPt{
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .card{
    .card-header{
      display: none;
    }
  }
  .grpbxCntrctLimit {
    margin-bottom: 0 !important;
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none
      }
      .checkboxwidget-container{
        display: none !important;
      }
      .txtCollPt{
        input{
          max-width: 67.5%;
        }
      }
      .txtPrcngTypBuyPt,.txtArea ,.txtPnutTypBuyPt,
      .txtDescBuyPt ,.txtVrtyBuyPt{
        max-width: 50%;
        min-width: 50%;
      }
      .txtPriceTonBuyPt ,.txtOptPrcCapBuyPt,.txtSegBuyPt{
        max-width: 30%;
        min-width: 30%;
      }
      .txtPriceMtchEndBuyPt,.txtPayRebtBuyPt,.txtOleicBuyPt{
        max-width: 45%;
        min-width: 45%;
      }
      .txtOleicBuyPt,.txtPayRebtBuyPt {
        margin-right: 25%;
      }      
      .txtArea{
        margin-right: 50%;
      }
      .txtRebtRateBuyPt {
        max-width: 25%;
      }
      
      .grpbxMarket {
        max-width: 75%;
        min-width: 75%;
        padding: 0;
        .card{
          .card-body{
            padding-left: 0.95rem;
            padding-right: 0.95rem;
            .txtMrktFloorBuyPt ,.txtMrktGainBuyPt ,.txtLoanRepayBuyPt {
              max-width: 40%;
              min-width: 40%;
            }
            .txtMrktCeilingBuyPt ,.txtMultiPricingsBuyPt ,.txtMrktWeeksPriorMatrtyBuyPt  {
              max-width: 60%;
              min-width: 60%;
              label{
                width: 58%;
              }
            }
          }
        }
      }
      
      label{
        width: 120px;
        text-align: right;
      }
      .txtDescBuyPt,.txtVrtyBuyPt{
        label{
          width: 9.75%;
        }
      }
      .txtOleicBuyPt,.txtPayRebtBuyPt ,.txtPriceMtchEndBuyPt{
        label{
          width: 58%;
        }
      }
  
      .lstLockIn {
        max-height: 86px;
    max-width: 25%;
        padding-left: 0;
        label{
          width: 50px;
        }
      }
    }
  }
  .gridContrctLimitBuyPt {
    min-width: 100%;
    .card-header{
      display: block;
    }
    div#row-0 {
      pointer-events: none;
      input{
        border: none;
        box-shadow: none;
      }
      button{
        display: none;
      }
  }
  }
  .grpbxActions {
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #f3f0f0;
      .btnIcon{
        display: none;
      }
      .txtAvailTons {
        max-width: 30%;
        min-width: 30%;
      }
      .buttonwidget-container{
        max-width: 10%;
        min-width: 10%;
        .row{
          justify-content: center;
          button{
            width: 95%;
          }
        }
      }
      .btnPrintGrid {
        margin-left: 40%;
      }
      .btnSave {
        margin-left: 70%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}

//for hiding original cross button as custom cross button from header file is visible on this page
#SystemMaintenanceMasterManagement_ContractLimitBuyPtPopUp{
  .close {
    display: none;
  }
}
