@media (min-width: 768px) {
  .navbar-expand-lg .hideTopMenu {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hamburger-content {
    top: 56px;
    right: 0px;
    position: absolute;
    padding: 15px;
    background: inherit;
  }

  .hamburger-content .navbar-nav {
    flex-direction: column;
  }
}
