.WarehouseReceipts-ViewWarehouseReceipt,.ContractManagement-ViewWarehouseReceipt {
    /*START_USER_CODE_FOR_.SCSS*/
    h1{
      display: none;
    }
    // background-color: #333E48;
  background: transparent;
    .groupboxwidget-container{
      .card{
        border: none;
        background-color:  #c0bcbc;
        .card-header{
          display: none;
        }
      }
    }
    .grpbxViewWarehouseReceipt {
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      button{
      height:30px;
      }
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
        }
      .grpbxReceiptInfo {
        .card{
          border: 1px solid  rgba(0, 0, 0, 0.125);
          .grpbxReceiptInfo1 {
            margin-bottom: 0 !important;
            .card{
              border: none;
              .card-body{
                padding: 0;
                .lblReceiptInfo{
                  min-width: 100%;
                  label{
                    width: 100%;
                    text-align: center;
                    font-weight: bold;
                  }
                }
                .txtObligationLocation,.txtVendor {
                  max-width: 75%;
                  min-width: 75%;
                  label{
                    width: 110px;
                    text-align: right;
                  }
                }
                .txtRcptNum ,.txtERecptNum ,.txtCtrlNum {
                  max-width: 25%;
                  min-width: 25%;
                  label{
                    width: 110px;
                    text-align: right;
                  }
                  input{
                    text-align: center;
                  }
                }
                .txtWhseBin ,.txtStatus ,.txtForfeitedStatus {
                  max-width: 25%;
                  min-width: 25%;
                  padding: 0;
                  label{
                    width: 110px;
                    text-align: right;
                  }
                  input{
                    text-align: right;
                  }
                }
                .txtIssuedDate,.txtPeanutType  {
                  max-width: 25%;
                  min-width: 25%;
                  label{
                    width: 110px;
                    text-align: right;
                  }
                  input{
                    text-align: center;
                  }
                }
                .txtIssuedDate{
                  padding-right: 0;
                  input:focus{
                    z-index: 0;
                    }
                }
                .txtWtdAvgStorageDt {
                  max-width: 35%;
                  min-width: 35%;
                  label{
                    width: 155px;
                    text-align: right;
                  }
                  input{
                    text-align: center;
                  }
                }
                .txtLicenseNum ,.txtCCCCode,.txtHowStored ,.txtShelled  {
                  max-width: 20%;
                  min-width: 20%;
                  padding: 0;
                  label{
                    width: 80px;
                    text-align: right;
                  }
                  input{
                    text-align: center;
                  }
                }
                .txtSeg{
                  max-width: 10%;
                  min-width: 10%;
                  padding: 0;
                  label{
                    width: 50px;
                    text-align: right;
                  }
                  .col{
                    padding-right: 0;
                  }
                  input{
                    text-align: center;
                    max-width: 110%;
                    min-width: 110%;
                  }
                }
                .txtOriginalValue,.txtShrunkValue  {
                  max-width: 25%;
                  min-width: 25%;
                  label{
                    width: 110px;
                    text-align: right;
                  }
                  input{
                    text-align: right;
                  }
                }
                .txtShrinkPct {
                  max-width: 10%;
                  min-width: 10%;
                  padding: 0;
                  margin-left: 25%;
                  label{
                    width: 50px;
                    text-align: right;
                  }
                  input{
                    text-align: left;
                    max-width: 110%;
                    min-width: 110%;
                  }
                  .col{
                    padding-right: 0;
                  }
                }
              }
            }
          }
          .grpbxReceiptInfo2{
            padding-left: 0;
            min-width: 60%;
            max-width: 60%;
            .card{
              border: none;
              .grpbxLstLbl {
                min-width: 32%;
                padding: 0;
                position: relative;
                bottom: 20px;
                .card{
                  .lstEWRHolder,.lstWhouseRemit {
                    min-width: 40%;
                    max-width: 40%;
                    padding: 0;
                  }
                  .labelwidget-container{
                    min-width: 30%;
                    max-width: 30%;
                    label{
                      width: 100%;
                    }
                  }
                }
              }
              .txtTotalLbs ,.txtNetLbs ,.txtTotalTons ,.txtLSKLbs {
                min-width: 17%;
                max-width: 17%;
                padding-right: 0;
                .d-flex{
                  display: block !important;
                  label{
                    width: 100%;
                    text-align: center;
                  }
                  .col{
                    padding: 0;
                  }
                }
                input{
                  text-align: right;
                }
              }
              .txtShrnkPct {
                max-width: 20%;
                min-width: 20%;
                margin-left: 12%;
                padding-right: 0;
                .col{
                  padding-right: 0;
                }
                input{
                  text-align: right;
                }
              }
              .txtNetLbsShrunk ,.txtTotalLbsShrunk ,.txtTotalTonsShrunk ,.txtLSKLbsShrunk {
                min-width: 17%;
                max-width: 17%;
                padding-right: 0;
                .col{
                  padding: 0;
                }
                input{
                  text-align: right;
                }
              }
            }
          }
          .grpbxPayShrink {
            max-width: 40%;
            min-width: 40%;
            .card{
              .lblPaymentShrinkUsing {
                min-width: 100%;
                margin-bottom: 0 !important;
                label{
                  margin-bottom: 0 !important;
                }
              }
              [class^="radio"]{
                padding: 0;
                max-width: 30%;
              }
              .lblShrinkPayLRR,.txtOtherRate{
                max-width: 20%;
                min-width: 20%;
                padding-left: 0;
                .col{
                  padding: 0;
                  text-align: right;
                }
              }
              .txtShrinkPayPct {
                max-width: 50%;
                min-width: 50%;
                padding: 0;
                label{
                  width: 85px;
                  text-align: right;
                }
              }
              .txtShrinkPay {
                max-width: 50%;
                min-width: 50%;
                margin-left: 20%;
                padding: 0;
                label{
                  width: 85px;
                  text-align: right;
                }
              }
              .txtShrinkPayLRR {
                max-width: 50%;
                min-width: 50%;
                padding: 0;
              }
            }
          }
          .grpbxReceiptInfo3{
            .card{
              border: none;
              .card-body{
                .checkboxwidget-container {
                  .form-check{
                    input{
                      position: relative;
                      top: 3px;
                    }
                    .form-check-label{
                      display: none;
                    }
                  }
                }
                .chkboxCommittedToOther {
                  max-width: 20%;
                  margin-left: 80%;
                }
                .ddCurrentHolder {
                  max-width: 55%;
                  min-width: 55%;
                  label{
                    width: 100px;
                    text-align: right;
                  }
                }
                .txtLastAction{
                  max-width: 30%;
                  min-width: 30%;
                  margin-right: 25%;
                  label{
                    width: 100px;
                    text-align: right;
                  }
                }
                .txtDateCancelled {
                  max-width: 30%;
                  min-width: 30%;
                  label{
                    width: 100px;
                    text-align: right;
                  }
                }
                .radioElectronicPaper{
                  min-width: 25%;
                  max-width: 25%;
                  .mb-3{
                    display: inline-flex;
                    .form-check{
                      padding-right: 25%;
                      input{
                        bottom: 2px;
                      }
                    }
                  }
                  
                }
                .txtCommittedTo {
                  max-width: 30%;
                  min-width: 30%;
                  label{
                    width: 80px;
                    text-align: right;
                  }
                }
                .chkboxDMA,.chkboxCMA{
                  max-width: 7.5%;
                }
                .txtVendorNum {
                  max-width: 18%;
                  min-width: 18%;
                  padding-right: 0;
                  label{
                    width: 80px;
                    text-align: right;
                  }
                }
                .btnVendor{
                  max-width: 2.5%;
                  min-width: 2.5%;
                  margin-bottom: 0.3rem !important;
                  padding-right: 0;
                }
                .txtVndr {
                  max-width: 24.5%;
                  min-width: 24.5%;
                  padding: 0;
                }
                .ddRemit {
                  max-width: 45%;
                  min-width: 45%;
                  label{
                    width: 80px;
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
      .grpbx1007s {
        .card{
          border: 1px solid  rgba(0, 0, 0, 0.125);
          .lbl1007s{
            min-width: 100%;
            label{
              font-weight: bold;
              width: 100%;
              text-align: center;
            }
          }
          .grid1007s{
            min-width: 100%;
            .rdt_TableCell:nth-child(8),
            .rdt_TableCell:nth-child(9),
            .rdt_TableCell:nth-child(10),
            .rdt_TableCell:nth-child(11) {
              justify-content: right !important;
            }
            .rdt_TableCell:nth-child(3),
            .rdt_TableCell:nth-child(4) {
              justify-content: left !important;
            }
            .rdt_TableCol:nth-child(1),
            .rdt_TableCell:nth-child(1) {
              min-width: 50px;
              max-width: 50px;
            }
            .rdt_TableCol:nth-child(3),
            .rdt_TableCell:nth-child(3) {
              min-width: 110px;
              max-width: 110px;
            }
            .rdt_TableCol:nth-child(4),
            .rdt_TableCell:nth-child(4) {
              min-width: 270px;
              max-width: 270px;
            }
            .rdt_TableCol:nth-child(5),
            .rdt_TableCell:nth-child(5) {
              min-width: 80px;
              max-width: 80px;
            }
            .rdt_TableCol:nth-child(2),
            .rdt_TableCol:nth-child(6),
            .rdt_TableCell:nth-child(2),            
            .rdt_TableCell:nth-child(6) {
            min-width: 80px;
            max-width: 80px;
          }
          .rdt_TableCol:nth-child(7),
          .rdt_TableCell:nth-child(7) {
            min-width: 70px;
            max-width: 70px;
          }
          .rdt_TableCol:nth-child(8),
          .rdt_TableCell:nth-child(8) {
            min-width: 70px;
            max-width: 70px;
          }
          .rdt_TableCol:nth-child(9),
          .rdt_TableCell:nth-child(9) {
            min-width: 70px;
            max-width: 70px;
          }
          .rdt_TableCol:nth-child(10),
          .rdt_TableCell:nth-child(10) {
            min-width: 90px;
            max-width: 90px;
          }
          .rdt_TableCol:nth-child(11),
          .rdt_TableCell:nth-child(11) {
            min-width: 80px;
            max-width: 80px;
         }
          .rdt_TableCol:nth-child(2),
          .rdt_TableCol:nth-child(3),
          .rdt_TableCol:nth-child(4),
          .rdt_TableCol:nth-child(5),
          .rdt_TableCol:nth-child(6),
          .rdt_TableCol:nth-child(7),
          .rdt_TableCol:nth-child(8),
          .rdt_TableCol:nth-child(9),
          .rdt_TableCol:nth-child(10){
            justify-content: center !important;
            text-align: center;
          }
        }
      }
    }
      .grpbxFactorsForAllPeanuts {
        .card{
          border: 1px solid  rgba(0, 0, 0, 0.125);
          .lblFactorsForAllPeanuts {
            min-width: 90%;
            max-width: 90%;
            margin-left: 10%;
            label{
              font-weight: bold;
            }
          }
          .textboxwidget-container{
            max-width: 17.5%;
            min-width: 17.5%;
            padding: 0;
            label{
              width: 90px;
              text-align: right;
            }
            .col{
              padding-right: 0.1rem;
            }
            input{
              text-align: right;
            }
          }
          [class ^= "lblPct"]{
            max-width: 2.5%;
            padding: 0;
            label{
              width: 100%;
              text-align: left;
            }
          }
          .txtFlavus {
            margin-left: 30%;
            input{
              text-align: right;
            }
          }
          .txtVICAM{
            input{
              text-align: right;
            }
          }
          .lblPPB{
            max-width: 10%;
            padding: 0;
          }
        }
      }
      .grpbxStrgPrchs{
        min-width: 40%;
        .card{
          .card-body{
            padding: 0;
            .grpbxStorage {
              padding-right: 0;
              .card{
                border: 1px solid  rgba(0, 0, 0, 0.125);
                .card-body{
                  padding-right: 1rem;
                  padding-left: 1rem;
                  .lblStorage {
                    min-width: 100%;
                    label{
                      width: 100%;
                      text-align: center;
                      font-weight: bold;
                    }
                  }
                  [class*="Date"],[class*="Dt"]{
                    max-width: 100%;
                    min-width: 100%;
                    label{
                      width: 160px;
                      text-align: right;
                    }
                    input{
                      width: 60%;
                    }
                    .col{
                      max-width: 40%;
                    }
                  }
                  .txtStorageCharge {
                    max-width: 50%;
                    padding: 0;
                    label{
                      width: 100px;
                      text-align: right;
                    }
                    input{
                      text-align: right;
                    }
                  }
                  .chkboxStoreAndHandleReceived ,.chkboxStoreAndHandlePaid {
                    max-width: 50%;
                    padding: 0;
                    max-height: 30px;
                    position: relative;
                    top: 10px;
                  }
                  .txtInCharges,.txtInspeactionFees ,.txtInChargesAndAsstCosts  {
                    min-width: 50%;
                    padding: 0;
                    label{
                      width: 100px;
                      text-align: right;
                    }
                    input{
                      text-align: right;
                    }
                  }
                }
              }
            }
            .grpbxPurchase {
              padding-right: 0;
              .card{
                border: 1px solid  rgba(0, 0, 0, 0.125);
                .card-body{
                  padding-right: 1rem;
                  padding-left: 1rem;
                  .lblPurchaseInfo {
                    min-width: 100%;
                    label{
                      width: 100%;
                      text-align: center;
                      font-weight: bold;
                    }
                  }
                  .txtLoanRepayRate{
                    input{
                      text-align: left !important;
                    }
                  }
                  .textboxwidget-container{
                    max-width: 50%;
                    padding: 0;
                    label{
                      width: 100px;
                      text-align: right;
                    }
                    input{
                      text-align: right;
                    }
                  }
                  .txtValueOfSeg{
                    margin-left: 50%;
                    input{
                      text-align: right;
                    }
                  }
                  .grpbxPrchs1 {
                    padding: 0;
                    .card{
                      border: none;
                      .chkboxOverrideLRR {
                        max-width: 70%;
                        margin-left: 30%;
                        padding-right: 0;
                        .d-flex{
                          justify-content: end;
                          .col{
                            max-width: 27%;
                            .form-check-label{
                              display: none;
                            }
                            input{
                              position: relative;
                              top: 2px;
                            }
                          }
                        }
                        
                      }
                      .radioRedemptionMethod{
                        padding: 0;
                        .d-flex{
                          .form-label{
                            width: 100px;
                            text-align: right;
                          }
                          .col{
                            padding-top: 0.5rem;
                            input{
                              bottom: 1px;
                            }
                          }
                        }
                      }
                      
                    }
                  }
                  .grpbxPrchs2{
                    padding: 0;
                    .card{
                      border: none;
                      .textboxwidget-container{
                        min-width: 100%;
                        padding: 0;
                        label{
                          width: 99px;
                        }
                      }
                      .chkboxDeferredRedemption{
                        .col{
                          .form-check-label{
                            text-align: right;
                            width: 95%;
                          }
                          input{
                            margin-left: 90%;
                          }
                        }
                      }
                    }
                    
                  }
                }
              }
            }
          }
          }
        }
        
      .grpbxRepay {
        max-width: 60%;
        .card{
          border: 1px solid  rgba(0, 0, 0, 0.125);
          .grpbxRepayment {
            .txtWireTransfer {
              max-width: 80%;
              min-width: 80%;
              padding-right: 0;
              padding-left: 0;
              .col{
                padding-left: 0;
              }
            }
            .txtWireXferData{
              visibility: hidden;
            }
            .txtWireTransferData1{
              padding-left: 0;
              padding-right: 0;
              min-width: 35%;
              .col{
                padding-left: 0;
              }
            }
            .txtWireTransferData2,.txtWireTransferData3,.txtWireTransferData4 ,.txtWireTransferData5 ,.txtWireTransferData6   {
              padding: 0;
              margin-left: -20px;
              .col{
                padding-right: 5px;
                padding-left: 5px;
              }
            }
            .txtWireTransferData3 {
              max-width: 9%;
              // margin-left: -15px;
            }
            .txtWireTransferData2 {
              max-width: 8%;
            }
            .txtWireTransferData5,.txtWireTransferData6  {
              max-width: 12%;
            }
            .txtWireTransferData4 {
              max-width: 7%;
            }
            .txtConcordia1,.txtWireXferData  {
              max-width: 17.5%;
            }
            .txtConcordia3{
              min-width:32%;
              max-width: 32%;
            }
            .txtConcordia1,.txtConcordia3  {
              padding-right: 0;
            }
            .txtConcordia2 {
              padding: 0;
              max-width: 9%;
            }
            .txtWireXferData {
              padding-left: 0;
            }
            .txtConcordia2,.txtConcordia3{
              margin-left: -16px;
              .col{
                padding-right: 0%;
                padding-left: 0;
              }
            }
            padding: 0;
            .card{
              border: none;
              .grpbxRepymnt {
                max-width: 65%;
                .card{
                  .lblRepayment {
                    min-width: 100%;
                    label{
                      width: 70%;
                      text-align: right;
                      font-weight: bold;
                    }
                  }
                  [class*="Amt"]{
                    max-width: 65%;
                    min-width: 65%;
                    padding: 0;
                    label{
                      width: 130px;
                      text-align: right;
                    }
                    input{
                      text-align: right;
                    }
                  }
                  [class*="Override"],.txtNetRepayAmt {
                    max-width: 35%;
                    min-width: 35%;
                    padding: 0;
                    input{
                      text-align: right;
                    }
                  }
                }
              }
              .grpbxForfeitureStorage {
                min-width: 30%;
                padding-top: 2rem;
                .card{
                  border: 1px solid  rgba(0, 0, 0, 0.125);
                  .lblForfeitureStorage {
                    min-height: 100%;
                    label{
                      width: 100%;
                      text-align: center;
                    }
                  }
                  .btnCalculatePayment {
                    max-width: 100%;
                    min-width: 100%;
                    .row{
                      justify-content: center;
                      button{
                        width: 90%;
                      }
                    }
                  }
                  .txtStoragePaidAmt {
                    min-width: 100%;
                    .d-flex{
                      display: block !important;
                      label{
                        width: 100%;
                        text-align: center;
                      }
                      .col{
                        padding: 0;
                      }
                      input{
                        text-align: left;
                      }
                    }
                  }
                }
              }
              .txtRepayVendorNum,.txtCMAVendorNum  {
                min-width: 30%;
                max-width: 30%;
                padding-right: 0;
                label{
                  width: 80px;
                  text-align: right;
                }
              }
              .btnRepayVendor ,.btnCMAVendor {
                max-width: 5%;
                margin-bottom: 0.3rem !important;
              }
              .txtCMAVendor ,.txtRepayVendor {
                min-width: 65%;
                padding-left:0;
              }
              .ddRepayRemit ,.ddCMARemit {
                min-width: 100%;
                label{
                  width: 80px;
                  text-align: right;
                }
              }
              .chkboxWireTransfer {
                max-width: 17%;
                min-width: 17%;
                padding: 0;
                label{
                  display: none;
                }
                .col{
                  padding: 0;
                }
                .form-check{
                label{
                  display: block;
                }
              }
              }
              // .txtWireTransfer {
              //   padding-left: 0;
              //   min-width: 97%;
              // }
            }
          }
          .grpbxLoan {
            .card{
              border: none;
              .grpbxLoanNum {
                .card{
                  border: 1px solid  rgba(0, 0, 0, 0.125);
                  .lblLoanNum {
                    min-width: 100%;
                  }
                  .textboxwidget-container{
                    min-height: 33%;
                    max-width: 33%;
                    padding: 0;
                  }
                  .txtLnNumber {
                    min-height: 34%;
                    max-width: 34%;
                    .col{
                      padding-right: 0;
                    }
                  }
                }
              }
              .grpbx211CCC500 {
                .card{
                  padding-top: 1rem;
                }
                .chkboxCCC500{
                  .form-check-label{
                     display: none;
                  }
                  input{
                    position: relative;
                    top: 3px;
                    right: 5px;
                  }
                }
              }
              [class*="Date"],[class*="Dt"],.txtLoanAmt ,.txtMarketGain1,.txtLoanReductionPayment{
                min-width: 40%;
                max-width: 40%;
                padding-right: 0;
                .d-flex{
                  position: relative;
                  top: 0%;
                }
                label{
                  width: 100px;
                  text-align: right;
                }
              }
              .txtLoanAmt ,.txtMarketGain1,.txtMarketGain2 {
                .d-flex{
                  align-self: start;
                }
                input{
                  text-align: right;
                }
              }
              .lblLoanAmtVal,.lblLoanMaturityVal{
                position: relative;
                bottom: 15px;
                right: 15px;
              }

              .lblLoanMaturityVal{  
                 color:blue
              }
              .txtMarketGain2 {
                min-width: 20%;
                max-width: 20%;
                padding: 0;
              }
              .txtLoanReductionPayment {
                margin-left: 40%;
              }
            }
          }
        }
      }
    }
    .grpbxActions {
      .buttonwidget-container{
        align-self: flex-start !important;
        .row{
          justify-content: center;
          button{
            width: 95%;
            padding: 0.2rem;
          }
        }
      }
      .btnViewEWRLog ,.btnPrintFSADoc ,.btnStorageHandlingPayment,.btnPrintWorksheet {
        min-width: 9%;
        max-width: 9%;
        button{
          height: 60px;
        }
      }
      .grpbxShrinkPayBtnCntnr,.grpbxTentApplBtnCntnr{
        max-width: 9%;
        padding: 0;
        .buttonwidget-container{
          min-width: 100%;
         button{
          height: 60px;
         }
        }
        
      }
      .grpbxBtnCntnr1 {
        max-width: 18%;
        padding: 0;
        .groupboxwidget-container{
          padding: 0;
        }
        .buttonwidget-container{
          min-width: 100%;
          max-width: 100%;
          
        }
      }
      .grpbxBtnCntnr2{
        padding: 0;
        min-width: 27%;
        .buttonwidget-container{
          min-width: 33.33%;
          max-width: 33.33%;
          
        }
      }
      .btnLoadOut{
        max-width: 9%;
      }
      .btnEnableLoanFields{
        min-width: 43%;
        padding-left: 30%;
        // margin-right: 50%;
        // min-width: 18%;
        margin-right: 80%;
      }
      .lblAddedBy,.lblChangedBy{
        min-width: 10%;
        max-width: 10%;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .lblAddedByValue,.lblChangedByValue{
        min-width: 40%;
        max-width: 40%;
        padding-left: 0;
        label{
          text-align: left;
          display: none;
        }
        .col{
          position: relative;
          bottom: 7px;
          right: 25px;
        }
        }
      }
    .grpbxLoanNum .card{
      width: 420px;
      left: -12px;
    }
    .radioRedemptionMethod .d-flex .col {
      min-width: 130px;
    }
    .txtLnRpyLockInDt, .txtLoanAmt, .txtExpDate, .txtLoanDt, .txtLoanForfeitedDt, .txtLoanMaturityDt, .txtCCCStrgPaidThruDt, .txtMarketGain1, .txtMarketGain2, .txtLoanReductionPayment{
      left: -40px;
      .col .input-group {
        width: 120%;
      }
    }
    /*END_USER_CODE_FOR_.SCSS*/
  }
  
