.SystemMaintenanceMasterManagement-FarmGovData,.ContractManagement-FarmGovData {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
.grpbxFarmGovData{
  .card-header{
    display: none;
  }
  .card{
    background-color:#f3f0f0;
  }
  .row{
    justify-content: center;
  }
  .ddSelectBuyingPoint {
    // margin-bottom: 70px !important;
    min-width: 100%;
    .dropdown-menu.show{
      overflow-y:scroll!important;
      max-height:25vh!important;
      }
      label{
        width: 25%;
        text-align: right;
      }
      .dropdown{
        max-width: 68%;
      }
  }
  .btn1{
    display:none !important;
  }
  .txtBuyingPointID{
   display: none !important;
  }
  .btnCopyData,.btnExportFarms,.btnExportCounties,.btnCancel{
    max-width: 12%;
    margin-top: 80px !important;
    button{
      width: 80%;
    }
  }
  
  .btn1{
    max-width: 4%;
  }
}
  /*END_USER_CODE_FOR_.SCSS*/
}
