.SystemMaintenanceSpecialFunctions-CmaDmaControlProfile,.ContractManagement-CmaDmaControlProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxCmaDmaControlProfile {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none
      }
      .textboxwidget-container{
        padding-right: 0;
        label{
          width: 70px;
          text-align: right;
        }
        .col{
          padding-right: 0;
        }
      }
      .txtState {
        min-width: 15%;
        max-width: 15%;
      }
      .ddStateAbbr {
        min-width: 15%;
        max-width: 15%;
        padding: 0;
      }
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      button{
      height:30px;
      }
      .txtCountry {
        min-width: 15%;
        max-width: 15%;
      }
      .txtVendor {
        min-width: 20%;
        max-width: 20%;
        padding: 0;
        label{
          width: 85px;
          text-align: right;
        }
      }
      .btnVendor {
        max-width: 5%;
        margin-bottom: 0.3rem !important;
        .row{
          justify-content: center;
        }
      }
      .txtVndr,.txtCountryName,.txtStateName{
        padding: 0;
        max-width: 60%;
        min-width: 60%;
        label{
          display: none;
        }
        input{
          box-shadow: none;
          border: none;
          color: blue;
          background-color: #c0bcbc;
          padding-left: 0;
        }
        .col{
          padding-left: 0;
        }
      }
      .txtCountryName{
        margin-right: 10%;
        .col{
          padding-left: 1rem;
        }
      }
      .checkboxwidget-container{
        margin-left: 70px;
        max-width: 80%;
        min-width: 80%;
      }
      .lblPDExceptionList {
        margin-left: 85px;
        label{
          margin-bottom: 0;
        }
      }
      .gridCMADMAControlProfile{
        min-width: 80%;
        max-width: 80%;
        margin-left: 85px;
        label{
          display: none;
        }
        .card{
          border: 1px solid #f3f0f0;
          padding-top: 0 !important;
        }
        .rdt_TableHeadRow{
          display: none;
        }
        .rdt_TableCell:nth-child(1),.rdt_TableCol:nth-child(1){
          min-width: 160px;
          max-width: 160px;
        }
        .rdt_TableCell:nth-child(2),.rdt_TableCol:nth-child(2){
          min-width: 20px;
          max-width: 20px;
          border-right: none !important;
        }
        .rdt_TableCell:nth-child(3),.rdt_TableCol:nth-child(3){
          min-width: 100px;
          max-width: 100px;
        }
        .rdt_TableCell:nth-child(4),.rdt_TableCol:nth-child(4){
          min-width: 20px;
          max-width: 20px;
          border-right: none !important;
        }
        .rdt_TableCell:nth-child(5),.rdt_TableCol:nth-child(5){
          min-width: 100px;
          max-width: 100px;
        }
      }
    }
  }



  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 8%;
      padding: 0;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 77%;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 7px;
      }
    }
    .btnOk,.btnCancel{
       max-width: 7.5%;
       min-width: 7.5%;
       top: 10px;
       button{
         width: 90%;
       }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
