.WarehouseReceipts-DelvAgreeProfile,.ContractManagement-DelvAgreeProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxDelvAgree {
    .card-header{
      display: none;
    }
    .card{
      background-color: #c0bcbc;
      .grpbxLeft{
        max-width: 50%;
        padding: 0;
        padding-top: 0.25rem;
        .card{
          border: none;
          .grpbxDelieveryInOut{
            min-width: 100%;
            padding-right: 0.25rem;
            label{
              width: 100px;
              text-align: right;
            }
            .card{
              border: 1px solid black;
              padding-top: 0.2rem;
            }
            .radioDeliveryINout{
              .mb-3{
                display: flex;
                margin-left: 20%;
              }
              .form-check{
                min-width: 40%;
                label{
                  text-align: left;
                }
              }
            }
            .ddWhseReceipt{
              #ddItem_{
                height: 25px;
              }	  
             .dropdown-toggle:empty::after {
                margin-left: auto;
               }
              button{
              height:30px;
            }
            }
            .txtAgreeDate{
              padding-left: 0;
              label{
                width: 90px;
              }
            }
          }
          .lblNote{
            min-width: 100%;
            label{
              color: blue;
            }
          }
          .grpbxVendorSplits{
            min-width: 100%;
            padding-right: 0.25rem;
            .card{
              border: 1px solid black;
            }
            .grpbxLabels {
              .card{
                border: none;
              }
              padding: 0;
              .lblVendorSplits{
                width: 100%;
                font-weight: 600;
                font-size: 15px;
              }
              .lblVendor,.lblRemitTo{
                label{
                  width: 100%;
                  text-align: center;
                }
              }
            }
            .grpbxVendorSplitsRows{
              max-height: 300px;
              min-height: 300px;
              overflow-y: overlay;
              .card{
                border: none;
              }
              [class^="lblXX"]{
                .col{
                  color: blue;
                  padding-left: 0;
                }
              }
              [class^="grpbxVendorSplitRow"]{
                padding: 0;
              }
              .txtVendor0 ,.txtVendor1,.txtVendor2,.txtVendor3,.txtVendor4,.txtVendor5,.txtVendor6,.txtVendor7,.txtVendor8,.txtVendor9,.txtVendor10,.txtVendor11,.txtVendor12,.txtVendor13,.txtVendor14,.txtVendor15,.txtVendor16,.txtVendor17,.txtVendor18,.txtVendor19{
                min-width: 23%;
                padding: 0;
              }
              .btn0,.btn1,.btn2,.btn3,.btn4,.btn5,.btn6,.btn7,.btn8,.btn9,.btn10,.btn11,.btn12,.btn13,.btn14,.btn15,.btn16,.btn17,.btn18,.btn19{
                max-width: 7%;
                padding-right: 0;
                margin-bottom: 0.3rem !important;
              }
              .ddRemitTo0,.ddRemitTo1,.ddRemitTo2,.ddRemitTo3,.ddRemitTo4,.ddRemitTo5,.ddRemitTo6,.ddRemitTo7,.ddRemitTo8,.ddRemitTo9,.ddRemitTo10,.ddRemitTo11,.ddRemitTo12,.ddRemitTo13,.ddRemitTo14,.ddRemitTo15,.ddRemitTo16,.ddRemitTo17,.ddRemitTo18,.ddRemitTo19{
                min-width: 52%;
                padding-right: 0;
                padding-left: 0;
                #ddItem_{
                  height: 25px;
                }	  
                .dropdown-toggle:empty::after {
                 margin-left: auto;
                 }
                 button{
                   height:30px;
                 }
              }
              .txtSharePercentage0,.txtSharePercentage1,.txtSharePercentage2,.txtSharePercentage3,.txtSharePercentage4,.txtSharePercentage5,.txtSharePercentage6,.txtSharePercentage7,.txtSharePercentage8,.txtSharePercentage9,.txtSharePercentage10,.txtSharePercentage11,.txtSharePercentage12,.txtSharePercentage13,.txtSharePercentage14,.txtSharePercentage15,.txtSharePercentage16,.txtSharePercentage17,.txtSharePercentage18,.txtSharePercentage19{
                min-width: 18%;
                padding: 0;
                .col{
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
      .grpbxRight{
        padding: 0;
        padding-top: 0.25rem;
        max-width: 50%;
        .card{
          border: none;
          .grpbxPeanutInfo{
            min-width: 100%;
            padding-left: 0.25rem;
            .card{
              border: 1px solid black;
            }
            label{
              width: 80px;
              text-align: right;
            }
            .lblPeanutInfo{
              label{
                width: 100%;
                font-weight: 600;
                font-size: 15px;
                text-align: center;
              }
            }
            .ddGeneration,.ddPeanutType,.ddPeanutVariety{
              #ddItem_{
                height: 25px;
              }	  
              .dropdown-toggle:empty::after {
                 margin-left: auto;
               }
                button{
                 height:30px;
               }
            }
          }
          .grpbxPricingInfo{
            min-width: 100%;
            label{
              width: 90px;
            }
          }
          .grpbxPricingInfo{
            padding-left: 0.25rem;
            .card{
              border: 1px solid black;
              padding-top: 0.2rem;
              .form-group{
                margin-bottom: 1rem !important;
              }
            }
            .ddFirmBasic{
            #ddItem_{
              height: 25px;
            }	  
            .dropdown-toggle:empty::after {
              margin-left: auto;
             }
            button{
            height:30px;
             }
            }
            .textboxwidget-container{
              input{
                text-align: right;
              }
            }
            label{
              text-align: right;
            }
            .lblPricingInfo{
              min-width: 70%;
              max-width: 70%;
              label{
                width: 100%;
                text-align: center;
                font-weight: 600;
                font-size: 15px;
              }
            }
            .chckBoxOverrideValue{
              max-width: 28%;
              padding: 0;
              label{
                width: 100%;
              }
              .form-label{
                display: none;
              }
              .form-check{
                margin-right: 22px;
              }
            }
          }
        }
      }
    }
    .lblApplicationInfo{
        width: 100%;
        font-weight: 600;
        font-size: 15px;
    }
    .txtAreaComments{
      .d-flex{
        display: grid !important;
      }
      label{
        width: 100%;
        font-weight: 600;
        font-size: 15px;
      }
      .col{
        padding: 0;
      }
    }

    .gridApplicationInfo{
      .card-header{
        display: block;
      }
    }
  }
  .grpbxActions{
    .card{
      background-color: #f3f0f0;
    }
    .card-header{
      display: none;
    }
    .lblAddedBy{
      max-width: 10%;
      label{
        text-align: right;
        width: 100%;
      }
    }
    .lblAddedByValue{
      min-width: 54%;
      .col{
        padding-top: 5px;
      }
      label{
        display: none;
      }
      .col{
        position: relative;
        bottom: 7px;
        right: 40px;
      }
    }
    .lblCropYear{
      max-width: 35%;
      color: red;
      font-weight: 600;
      font-size: 16px;
      .col{
        padding-top: 5px;
        position:relative;
        right:12px;
      }
      .d-flex{
        max-width: 43%;
        background-color: lightgray;
      }
      label{
        padding-left: 10%;
      }
    }
    .lblChangedBy{
      max-width: 10%;
      label{
        text-align: right;
        width: 100%;
      }
    }
    .lblChangedByValue{
      min-width: 48%;
      .col{
        padding-top: 5px;
      }
      label{
        display: none;
      }
      .col{
        position: relative;
        bottom: 7px;
        right: 40px;
      }
    }
    .btncancel,.btnPrint,.btnAdd{
      max-width: 6%;
    }
    .btnPayments{
      max-width: 8.4%;
    }
    .btnAcctDist{
      max-width: 7.4%;
    }
    .btnDelete{
      max-width: 7%;
    }
  }
  .lblXX0{
    .col{
      color: blue;
    } 
  }
  .lblSharePercentage{
    min-width: 30%;
    padding-left: 18%; 
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
