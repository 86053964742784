.WarehouseReceipts-ViewWRPayments,.ContractManagement-ViewWRPayments {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxViewWRPayments{
    .lblPayments{
      min-width: 100%;
    }
    .gridPayments{
      min-width: 100%;
      .rdt_TableCell:nth-child(4){
        justify-content: right !important;
      }
      .rdt_TableCol:nth-child(1),
      .rdt_TableCol:nth-child(2), .rdt_TableCol:nth-child(3),
      .rdt_TableCol:nth-child(4), .rdt_TableCol:nth-child(6),
      .rdt_TableCol:nth-child(7),.rdt_TableCol:nth-child(9), 
      .rdt_TableCol:nth-child(11){
        position: relative;
        top: 15px;
      }
      .rdt_TableCol:nth-child(5),
      .rdt_TableCol:nth-child(8), .rdt_TableCol:nth-child(10){
        position: relative;
        top: 9px;
      }
      .rdt_TableCol:nth-child(5),.rdt_TableCol:nth-child(8),.rdt_TableCol:nth-child(10){
        justify-content: center !important;
        text-align: center;
      }
      .rdt_TableCol:nth-child(10){
        position: relative;
        top: 15px;
      }
    }
  }
  .cmmndCntnrActions{
    .row{
      background-color: #f3f0f0;
      padding-top: 0.25rem;
      .btnClose{
        min-width: 10%;
        max-width: 10%;
        margin-left: 40%;
        button{
          width: 90%;
        }
      }
      .btnExportToExcel{
        min-width: 15%;
        max-width: 15%;
        margin-left: 20%;
        button{
          width: 90%;
        }
      }
      .btnPrint{
        min-width: 10%;
        max-width: 10%;
        button{
          width: 90%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
