.ContractManagement-ExceptPremRequestSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .card {
    background-color: #f3f0f0;
    border: none;
  }
  h1,
  .card-header {
    display: none;
  }
  .txtLocation,
  .txtPremiumCode {
    max-width: 20%;
    input {
      max-width: 37%;
    }
  }
  .txtPeanuttype,
  .txtPeanutVariety,
  .txtSeed,
  .txtGeneration,
  .txtSegType,
  .txtOleic {
    max-width: 10%;
  }
  .ddGeneration,
  .ddSeed,
  .ddPeanutVariety,
  .ddPeanutType,
  .ddOleic,
  .ddSegType,
  .ddLocation,
  .ddPremiumCode {
    min-width: 31%;
  }
  .txtFarm {
    max-width: 23%;
    padding-right: 0;
  }
  .ddLocation,.ddPremiumCode{
    min-width: 51%;
    color: #ff0000;
    .dropdown{
      max-width: 64%;
    }
  }   
  .txtFarmSuffix {
    min-width: 28%;
    padding-left: 0;
    label{
      width: 73px !important;
    }
    input {
      max-width: 42%;
    }
  }
  .txtVendor {
    max-width: 23%;
    color: #ff0000;
    padding-right: 0;
  }
  .btnVendor {
   // min-width: 30%;
    max-width: 3%;
    margin-bottom: 4px !important;
  }
  .txtVndrName{
    max-width: 31%;
    min-width: 25%;
    padding-left: 10px;
    .col{
      padding-left: 0;
      padding-right: 0;
    }
    input{
      padding-right: 0;
      padding-left: 0;
      max-width: 90%;
      position: relative;
      color: blue;
      border: none;
      background: transparent;
      font-weight: 600;
      padding: 0;
    }
  }
  .grpbxSearch {
    label {
      width: 110px;
      text-align: right;
    }
  }
  .txtContract {
    max-width: 50%;
    min-width: 51%;
    input {
      max-width: 26%;
    }
  }
  .btnSearch {
    margin-bottom: 4px !important;
   max-width: 14.4%;
    .row{
      justify-content: end;
  }
  }
  .lblAvailble {
    min-width: 20%;
    label{
      text-align: left;
      width: 100%;
    }
  }
  .chkboxAll1007s {
    // left: 14%;
    // top: 14px;
    .form-check{
      visibility: hidden !important;
      label{
        text-align: left;
      }
    }
    .form-label {
      display: none;
    }
  }
  .gridExcPremReqSrch{
    min-width: 100%;
    
  }
  .txtarAvailable1007s {
    .d-flex {
      display: grid !important;
    }
    label {
      width: 116px;
    position: relative;
    top: 7px;
    }
    textarea {
      height: 320px;
      background-color: #c0bcbc;
    }
  }
  .txtLSKWT,
  .txtNetWT,
  .txtGrossWT,
  .txtPremiumAmount {
    .form-control:disabled, .form-control[readonly]{
      box-shadow: 2px 2px 0px grey inset !important;
    }
    .d-flex {
      display: grid !important;
    }
    label {
      width: 126px;
      text-align: right;
    }
    input {
      background-color: #c0bcbc;
      border: none;
      text-align: right;
    }
    background-color: #c0bcbc;
    margin-right: 0.5%;
  }
  .lblTotal {
    max-width: 13%;
    margin-left: 17%;
    background-color: #c0bcbc;
    margin-right: 0.5%;
  }
  .grpbxPayReq {
    margin-bottom: 0 !important;
    padding-right: 0;
    label {
      width: 140px;
      text-align: right;
    }
    .txtReqBy{
      min-width: 50%;
    }
    .txtDateReq{
      max-width: 50%;
      div#txtDateReqParent {
        left: 35%;
        max-width: 65%;
    }
    }
    .btnPrintPayReq {
      margin-left: 22%;
      min-width: 58%;
    }
    .btnRejectPrem {
      max-width: 20%;
    }
  }
  .btnExit {
    left: 87%;
    max-width: 10%;
  }
  .gridExcPremReqSrch{
    .card-header{
      display:none

    }    

    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
    .grpbxReprintPayReq{
      padding-left: 0;
      margin-bottom: 0 !important;
      .card{
        height: 100%;
      }
      .card-body{
        padding-top: 40px;
        .btnReprintPayReq {
          .row{
            width: 100%;
            justify-content: center;
          }
      } 
      }
    }
.gridExcPremReqSrch  {
  .rdt_TableBody {
    
    overflow-y: overlay;
}
 .rdt_TableCol:nth-child(2),.rdt_TableCell:nth-child(2) {
 min-width: 220px;
max-width: 220px;
}
.rdt_TableCol:nth-child(3),.rdt_TableCell:nth-child(3) {
  min-width: 190px;
 max-width: 190px;
 
 }
 .rdt_TableCol:nth-child(4),.rdt_TableCell:nth-child(4) {
  min-width: 190px;
 max-width: 190px;
 
 }
 .rdt_TableCol:nth-child(23),.rdt_TableCell:nth-child(23) {
  min-width: 190px;
 max-width: 190px;
 
 }
}


  }
  /*END_USER_CODE_FOR_.SCSS*/
    