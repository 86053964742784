.AccountPayment-SpecialPayDocsView,
.ContractManagement-SpecialPayDocsView {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
  }
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .card-header,
  h1 {
    display: none;
  }
  .txtSpecialPayDocuments {
    font-size: 15px;
    font-weight: 600;
    left: 8%;
    max-width: 90%;
  }
  .grpbxSpecialPayDocs {
    .card {
      background-color: #c0bcbc;
    }
    .lblSpoolLocation,
    .lblVendor,
    .lblMemo {
      //  max-width: 18%;
      max-width: 190px;
      label {
        width: 100%;
        text-align: right;
      }
    }
    .lblSpoolLocationValue,
    .lblVendorValue,
    .lblMemoValue {
      padding-left: 0;
      label {
        display: none;
      }
      .col {
        padding-left: 0;
        bottom: 7px;
      }
    }
    .lblMemoValue {
      min-width: 64%;
    }
    .btnReview {
      max-width: 8%;
    }
  }
  .lstDocuments {
    label {
      // width: 15.65%;
      width: 160px;
      text-align: right;
      font-weight: 600;
    }
    select {
      max-width: 94%;
    }
  }
  .btnView {
    max-width: 5%;
  }
  .cmmndCntnrExit {
    max-width: 97.3%;
    left: 1.4%;
    .row {
      justify-content: center;
    }
    background-color: #f3f0f0;
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
