.ContractManagement-TradeAgreeSearch,.Settlements-TradeAgreeSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  h1 {
    display: none;
  }
  .card {
    background-color: #f3f0f0;
    border: none;
  }
  .grpbxSearch {
    .card-header {
      display: none;
    }
    label {
      width: 130px;
      text-align: right;
    }
    .lblFirstLoad {
      max-width: 9%;
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .ddSeg,
    .ddAgreementType,
    .ddPeanutVariety,
    .ddPeanutType {
      min-width: 28%;
    }
   .ddAgreementType{
    .dropdown{
      max-width: 50%;
    }
   }
    .ddLocation {
      min-width: 46%;
      .dropdown{
        max-width: 50%;
      }
    }
    // .ddLocation{
    //   .dropdown-menu.show {
    //     overflow-y: scroll !important;
    //     max-height: 25vh !important;
    //     }
    // }
    .txtAgreement {
      min-width: 46%;
      input {
        max-width: 13%;
      }
    }
    .txtLocationID,
    .txtAgreementType {
      max-width: 12%;
    }
    .txtPeanutVarietyID,
    .txtGeneration,
    .txtSeg,
    .txtEdibleOil,
    .txtOliec {
      max-width: 17%;
    }
    .txtVendor {
      min-width: 20%;
      max-width: 21.5%;
      input{
        min-width: 100%;
      }
    }
    .txtVndor {
      min-width: 66.5%;
      input{
        max-width: 42%;
        color:blue;
        background: transparent;
        border: none;
      }
    }
    .btnVendor {
      min-width: 45.1%;
      margin-bottom: 4px !important;
    }
    .lblXxx {
      min-width: 46%;
      left: 10%;
      label {
        width: 100%;
        text-align: left;
      }
    }
    .ddEdibleOil{
      max-width: 33.5%;
      margin-left: 66.5%;
    }
    .ddOliec {
      max-width: 33.5%;
      // margin-left: 66.6%;
    }
    .btnSearch {
      min-width: 50%;
      max-width: 15%;
      .row {
        justify-content: end;
      }
    }
  }
  .grpbxCreateOpenAgrmnt {
    .card-header {
      display: none;
    }
    .card {
      border: none;
    }
    
    .btnOpen {
      max-width: 6%;
      margin-right: 1.5%;
      button{
        width: 100%;
      }
    }
    .btnCreateAgreement{
      padding-left: 3%;
      max-width: 12%;
    }
    .lblCropYear2 {
      max-width: 14%;
      margin-left: auto;
      .col{
        padding-left: 0;
        div{
          top: 5px;
    position: relative;
        }
      }
    }
    .lblCropYear2 {
      // margin-left: 64%;
      font-weight: 500;
      color: red;  
      background-color: lightgray;
      font-size: 16px;
      // bottom: 41px;
    }
  }
  .grpbxActions {
    .card {
      border: none;
      min-height: 83px;
    }
    .card-header {
      display: none;
    }
    .btnExportToExcel {
      max-width: 10%;
      button{
        width:85%;
      }
    }
    .btnClose,
    .btnPrint {
      max-width: 6%;
    }
    .btnPrint{
      margin-right:28% ;
    }
    .lblAgreedLbs,
    .lblOpenLbs {
      padding-right: 0;
      max-width: 6%;
    }
    .lblAppliedLbs {
      padding-right: 0;
      max-width: 7%;
    }
    .btn1 {
      min-width: 21%;
    }
  }
  .txtAgreedLbs,.txtAppliedLbs,.txtOpenLbs{
    .d-flex{
      display: grid !important;
    }
    // input{
    //   top: 88%;
    //   position: relative;
    //   right: 97%;
    //   }
  }
  .gridAgrmntSrch {
    // .rdt_TableBody,
    // .rdt_TableHead {
    //   min-width: 150%;
    // }
    .expandable-area{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-right: 8px !important;
    }
    .ExpandRow{
      padding-right: 2%;
    padding-left: 2%;
    border-bottom: 0.7px solid #eeeede;
    }
    .ch-3{
      border-right: 1px solid #eeeede;
      min-height: 35px;
    }
    .extraRowCell_15{
      border-right: none !important;
    }
    .expandIconSpc{
      min-width: 30px;
      max-width: 30px;
    }
    .extraRowCell_2,.extraRowCell_4,.extraRowCell_6,.extraRowCell_12,.extraRowCell_13,.extraRowCell_11 {
      min-width: 6.75%;
      max-width: 6.75%;
    }
    .extraRowCell_3{
      min-width: 8.6%;
      max-width: 8.6%;
    }
    .extraRowCell_5{
      min-width:14.75% ;
      max-width: 14.75%;
      label{
        font-size: 11px;
        width: 100%;
        text-align: center;
      }
    }
    .extraRowCell_1{
      min-width: 4.85%;
      max-width: 4.85%;
    }
    .extraRowCell_8{
      min-width: 4.75%;
      max-width: 4.75%;
    }
    .extraRowCell_9,.extraRowCell_10{
      min-width: 4%;
      max-width: 4%;
    }
    .extraRowCell_7{
      min-width: 8.05%;
      max-width: 8.05%;
    }
    .extraRowCell_14{
      min-width: 6.65%;
      max-width: 6.65%;
    }
    
    .rdt_TableCol:nth-child(2),
    .rdt_TableCell:nth-child(2),
    .rdt_TableCol:nth-child(9),
    .rdt_TableCell:nth-child(9) {
      min-width: 70px;
      max-width: 70px;
    }
    .rdt_TableCol:nth-child(4),
    .rdt_TableCell:nth-child(4){
      min-width: 128px;
      max-width: 128px;
    }
    .rdt_TableCell:nth-child(6){
      justify-content: left;
    }
    .rdt_TableCol:nth-child(6),
    .rdt_TableCell:nth-child(6) {
      min-width: 220px;
      max-width: 220px;
      // justify-content: left;
    }
    .rdt_TableCol:nth-child(7),
    .rdt_TableCell:nth-child(7) {
      min-width: 100px;
      max-width: 100px;
    }
    .rdt_TableCol:nth-child(8),
    .rdt_TableCell:nth-child(8) {
      min-width: 120px;
      max-width: 120px;
    }
    .rdt_TableCol:nth-child(10),
    .rdt_TableCell:nth-child(10),
    .rdt_TableCol:nth-child(11),
    .rdt_TableCell:nth-child(11) {
      min-width: 60px;
      max-width: 60px;
    }
  //   .rdt_TableCol:nth-child(14),
  //   .rdt_TableCell:nth-child(14) {
  //     min-width: 7%;
  //     max-width: 7%;
  //   }
  //   .rdt_TableCol:nth-child(15),
  //   .rdt_TableCell:nth-child(15) {
  //     min-width: 7%;
  //     max-width: 7%;
  // }
  // .rdt_ExpanderRow{
  //   left: 22%;
  //   min-width: 15%;
  //   position: relative;
  //   max-width: 35%
  // }
}
  /*END_USER_CODE_FOR_.SCSS*/
}
