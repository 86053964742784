.SystemMaintenanceMasterManagement-PremiumDeductionScheduleSetup,.ContractManagement-PremiumDeductionScheduleSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxPremiumDeductionScheduleSetup{
    .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none
      }
      .card-body{
        padding-right: 10%;
        padding-left: 10%;
      }
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
        }
      .ddBuyingPoint{
        min-width: 100%;
        .d-flex{
          display: block !important;
          .col{
            padding: 0;
            
          }
        }
        
      }
      select{
        height: 160px;
      }
      .btnAdd ,.btnEdit ,.btnDelete {
        max-width: 8%;
        padding-top: 0.5rem;
        button{
          width:95%
        }
      }
      .btnAdd{
        margin-left: auto;
      }
      .btnDelete{
        margin-right: 1%;
      }
      .lstPDCode{
        min-width: 100%;
        padding: 0;
        label{
          display: none;
        }
      }
      .lblPDCode{
        top: 10px;
      }
    }

    
  }
  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
      .btncmdEnableDisableControls {
        padding-left: 2rem;
        max-width: 20%;
        min-width: 20%;
        display: none;
      }
      .btnClose{
        min-width: 10%;
        max-width: 10%;
        margin-left: 45%;
        button{
          width: 100%;
        }
      }
    }
    
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
