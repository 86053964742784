.SystemMaintenanceApplicationSupport-OpenAccountsSetup,.ContractManagement-OpenAccountsSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxOpenAccountsSetup        {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    // margin-top: 1rem;
    margin-bottom: 0.2rem !important;
    .card-header{
      display:none
    }
    .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #c0bcbc;
    }
    label{
      width: 80px;
      text-align: right;
    }
    .ddBuyingPoint {
      min-width: 100%;
      .dropdown{
        width: 80%;
      }
    }
    .txtAcctVendor {
      max-width: 30%;
      padding-right: 0;
      .col{
        padding-right: 0;
      }
    }
    .btnVendor{
      margin-bottom: 0.25rem !important;
      max-width: 5%;
      min-width: 5%;
      .row{
        justify-content: center;
      }
    }
    .txtAcctVndr {
      max-width: 60%;
      padding-left: 0;
      input{
        border: none;
        box-shadow: none;
        color: blue;
        background: transparent;
      }
    }
  }


  .cmmndCntnrSearchActions {
    .row{
      // background-color: #c0bcbc;
      padding-top: 0.25rem;
      // padding-bottom: 1%;
    }
    .btnUp1,.btnDown1,.btnUp2,.btnDown2{
      max-width: 5%;
      min-width: 5%;
      button{
        width: 90%;
      }
    }
    .btnUp2{
      margin-left: 10%;
    }
    .brnSearch{
      max-width: 10%;
      min-width: 10% !important;
      margin-left: 15%;
      button{
        width: 90%;
      }
    }
    .btnAdd,.btnEdit,.btnDelete{
      max-width: 7%;
      min-width: 7%;
      button{
        width: 95%;
      }
    }
    .btnAdd{
      margin-left: auto;
    }
  }

  .gridOpenAccount {
    .card{
      padding-top: 0;
      // .rdt_TableBody{

      //   .rdt_TableRow:last-child{

      //     background-color: yellow;

      //   }

      // }
    }
   
      .rdt_TableCell:nth-child(2){
        justify-content: left;
      }
      
      .rdt_TableCell:nth-child(3){
        justify-content: right;
      }
      
      .rdt_TableCell:nth-child(4){
        justify-content: right;
      }
     
      .rdt_TableCell:nth-child(5){
        justify-content: right;
      }
     
      .rdt_TableCell:nth-child(6){
        justify-content: right;
      }
  }
  .txtAcctVendorName{
    padding: 0;
    label{
      display: none;
    }
    .col{
      padding: 0;
      input{
        box-shadow: none;
        border: none;
        background: transparent;
        color: blue;
      }
    }
  }

  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }

    .buttonwidget-container{
      max-width: 10%;
      button{
        width: 95%;
      }
    }
    .btnClose{
      margin-left: 45%;
    }
    .btnExportToExcel {
      margin-left: auto;
    }
    .btnPrint{
      margin-right: 2%;
    }

  }
  /*END_USER_CODE_FOR_.SCSS*/
}
