.SystemMaintenanceApplicationSupport-VendorMaintenance,.ContractManagement-VendorMaintenance{
  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }
  .card-header{
    display: none;
  }
  .lblContactInformationFromAP,.lblWomen,.lblMinority,.lblVeteran,.lblContactInfo,.lblLienHolders{
    font-size: 15px;
    font-weight: 600;
  }
  .grpbxVendorMaintenance {
    .card{
      background-color:#c0bcbc ;
    }
    .grpbxContractInformationFromAP{
      .card{
        background-color:#c0bcbc ;
        border: 1px solid #f3f0f0;
      }
      label{
        width: 92px;
        text-align: right;
      }
      .lblContactInformationFromAP{
        label{
          width: 300px;
          text-align: left;
        } 
      }
      .txtVendor{
        input{
          color: blue;
          max-width: 25%;
        }
      }
      .txtVendorName{
        input{
          color: blue;
          max-width: 43%;
        }
      }
      .txtCityPA,.txtStatePA,.txtZipPA,.txtPhone,.txtCityMA ,.txtStateMA ,.txtZipMA,.txtCustomer{
        input{
          max-width: 58%;
          color: blue;
        }
      }
      .txtPhysicalAddress,.txtMailingAddress{
        input{
          color: blue;
        }
      }
    }
    .grpbxContactInformation{
      .card{
        max-height: 100%;
        border: 1px solid #f3f0f0;
      }
      label{
        width: 55px;
        text-align: right;
      }
      .lblContactInfo{
        min-width: 100%;
        label{
          width: 100%;
          text-align: left;
        }
      }
    }
    .grpbxLienHolders{
      padding-left: 0;
  
      .card{
        background-color:#c0bcbc ;
        min-height: 100%;
        border: 1px solid #f3f0f0;
      }
      .txtLienHolder{
        // min-width: 30%;
        // padding-right: 0;
        // margin-left: 17%;
        display: none !important;
      }
      .btnEdit,.btnAdd{
        max-width: 15%;
      }
      .btnAdd{
        margin-left: 45%;
      }
      .btnDelete{
        max-width: 18%;
      }
      .lstLienHolder{
        max-height:120px;
        max-width: 100%;
        min-width: 100%;
        .d-flex{
          height: 100%;
        }
        .col{
          padding-left: 0;
        }
        select{
         height: 115px;
        }
        textarea{
          min-height: 100px;
        }
        
      }
    }
    .grpbxWomen{
      .card{
        min-height: 190px;
        border: 1px solid #f3f0f0;
      }
      .form-check{
        display: inline-block;
        padding-right: 2%;
      }
    }
    .grpbxMinority{
      padding-left: 0;
      .card{
        background-color:#c0bcbc ;
        border: 1px solid #f3f0f0;
      }
      .chkboxMinority {
        .d-flex{
          .col{
            .form-check{
              width: 50%;
              display: inline-block;
              padding-right: 2%;
            }
          }
        }
      }
      .form-check{
        display: inline-block;
        padding-right: 2%;
      }
    }
    .grpbxVeteran{
      padding-left: 0;
      .card{
        min-height: 100%;
        border: 1px solid #f3f0f0;
      }
      .form-check{
        display: inline-block;
        padding-right: 2%;
      }
    }
  }
  
  .grpbxActions{
    .card{
      background-color:#f3f0f0;
    }
    label{
      width: 70px;
      text-align: right;
    }
    .txtAddedBy{
      min-width: 29%
    }
    .btnScan211GPC{
      top: 15px;
      max-width: 9%;
      padding-right: 2%;
    }
    .btnView211GPC{
      top: 15px;
      max-width: 7%;
      min-width: 18%;
      button{
        max-width: 45%;
      }
    }
    .btnScan211Concordia{
      top: 15px;
      max-width: 10%;
      padding-right: 2%;
    }
    .btnView211Concordia{
      top: 15px;
      max-width: 9%;
      min-width: 22%;
      button{
        max-width: 50%;
      }
    }
    .btn1{
      display: none;
    }
    .btnExit,.btnSave{
      max-width: 6%;
    }
    .txtChangedBy{
      min-width: 29%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
