.SystemMaintenanceMasterManagement-FreightLocToLocSetup,.ContractManagement-FreightLocToLocSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
   display:none
 }
//  background-color: #333E48;
 background: transparent;
 .grpbxFreightLocToLocSetup {
   .card-header{
     display:none
   }
   .dropdown-menu.show {
     overflow-y: scroll !important;
     max-height: 25vh !important;
   }
   .card{
     border: none;
     background-color: #c0bcbc;
    //  margin-top: 0.5rem;
     .grpbxOrigination{
       .card-header{
         display:none
       }
       .card{
         border: none;
         background-color: #c0bcbc;
         border:  1px solid #f3f0f0;
       }
       label{
         width: 120px;
         text-align: right;
       }
       .lblOrigination label{
         font-weight: bold;
       }
   
       .ddAreaOrig,.ddCollectionPointOrig,.ddBuyingPointOrig{
         max-width: 60%;
         min-width: 60%;
         .dropdown-menu.show {
           overflow-y: scroll !important;
           max-height: 25vh !important;
       }
       }
       .ddState,.ddCounty{
         max-width: 40%;
         .dropdown{
           width: 50%;
         }
         .dropdown-menu.show {
           overflow-y: scroll !important;
           max-height: 25vh !important;
       }
       }
       .ddFarm{
         max-width: 40%;
         .dropdown-menu.show {
           overflow-y: scroll !important;
           max-height: 25vh !important;
       }
       }
       .dropdown-toggle:empty::after {
         margin-left: auto;
       }
       button{
       height:30px;
       }
       .lblNote{
         margin-left: auto;
         max-width: 40%;
         label{
           width: 100%;
           text-align: left;
           padding-left: 20%;
           color: blue;
         }
       }
     }
   
     .grpbxDestination{
   
       padding-right: 0;
       .card-header{
         display:none
       }
       
       .card{
         border: none;
         background-color: #c0bcbc;
         border:  1px solid #f3f0f0;
       }
       label{
         width: 120px;
         text-align: right;
       }
       .lblDestination label{
         font-weight: bold;
       }
       .dropdown{
         width: 90%;
       }
       .ddCollectionPointDest,.ddAreaDest{
         min-width: 92.4%;
        }
     }
   
     .grpbxEffectiveDate{
   
       padding-left: 1rem;
       .card-header{
         display:none
       }
       
       .card{
         height: 100%;
         border: none;
         background-color: #c0bcbc;
         border:  1px solid #f3f0f0;
       }
   
       .lblEffectiveDate label{
         font-weight: bold;
       }
       .ddEffectiveDate{
         padding-right: 0;
        label{
         max-width: 58%;
         min-width: 58%;
        }
        .col{
          padding: 0;
        }
      }
      .txtEffectiveDate {
        padding: 0;
        .input-group{
          height: 31px;
          input{
            height: auto;
          }
        }
      }
     }
   
     .cmmndCntnrActions1{
       .row{
        //  background-color: #c0bcbc;
         padding-top: 1%;
       }
       .buttonwidget-container{
         max-height: 40px;
       }
       .btnSearch{
         max-width: 10%;
         margin-left: 45%;
         button{
           width: 95%;
         }
       }
       .btnAdd,.btnEdit,.btnDelete{
         max-width: 7.5%;
         margin-bottom: 0rem !important;
         button{
           width: 95%;
         }
       }
       .btnAdd{
         margin-left: auto;
       }
     }
   
     .gridFreightLocToLocSetupData{
       .card-header{
         display:block;
       }
       padding-bottom: 1rem;
       .card{
         border: none;
         background-color: #c0bcbc;
         border:  1px solid #f3f0f0;
         .rdt_TableCell{
           min-width: 130px;
           padding: 0;
           text-align: center;
         }
       }
     }
   }
 }
 
 
 .cmmndCntnrActions2{
   .row{
     background-color: #f3f0f0;
     padding-top: 0.25rem;
   }
   .buttonwidget-container{
     max-height: 40px;
   }
   .btnClose{
     max-width: 10%;
     margin-left: 45%;
     button{
       width: 95%;
     }
   }
   .btnAdd,.btnEdit,.btnDelete{
     max-width: 5%;
     button{
       width: 95%;
     }
   }
   .btn1{
     display: none;
   }
   .btnExportToExcel{
     margin-left: auto;
     max-width: 10%;
     button{
       width: 95%;
     }
   }
   .btnPrint{
     max-width: 10%;
     button{
       width: 95%;
     }
   }
 }
 
   /*END_USER_CODE_FOR_.SCSS*/
 }
 