.SystemMaintenanceApplicationSupport-SpecFuncChgNum,.ContractManagement-SpecFuncChgNum {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .card-header{
    display: none;
  }
  .lblChngWRNum,.lblChngSettle1007Num,.lblChng1007VndrNum,.lblChangeTransferNum,.lblChngInsNum,.lblChangeNumbers{
    font-weight: 700;
    label{
      width: 100% !important;
      text-align: left !important;
    }
  }
  .lblChangeNumbers,.radioChangeNumbers{
    min-width: 100%;
  }
  .radioChangeNumbers{
    .mb-3{
      display: inline-flex;
    }
    .form-check{
      min-width: 40%;
      max-width: 40%;
    }
  }
  .grpbxChangeNumbers{

    .card{
      background-color: #c0bcbc;
    }
  }
  .grpbxChngInsNum{
    min-width: 100%;
    label{
      width: 30%;
      text-align: right;
    }
    input{
      max-width: 45%;
    }
    .txtBuyPt{
      input{
        max-width: 28%;
      }
    }
  }
  
  .grpbxChangeTransferNum{
    min-width: 100%;
    .grpbxTransferNum{
      min-width: 60%;
      .card-body{
        padding: 0;
      }
      .card{
        border: none;
      }
      label{
        width: 42%;
        text-align: right;
      }
      .txtShippingBuyPt{
        input{
          max-width: 55%;
              }
      }
      
    }
    .grpbxTransferType{
      max-width: 40%;
      .radioTranferType{
        .d-flex{
          display: grid !important;
        }
      }
    }
    
  }
  .Chng1007VndrNum{
    min-width: 100%;
    label{
      width: 30%;
      text-align: right;
    }
    input{
      max-width: 45%;
    }
    .txtBuyPtCVN{
      input{
        max-width: 28%;
      }
    }
  }
  .grpbxChngSettle1007Num{
    min-width: 100%;
    label{
      width: 30%;
      text-align: right;
    }
    input{
      max-width: 45%;
    }
    .txtBuyPtCSN{
      input{
        max-width: 28%;
      }
    }
  }
  .grpbxChngWRNum{
    min-width: 100%;
    label{
      width: 30%;
      text-align: right;
    }
    .txtBuyPtWR{
      input{
        max-width: 28%;
      }
    }
    .txtNewReceiptNum,.txtOldReceiptNum{
      input{
        max-width: 45%;
      }
    }
  }
   .cmmndCntnrActions{
  .row{
    background-color: #f3f0f0;
    }
    .btnUpdate{
      min-width: 35%;
      max-width: 35%;
      margin-left: 54%;
    }
    .btnCancel{
      min-width: 10%;
      max-width: 10%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
