.Settlements-PremiumDeductions,.ContractManagement-PremiumDeductions {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  .groupboxwidget-container        {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #c0bcbc;
    }
  }
  .grpbxPremiumDeductions {
    .lblPremDeduct{
      min-width: 100%;
      label{
        width: 100%;
        text-align: left;
        margin-bottom: 0;
      }
    }
    .gridPremiumDeductions {
      min-width: 100%;
    }
  }
  .cmmndCntnrActions{
    .row{
      background-color: #c0bcbc;
      padding-top: 0.2rem;
    }
    .btnOk,.btnCancel{
      min-width: 10%;
      max-width: 10%;
      button{
        width: 90%;
      }
    }
    .btnOk{
      margin-left: 40%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
