.Settlements-FV95,
.ContractManagement-FV95 {

  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none
  }

  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .form-group {
    margin-bottom: 0.5rem;
  }

  .grpbxFarmInformation {
    // margin-top: 1rem;
    margin-bottom: 0.2rem !important;

    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #f3f0f0;
      max-height: 445px;

    }

    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }

    .ddIrrigatedDryland {
      margin-left: 75%;
      bottom: 100px;
    }

    .grpbxFarmInfo {
      label {
        width: 160px;
        text-align: right;
      }

      .lblFarmInformation {
        max-width: 76%;

        label {
          width: 100% !important;
          font-weight: bold;
        }
      }

      .lblSC95Submit {
        max-width: 24%;
        padding: 0;

        label {
          width: 0%;
        }

        .col {
          bottom: 7px;
        }
      }
      .btnChange {
        margin-bottom: 4px !important;
      }
      .txtRevision {
        max-width: 10%;
        padding: 0;

        .col {
          padding: 0;
        }

        input {
          width: 90%;
        }
      }

      .lblDateTimeFormat {
        label {
          width: 100%;
          text-align: left;
          color: blue;
          position: relative;
          right: 20%;
        }
      }

      .txtInspectionDateTime {
        max-width: 31.5%;
        min-width: 50%;
      }

      .radioHighMoisture {
        margin-left: 25%;
        padding-top: 1%;

        .form-check {
          display: inline;
          margin-left: 2%;

          label {
            text-align: left;
            width: 5%;

          }
        }

        .col {
          position: relative;
          right: 16px;

          .mb-3 {
            position: relative;
            top: 4px;
          }
        }
      }

      .txtSC95 {
        min-width: 40%;
      }

      .buttonwidget-container {
        max-width: 10%;

        buttom {
          width: 95%;
        }
      }

    }

    .grpbxCMAInformationuic0 {
      label {
        width: 100px;
        text-align: right;
      }

      .lblCMAInformation label {
        width: 100% !important;
        text-align: center !important;
        font-weight: bold;
      }

      .chkboxDefault {
        margin-left: 35%;
        margin-bottom: 0.5%;

        .mb-3 {
          margin-bottom: 0.5rem !important;

        }

        label {
          text-align: left !important;
        }
      }
    }

    .lblTicketUploadMod,
    .lblfreefv95,
    .lblfreefv95,
    .lblN,
    .lblLoadType1,
    .lblLoadType2,
    .lblSC95 {
      label {
        display: none;
      }
    }
  }

  .grpbxWeightTickets {
    // min-height: 642px;
    min-height: 440px;

    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #f3f0f0;
      height: 100%;
    }

    padding-right: 0.2%;

    label {
      width: 100px;
      text-align: right;
    }

    .lblEWeightTktValue,
    .lblEVehicleNumValue,
    .lblEWtInclVehValue,
    .lblEWtOfVehValue,
    .lblEWtOfPnutValue,
    .lblTicketsValue,
    .lblTotalLBsValue,
    .lblTotalDollarValue {
      padding-left: 0;

      label {
        text-align: left !important;
      }
    }

    .lblWeightTickets {
      label {
        // text-align: left;
        font-weight: bold;
        position: relative;
        right: 250px;
        top: 36px;
      }
    }

    .btnAdd,
    .btnEdit,
    .btnDelete {
      min-width: 15%;
      position: relative;
      left: 280px;

      button {
        min-width: 80%;
      }
    }

    .txtTotalLBs,
    .txtTickets,
    .txtTotalDollar {
      label {
        width: 60px;
      }
    }

    .txtTotalDollar {
      display: none !important;
    }
  }

  .grpbx1007Control {
    max-height: 350px;

    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #f3f0f0;

    }

    padding-left: 0;

    label {
      width: 100px;
      text-align: right;
    }

    .lbl1007Control {
      margin-left: 20%;

      label {
        text-align: left;
      }

      font-weight: bold;
    }

    .lblSettleDate {
      max-width: 20%;

      label {
        display: none;
      }
    }

    .lblAdd1007Aplcnt {
      max-width: 80%;
      margin-left: 20%;

      label {
        width: 100%;
        text-align: left;
        font-weight: bold;
      }
    }

    // .lblSettleDate{
    //   label{
    //    display: none;
    //   }
    //  }
  }

  .grpbxPeanutInfo {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }

    // margin-left: 66.67%;
    // bottom: 297px;
    // bottom: 193px;
    padding-left: 0;

    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #f3f0f0;

    }

    label {
      width: 150px;
      text-align: right;
    }

    .lblPeanutInformation {
      margin-left: 28%;
      max-width: 70%;

      label {
        width: 100%;
        font-weight: bold;
        text-align: left !important;
      }
    }

    .lblUnloadedInfo,
    .lblDelPtObligation {

      // margin-left: 20%;
      label {
        width: 100%;
        font-weight: bold;
        text-align: left !important;
      }
    }

    .txtSeedMeetingSpec {
      label {
        width: 50%;
      }

      input {
        width: 50%;
      }
    }

    .lblOrganic {
      max-width: 31%;
      padding-right: 0 !important;
    }

    .chkboxOrganic {
      margin-bottom: 0.5%;
      padding-left: 0;

      .mb-3 {
        margin-bottom: 0.6rem !important;
      }

      .col {
        padding-left: 0;
      }
    }

    .chkboxVicam {
      max-width: 26.75%;
      margin-left: 6%;
      padding-right: 0;
      bottom: 7px;

      .col {
        label {
          width: 100% !important;
          margin-left: 10%;
          text-align: left;
        }
      }
    }

    .txtReading {
      label {
        width: 32%;
      }

      input {
        width: 90%;
      }
    }

    .ddBuyingPt,
    .ddObligationPt {
      min-width: 100%;

      label {
        text-align: left;
      }

      .d-flex {
        display: block !important;
      }
    }
  }

  .grpbxVendorSplits {
    // bottom: 825px;
    // bottom: 594px;
    max-height: 400px;
    min-height: 417px;
    overflow-y: overlay;

    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #f3f0f0;
      min-height: inherit;
      // height: 100%;
      // min-height: 100%;
      // max-height: 100%;
    }

    padding-right: 0.2%;

    .lblVendorSplits {
      label {
        text-align: left;
        font-weight: bold;
      }
    }

    .lblVendor {
      min-width: 23%;
      max-width: 23%;

      label {
        width: 100%;
        text-align: center !important;
      }
    }

    .lblRemitTo {
      max-width: 50%;
      min-width: 50%;

      label {
        width: 100%;
        text-align: center !important;
      }
    }

    .lblSharePct {
      max-width: 15%;

      label {
        width: 100%;
        text-align: center !important;
      }
    }

    .lblCMAParticipant {
      max-width: 10%;

      label {
        // display: none;
        width: 100%;
        text-align: center !important;
      }

      padding-left: 0;
    }

    //   .grpbxVendorSplits {
    //     max-height: 396px;
    //     overflow-y: overlay;
    // }

    .ddVendor1,
    .ddVendor2,
    .ddVendor3,
    .ddVendor0,
    .ddVendor4,
    .ddVendor5,
    .ddVendor6,
    .ddVendor7,
    .ddVendor8,
    .ddVendor9,
    .ddVendor10,
    .ddVendor11,
    .ddVendor12,
    .ddVendor13,
    .ddVendor14,
    .ddVendor15,
    .ddVendor16,
    .ddVendor17,
    .ddVendor18,
    .ddVendor19 {
      max-width: 20%;
      min-width: 20%;
      padding: 0;
    }

    .btnVendor1,
    .btnVendor2,
    .btnVendor3,
    .btnVendor0,
    .btnVendor4,
    .btnVendor5,
    .btnVendor6,
    .btnVendor7,
    .btnVendor8,
    .btnVendor9,
    .btnVendor10,
    .btnVendor11,
    .btnVendor12,
    .btnVendor13,
    .btnVendor14,
    .btnVendor15,
    .btnVendor16,
    .btnVendor17,
    .btnVendor18,
    .btnVendor19 {
      max-width: 3%;
    }

    .ddRemitTo1,
    .ddRemitTo2,
    .ddRemitTo3,
    .ddRemitTo0,
    .ddRemitTo4,
    .ddRemitTo5,
    .ddRemitTo6,
    .ddRemitTo7,
    .ddRemitTo8,
    .ddRemitTo9,
    .ddRemitTo10,
    .ddRemitTo11,
    .ddRemitTo12,
    .ddRemitTo13,
    .ddRemitTo14,
    .ddRemitTo15,
    .ddRemitTo16,
    .ddRemitTo17,
    .ddRemitTo18,
    .ddRemitTo19 {
      padding: 0;
      min-width: 50%;
    }

    .txtSharePct1,
    .txtSharePct2,
    .txtSharePct3,
    .txtSharePct0,
    .txtSharePct4,
    .txtSharePct5,
    .txtSharePct6,
    .txtSharePct7,
    .txtSharePct8,
    .txtSharePct9,
    .txtSharePct10,
    .txtSharePct11,
    .txtSharePct12,
    .txtSharePct13,
    .txtSharePct14,
    .txtSharePct15,
    .txtSharePct16,
    .txtSharePct17,
    .txtSharePct18,
    .txtSharePct19 {
      padding: 0;
      max-width: 15%;

      .col {
        padding: 0;
      }
    }

    .chkboxCMA1,
    .chkboxCMA2,
    .chkboxCMA3,
    .chkboxCMA0,
    .chkboxCMA4,
    .chkboxCMA5,
    .chkboxCMA6,
    .chkboxCMA7,
    .chkboxCMA8,
    .chkboxCMA9,
    .chkboxCMA10,
    .chkboxCMA11,
    .chkboxCMA12,
    .chkboxCMA13,
    .chkboxCMA14,
    .chkboxCMA15,
    .chkboxCMA16,
    .chkboxCMA17,
    .chkboxCMA18,
    .chkboxCMA19 {
      max-width: 9%;
      padding: 0;

      .mb-3 {
        margin-bottom: 0.6rem !important;
      }
    }
  }

  .grpbxInspectorRemarks {

    // bottom: 838px;
    // bottom: 595px;
    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #f3f0f0;

    }
  }

  .grpbxValueCalculation {

    input {
      text-align: right;
    }

    padding-left: 0;
    max-width: 35%;
    min-width: 35%;

    .card-header {
      display: none
    }

    .card {
      min-height: 1015px;
      border: none;
      background-color: #f3f0f0;
    }

    //   // label{
    //   //   width: 100% !important;
    //   // }

    //   .textboxwidget-container{
    //     label{
    //       width: 175px;
    //       text-align: right;
    //     }
    //     padding: 0;
    //   }

    //   // .lblWtIncludingVehicle ,.lblWtOfVehicle ,.lblGrossWeight ,.lblForeignMaterial ,.lblWtlessFM ,
    //   // .lblExcessMoisture ,.lblNetWeight ,.lblLSK ,.lblNetWtExclLSK ,.lblKernelValue  ,.lblELKPremium ,
    //   // .lblTotal ,.lblDamage ,.lblAccessFM ,.lblExcessSplits ,.lblAFlavus ,.lblTotalDiscounts ,
    //   // .lblNetValPerTonExclSLK ,.lblValPerPoundExclSLK ,.lblValPerPoundInclSLK ,.lblOI ,.lblH ,
    //   // .lblTotalLoadVal ,.lblG {
    //   //   label{
    //   //     width: 250px;
    //   //     text-align: right;
    //   //   }
    //   //   min-width: 50%;
    //   //   max-width: 50%;
    //   // }

    //   // .lblWtIncludingVehicleValue ,.lblWtOfVehicleValue ,.lblGrossWeightValue ,.lblForeignMaterialValue ,.lblWtlessFMValue ,
    //   // .lblExcessMoistureValue ,.lblNetWeightValue ,.lblLSKValue ,.lblNetWtExclLSKValue,.lblValPerPoundInclSLKValue ,.lblOIValue ,.lblHValue ,
    //   // .lblTotalLoadValValue ,.lblNetValPerTonExclSLKValue {
    //   //   max-width: 50%;
    //   // }
    //   .txtKernelValueTon  ,.txtELKPremiumTon ,
    //   .txtTotalTon ,.txtDamageTon ,.txtAccessFMTon ,.txtExcessSplitsTon ,.txtAFlavusTon ,.txtTotalDiscountsTon,.txtValPerPoundExclSLK ,.txtG 
    //   // .
    //   {
    //     .col{
    //       padding-right: 0;
    //     }
    //   }
    // //  .lblPerLb2 ,.lblPerLb3 ,.lblGValue {
    // //     max-width: 25%;
    // //   }

    //   .lblValueCalculation {
    //     max-width: 50%;
    //     label{
    //       text-align: left;
    //       font-weight: bold;
    //     }
    //   }

    //   .lblPerTon {
    //     max-width: 25%;
    //     margin-left: 50%;
    //     label{
    //       text-align: center;
    //     }
    //   }
    //   .lblPerLb {
    //     max-width: 25%;
    //     label{
    //       text-align: center;
    //     }
    //   }
    .textboxwidget-container {
      label {
        width: 175px;
        text-align: right;
      }

      padding: 0;
    }


    .txtKernelValueTon,
    .txtELKPremiumTon,
    .txtTotalTon,
    .txtDamageTon,
    .txtAccessFMTon,
    .txtExcessSplitsTon,
    .txtAFlavusTon,
    .txtTotalDiscountsTon,
    .txtValPerPoundExclSLK,
    .txtG {
      .col {
        padding-right: 0;
      }
    }

    .txtG,
    .txtValPerPoundExclSLK {
      input {
        max-width: 100%;
        min-width: 130%;
      }
    }

    .lblPerLb3,
    .lblPerLb2 {
      position: relative;
      left: 20px;
    }

    .txtNetWtExclLSK,
    .txtLSKvc,
    .txtNetWeight,
    .txtExcessMoisture,
    .txtWtlessFM,
    .txtForeignMaterial,
    .txtGrossWeight,
    .txtWtOfVehicle,
    .txtWtIncludingVehicle,
    .txtOI,
    .txtH,
    .txtTotalLoadVal,
    .txtNetValPerTonExclSLK,
    .txtValPerPoundInclSLK {
      min-width: 76%;
      max-width: 76%;
    }

    .txtOI .txtWtOfVehicleVal {
      min-width: 30%;
      max-width: 30%;

      label {
        display: none;
      }

      .col {
        padding-left: 0;
      }

    }

    .lblValueCalculation {

      max-width: 50%;

      label {
        text-align: left;
        font-weight: bold;
      }
    }

    .lblPerTon {
      max-width: 25%;
      margin-left: 50%;

      label {
        text-align: center;
      }
    }

    .lblPerLb {
      max-width: 25%;

      label {
        text-align: center;
      }
    }
  }

  .grpbxGradeCalculation {
    // bottom: 851px;
    // bottom: 597px;
    padding-right: 5px;
    min-width: 50%;

    .card-header {
      display: none
    }

    input {
      text-align: right;
      padding-right: 6px;
      padding-left: 6px;
    }

    .card {
      min-height: 940px;
      border: none;
      background-color: #f3f0f0;
    }

    // .card:first-child{
    //   min-height: 890px;
    // }
    .grpbxVirginiaGrading .card {
      min-height: 184px !important;
    }

    .lblGradeCalculation {

      label {
        text-align: left;
        font-weight: bold;
      }
    }

    .txtFMSampleWt,
    .txtProbingPattern,
    .txtCleanSampleWt,
    .txtValenciaCleanSampleWt {
      label {
        width: 145px;
        text-align: right;
      }
    }

    .lblCornFound {
      margin-left: 16%;

      label {
        width: 100%;
        text-align: right;
      }
    }

    .chkboxCornFound {
      max-width: 30%;
      margin-bottom: 0.5%;
      padding-left: 0;

      .mb-3 {
        margin-bottom: 0.6rem !important;
      }

      .col {
        padding-left: 0;
      }
    }

    .lblGrams1,
    .lblGrams2 {
      max-width: 15%;
      margin-left: 20%;

      label {
        width: 100%;
        text-align: center;
      }
    }

    .lblPct1,
    .lblPct2 {
      max-width: 15%;

      label {
        width: 100%;
        text-align: center;
      }
    }

    .txtSMKRSGr,
    .txtFreezeDamageGr,
    .txtSMK1Gr,
    .txtSMK3Gr,
    .txtSMK2Gr,
    .txtSMK4Gr,
    .txtConcealedRMDGr,
    .txtCrkOrBrkShellsGr,
    .txtDiscoloredShellsGr,
    .txtHullBrightness,
    .txtSoundSplitsGr,
    .txtJumboGr,
    .txtELKGr,
    .txtOtherKernelsGr,
    .txtForeignMaterialGr,
    .txtTotalDamageGr,
    .txtLSKGr,
    .txtHullsGr,
    .txtFancyGr {
      max-width: 35%;
      min-width: 35%;
      padding-right: 0;

      label {
        width: 101px;
        text-align: right;
      }
    }

    .txtTotalSMK,
    .txtTotalKrnls,
    .txtTotalKernelsHulls {
      max-width: 50%;
      min-width: 50%;
      padding-right: 0;

      label {
        width: 101px;
        text-align: right;
      }

      .d-flex {
        max-height: 33px;
      }
    }

    .txtTotalSMK,
    .txtTotalKrnls,
    .txtTotalKernelsHulls {
      .col {
        display: flex;
        justify-content: end;
      }

      input {
        max-width: 50%;
        min-width: 50%;
      }
    }

    .txtSMK1Gr,
    .txtSMK2Gr,
    .txtSMK3Gr,
    .txtSMK4Gr {
      label {
        width: 75px;
      }

      input {
        max-width: 46%;
        text-align: left !important;
        position: relative;
        top: 15px;
      }

      .col {
        padding-left: 5px;
      }
    }

    .txtSMKRSPct,
    .txtFreezeDamagePct,
    .txtSMK1Pct,
    .txtSMK3Pct,
    .txtSMK2Pct,
    .txtSMK4Pct,
    .txtConcealedRMDPct,
    .txtCrkOrBrkShellsPct,
    .txtDiscoloredShellsPct,
    .txtHullBrightnessPct,
    .txtSoundSplitsPct,
    .txtJumboPct,
    .txtELKPct,
    .txtOtherKernelsPct,
    .txtForeignMaterialPct,
    .txtTotalDamagePct,
    .txtLSKPct,
    .txtHullsPct,
    .txtFancyPct {
      max-width: 15%;
      min-width: 15%;
      padding: 0;

      .col {
        padding-left: 0;
      }
    }

    .txtDamageSplits {
      input {
        max-width: 38%;
        min-width: 38%;
      }
    }

    .txtDamageSplits,
    .txtOtherForeignMaterials,
    .txtKernelsREL,
    .txtMoisture,
    .txtKernelsRPS,
    .txtMeterReading,
    .txtDamagedKRS,
    .txtELKDamage,
    .txtBluePanWt {
      label {
        width: 101px;
        text-align: right;
      }

      .d-flex {
        max-height: 33px;
      }

      padding-right: 0;
    }

    .txtELKDamage,
    .txtDamagedKRS {
      input {
        background-color: black;
        color: white;
      }
    }

    .txtKernelsREL,
    .txtMoisture,
    .txtKernelsRPS,
    .txtMeterReading,
    .txtDamagedKRS,
    .txtELKDamage,
    .txtBluePanWt {
      input {
        width: 50.5%;
        margin-left: auto;
      }
    }

    .lblVirginiaGrading {
      label {
        text-align: left;
        font-weight: bold;
      }
    }

    .txtSampleWt,
    .txtGrams,
    .txtPercent {
      label {
        width: 65px;
        text-align: right;
      }

      padding: 0;
    }

    .lblFlavusFound {
      max-width: 19%;

      padding-right: 0;

      label {
        width: 150px;
        text-align: right;
      }

    }

    .chkboxFlavusFound {

      .mb-3 {
        margin-bottom: 0.4rem !important;
      }

      .col {
        padding-left: 0;
      }

      .d-flex {
        height: 27px;
      }
    }
  }

  .grpbxHMC {
    // bottom: 851px;
    // bottom: 597px;
    padding-right: 5px;
    padding-left: 0;
    max-width: 15%;

    .card-header {
      display: none
    }

    .card {
      min-height: 1015px;
      border: none;
      background-color: #f3f0f0;
    }

    .textboxwidget-container {
      padding-right: 0;

      label {
        width: 75px;
        text-align: right;
      }

      .col {
        padding-right: 0rem;
      }
    }

    .btnConvertHMC {
      max-width: 80%;
      margin-left: 10%;

      button {
        width: 100%;
      }
    }

    .lblHMCConvertedGrades {
      max-width: 70%;
      margin-left: 15%;

      label {
        text-align: center;
        font-weight: bold;
      }
    }
  }

  .grpbxActions {
    bottom: 0;
    position: Sticky;

    .card {
      border: none;
      background-color: #f3f0f0;

      .card-header {
        display: none
      }
    }

    .lblCorpYear2 {
      margin-left: 17%;
      position: relative;
      bottom: 16px;
      font-weight: 500;
      color: red;
      font-size: 16px;
      background-color: lightgrey;
    }

    .lblChangedBy,
    .lblAddedBy,
    .lblAuditCount {
      max-width: 10%;

      label {
        width: 100%;
        text-align: right;
      }
    }

    .lblChangedByValue,
    .lblAuditCountValue {
      min-width: 20%;
      max-width: 20%;
      padding-left: 0;

      label {
        display: none;
      }

      .col {
        bottom: 7px;
        padding-left: 0;
      }

    }
    .lblAddedByValue {
      max-width: 30%;
      min-width: 30%;
      margin-right: 60%;
      padding-left: 0;

      label {
        display: none;
      }

      .col {
        bottom: 7px;
        padding-left: 0;
      }
    }

    .lblAddedByValue,
    .lblAddedBy {
      bottom: 20px;
    }

    .lblChangedBy,
    .lblChangedByValue {
      bottom: 40px;
    }
    button {
      width: 85%;
      height: 40px;
    }
    .btnCreate1007 {
      max-width: 10%;
      top: 20px;
      margin-right: 15%;      
    }
    .btnReprint {
      max-width: 10%;
      margin-left: 10%;
      top: 20px;
      min-height: 40px;
    }
    .btnCreate,
    .btnSave,
    .btnCancel {
      max-width: 7%;
      top: 20px;
      button {
        width: 90%;
        height: 40px;
      }
    }

    .btnCreate {
      margin-left: auto;
    }
  }

  .grpbxWeightVendor,
  .grpbx1007Peanut {

    .card-header {
      display: none;
    }

    max-width: 50%;
    min-width: 50%;

    .grpbxWeightTickets,
    .grpbxVendorSplits,
    .grpbx1007Control,
    .grpbxPeanutInfo {
      width: 100%;
      min-width: 100%;

      .card {
        background-color: #f3f0f0;
      }
    }
  }

  .grpbxWeightVendor {
    padding-right: 0;

    .card {
      min-height: 100%;
      background-color: #333E48;
    }

    .grpbxWeightTickets,
    .grpbxVendorSplits {
      padding-left: 0;

    }

    .txtEWtInclVeh {
      max-width: 40%;
      min-width: 40%;

      input {
        max-width: 74px;
      }
    }

    .txtEWtOfVeh {
      max-width: 40%;
      min-width: 40%;
      position: relative;
      right: 65px;
    }

    .txtEWtOfPnut {
      max-width: 40%;
      min-width: 40%;
      position: relative;
      left: 357px;
      bottom: 36px;
    }
  }

  .grpbx1007Peanut {
    padding-left: 5px;
    .card {
      background-color: #333E48;
    }

    .grpbx1007Control,
    .grpbxPeanutInfo {
      padding-right: 0;
    }
  }

  /*END_USER_CODE_FOR_.SCSS*/  
}