.WarehouseReceipts-WarehouseReceiptRedeemByLoan,.ContractManagement-WarehouseReceiptRedeemByLoan {
  /*START_USER_CODE_FOR_.SCSS*/
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .txtWireTransferData {
    max-width: 30%;
    min-width: 30%;
    padding-right: 0;
  }
  .txtWireXferData{
    visibility: hidden;
  }
  .txtWireTransferData2,.txtWireTransferData3,.txtWireTransferData4 ,.txtWireTransferData5 ,.txtWireTransferData6   {
    padding: 0;
    margin-left: -20px;
    .col{
      padding-right: 5px;
      padding-left: 5px;
    }
  }
  .txtWireTransferData3 {
    max-width: 9%;
    // margin-left: -15px;
  }
  .txtWireTransferData2 {
    max-width: 8%;
  }
  .txtWireTransferData5,.txtWireTransferData6  {
    max-width: 12%;
  }
  .txtWireTransferData4 {
    max-width: 7%;
  }
  .txtConcordia1,.txtWireXferData  {
    max-width: 17.5%;
  }
  .txtConcordia3{
    min-width:32%;
    max-width: 32%;
  }
  .txtConcordia1,.txtConcordia3  {
    padding-right: 0;
  }
  .txtConcordia2 {
    padding: 0;
    max-width: 9%;
  }
  .txtWireXferData {
    padding-left: 0;
  }
  .txtConcordia2,.txtConcordia3{
    margin-left: -16px;
    .col{
      padding-right: 0%;
      padding-left: 0;
    }
  }
  
 
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxWarehouseReceiptReedembyyLoanNum {
    .grpbxSearch {
      .chkboxAll {
        max-width: 2.5%;
        min-width: 2.5%;
        padding: 0;
        .d-flex{
          display: block !important;
          .form-label{
            width: 100%;
            text-align: center;
            margin-bottom: 0 !important;
          }
          label{
          }
          .col{
            height: 26px;
            input{
              position: relative;
              right: 6px;
            }
            
            .form-check{
              label{
               display: none;
             }}
          }
        }
      }
      .txtLoanNum1{
        max-width: 16%;
        padding: 0;
        margin-left: 21.5%;
        bottom: 10px;
        label{
          width: 60%;
          text-align: right;
        }
      }
      .txtLoanNum2{
        padding: 0;
        max-width: 4.5%;
        bottom: 10px;
        .col{
          padding: 0;
        }
      }
      .txtLoanNum3{
        padding: 0;
        max-width: 8%;
        min-width: 8%;
        bottom: 10px;
      }
      .btnSearch{
        min-width: 10%;
        max-width: 10%;
        margin-bottom: 0.375rem !important;
        bottom: 10px;
        button{
          width: 90%;
        }
      }
      .radioScreenmode{
        max-width: 13%;
        margin-top: 0.25rem;
        margin-left: 2%;
        border: 1px solid #ccc;
        .d-flex{
          display: block !important;
          .form-label{
            margin-bottom: 0;
            padding-top: 0 !important;
          }
          .col{
            padding: 0;
          }
        }
        
      }
      .chkboxCCC500 {
        max-width: 12%;
        min-width: 12%;
        padding-left: 0;
        bottom: 10px;
        input{
          margin-left: 75%;
          bottom: 3px;
        }
      }
      .btnUpdate{
        max-width: 10%;
        min-width: 10%;
        margin-bottom: 0.375rem !important;
        bottom: 10px;
        button{
          width: 90%;
        }
      }
    }
    .gridWarehouseReceiptRedeembyLoan {
      .card-header{
        display: block !important;
      } 
      .rdt_TableCell:nth-child(1),.rdt_TableCell:nth-child(2),
      .rdt_TableCell:nth-child(3),.rdt_TableCell:nth-child(4),
      .rdt_TableCell:nth-child(5),.rdt_TableCell:nth-child(6),
      .rdt_TableCell:nth-child(7),.rdt_TableCell:nth-child(12),
      .rdt_TableCell:nth-child(13){
        background-color: #c0bcbc;
      }
      .rdt_TableCell:nth-child(5),.rdt_TableCell:nth-child(6),
      .rdt_TableCell:nth-child(7),.rdt_TableCell:nth-child(8),
      .rdt_TableCell:nth-child(9),.rdt_TableCell:nth-child(10),
      .rdt_TableCell:nth-child(11),.rdt_TableCell:nth-child(12){
       input{
         text-align: right;
       }
      }
      .rdt_TableCol:nth-child(7),.rdt_TableCol:nth-child(9),.rdt_TableCol:nth-child(10),
      .rdt_TableCol:nth-child(11),.rdt_TableCol:nth-child(12){
        text-align: center;
        justify-content: center !important;
      }
      .rdt_TableCol:nth-child(1),
      .rdt_TableCell:nth-child(1) {
        min-width: 50px;
        max-width: 50px;
      }
      .rdt_TableCol:nth-child(2),
      .rdt_TableCell:nth-child(2) {
        min-width: 70px;
        max-width: 70px;
      }
      .rdt_TableCol:nth-child(3),
      .rdt_TableCell:nth-child(3) {
        min-width: 90px;
        max-width: 90px;
      }
      .rdt_TableCol:nth-child(4),
      .rdt_TableCell:nth-child(4) {
        min-width: 40px;
        max-width: 40px;
      }
      .rdt_TableCol:nth-child(5),
      .rdt_TableCell:nth-child(5) {
        min-width: 110px;
        max-width: 110px;
      }
      .rdt_TableCol:nth-child(6),
      .rdt_TableCell:nth-child(6) {
        min-width: 110px;
        max-width: 110px;
      }
      .rdt_TableCol:nth-child(7),
      .rdt_TableCell:nth-child(7) {
        min-width: 110px;
        max-width: 110px;
      }
      .rdt_TableCol:nth-child(8),
      .rdt_TableCell:nth-child(8) {
        min-width: 110px;
        max-width: 110px;
      }
      .rdt_TableCol:nth-child(9),
      .rdt_TableCell:nth-child(9) {
        min-width: 110px;
        max-width: 110px;
      }
      .rdt_TableCol:nth-child(10),
      .rdt_TableCell:nth-child(10) {
        min-width: 110px;
        max-width: 110px;
      }
      .rdt_TableCol:nth-child(11),
      .rdt_TableCell:nth-child(11) {
        min-width: 110px;
        max-width: 110px;
      }
      .rdt_TableCol:nth-child(13),
      .rdt_TableCell:nth-child(13) {
        min-width: 60px;
        max-width: 60px;
      }
    }
    .grpbxTotalC{.WarehouseReceipts-WarehouseReceiptRedeemByLoan,.ContractManagement-WarehouseReceiptRedeemByLoan {
      /*START_USER_CODE_FOR_.SCSS*/
      /*START_USER_CODE_FOR_.SCSS*/
      h1{
        display: none;
      }
      // background-color: #333E48;
  background: transparent;
      .groupboxwidget-container{
        .card{
          border: none;
          background-color:  #f3f0f0;
          .card-header{
            display: none;
          }
        }
      }
      .grpbxWarehouseReceiptReedembyyLoanNum {
        .grpbxSearch {
          .chkboxAll {
            max-width: 2.5%;
            min-width: 2.5%;
            padding: 0;
            .d-flex{
              display: block !important;
              .form-label{
                width: 100%;
                text-align: center;
                margin-bottom: 0 !important;
              }
              label{
              }
              .col{
                height: 26px;
                input{
                  position: relative;
                  right: 6px;
                }
                 .form-check{
                  label{
                   display: none;
                 }}
              }
            }
          }
          .txtLoanNum1{
            max-width: 16%;
            padding: 0;
            margin-left: 22.5%;
            bottom: 10px;
            label{
              width: 60%;
              text-align: right;
            }
          }
          .txtLoanNum2{
            padding: 0;
            max-width: 4%;
            bottom: 10px;
            .col{
              padding: 0;
            }
          }
          .txtLoanNum3{
            padding: 0;
            max-width: 8%;
            min-width: 8%;
            bottom: 10px;
          }
          .btnSearch{
            min-width: 10%;
            max-width: 10%;
            margin-bottom: 0.375rem !important;
            bottom: 10px;
            button{
              width: 90%;
            }
          }
          .radioScreenmode{
            max-width: 13%;
            margin-top: 0.25rem;
            margin-left: 2%;
            border: 1px solid #ccc;
            .d-flex{
              display: block !important;
              .form-label{
                margin-bottom: 0;
                padding-top: 0 !important;
              }
              .col{
                padding: 0;
              }
            }
            
          }
          .chkboxCCC500 {
            max-width: 12%;
            min-width: 12%;
            padding-left: 0;
            bottom: 10px;
            input{
              margin-left: 75%;
              bottom: 3px;
            }
          }
          .btnUpdate{
            max-width: 10%;
            min-width: 10%;
            margin-bottom: 0.375rem !important;
            bottom: 10px;
            button{
              width: 90%;
            }
          }
        }
        .gridWarehouseReceiptRedeembyLoan {
          .card-header{
            display: block !important;
          } 
          .activeRow{
            background-color: #e3f2fd !important;
          }
        }
        .grpbxTotalC{
          .textboxwidget-container{
            min-width: 9%;
            max-width: 9%;
            padding-right: 0.2rem;
            padding-left: 0.5rem;
            padding-left:  0;
            .col{
              padding: 0;
            }
            input{
              text-align: right;
            }
          }
          .txtTotalMarketGain,.txtTotalLoanRepayAmount,.txtTotalHandlingAmount,.txtTotalInterestAmount,.txtTotalNetRepayAmount{
            min-width: 9.5%;
            max-width: 9.5%;
          }
          .txtTotalTons{
            max-width: 30%;
            min-width: 30%;
            label{
              text-align: center;
              width: 71%; 
              text-align:right;
              position: relative;
              right: 7px;
            }
            input{
              text-align: right;
            }
          }
        }
        .grpbxTotal {
          label{
            width: 200px;
            text-align: right;
          }
          .txtRedemptionEndDate {
            margin-top: 2rem;
              #txtRedemptionEndDateParent{
              width: 140%;
            }
            .dropdown-menu{
              top: -105px !important;
              left: 155px !important;
             }
          }
          .txtTotalprincipalLiquidated,.txtTotalMrktGain,.txtTotalHandling,.txtTotalInterestDue,.txtTotalAmountDue{
            input{
              text-align: right;
            }
          }
        }
        .grpbxRepayment {
          label{
            width: 80px;
            text-align: right;
          }
          .lblRepayment {
            max-width: 80%;
            margin-left: 5%;
            min-width: 80%;
            label{
              font-weight: bold;
            }
          }
          .txtRepayVendor {
            max-width: 27%;
            min-width: 27%;
            padding-right: 0;
          }
          .btnRepayVendor {
            max-width: 5%;
            min-width: 5%;
            margin-bottom: 0.3rem;
            position: relative;
            // right: 60%;
          }
          .txtRepayVndr {
            max-width: 60%;
            min-width: 60%;
            label{
              display: none;
            }
          }
          .ddRepayRemit {
            #ddItem_ {
                height: 25px;
              }
            
              .dropdown-toggle:empty::after {
                margin-left: auto;
              }
            
              button {
                height: 30px;
              }
            .col{
              max-width: 60%;
            }
          }
          .chkboxWireTransfer {
            max-width: 80px;
            min-width: 80px;
            padding: 0;
            bottom: 5px;
            .form-label{
              display: none;
            }
            .col{
              padding: 0;
              .form-check-label{
                width: 75px;
              }
            }
          }
          
          .btnPurchase {
            max-width: 16%;
            margin-left: 70%;
            margin-top: 3.5rem;
            button{
              width: 101%;
            }
          }
          .btnClose{
            max-width: 12%;
            margin-top: 3.5rem;
            button{
              width: 90%;
            }
          }
        }
      } 
      /*END_USER_CODE_FOR_.SCSS*/
    }
    
      .textboxwidget-container{
        min-width: 9%;
        max-width: 9%;
        padding-right: 0.2rem;
        padding-left: 0.5rem;
        padding-left:0;
        .col{
          padding: 0;
        }
        input{
          text-align: right;
        }
      }
      .txtTotalMarketGain,.txtTotalLoanRepayAmount,.txtTotalHandlingAmount,.txtTotalInterestAmount,.txtTotalNetRepayAmount{
        min-width: 9.5%;
        max-width: 9.5%;
      }
      .txtTotalTons{
        max-width: 30%;
        min-width: 30%;
        label{
          width: 71%; 
          text-align:right;
          position: relative;
          right: 7px;
        }
      }
    }
    .grpbxTotal {
      label{
        width: 200px;
        text-align: right;
      }
      .txtRedemptionEndDate {
        margin-top: 2rem;
          #txtRedemptionEndDateParent{
          width: 140%;
        }
        .dropdown-menu{
          top: -105px !important;
          left: 155px !important;
         }
      }
      .txtTotalprincipalLiquidated,.txtTotalMrktGain,.txtTotalHandling,.txtTotalInterestDue,.txtTotalAmountDue{
        input{
          text-align: right;
        }
      }
    }
    .grpbxRepayment {
      label{
        width: 80px;
        text-align: right;
      }
      .lblRepayment {
        max-width: 80%;
        margin-left: 5%;
        min-width: 80%;
        label{
          font-weight: bold;
        }
      }
      .txtRepayVendor {
        max-width: 27%;
        min-width: 27%;
        padding-right: 0;
      }
      .btnRepayVendor {
        max-width: 5%;
        min-width: 5%;
        margin-bottom: 0.3rem;
        position: relative;
        // right: 60%;
      }
      .txtRepayVndr {
        max-width: 60%;
        min-width: 60%;
        .col input{
          background: transparent;
          border: none;
          margin-left:-35px;
        }
        label{
          display: none;
        }
      }
      .ddRepayRemit {
        #ddItem_ {
            height: 25px;
          }
        
          .dropdown-toggle:empty::after {
            margin-left: auto;
          }
        
          button {
            height: 30px;
          }
        .col{
          max-width: 60%;
        }
      }
      .chkboxWireTransfer {
        max-width: 80px;
        min-width: 80px;
        padding: 0;
        bottom: 5px;
        .form-label{
          display: none;
        }
        .col{
          padding: 0;
          .form-check-label{
            width: 75px;
          }
        }
      }
     
      .btnPurchase {
        max-width: 16%;
        margin-left: 70%;
        margin-top: 3.5rem;
        button{
          width: 94%;
        }
      }
      .btnClose{
        max-width: 12%;
        margin-top: 3.5rem;
        button{
          width: 90%;
        }
      }
    }
  } 
  /*END_USER_CODE_FOR_.SCSS*/
}
