.SystemMaintenanceMasterManagement-CountySetup,.ContractManagement-CountySetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxCountySetup {
    .row {
      justify-content: end;
    }
    .card {
      background-color: #c0bcbc;
    }
    .card-header {
      display: none;
    }
    .dropdown-menu.show{
      overflow-y:scroll!important;
      max-height:25vh!important;
      }
    .btnAddS,
    .btnEditS,
    .btnDleteS,
    .btnAddC,
    .btnEditC,
    .btnDeleteC {
      max-width: 4%;
      margin-right: 2%;
    }
    .ddState,
    .lstCounty {
      bottom: 10px;
    }
  }
  .cmmndCntnreDisableClose {
    .row{
    background-color: #f3f0f0;
    }
    .btneDisable {
      max-width: 22%;
    }
    .btnClose {
      max-width: 5%;
      margin-left:46%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
