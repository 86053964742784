
.SystemMaintenanceSpecialFunctions-HighMoistureSetup,.ContractManagement-HighMoistureSetup{
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
   display:none
 }
//  background-color: #333E48;
 background: transparent;
 .grpbxHighMoisturSetup        {
//  margin-top: 1rem;
 margin-bottom: 0.2rem !important;
 .card-header{
   display:none
 }
 .card{
   padding-top: 0.5rem;
   border: none;
   background-color: #f3f0f0;
 }
 .dropdown-menu.show {
   overflow-y: scroll !important;
   max-height: 25vh !important;
   }
 label{
   width: 120px;
   text-align: right;
 }
 .textboxwidget-container{
   min-width: 40%;
   max-width: 40%;
 }
 .dropdownfieldwidget-container{
   min-width: 60%;
   max-width: 60%;
 }
 .ddGradeType{
   .dropdown-toggle:empty::after {
     margin-left: auto;
   }
   button{
   height:30px;
   }
   #ddItem_{
    height: 25px;
  }
 }
 }
 .cmmndCntnrActions1{
 .row{
   background-color: #f3f0f0;
  }
 
 .buttonwidget-container{
   max-width: 15%;
   min-width: 15%;
   button{
     width: 90%;
   }
 }
 .btnSearch{
   margin-left: auto;
 }
 
 }
 .gridHighMoistureSetup {
 min-width: 100%;
 .card{
   padding-top: 0;
 }
 }
 .cmmndCntnrActions2{
 .row{
   background-color: #f3f0f0;
   padding-top: 0.25rem;
  }
 .buttonwidget-container{
   max-width: 15%;
   min-width: 15%;
   button{
     width: 90%;
   }
 }
 .btnAdd{
   margin-left: auto;
 }
 
 }
 /*END_USER_CODE_FOR_.SCSS*/
 }
 