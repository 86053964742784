.SystemMaintenanceMasterManagement-BuyingPointTransfer,.ContractManagement-BuyingPointTransfer {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .card-header {
    display: none;
  }
  .grpbxBuyingPointDetails {
    .card {
      background-color: #c0bcbc;
      min-height: 300px;
    }
    .lblBuyingPoint {
      max-width: 50%;
    }
    .lblBuyingPointValue {
      color: blue;
      position: relative;
    bottom: 10px;
      label{
      position: relative;
      bottom: 21px;
      display: none;
      }
    }
    .txtTransferBuyingPoint {
      max-width: 80%;
      input {
        max-width: 12%;
        left: 15%;
        position: relative;
      }
    }
    .ddTransferBuyingPoint {
      max-width: 30%;
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 30vh !important;
        }
    }
  }
  .cmmndCntnrActions {
    // background-color: white;
    .row{
      background-color: #f3f0f0;
    }
    .btnOk {
      max-width: 4%;
      margin-left: 90%;
      .row{
        position: relative;
        right: 30px;
      }
    }
    .btnCancel {
      max-width: 6%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}

