// .col-lg-12{
//     min-width: fit-content;
// }
@media print {
    .centerWidth {
        display: flex;
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .centerWidthHeader {
        display: block !important;
        padding-right: 0 !important;
        padding-left: 0 !important;

        .grpbxHeader {
            display: block !important;

            .grpbxWithoutLogo {
                min-width: 92% !important;
                max-width: 92% !important;

                .lnkScrNmbr {
                    min-width: 11%;
                }
            }

            .LogoImage {
                max-width: 7% !important;
                top: 10px;
                position: relative;
            }
        }
    }
}
body{
    background-color: #f3f0f0;
    // overflow: overlay !important;
      
}
.textboxWidgetClass {
    border-radius: 0.2rem;
    box-shadow: 1px 1px 0px gray inset;
}
.textboxWidgetClass:focus, .form-control:focus{
    border-color: black;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(105, 98, 105, 0.5);
}
 .form-control:disabled,  .form-control[readonly]{
    box-shadow: none;
}
.dropdown-toggle{

    box-shadow: 0px -0.5px 0px 1.2px grey !important;
     border-radius: 1px;

}

.buttonWidgetClass {

    box-shadow: 1px 1px 0px grey;

}
nav.navbar.navbar-expand-lg.navbar-dark.bg-dark.fixed-top {
    display: none;
}
.main{
    margin: 1rem;
    margin-top: 0rem;
    // background-color: #333E48;
    // background-image: url(../src/assets/img/BgLogo.png);
    // background-repeat:no-repeat ;
    
    // background-size: 100% 100%;
    // min-height: 100vh;
    // background-attachment: fixed;
    // object-fit: fill;
    // background-position: right bottom;
    
}

//     body {
//         background-image: url(../src/assets/img/BgLogo.png);
//         background-repeat: no-repeat;
//         background-size: 100% 100%;
//         min-height: 100vh;
//         background-attachment: fixed;
//    }

.card-body {
    padding-top: 0;
    padding-bottom: 0;
}
body{
    background-color: white !important;
    background-image: url(../src/assets/img/BgLogo.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        min-height: 100vh;
        background-attachment: fixed;
}
.form-group{
    margin-bottom: 0.25rem !important;
}
.mb-3{
    margin-bottom: 0.25rem !important;
}
.btn,body,.form-control{
    font-size: 0.75rem;
}
html{
    padding-bottom: 0;
}
.btn-secondary {
    color: #212529;
    background-color: #bbbbbb;
    border-color: #e9ecef;
}
.input-group-text{
    padding: 0.275rem 0.75rem;
}
.rdt_TableHeadRow{
    background-color:#c0bcbc !important;
}
.GridWidget .rdt_TableCol {
    border-right: 1px solid #eeeded !important;
    justify-content: center;
}
.GridWidget .rdt_TableCell {
    font-size: 0.7rem !important;
    border-right: 1px solid #eeeded !important;
    justify-content: center;
}
.rdt_TableRow{
    min-height: 35px !important;
}
.activeRow{
    color: white !important;
    background-color: hsl(221, 80%, 50%) !important;
    border-bottom-color: #FFFFFF !important;
   }
// form.row {
//     width: 1265px;
//     position: relative;
// }
.rdt_TableHead{
    position: sticky; 
    top: 0;
    z-index: 1;
   }
@media screen and (min-width:1281px) {
    form.row {
        position: relative;
        width: 82.4rem;
        // background-color: red;
    }

    .modal-dialog-scrollable,
    .modal-body {
        overflow: auto;

        form.row {
            position: relative;
            width: 74.7rem;
    // background-color: #333E48;
       background:transparent;
    // background-image: url(../src/assets/img/BgLogo.png);
        }
    }
}
@media screen and (max-width:1280px) {
    form.row {
        position: relative;
        width: 77.5rem; //77.5rem
        // background-color: yellow;
    }

    .modal-dialog-scrollable,
    .modal-body {
        overflow: auto;

        form.row {
            position: relative;
            width: 70rem; //71.8
        }
    }
}
.centerWidth{
    padding-left: 0px;
    display: grid;
    place-items: center;
    padding-right: 0;
    min-width: fit-content; // for color matching only not for zoom
    // background-color: #333E48;
}
.centerWidthHeader{
    display: grid;
    place-items: center;
}
.btn-secondary{
    background: #D9D9D6;
    color: #212529;
    border-color: #e9ecef;
  }
  .dropdown-toggle{
    box-shadow: none;
  }
  .dropdown{
    .btn-secondary{
        background: white;
        border: 2px solid lightgray;
    }
    .btn-secondary:active,.btn-secondary:focus{
      background: #5E86C5;
      color: white;
  }
  .btn-secondary:disabled{
    color: #212529;
    background-color: #bbbbbb;
    border-color: #e9ecef;
}
}
  .card{
    background-color: white !important;
    background: white !important;
}
.stripDesign{
max-width: 97.5%;
width: 97.5%;
min-width: 97.5%;
height: 23px;
padding-bottom: 4px;
text-align: center;
left: 1.2%;
position: relative;
}
.ContractManagement-header{
    position: sticky;
    top: 0em;
    z-index: 1002;
}
.Settlements-header{
    position: sticky;
    top: 0em;
    z-index: 1002;
}
.grpbxActions,.grpbxAction,.grpbxAcions,.grpAction {
    position: sticky;
    bottom: 0;
    z-index: 2;
}
.commandcontainerwidget-container{
    position: sticky;
    bottom: 0;
    z-index: 2;
}
.card-body {
    padding-top: 12px;
    padding-bottom: 0;
}
.commandcontainerwidget-container{
    //  max-width: 97.7%;
    //  left: 1.1%; 
    }
    .bootstrap-datetimepicker-widget{
        z-index: 2000;
    }
    .datetimepicker-input:active, .datetimepicker-input:focus,.bootstrap-datetimepicker-widget{
        z-index: 2000 !important;
    }