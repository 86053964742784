.GridWidget {
  min-width: 100%;
  .collapse {
    > div {
      overflow-x: auto;
      overflow-y: auto;
      max-height: 300px;
      min-height: auto;
      > div {
        display: block;
        // overflow-x: auto;
      }
    }
  }
  .card{
    border: 1px solid #eeeded !important;
  }
  .rdt_Table {
    display: table;
  }

  .rdt_TableCell {
    div:first-child {
      overflow: inherit;
    }
      font-size: 1rem;
  }

  .rdt_TableBody {
    overflow-x: hidden;
    // overflow-y: hidden;
    max-height: 100%;
  }
  .rdt_TableCol {
    font-size: 0.75rem;
  }
   .valRowSel{
    pointer-events: none;
  }
  table {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 35px;
  }
  th, td {
    // padding: 10px 30px;
    // margin: 20px;
    text-align: left;
  }
  th{
    font-weight:500;
    font-size: 12px;
    border: 0.2px solid;
    background-color: #c0bcbc;
    text-align: center;
  }
}
