.popover-actions {
  min-width: 120px;
}
.ok-cancel {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
}

.action-list {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.action-item button:hover {
  text-decoration: none;
}

.action-item i {
  margin-right: 5px;
}

.action-item a {
  text-decoration: none;
}

hr {
  margin-top: 0.375rem;
  margin-bottom: 0px;
}

.columns-parent {
  max-height: 174px;
  overflow-y: auto;
  padding-top: 0.375rem;
}

.columns-parent input[type="checkbox"] {
  margin-right: 5px;
}
