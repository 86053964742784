.WarehouseReceipts-EWRListUpdate,.ContractManagement-EWRListUpdate {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxEWRListUpdate {
    .grpbxSearch {
      .textboxwidget-container{
        min-width: 25%;
        max-width: 25%;
        label{
          width: 115px;
          text-align: right;
        }
      }
      .txtReceiptNumber {
        margin-left: 25%;
      }
      .btnSearch{
        min-width: 15%;
        max-width: 15%;
        margin-left: 5%;
        margin-right: 5%;
        top: 15px;
        button{
          width: 100%;
        }
      }
    }
  }
  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btnClose{
      min-width: 10%;
      max-width: 10%;
      margin-left: 45%;
      button{
        width: 90%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
