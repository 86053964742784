.SystemMaintenanceApplicationSupport-DeductionTrackingSummary,.ContractManagement-DeductionTrackingSummary {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxDeductionTrackingSummary {
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none;
      }
      .txtBuyingPoint ,.txtAccountVendor {
        max-width: 65%;
        min-width: 65%;
        label{
          width: 100px;
          text-align: right;
        }
      }
      .lblDeductionCode {
        min-width: 100%;
        max-width: 100%;
        label{
          margin-bottom: 0;
        }
      }
      .chkbox {
        display: none !important;
      }
      .btnViewDetails {
        max-width: 10%;
        min-width: 10%;
        margin-left: 89%;
        button{
          width: 100%;
        }
      }
      .gridDeductionData {
        min-width: 100%;
      }
      .lblTotal {
        max-width: 10%;
        min-width: 10%;
        label{
          font-weight: bold;
        }
      }
      .lblChargedTotal ,.lblCollectedTotal {
        max-width: 10%;
        min-width: 10%;
        label{
          width: 100%;
          text-align: left;
        }
      }
      .lblChargedTotal{
        margin-left: 60%;
      }
    }
  }

  .cmmndCntnrActions {
    .row{
      padding-top: 0.25rem;
      background-color: #f3f0f0;
      .btnClose,.btnExportToExcel,.btnPrint,.btnClearDeduction {
        max-width: 10%;
        min-width: 10%;
        button{
          width: 100%;
        }
      }
      .btnClose{
        margin-left: auto;
        margin-right: 22.5%;
      }
    }
    .btnExportToExcel,.btnPrint{
      margin-right: 1%;
    }
    .btn1{
      display: none;
    }
    .btnClearDeduction{
      margin-left: 1%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
