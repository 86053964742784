.SystemMaintenanceApplicationSupport-ManualAcctDistMaint,.ContractManagement-ManualAcctDistMaint {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxManualAcctDistMaint  {
    margin-bottom: 0.2rem !important;
    .card-header{
      display:none
    }
    .card{
      padding-top: 0.2rem;
      border: none;
      background-color: #f3f0f0;
      .textboxwidget-container{
        min-width: 30%;
        max-width: 30%;
        margin-right: 70%;
        padding-right: 0;
        label{
          width: 120px;
          text-align: right;
        }
      }
      .radioSourceType{
        min-width: 100%;
        .form-label{
          width: 120px;
          text-align: right;
        }
        .mb-3{
          display: flex;
          flex-wrap: wrap;
          .form-check{
            width: 25%;
            input{
              bottom: 2px;
            }
          }
        }
      }
      .ddTransferType{
        .dropdown-toggle:empty::after {
          margin-left: auto;
        }
        button{
        height:30px;
        }
        #ddItem_{
          height: 25px;
        }
      }
      .txtLocation ,.txtChargeLocation,.txtShipLocation{
        margin-right: 0;
        label{
          color: red;
        }
      }
      .txtAccountNum,.txtReason,.txtResponsibility,.txtDepartment,.txtSubDepartment,.txtChargeLocation,.txtAmount,.txtSC95InspNum,.txt1007SettleNum{
        label{
          color: red;
        }
      }
      .txtShipLocation,.txtTransferNum,.ddTransferType,.txtContractNum,.txtDeliveryAgree,.txtWhseRcptNum,.txtTradeInspNum,.txtTradeSettleNum,.txtCheckNum{
        label{
          color: red;
        }
      }
      .txtLocationVal,.txtChargeLocationVal,.txtShipLocationVal{
        padding: 0;
        margin-right: 30%;
        label{
          display: none;
        }
        .col{
          padding: 0;
          input{
            box-shadow: none;
            border: none;
            background: transparent;
            color: blue;
            padding-left: 0;
          }
        }
      }
      .txtShipLocation{
        margin-right: 0;
      }
      .txtShipLocationVal{
        input{
          box-shadow: none;
          border: none;
          background: transparent;
          color: blue;
          padding-left: 0;
        }
      }
      .txtTransferNum {
        margin-right: 0;
      }
      .ddTransferType {
        max-width: 30%;
        min-width: 30%;
        margin-right: 35%;
      }
      .txtReason {
        max-width: 80%;
        min-width: 80%;
        margin-top: 2rem;
      }
      .lblReason{
        label{
          color: red;
          font-weight: bolder;
          font-size: initial;
        }
      }
    }
  }
  .grpbxActions {
    .card{
      background-color: #f3f0f0;
      .card-header{
        display: none;
      }
      .lblAddedBy {
        min-width: 10%;
        max-width: 10%;
      }
      .lblAddedByValue {
        min-width: 30%;
        max-width: 30%;
        label{
          display: none;
        }
        .col{
          position: relative;
          right: 73px;
          bottom: 7px;
        }
      }
      .lblPostedOn {
        min-width: 20%;
        max-width: 20%;
      }
      .lblPostedOnValue {
        min-width: 20%;
        max-width: 20%;
        label{
          display: none;
        }
      }
      .btnAdd{
        min-width: 10%;
        max-width: 10%;
        margin-left: 40%;
        top: 10px;
        button{
          width: 90%;
        }
      }
      .btnCancel{
        min-width: 10%;
        max-width: 10%;
        top: 10px;
        button{
          width: 90%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
