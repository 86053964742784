.Settlements-KCMOdataSearch,
.ContractManagement-KCMOdataSearch {

  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none
  }

  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .grpbxKCMOdataSearch {
    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #f3f0f0;
    }

    .ddLocation {
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }

    .ddLocation,
    .txtFederalBuyingPoint,
    .txtSC95Inspection,
    .txt1007Settlement {
      min-width: 43.33%;

      label {
        width: 100px;
        text-align: right;
      }

      input {
        width: 40%;
      }
    }

    .txt1007SettleRangeFrom,
    .txtFileNumber {
      margin-left: 3.33%;
      padding-right: 0;

      label {
        width: 200px;
        text-align: right;
      }
    }

    .txtTransmitDateFrom {
      margin-left: 3.33%;
      padding-right: 0;

      // z-index: 1;
      label {
        width: 200px;
        text-align: right;
      }
    }

    .txtTransmitDateFrom {
      input:focus {
        z-index: 0;
      }
    }

    .txtFileNumber {
      max-width: 33.33%;
    }

    .txtTransmitDateTo,
    .txt1007SettleRangeTo {
      max-width: 20%;
      padding-left: 0;
    }

    .btnSearch {
      margin-left: 1.67%;
      max-width: 10%;
      top: 10px;

      button {
        width: 90%;
      }
    }


    .cmmndCntnrOpen {
      .btnOpen {
        margin-left: auto;
        margin-bottom: 0 !important;
        max-width: 7%;
        min-width: 7%;

        button {
          width: 100%;
        }
      }

    }
  }

  .gridKCMOdata {

    .rdt_TableCol:nth-child(1),
    .rdt_TableCell:nth-child(1) {
      min-width: 67px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(2),
    .rdt_TableCell:nth-child(2) {
      min-width: 152px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(3),
    .rdt_TableCell:nth-child(3) {
      min-width: 75px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(4),
    .rdt_TableCell:nth-child(4) {
      min-width: 80px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(5),
    .rdt_TableCell:nth-child(5) {
      min-width: 77px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(6),
    .rdt_TableCell:nth-child(6) {
      min-width: 87px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(7),
    .rdt_TableCell:nth-child(7) {
      min-width: 70px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(8),
    .rdt_TableCell:nth-child(8) {
      min-width: 80px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(9),
    .rdt_TableCell:nth-child(9) {
      max-width: 22px;
      min-width: 80px;
    }

    .rdt_TableCol:nth-child(10),
    .rdt_TableCell:nth-child(10) {
      min-width: 157px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(11),
    .rdt_TableCell:nth-child(11) {
      min-width: 87px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(12),
    .rdt_TableCell:nth-child(12) {
      min-width: 70px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(13),
    .rdt_TableCell:nth-child(13) {
      min-width: 80px;
      max-width: 50px;
    }

    .rdt_TableCol:nth-child(14),
    .rdt_TableCell:nth-child(14) {
      max-width: 22px;
      min-width: 80px;
    }
  }

  .cmmndCntnrActions {
    .row {
      border: none;
      background-color: #f3f0f0;
      padding-top: 0.3rem;
    }

    .buttonwidget-container {
      min-width: 10%;
      max-width: 10%;

      button {
        width: 82%;
        height: 42px;
      }
    }

    .btnRequestFileResend {
      margin-left: 1%;
    }

    .btnClose {
      margin-left: 34.5%;
    }

    .btnExportToExcel {
      margin-left: auto;
    }

  }

  /*END_USER_CODE_FOR_.SCSS*/
}