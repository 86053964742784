.WarehouseReceipts-DelvSettleSearch,.ContractManagement-DelvSettleSearch{
    /*START_USER_CODE_FOR_.SCSS*/
    h1{
      display: none;
    }
    // background-color: #333E48;
  background: transparent;
    .groupboxwidget-container{
      .card{  
        border: none;
        background-color:  #f3f0f0;
        .card-header{
          display: none;
        }
      }
    }
    .grpbxDelvSettleSearch {
      .grpbxDetails1 {
        min-width: 55%;
        max-width: 55%;
        label{
          width: 100px;
          text-align: right;
        }
        .ddLocation ,.ddStatus ,.ddVendorNum {
          min-width: 100%;
          .dropdown{
            width: 90%;
          }
        }
        .txtDelvInspectNum ,.txtDelvSettleNum ,.txtAgreementNum {
          min-width: 100%;
          input{
            width: 40%;
          }
        }
        .txtVendorNum {
          min-width: 45%;
          max-width: 45%;
          padding-right: 0;
        }
        .btnVendor{
          min-width: 6%;
          max-width: 6%;
          margin-bottom: 0.3rem !important;
        }
        .txtVendor{
          min-width: 49%;
          max-width: 49%;
          padding: 0;
        }
      }
      .grpbxDetails2{
        min-width: 45%;
        max-width: 45%;
        label{
          width: 120px;
          text-align: right;
        }
        .ddPeanutType ,.ddPeanutVariety ,.ddGeneration {
          min-width: 100%;
          .dropdown{
            width: 90%;
          }
        }
        .ddSegType,.ddOleic,.ddEdibleOil,.ddGradePricingMethod{
          min-width: 100%;
          .dropdown{
            width: 50%;
          }
        }
      }
      .grpbxDelvSettlementList {
        padding: 0;
        .btnSearch{
          min-width: 10%;
          max-width: 10%;
          margin-left: 45%;
          margin-bottom: 1.5rem !important;
          margin-top: 0.5rem !important;
          button{
            width: 100%;
          }
        }
        .gridDelvSettlementList{
          .card-header{
             display: block;
          }
          .rdt_TableCol:nth-child(8),
          .rdt_TableCell:nth-child(8) {
            min-width: 250px;
            max-width: 250px;
            justify-content: left !important;
          }
          .rdt_TableCell:nth-child(13),
        .rdt_TableCell:nth-child(15),
        .rdt_TableCell:nth-child(14) {
          justify-content: right !important;
        }
        .expandIconSpc{
          min-width: 35px;
          max-width: 35px;
        }
        .extraRowCell_1{
          min-width: 160px;
          max-width: 160px;
        }
        .extraRowCell_2{
          min-width: 100px;
          max-width: 100px;
        }
        .extraRowCell_3{
          min-width: 210px;
          max-width: 210px;
        }
        .extraRowCell_4{
          min-width: 100px;
          max-width: 100px;
        }
        .extraRowCell_5{
          min-width: 80px;
          max-width: 80px;
        }
        .extraRowCell_6{
          min-width: 180px;
          max-width: 180px;
        }
        .expandable-area {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          background-color: #e7f9ff;
          min-height: 35px !important;
          table-layout: auto;
          width: 100%;
          .rdt_ExpanderRow{
            position: relative;
            top: 8px;}
          }
        }
        .lblCropYear{
          min-width: 15%;
          max-width: 15%;
          margin-left: 5%;
          label{
            font-size: 15px;
            font-weight: 500;
            color: red;
          }
          .col{
            font-size: 15px;
            font-weight: 500;
            position: relative;
            color: red;
            top: 5px;
            right: 9px;
          }
        }
        .btnCreateDelvSettlement {
          min-width: 17.5%;
          max-width: 17.5%;
          button{
            width: 95%;
          }
        }
        .btnOpen{
          min-width: 7.5%;
          max-width: 7.5%;
          button{
            width: 90%;
          }
        }
      }
    }
    .grpbxActions {
      .textboxwidget-container{
        max-width: 13%;
        .d-flex{
          display: block !important;
          label{
            width: 100%;
            text-align: center;
          }
          .col{
            padding: 0;
            input{
              text-align: right;
            }
          }
        }
      }
      .btnExportToExcel {
        min-width: 10%;
        max-width: 10%;
        button{
          width: 90%;
        }
      }
      .btnPrint{
        min-width: 7%;
        max-width: 7%;
        button{
          width: 90%;
        }
      }
      .btnClose{
        min-width: 9%;
        max-width: 9%;
        margin-left: 20%;
        margin-right: 15%;
        button{
          width: 100%;
        }
      }
    }
    /*END_USER_CODE_FOR_.SCSS*/
  }
  