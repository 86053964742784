.StockTransfer-TransferSearch,.ContractManagement-TransferSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
  }
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxTransferSearch {
    .grpbxTransfer {
      padding: 0;
      min-width: 40%;
      max-width: 40%;
      margin-bottom: 0 !important;
      label{
        width: 140px;
        text-align: right;
      }
      .txtTrnsfr {
        padding: 0;
        min-width: 100%;
        input{
          width: 50%;
        }
      }
      .ddShippingLoc ,.ddRcvLoc {
        min-width: 100%;
        padding: 0;
      }
      .dtShippdDtFrm ,.dtRcvdFrm {
        max-width: 65%;
        min-width: 65%;
        padding: 0;
      }
      .dtRcvdTo ,.dtShippdTo {
        padding: 0;
        max-width: 35%;
        label{
          width: 15px;
        }
      }
      .ddSts ,.ddFrmWhse {
        min-width: 100%;
        padding: 0;
        .dropdown{
          width: 80%;
        }
      }
      .ddFrghtAccr{
        min-width: 100%;
        padding: 0;
        .dropdown{
          width: 60%;
        }
      }
    }
    .grpbxTrnsfr {
      padding: 0;
      min-width: 60%;
      max-width: 60%;
      margin-bottom: 0 !important;
      .dropdownfieldwidget-container{
        padding-right: 0;
        min-width: 50%;
        max-width: 50%;
        label{
          width: 106px;
          text-align: right;
        }
      }
      .txtVndr {
        padding-right: 0;
        min-width: 35%;
        max-width: 35%;
        label{
          width: 106px;
          text-align: right;
        }
      }
      .btnLookup{
        min-width: 15%;
        max-width: 15%;
        button{
          padding-top: 3%;
        }
      }
      .txtCarrAbbrv {
        min-width: 30%;
        max-width: 30%;
        padding: 0;
        label{
          width: 120px;
          text-align: right;
        }
        .col{
          padding-right: 0;
        }
      }
      .txtVndrName {
        max-width: 70%;
        min-width: 70%;
        padding: 0;
        .col{
          input{
            padding-left: 0;
            background: transparent;
            border: none;
            box-shadow: none;
            color: blue;
          }
        }
      }
      .btnSrch {
        min-width: 20%;
        max-width: 20%;
        margin-right: 5%;
        margin-bottom: 0.1% !important;
        button{
          width: 90%;
        }
      }
      .lblCropYr {
        min-width: 25%;
        max-width: 25%;
        margin-bottom: 0.1% !important;
        font-weight: 500;
        color: red;
        font-size: 16px;
        label{
          color: red;
        }
        .d-flex {
          background-color: lightgray;
        }
        .col{
          position: relative;
          top: 5px;
          right: 7px;
        }
      }
      .btnCrtNewTrnsfr {
        min-width: 25%;
        max-width: 25%;
        margin-left: 5%;
        margin-bottom: 0.1% !important;
        button{
          width: 90%;
        }
      }
      .btnOpen {
        min-width: 20%;
        max-width: 20%;
        margin-bottom: 0.1% !important;
        button{
          width: 90%;
        }
      }
    }
    .grpbxGrid {
      padding: 0;
      margin-top: 0.1rem;
      .lblTrnsfrList {
        font-weight: bold;
      }
      .gridTrnsfrSrch{
      .card-header{
        display: block;
      }
      .rdt_TableCol:nth-child(1),.rdt_TableCol:nth-child(6),
          .rdt_TableCol:nth-child(2),.rdt_TableCol:nth-child(7),
          .rdt_TableCol:nth-child(3),.rdt_TableCol:nth-child(8),
          .rdt_TableCol:nth-child(4),.rdt_TableCol:nth-child(9),
          .rdt_TableCol:nth-child(5),.rdt_TableCol:nth-child(10),
          .rdt_TableCol:nth-child(11),.rdt_TableCol:nth-child(12),
          .rdt_TableCol:nth-child(13),.rdt_TableCol:nth-child(14),
          .rdt_TableCol:nth-child(15),.rdt_TableCol:nth-child(16),
          .rdt_TableCol:nth-child(17),.rdt_TableCol:nth-child(18),
          .rdt_TableCol:nth-child(19),.rdt_TableCol:nth-child(20),
          .rdt_TableCol:nth-child(21),.rdt_TableCol:nth-child(22),
          .rdt_TableCol:nth-child(23),.rdt_TableCol:nth-child(24),
          .rdt_TableCol:nth-child(25),.rdt_TableCol:nth-child(26){
            position: relative;
            top: 15px;
          }
      .expandIconSpc{
        min-width: 35px;
        max-width: 35px;
      }
      .expandable-area {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        background-color: #e7f9ff;
        min-height: 35px !important;
        .rdt_ExpanderRow{
          position: relative;
          top: 8px;}
        }
      .extraRowCell_1,.extraRowCell_2,.extraRowCell_3,.extraRowCell_4,.extraRowCell_5,.extraRowCell_6,.extraRowCell_7,.extraRowCell_8,.extraRowCell_9,.extraRowCell_10,.extraRowCell_11,.extraRowCell_12,.extraRowCell_13,.extraRowCell_14,.extraRowCell_15,.extraRowCell_16,.extraRowCell_17,.extraRowCell_18,.extraRowCell_19,.extraRowCell_20,.extraRowCell_21,.extraRowCell_22,.extraRowCell_23,.extraRowCell_24,.extraRowCell_25,.extraRowCell_26{
        min-width: 100px;
        max-width: 100px;
        width: 100px;
        label{
          width: 100%;
          text-align: center;
        }
      }
    }
    }
  }
  .grpbxActions{
    .textboxwidget-container{
      min-width: 13%;
      max-width: 13%;
      .d-flex{
        display: block !important;
        label{
          width: 100%;
          text-align: right;
        }
        .col{
          padding: 0;
        }
      }
      input{
        text-align: right !important;
      }
    }
    .lblTotal{
      max-width: 18%;
      min-width: 18%;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .btnPrntTcktDlvry,.btnViewRelAccr,.btnFrtAccr{
      min-width:15%;
      max-width: 15%;
      button{
        width: 95%;
      }
    }
    .btnPrntTcktDlvry,.btnViewRelAccr{
      margin-left: 2%;
    }
    .btnViewRelAccr,.btnFrtAccr,.btnMltiTrnsfr,.btnExport,.btnPrint,.btnClose{
      padding-top: 1rem;
    }
    .btnMltiTrnsfr,.btnExport{
      min-width:10%;
      max-width: 10%;
      button{
        width: 95%;
      }
    }
    .btnPrint,.btnClose{
      min-width:10%;
      max-width: 10%;
      button{
        width: 95%;
      }
    }
    .btnClose{
      margin-left: 15%;
    }
    .btnExport{
      margin-left: 12%;
    }
  }
  .ddPayFrght .d-flex{
    padding-bottom: 1%;
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
