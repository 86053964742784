.StockTransfer-StockTransferOldNumber,.ContractManagement-StockTransferOldNumber {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxStockTransferOldNumber {
    .lblNote1 {
      min-width: 100%;
      font-weight: bold;
      label{
        width: 100%;
        text-align: left;
      }
    }
    .card{
      height: 300px;
    }
    .dropdown-menu.show{
      overflow-y:scroll!important;
      max-height:20vh!important;
      }
    .lblNote2 {
      min-width: 100%;
      label{
        width: 100%;
        text-align: left;
        color: red;
        font-weight: bold;
      }
    }
    .txtCurrentShippingLocation {
      min-width: 100%;
      input{
        background-color: #f3f0f0;
        border: none;
      }
      label{
        width: 160px;
        text-align: right;
      }
    }
    .ddNewShippingLocation{
      min-width: 100%;
      label{
        width: 160px;
        text-align: right;
      }
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      button{
      height:30px;
      }
    }
  }
  .cmmndcntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btnCancel,.btnOk{
      min-width: 20%;
      max-width: 20%;
      button{
        width: 90%;
      }
    }
    .btnOk{
      margin-left: 60%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
