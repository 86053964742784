.ContractManagement-ExceptPremCreate {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  .grpbx1007s{
    margin-bottom: 0 !important;
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .card {
    background-color: #f3f0f0;
    border: none;
  }
  h1,
  .card-header {
    display: none;
  }
  .txtLocation,
  .txtPremiumCode {
    max-width: 20%;
    input {
      max-width: 37%;
    }
  }
 
  .txtPeanuttype,
  .txtPeanutVariety,
  .txtSeed,
  .txtGeneration,
  .txtSegType,
  .txtOleic {
    max-width: 10%;
  }
  .ddPeanutVariety{
    // max-width: 60%;
    min-width: 31%;
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
  }

  }
  .ddGeneration,
  .ddSeed,
  // .ddPeanutVariety,
  .ddPeanutType,
  .ddOleic,
  .ddSegType
   {
    min-width: 31%;
  }
  .ddLocation,.ddPremiumCode{
    min-width: 51%;
    color: #ff0000;
    .dropdown{
      max-width: 64%;
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
  }
  }
  .txtFarm {
    max-width: 22%;
    padding-right: 0;
  }
  .txtFarmSuffix {
    min-width: 29%;
    padding-left: 0;
   
    input {
      max-width: 46%;
    }
    label{
      width: 75px !important;
    }
  }
  .txtVendor {
    color: #ff0000;
    max-width: 22%;
    padding-right: 0;
  }
  .btnVendor {
    // min-width: 30%;
    margin-bottom: 0 !important;
    bottom:5px;
    max-width: 3%;
  }
  .txtVendr{
    max-width: 31%;
    min-width: 26%;
    padding-left: 5px;
    label{
      display: none;
    }
    .col{
      padding-right: 0;
      padding-left: 0;
    }
    input{
      padding-right: 0;
      padding-left: 0;
      color: blue;
      border: none;
      background: transparent;
      font-weight: 600;
    }
  }
  .grpbxSearch {
    label {
      width: 110px;
      text-align: right;
    }
  }
  .txtContract {
    max-width: 50%;
    min-width: 51%;
    input {
      max-width: 23.8%;
    }
  }
  .btnSearch {
    min-width: 51%;
    margin-bottom: 4px !important;
    .row{
      justify-content: end;
    }
  }
  .chkboxAll1007s {
    // left: 14%;
    display: none !important;
    label.pt-1.form-label {
      display: none;
    }   
  }
  .lblAvailble {  
    min-width: 20%;  
    label{ 
    width: 100%;  
    } 
    }
  .lblBuyPtId{
    margin-left: 10%;
    max-width: 8%;
  }
  .lblPremiumCode,.lblVendrNm{
    max-width: 10%;
  }
  .gridAvail1007{
    // .card-header{
    //   display: block;
    // }
    // .rdt_TableCol {
    //   input {
    //     visibility: hidden;
    //   }
    // }
    .rdt_TableBody,.rdt_TableHeadRow{
      min-width: 102%;
    }
  }
  .txtarAvailable1007s {
    .d-flex {
      display: grid !important;
    }
    label {
      width: 116px;
      position: relative;
      top: 7px;
    }
    textarea {
      height: 320px;
      background-color: #c0bcbc;
    }
  }
  .txtLSKWT,
  .txtNetWT,
  .txtGrossWT,
  .txtPremiumAmount {
    .form-control:disabled, .form-control[readonly]{
      box-shadow: 2px 2px 0px grey inset !important;
    }
    .d-flex {
      display: grid !important;
    }
    label {
      width: 126px;
      text-align: right;
    }
    input {
      background-color: #c0bcbc;
      border: none;
      text-align:right;
    }
    background-color: #c0bcbc;
    margin-right: 0.5%;
  }
  .lblTotal {
    max-width: 13%;
    margin-left: 17%;
    background-color: #c0bcbc;
    margin-right: 0.5%;
  }
 
  .grpbxPayReq {
    min-width: 100%;
    label {
      width: 95px;
      text-align: right;
    }
    .txtReqBy{
      max-width: 25.5%;
    }
    .txtDateReq{
      max-width: 50%;
      div#txtDateReqParent {
        left: 40%;
        max-width: 65%;
      }
    }
    .txtarExplanation{
      textarea{max-width: 53.7%;}
    }
    .btnCreatePayReq {
      margin-left: 10.7%;
      /* min-width: 5%; */
      max-width: 13%;
    }
    .btnRejectPrem {
      max-width: 29.5%;
    }
    .btnExit{
      max-width: 5%;
    }
  }
  .grpbxRate{
    min-width: 100%;
    .txtIsRateBase{
      justify-content: end;
      display: flex;
      input{
        max-width: 21%;
      }
    }
    .txtRate{
      max-width: 20%;
    }
    .ddRateBase{
      min-width:40%;
      button{
             height: 30px;
            }
    }
    .btnCrtPrem{
      max-width: 18%;
    }
  }

  /*END_USER_CODE_FOR_.SCSS*/
}
