.WarehouseReceipts-EWRListRRI,.ContractManagement-EWRListRRI {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxDetails {
    padding: 0;
    .card{
      label{
        width: 120px;
        text-align: right;
      }
      .txtFileNumber,.txtWarehouseCode ,.txtReceiptNumber,.txtFarmNumber ,.txtFedBPNumber  {
        input{
          width: 70%;
        }
      }
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      .ddCCCLoanStatus,.ddEWRReceiptStatus,.ddEWRPeanutType{
        button{
          height: 30px;
        }
        #ddItem_{
          height: 25px;
        }
      }
      .txtLoanNumber{
        input{
          width: 90%;
        }
      }
      .cmmndCntnrActions {
        .btnSearch{
          min-width: 10%;
          max-width: 10%;
          margin-left: 45%;
          button{
            width: 100%;
          }
        }
        .btnView{
          min-width: 10%;
          max-width: 10%;
          margin-left: 35%;
          button{
            width: 90%;
          }
        }
      }
    }
  }
  .gridListRRI{
    .rdt_TableCell:nth-child(3),.rdt_TableCol:nth-child(3){
      min-width: 80px;
      max-width: 80px;
    }
    .rdt_TableCell:nth-child(10),.rdt_TableCol:nth-child(10){
      min-width: 80px;
      max-width: 80px;
    }
    .rdt_TableCell:nth-child(11),.rdt_TableCol:nth-child(11){
      min-width: 80px;
      max-width: 80px;
    }
    .rdt_TableCell:nth-child(4),.rdt_TableCol:nth-child(4){
      min-width: 80px;
      max-width: 80px;
    }
    .rdt_TableCell:nth-child(5),.rdt_TableCol:nth-child(5){
      min-width: 80px;
      max-width: 80px;
    }
    .rdt_TableCell:nth-child(6),.rdt_TableCol:nth-child(6){
      min-width: 80px;
      max-width: 80px;
    }
    .rdt_TableCell:nth-child(8),.rdt_TableCol:nth-child(8){
      min-width: 80px;
      max-width: 80px;
    }
    .rdt_TableCell:nth-child(9),.rdt_TableCol:nth-child(9){
      min-width: 80px;
      max-width: 80px;
    }
    .rdt_TableCell:nth-child(1),.rdt_TableCol:nth-child(1){
      min-width: 80px;
      max-width: 80px;
    }
  }
  .cmmndCntnrClose{
    .row{
      background-color: #f3f0f0;
      padding-top: 0.25rem;
      .btnClose{
        max-width: 10%;
        min-width: 10%;
        margin-left: 45%;
        button{
          width: 100%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}