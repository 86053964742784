.eamimgRemoveIcon {
  cursor: pointer;
  top: 12px;
  margin-left: -15px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  position: absolute;
}

.imgPreview {
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray;
  > img {
    width: 100%;
    height: 100%;
  }
}

.uploadPicturesWrapper {
  > div {
    justify-content: flex-start !important;
    > div {
      width: 100% !important;
      margin: unset !important;
      padding: unset !important;
    }
  }
}
