.SystemMaintenanceMasterManagement-FarmerStockPriceProfile,.ContractManagement-FarmerStockPriceProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
.grpFarmSrockPriceProfile {
  .card-header{
    display:none
  }
  .card{
    padding-top: 0.5rem;
    border: none;
    background-color: #c0bcbc;
  }
  label{
    width: 255px;
    text-align: right;
  }
  .textboxwidget-container,.txtEffectiveDate {
    min-width: 70%;
    max-width: 70%;
    margin-left: 15%;
  }
  .txtArea ,.txtPeanutType {
    input{
      padding-left: 0;
      color: blue;
      background:transparent;
      border: none;
      box-shadow: none;
      position: relative;
    bottom: 2px;
    }
  }
  .txtEffectiveDate {
    label{
      color: #c02020;
    }
    .col{
      max-width: 40%;
    }
    .dropdown-menu{
      top: -145px !important;
      left: 283px !important;
  }
  }
  .txtFarmerStockPrice {
    label{
      color: #c02020;
    }
    input{
      width: 40%;
    }
  }
  
}
.grpbxActions{
  .card-header{
    display: none;
  }
  .card{
    background-color: #f3f0f0;
  }
  .lblAddedBy,.lblChangedBy{
    max-width: 8%;
    padding: 0;
    label{
      width: 100%;
      text-align: right;
    }
  }
  .lblAddedByValue,.lblChangedByValue{
    min-width: 77%;
    label{
      display: none;
    }
    .col{
      padding: 0;
      bottom: 7px;
    }
  }
  .btnOk,.btnExit{
     max-width: 7.5%;
     min-width: 7.5%;
     top: 10px;
     button{
       width: 90%;
     }
  }
}
  /*END_USER_CODE_FOR_.SCSS*/
}
