.SystemMaintenanceMasterManagement-WeigherProfile,.ContractManagement-WeigherProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
.grpbxWeigherProfile{
  .card-header{
    display: none;
  }
  .card{
    background-color: #c0bcbc;
  }
  .txtWeigher{
    input{
      max-width: 62%;
    }
  }
  .lblWeigher,.chkboxDefaultWeigher{
    bottom: 18px;
  }
  .lblBuyingPointValue{
    color: blue;
    label{
    display: none;
    }
  }
}
.cmmndCntnrActions{
  .row{
    background-color: #f3f0f0;
    }
  .row{
    justify-content: end;
  }
  .btnCancel,.btnOk{
    max-width: 6%;
  }
}
  /*END_USER_CODE_FOR_.SCSS*/
}

