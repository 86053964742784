.SystemMaintenanceSpecialFunctions-PremiumDeducMasterProfile,.ContractManagement-PremiumDeducMasterProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .cmmndCntnrActions{
  .row{
    background-color: #f3f0f0;
    }
  }
  .grpbxPremiumDeductionMasterProfile{
    .card-header{
      display: none;
    }
    .card{
      background-color: #c0bcbc;
    }
    label{
      width: 115px;
    }
    .txtPDCode{
      input{
        max-width: 25%;
      }
      label{
        color: rgb(224, 15, 15);
        text-align: right;
      }
    }
    .txtDiscription{
      input{
        max-width: 70%;
      }
      label{
        color: rgb(224, 15, 15);
        text-align: right;
      }
    }.ddAccountingIndicator,.ddIndicator{
      .dropdown{
        max-width: 50%;
      }
      label{
        color: rgb(224, 15, 15);
        text-align: right;
      }
    }
    .txtPDSeedAccount,.txtAccount{
      input{
        max-width: 45%;
      }
      label{
        color: rgb(224, 15, 15);
        text-align: right;
      }
    }
    .chkboxIndicators1{
      label{
        width: 100%;
      }
    }
    .checkboxwidget-container{
      label{
        width: 75%;
        text-align: right;
        pointer-events: none;
      }
      .form-check{
        label{
          display: none;
        }
        
      }
    }
    .chkboxRepPremCntrctPstn,.chkboxExcPrmInd,.chkboxCMAInd,.chkboxFrtAccrlInd,.chkboxPrint1007Strg,.chkboxRcrdWtAcct{
      label{
        width: 38%;
      }
    }
  }
  .grpbxActions{
    .card{
      background-color: #f3f0f0;
    }
    .card-header{
      display: none;
    }
    .lblAddedBy{
      min-width: 85%;
    }
    .lblAddedByValue{
      display: none !important;
    }
    .btnCancel,.btnOk{
      max-width: 7%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
