.ContractManagement-TradeSettleScaleTicket,.Settlements-TradeSettleScaleTicket {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
  }
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
  .card {
    background-color: #f3f0f0;
  }
  .grpbxLoadInfo {
    label {
      width: 75px;
      text-align: right;
    }
    .lblLoadInfo {
      max-width: 30%;
      margin-left: 22%;
      font-weight: 600;
      font-size: 20px;
      label {
        width: 100%;
      }
    }
    .txtVehicle,
    .txtLoad,
    .txtDriver,
    .txtTruckLine {
      max-width: 65%;
      margin-left: 35%;
    }
    .txtLoad {
      input {
        max-width: 18%;
      }
    }
    .txtVehicle {
      input {
        max-width: 22%;
      }
    }
    .txtDriver,
    .txtTruckLine {
      input {
        max-width: 35%;
      }
    }
  }
  .grpbxShippedWeight {
    padding-right: 0;
    label {
      width: 155px;
      text-align: right;
    }
    .lblShippedWight {      
      min-width: 100%;
      label{
        width: 100%;
        text-align: center;
      }
      font-size: 18px;
      font-weight: 600;
    }
    .txtShippingDate {
      max-width: 60%;
      min-width: 60%;
    }
    .lblDateFormat {
      padding-left: 0;
      max-width: 30%;
      color: blue;
      label {
        text-align: left;
      }
    }
    .txtScaleTicket {
      input {
        max-width: 55%;
      }
    }
    .lblSpltWght {
      max-width: 30%;
      padding-right: 0;
    }
    .chkBoxSplitWeight {
      padding-left: 0;
    }
    .txtWtOfVehicle,
    .txtIncludingVehicle {
      input {
        max-width: 55%;
        text-align: right;
      }
    }
    .txtWeightPeanuts {
      max-width: 70%;
      border-top: 1px solid black;
    }
    .lblPnuts{
      max-width: 28%;
      padding-right: 0;
    }
    .lblPnutVal{
      label{
        width: 100%;
        text-align: left;
      }
    }
  }
  .grpbxRecdWeight {
    padding-left: 0;
    label {
      width: 155px;
      text-align: right;
    }
    .lblRecdWeight {      
      min-width: 100%;
      label{
        text-align: center;
        width: 100%;
      }
      font-size: 18px;
      font-weight: 600;
    }
    .lblRecdDateFormat {
      padding-left: 0;
      min-width: 30%;
      color: blue;
      label {
        text-align: left;
        width: 155px;
      }
    }
    .txtRecdDate{     
      min-width: 60%;
      max-width: 60%;
    }
    .txtShippingDate{
      label{
        display: none;
        padding-bottom: 10px;
      }
    }
    .txtRecdScaleTicket {
      input {
        max-width: 55%;
      }
    }
    .lblRecdSplitWeight {
      max-width: 30%;
      padding-right: 0;
    }
    .chkBoxRecdSplit {
      padding-left: 0;
    }
    .txtRecdWtVehicle,
    .txtRecdWtIncVehicle {
      input {
        max-width: 55%;
        text-align: right;
      }
    }
    .txtRecdWtPeanuts {
      max-width: 70%;
      border-top: 1px solid black;
    }
    .lblPnut{
      max-width: 28%;
      padding-right: 0;
    }
    .lblPeanutVal{
      label{
        width: 100%;
        text-align: left;
      }
      input{
        text-align: right;
      }
    }
  }
  .grpbxUpdateInventory {
    padding-right: 0;
    .lblUpdateInventory {
      font-size: 18px;
      font-weight: 600;
    }
    .radioWeight {
      max-height: 90px;
      margin-left: 25%;
      padding-right: 0;
    }
    .txtOtherWt {
      padding-left: 0;
    }
  }
  .grpbxWeightVariance {
    padding-left: 0;
    .card {
      height: 100%;
    }
    .lblLbs {
      padding-left: 0;
    }
    .txtWtVariance {
      padding-right: 0;
    }
  }
  .grpbxUpdate {
    .lblAddedByVal{
      min-width: 75%
    }
    .lblAddedBy,.lblChngdBy{
      max-width: 7%;
      padding-right: 0;
    }
    .btnCancel,
    .btnUpdate {
      max-width: 8%;
      margin-left: 5px;
      button {
        width: 100%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
