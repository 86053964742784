.WarehouseReceipts-ExternalReceiptForfeituresSearch,.ContractManagement-ExternalReceiptForfeituresSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxExternalReceiptForfeituresSearch {
    .grpbxDetails {
      padding: 0;
      label{
        width: 120px;
        text-align: right;
      }
      .txtFedBuyPtNum ,.txtWarehouseCode {
        input{
          width: 75%;
        }
      }
      .ddPeanutType{
        .dropdown{
          width: 75%;
        }
      }
      .txtReceiptNum {
        input{
          width: 25%;
        }
      }
      .txtDeliveryAgreementNum {
        input{
          width: 75%;
        }
      }
      .ddRecordStatus {
        max-width: 50%;
        min-width: 50%;
        margin-right: 50%;
        .dropdown{
          width: 75%;
        }
      }
    }
    .grpbxSearchList {
      padding: 0;
      .lblExternalReceiptForfeitureSearchList {
        min-width: 25%;
        max-width: 25%;
        label{
          font-weight: bold;
          margin-bottom: 0;
        }
      }
      .buttonwidget-container{
        min-width: 10%;
        max-width: 10%;
        button{
          width: 90%;
        }
      }
      .btnSearch{
        margin-left: 15%;
      }
      .lblCropYear{
        min-width: 15%;
        max-width: 15%;
        font-weight: 500;
        color: red;  
        background-color: lightgray;
        font-size: 16px;
        .col{
          padding-left: 0;
          div{
            top: 5px;
            position: relative;
          }
        }
      }
      .gridExternalReceiptForfeitureSearchList{
        .rdt_TableCell:nth-child(4),
        .rdt_TableCell:nth-child(14),
        .rdt_TableCell:nth-child(4) {
          justify-content: left !important;
        }
        .rdt_TableCell:nth-child(2),.rdt_TableCol:nth-child(2){
          max-width: 110px;
          min-width: 110px;
        }
        .rdt_TableCell:nth-child(5),.rdt_TableCol:nth-child(5){
          max-width: 110px;
          min-width: 110px;
        }
        .rdt_TableCell:nth-child(7),.rdt_TableCol:nth-child(7){
          max-width: 150px;
          min-width: 150px;
        }
        .rdt_TableCell:nth-child(8),.rdt_TableCol:nth-child(8){
          max-width: 120px;
          min-width: 120px;
        }
        .rdt_TableCell:nth-child(9),.rdt_TableCol:nth-child(9){
          max-width: 115px;
          min-width: 115px;
        }
        .rdt_TableCell:nth-child(10),.rdt_TableCol:nth-child(10){
          max-width: 170px;
          min-width: 170px;
        }
        .rdt_TableCell:nth-child(11),.rdt_TableCol:nth-child(11){
          max-width: 120px;
          min-width: 120px;
        }
        .rdt_TableCell:nth-child(12),.rdt_TableCol:nth-child(12){
          max-width: 135px;
          min-width: 135px;
        }
        .rdt_TableCell:nth-child(13),.rdt_TableCol:nth-child(13){
          max-width: 135px;
          min-width: 135px;
        }
        .rdt_TableCell:nth-child(14),.rdt_TableCol:nth-child(14){
          max-width: 140px;
          min-width: 140px;
        }
        .rdt_TableCell:nth-child(15),.rdt_TableCol:nth-child(15){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(79),.rdt_TableCol:nth-child(79){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(81),.rdt_TableCol:nth-child(81){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(82),.rdt_TableCol:nth-child(82){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(83),.rdt_TableCol:nth-child(83){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(90),.rdt_TableCol:nth-child(90){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(77),.rdt_TableCol:nth-child(77){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(73),.rdt_TableCol:nth-child(73){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(91),.rdt_TableCol:nth-child(91){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(92),.rdt_TableCol:nth-child(92){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(93),.rdt_TableCol:nth-child(93),
        .rdt_TableCell:nth-child(94),.rdt_TableCol:nth-child(94),
        .rdt_TableCell:nth-child(95),.rdt_TableCol:nth-child(95),
        .rdt_TableCell:nth-child(96),.rdt_TableCol:nth-child(96),
        .rdt_TableCell:nth-child(97),.rdt_TableCol:nth-child(97){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(108),.rdt_TableCol:nth-child(108){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(107),.rdt_TableCol:nth-child(107){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(106),.rdt_TableCol:nth-child(106){
          max-width: 155px;
          min-width: 155px;
        }
        .rdt_TableCell:nth-child(16),.rdt_TableCell:nth-child(35),
        .rdt_TableCell:nth-child(17),.rdt_TableCell:nth-child(36),
        .rdt_TableCell:nth-child(18),.rdt_TableCell:nth-child(37),
        .rdt_TableCell:nth-child(20),.rdt_TableCell:nth-child(38),
        .rdt_TableCell:nth-child(19),.rdt_TableCell:nth-child(21),
        .rdt_TableCell:nth-child(30),.rdt_TableCell:nth-child(39),
        .rdt_TableCell:nth-child(40),.rdt_TableCell:nth-child(41),
        .rdt_TableCell:nth-child(42),.rdt_TableCell:nth-child(46),
        .rdt_TableCell:nth-child(43),.rdt_TableCell:nth-child(47),
        .rdt_TableCell:nth-child(44),.rdt_TableCell:nth-child(48),
        .rdt_TableCell:nth-child(45),.rdt_TableCell:nth-child(49),
        .rdt_TableCell:nth-child(50),.rdt_TableCell:nth-child(52),
        .rdt_TableCell:nth-child(53),.rdt_TableCell:nth-child(54),
        .rdt_TableCell:nth-child(58),.rdt_TableCell:nth-child(64),
        .rdt_TableCell:nth-child(34),.rdt_TableCell:nth-child(108),
        .rdt_TableCell:nth-child(109),.rdt_TableCell:nth-child(114),
        .rdt_TableCell:nth-child(13),.rdt_TableCell:nth-child(59),
        .rdt_TableCell:nth-child(60),.rdt_TableCell:nth-child(61),
        .rdt_TableCell:nth-child(62),.rdt_TableCell:nth-child(63),
        .rdt_TableCell:nth-child(66),.rdt_TableCell:nth-child(65),
        .rdt_TableCell:nth-child(67),.rdt_TableCell:nth-child(68),
        .rdt_TableCell:nth-child(69),.rdt_TableCell:nth-child(70),
        .rdt_TableCell:nth-child(71),.rdt_TableCell:nth-child(65),
        .rdt_TableCell:nth-child(100),.rdt_TableCell:nth-child(101),
        .rdt_TableCell:nth-child(105),.rdt_TableCell:nth-child(107),
        .rdt_TableCell:nth-child(112),.rdt_TableCell:nth-child(114),
        .rdt_TableCell:nth-child(116)
         {
          justify-content: right !important;
        }
        .rdt_TableCell:nth-child(22),.rdt_TableCell:nth-child(31),
        .rdt_TableCell:nth-child(23),.rdt_TableCell:nth-child(32),
        .rdt_TableCell:nth-child(24),.rdt_TableCell:nth-child(33),
        .rdt_TableCell:nth-child(25),.rdt_TableCell:nth-child(27),
        .rdt_TableCell:nth-child(26),.rdt_TableCell:nth-child(27),
        .rdt_TableCell:nth-child(27),.rdt_TableCell:nth-child(27),
        .rdt_TableCell:nth-child(28),.rdt_TableCell:nth-child(29),
        .rdt_TableCell:nth-child(55),.rdt_TableCell:nth-child(56),
        .rdt_TableCell:nth-child(57),.rdt_TableCell:nth-child(29),
        .rdt_TableCell:nth-child(51),.rdt_TableCell:nth-child(76),
        .rdt_TableCell:nth-child(77),.rdt_TableCell:nth-child(78),
        .rdt_TableCell:nth-child(79),.rdt_TableCell:nth-child(106),
        .rdt_TableCell:nth-child(111),.rdt_TableCell:nth-child(110),
        .rdt_TableCell:nth-child(14),.rdt_TableCell:nth-child(72),
        .rdt_TableCell:nth-child(117),.rdt_TableCell:nth-child(118),.rdt_TableCell:nth-child(119), 
        .rdt_TableCell:nth-child(80),.rdt_TableCell:nth-child(81),
        .rdt_TableCell:nth-child(82),.rdt_TableCell:nth-child(83),
        .rdt_TableCell:nth-child(89),.rdt_TableCell:nth-child(90),
        .rdt_TableCell:nth-child(91),.rdt_TableCell:nth-child(92),.rdt_TableCell:nth-child(93),
        .rdt_TableCell:nth-child(94),.rdt_TableCell:nth-child(95),
        .rdt_TableCell:nth-child(96),.rdt_TableCell:nth-child(97),
        .rdt_TableCell:nth-child(98),.rdt_TableCell:nth-child(99),
        .rdt_TableCell:nth-child(102),.rdt_TableCell:nth-child(103),
        .rdt_TableCell:nth-child(113),.rdt_TableCell:nth-child(115),
        .rdt_TableCell:nth-child(75)
       {
          justify-content: left !important;
        }

      }
      .chkboxShowAllFields {
        min-width: 15%;
        max-width: 15%;
        .form-check{
          input{
            bottom: 2px;
          }
        }
      }
      .txtTotalPounds {
        min-width: 20%;
        max-width: 20%;
        input{
          border: none;
          background-color: #f3f0f0;
          text-align: right;
        }
        .col{
          position: relative;
          bottom: 2px;
          padding: 0;
        }
      }
      .txtTotalReceiptValue {
        min-width: 20%;
        max-width: 20%;
        margin-left: 50%;
        input{
          border: none;
          background-color: #f3f0f0;
          text-align: right;
        }
        .col{
          position: relative;
          bottom: 2px;
          padding: 0;
        }
      }
    }
  }
  .cmmndCntnrActions  {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btnClose{
      min-width: 10%;
      max-width: 10%;
      margin-left: 45%;
      button{
        width: 90%;
      }
    }
    .btnPrintSearch{
      min-width: 10%;
      max-width: 10%;
      margin-left: 35%;
      button{
        width: 90%;
      }
    }
    }
  
  /*END_USER_CODE_FOR_.SCSS*/
}
