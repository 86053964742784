.WarehouseReceipts-1007GroupingProfile,.ContractManagement-1007GroupingProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxDetails {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    padding-top: 0.25rem;
    .card{
      border: 1px solid #ccc;
      .txtGroupingNum,.txtDate  {
        max-width: 40%;
        min-width: 40%;
        label{
          width: 70px;
          text-align: right;
        }
        input{
          background-color: #f3f0f0;
          border: none;
        }
      }
     .ddHolder {
      min-width: 100%;
      label{
        width: 70px;
        text-align: right;
      }
     }
      .chkboxCMA,.chkboxDMA{
        max-width: 15%;
        min-width: 15%;
        .col{
          input{
            bottom: 2px;
          }
        }
      }
      .chkboxDefferedRedemption {
        max-width: 60%;
        min-width: 60%;
        .col{
          input{
            bottom: 2px;
          }
        }
      }
    }
  }
  .cmmndCntntrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.25rem;
      .btnSearchFor1007 {
        max-width: 20%;
        min-width: 20%;
        margin-left: 2%;
        button{
          width: 90%;
        }
      }
      .btnUpdate,.btnDelete,.btnExit{
        max-width: 10%;
        min-width: 10%;
        button{
          width: 90%;
        }
      }
      .btnUpdate{
        margin-left: auto;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
