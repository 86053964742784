.SystemMaintenanceMasterManagement-BuyingPointSetup,.ContractManagement-BuyingPointSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxBuyingPointDetails {
    .card-header {
      display: none;
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card {
      background-color: #c0bcbc;
      .card-body{
        width: 70%;
        margin-left: 15%;
      }
    }
    .row {
      justify-content: end;
    }
    .cmmndCntnrActions1 {
      max-width: 45%;
      top: 25px;
      .btnAddArea,
      .btnDeleteArea,
      .btnEditArea {
        max-width: 19%;
        button{
          width: 90%;
        }
      }
    }
    .cmmndCntnrActions2 {
      max-width: 45%;
      top: 25px;
      .btnDeleteCollectionPoint,
      .btnEditCollectionPoint,
      .btnAddCollectionPoint {
        max-width: 19%;
        button{
          width: 90%;
        }
      }
    }
    .cmmndCntnrActions3 {
      max-width: 45%;
      top: 25px;
      .btnDeleteBuyingPoint,
      .btnEditBuyingPoint,
      .btnAddBuyingPoint {
        max-width: 19%;
        button{
          width: 90%;
        }
      }
      .lstBuyingPoint {
        margin-bottom: 0.5rem !important;
      }
    }
    .grpbxTransfers {
      padding: 0;
      .row {
        justify-content: start;
      }
      .card {
        border: none;
        .card-body{
          padding-left: 15px;
          width: 100%;
          margin-left: 0;
        }
      }
      .lblTransfersOrObligationLocations {
        min-width: 64%;
      }
      .buttonwidget-container{
        max-width: 17%;
        min-width: 17%;
        margin-bottom: 0.3em !important;
        .row{
          button{
            width: 90%;
          }
        }
      }
      .lstTransferBuyingPoint{
        min-width: 100%;
        label{
          display: none;
        }
      }
    }
    .grpbxWeighers {
      padding: 0;
      .card-body{
        width: 100%;
        margin-left: 0;
      }
      .row {
        justify-content: start;
      }
      .card {
        border: none;
        .card-body{
          padding-right: 15px;
        }
      }
      .lblWeighers {
        min-width: 50%;
      }
      .buttonwidget-container{
        max-width: 16%;
        min-width: 16%;
        margin-bottom: 0.3em !important;
        .row{
          button{
            width: 90%;
          }
        }
      }
      .lstWeighers {
        min-width: 100%;
        label{
          display: none;
        }
      }
    }
  }
  .cmmndCntnrActions4 {
    .row{
      padding-top: 0.2rem;
    background-color: #f3f0f0;
  }
   .btnClose{
    margin-left: 48%;
   }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}