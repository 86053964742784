.dropdown {
  > button {
    width: 100%;
    display: flex;
    align-items: center;
  }
  > button:after {
    margin-left: auto;
  }
}

.dd-invalid {
  border: 1px solid #c71c22;
}
