.ContractManagement-ContractExceptionPremiumSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  h1 {
    display: none;
  }
  
  .grpbcContrctExcPremSet {
    .card {
      background-color: #c0bcbc;
    }
    .card-header {
      display: none;
    }
    .grpbxAddDel {
      .card {
        border: none !important;
      }
      max-width: 22%;
      left: 29%;
      top: 20px;
      position: relative;
    }
    .btnAdd,
    .btnEdit,
    .btnDel {
      max-width: 38%;
      button {
        width: 90%;
      }
    }
    .btnClose {
      margin-left: 40%;
      max-width: 8%;
    }
   
    .lstExceptions {
      .d-flex {
        display: grid !important;
      }
      .col {
        right: 2%;
      }
    }
    .txtContrctNm {
      min-width: 46%;
      label {
        min-width: 20%;
      }
      .col{
        padding-left: 0;
      }
      input{
        color: #0000FF;
        background: transparent;
        border: none;
        padding-bottom: 13px;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
