.SystemMaintenanceMasterManagement-StateProfile,.ContractManagement-StateProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxStateProfile{
    .card-header{
      display: none;
    }
    .card{
      background-color: #c0bcbc;
    }
    label{
      width: 250px;
      text-align: right;
    }
    .txtAbbreviation,.txtStateID,.txtInCharges,.txtInspectionFees,.txtFreightLoadLimit{
      input{
        max-width: 10%;
      }
      label{
        color: rgb(224, 15, 15);
      }
    }
    .txtName{
      input{
        max-width: 20%;
      }
      label{
        color: rgb(224, 15, 15);
      }
    }
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 9%;
    }
    .lblAddedByValue,.lblChangedByValue{
      label{
        display: none;
      }
      min-width: 80%
      }
    .lblAddedByValue,.lblChangedByValue{
      .col{
        position: relative;
        bottom: 7px;
        right: 46px;
        }
    }
    .btnOk{
      max-width: 5%;
      position: relative;
      right: 40px;
    }
    .btnCancel{
      max-width: 6%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
