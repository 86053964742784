@import "~bootswatch/dist/cerulean/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/cerulean/bootswatch";
@import "~font-awesome/css/font-awesome.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css";

.button-anchor {
  background-color: transparent;
  border: none;
  color: #ddd;
  text-decoration: none;
  padding: 0px;
}

.button-anchor:hover,
.button-anchor:focus,
.button-anchor:active {
  color: #fff;
}

.button-anchor:focus,
.button-anchor:active {
  outline: 0;
}

html {
  position: absolute;
  min-height: 100%;
  padding-bottom: 60px;
  width: 100%;
}

.main {
  margin: 1rem;
  margin-top: 4.5rem;
}

.form-group.required .form-label:after {
  content: " *";
  color: red;
}

.fileLinkClass:focus, .remove-file:focus{
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    border: 1px solid rgba(81, 203, 238, 1);
}

@media (max-width: 767px) {
  .rowspanResponsive-xs-1 {
    height: auto !important;
  }
  .rowspanResponsive-xs-2 {
    height: 156px !important;
  }
  .rowspanResponsive-xs-3 {
    height: 234px !important;
  }
  .rowspanResponsive-xs-4 {
    height: 312px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .rowspanResponsive-sm-1 {
    height: auto !important;
  }
  .rowspanResponsive-sm-2 {
    height: 156px !important;
  }
  .rowspanResponsive-sm-3 {
    height: 234px !important;
  }
  .rowspanResponsive-sm-4 {
    height: 312px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .rowspanResponsive-md-1 {
    height: auto !important;
  }
  .rowspanResponsive-md-2 {
    height: 156px !important;
  }
  .rowspanResponsive-md-3 {
    height: 234px !important;
  }
  .rowspanResponsive-md-4 {
    height: 312px !important;
  }
}
@media (min-width: 1200px) {
  .rowspanResponsive-lg-1 {
    height: auto !important;
  }
  .rowspanResponsive-lg-2 {
    height: 156px !important;
  }
  .rowspanResponsive-lg-3 {
    height: 234px !important;
  }
  .rowspanResponsive-lg-4 {
    height: 312px !important;
  }
}
.hideControl{ 
  visibility: hidden;
}