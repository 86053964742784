.SystemMaintenanceSpecialFunctions-TradeCategoryProfile,.ContractManagement-TradeCategoryProfile {
 /*START_USER_CODE_FOR_.SCSS*/
 h1{
  display:none
}
// background-color: #333E48;
background: transparent;
.grpbxTradeCategoryProfile{
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
    }
  .card{
    padding-top: 0.2rem !important;
    border: none;
    background-color: #c0bcbc;
    .card-header{
      display:none
    }
    .textboxwidget-container,.dropdownfieldwidget-container{
      padding-right: 0;
      min-width: 50%;
      max-width: 50%;
      margin-left: 25%;
      label{
        width: 155px;
        text-align: right;
      }
    }
    .txtTradeCategory {
      input{
        width: 90%;
      }
    }
    .ddPurchaseSale,.ddBalanceSheet{
      .dropdown{
        width: 90%;
      }
    }
    .txtAccount{
      input{
        width: 45%;
      }
    }
    .checkboxwidget-container{
      max-width: 50%;
      min-width: 50%;
      margin-left: 25%;
      .form-label{
        width: 155px;
      }
      .col{
        .form-check-label{
          display: none;
        }
        input{
          top: 2px;
        }
      }
    }
  }
}



.grpbxActions {
  .card{
    background-color: #f3f0f0;
    .card-header{
      display: none;
    }
    .card-body{
      .lblAddedBy,.lblChangedBy{
        min-width: 10%;
        max-width: 10%;
        label{
          text-align: right;
          width: 100%;
        }
      }
      .lblAddedByValue,.lblChangedByValue{
        min-width: 30%;
        max-width: 30%;
        padding-left: 0;
        .form-label{
          display: none;
        }
        .col{
          position: relative;
          bottom: 6px;
          right: 26px;
        }
      }
      .btnUpdate,.btnCancel{
        top: 15px;
        max-width: 10%;
        min-width: 10%;
        button{
          width: 90%;
        }
      }
      .btnUpdate{
        margin-left: 40%;
      }
    }
  }
}
/*END_USER_CODE_FOR_.SCSS*/
}
