.AccountPayment-CorrectCheck,.ContractManagement-CorrectCheck {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
  }
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .card-header,
  h1 {
    display: none;
  }
  .grpbxCheckNumber {
    .card {
      background-color: #c0bcbc;
    }
    label {
      width: 145px;
    }
    .txtNewCheckNumber{
      max-width: 65%;
     label{ width: 53%;
    text-align: right;}
      // max-width: 50%;
      // margin-left: 25%;
    }
    .lblOldCheckNumber{
      max-width: 36%;
      label{
        width: 100%;
        text-align: right;
      }
    }
  }
  .lblOldCheckNumValue {
    padding: 0%;
    .form-label{
      max-width: 0.0%;
    padding: 0% !important;
    }
    .col{
      padding:0%;
    }
  }
  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
  }
    .btnOk {
      max-width: 5%;
      margin-left: 85%;
    }
    .btnCancel {
      max-width: 8%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
