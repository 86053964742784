.AccountPayment-CheckInquiry,
.ContractManagement-CheckInquiry {
  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }
  .grpbxCheckDetails1 {
    .card-header {
      display: none;
    }
    .card {
      background-color: #f3f0f0;
    }
    label {
      width: 122px;
      text-align: right;
    }
    .ddBuyingPoint {
      min-width: 45%;
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }
    .txtMemo {
      label {
        width: 56px;
      }
      max-width: 33%;
      min-width: 20%;
    }
    .ddChargeLocation {
      max-width: 30%;
      .dropdown-menu.show{
        max-width: 140%;
        min-width: 140%;
      }
    }
    .txtInvoice {
      min-width: 20% !important;
      label {
        width: 57px !important;
      }
    }
    .ddOleicInd{
      label{
        min-width: 124px;
      }
    }
    .txtInvoice,
    .ddItemCode,
    .txtPayAmount,
    .ddOleicInd {
      min-width: 25%;
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }
    .ddChargeLocation,
    .ddPeanutType,
    .ddSeedGeneration,
    .ddCheckStatus {
      min-width: 20%;
      max-width: 30%;
      padding-right: 0;
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }
    .ddItemCode{
      button {
        height: 30px;
      }
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
    }
    .txtVendor {
      padding-right: 0;
      min-width: 18.1%;
      .col {
        padding-right: 0.5rem;
      }
    }
    .btn2 {
      max-width: 3.2%;
    }
    .txtVendor, .txtCarrierAbbr, .txtVendorName, .txtInvoice, .txtStartDate, .txtEndDate{
      POSITION: RELATIVE;
      bottom: 2px;
    }
    .txtCarrierAbbr {
      // min-width: 20.6%;
      max-width: 12%;
      padding: 0;
      label {
        width: 75px;
      }
      .col {
        padding-right: 0.5rem;
      }
      input {
        max-width: 130%;
        min-width: 130%;
      }
    }
    .txtVendorName {
      max-width: 18%;
    }
    .txtStartDate {
      min-width: 20%;
      padding-right: 0;
      .col{
        padding-right: 4px;
      }
      .input-group-text{
        padding: 3%;
      }
      #txtStartDateParent{
        width: 97%;
      }
    }
    .txtEndDate {
      label {
        width: 2px;
      }
      .input-group-text{
        padding: 0;
    }
      max-width: 10%;
      min-width: 10%;
      padding-left: 0;
      padding-right: 0%;
    }
    .ddRemitTo,
    .txtCheck,
    .txt1007 {
      min-width: 40%;
      max-width: 45%;
    }
    .chkboxWireTransferData {
      .form-label {
        display: none;
      }
      label {
        text-align: left;
      }
      max-width: 9%;
      // right: 18px;
    }
    .txtWireXfer{
      max-width: 23%;
      padding-right: 0;
      z-index: 2;
      border-right: none;
      input{
        padding-right: 0;
        padding-left: 10px;
        width: 107%;
        border-right: none;
      }
    }
      .ddWireTransferData {
        max-width: 26%;
        right: 22.9%;
        z-index: 1;
       padding-right: 0;
       
        .dropdown {
          background-color: #fff;
        }
  
        button {
          right: 7%;
          background: transparent;
          background-image: none;
          background-color: #fff;
          border: none;
        }
      }
    .txtCheck,
    .txt1007 {
      input {
        max-width: 27%;
      }
    }
  }
//   .grpbxSearchResults{
//   .card-header {
//     display: block;
//   }
// }
.gridSearchResults {

  .rdt_TableCell:nth-child(5),.rdt_TableCell:nth-child(5){
    justify-content: center;
  }
  .rdt_TableCol:nth-child(1),.rdt_TableCell:nth-child(1){
    max-width: 100px;
    min-width: 100px;
  }
  .rdt_TableCol:nth-child(2),.rdt_TableCell:nth-child(2){
    max-width: 250px;
    min-width: 250px;
    justify-content: left !important;
  }
  .rdt_TableCol:nth-child(3),.rdt_TableCell:nth-child(3){
    max-width: 200px;
    min-width: 200px;
    justify-content: left !important;
  }
  .rdt_TableCol:nth-child(4),.rdt_TableCell:nth-child(4){
    max-width: 200px;
    min-width: 200px;
  }
}
  .grpbxActions {
    .card-header {
      display: none;
    }
    .card {
      height: 100%;
      bottom: 8px;
      border: none;
      background-color: #f3f0f0;
    }
  }
  .grpbxSearchResults {
    .lblSearchResults{
      font-size: 17px;
     font-weight: 500;
    }
    .card-header {
      display: block
    }
    .card {
      background-color: #f3f0f0;
    }
    .btnSearch {
      min-width: 35%
    }
    .btnCorrect {
      max-width: 6%;
    }
    .btnVoid {
      min-width: 30%;
    }
    .btnReview {
      max-width: 6%;
    }
    .grpbxbtn1 {
      display: none;
    }
    .grpbxCheckDetails2 {
      left: 42%;
      .card {
        border: none;
      }
      .card-header{
        display: none;
      }
      .lblGrandTotal,
      .lblWireTransfers,
      .lblSystemVoids,
      .lblCheckProceeds {
        max-width: 17%;
      }
      .lblGrandTotalValue,
      .lblWireTransfersValue,
      .lblSystemVoidsValue,
      .lblCheckProceedsValue {
        min-width: 83%;
        position: relative;
        bottom: 7px;
        label{
          display: none;
        }
        .col{
          max-width: 23%;
          text-align: right;
        }
      }
    }
  }
  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
    }
    .btnClose {
      margin-left: 45%;
    }
    .btnExportToExcel {
      max-width: 10%;
    }
    .btnPrint {
      max-width: 6%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}