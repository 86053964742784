.SystemMaintenanceMasterManagement-VoidReasonSetup,.ContractManagement-VoidReasonSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxVoidReasonSetup{
    .card-header{
      display:none
    }
    .card{
      background-color: #c0bcbc;
    }
    .lblVoidReason{
      min-width: 81.5%
    }
    .btnDelete,.btnEdit,.btnAdd{
      max-width: 6%;
    }
    .gridVoidReasonData{
      .card-header{
        display: block;
      }
    }
  }
  .cmmndCntnrActions{
    .row{
    background-color: #f3f0f0;
    }
  }
  .btnClose{
    margin-left: 48%;
  }
  /*END_USER_CODE_FOR_.SCSS*/
}

