.WarehouseReceipts-WarehouseReceipt,
.ContractManagement-WarehouseReceipt {
  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }
  // background-color: #333e48;
  background: transparent;
  .groupboxwidget-container {
    .card {
      border: none;
      background-color: #f3f0f0;
      .card-header {
        display: none;
      }
    }
  }
  .grpbxWarehouseReceipt {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
    .card {
      padding-top: 0.2rem;
    }
    .grpbxDetails1 {
      min-width: 30%;
      max-width: 30%;
      padding-top: 0.2rem;
      padding: 0;
      .dropdownfieldwidget-container {
        min-width: 100%;
        padding: 0;
        label {
          width: 65px;
          text-align: right;
        }
      }
      .txtContractNum {
        min-width: 100%;
        padding: 0;
        label {
          width: 65px;
          text-align: right;
        }
        input {
          width: 42%;
        }
      }
      .txt1007GrpNum {
        min-width: 55%;
        max-width: 55%;
        padding: 0;
        label {
          width: 65px;
          text-align: right;
        }
        .col {
          input {
            width: 100%;
          }
        }
      }
      .txt1007GrpName {
        min-width: 45%;
        max-width: 45%;
        padding: 0;
        label {
          display: none;
        }
        .col {
          padding: 0;
          input {
            padding: 0;
            color: red;
            border: none;
            box-shadow: none;
            background: transparent;
            font-weight: bold;
          }
        }
      }
      .txtVendorNum {
        min-width: 55%;
        max-width: 55%;
        padding: 0;
        label {
          width: 65px;
          text-align: right;
        }
      }
      .btnVendor {
        max-width: 5%;
        min-width: 5%;
        margin-bottom: 0.3rem !important;
      }
      .txtVendor {
        max-width: 80%;
        min-width: 80%;
        margin-left: 65px;
        .col {
          padding: 0;
          input {
            padding-left: 0;
            border: none;
            background: transparent;
            box-shadow: none;
            color: blue;
            padding: 0;
          }
        }
      }
    }
    .grpbxDetails2 {
      min-width: 45%;
      max-width: 45%;
      padding: 0;
      padding-top: 2.5rem;
      .dropdownfieldwidget-container {
        min-width: 50%;
        padding-right: 0;
        label {
          width: 67px;
          text-align: right;
        }
      }
      .ddOleic,
      .ddSeed,
      .ddSegType {
        .dropdown {
          width: 80%;
        }
      }
      .ddPeanutType {
        .dropdown {
          width: 80%;
        }
      }
      .radioElectronicPaper {
        max-width: 30%;
        min-width: 30%;
        align-items: normal !important;
      }
      .radioAppliedTo {
        min-width: 70%;
        max-width: 70%;
      }
    }
    .grpbxDetails3 {
      min-width: 25%;
      max-width: 25%;

      .card-body {
        .grpbxMarketingAssociation {
          .card-body {
            border: 1px solid #ccc;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
          .lblMarketAssociation {
            min-width: 100%;
            label {
              font-weight: bold;
            }
          }
          .radioCMADMA {
            padding: 0;
            .col {
              padding-right: 0;
              .form-check {
                padding-right: 1rem;
                display: inline;
                input {
                  bottom: 1px;
                }
              }
            }
          }
          .txtVendorNumMA {
            min-width: 80%;
            .col {
              padding-right: 0;
            }
          }
          .btnVendorMA {
            max-width: 20%;
            margin-bottom: 0.3rem !important;
          }
          .txtVendorName {
            min-width: 100%;
            label {
              display: none;
            }
            .col {
              padding: 0;
              input {
                padding-left: 0;
                color: blue;
                border: none;
                box-shadow: none;
                background: transparent;
                padding: 0;
              }
            }
          }
          .ddRemitTo {
            min-width: 100%;
            #ddItem_{
              height: 25px;
            }	  
    .dropdown-toggle:empty::after {
      margin-left: auto;
    }
    button{
    height:30px;
    }
            .d-flex {
              display: block !important;
              .col {
                padding: 0;
              }
            }
          }
        }
        .chkboxDiferredRedemption {
          padding-top: 0.5rem;
          padding-left: 0;
          .form-check {
            input {
              bottom: 2px;
            }
          }
        }
      }
    }
    .grpbxAvailable1007s {
      padding: 0;
      min-width: 100%;
      .lblAvailable1007s {
        max-width: 12%;
        label {
          margin-bottom: 0;
          font-weight: bold;
        }
      }
      .chkboxAll1007s {
        max-width: 18%;
      }
      .gridAvailable1007s {
        .rdt_TableHeadRow {
          .rdt_TableCol:nth-child(1) {
            div {
              display: none;
            }
          }
        }
        .rdt_TableCell:nth-child(9),
        .rdt_TableCell:nth-child(10),
        .rdt_TableCell:nth-child(11),
        .rdt_TableCell:nth-child(12) {
          justify-content: right !important;
        }
        .rdt_TableCell:nth-child(4),
        .rdt_TableCell:nth-child(5) {
          justify-content: left !important;
        }
        .rdt_TableCol:nth-child(1),
        .rdt_TableCell:nth-child(1) {
          min-width: 80px;
          max-width: 80px;
        }
        .rdt_TableCol:nth-child(2),
        .rdt_TableCell:nth-child(2) {
          min-width: 50px;
          max-width: 50px;
        }
        .rdt_TableCol:nth-child(3),
        .rdt_TableCell:nth-child(3) {
          min-width: 90px;
          max-width: 90px;
        }
        .rdt_TableCol:nth-child(4),
        .rdt_TableCell:nth-child(4) {
          min-width: 250px;
          max-width: 250px;
        }
        .rdt_TableCol:nth-child(5),
        .rdt_TableCell:nth-child(5) {
          min-width: 200px;
          max-width: 200px;
        }
        .rdt_TableCol:nth-child(8) {
          justify-content: center !important;
          text-align: center;
        }
        .rdt_TableCol:nth-child(11) {
          justify-content: center !important;
          text-align: center;
        }
      }
      .btnSearch {
        max-width: 10%;
        min-width: 10%;
        button {
          width: 100%;
        }
      }
    }
    .grpbxInventoriesAvailable {
      padding: 0;
      min-width: 100%;
      .lblInventoriesAvailable {
        max-width: 40%;
        min-width: 40%;
        label {
          font-weight: bold;
          margin-bottom: 0;
        }
      }
      .gridInventoriesAvailable {
        .rdt_TableCol:nth-child(1),
        .rdt_TableCell:nth-child(1) {
          min-width: 50px;
          max-width: 50px;
        }
        .rdt_TableCol:nth-child(2),
        .rdt_TableCell:nth-child(2) {
          min-width: 250px;
          max-width: 250px;
        }
        .rdt_TableCol:nth-child(4),
        .rdt_TableCell:nth-child(4) {
          min-width: 250px;
          max-width: 250px;
        }
        .rdt_TableCol:nth-child(6),
        .rdt_TableCell:nth-child(6) {
          min-width: 100px;
          max-width: 100px;
        }
        .rdt_TableCol:nth-child(7),
        .rdt_TableCell:nth-child(7) {
          min-width: 120px;
          max-width: 120px;
        }
        .rdt_TableCell:nth-child(9),
        .rdt_TableCell:nth-child(10),
        .rdt_TableCell:nth-child(11),
        .rdt_TableCell:nth-child(12) {
          justify-content: right !important;
        }
        .rdt_TableCell:nth-child(7),
        .rdt_TableCell:nth-child(8),
        .rdt_TableCell:nth-child(13) {
          justify-content: right !important;
        }
        .rdt_TableCell:nth-child(4),
        .rdt_TableCell:nth-child(2) {
          justify-content: left !important;
        }
        .rdt_TableCol:nth-child(7),
        .rdt_TableCol:nth-child(9),
        .rdt_TableCol:nth-child(10),
        .rdt_TableCol:nth-child(11),
        .rdt_TableCol:nth-child(12),
        .rdt_TableCol:nth-child(13) {
          text-align: center;
        }
      }
      .btnMore {
        max-width: 10%;
        min-width: 10%;
        margin-left: 48%;
        button {
          width: 100%;
        }
      }
    }
    .grpbxStorageInformation {
      padding-right: 0;
      min-width: 45%;
      max-width: 45%;
      .card-body {
        border: 1px solid #ccc;
        height: 206px;
        .textboxwidget-container {
          padding-right: 0;
          padding-left: 0.25rem;
          // max-width: 9%;
          .d-flex {
            display: block !important;
            label {
              width: 100%;
              text-align: center;
              margin-bottom: 0;
            }
            .col {
              padding: 0;
            }
          }
        }
        .lblStorageInformation {
          padding: 0;
          label {
            font-weight: bold;
          }
        }
        .txtIssueDate,
        .txtStorageDate {
          max-width: 20%;
          min-height: 16%;
          min-width: 20%;
          input {
            color: blue;
          }
        }
        .txtStorageDate {
          input {
            padding: 0;
            text-align: center;
          }
        }
        .txtTotalLbsShrunk,
        .txtTotalLbs,
        .txtLSKLbs,
        .txtLSKLbsShrunk {
          max-width: 15%;
          min-width: 15%;
          input {
            color: blue;
            text-align: center;
            padding: 0;
          }
        }
        .txtPeanutType {
          min-width: 22%;
          max-width: 22%;
          input {
            color: blue;
            text-align: center;
            padding: 0;
          }
        }
        .txtSeg {
          max-width: 10%;
          min-height: 10%;
          input {
            color: blue;
            text-align: center;
            padding: 0;
          }
        }
        .txtNetLbs,
        .txtNetLbsShrunk {
          min-width: 18%;
          max-width: 18%;
          input {
            color: blue;
            text-align: center;
            padding: 0;
          }
        }
        .txtValShrinkPct {
          min-width: 25%;
          max-width: 25%;
          .d-flex {
            display: flex !important;
            label {
              width: 74px;
            }
            input {
              padding: 0;
              text-align: center;
              color: blue;
            }
          }
        }
        .txtWtsShrinkPct {
          min-width: 27%;
          max-width: 27%;
          .d-flex {
            display: flex !important;
            label {
              width: 79px;
            }
          }
          input {
            text-align: center;
            padding: 0;
          }
        }
        .txtHoeStored,
        .txtStatus {
          min-width: 18%;
          max-width: 18%;
          input {
            color: blue;
            text-align: center;
            padding: 0;
          }
        }
        .txtControlNumber {
          min-width: 44%;
          .d-flex {
            display: flex !important;
            label {
              width: 100px;
            }
            input {
              text-align: center;
            }
          }
        }
      }
    }
    .grpbxFactorsForAllPeanuts {
      // padding: 0;
      min-width: 55%;
      max-width: 55%;
      padding-left: 0.2rem;
      .card-body {
        border: 1px solid #ccc;

        .textboxwidget-container {
          padding-right: 0;
          padding-left: 0.25rem;
          max-width: 9.09%;
          min-width: 9.09%;
          .d-flex {
            display: block !important;
            label {
              max-width: 100%;
              min-width: 100%;
              text-align: center;
              margin-bottom: 0;
            }
            .col {
              padding: 0;
            }
            input {
              color: blue;
              text-align: center;
              padding: 0;
            }
          }
        }
        .lblFactorsForAllPeanuts {
          min-width: 100%;
          padding: 0;
          label {
            font-weight: bold;
          }
        }
      }
    }
  }
  .grpbxActions {
    min-width: 100%;
    .row {
      padding-top: 0.25rem;
      background-color: #f3f0f0 !important;
      // background-color:  #f3f0f0;
      .lblCropYear {
        max-width: 23%;
        min-width: 23%;
        margin-left: 50%;
        font-weight: 500;
        color: red;
        font-size: 16px;
        .col {
          padding-left: 0;
          div {
            top: 5px;
            position: relative;
          }
        }
        .d-flex {
          background-color: lightgray;
          width: 60%;
        }
      }
      .btnCreateWR {
        max-width: 17%;
        min-width: 17%;
        button {
          width: 90%;
        }
      }
      .btnCancel {
        max-width: 10%;
        min-width: 10%;
        button {
          width: 90%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
