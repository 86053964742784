.SystemMaintenanceMasterManagement-CureCleanScheduleProfile,.ContractManagement-CureCleanScheduleProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxCureCleanScheduleProfile {
    .card-header {
      display: none;
    }
    .card {
      background-color: #c0bcbc;
    }
    label {
      width: 100px;
      text-align: right;
    }
    .lblBuyingPoint {
      max-width: 10%;
      .d-flex {
        width: 100px !important;
      }
    }
    .lblBuyingPointValue {
      margin-left: 10px;
      label {
        width: 100%;
        text-align: left;
        color: blue;
      }
    }
    .txtCode {
      input {
        max-width: 12%;
      }
      label{
        color: red;
      }
    }
    .txtDiscription,.txtDeduction{
      label{
        color: red;
      }
    }
    .ddType,
    .ddDryGreenInd,
    .ddRateType {
      .dropdown {
        max-width: 25%;
      }
      label{
        color:red
      }
    }
    .ddPDCode {
      max-width: 92%;
      left: 8%;
      button {
        display: none;
      }
      label{
        color:red
      }
    }
  }
  .gridPrint {
    display: none;
  }
  .gridRates {
    .card-header {
      display: block;
    }
  }
  .grpbxActions {
    .card {
      background-color: #f3f0f0;
    }
    .card-header {
      display: none;
    }
    .lblAddedBy,
    .lblChangedBy {
      max-width: 8%;
      padding-right: 0;
    }
    .lblAddedByValue,
    .lblChangedByValue {
      min-width: 80%;
    }
    .btnOk {
      max-width: 5%;
      .row {
        position: relative;
        right: 20px;
      }
    }
    .btnCancel {
      max-width: 7%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}

