.SystemMaintenanceSpecialFunctions-SecurityUser,.ContractManagement-SecurityUser {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSecurityUser  {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #c0bcbc; 
      padding-top: 0.2rem;

      .grpbxDetails {
        padding: 0;
        min-width: 60%;
        max-width: 40%;
        .card{
          .txtUserID ,.txtIBMUserID {
            max-width: 50%;
            min-width: 50%;
            padding-right: 0;
            padding-top: 0.5rem;
            label{
              width: 100px;
              text-align: right;
            }
          }
          .txtUserName,.txtUserPhone ,.txtUserEmail {
            max-width: 100%;
            min-width: 100%;
            padding-right: 0;
            label{
              width: 100px;
              text-align: right;
            }
          }
          .txtUserPhone{
            input{
              width: 70%;
            }
          }
          .dropdownfieldwidget-container {
            max-width: 100%;
            min-width: 100%;
            padding-right: 0;
            label{
              width: 100px;
              text-align: right;
            }
          }
          .ddAuthorityLevel {
            .dropdown{
              width: 40%;
            }
          }
        }
      }
      .grpbxImportFunctionsLocations {
        min-width: 40%;
        max-width: 40%;
        margin-top: 0.75rem;
        .card{
          background-color: #f3f0f0;
          label{
            padding-left: 14px;
          }
          .lblFromUserID {
            label{
              margin-bottom: 0;
            }
          }
          .lblNote {
            label{
              color: blue;
            }
          }
          .ddFromUserID{
            .dropdown-toggle:empty::after {
              margin-left: auto;
            }
            button{
            height:30px;
            }
            .dropdown-menu.show{
              max-width: 100%;
              min-width: 100%;
            }
          }
          .btnImportSecurity {
            max-width: 40%;
            min-width: 40%;
            margin-left: 10%;
            .row{
              padding-right: 30px;
              justify-content: end;
              button{
                width: auto;
              }
            }
          }
        }
      }
      .grpbxFunctions {
        min-width: 55%;
        max-width: 55%;
        padding: 0;
        .card{
          .card-body{
            // padding-left: 0.5rem;
          }
          .lblFunctions {
            min-width: 30%;
            label{
              margin-bottom: 0;
            }
          }
          .btnAddFunction{
            margin-left: 35%;
          }
          .btnAddFunction ,.btnEditFunction,.btnDeleteFunction{
            min-width: 11.63%;
            max-width: 11.63%;
            margin-bottom: 0.2rem !important;
            .row{
              justify-content: end;
              button{
                width: 95%;
              }
            }
          }
          .lstFunctions {
            padding: 0;
            min-width: 100%;
            .col{
              padding-right: 0;
            }
            select{
              height: 198px;
            }
          }
        }
      }
      .grpbxLocations {
        min-width: 45%;
        max-width: 45%;
        padding-left: 0;
        padding-right: 0.5rem;
        .card{
          .card-body{
            // padding-left: 0.5rem;
          }
          .lblLocations {
            min-width: 20%;
            label{
              margin-bottom: 0;
            }
          }
          .btnAddLocation{
            margin-left: 32.3%;
          }
          .btnAddLocation,.btnEditLocation,.btnDeleteLocation{
            min-width: 14.22%;
            max-width: 14.22%;
            margin-bottom: 0.2rem !important;
            .row{
              justify-content: end;
              button{
                width: 95%;
              }
            }
          }
          .lstLocations {
            padding: 0;
            min-width: 100%;
            .col{
              padding-right: 0;
            }
            select{
              height: 198px;
            }
          }
        }
      }
      .grpbxActions {
        min-width: 100%;
        .card{
          background-color: #f3f0f0;
          .card-header{
            display: none;
          }
          .card-body{
            .lblAddedBy,.lblChangedBy{
              min-width: 10%;
              max-width: 10%;
              label{
                text-align: right;
                width: 100%;
              }
            }
            .lblAddedByValue,.lblChangedByValue{
              min-width: 30%;
              max-width: 30%;
              padding-left: 0;
              .form-label{
                display: none;
              }
              padding-bottom: 8px;
            }
            .btnCancel,.btnAdd{
              top: 15px;
              max-width: 10%;
              min-width: 10%;
              button{
                width: 90%;
              }
            }
            .btnAdd{
              margin-left: 40%;
            }
          }
        }
      }
    }
  }
  
  /*END_USER_CODE_FOR_.SCSS*/
}
