.ReportTool-FormulaEditor,.ContractManagement-FormulaEditor {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
background: transparent;
  .modal-body form.row {
    position: relative;
    width: 68.5rem;
    background-color: #333E48;
  }
  .grpbxHeader{
    padding-left: 0;
  }
  .grpbx1,.grpbx2,.grpbx3{  
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #f3f0f0;
    }
  }
  .grpbx1{
    .txtUserID{
      min-width: 93%;
      padding-left: 46px;
    }
    .btnClose{
      max-width: 7%;
    }
  }
  .grpbx21{
    min-width: 30%;
    .lstFormulas{
      padding-left: 0;
      .d-flex{
        display: grid !important;
      }
      .col{
        padding-left: 0;
      }
    }
  }
  .grpbx22{
    
    .lstFormulaDefination, .lstFormulaColumn{
      .d-flex{
        display: grid !important;
      }
      .col{
        padding-left: 0;
      }    
    }
    .txtFormulaName, .txtFormulaDescription{
    input{
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }
  .lblFormulaDetail{
    font-weight: 800;
    text-decoration: underline;
  }
  }
  .grpbx23{
    max-width: 10%;
    button{
      width: 90%;
    }
    .card-body{
      padding-top: 160px ;
    }
  }
  .grpbx31{
    min-width: 33%;
    .card-body{
      padding-left: 15px;
    }
    .lstNumber, .lstDate{
      padding-left: 0;
      .d-flex{
        display: grid !important;
      }
      .col{
        padding-left: 0;
      }  
    }
  }
  .grpbx32{
    max-width: 8%;
    padding-right: 0;
    padding-left: 0;
    .card-body{
      padding-top: 100px;
    }
  }
  .grpbx33{
    min-width: 47%;
    .lblFormulaDefination1{
      max-width: 29%;
    label{
      font-weight: 800;
      text-decoration: underline;
    }
  }
  .lstDirections{
    padding-left: 0;
  }
  .lstFormulaStatus{
    //padding-left: 0;
    .d-flex{
      display: grid !important;
    }
    .col{
      padding-left: 0;
    }
  }
  }
  .grpbx34{
    max-width: 9%;
    button{
      width: 150%;
    }
    .card-body{
      padding-top: 145px;
    }
  }
//   .row{
//     left: 30px;
//   }
//   .ContractManagement-header {
//     left: -40px;
// }
//   .grpbx1,.grpbx2,.grpbx3{  
//       .card-header{
//         display:none
//       }
//       .card{
//         border: none;
//         background-color: #f3f0f0;
//         left: -25px;
//       }
//     }
//     .grpbx2 .card{
//       height: 350px;
//     }
//   //   .labelwidget1 label{
//   //     text-align: center;
//   //     font-weight: 1000;
    
//   // }
//     .txtReportName input{
//         width: 50%;
//         // margin-left: 120px;
//         // margin-top: -30px;
//     }
//     .txtUserID{
//       padding-bottom: 10px;
//       label{
//         margin-left: 32px;
//       }
//       input{
//         width: 70%;
//       }
//     }
//     .btnClose .row{
//       justify-content: end; 
//     }
//   .grpbx21{
//    min-width: 28%; 
//    .card{
//     // background-color: red;
//     width: 420px; 
//     padding-top: 10px;
//     left: -20px;
//   } 
// }
//   .lstFormulas select{
//     height: 300px;
//     margin-top: 30px;
//     margin-left: -70px;
//     width: 300px;

//   }
//   .grpbx22{
//     min-width: 62%;
//     padding-left: 5%;
//   .card{
//     // background-color: orange;
//     // left: 25%;
//     width: 700px;
//     padding-top: 10px;
//     // height: 600px;
//   }
// }
//   .lstFormulaDefination select{
//     // height: 140px;
//     margin-top: 30px;
//     margin-left: -120px;
//     width: 645px;
//   }
//   .lstFormulaColumn select{
//     margin-top: 40px;
//     margin-left: -290px;
//     width: 640px;
//   }
//   .grpbx23{
//     max-width: 10%;
//     .card{
//     // background-color: olivedrab;
//     left: 15%;
//     width: 80px;
//     top: 150px;
//     height: 100px;
//   }
// }
//   #btnEdit,#btnDelete,#btnCreate,#btnClear,#btnUpdate{
//     width: 300px;
//   }
//   .lblFormulaDetail{
//     font-weight: bold;
//     label{
//     text-decoration: underline;
//     }
//   }
// // //   .txtFormulaName input{
// // //     width: 80%;
// // //     margin-left: 120px;
// // //     margin-top: -30px;
// // // }
// // .txtFormulaDescription input{
// //   width: 80%;
// //   margin-left: 150px;
// //   margin-top: -30px;
// // }
// // .txtFormulaName1 input{
// //   width: 80%;
// //   margin-left: 120px;
// //   margin-top: -30px;
// // }
// // .txtFormulaDescription1 input {
// //   width: 80%;
// //   margin-left: 150px;
// //   margin-top: -30px;
// // }
// .grpbx3 .card{
//   height: 510px;
// }
// .grpbx31 .card{
//   // background-color: red;
//   width: 470px; 
//   height: 420px;
//   left: -20px;
// }
// .txtFormulaName1{
//   padding-top: 10px;
// }
// .lstNumber select{
//   height: 170px;
//   margin-top: 30px;
//   margin-left: -105px;
//   width: 350px;
// }
// .lstDate select{
//   height: 170px;
//   margin-top: 30px;
//   margin-left: -90px;
//   width: 350px;
// }

// .grpbx32 .card{
//   left: 100%;
//   width: 100px;
//   top: 200px;
//   height: 100px;
// }
// #btnAdd{
//   margin-left: 10%;
// }

// .grpbx33 .card{
//   left: 12%;
//   width: 650px;
//   height: 420px;
// }
// // #btnInsert{
// //   // padding-top: 10px;
// //   // padding-top: 30px;
// // }
// .lblFormulaDefination1{
//   font-weight: bold;
//   label{
//   text-decoration: underline;
//   }
// }
// .btnInsert{
//   right:23%;
//   top: 2px;
// }
// .lstDirections select{
//   height: 150px;
//   margin-left: -15px;
//   width: 540px;
// }
// .lstFormulaStatus select{
//   height: 75px;
//   margin-top: 30px;
//   margin-left: -95px;
//   width: 540px;
// }
// .grpbx34 .card{
//   left: 90%;
//   width: 100px;
//   top: 150px;
//   height: 200px;
// }
// .btnUpdate{
//   margin-bottom: 25px;
//   margin-top: 12px;
// }
// #btnEdit{
//   margin-bottom: 10px;
// }
// .btnClear{
//   margin-top: 12px;
// }
  /*END_USER_CODE_FOR_.SCSS*/
}
