.ContractManagement-VendorLookup {
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .ContractManagement-header{
   .LogoImage{
    //  display: none;
   }
 }
 h1 {
   display: none !important;
 }
 .grpbxVendorLookup {
   // max-width: 65%;
   .card {
     background-color: #c0bcbc;
   }
   .card-header {
     display: none;
   }
   .lstBoxVndrLst {
     min-width: 80%;
     select{
       min-height: 200px;
     }
   }
   .txtSrch {
     min-width: 72.7%;
   }
   .btnSrch {
     max-width: 20%;
   }
   
  
 }
 .btnCntnr {
  
  .mx-0{
    padding-right: 21%;
    justify-content: end;
    background-color: white;
    padding-top: 5px;
    .btnCncl,.btnSelect{
      max-width: 8%;
    }
  }

 }
 /*END_USER_CODE_FOR_.SCSS*/
}
