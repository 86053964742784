.WarehouseReceipts-ExtReceiptForfeituresCropYear,.ContractManagement-ExtReceiptForfeituresCropYear {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxExtReceiptForfeituresCropYear  {
    .lblCropYear{
      max-width: 20%;
      margin-left: 2%;
      color: red;
    }
    .txtYear{
     input{
      max-width: 15%;
    position: relative;
    right: 26%;
     }
    }
    .ddSelectNewCropYear  {
      min-width: 100%;
      label{
        width: 120px;
        text-align: right;
      }
    }
   
  }
  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.25rem;
      .buttonwidget-container{
        max-width: 15%;
        min-width: 15%;
        button{
          width: 90%;
        }
      }
      .btnCopy{
        margin-left: 35%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
