.SystemMaintenanceApplicationSupport-ManualDPRInvMaint,.ContractManagement-ManualDPRInvMaint {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxManualDPRInvMaint {
    margin-bottom: 0.2rem !important;
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      button{
      height:30px;
      }
    .card-header{
      display:none
    }
    .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #f3f0f0;

      label{
        width: 120px;
        text-align: right;
      }

      .dropdownfieldwidget-container{
        min-width: 100%;
        max-width: 100%;
      }
      .ddBuyingPoint,.ddAuditBPLoc{
        .dropdown{
          width: 45%;
        }
      }
      .ddWarehouseBin {
        .dropdown{
          width: 40%;
        }
      }
      .ddPeanutType,.ddPeanutVariety ,.ddSeedGeneration ,.ddSegType ,.ddOleicInd ,.ddOrganicInd ,.ddAuditDate ,.ddSource  {
        .dropdown{
          width: 25%;
        }
      }
      .ddAuditDate{
        input{
          max-width: 21%;
        }
      }
      .txt1007,.txtSC95{
        min-width: 100%;
        margin-top: 0.25rem;
        .d-flex{
          label{
            width: 120px;
            text-align: right;
          }
          input{
            width: 25%;
            position: relative;
            left: 12px;
          }
        }
      }
      .grpbxAdjustments{
        margin-top: 1rem;
        .card{
          .card-body{
            padding-right: 0;
          }
          .textboxwidget-container{
            min-width: 14.28%;
            max-width: 14.28%;
            padding-left: 0;
            .d-flex{
              display: block !important;
              label{
                width: 100%;
                text-align: center;
              }
              .col{
                padding: 0;
              }
            }
          }
          .lblNote{
            min-width: 100%;
            padding-left: 0;
            label{
              width: 100%;
              text-align: left;
              color: blue;
            }
          }
          .txtChangeRemarks{
            min-width: 100%;
            max-width: 100%;
            padding-left: 0;
            .d-flex{
              display: flex !important;
              label{
                width: auto;
              }
              .col{
                padding-left: 1rem;
              }
            }
          }
          
        }
      }
    }
  }
  .grpbxActions {
    .card{
      background-color: #f3f0f0;
      .card-header{
        display: none;
      }
      .card-body{
        .lblAddedBy,.lblChangedBy{
          min-width: 10%;
          max-width: 10%;
          label{
            text-align: right;
            width: 100%;
          }
        }
        .lblAddedByValue,.lblChangedByValue{
          min-width: 30%;
          max-width: 30%;
          padding-left: 0;
          padding-bottom: 0.75%;
          .form-label{
            display: none;
          }
        }
        .btnOK,.btnExit{
          top: 15px;
          max-width: 10%;
          min-width: 10%;
          button{
            width: 90%;
          }
        }
        .btnOK{
          margin-left: 40%;

        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
