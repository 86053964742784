.Settlements-CleaningCuringCosts,.ContractManagement-CleaningCuringCosts {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .dropdown-toggle:empty::after {
    margin-left: auto;
  }
  .dropdown {
     button{
    height: 30px;
   }
  }
  .groupboxwidget-container        {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #c0bcbc;
    }
  }


  .grpbxCleaningCuringCosts {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card{
      padding-top: 0.5rem;
    }
    .grpbxCureClean {
      padding-right: 0;
      padding-right: 0;
      .radiobuttongroupfieldwidget-container{
        top: 2px;
        .form-check{
          input{
            bottom: 2px;
          }
        }
      }
      .grpbxRadioCureClean {
        .card{
          background-color:  #f3f0f0;
          padding-top: 0.5rem;
          min-height: 40px;
        }
        .radioCureClean{
          min-width: 100%;
          .form-check{
            display: inline;
            margin-right: 30%;
            label{
              font-weight: bold;
            }
            input{
              bottom: 1px;
            }
          }
        }
      }
      .grpbxCureCleanGrossWt {
        .card{
          background-color:  #f3f0f0;
          padding-top: 0rem;
        }
        .labelwidget-container{
          min-width: 15%;
          max-width: 15%;
          padding: 0;
          label{
            width: 100%;
            text-align: center;
            padding: 0;
          }
        }
        .lblCCSchedule {
          min-width: 25% !important;
          max-width: 25%;
        }
        
        .groupboxwidget-container {
          padding: 0;
          .textboxwidget-container{
            min-width: 15%;
            max-width: 15%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
          .dropdownfieldwidget-container{
            min-width: 25%;
            max-width: 25%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
        }
      }
      .grpbxCureCleanNetWt {
        .card{
          background-color:  #f3f0f0;
          padding-top: 0rem;
        }
        .labelwidget-container{
          min-width:15%;
          max-width: 15%;
          padding: 0;
          label{
            width: 100%;
            text-align: center;
            padding: 0;
          }
        }
        .lblCCNWSchedule {
          min-width:25% !important;
          max-width: 25% !important;
        }
        .lblCCNetWeight {
          margin-left: 30%;
        }
        .grpbxCureCleanNetWtRow  {
          padding: 0;
          .textboxwidget-container{
            min-width: 15%;
            max-width: 15%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
          .dropdownfieldwidget-container{
            min-width: 25%;
            max-width: 25%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
          .txtCCNetWeight  {
            margin-left: 30%;
          }
        }
      }
      .grpbxCureCleanOther {
        .card{
          background-color:  #f3f0f0;
          padding-top: 0rem;
        }
        .labelwidget-container{
          min-width: 15%;
          max-width: 15%;
          padding: 0;
          label{
            width: 100%;
            text-align: center;
            padding: 0;
          }
        }
        .lblCCOSchedule {
          min-width:25% !important;
          max-width: 25% !important;
        }
        .lblCCOWeight {
          margin-left: 30%;
        }
        .grpbxCureCleanOtherRow {
          padding: 0;
          .textboxwidget-container{
            min-width: 15%;
            max-width: 15%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
          .dropdownfieldwidget-container{
            min-width: 25%;
            max-width: 25%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
          .txtCCOWeight {
            margin-left: 30%;
          }
        }
      }
    }

    .grpbxClean  {
      padding-left: 0;
      padding-right: 0;
      .radiobuttongroupfieldwidget-container{
        top: 2px;
        .form-check{
          input{
            bottom: 2px;
          }
        }
      }
      .grpbxRadioClean {
        .card{
          background-color:  #f3f0f0;
          max-height: 40px;
        }
        .lblCleaning{
          margin-left: 2.5%;
          bottom: 2px;
          label{
            font-weight: bold;
          }
        }
      }
      .grpbxCleanGrossWt  {
        .card{
          background-color:  #f3f0f0;
          padding-top: 0rem;
        }
       
        .labelwidget-container{
          min-width: 17%;
          max-width: 17%;
          label{
            width: 100%;
            text-align: center;
          }
        }
        .lblCSchedule {
          min-width: 30% !important;
          max-width: 30%;
        }
        // .lblCTicket,.txtCTicket0 ,.txtCTicket0uic16 ,.txtCTicket0uic22 {
        //   // margin-left: 2%;
        // }
        
        .groupboxwidget-container {
          padding: 0;
          .textboxwidget-container{
            min-width: 17%;
            max-width: 17%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
          .dropdownfieldwidget-container{
            min-width:30%;
            max-width: 30%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
        }
        .ddCSchedule0,.ddCSchedule1,.ddCSchedule2{
          button{
            height: 30px;
           }
           .dropdown-toggle:empty::after {
            margin-left: auto;
          }
        }
      }
      .grpbxCleanNetWt {
        .card{
          background-color:  #f3f0f0;
          padding-top: 0rem;
        }
        .labelwidget-container{
          min-width:17%;
          max-width: 17%;
          label{
            width: 100%;
            text-align: center;
          }
        }
        .lblCNWSchedule {
          min-width:30% !important;
          max-width: 30% !important;
        }
        .lblCNetWeight {
          margin-left: 36%;
        }
        .grpbxCleanNetWtRow  {
          padding: 0;
          .textboxwidget-container{
            min-width: 17%;
            max-width: 17%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
        
          
          .dropdownfieldwidget-container{
            min-width: 30%;
            max-width: 30%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
          .txtCNetWeight  {
            margin-left: 36%;
          }
        }
        .ddCNWSchedule{
          button{
            height: 30px;
           }
           .dropdown-toggle:empty::after {
            margin-left: auto;
          }
        }
      }
      .grpbxCleanOther {
        .card{
          background-color:  #f3f0f0;
          padding-top: 0rem;
        }
        .labelwidget-container{
          min-width: 17%;
          max-width: 17%;
          label{
            width: 100%;
            text-align: center;
          }
        }
        
        .lblCOSchedule {
          min-width:30% !important;
          max-width:30% !important;
        }
        .lblCOWeight {
          margin-left: 36%;
        }
        .grpbxCleanOtherRow {
          padding: 0;
          .textboxwidget-container{
            min-width: 17%;
            max-width: 17%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
          .dropdownfieldwidget-container{
            min-width: 30%;
            max-width: 30%;
            padding: 0;
            .col{
              padding-left: 5%;
              padding-right: 5%;
            }
          }
          .txtCOWeight {
            margin-left: 36%;
          }
        }
        .ddCOSchedule{
          button{
            height: 30px;
           }
           .dropdown-toggle:empty::after {
            margin-left: auto;
          }
        }
      }
    }

    .txtCostToCure ,.txtCostToClean {
      min-width: 20%;
      max-width: 20%;
      margin-left: 30%;
      label{
        width:75px;
      }
    }
    .txtCleanCureTicketNum {
      min-width: 100%;
      label{
        width: 150px;
        text-align: right;
      }
    }
  }

  .grpbxActions {
    .card-header{
      display:none
    }
    // margin-top: 1rem;
    // bottom: 15px;
    .card{
      border: none;
      background-color: #f3f0f0;
      padding-top: 0.5rem;
    }
    .lblChangedBy,.lblAddedBy {
      max-width: 10%;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblChangedByValue,.lblAddedByValue{
      min-width: 40%;
      margin-right: 30%;
    }
    .lblChangedBy,.lblChangedByValue{
      bottom: 10px;
    } 
    .lblAddedByValue,.lblAddedBy{
      bottom: 10px;
    }
    .btnOkuic0,.btnCanceluic0{
      max-width: 10%;
      top: 15px;
      button{
        width: 85%;
        height: 40px;
      }
    }
    
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
