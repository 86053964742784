.StockTransfer-TransferErrorList,.ContractManagement-TransferErrorList {
  /*START_USER_CODE_FOR_.SCSS*/
  .pageHeader {
    display: none;
  }
  .grpbxError{
    .card-header{
      display: none;
    }
    .lblHeading{
      min-width: 100%;
      label{
        font-weight: bold;
        font-size: 16px;
      }
    }
    .txtarError{
      min-width: 100%;
    }
    #txtarError{
      color: red;
      height: 250px;
    }
  }
  .cmndCntnrActions{
    .row{
      background-color: #f3f0f0;
      min-width: 100%;
    width: 100%;
    justify-content: end;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
