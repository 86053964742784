.Settlements-ViewSettlement,
.ContractManagement-ViewSettlement {

  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none
  }

  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  // form{
  //   height: 1000px;
  // }
  .groupboxwidget-container        {
    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #c0bcbc;
    }
  }

  .grpbxFarmInformation {
    // margin-top: 1rem;
    padding-right: 0.2%;
    margin-bottom: 0.2rem !important;

    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #c0bcbc;
      min-height: 353px;
    }

    label {
      width: 160px;
      text-align: right;
    }

    .lblSC95ES {
      width: 110%;
    }

    .lblFarmInformation label {
      width: 100% !important;
      text-align: center !important;
      font-weight: bold;
    }
    .txtRevision {
      max-width: 30%;

      label {
        width: 90px;
      }

    }

    .txtInspectDateTime {
      min-width: 60%;
      max-width: 60%;
    }

    // .lblDateTimeFormat {
    //   label{
    //     width: 100%;
    //     text-align: left;
    //     color: blue;
    //   }
    // }
    .txtFarm,
    .txtLocation {
      max-width: 75%;
    }

    .txtState,
    .txtCounty {
      max-width: 50%;
      min-width: 50%;
    }

    .txtSC95 {
      min-width: 45%;
      max-width: 45%;
    }

    .buttonwidget-container {
      max-width: 10%;

      buttom {
        width: 95%;
      }
    }

  }

  .grpbx1007Controluic0 {
    // max-height: 350px;
    padding-left: 0.4%;

    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #c0bcbc;
      min-height: 354px;
    }

    // padding-left: 0;

    label {
      width: 125px;
      text-align: right;
    }

    .lbl1007Control {
      min-width: 100%;

      label {
        width: 100%;
        text-align: center;
      }

      font-weight: bold;
    }

    .lblAdd1007Apply {
      min-width: 100%;

      label {
        width: 100%;
        text-align: left;
      }
    }

  }

  .grpbxWeightTickets {
    min-width: 100%;
    padding-right: 0.2%;

    .card {
      min-height: 121px;
      border: 1px solid rgba(0, 0, 0, 0.125);
    }

    label {
      width: 100px;
      text-align: right;
    }

    .gridWeightTickets {
      .rdt_TableCol {
        background: #dad6d6;
        border: 0.5px solid rgb(104, 97, 97);

      }
      .rdt_TableCell {
        border-right: 0.5px solid rgb(104, 97, 97);
        border-left: 0.5px solid rgb(104, 97, 97);
        border-bottom: 0.5px solid rgb(104, 97, 97);
      }
    }

    .lblEWeightTktValue,
    .lblEVehicleNumValue,
    .lblEWtInclVehValue,
    .lblEWtOfVehValue,
    .lblEWtOfPnutValue,
    .lblTicketsValue,
    .lblTotalLBsValue,
    .lblTotalDollarValue {
      padding-left: 0;

      label {
        text-align: left !important;
      }
    }

    .lblWeightTickets {
      label {
        text-align: left;
        font-weight: bold;
      }
    }

    .btnDelete {
      margin-left: 33%;

      button {
        width: 80%;
      }
    }
  }

  .grpbxPeanutInfouic0 {
    max-width: 33%;
    padding-left: 0;
    padding-top: 0.1%;

    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #c0bcbc;
      height: 100%;
    }

    label {
      width: 150px;
      text-align: right;
    }

    .lblPeanutInformation {
      min-width: 100%;

      label {
        width: 100%;
        font-weight: bold;
        text-align: center;
      }
    }

    .lblUnloadedInfo,
    .lblDelPtObligation {

      // margin-left: 20%;
      label {
        width: 100%;
        font-weight: bold;
        text-align: left !important;
      }
    }

    .txtSeedMeetingSpec {
      margin-left: 25%;
      max-width: 75%;

      label {
        width: 150px;
      }

    }

    .lblOrganic {
      max-width: 42%;
      padding-right: 0 !important;
    }

    .chkboxOrganic {
      margin-bottom: 0.5%;
      padding-left: 0;

      .mb-3 {
        margin-bottom: 0.6rem !important;
      }

      .col {
        padding-left: 0;
      }

      label {
        padding-right: 5px;
      }

      input {
        margin-left: 102%;
      }
    }

    .chkboxVicam {
      max-width: 23.75%;
      margin-left: 26%;
      padding-right: 0;
      padding-left: 0%;
      bottom: 7px;

      .col {
        label {
          width: 100% !important;
          margin-left: 10%;
          text-align: left;
        }
      }
    }

    .txtReading {
      label {
        width: 57px;
      }

    }

    .txtBuyingPt,
    .txtObligationPt {
      min-width: 100%;

      label {
        text-align: left;
      }

      .d-flex {
        display: block !important;
      }
    }
  }

  .grpbxVendorSplits {
    min-width: 100%;
    padding-right: 0.2%;
    .card-header {
      display: none
    }
    .card {
      border: none;
      background-color: #c0bcbc;
      max-height: 371px;
      overflow-y: overlay;
    }
    .lblVendorSplits {
      label {
        text-align: left;
        font-weight: bold;
      }
    }

    .lblVendor {
      min-width: 37%;
      max-width: 37%;

      label {
        width: 100%;
        text-align: center !important;
      }
    }

    .lblCMA {
      max-width: 5%;
      min-width: 5%;
      padding-left: 0;

      label {
        text-align: center;
      }
    }

    .lblWhseRcpt {
      max-width: 17%;
      min-width: 17%;
      padding-right: 0;

      label {
        width: 100%;
        text-align: center !important;
      }
    }

    .lblSharePct {
      max-width: 10%;
      padding: 0;

      label {
        width: 100%;
        text-align: center !important;
      }
    }

    .lblPOrS {
      max-width: 10%;
      padding-left: 0;

      label {
        width: 100%;
        text-align: center !important;
      }
    }

    .lblPounds {
      max-width: 15%;

      label {
        width: 100%;
        text-align: center !important;
      }

      padding-left: 0;
    }

    [class^="txtVendor"],
    [class^="txtRemitTo"] {
      max-width: 40%;
      min-width: 40%;
      padding: 0;
    }

    [class^="txtPorS"] {
      max-width: 10%;
      padding: 0;

      .col {
        padding-left: 0;
      }
    }

    [class^="ddWhseRcpt"],
    [class^="lblRemitTo"] {
      padding: 0;
      min-width: 15%;
      max-width: 15%;

      .col {
        padding: 0;
      }

      label {
        text-align: center;
        width: 100%;
      }

      button {
        height: 31px;
      }

      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
    }

    [class^="lblValue"] {
      padding: 0;
      min-width: 20%;
      max-width: 20%;

      label {
        text-align: right;
        width: 95%;
      }
    }

    [class^="txtSharePct"] {
      padding: 0;
      max-width: 10%;

      .col {
        padding: 0;
      }
    }

    [class^="txtPounds"],
    [class^="txtValue"] {
      max-width: 12%;
      padding-right: 0;

      .col {
        padding: 0;
      }
    }

    [class^="txtCMA"] {
      max-width: 13%;
      min-width: 13%;
      padding-right: 0;
    }
  }

  .grpbxPrchsStrgWtCert {

    // bottom: 380px;
    input {
      text-align: right;
    }

    .card {
      border: none;
      background-color: #c0bcbc;
      ;
    }

    .grpbxPurchases {
      .card {
        border: none;
        min-height: 400px;
      }

      padding-left: 0;
      padding-right: 0.2%;
      max-width: 25%;

      label {
        width: 130px;
        text-align: right;
      }

      .lblPurchases {
        label {
          width: 100% !important;
          text-align: center;
          font-weight: bold;
        }
      }

    }

    .grpbxStorage {
      .card {
        border: none;
        min-height: 400px;
      }

      padding-left: 0;
      padding-right: 0.2%;
      max-width: 25%;

      label {
        width: 130px;
        text-align: right;
      }

      .lblStorage {
        label {
          width: 100% !important;
          text-align: center;
          font-weight: bold;
        }
      }
    }

    .grpbxWeightCert {
      input {
        text-align: left;
      }

      .card {
        border: none;
        min-height: 400px;
      }

      padding-left: 0;
      padding-right: 0;
      max-width: 25%;

      label {
        width: 100px;
        text-align: right;
      }

      .lblWeightCert {
        label {
          width: 100% !important;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }

  .grpbxInspectorRemarks {

    // bottom: 838px;
    // bottom: 595px;
    // bottom: 378px;
    .card-header {
      display: none
    }

    .card {
      border: none;
      background-color: #c0bcbc;

    }
  }

  .grpbxHMC {
    .txtSeg,
    .txtFM,
    .txtLSK,
    .txtMST,
    .txtSMK,
    .txtSS,
    .txtSMKRS,
    .txtOK,
    .txtFRZ,
    .txtCRMD,
    .txtTotalKernels,

    .txtDAM,
    .txtHULLS,
    .txtELK,
    .txtFANCY,
    .txtTotKrnlsHulls {

      input {

        min-width: 130%;

        max-width: 130%;

      }

    }

  }

  .grpbxValueCalculation {

    // bottom: 851px;
    // bottom: 597px;
    // bottom: 379px;
    input {
      text-align: right;
    }

    padding-left: 0;
    max-width: 35%;
    min-width: 35%;

    .card-header {
      display: none
    }

    .card {
      min-height: 942px;
      border: none;
      background-color: #c0bcbc;
    }

    .txtValPerPoundInclSLK {
      input {
        display: none;
      }
    }

    .textboxwidget-container {
      label {
        width: 175px;
        text-align: right;
      }

      padding: 0;
    }

    .txtWtIncludingVehicle,
    .txtWtOfVehicle,
    .txtGrossWeight,
    .txtForeignMaterial,
    .txtWtlessFM,
    .txtExcessMoisture,
    .txtNetWeight,
    .txtLSKvc,
    .txtNetWtExclLSK {
      .col {
        max-width: 25%;
        min-width: 25%;
      }
    }

    .txtKernelValueTon,
    .txtELKPremiumTon,
    .txtTotalTon,
    .txtDamageTon,
    .txtAccessFMTon,
    .txtExcessSplitsTon,
    .txtAFlavusTon,
    .txtTotalDiscountsTon,
    .txtValPerPoundExclSLK,
    .txtG {
      .col {
        padding-right: 0;
      }
    }

    .txtG {
      input {
        max-width: 118%;
        min-width: 118%;
      }
    }

    .txtOI,
    .txtH,
    .txtTotalLoadVal,
    .txtNetValPerTonExclSLK {
      .col {
        max-width: 29%;
        min-width: 29%;
      }
    }

    .lblValueCalculation {

      max-width: 50%;

      label {
        text-align: left;
        font-weight: bold;
      }
    }

    .lblPerTon {
      max-width: 25%;
      margin-left: 50%;

      label {
        text-align: center;
      }
    }

    .lblPerLb {
      max-width: 25%;

      label {
        text-align: center;
      }
    }

    .btnCalculate {
      display: none;
    }

    .txtValPerPoundExclSLK {
      min-width: 70%;
      max-width: 70%;
    }

    .lblPerLb2 {
      max-width: 30%;
    }
  }

  .grpbxGradeCalculation {
    padding-right: 0.2%;
    min-width: 50%;
    input {
      text-align: right;
      padding-right: 10%;
      padding-left: 10%;
    }
    .card-header {
      display: none
    }
    .card {
      min-height: 942px;
      border: none;
      background-color: #c0bcbc;
    }
    .grpbxVirginiaGrading .card {
      min-height: 184px !important;
    }

    .lblGradeCalculation {

      label {
        text-align: left;
        font-weight: bold;
      }
    }

    .txtFMSampleWt,
    .txtProbingPattern,
    .txtCleanSampleWt,
    .txtValenciaCleanSampleWt {
      label {
        width: 145px;
        text-align: right;
      }
    }

    .lblCornFound {
      margin-left: 16%;

      label {
        width: 100%;
        text-align: right;
      }
    }

    .chkboxCornFound {
      max-width: 30%;
      margin-bottom: 0.5%;
      padding-left: 0;

      .mb-3 {
        margin-bottom: 0.6rem !important;
      }

      .col {
        padding-left: 0;
      }
    }

    .lblGrams1,
    .lblGrams2 {
      max-width: 15%;
      margin-left: 20%;

      label {
        width: 100%;
        text-align: center;
      }
    }

    .lblPct1,
    .lblPct2 {
      max-width: 15%;

      label {
        width: 100%;
        text-align: center;
      }
    }

    .txtSMKRSGr,
    .txtFreezeDamageGr,
    .txtSMK1Gr,
    .txtSMK3Gr,
    .txtSMK2Gruic0,
    .txtSMK4Gr,
    .txtConcealedRMDGr,
    .txtCrkOrBrkShellsGr,
    .txtDiscoloredShellsGr,
    .txtHullBrightness,
    .txtSoundSplitsGr,
    .txtJumboGr,
    .txtELKGr,
    .txtOtherKernelsGr,
    .txtForeignMaterialGr,
    .txtTotalDamageGr,
    .txtLSKGr,
    .txtHullsGr,
    .txtFancyGr {
      max-width: 35%;
      min-width: 35%;
      padding-right: 0;

      label {
        width: 101px;
        text-align: right;
      }
    }

    .txtTotalSMK,
    .txtTotalKrnls,
    .txtTotalKernelsHulls {
      max-width: 50%;
      min-width: 50%;
      padding-right: 0;

      label {
        width: 101px;
        text-align: right;
      }

      .d-flex {
        max-height: 33px;
      }
    }

    .txtSMKRSPct,
    .txtFreezeDamagePct,
    .txtSMK1Pct,
    .txtSMK3Pct,
    .txtSMK2Pct,
    .txtSMK4Pct,
    .txtConcealedRMDPct,
    .txtCrkOrBrkShellsPct,
    .txtDiscoloredShellsPct,
    .txtHullBrightnessPct,
    .txtSoundSplitsPct,
    .txtJumboPct,
    .txtELKPct,
    .txtOtherKernelsPct,
    .txtForeignMaterialPct,
    .txtTotalDamagePct,
    .txtLSKPct,
    .txtHullsPct,
    .txtFancyPct {
      max-width: 15%;
      min-width: 15%;
      padding: 0;

      .col {
        padding-left: 0;
      }
    }

    .txtDamageSplits,
    .txtOtherForeignMaterials,
    .txtKernelsREL,
    .txtMoistureReading,
    .txtKernelsRPS,
    .txtMeterReading,
    .txtDamagedKRS,
    .txtELKDamage,
    .txtBluePanWt {
      label {
        width: 101px;
        text-align: right;
      }

      .d-flex {
        max-height: 33px;
      }

      padding-right: 0;
    }

    .txtKernelsREL,
    .txtMoistureReading,
    .txtKernelsRPS,
    .txtDamagedKRS,
    .txtTotalKernelsHulls,
    .txtTotalKrnls,
    .txtTotalSMK {
      input {
        width: 50.5%;
        margin-left: auto;
      }
    }

    .txtDamageSplits {
      input {
        max-width: 38%;
        min-width: 38%;
      }
    }

    .txtMeterReading,
    .txtBluePanWt,
    .txtELKDamage {
      input {
        width: 47.5%;
      }
    }

    .lblVirginiaGrading {
      label {
        text-align: left;
        font-weight: bold;
      }
    }

    .txtSampleWt,
    .txtGrams,
    .txtPercent {
      label {
        width: 65px;
        text-align: right;
      }

      padding: 0;
    }

    .lblFlavusFound {
      max-width: 19%;

      padding-right: 0;

      label {
        width: 150px;
        text-align: right;
      }

    }

    .chkboxFlavusFound {

      .mb-3 {
        margin-bottom: 0.4rem !important;
      }

      .col {
        padding-left: 0;
      }

      .d-flex {
        height: 27px;
      }
    }
  }

  .grpbxHMC {
    // bottom: 379px;
    // bottom: 851px;
    // bottom: 597px;
    padding-right: 0.2%;
    padding-left: 0;
    max-width: 15%;

    .card-header {
      display: none
    }

    .card {
      min-height: 940px;
      border: none;
      background-color: #c0bcbc;
    }

    .textboxwidget-container {
      padding-right: 0;

      label {
        width: 75px;
        text-align: right;
      }
    }

    .btnConvertHMC {
      max-width: 80%;
      margin-left: 10%;

      button {
        width: 100%;
      }
    }

    .lblHMCConvertedGrades {
      max-width: 70%;
      margin-left: 15%;

      label {
        text-align: center;
        font-weight: bold;
      }
    }

  }


  .grpbxActions {
    bottom: 0;
    position: sticky;

    .card {
      background-color: #f3f0f0;
      ;
    }

    .lblAddedBy {
      max-width: 7%;
      padding-right: 0;
    }

    .lblAddedByValue {
      position: relative;
      right: 22px;
      bottom: 6px;
      min-width: 17%;
      padding: 0;

      label {
        display: none;
      }

      .d-flex {
        .col {
          padding-right: 0;
        }
      }
    }

    .lblChangedBy {
      position: relative;
      left: 2px;
      bottom: 11px;
      padding-right: 0;
      max-width: 7%;
      padding-left: 0;
    }

    .lblChangedByValue {
      position: relative;
      right: 24px;
      bottom: 18px;
      min-width: 18%;
      padding: 0;

      label {
        display: none;
      }
    }

    .btnDeductTrack,
    .btnPremsDeduct {
      min-width: 9%;
    }

    .btnCorrectionLog {
      min-width: 11%;
    }

    .btnApplications {
      min-width: 9.5%;
    }

    .btnCorrectionReprint {
      min-width: 12%;
    }

    .btnResendToKCMO {
      min-width: 11%;
    }

    .btnVoid,
    .btnReprint,
    .btnClose {
      button {
        width: 80%;
      }
    }

    .btnVoid {
      position: relative;
      left: 28px;
    }

    .btnReprint {
      position: relative;
      left: 11px;
    }

    .btnFree1007 {
      button {
        min-width: 120%;
        max-width: 120%;
      }
    }

    .btnReprint {
      max-width: 8%;
      min-width: 8%;
    }
  }

  //   .card-header{
  //     display:none
  //   }
  //   margin-top: 1rem;
  //   // bottom: 397px;
  //   .card{
  //     border: none;
  //     background-color: #f3f0f0;
  //     padding-top: 1rem;
  //   }
  //   .lblChangedBy,.lblAddedBy {
  //     padding: 0;
  //     max-width: 6%;
  //     label{
  //       width: 100%;
  //       text-align: right;
  //     }
  //   }
  //   .lblAddedByValue{
  //     min-width: 23%;
  //     padding: 0;
  //     // margin-right: 10%;
  //     // margin-right: 30%;
  //   }
  //   .lblChangedByValue{
  //     min-width: 22%;
  //     max-width: 22%;
  //     padding: 0;
  //   }
  //   .lblChangedBy,.lblChangedByValue{
  //     bottom: 10px;
  //   } 
  //   .lblAddedByValue,.lblAddedBy{
  //     bottom: 10px;
  //   }
  //   .buttonwidget-container{
  //     max-width: 9%;
  //     min-width: 9%;
  //     // top: 15px;
  //     padding: 0;
  //     button{
  //       width: 79%;
  //       height: 40px;
  //     }
  //   }
  //   .btnPayments ,.btnAcctDist{
  //     min-width: 7.5% !important;
  //     max-width: 7.5% !important;
  //     margin-right: 0.5%;
  //   }
  //   .btnClose {
  //     min-width: 7% !important;
  //     max-width: 7% !important;
  //     margin-left: 0.25%;
  //   }
  //   .btnCorrectionReprint ,.btnWorksheet{
  //     min-width: 10% !important;
  //     max-width: 10% !important;
  //   }
  //   .btnCancel{
  //     margin-left: 10%;
  //   }
  // }
  .grpbxTicketVendrSplit {
    max-width: 67%;
    min-width: 67%;
    padding-right: 0.5%;
    padding-top: 0.1%;
  }

  /*END_USER_CODE_FOR_.SCSS*/
}