.WarehouseReceipts-DelvSettleApplyView,
.ContractManagement-DelvSettleApplyView {
  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }
  // background-color: #333e48;
  background: transparent;
  .groupboxwidget-container {
    .card {
      border: none;
      background-color: #f3f0f0;
      .card-header {
        display: none;
      }
    }
  }
  .grpbxDelvSettleApplyView {
    .card {
      .textboxwidget-container {
        .d-flex {
          display: block !important;
          label {
            width: 100%;
            text-align: center;
            margin-bottom: 0 !important;
          }
          .col {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
          }
          input {
            text-align: center;
          }
        }
      }
      .txtDelvInspNum {
        max-width: 12%;
        min-width: 12%;
        padding-right: 0;
      }
      .txtSettleNum,
      .txtInOut,
      .txtSeed {
        max-width: 10%;
        min-width: 10%;
        padding: 0;
      }
      .txtPeanutType {
        max-width: 12%;
        min-width: 12%;
        padding: 0;
      }
      .txtPeanutVariety {
        max-width: 17%;
        min-width: 17%;
        padding: 0;
      }
      .txtNetLbs {
        max-width: 12%;
        min-width: 12%;
        padding: 0;
      }
      .txtValue {
        max-width: 17%;
        min-width: 17%;
        padding-left: 0;
      }
      .lblAgreementApplications {
        max-width: 50%;
        min-width: 50%;
        label {
          margin-bottom: 0 !important;
          font-weight: bold;
        }
      }
      .txtAgreementLocations {
        max-width: 45%;
        min-width: 45%;
        margin-left: 5%;
        .d-flex {
          display: flex !important;
          label {
            text-align: right;
            width: 35%;
          }
        }
        input {
          text-align: left !important;
        }
      }
      .gridAgreementApplications {
        min-width: 100%;
        .rdt_TableCol:nth-child(1),
        .rdt_TableCell:nth-child(1) {
          min-width: 50px;
          max-width: 50px;
        }
        .rdt_TableCol:nth-child(7),
        .rdt_TableCell:nth-child(7){
          min-width: 60px;
          max-width: 60px;
        }
        .rdt_TableCol:nth-child(9),
        .rdt_TableCell:nth-child(9){
          min-width: 110px;
          max-width: 110px;
        }
        .rdt_TableCol:nth-child(13),
        .rdt_TableCell:nth-child(13){
          min-width: 120px;
          max-width: 120px;
        }
        .rdt_TableCol:nth-child(11),
        .rdt_TableCell:nth-child(11){
          min-width: 140px;
          max-width: 140px;
        }
        .rdt_TableCol:nth-child(12),
        .rdt_TableCell:nth-child(12){
          min-width: 140px;
          max-width: 140px;
        }
        .rdt_TableCol:nth-child(4),
        .rdt_TableCell:nth-child(4),
        .rdt_TableCol:nth-child(6),
        .rdt_TableCell:nth-child(6)
        
         {
          min-width: 50px;
          max-width: 50px;
          text-align: center;
          justify-content: center !important;
        }
        .rdt_TableCol:nth-child(9),.rdt_TableCol:nth-child(2)
        ,.rdt_TableCol:nth-child(7),.rdt_TableCol:nth-child(8){
          text-align: center;
          justify-content: center !important;
        }
        .rdt_TableCol:nth-child(5){
          text-align: center;
          justify-content: center !important;
        }
        .rdt_TableCell:nth-child(8),
        .rdt_TableCell:nth-child(9),
        .rdt_TableCell:nth-child(10),
        .rdt_TableCell:nth-child(11),
        .rdt_TableCell:nth-child(12),
        .rdt_TableCell:nth-child(13) {
          justify-content: right !important;
        }
      }
    }
  }
  .grpbxActions {
    .card {
      background-color: #f3f0f0;
      .lblAddedBy,
      .lblChangedBy {
        max-width: 10%;
        min-width: 10%;
        label {
          width: 100%;
          text-align: right;
        }
      }
      .lblAddedByValue,
      .lblChangedByValue {
        max-width: 30%;
        min-width: 30%;
        padding-left: 0;
        label {
          text-align: left;
        }
      }
      .lblChangedByValue,
      .lblChangedBy {
        bottom: 10px;
      }

      .btnCancel {
        min-width: 10%;
        max-width: 10%;
        margin-left: 50%;
        top: 15px;
        button {
          width: 90%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
