.SystemMaintenanceMasterManagement-InterestAgreementProfile,.ContractManagement-InterestAgreementProfile {
   /*START_USER_CODE_FOR_.SCSS*/
   h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxAgreeProf {
    margin-bottom: 0 !important;
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none;
      }
      .card-body{
        width: 70%;
        margin-left: 15%;
        label{
          width: 105px;
          text-align: right;
        }
        .txtLoc{
          min-width: 100%;
          input{
            background: transparent;
            border: none;
            box-shadow: none;
            color: blue;
            padding-left: 0;
          }
        }
        .txtVendr ,.txtRate,.txtAgreement{
          max-width: 30%;
          min-width: 30%;
          padding-right: 0;
          label{
            color: #c02020;
          }
          .col{
            padding-right: 0;
          }
        }
        .txtRate,.txtAgreement{
          margin-right: 65%;
        }
        .btnVndr {
          max-width: 7%;
          min-width: 7%;
          margin-bottom: 0.3rem !important;
          .row{
            justify-content: center;
          }
        }
        .txtVendrName  {
          max-width: 63%;
          min-width: 63%;
          padding-left: 0;
          .col{
            padding-left: 0;
          }
          label{
            display: none;
          }
          input{
            background: transparent;
            border: none;
            box-shadow: none;
            color: blue;
            padding-left: 0;
          }
        }
        .ddRemitTo{
          min-width: 100%;
          label{
            color: #c02020;
          }
          .dropdown{
            width: 60%;
          }
        }
        .dtIntrstBegn {
          min-width: 100%;
          .col{
            max-width: 35%;
            min-width: 35%;
          }
        }
      }
    }
  }
  .grpbxGrids {
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none;
      }
      .txtAvlbRadyToPays {
        min-width: 50%;
        max-width: 50%;
        label{
          margin-bottom: 0;
        }
        input{
          background: transparent;
          border: none;
          box-shadow: none;
          color: blue;
          padding-bottom: 3.1%;
        }
      }
      .txtAssociatedReadyToPay {
        min-width: 40%;
        max-width: 40%;
        label{
          margin-bottom: 0;
        }
        input{
          background: transparent;
          border: none;
          box-shadow: none;
          color: blue;
          padding-bottom: 3.1%;
        }
      }
      .btnSelectAll {
        max-width: 10%;
        min-width: 10%;
        margin-bottom: 0.1rem !important;
        button{
          width: 100%;
        }
      }
      .gridAvlbReady {
        min-width: 47.5%;
        max-width: 47.5%;
        .rdt_TableCol:nth-child(3),
        .rdt_TableCell:nth-child(3){
          min-width: 160px;
          max-width: 160px;
        }
      }
      .grpbxButtons {
        min-width: 5%;
        max-width: 5%;
        padding: 0;
        .card{
          height: 100% !important;
          .row{
            flex-direction: column;
    justify-content: center;
    min-height: 100%;
          }
        }
        .card-body{
          padding-right: 0.75rem;
          padding-left: 0.75rem;
          button{
            width: 100%;
          }
        }
      }
      .gridAssocReady {
        min-width: 47.5%;
        max-width: 47.5%;
        .rdt_TableCell:nth-child(7),.rdt_TableCol:nth-child(7){
          min-width: 20px;
          max-width: 20px;
          border-right: none !important;
        }
        .rdt_TableCell:nth-child(8),.rdt_TableCol:nth-child(8){
          min-width: 30px;
          max-width: 30px;
        }
      }
    }
  }
  .grpbxBottom {
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none;
      }
      .grpbxRemarks {
        max-width: 60%;
        min-width: 60%;
        .txtLienhldr {
          min-width: 100%;
          label{
            color: #c02020;
          }
          input{
            background: transparent;
            border: none;
            box-shadow: none;
            color: blue;
            padding-left: 0;
            padding-bottom: 2.6%;
          }
        }
        .ddRemit {
          min-width: 100%;
          label{
            color: #c02020;
          }
          .dropdown{
            width: 70%;
          }
        }
        .txtarRemarks {
          min-width: 100%;
          .d-flex{
            display: block !important;
            label{
              margin-bottom: 0;
            }
            .col{
              padding: 0;
            }
          }
        }
        .lblRemarks {
          min-width: 100%;
          label{
            color: red;
          }
        }
      }
      .grpbxArCr {
        max-width: 40%;
        min-width: 40%;
        .textboxwidget-container{
          min-width: 66.67%;
          max-width: 66.67%;
          padding: 0;
          label{
            width: 50%;
            text-align: right;
          }
          .col{
            padding-right: 0;
          }
        }
        [class ^= "txtBln" ]{
          min-width: 33.33%;
          max-width: 33.33%;
          label{
            display: none;
          }
        }
        .lblBalance{
          min-width: 33.33%;
          max-width: 33.33%;
          padding-right: 0;
          label{
            width: 100%;
            text-align: center;
          }
        }
        .txtValRtp,.txtInterest,.txtBlnc,.txtBlnc2,.txtBlnc3,.txtValChck{
          input{
            text-align: right;
            color: blue;
          }
        }
        .txtCllctSr,.txtCllctAr,.txtCllctCR{
          input{
          text-align: right;
          }
        }
      }
      .grpbxActions {
        .card{
          background-color: #f3f0f0;
          .txtAddedBy,.txtChangedBy {
            min-width: 50%;
            max-width: 50%;
            label{
              width: 70px;
            }
            input{
              background: transparent;
              border: none;
              box-shadow: none;
              padding-left: 0;
              padding-bottom: 3.2%;
            }
          }
          .btnSave,.btnSaveBatch,.btnCncl,.btnPrntChck {
            min-width: 12%;
            max-width: 12%;
            top: 10px;
            button{
              width: 90%;
            }
          }
          .btnSave{
            margin-left: 2%;
          }
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
