.SystemMaintenanceMasterManagement-WarehouseBinCopy,.ContractManagement-WarehouseBinCopy {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxWarehouseBinCopy{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .grpbxBuyingPoint{
      .card{
        background-color: #f3f0f0;
      }
      label{
      width: 250px;
      text-align: right;
    }
  }
  .grpbxCopyTo{
    .card{
      background-color: #c0bcbc;
    }
    label{
      width: 180px;
      text-align: right;
    }
    .ddLocation1,.ddLocation2,.ddLocation3,.ddLocation4,.ddLocation5,.ddLocation6,.ddLocation7,.ddLocation8,.ddLocation9,.ddLocation10{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    }
    .ddLocation1,.ddLocation2,.ddLocation3,.ddLocation4,.ddLocation5,.ddLocation6,.ddLocation7,.ddLocation8,.ddLocation9,.ddLocation10{
      min-width: 100%;
    }
  }
  .cmmndCntnrActions{
    .row{
      justify-content: end;
    }
  }
  }
  
  /*END_USER_CODE_FOR_.SCSS*/
}
