.WarehouseReceipts-DelvInAvailAgreement,.ContractManagement-DelvInAvailAgreement {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  //background-color: #333E48;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxDelvInAvailAgreement {
    .lblCropYear{
      max-width: 20%;
      margin-left: 60%;
      color: red;
      margin-top: 2rem;
      font-weight: 600;
      font-size: large;
      label{
        position: relative;
        bottom: 15px;
      }
    }
    .card{
      min-height: 230px;
    }
    .lbl4{
      max-width: 20%;
      min-width: 20%;
      color: red;
      font-weight: 600;
      font-size: large;
      .col{
        position: relative;
        right: 66%;
        bottom: 22px;
      }
    }
    .ddAgreement{
      min-width: 100%;
      label{
        width: 80px;
        text-align: right;
      }
      .dropdown-menu.show{
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }
    .lblAgreeNumBuyPtId {
      color: blue;
      label{
        position: relative;
        left: 95px;
      }
    }
  }
  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.25rem;
      .buttonwidget-container{
        max-width: 15%;
        min-width: 15%;
        button{
          width: 90%;
        }
      }
      .btnRemove{
        margin-left: 55%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
