.SystemMaintenanceApplicationSupport-ManualRTPMaint,.ContractManagement-ManualRTPMaint {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxManualRTPMaint{
    margin-bottom: 0.2rem !important;
    .card-header{
      display:none
    }
    .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #f3f0f0;
      .dropdown-menu{
        max-height: 200px;
        overflow: auto;
      }
    }
    label{
      width: 120px;
      text-align: right;
    }
    .textboxwidget-container{
      min-width: 45%;
      max-width: 45%;
    }
   
    .ddLocation{
      max-width: 90%;
      min-height: 90%;
      padding-right: 0;
    }
    .txtVe{
      display: none !important;
    }
    .txtVendor{
      max-width: 30%;
      min-width: 30%;
      padding-right: 0;
      .col{
        padding-right: 0;
      }
    }
    .ddOBLLocation {
      min-width: 60%;
      padding: 0;
    }
    .btnVendor{
      max-width: 10%;
      margin-bottom: 0.3rem !important;
    }
    .ddStatus{
      max-width: 30%;
      min-width: 30%;
      padding-right: 0;
      .col{
        padding-right: 0;
      }
    }
    .txtProceeds{
      input{
        text-align: right;
      }
    }
    .ddOptions ,.ddStorageInd,.ddShrinkPayment ,.ddLoanReduction{
      .dropdown{
        width: 20%;
      }
      min-width: 100%;
    }
    .txtState,.txtCounty{
      max-width: 22.5%;
      min-width: 22.5%;
    }
    .txtCounty{
      margin-right: 45%;
      label{
        width: 70px;
      }
    }
    .txtState{
      padding-right: 0;
      .col{
        padding-right: 0;
      }
    }
    .txtVndr  {
      padding: 0;
      input{
        color: blue;
        box-shadow: none;
        border: none;
        background: transparent;
      }
    }
    .ddWarehouseReceiptLocation,.txtReason {
      max-width: 90%;
      min-width: 90%;
    }
    .ddWarehouseReceiptLocation {
      min-width: 63%;
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
       button{
       height: 30px;
      }
    }
  }

  .grpbxActions  {
    .card-header{
      display:none
      }
      .card{
      padding-top: 0.25rem;
      border: none;
      background-color: #f3f0f0;
      }
      .lblChangedBy,.lblAddedBy {
        max-width: 10%;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .lblChangedByValue,.lblAddedByValue{
        min-width: 40%;
        margin-right: 30%;
        padding-left: 0;
        .col{
       
          position: relative;
          bottom: 6px;
          right: 22px;
      }
      }
      .lblChangedBy,.lblChangedByValue{
        bottom: 10px;
      } 
      .lblAddedByValue,.lblAddedBy{
        bottom: 10px;
      }
      .btnOk,.btnCancel{
        max-width: 10%;
        top: 10px;
        button{
          width: 85%;
        }
      }
  }

  /*END_USER_CODE_FOR_.SCSS*/
}
