.SystemMaintenanceApplicationSupport-LienHolderProfile,.ContractManagement-LienHolderProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }
  .grpbxLienHolderDetails{
    .card-header{
      display: none;
    }
    .card{
      background-color: #c0bcbc;
    }
    label{
      width: 80px;
      text-align: right;
    }
    .txtVendor{
      input{
        color:blue;
        background: transparent;
      }
    }
    .txtVendor,.txtLienOrder{
      input{
        max-width: 45%;
      }
    }
    .txtLienOrder{
      label{
        color:red
      }
    }
    .txtRemitTo{
      input{
        max-width: 20%;
        display: none;
      }
    }
    .ddRemitTo{
      .dropdown{
        max-width: 60%;
      }
    }
    .txtCity,.txtAddress,.txtName,.txtNatureOfLien{
      input{
        max-width: 50%;
      }
    }
    .ddState{
      max-width: 25%;
    }
    .txtStateID,.txtStateID1{
      max-width: 15%;
      input{
      display: none;
      }
    }
    .txtZip{
      input{
        max-width: 16%;
      }
    }
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color:#f3f0f0 ;
    }
    label{
      width: 80px;
      text-align: right;
    }
    .txtAddedBy{
      min-width:90%;
      input{
        max-width: 27.5%;
      }
    }
    .btnOk{
      max-width: 4%;
      position:relative;
      right:30px;
    }
    .btnCancel{
      max-width: 6%;
    }
  }
  
  /*END_USER_CODE_FOR_.SCSS*/
}
