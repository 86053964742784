.SystemMaintenanceApplicationSupport-VendorSearch,.ContractManagement-VendorSearch{
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333E48;
  background: transparent;
  h1{
    display: none;
  }
  div.rdt_TableCol:has(div#column-txtLienHolders), 
  div[id^='cell-txtLienHolders_ID-'] {
    color: black;
  }
  .grpbxVendorDetails{
    margin-bottom: 0 !important;
    label{
      width:100px;
    }
    .card {
      background-color: #f3f0f0;
    }
    .card-header{
      display: none;
    }
    .ddBuyingPoint{
      .dropdown{
        max-width: 40%;
      }
      .dropdown-menu.show{
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }
    .lblMinOf3Chars1,.lblMinOf3Chars2{
      label{
        color: blue;
      }
    }
    .txtVendorNumber{
      max-width: 27%;
      padding-right: 0;
    }
    .txtVendorName{
      max-width: 40%;
      padding-right: 0;
    }
  }
  .grpbxVendorList{
    .card {
      background-color: #f3f0f0;
    }
    .card-header{
      display: block;
    }
    .lblVendorList{
      max-width: 30%;
      min-width: 30%;
    }
    .btnOpen{
      max-width: 7.5%;
      min-width: 7.5%;
      margin-left: 38.25%;
      margin-bottom: 0.3rem !important;
      button{
        width: 90%;
      }
    }
    .btnSearch{
      max-width: 7.5%;
      min-width: 7.5%;
      margin-left: 16.25%;
      margin-bottom: 0.3rem !important;
      button{
        width: 100%;
      }
    }
    .gridVendorList{
      .rdt_TableCol:nth-child(1),
      .rdt_TableCell:nth-child(1){
        min-width: 87px;
        max-width: 87px;
      }
      .rdt_TableCell:nth-child(2),.rdt_TableCell:nth-child(3),.rdt_TableCell:nth-child(4),.rdt_TableCell:nth-child(15),.rdt_TableCell:nth-child(10), .rdt_TableCell:nth-child(11), .rdt_TableCell:nth-child(12){
        justify-content: left !important;
        }
      .rdt_TableCol:nth-child(2),
      .rdt_TableCell:nth-child(2){
        min-width: 180px;
        max-width: 180px;
      }
      .rdt_TableCol:nth-child(3),
      .rdt_TableCell:nth-child(3){
        min-width: 350px;
        max-width: 350px;
      }
      .rdt_TableCol:nth-child(4),
      .rdt_TableCell:nth-child(4){
        min-width: 360px;
        max-width: 360px;
      }
      .rdt_TableCol:nth-child(5),
      .rdt_TableCell:nth-child(5){
        min-width: 120px;
        max-width: 120px;
      }
      .rdt_TableCol:nth-child(6),
      .rdt_TableCell:nth-child(6){
        min-width: 120px;
        max-width: 120px;
      }
      .rdt_TableCol:nth-child(7),
      .rdt_TableCell:nth-child(7){
        min-width: 120px;
        max-width: 120px;
      }
      .rdt_TableCol:nth-child(9),
      .rdt_TableCell:nth-child(9){
        min-width: 150px;
        max-width: 150px;
      }
      .rdt_TableCol:nth-child(10),
      .rdt_TableCell:nth-child(10){
        min-width: 200px;
        max-width: 200px;
      }
      .rdt_TableCol:nth-child(11),
      .rdt_TableCell:nth-child(11){
        min-width: 200px;
        max-width: 200px;
      }
      .rdt_TableCol:nth-child(12),
      .rdt_TableCell:nth-child(12){
        min-width: 200px;
        max-width: 200px;
      }
      .rdt_TableCol:nth-child(13),
      .rdt_TableCell:nth-child(13){
        min-width: 120px;
        max-width: 120px;
      }
      .rdt_TableCol:nth-child(14),
      .rdt_TableCell:nth-child(14){
        min-width: 120px;
        max-width: 120px;
      }
      .rdt_TableCol:nth-child(15),
      .rdt_TableCell:nth-child(15){
        min-width: 300px;
        max-width: 300px;
      }
      .rdt_TableCol:nth-child(16),
      .rdt_TableCell:nth-child(16){
        min-width: 120px;
        max-width: 120px;
      }
      .rdt_TableCol:nth-child(17),
      .rdt_TableCell:nth-child(17){
        min-width: 140px;
        max-width: 140px;
      }
      .rdt_TableCol:nth-child(18),
      .rdt_TableCell:nth-child(18){
        min-width: 120px;
        max-width: 120px;
      }
    }
  }
  .cmmndCntnrActions{
    .row{
    background-color: #f3f0f0;
    }
    .btnExportToExcel{
      max-width: 10%;
    }
    .btn1{
      max-width: 4%;
      display: none;
    }
    .btnPrintSearch{
      min-width: 80%;
    }
    .btnClose{
      max-width: 5%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}

