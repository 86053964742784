.WarehouseReceipts-DelvSettleApply,.ContractManagement-DelvSettleApply {
    /*START_USER_CODE_FOR_.SCSS*/
    h1{
      display: none;
    }
    // background-color: #333E48;
  background: transparent;
    .groupboxwidget-container{
      .card{
        border: none;
        background-color:  #f3f0f0;
        .card-header{
          display: none;
        }
      }
    }
    .grpbxDelvSettleApply  {
      .card{
        .textboxwidget-container{
          .d-flex{
            display: block !important;
            label{
              width: 100%;
              text-align: center;
              margin-bottom: 0 !important;
            }
            .col{
              padding-right: 0.5rem;
              padding-left: 0.5rem;
            }
          }
        }
        .txtDelvInspNum {
          max-width: 12%;
          min-width: 12%;
          padding-right: 0;
          input{
            text-align: center;
          }
        }
        .txtInOut,.txtSeedYN{
          max-width: 10%;
          min-width: 10%;
          padding: 0;
          input{
            text-align: center;
          }
        }
        .txtPeanutType {
          max-width: 12%;
          min-width: 12%;
          padding: 0;
          input{
            text-align: center;
          }
        }
        .txtFirstVendorNumName  {
          max-width: 20%;
          min-width: 20%;
          padding: 0;
          input{
            text-align: center;
          }
        }
        .txtSeg{
          max-width: 7%;
          min-width: 7%;
          padding: 0;
          input{
            text-align: center;
          }
        }
        .txtNetLbs {
          max-width: 12%;
          min-width: 12%;
          padding: 0;
          input{
            text-align: center;
          }
        }
        .txtValue{
          max-width: 17%;
          min-width: 17%;
          padding-left: 0;
          input{
            text-align: center;
          }
        }
        .lblAgreementApplications {
          max-width: 50%;
          min-width: 50%;
          label{
            margin-bottom: 0 !important;
            font-weight: bold;
          }
        }
        .txtAgreementLocations  {
          max-width: 45%;
          min-width: 45%;
          margin-left: 5%;
          // padding: 0;
          .d-flex{
            display: flex !important;
            label{
              text-align: right;
              width: 35%;
            }
            
          }
        }
        .gridAgreementApplications {
          min-width: 100%;
          .rdt_TableCell:nth-child(9),
          .rdt_TableCell:nth-child(10),
          .rdt_TableCell:nth-child(11),
          .rdt_TableCell:nth-child(13),
          .rdt_TableCell:nth-child(12) {
            justify-content: right !important;
          }
          .rdt_TableCell:nth-child(7){
            input{
              text-align: right;
            }
          }
          .rdt_TableCell:nth-child(8){
            input{
              text-align: center;
            }
          }
          .rdt_TableCol:nth-child(7),
          .rdt_TableCell:nth-child(7) {
            min-width: 80px;
            max-width: 80px;
          }
          .rdt_TableCol:nth-child(8),
          .rdt_TableCell:nth-child(8) {
            min-width: 130px;
            max-width: 130px;
          }
          .rdt_TableCol:nth-child(9),
          .rdt_TableCell:nth-child(9) {
            min-width: 140px;
            max-width: 140px;
          }
        }
        .txtLoanRepaymentRate {
          max-width: 25%;
          min-width: 25%;
          .d-flex{
            display: flex !important;
            label{
              font-weight: bold;
              width: 128px;
            }
          }
        }
        .txt100PctAdjValue {
          max-width: 25%;
          min-width: 25%;
          margin-left: 50%;
          .d-flex{
            display: flex !important;
            label{
              font-weight: bold;
              width: 139px;
            }
          }
        }
      }
    }
    .cmmndCntnrActions  {
      .row{
        padding-top: 0.25rem;
        background-color: #f3f0f0;
        .btnOk{
          min-width: 10%;
          max-width: 10%;
          margin-left: 70%;
          button{
            width: 90%;
          }
        }
        .btnCancel{
          min-width: 10%;
          max-width: 10%;
          button{
            width: 90%;
          }
        }
      }
    }
    /*END_USER_CODE_FOR_.SCSS*/
  }
  
