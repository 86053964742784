.SystemMaintenanceSpecialFunctions-PremiumDeducMasterSetup,.ContractManagement-PremiumDeducMasterSetup{
/*START_USER_CODE_FOR_.SCSS*/
h1{
  display: none;
}
// background-color: #333E48;
background: transparent;
.cmmndCntnrActions{
.row{
  background-color: #f3f0f0;
  }
  .btnClose{
    min-width: 100%;
  justify-content: center;
  display: flex;
  }
}
.grpbxPremiumDeducMasterSetup{
  .card{
    background-color: #c0bcbc;
  }
  .card-header{
    display: none;
  }
  .lblPDCodeList{
    min-width: 82.5%;
  }
  .btnAdd,.btnEdit{
    max-width: 5%;
  }
  .btnDelete{
    max-width: 6%;
  }
  .lstPDCodeList{
    select{
      height: 180px;
    }
  }
}
/*END_USER_CODE_FOR_.SCSS*/
}

