.AccountPayment-ReadyToPayRelease, .ContractManagement-ReadyToPayRelease {
  /*START_USER_CODE_FOR_.SCSS*/
  .card-header,
  h1 {
    display: none;
  }
  .card {
    background-color: #c0bcbc;
  }
  // .grpbxpayee {
  //   max-width: 25%;
  //   padding-right: 0;
  //   .card {
  //     border: none;
  //     height: 100%;
  //   }
  //   .txtCollectionPoint {
  //     min-width: 54%;
  //   }
  //   .txt1stPayeeTo {
  //     min-width: 45%;
  //     input {
  //       max-height: 10px;
  //     }
  //   }
  // }
  // .grpbxLocRemit {
  //   min-width: 45%;
  //   .card {
  //     border: none;
  //   }
  //   label {
  //     width: 92px;
  //     text-align: right;
  //   }
  //   .txt2ndLocation,
  //   .txt1stLocation {
  //     max-width: 30%;
  //     padding-right: 0;
  //     input {
  //       max-height: 10px;
  //     }
  //   }
  //   .txt1stLocation {
  //     margin-left: 37%;
  //   }
  //   .btnBrowse {
  //     max-width: 10%;
  //   }
  //   .txtCarrierAbbr {
  //     min-width: 40%;
  //   }
  //   .txtFarmSuffix,
  //   .txtFarm {
  //     min-width: 35%;
  //     padding-right: 0;
  //     label {
  //       width: 92px;
  //     }
  //   }
  //   .btnSrch {
  //     left: 63px;
  //     max-width: 15%;
  //     bottom: 10px;
  //   }
  // }
  // .grpbxNoteChck {
  //   max-width: 30%;
  //   padding-left: 0;
  //   .card {
  //     border: none;
  //     height: 100%;
  //   }
  //   .chckbx {
  //     max-width: 20%;
  //     left: 75%;
  //   }
  //   .lblNOTE {
  //     top: 65px;
  //   }
  // }
  .grpbxDetails{
    min-width: 73.6%;
    padding-right: 0;
    .card{
      border:none
    }
    label{
      width: 150px;
      text-align: right;
    }
    .ddLocation{
      max-width: 70%;
      color: red;
      .dropdown-menu.show{
        overflow-y:scroll!important;
        max-height:25vh!important;}
    }
    .txtVendor{
      max-width: 34%;
      color:red;
    }
    .txtVndor {
      max-width: 37%;
      min-width: 37%;
      padding-left: 0;
      input{
      color:blue;
      border: none;
      background: transparent;
      }
      label{
        display: none;
      }
    }
    .btnBrowse{
      max-width: 0%;
      button{
      min-height: 25px;
      }
      position: relative;
      top: 2px;
    }
    .txtVendor, .txtCarrierAbbr, .txtVndor {
      position: relative;
      bottom: 2px;
    }
    .txtCarrierAbbr{
      min-width: 25%;
      label{
        width: 70px;
      }
    }
    .ddremitTo{
      max-width:75%;
    }
    .txtFarm{
      min-width: 35%;
    }
    .txtFarmSuffix{
      min-width: 30%;
      input{
        max-width: 70%;
      }
      label{
        width: 70px;
      }
    }
  }
  .grpbxRemit{
    padding-right: 0;
    .ddRemitTo{
      color: red;
    }
    .card{
      min-height: 100%;
    }
  }
  .grpbxNoteChck{
    max-width: 25%;
    padding: 0;
    .card{
      min-height: 100%;
      border: none;
    }
    .lblNOTE{
      color: red;
      top: 75px;
      right: 70%;
    }
  }
  .txtarRemarks{
    textarea.form-control {
      height: auto;
    }
  }
  .grpbxReadyToPay{
    .lblRTPsHold{
      max-width: 12%;
      padding: 0%;
    }
    .lblRTPsReleased{
      min-width: 17%;
      label{
        width: 85%;
      }
    }
    .lblRTPsHoldValue{
      min-width: 42%;
      color:blue;
      padding-bottom: 0.5%;
    }
    .lblRTPsReleasedValue{
      min-width: 17%;
      color: blue;
      padding-bottom: 0.5%;
    }
    .gridReadyToPayHold{
      min-width: 46%;
    //   // max-height: 200px;
    //   // min-height: 200px;
    //   // overflow-y: overlay;
    //   // .rdt_TableBody {
    //   //   max-height: 250px;
    //   // }
    .rdt_TableBody,.rdt_TableHead{
      min-width: 104%;
    }
    .rdt_TableCol:nth-child(11){
      text-align: center !important;
    }
    }
    .gridReadyToPayReleased{
      min-width: 46%;
      max-width: 46%;
      .rdt_TableCol:nth-child(11){
        text-align: center !important;
      }
    //   // .rdt_TableBody {
    //   //   max-height: 250px;
    //   // }
    .rdt_TableBody,.rdt_TableHead{
      min-width: 104%;
    }
    }
    .grpbxArrows{
      max-width: 8%;
      .card{
        border: none;
      }
    }
  }
  .labelwidget49{
    font-size: 13px;
      font-weight: 500;
  }
  .grpbxPayHold {
    .card {
      border: none;
    }
  }
  .grpbxRemit {
    .card {
      border: none;
    }
    .txtRemarks {
      .d-flex {
        display: grid !important;
      }
    }
    .lblLienholders{
      color:red;
      padding-bottom: 1%;
    }
    .lblLienholdersValue{
      position: relative;
      right : 370px;
      color : blue;
      padding-top: 0.5%;
    }
    .lblSaveNote {
      min-width: 100%;
      left: 8%;
      color: red;
      label{
    bottom: 20%;
    position: relative;
      }
    }
  }
  .grpbxBalance {
    padding-left: 0;
    .card {
      border: none;
    }
    label {
      width: 111px;
    }
    .txtValueOfCheck,
    .txtCollectCR,
    .txtCollectAR,
    .txtCollectSR,
    .txtValOfRTP,
    .txtCheck {
      min-width: 64%;
      padding-right: 0;
      input{ text-align: right; }      
    }
    .lblValOfRTPValue,.lblSRBalance,.lblARBalance,.lblCRBalance,.lblValueOfCheckValue{
      color:blue;
    }
    
    .txtCRBalance,
    .txtARBalance,
    .txtSRBalance,
    .txtCheck2 {
      max-width: 30%;
    }
    .lblBalance {
      left: 5%;
      max-width: 30%;
    }
  }
  .grpbxActions {
    .card {
      border: none;
      background-color: #f3f0f0;
    }
    .lblCorpYear2 {
      min-width: 41%;
      margin-left: 28%;
      font-weight: 500;
      color: red;  
      font-size: 16px;

    }
    .btnCancel,
    .btnSave {
      max-width: 5%;
    }
    .btnSaveToBatch {
      max-width: 10%;
    }
    .btnPrintCheck {
      max-width: 9%;
    }
  }
}