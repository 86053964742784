.Settlements-FV95Search,
.ContractManagement-FV95Search {
  /*START_USER_CODE_FOR_.SCSS*/

  h1 {
    display: none;
  }
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  // form{
  //   height: 730px;
  // }
  .grpbxFV95Search,
  .grpbxSearchResults,
  .grpbxAcions {
    .card-header {
      display: none;
    }

    .card {
      border: none;
      background-color: #f3f0f0;
    }
  }
  .grpbxFV95Search {
    .card {
      max-height: 525px;
    }
  }
  // .grpbxNumber,.grpbxLocation,.grpbxPeanut ,.grpbxType ,.grpbxFarm ,.grpbxWhseBin,.grpbxMiscOpt {
  //   .card-header{
  //     display:none
  //   }
  //   .card{
  //     border: none;
  //     background-color: #c0bcbc;

  //   }
  // }
  .grpbxNumber {
    padding-right: 0;
    min-width: 20%;
    max-width: 20%;
    .lblNumber {
      label {
        font-weight: bold;
      }
    }
    label {
      width: 55px;
      text-align: right;
    }
    .txtContract {
      max-height: 32px;
    }
    .chkboxPercentOnly {
      label {
        text-align: left;
      }
    }
    .chkboxPercentOnly {
      min-width: 20%;
      max-width: 20%;
      padding: 0;
      .col {
        padding: 0;
        .form-check {
          padding-left: 0.5rem;
        }
      }
    }
    .btnVendor {
      min-width: 20%;
      max-width: 20%;
    }
    .txtSharePercent,
    .txtVendor {
      min-width: 80%;
      padding-right: 0;
      input {
        width: 100% !important;
      }
    }
    .txtSharePercent {
      min-width: 78% !important;
      max-width: 78%;
      .col {
        padding-bottom: 3%;
      }
    }
    .textboxwidget-container {
      input {
        width: 72%;
      }
    }
  }

  .grpbxPeanut {
    padding: 0;

    .lblPeanut {
      label {
        font-weight: bold;
        text-align: left;
      }
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
    max-height: 355px;
    label {
      min-width: 65px;
      text-align: right;
    }
    .dropdownfieldwidget-container,
    .textboxwidget-container {
      padding: 0;
    }
    .txtGeneration {
      padding-right: 0;
      min-width: 40%;
      max-width: 40%;
      .col {
        padding-right: 0;
      }
    }
    .ddSeed {
      padding: 0;
      max-width: 60%;
      min-width: 60%;
      label {
        min-width: 40px;
      }
    }
    .ddSeed,
    .ddPeanutType,
    .ddPeanutVariety {
      .col {
        padding-right: 0;
      }
    }
    .ddOleic,
    .ddHighMoist {
      .col {
        padding-right: 0;
      }
    }
  }
  .grpbxLocFarm {
    min-width: 30%;
    padding-left: 0;
    padding-right: 0;
    .grpbxLocation {
      padding: 0;
      //min-width: 30%;
      //max-width: 30%;
      min-width: 100%;
      .card-body {
        padding-top: 0;
        padding-left: 0;
      }
      .lblLocation {
        min-width: 100%;
        label {
          font-weight: bold;
        }
      }
      max-height: 190px;
      .ddBuyingPoint,
      .ddObligationPoint {
        label {
          width: 90px;
          text-align: right;
        }
        padding-right: 0;
      }
      .ddArea {
        margin-left: 9%;
        max-width: 30%;
        text-align: right;
        .dropdown {
          width: 135%;
          margin-left: -5%;
        }
      }
      .ddCollectionPoint {
        margin-left: 2%;
        label {
          min-width: 37%;
          text-align: right;
        }
        .dropdown {
          max-width: 90%;
        }
      }
      .txtVndor {
        min-width: 80%;
        padding-left: 0;
      }
      input{
      color: blue;
      border: none;
      background: transparent;
      font-weight: 600;
      padding: 0;
      }
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }
  }
  .grpbxTypeWhse {
    padding-right: 0;
    padding-left: 0;
  }
  .grpbxType {
    padding-left: 0;
    min-width: 100%;
    // max-width: 25%;
    .card-body{
      padding-top: 0;
    }
    label {
      width: 77px;
      text-align: right;
    }
    .lblType {
      min-width: 100%;
      label {
        font-weight: bold;
      }
    }
    // max-height: 300px;
    .dropdownfieldwidget-container {
      // label {
      //   width: 77px;
      //   text-align: right;
      // }
      padding-right: 0;
      // .col {
      //   width: 80%;
      // }
    }

    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
  }
  .grpbxFarm {
    padding: 0;
    min-width: 100%;
    // min-width: 30%;
    // max-width: 30%;
    .card-body {
      padding-left: 0;
    }
    .lblFarm {
      margin-left: 5%;
      label {
        font-weight: bold;
        text-align: left;
      }
    }
    .dropdownfieldwidget-container {
      bottom: 8px;
      min-width: 100%;
      padding-right: 0;
    }
    // margin-left: 20%;
    // bottom: 153px;
    label {
      width: 90px;
      text-align: right;
    }
    .ddState,
    .ddCounty {
      .dropdown {
        width: 60%;
      }
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
  }
  .grpbxWhseBin {
    padding-left: 0;
    // min-width: 25%;
    // max-width: 25%;
    min-width: 100%;
    .lblWhseBin {
      label {
        font-weight: bold;
      }
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
    .dropdownfieldwidget-container {
      label {
        width: 77px;
        text-align: right;
      }
      padding-right: 0;
      .col {
        width: 80%;
      }
    }
    // margin-left: 25%;
    // bottom: 80px;
  }
  .grpbxMiscOpt {
    // margin-left: 18.5%;
    // bottom: 155px;
    margin-left: 17%;
    bottom: 10px;
    min-width: 60%;
    .chkboxAll1007s {
      // top: 5px;
      max-width: 16%;
      padding: 0;
      .form-check {
        width: 110%;
      }
    }
    .chkboxIncludeResidueLoads {
      // top: 5px;
      max-width: 25%;
      padding: 0;
      .form-check {
        width: 120%;
      }
    }
    .txtInspectionDateFrom {
      min-width: 36%;
      padding: 0;
      position: relative;
      top: 3px;
      .input-group-append{
        max-width: 31%;
      }
    }
    .txtInspectionDateTo {
      max-width: 23%;
      position: relative;
      top: 1px;
      .input-group-append{
        max-width: 29%;
      }
    }
  }
  .grpbxSearchResults {
    // bottom: 153px;
    .buttonwidget-container {
      min-width: 8%;
      button {
        width: 88%;
        height: 35px;
        line-height: 13px;
      }
    }
    .btnSearch {
      margin-left: 4%;
      button {
        height: 40px !important;
      }
      .row{
        position: relative;
        top: 4px;
      }
    }
    .btnUnSignSettlements {
      min-width: 11%;
    }
    .lblTotals {
      max-width: 10%;
      label {
        font-weight: bold;
        position: relative;
        top: 8px;
      }
    }
    .lblNetWeight,
    .lblContracted,
    .lblUncontracted,
    .lblValue {
      max-width: 10%;
      label {
        width: 95%;
        text-align: right;
        position: relative;
        top: 8px;
      }
      padding-right: 0;
    }
    .lblNetWeightValue,
    .lblContractedValue,
    .lblUncontractedValue,
    .lblValueValue {
      min-width: 13.75%;
    }
  }
  .grpbxAcions {
    // bottom: 153px;
    .radioAsShownExpColl {
      padding: 0;
    }
    .buttonwidget-container {
      min-width: 8%;
      button {
        width: 96%;
        height: 50px;
      }
    }
    .btnTransferSC95File,
    .btn1 {
      margin-left: 1%;
    }
    .btnAuthorizeApplicationEdit {
      min-width: 10%;
    }
    .btnClose {
      margin-left: 7.5%;
      margin-right: 6%;
    }
    .btn1,
    .btn2,
    .btn3,
    .btn4 {
      display: none;
      // min-width: 4% !important;
      // max-width: 4% !important;
      // button{
      //   height: 50px !important;
      // }
    }
  }
  .gridFV95SearchData {
    .popover-body{
      .columns-parent {
        background-color: aliceblue;
        div:nth-child(1){
        display: none;
        }
      }
    }
    .card-header{
      display: block;
      .popover-body{
        button:nth-child(1){
          display: none;
        }
      }
    }
    .expandable-area {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      background-color: #e7f9ff;
      min-height: 40px !important;
      .rdt_ExpanderRow {
        position: relative;
        top: 8px;
        .extraRowCell_1 {
          max-width: 5%;
          min-width: 39.5%;
          margin-left: 21%;
        }
        .extraRowCell_2 {
          min-width: 5.3%;
        }
        .extraRowCell_3 {
          min-width: 5%;
          text-align: right;
          margin-left: 2.5%;
        }
        .extraRowCell_4 {
          min-width: 3.33%;
          margin-left: 8.5%;
        }
        .extraRowCell_5 {
          min-width: 3.33%;
          margin-left: 1%;
        }
      }
    }
  }
  .GridWidget .collapse > div {
    max-height: none;
  }
  .gridFV95SearchData {
      .rdt_TableCol,
      .rdt_TableCell  {
        min-width: 150px;
        max-width: 150px;
        padding: 0;
        //min-height: 30px;
    }
  }
    .rdt_TableCol:nth-child(2),
    .rdt_TableCell:nth-child(2) {
      min-width: 52px;
      max-width: 50px;
    }
  //   .rdt_TableCol:nth-child(3),
  //   .rdt_TableCell:nth-child(3) {
  //     min-width: 78px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(4),
  //   .rdt_TableCell:nth-child(4) {
  //     min-width: 84px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(5),
  //   .rdt_TableCell:nth-child(5) {
  //     min-width: 110px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(6),
  //   .rdt_TableCell:nth-child(6) {
  //     min-width: 224px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(7),
  //   .rdt_TableCell:nth-child(7) {
  //     min-width: 180px;
  //     max-width: 100px;
  //   }
  //   .rdt_TableCol:nth-child(8),
  //   .rdt_TableCell:nth-child(8) {
  //     min-width: 212px;
  //     max-width: 100px;
  //   }
  //   .rdt_TableCol:nth-child(9),
  //   .rdt_TableCell:nth-child(9) {
  //     min-width: 100px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(10),
  //   .rdt_TableCell:nth-child(10) {
  //     min-width: 93px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(11),
  //   .rdt_TableCell:nth-child(11) {
  //     min-width: 52px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(12),
  //   .rdt_TableCell:nth-child(12) {
  //     min-width: 50px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(13),
  //   .rdt_TableCell:nth-child(13) {
  //     min-width: 100px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(14),
  //   .rdt_TableCell:nth-child(14) {
  //     min-width: 100px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(15),
  //   .rdt_TableCell:nth-child(15) {
  //     min-width: 80px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(16),
  //   .rdt_TableCell:nth-child(16) {
  //     min-width: 80px;
  //     max-width: 50px;
  //   }
  //   .rdt_TableCol:nth-child(17),
  //   .rdt_TableCell:nth-child(17) {
  //     min-width: 100px;
  //     max-width: 50px;
  //   }
  // }
  // .rdt_TableCol:nth-child(18),
  // .rdt_TableCell:nth-child(18) {
  //   min-width: 119px;
  //   max-width: 50px;
  // }
}
/*END_USER_CODE_FOR_.SCSS*/
