.StockTransfer-FrtAccrlSearch, .ContractManagement-FrtAccrlSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }

  .grpbxFrtAccrlSearch {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .grpbxDetails {
      padding-right: 0;
      padding-left: 0;
      

      .ddShippingLocation ,.ddDestinationLocation {
        min-width: 50%;
        max-width: 50%;
        label{
          width: 120px;
          text-align: right;
        }
      }
      .txtVendorNum ,.txtCarrierAbbrNum ,.txtTransferNum ,.txtCropYear ,.txtPayableCropYear {
        min-width: 50%;
        max-width: 50%;
        input{
          width: 25%;
        }
        label{
          width: 120px;
          text-align: right;
        }
      }
      .tctScaleTktNum ,.txtInvoiceNum ,.txtMemoNum ,.txtDispatchNum ,.txtCheckNum {
        min-block-size: 50%;
        max-width: 50%;
        input{
          width: 50%;
        }
        label{
          width: 70px;
          text-align: right;
        }
      }
      .txtDateRangeFrom {
        min-width: 25%;
        max-width: 25%;
        padding-right: 0;
        label{
          width: 70px;
          text-align: right;
        }
      }
      .txtDateRangeTo {
        min-width: 18%;
        max-width: 18%;
        padding-left: 0;
        .col{
          padding-left: 0;
        }
      }
    }
    .gridFreightAccural{
      .card-header{
        display: block;
      }
      .rdt_TableCol:nth-child(3),
      .rdt_TableCell:nth-child(3){
        min-width: 200px;
        max-width: 200px;
      }
      .rdt_TableCol:nth-child(7),
      .rdt_TableCell:nth-child(7){
        min-width: 200px;
        max-width: 200px;
      }
      .rdt_TableCol:nth-child(8),
      .rdt_TableCell:nth-child(8){
        min-width: 200px;
        max-width: 200px;
      }
      .rdt_TableCell:nth-child(3),
        .rdt_TableCell:nth-child(7),
        .rdt_TableCell:nth-child(8) {
          justify-content: left !important;
        }
        .rdt_TableCol:nth-child(9),.rdt_TableCol:nth-child(14){
          justify-content: center !important;
        } 
    }
    .cmmndCntnrSearchOpt {
      padding-right: 0;
      padding-left: 0;
      .btnReversePayments,.btnUpdateInvoice{
        min-width: 15%;
        max-width: 15%;
        button{
          width: 90%;
        }
      }
      .btnUpdateInvoice{
        margin-left: 16%;
      }
      .btnPrint,.btnSearch{
        min-width: 10%;
        max-width: 10%;
        button{
          width: 90%;
        }
      }
      .btnSearch{
        margin-left: 40%;
      }
      .btnReversePayments{
        margin-left: 5%;
      }
      .btnPrint{
        margin-left: 9%;
        button{
          width: 90%;
        }
      }
    }

    .txtTotals{
      min-width: 17%;
      max-width: 17%;
      margin-left: 82%;
      input{
        border: none;
        background-color: #f3f0f0;
      }
      .col{
        position: relative;
        bottom:2px;
      }
    }
  }
  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btnClose{
      min-width: 10%;
      max-width: 10%;
      margin-left: 40%;
      button{
        width: 90%;
      }
    }
    .btnExportToExcel{
      min-width: 12%;
      max-width: 12%;
      margin-left: 28%;
      button{
        width: 90%;
      }
    }
    .btnPrintSearch {
      min-width: 10%;
      max-width: 10%;
      button{
        width: 90%;
      }
    }
  }
  
  
  /*END_USER_CODE_FOR_.SCSS*/
}
