.Settlements-TradeSettleApplyView,.ContractManagement-TradeSettleApplyView{
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }

  .grpbxTradeSettleApplyView{
    .card {
      background-color: #c0bcbc;

      .textboxwidget-container{
        padding: 0;
        .d-flex{
          display: block !important;
          label{
            width: 100%;
            text-align: center;
          }
          .col{
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
        }
      }
      .txtTradeInspNum {
        max-width: 10%;
        input{
          text-align: center;
        }
      }
      .txtSettleNum {
        max-width: 8%;
        min-width: 8%;
      }
      .txtPeanutType{
        max-width: 22%;
        min-width: 22%;
        input{
          text-align: left;
        }
      }
      .txtPeanutVariety {
        max-width: 30%;
        min-width: 30%;
        input{
          text-align: left;
        }
      }
      .txtSeed {
        max-width: 8%;
        input{
          text-align: center;
        }
      }
      .txtPounds {
        max-width: 12%;
        input{
          text-align: right;
        }
      }
      .txtPurchaseSale {
        max-width: 10%;
        input{
          text-align: center;
        }
      }

      .lblAgreementApplications {
        min-width: 100%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        label{
          font-weight: bold;
          margin-bottom: 0;
        }
      }
      .gridAgreementApplications {
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        .rdt_TableCol:nth-child(1),
        .rdt_TableCell:nth-child(1){
          min-width: 30px;
        }
        .rdt_TableCol:nth-child(2),
        .rdt_TableCell:nth-child(2){
          min-width: 73px;
        }
        .rdt_TableCol:nth-child(3),
        .rdt_TableCell:nth-child(3){
          min-width: 95px;
        }
        .rdt_TableCol:nth-child(4),
        .rdt_TableCell:nth-child(4){
          min-width: 90px;
        }
        .rdt_TableCol:nth-child(5),
        .rdt_TableCell:nth-child(5){
          min-width: 72px;
        }
        .rdt_TableCol:nth-child(6),
        .rdt_TableCell:nth-child(6){
          min-width: 50px;
        }
        .rdt_TableCol:nth-child(7),
        .rdt_TableCell:nth-child(7){
          min-width: 58px;
        }
        .rdt_TableCol:nth-child(8),
        .rdt_TableCell:nth-child(8){
          min-width: 68px;
        }
        .rdt_TableCol:nth-child(9),
        .rdt_TableCell:nth-child(9){
          min-width: 82px;
          justify-content: right !important;
        }
        .rdt_TableCol:nth-child(10),
        .rdt_TableCell:nth-child(10){
          min-width: 60px;
        }
        .rdt_TableCol:nth-child(11),
        .rdt_TableCell:nth-child(11){
          min-width: 70px;
          justify-content: right !important;
        }
        .rdt_TableCol:nth-child(12),
        .rdt_TableCell:nth-child(12){
          min-width: 75px;
          justify-content: right !important;
        }
        .rdt_TableCol:nth-child(13),
        .rdt_TableCell:nth-child(13){
          min-width: 70px;
          justify-content: right !important;
        }
        .rdt_TableCol:nth-child(14),
        .rdt_TableCell:nth-child(14){
          min-width: 70px;
          justify-content: right !important;
        }
      }
    }
  }
  .grpbxActions {
    .card-header{
      display:none
    }
    
    .card{
      border: none;
      background-color: #f3f0f0;
      padding-top: 0.5rem;
    }
    .lblChangedBy,.lblAddedBy {
      max-width: 10%;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblChangedByValue,.lblAddedByValue{
      min-width: 30%;
      max-width: 30%;
      padding-left: 0;
      label{
        max-width: 0;
      }
    }
    .lblChangedBy,.lblChangedByValue{
      bottom: 15px;
    } 
    
    .btnCancel{
      max-width: 10%;
      top: 10px;
      margin-left: 50%;
      button{
        width: 85%;
      }
    }
    
  }
  /*END_USER_CODE_FOR_.SCSS*/
}