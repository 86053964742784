.SystemMaintenanceMasterManagement-DeliveryPeriodProfile ,.ContractManagement-DeliveryPeriodProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxDeliveryPeriodProfile {
    .card-header {
      display: none;
    }
    .card {
      background-color: #c0bcbc;
    }
    label {
      width: 85px;
      // text-align: right;
    }
    .txtArea,
    .txtDeliveryId {
      input {
        max-width: 30%;
      }
    }
    .radioActiveStatus {
      .form-check {
        display: inline-flex !important;
        max-width: 7%;
      }
    }
  }
  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
      }
    .btnSave {
      margin-left: 88%;
    }
    .btnExit,
    .btnSave {
      max-width: 6%;
    }
  }

  
  /*END_USER_CODE_FOR_.SCSS*/
}
