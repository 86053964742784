.SystemMaintenanceApplicationSupport-ManualDPRInvHistSearch,.ContractManagement-ManualDPRInvHistSearch{
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxManualDPRInvHistSearch{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #f3f0f0;
      .card-header{
        display:none
      }

      .grpbxBuyPtDetails {
        min-width: 40%;
        max-width: 40%;
        padding: 0;
        .card{
          label{
            width: 70px;
            text-align: right;
          }
          .ddBuyPt {
            min-width: 100%;
            padding-right: 0;
            .col{
              padding-right: 0;
            }
          }
          .ddWhseBin {
            min-width: 100%;
          }
          .txtAuditDateFrom{
            // z-index: 1;
            min-width: 52.5%;
            max-width: 52.5%;
            padding-right: 0;
            .col{
              padding-right: 0;
            }
          }
          .txtAuditDateFrom{
          input:focus{
            z-index: 0;
            }
          }
          .txtAuditDateTo {
            min-width: 47.5%;
            max-width: 47.5%;
            padding-left: 0;
            label{
              width: 40px;
              text-align: center;
            }
            .col{
              padding-left: 0;
            }
            .input-group{
              width: 100%;
            }
          }
          .txtSC95 ,.txt1007 ,.txtWhseRcptNum {
            min-width: 100%;
            input{
              width: 40%;
            }
          }
        }
      }
      .grpbxTransferDetails {
        min-width: 30%;
        max-width: 30%;
        padding: 0;
        .card{
          label{
            width: 120px;
            text-align: right;
          }
          .textboxwidget-container{
            min-width: 100%;
            input{
              width: 80%;
            }
          }
          .txtTransfer {
            margin-top: 2.25rem;
          }
          .btnSearch {
            max-width: 30%;
            min-width: 30%;
            margin-left: 15%;
            margin-top: 2rem;
            button{
              width: 100%;
            }
          }
        }
      }
      .grpbxPnutDetails {
        min-width: 30%;
        max-width: 30%;
        padding: 0;
        .card{
          label{
            width: 70px;
            text-align: right;
          }
          .lblPeanut {
            label{
              font-weight: bold;
            }
          }
          .dropdownfieldwidget-container{
            min-width: 100%;
          }
        }
      }
      .grpbxAuditHistory {
        min-width: 100%;
        padding: 0;
        .card{
          .lblAuditHistOfInvAdj {
            max-width: 30%;
            min-width: 30%;
            label{
              margin-bottom: 0;
            }
          }
          .btnView{
            max-width: 5%;
            min-width: 5%;
            margin-right: 17px;
            margin-left: auto;
            margin-bottom: 0.3rem !important;
            button{
              width: 100%;
            }
          }
          .gridAuditHistory1 {
            min-width: 100%;
            .card{
              padding-top: 0 !important;
              border: 1px solid #cbc0c0;
            }
          }
        }
      }
    }
  }
  
  .grpbxActions {
    min-width: 100%;
    .card{
      background-color: #f3f0f0;
      .card-header{
        display: none;
      }
      .grpbxPrintExport {
        min-width: 15%;
        max-width: 15%;
        .card{
          border: none;
          padding-top: 0.2rem;
        .buttonwidget-container{
          min-width: 100%;
          margin-bottom: 0.2rem !important;
          button{
            width: 100%;
          }
        }
        }
      }
      .btmExit{
        min-width: 10%;
        max-width: 10%;
        margin-left: 30%;
        margin-bottom: 1.25rem !important;
        button{
          width: 100%;
          height: 40px;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}

