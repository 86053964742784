.SystemMaintenanceApplicationSupport-ManualAcctDistSearch,.ContractManagement-ManualAcctDistSearch {
/*START_USER_CODE_FOR_.SCSS*/
h1{
  display:none
}
// background-color: #333E48;
background: transparent;
.txtManualAcctDistSearch   {
  .card-header{
    display:none
  }
  .card{
    padding-top: 0.5rem;
    border: none;
    background-color: #f3f0f0;
  }
  .textboxwidget-container{
    max-width: 50%;
    min-width: 50%;
    label{
      width: 150px;
      text-align: right;
    }
    input{
      width: 50%;
    }
  }
  
  .txtTransfer, .txtDeliveryAgreement {
    max-width: 50%;
    min-width: 50%;
    margin-right: 50%;
  }
  .btnSearch{
    max-width: 10%;
    min-width: 10% !important;
    margin-left: 45%;
    button{
      width: 95%;
    }
  }
  .txtLocation {
    max-width: 34%;
    min-width: 34%;
    input{
      width: 100%;
    }
  }
  .txtLocationName{
      label{
        display: none !important;
      }
      padding: 0;
      .col{
        padding: 0;
      }
      input{
        position: relative;
        right: 18px;
        padding-left: 0;
        box-shadow: none;
        border: none;
        background: transparent;
        color: blue;
      }
  }
  .cmmndCntnrActions {
    
    .btnAdd,.btnView,.btnReversal{
      max-width: 7.5%;
      min-width: 7.5%;
      margin-bottom: 0 !important;
      button{
        width: 95%;
      }
    }
    .btnAdd{
      margin-left: auto;
    }
    
  }
  .gridManualAcctDist   {
    .card{
      padding-top: 0;
    }
    .rdt_TableCol:nth-child(1),
      .rdt_TableCell:nth-child(1) {
        min-width: 120px;
        max-width: 120px;
      }
      .rdt_TableCol:nth-child(2),
      .rdt_TableCell:nth-child(2) {
        min-width: 120px;
        max-width: 120px;
      }
      .rdt_TableCell:nth-child(2),.rdt_TableCell:nth-child(4),.rdt_TableCell:nth-child(11),.rdt_TableCell:nth-child(12){
        justify-content: right !important;
        }
        .rdt_TableCell:nth-child(12){
          text-align: center !important;
        }
        .rdt_TableCol:nth-child(11),
        .rdt_TableCell:nth-child(11) {
          min-width: 180px;
          max-width: 180px;
          text-align: right !important;
    
        }
      .rdt_TableCol:nth-child(12),
      .rdt_TableCell:nth-child(12) {
        min-width: 150px;
        max-width: 150px;
        text-align: center !important;
  
      }
  }
}
.cmmndCntnrClose {
  .row{
    background-color: #f3f0f0;
    padding-top: 0.25rem;
  }
  .btnClose{
    max-width: 10%;
    min-width: 10%; 
    margin-left: 45%;
    button{
      width: 100%;
    }
  }
  
}
/*END_USER_CODE_FOR_.SCSS*/
}
