.SystemMaintenanceSpecialFunctions-SecurityUserFunctionSelect,.ContractManagement-SecurityUserFunctionSelect {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSecurityUserFunctionSelect{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    margin-bottom: 0.2rem !important;
    .card{
      padding-top: 0.2rem;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none;
      }
      label{
        width: 125px;
        text-align: right;
      }
      .txtKeyInformation{
        min-width: 100%;
        input{
          width: 60%;
          padding: 0;
          border: 0;
          box-shadow: none;
          color: blue;
          background: transparent;
        }
      }
      .ddFunction {
        min-width: 80%;
        max-width: 80%;
        padding-right: 0;
      }
      .ddAccessLevel{
        min-width: 100%;
        .dropdown{
          width: 30%;
        }
      }
      .buttonwidget-container{
        max-width: 7.5%;
        margin-bottom: 0.3rem !important;
       .row{
        justify-content: center;
        button{
          width: 95%;
        }
       }
      }
      .btnAddLocation{
        margin-left: 56.5%;
      }
      .btnDeleteLocation{
        margin-right: 1%;
      }
      .lstLocations  {
        min-width: 80%;
        max-width: 80%;
        padding-right: 0;
        select{
          height:197px;
        }
      }
      .grpbxCopyToUserID {
        padding-left: 155px;
        max-width: 80%;
        min-width: 80%;
        .card{
          margin-top: 1rem;
          border: 1px solid #f1f2f3;
          .lblCopyToUserID {
            min-width: 100%;
            label{
              text-align: left;
            }
          }
          .ddCopyToUserID {
            max-width: 85%;
            min-width: 85%;
            padding: 0;
            .dropdown-toggle:empty::after {
              margin-left: auto;
            }
            button{
            height:30px;
            }
            #ddItem_{
              height: 25px;
            }
          }
          .btnCopy {
            max-width: 15%;
            min-width: 15%;
            button{
              width: 90%;
            }
          }
          .lblCopyToUser {
            min-width: 100%;
            label{
              text-align: left;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .grpbxActions  {
      .card{
        .card-header{
          display:none
          }
      border: none;
      background-color: #f3f0f0;
      .lblChangedBy,.lblAddedBy {
        max-width: 10%;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .lblChangedByValue,.lblAddedByValue{
        min-width: 40%;
        margin-right: 30%;
        padding-left: 0;
        label{
          display: none;
        }
        .col{
          position: relative;
          right: 28px;
          bottom: 7px;
        }
      }
      .btnSUFS_OK,.btnCancel{
        max-width: 10%;
        top: 15px;
        button{
          width: 85%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
