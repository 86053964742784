.SystemMaintenanceSpecialFunctions-LoanRepayProfile,.ContractManagement-LoanRepayProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxVendorWireTransfer{
  .card{
    border: none;
    background-color: #c0bcbc;
    .card-header{
      display: none;
    }
  }
  label{
    width: 100px;
    text-align: right;
  }
  .txtPeanutType{
    .col{
      padding: 0;
      position: relative;
     bottom: 2px;
      input{
        box-shadow: none;
        border: none;
        background: transparent;
        color: blue;
      }
    }
  }
  .txtEffectiveDate,.txtInterestRate{
    label{
      color: rgb(224, 15, 15);
    }
  }
  .txtEffectiveDate{
    input{
      max-width: 18%;
    }
  }
  .txtInterestRate{
    input{
      max-width: 18%;
    }
  }
  .txtEffectiveDate{
    .dropdown-menu{
     top: -145px !important;
     left: 225px !important;
    }
  }
}
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 8%;
      padding: 0;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 32%;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 7px;
      }
    }
    .btnAdd{
      margin-left: 40%;
    }
    .btnAdd,.btnCancel{
       max-width: 10%;
       min-width: 10%;
       top: 10px;
       button{
         width: 90%;
       }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
