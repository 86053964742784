.Settlements-ViewContractApplication,.ContractManagement-ViewContractApplication {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .grpbxViewContractApplication   {
    .card-header{
      display:none
    }
    
    .card{
      border: none;
      background-color:  #f3f0f0;
    }

    .textboxwidget-container{
      max-width: 12.5%;
      padding-right: 0;
      label{
        //  width: 100%;
         text-align: center;
      }
    }
    .gridViewContractApplication,.gridSeedGroverApp{
      overflow-y: scroll;
    }
    .txtSC95Num{
      label{
        // text-align: center;
        position: relative;
        left:18%;
      }
      input{
        width: 58%;
        text-align: center;
      }
    }
    .txt1007Num{
      margin-left: -4%;
      label{
        // text-align: center;
        position: relative;
        left:18%;
      }
      input{
        width: 58%;
        text-align: center;
      }
    }
    .txtFarm{
      position: relative;
    left: -4%;
    label{
      left: 118%;
      position: relative;
    }
    input{
      width: 250%;
      text-align: center;
    }
    }
    .txtPeanutType{
      position: relative;
    left: 13%;
    label{
      // text-align: center;
      margin-left: 6%;
    }
    input{
      width: 60%;
    text-align: center;
    }
    }
    .txtSeed{
      position: relative;
    left: 10%;
    label{
      // text-align: center;
      margin-left: 14%;
    }
    input{
      width: 50%;
    text-align: center;
    }
    }
    .txtSeedSpec{
      position: relative;
    left: 6%;
    label{
      // text-align: center;
      margin-left: 8%;
    }
    input{
      width: 52%;
    text-align: center;
    }
    }
    .txtPounds{
      position: relative;
      left: 2%;
      label{
        // text-align: center;
        margin-left: 20%;
      }
      input{
        width: 73%;
        text-align: center;
      }
    }
    .txtPrchsStrg{
      margin-left: 0%;
      input{
        width: 82%;
    text-align: center;
      }
    }
    .gridViewContractApplication {
      // max-height: 300px;
      // overflow-y: overlay;
      // .card {
      //   background-color: #777474;
      // }
      // padding-top: 8px;
      // .rdt_TableHeadRow {
      //   background-color: lightgray;
      // }
      // .rdt_TableCol {
      //   border: solid rgba(#777474, 0.1);
      // }
      .rdt_TableCol:nth-child(1),
      .rdt_TableCell:nth-child(1){
        min-width: 37px;
      }
      .rdt_TableCol:nth-child(2),
      .rdt_TableCell:nth-child(2){
        min-width: 60px;
      }
      .rdt_TableCol:nth-child(3),
      .rdt_TableCell:nth-child(3){
        min-width: 118px;
      }
      .rdt_TableCol:nth-child(4),
      .rdt_TableCell:nth-child(4){
        min-width: 91px;
      }
      .rdt_TableCol:nth-child(5),
      .rdt_TableCell:nth-child(5){
        min-width: 70px;
      }
      .rdt_TableCol:nth-child(6),
      .rdt_TableCell:nth-child(6){
        min-width: 60px;
      }
      .rdt_TableCol:nth-child(7),
      .rdt_TableCell:nth-child(7){
        min-width: 58px;
      }
      .rdt_TableCol:nth-child(8),
      .rdt_TableCell:nth-child(8){
        min-width: 88px;
      }
      .rdt_TableCol:nth-child(9),
      .rdt_TableCell:nth-child(9){
        min-width: 62px;
      }
      .rdt_TableCol:nth-child(10),
      .rdt_TableCell:nth-child(10){
        min-width: 91px;
      }
      .rdt_TableCol:nth-child(11),
      .rdt_TableCell:nth-child(11){
        min-width: 91px;
      }
      .rdt_TableCol:nth-child(12),
      .rdt_TableCell:nth-child(12){
        min-width: 96px;
      }
      .rdt_TableCol:nth-child(13),
      .rdt_TableCell:nth-child(13){
        min-width: 90px;
      }
      .rdt_TableCol:nth-child(14),
      .rdt_TableCell:nth-child(14){
        min-width: 90px;
      }
      .rdt_TableCol:nth-child(15),
      .rdt_TableCell:nth-child(15){
        min-width: 90px;
      }.rdt_TableCol:nth-child(16),
      .rdt_TableCell:nth-child(16){
        min-width: 75px;
      }
      .rdt_TableCol:nth-child(17),
      .rdt_TableCell:nth-child(17){
        min-width: 90px;
      }
      .rdt_TableCol:nth-child(18),
      .rdt_TableCell:nth-child(18){
        min-width:80px;
      }

    }
    
    .gridSeedGroverApp{
      .rdt_TableCol:nth-child(1),
      .rdt_TableCell:nth-child(1){
        min-width: 20px;
      }
      .rdt_TableCol:nth-child(2),
      .rdt_TableCell:nth-child(2){
        min-width: 117px;
      }
      .rdt_TableCol:nth-child(3),
      .rdt_TableCell:nth-child(3){
        min-width: 91px;
      }
      .rdt_TableCol:nth-child(4),
      .rdt_TableCell:nth-child(4){
        min-width: 63px;
      }
      .rdt_TableCol:nth-child(5),
      .rdt_TableCell:nth-child(5){
        min-width: 80px;
      }
      .rdt_TableCol:nth-child(6),
      .rdt_TableCell:nth-child(6){
        min-width: 77px;
      }
      .rdt_TableCol:nth-child(7),
      .rdt_TableCell:nth-child(7){
        min-width: 72px;
      }
      .rdt_TableCol:nth-child(8),
      .rdt_TableCell:nth-child(8){
        min-width: 87px;
      }
      .rdt_TableCol:nth-child(9),
      .rdt_TableCell:nth-child(9){
        min-width: 61px;
      }
      .rdt_TableCol:nth-child(10),
      .rdt_TableCell:nth-child(10){
        min-width: 82px;
      }
    }

    .lblContractApplications,.lblSpotApplications ,.lblSeedGroverApp  {
      font-weight: bold;
      min-width: 100%;
      label{
        margin-bottom: 0;
      }
      margin-top: 5px;
    }
    .lblSpotApplications{
      margin-left: 0;
      padding-left: 0;
      label{
        display: none;
      }
    }
    .txtLbsToApply ,.txtPrice {
      padding-bottom:10px;
      padding-top: 10px;
      padding-left: 0;
      min-width: 40%;
      max-width: 40%;
      div{
        display: flex;
      }
      .invalid-feedback{
        display: none;
      }
      label{
        text-align: right;
        width: 90%;
        margin-right: 5%;
      }
      
    }
    .grpbxSeedGroverApp{
      max-width: 60%;
    }
    .grpbxSpotApplication {
      max-width: 40%;
    }
  }
  .grpbxActions {
    .card-header{
      display:none
    }
    margin-top: 1rem;
    bottom: 15px;
    .card{
      border: none;
      background-color:  #f3f0f0;
      padding-top: 1rem;
    }
    .lblChangedBy,.lblAddedBy {
      max-width: 11%;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblChangedByValue,.lblAddedByValue{
      min-width: 40%;
      margin-right: 30%;
    }
    .lblChangedBy,.lblChangedByValue{
      bottom: 10px;
    } 
    .lblAddedByValue,.lblAddedBy{
      bottom: 10px;
    }
    .btnPurchase,.btnCancel{
      max-width: 10%;
      top: 15px;
      button{
        width: 85%;
        height: 40px;
      }
    }
    .btnPurchase{
      margin-left: auto;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}