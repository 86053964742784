.WarehouseReceipts-DelvAvailAgreement,.ContractManagement-DelvAvailAgreement {
  /*START_USER_CODE_FOR_.SCSS*/
 h1{
  display: none;
}
// background-color: #333E48;
background: transparent;
.groupboxwidget-container{
  .card{
    border: none;
    background-color:  #f3f0f0;
    .card-header{
      display: none;
    }
  }
}
.grpbxDelvAvailAgreement {
  .card{
    min-height: 180px;
  }
  .lblCropYear2{
    max-width: 20%;
    margin-left: 60%;
    color: red;
    font-size: 18px;
    font-weight: 500;
  }
  .ddAgreements {
    min-width: 100%;
    label{
      width: 80px;
      text-align: right;
    }
    .dropdown-toggle:empty::after {
      margin-left: auto;
    }
    button{
    height:30px;
    }
  }
  .lblAgreeNumBuyPtId {
    margin-left: 93px;
    color: blue;
  }
}
.cmmndCntnrActions {
  .row{
    background-color: #f3f0f0;
    padding-top: 0.25rem;
    .buttonwidget-container{
      max-width: 15%;
      min-width: 15%;
      button{
        width: 90%;
      }
    }
    .btnRemove{
      margin-left: 55%;
    }
  }
}
/*END_USER_CODE_FOR_.SCSS*/
}
