.Settlements-ViewPremiumDeductions,.ContractManagement-ViewPremiumDeductions {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .grpbxViewPremiumDeductions   {
    .card-header{
      display:none
    }
    
    
    .card{
      border: none;
      background-color:  #f3f0f0;
      
    }
    .cmmndCntnrCancel {
      .row{
        border: none;
        background-color:  #f3f0f0;
        padding-top: 0.3rem;
      }
      .btnCancel{
        min-width: 10%;
        max-width: 10%;
        button{
          width: 90%;
        }
        margin-left: 45%;
      }
    }
    
  }
  .gridViewPremiumDeductions{
    .extraRowCell_2,.extraRowCell_3,.extraRowCell_4,.extraRowCell_5{
      padding-top: 6px !important;
      font-size: 0.7rem;
      border-right: 1px solid #eeeede;
      min-height: 34px;
    }
    .rdt_TableRow{
      min-height: 35px !important;
    }
    .rdt_ExpanderRow{
      .py-3{
        padding-bottom: 0rem !important;
        padding-top: 0rem !important;
      }
      .extraRowCell_2{
        min-width: 14.5%;
        max-width: 14.5%;
        padding-right: 10px;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .extraRowCell_3{
        max-width: 25.3%;
        min-width: 25.3%;
        padding-left: 10px;
      }
      .extraRowCell_4{
        text-align: center;
        min-width: 28.3%;
        max-width: 28.3%;
      }
      .extraRowCell_5{
        border-right: none;
        min-width: 24.7%;
        max-width: 24.7%;
        label{
          text-align: right;
          width: 100%;
        }
      }
    } 
    #column-txtcolPremiumsDeductions{
      max-width: 100px;
    } 
    .rdt_TableCell{
      border-right: 1px solid #eeeede;
      padding-right: 7px;
      padding-left: 27px;
      font-weight: bolder;
      // color: red;  
      background-color: rgb(243, 243, 243);
      // font-size: 500px;
    }
    .ExpandRow{
      border-bottom: 1px solid #eeeede;
    }
    .extraRowCell_2{
      margin-left: 3.75%;
    }
    .rdt_TableCell:nth-child(2),.rdt_TableCol:nth-child(2){
      min-width: 100px;
      max-width: 155px;
    }
    .rdt_TableCell:nth-child(3),.rdt_TableCol:nth-child(3){
      min-width: 100px;
      padding: 0;
    }
    .rdt_TableCell:nth-child(4),.rdt_TableCol:nth-child(4){
      min-width: 100px;
    }
    .rdt_TableCell:nth-child(5),.rdt_TableCol:nth-child(5){
      min-width: 100px;
    }
    .rdt_TableCell:nth-child(2),.rdt_TableCell:nth-child(5){
      justify-content: right;

    }
    .rdt_TableCell:nth-child(5){
      padding-right: 33px;
      padding-left: 0px;
    }
    .rdt_TableRow {
      pointer-events: none !important;
    }
    .expandable-area{
      .row{
        cursor: pointer;
        color: rgba(0,0,0,0.87);;
      }
      .row:hover{
        color: rgba(0,0,0,0.87);;
        text-decoration: none;
      }
      .activeExpandRow{
        color: rgba(0,0,0,0.87);
        background-color: #e3f2fd;
        border-bottom-color: #FFFFFF;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
