.SystemMaintenanceSpecialFunctions-PriceScheduleAuditList,.ContractManagement-PriceScheduleAuditList {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxPriceScheduleAuditList{
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none
      }
      .btnView{
        max-width: 10%;
        min-width: 10%;
        margin-left: auto;
        margin-right: 1rem;
        button{
          width: 100%;
        }
      }
      .gridPriceScheduleAuditList   {
        min-width: 100%;
        label{
          display: none;
        }
        .card{
          border: 1px solid #f3f0f0;
          padding-top: 0 !important;
        }
      }
    }
  }

  .cmmndCntnrClose {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
      justify-content: center;
    }
    .btnClose{
      max-width: 10%;
      min-width: 10%;
      .row{
        button{
          width: 100%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
