.ContractManagement-AddendumSeedGrower2009 {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  .pageHeader {
    font-size: 1.15rem;
    color: black;
    font-weight: 400;
    text-align: center;
  }
  h1 {
    display: none;
  }
  .gridPeanut {
    max-width: 60%;
    // margin-left: 20%;
  }
  .grpSeedGrower {
    .card {
      border: none;
      background-color: lightgray;
      .card-header {
        display: none;
      }
      label {
        text-align: right;
      }
    }
    .txtCompany,
    .txtContract,
    .txtVendor,
    .txtPeanutType,
    .txtPounds,
    .txtOpenPounds {
      label {
        min-width: 100px;
      }
    }
    .drpSeedPremium {
      min-width: 53.33%;
    }
    .lblPerTon {
      max-width: 13.33%;
      padding-left: 0;
    }
    .txtOpenPounds {
      max-width: 33.33%;
    }
    .chkPeanutVar {
      .form-label {
        display: none;
      }
    }
  }
  .grpTotalApp {
    .card {
      border: none;
      .card-header {
        display: none;
      }
      label {
        text-align: right;
      }
      .txtTotApplied {
        margin-left: 57%;
      }
    }
  }
    .gridPeanut {
      .card {
        .card-header {
          display: block !important;
        }
      }
  
      .rdt_TableBody {
        overflow-x: hidden;
        overflow-y: auto;
        height: 200px;
      }
  
      .dropdown {
        min-width: 150px;
        max-width: 150px;
        box-shadow: 0px -1px 0px 2px grey !important;
  
        button {
            // background: white;
            // color: black;
            // border: black;
            .dropdown-toggle{

              //box-shadow: 0px -1px 0px 2px grey !important;
          box-shadow: none;
          }
            color: #212529;
            background-color: lightgray !important;
            border-color: #e9ecef !important;
            background-image: linear-gradient(white, #e9ecef 60%, #dde2e6) !important;
            box-shadow: none !important;
          }
        
          .btn-primary {
            background-image: none;
          }
      
        }
    }
  .commandcontainerwidget21 {
    label{
      width: 100px;
    }
    .row{
      background-color: white;
    }
    .txtAddedBy,.txtChangedBy{
      max-width: 20%;
      input{
        display: none;
      }
    }
    .btnApplications {
      min-width: 66%;
      display: flex;
      justify-content: end;
    }
    .btnUpdate {
      max-width: 8%;
      padding-left: 2.2%;
    }
    .btnCancel {
      max-width: 6%;
    }
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: transparent;
    opacity: 1;
    border: none;
    padding-top: 2px;
  }
  .txtSeededAcres {
    max-width: 25%;
    label {
      width: 78px;
    }
  }
  .txtSeededAcres2,
  .txtSeededAcres3 {
    max-width: 9%;
  } 

  // .form-group {
  //   margin-bottom: 0rem;
  // }
  // .mb-3, .my-3 {
  //     margin-bottom: 0rem !important;
  // }

  .dropdown-menu.show {
     overflow-y: scroll !important;
     max-height: 25vh !important;
    }
  /*END_USER_CODE_FOR_.SCSS*/
}