.ContractManagement-TradeSettleSearch,
.Settlements-TradeSettleSearch {

  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }

  .grpbxSearch {
    .card {
      border: none;
      background-color: #f3f0f0;
    }

    .card-header {
      display: none;
    }

    label {
      width: 145px;
      text-align: right;
    }
    .textboxwidget-container{
      .col{
        padding-left: 13px;
      }
    }
    .ddLocation,
    .ddStatus,
    .ddAgreementType {
      min-width: 45%;

      .dropdown {
        max-width: 50%;
      }
    }

    .txtBuyingPoint,
    .txtStatus,
    .txtAgreementType {
      max-width: 13%;
      padding-left: 0;

      input {
        width: 80%;
      }
    }

    .ddPeanutType,
    .ddEdibleOil,
    .ddOleic,
    .ddSegType,
    .ddGeneration,
    .ddPeanutVariety {
      min-width: 30%;
    }

    .txtPeanutType,
    .txtEdibleOil,
    .txtOleic,
    .txtSegType,
    .txtGeneration,
    .txtPeanutVariety,
    .txtGradePricingMehtod {
      max-width: 12%;
    }

    .txtTradeSettle,
    .txtTradeInspect,
    .txtAgreement {
      min-width: 58%;

      input {
        width: 30%;
      }
    }

    .txtVendor {
      max-width: 31.3%;
    }

    .btnVendor {
      min-width: 35.3%;
      margin-bottom: 4px !important;
    }

    // .txtVendor {
    //   min-width: 33%;
    //   max-width: 33%;
    // }
    // .btnVendor{
    //   margin-right: 0.3%;

    // }
    .ddGradePricingMehtod {
      padding-right: 0;
      max-width: 32.2%;
      label{
        width: 146px;
      }
    }

    .txtVndor {
      min-width: 40%;
      input{
      color: blue;
      }
    }

    .btnSearch {
      max-width: 8%;
      left: 9%;
    }
  }

  .ddPeanutVariety,
  .ddLocation {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
  }


  .grpbxTradeSettlementCrateOpen {
    .card-header {
      display: none;
    }

    .card {
      border: none;
    }

    // .gridTrdSettlSrch {
    //   .card{
    //     .card-header {
    //       display: block;
    //     }
    //   }


    // }
    .lblCropYear2 {

      max-width: 14%;
      min-width: 10%;
      margin-left: 65%;
      font-weight: 500;
      color: red;
      font-size: 16px;

      .col {
        div {
          top: 4px;
          right: 100%;
          position: relative;
        }
      }

      label {

        right: 23%;
        position: relative;
      }
    }

    .btnCreateTradeSettlement {
      max-width: 14%;
      //margin-left: 20%;
    }

    .btnOpen {
      max-width: 6%;
    }
  }

  .grpbxAction {
    .card-header {
      display: none;
    }

    .d-flex {
      display: grid !important;
    }

    .card {
      border: none;
      background-color: #f3f0f0;
    }

    .btnExportToExcel {
      max-width: 11%;
      min-width: 10%;
    }

    .btnPrint,
    .btnClose {
      max-width: 6%;
      min-width: 6%;
    }

    .btnClose {
      margin-left: 26%;
    }

    .txtSegValue,
    .txt100Value,
    .txtTkcLbsVal,
    .txtNetWt {
      max-width: 13%;

      label {
        left: 12%;
        position: relative;
      }
    }
  }

  .gridTrdSettlSrch {

    .card-header {
      display: block;
    }
    .expandable-area{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-right: 8px !important;
    }
    .ExpandRow{
      padding-right: 2%;
    padding-left: 2%;
    border-bottom: 0.7px solid #eeeede;
    }
    .ch-3{
      border-right: 1px solid #eeeede;
      min-height: 35px;
    }
   
    .extraRowCell_1{
      min-width: 5.65%;
    }
    .extraRowCell_2,.extraRowCell_11,.extraRowCell_12{
      min-width: 6.5%;
    }
    .extraRowCell_15{
      min-width: 6.3%;
    }
    .extraRowCell_13{
      min-width: 6.6%;
    }
    .extraRowCell_14{
      min-width: 6.56%;
    }
    .rdt_TableCol:nth-child(2),
    .rdt_TableCell:nth-child(2),
    .rdt_TableCol:nth-child(9),
    .rdt_TableCell:nth-child(9),.extraRowCell_8 {
      min-width: 85px;
      max-width: 85px;
    }

    .rdt_TableCol:nth-child(4),
    .rdt_TableCell:nth-child(4),
    .rdt_TableCol:nth-child(6),
    .rdt_TableCell:nth-child(6),.extraRowCell_3,.extraRowCell_5 {
      min-width: 77px;
      max-width: 77px;
      label{
        width: 100%;
        text-align: right;
      }
    }

    .rdt_TableCol:nth-child(5),
    .rdt_TableCell:nth-child(5),
    .rdt_TableCol:nth-child(16),
    .rdt_TableCell:nth-child(16),.extraRowCell_4,.extraRowCell_15 {
      min-width: 120px;
      max-width: 120px;
      justify-content: left;
    }

    .rdt_TableCol:nth-child(7),
    .rdt_TableCell:nth-child(7),.extraRowCell_6 {
      min-width: 100px;
      max-width: 100px;
    }

    .rdt_TableCol:nth-child(8),
    .rdt_TableCell:nth-child(8) ,.extraRowCell_7{
      min-width: 250px;
      max-width: 250px;
      label{
        width: 100%;
        text-align: center;
      }
    }

    .rdt_TableCol:nth-child(10),
    .rdt_TableCell:nth-child(10),
    .rdt_TableCol:nth-child(11),
    .rdt_TableCell:nth-child(11),.extraRowCell_9,.extraRowCell_10 {
      min-width: 80px;
      max-width: 80px;
    }
  }


  /*END_USER_CODE_FOR_.SCSS*/
}