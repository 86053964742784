.footer1 {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;

  .footer-bottom {
    padding: 15px 0;
    color: #ddd;
    font-size: 14px;
    .pull-left {
      float: left;
    }
    .pull-right {
      float: right;
    }
    ul {
      li {
        display: inline-block;
        button {
          display: block;
          padding-left: 8px;
        }

        button:after {
          content: "-";
          padding-left: 8px;
        }
      }

      li:last-child {
        button:after {
          content: "";
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .footer1 {
    .footer-bottom {
      .pull-left,
      .pull-right {
        float: none !important;
        width: 100%;
        text-align: center;
      }

      .pull-right {
        ul {
          padding-left: 0px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .footer1 {
    .footer-bottom {
      .pull-right {
        display: none;
      }
    }
  }
}
