.ContractManagement-MainMenu {
  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }
  .main{
    background-color: transparent;
  }
  .card-header {
    display: none;
  }
  .mb-2, .my-2 {
    margin-bottom: 0.3rem !important;
  }
  .buttonWidgetClass {
    box-shadow: none;
  }
  .btn-secondary {
    background-image: none;
  }
  .grpbxAppSysMaint {
    width: 98%;
    left: 1%;
    position: relative;
    .card{
      background: transparent !important;
      // background-color: #333E48 !important;
      border: none;
      .card-body{
        // border-right: 0.5px solid wheat;
        // border-left: 0.5px solid wheat;
        // border-bottom: 0.5px solid wheat;
      }
      .ddCropYear{
        margin-left: 36%;
        max-width: 8%;
        color: white;
        .dropdown-menu.show {
          overflow-y: scroll !important;
          max-height: 30vh !important;
          }
          .dropdown-menu.show{
            max-width: 115%;
            min-width: 115%;
          }
      }
      .ddCompany {
        min-width: 16%;
        color: white;
        padding-left: 1%;
      }
    }
  }
  
  .applicationSection {
    padding-left: 1%;
    padding-right: 0.5%;
    .whtSpc01,.whtSpc02 {
      display: none;
    }
    .btnApplicationLable {
      text-align: center;
      font-size: 22px;
      color: #FDC741;
      font-weight: bold;
    }
    .card-body {
      // background-color: #333E48;
      border:none !important;
      padding-left: 0%;
    }
    .row {
      margin-left: 0px;
    }
    button {
      min-width: 80%;
      min-height: 45px;
      border-radius: 0;
      color: black;
      font-weight: 600;
      margin: auto;
    }
    .btnNewContract,
    .whtSpc01,
    .whtSpc02,
    .btnWarehouseRecipts,
    .btnReports,
    .btnSearchForContracts,
    .btnVoidNoSaleSC95,
    .btnSearchFor,
    .btnStockTransfers,
    .btnPaymentProcessing,
    .btnContractGroupings,
    .btnTradeAgreements,
    .btnTradeSettlements,
    .btnGroupings1007,
    .btnDeliveryInOut,
    .btnExceptionPremiums,
    .btnUserManuals {
      min-width: 20%;
    }
    .btnTradeSettlements {
      // button {
      //   min-width: 91%;
      // }
    }
  }
  .systemMaintenanceSetupSection {
    .lblSystemMaintenanceSetupLable {
      text-align: center;
      font-size: 22px;
      color: #FDC741;
      font-weight: bold;
    }
    .card-body {
      // background-color: #333E48;
      border:none !important;
    }
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
    button {
      min-width: 108%;
      min-height: 45px;
      border-radius: 0;
      color: black;
      font-weight: 600;
    }
    .btnStateCountries,
    .btnFarms,
    .btnScaleTicket,
    .btnPeanutSpecifications,
    .btnInventory.btnCureCleanSchedule,
    .btnBuyingPoints,
    .btnOpenAccounts,
    .btnSpotPrice,
    .btnPremDeducsMaster,
    .btnStorageSchedule,
    .btnInventory,
    .btnVoidWRcontrolNumber,
    .btnContractLimitControl,
    .btnFarmerStockPriceSetup {
      max-width: 17%;
    }
  }
  .btnExit {
    position: relative;
    left: 46%;
    max-width: 20%;
    button {
      min-width: 45%;
      // min-height: 45px;
      border-radius: 0;
      color: black;
      font-weight: 600;
    }
  }
  
  /*END_USER_CODE_FOR_.SCSS*/
}
div#ReportModal{
  max-width: 15.5%;
  position: absolute;
  // left: 35%;
  // top: 26%;
  padding: 0;
  .reportBtnList {
    padding: 0;
    margin-bottom: 0 !important;
    .card{
      // background-color: #333E48;
      background-image: url(../../../../assets/img/BgLogo.png);
      background: transparent !important;
    .card-header{
      display: none !important;
    }
    .card-body{
      .btnFarmSumm,.btnViewReports,.btnProcReport {
        min-width: 200px;
        button{
          min-width: 97%;
          background-color: #bbbbbb;
          background-image: none !important;
        }
      }
    }
  }
  }
}
.modal-body{
  padding: 0rem !important;
}
div#PaymentProcessModal{
  max-width: 15.5%;
  position: absolute;
  // left: 35%;
  // top: 35%;
  padding: 0;
  .paymentProcessBtnList {
    padding: 0;
    margin-bottom: 0 !important;
    .card{
      // background-color: #333E48;
      background-image: url(../../../../assets/img/BgLogo.png);
      background: transparent !important;
    .card-header{
      display: none !important;
    }
    .card-body{
      .btnReadyToPayRelease,.btnPrintChecks,.btnSpoiledCheckEntry,.btnInquireVoidCorrectChecks,
      .btnSpecialPayables {
        min-width: 200px;
        button{
          min-width: 97%;
          background-color: #bbbbbb;
          background-image: none !important;
        }
      }
    }
  }
  }
}
