.StockTransfer-MultiStopSearch,.ContractManagement-MultiStopSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }

  .grpbxDetails {
    .textboxwidget-container,.dropdownfieldwidget-container{
    label{
      width: 150px;
      text-align: right;
    }
    }
    .ddOriginalLocation ,.ddDestinationLocation {
      .dropdown{
        width: 50%;
      }
      .dropdown-menu.show{
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }
    .txtTransferNum,.txtTripNum{
      input{
        width: 25%;
      }
    }
        .ddTransferType {
          .dropdown {
            width: 33%;
          }
          .dropdown-menu.show {
            overflow-y: scroll !important;
            max-height: 15vh !important;
          }
          #ddItem_ {
            height: 25px;
          }
          .dropdown-toggle:empty::after {
            margin-left: auto;
          }
          button {
            height: 30px;
          }
    }
    .btnSearch{
      padding-top: 1rem;
      min-width: 20%;
      max-width: 20%;
      margin-left: 17.5%;
      button{
        width: 50%;
      }
    }
  }

  .grpbxTransferList {
    .gridTransferList{
      // .card-header{
      //   display: block;
      // }
      .expandIconSpc{
        min-width: 35px;
        max-width: 35px;
      }
      .expandable-area {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        background-color: #e7f9ff;
        min-height: 35px !important;
        table-layout: auto;
        width: 100%;
        .rdt_ExpanderRow{
          position: relative;
          top: 8px;}
        }
      // {
      //   min-width: 177px;
      //   max-width: 177px;
      // }
      .extraRowCell_1,.extraRowCell_3,.extraRowCell_5,.extraRowCell_2,.extraRowCell_4{
        min-width: 177px;
        max-width: 177px;
      }
      .extraRowCell_6{
        min-width: 150px;
        max-width: 150px;
        label{
          position: relative;
          left: 10px;
          width: 100%;
          text-align: center;
        }
      }
      .extraRowCell_1,.extraRowCell_2,.extraRowCell_3,.extraRowCell_4,.extraRowCell_5{
        
        label{
          width: 95%;
          text-align: center;
        }
      }
    }
    .card-body{
      padding-left: 0;
      padding-right: 0;
    }
    .btnCreateMultiTransfer,.btnOpen{
      
      min-width: 20%;
      max-width: 20%;
      button{
        width: 90%;
      }
    }
    .btnCreateMultiTransfer{
      margin-left: 60%;
    }
  }

  .cmmndCntnrClose{
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btnClose{
      min-width: 10%;
      max-width: 10%;
      margin-left: 45%;
      button{
        width: 90%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
