.StockTransfer-StockTransferNewNumber,.ContractManagement-StockTransferNewNumber{
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333e48;
  background: transparent;
  h1 {
    display: none;
  }
  .card {
    background-color: #f3f0f0;
    border: none;
  }
  .grpbxStockTransferNewNumber{
  .txtTransferNum{
    margin-left: 32%;
    max-width: 25%;
    min-width: 25%;
    input:focus{
      background-color: yellow;
    }
  }
}
  .cmmndCntnrActions{
    .row{
    background-color: #f3f0f0;
    }
    .btnOk{
      margin-left: 39%;
      max-width: 10%;
      min-width: 10%;
    }
    .btnCancel{
      max-width: 40%;
      min-width: 40%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
