.StockTransfer-ReportPreviewTransfer,.ContractManagement-ReportPreviewTransfer {
    /*START_USER_CODE_FOR_.SCSS*/
    h1{
      display: none;
    }
   // background-color: #333E48;
    .groupboxwidget-container{
      .card{
        border: none;
        background-color:  #f3f0f0;
        .card-header{
          display: none;
        }
      }
    }
    .grpbxPreview {
      min-width: 100%;
    }
    .grpbxActions {
      min-width: 100%;
      .card{
        padding-top: 0.25rem;
        .buttonwidget-container{
          max-width: 10%;
          min-width: 10%;
          align-self: center !important;
          .row{
            justify-content: center;
            button{
              width: 90%;
              height: 40px;
              //PRB0053955 - Stock transfer printing issue fixes
              &.disabled {
                opacity: 0.5;  
                cursor: not-allowed;
                pointer-events: none;  
              }
            }
          }
        }
        .btnPrintSetup{
          margin-left: 20%;
        }
        .chkboxSelectCopies {
          max-width: 15%;
          min-width: 15%;
          margin-left: 1%;
          border: 1px solid #ccc;
          .d-flex{
            display: block !important;
            .form-label{
              margin-bottom: 0 !important;
            }
            .col{
              padding: 0;
            }
          }
        }
      }
    }
    /*END_USER_CODE_FOR_.SCSS*/
  }
  