.AccountPayment-SpecialPayablesScan, .ContractManagement-SpecialPayablesScan {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  // background-color: #333E48;
h1,.card-header{
  display: none;
}
.card{
  background-color:#f3f0f0 ;
}
.lblSpecialPayDocInfo,.lblSavingDocumentOptions,.lblScanningOptions{
  font-size: 15px;
  font-weight: 500;
}
.grpbxDocumentDetails{
  margin-bottom: 0 !important;
  .lblMemo,.lblVendor,.lblSpoolLocation{
    max-width: 28%;
    label{
      width: 100%;
      text-align: right;
    }
  }
  
  .lblMemoValue,.lblVendorValue,.lblSpoolLocationValue{
    min-width: 32%;
    padding-left: 0;
    label{
      // width: 80%;
      // text-align: left;
    display: none;
    }
    div{
      position: relative;
      bottom: 2px;
    }
  }
  .lblSpecialPayDocInfo{
    label{width: 250px;}
  }
 .txtDocument{
  label{
    width: 26.4%;
    text-align: right;
    color: red;
  }
 }
 
}
.grpbxSavingDocumentOptions{
  .row{
    justify-content: center;
  }
  .btnScan,.btnSaveFromExistingFile,.btnCancelSave{
    max-width: 15%;
    button{
      width: 90%;
    }
  }
  
}
.grpbxScanningOptions{
  .row{
    justify-content: center;
  }
  .btnScanUsingDocFeeder,.btnScanUsingFlatbed,.btnCancelScan{
    max-width: 15%;
    button{
      width: 90%;
    }
  }
  .lblScngSts{
    min-width: 100%;
  }
  .lblScngStsVal{
    max-width: 86%;
  }
}
.grpbxVerificationResult{
  .txtarVerificationResult{
    font-size: 15px;
    font-weight: 500;
  }
  .lblVerifictnResult{
    min-width: 100%;
  }
  .lblVerifictnResultVal{
    max-width: 86%;
  }
  .row{
    justify-content: center;
  }
  
  .btnVerifynSave,.btnCancel{
    max-width: 15%;
    button{
      width: 90%;
    }
  }
  .btn1,.btn2,.btn3{
    max-width: 5%;
  }
}
  /*END_USER_CODE_FOR_.SCSS*/
}
