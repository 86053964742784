.ContractManagement-TradeSettlePremDeductView,.Settlements-TradeSettlePremDeductView {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
  }
  .card-header,
  h1 {
    display: none;
  }
  // background-color: #c0bcbc;

  background-color: #333e48;
  .gridPremDed {
    // .card-header {
    //   display: none
    // }
    // overflow-y: overlay;
    // max-height: 300px;
    // min-height: 300px;

    .extraRowCell_2,.extraRowCell_4,.extraRowCell_5{
      padding-top: 6px !important;
      font-size: 0.7rem;
      border-right: 1px solid #eeeede;
      min-height: 34px;
    }
    .rdt_TableRow{
      min-height: 35px !important;
    }
    .rdt_ExpanderRow{
      .py-3{
        padding-bottom: 0rem !important;
        padding-top: 0rem !important;
      }
      .extraRowCell_2{
        min-width: 158px;
        text-align: center !important;
      }
      .extraRowCell_4{
        min-width: 460px;
        justify-content: center !important;
        text-align: center !important;
      }
      .extraRowCell_5{
        min-width: 458px;
       text-align: center !important;
       padding-left: 20px;
      }
    } 
    #column-txtcolPremiumsDeductions{
      max-width: 100px;
    } 
    .rdt_TableCell{
      border-right: 1px solid #eeeede;
      padding-right: 7px;
      padding-left: 27px;
      font-weight: bolder;
      // color: red;  
      background-color: rgb(243, 243, 243);
      // font-size: 500px;
    }
    .ExpandRow{
      border-bottom: 1px solid #eeeede;
    }
    .extraRowCell_2{
      margin-left: 3.75%;
    }
    .rdt_TableCell:nth-child(2),.rdt_TableCol:nth-child(2),.extraRowCell_2{
      min-width: 100px;
      max-width: 155px;
    }
    .rdt_TableCell:nth-child(4),.rdt_TableCol:nth-child(4),.extraRowCell_4{
      min-width: 100px;
    }
    .rdt_TableCell:nth-child(5),.rdt_TableCol:nth-child(5),.extraRowCell_5{
      min-width: 100px;
    }
    .expandable-area{
      .row{
        cursor: pointer;
        color: rgba(0,0,0,0.87);;
      }
      .row:hover{
        color: rgba(0,0,0,0.87);;
        text-decoration: none;
      }
      .activeExpandRow{
        color: rgba(0,0,0,0.87);
        background-color: #e3f2fd;
        border-bottom-color: #FFFFFF;
      }
    }
  }
  .grpbxPremDedView {
    .card {
      background-color: #c0bcbc;
    }
    // .txtPreDed3,
    // .txtPreDed2,
    // .txtpreDed1 {
    //   max-width: 10%;
    // }
  }

  #txtPremDed {
    display: none;
  }
  #txtpreDed1, #txtPreDed2, #txtPreDed3  {
    display: none;
  }
  .btnCncl {
    margin-left: 45%;
    #btnCncl{
      min-width: 40%;
    }
  }

  /*END_USER_CODE_FOR_.SCSS*/
}
