.StockTransfer-FrtAccrlSettlement,.ContractManagement-FrtAccrlSettlement {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #c0bcbc;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxFrtAccrlSettlement {
    .grpbxDetails {
      label{
        width: 130px;
        text-align: right;
      }
      .ddCropYear{
        min-width: 80%;
        max-width: 80%;
        margin-left: 10%;
        .dropdown{
          width: 30%;
        }
      }
      .ddCollectionLocation,.ddShippingLocation,.ddDestinationLocation{
        min-width: 80%;
        max-width: 80%;
        margin-left: 10%;
        .dropdown{
          width: 70%;
        }
        
      }
      .txtCarrierAbbrNum1 {
        display: none !important;
      }
      .txtCarrierAbbrNum{
        min-width: 30%;
        max-width: 30%;
        margin-left: 10%;
        margin-right: 50%;
        padding-right: 0;
        label{
          color: red;
        }
      } 
      .txtVendorNum{
        min-width: 30%;
        max-width: 30%;
        margin-left: 10%;
        padding-right: 0;
        label{
          color: red;
        }
      }
      .btnVendor{
        max-width: 3%;
        margin-bottom: 0.3rem !important;
      }
      .txtVendor{
        padding-left: 0;
        input{
          width: 80%;
          background: transparent;
          color: blue;
          border: none;
        }
        .col{
          position: relative;
          bottom: 34px;
        }
      }
    }
    .grpbxSearchResults {
      padding-top: 1rem;
      padding-left: 0;
      padding-right: 0;
      .txtFAOnHold ,.txtFAToPays {
        max-width: 30%;
        min-width: 30%;
        margin-left: 5%;
        input{
          border: none;
          color: blue;
          background: transparent;
        }
        .col{
          position: relative;
          bottom: 4px;
        }
      }
      .btnSearch{
        max-width: 10%;
        min-width: 10%;
        margin-left: 10%;
        // margin-right: 5%;
        button{
          width: 100%;
        }
      }
      
      .gridHoldFA ,.gridReleaseFA {
        max-width: 47%;
        min-width: 47%;
      }
      .gridHoldFA,.gridReleaseFA{
        .rdt_TableCol:nth-child(6),.rdt_TableCol:nth-child(7),.rdt_TableCol:nth-child(8),
        .rdt_TableCol:nth-child(4),.rdt_TableCol:nth-child(5),.extraRowCell_6,.extraRowCell_5,.extraRowCell_4{
         justify-content: center !important;
         text-align: center !important;
        }
        .rdt_TableCol:nth-child(1),
        .rdt_TableCell:nth-child(1) {
          min-width: 30px;
          max-width: 30px;
        }
        .rdt_TableCol:nth-child(3),
        .rdt_TableCell:nth-child(3) {
          min-width: 30px;
          max-width: 30px;
        }
        .rdt_TableCol:nth-child(4),
        .rdt_TableCell:nth-child(4) {
          min-width: 50px;
          max-width: 50px;
        }
        .rdt_TableCol:nth-child(5),
        .rdt_TableCell:nth-child(5) {
          min-width: 58px;
          max-width: 58px;
        }
        .rdt_TableCol:nth-child(6),
        .rdt_TableCell:nth-child(6),
        .rdt_TableCol:nth-child(7),
        .rdt_TableCell:nth-child(7),.extraRowCell_4 {
          min-width: 70px;
          max-width: 70px;
        }
        .rdt_TableCol:nth-child(8),
        .rdt_TableCell:nth-child(8),.extraRowCell_5 {
          min-width: 88px;
          max-width: 88px;
        }
        .rdt_TableCol:nth-child(9),
        .rdt_TableCell:nth-child(9),.extraRowCell_6 {
          min-width: 78px;
          max-width: 78px;
          border-right: none !important;
        }
        .expandIconSpc{
          min-width: 95px;
          max-width: 95px;
        }
        .expandable-area {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          background-color: #e7f9ff;
          min-height: 35px !important;
          table-layout: auto;
          width: 100%;
          padding-right: 0;
          .rdt_ExpanderRow{
            position: relative;
            top: 8px;}
          }
          .extraRowCell_1{
            min-width: 52px;
            max-width: 52px;
          }
          .extraRowCell_2{
            min-width: 55px;
            max-width: 55px;
          }
          .extraRowCell_3{
            min-width: 72px;
            max-width: 72px;
          }
      }
      .grpbxAddRemove{
        padding-left: 0;
        padding-right: 0;
        max-width: 6%;
        .btnAdd,.btnRemove{
          button{
            width: 100%;
          }
        }
      }
    }
    
    .grpbxInvoiceInfo  {
      // bottom: 200px;
      label{
        width: 90px;
        text-align: right;
      }
      .lblNoteRedPay{
        min-width: 100%;
        bottom: 15px;
        label{
          color: red;
          width: 100%;
          text-align: left;
          font-size: 18px;
          font-weight: 500;
        }
      }
      .ddRemitTo{
        label{
          color: red;
        }
        #ddItem_{
          height: 25px;
        }	  
        .dropdown-toggle:empty::after {
          margin-left: auto;
        }
        button{
          height:30px;
        }
      }
      .ddSpoolLocation{
        .dropdown-toggle:empty::after {
          margin-left: auto;
        }
        button{
        height:30px;
        }
        #ddItem_{
          height: 25px;
        }
        label{
          color: red;
        }
      }
    }
    .grpbxCheckInfo {
      .lblCheckInformation {
        min-width: 100%;
        label{
          width: 80%;
          text-align: center;
          font-weight: bold;
        }
      }
      .txtCheckNumber,.txtCollectSR ,.txtCollectAR ,.txtCollectCR {
        label{
          width: 90px;
          text-align: right;
        }
        .col{
          padding-right: 0;
        }
        input{
          text-align: right;
        }
        padding-right: 0;
        min-width: 50%;
        max-width: 50%;
      }
      .txtValueOfRTPs ,.txtValueOfCheck {
        label{
          width: 90px;
          text-align: right;
        }
        .col{
          padding-right: 0;
        }
        input{
          color: blue;
          text-align: right;
        }
        padding-right: 0;
        min-width: 50%;
        max-width: 50%;
      } 
      .txtEndCheckNumber ,.lblBalance ,.txtSRBalance ,.txtARBalance ,.txtCRBalance {
        min-width: 25%;
        max-width: 25%;
       .col{
        padding-left: 0;
       }
       input{
        color: blue;
        text-align: right;
       }
      }
    }
    
  }
  .grpbxAction {
    .card{
      padding-top: 0.2rem;
      background-color: #f3f0f0;
      
      .lblCropYear {
        max-width: 15%;
        min-width: 15%;
        font-weight: 600;
        font-size: 16px;
        label{
          text-align: left;
          color: red;
        }
        .d-flex{
          max-width: 90%;
          background-color: lightgray;
        }
        .col{
          position: relative;
          right: 8px;
          top: 4px;
          color: red;
        }
      }
      .buttonwidget-container{
        min-width: 15%;
        max-width: 15%;
        button{
          width: 95%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
