.WarehouseReceipts-WarehouseReceiptUpload,.ContractManagement-WarehouseReceiptUpload {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxUploadLockInfo {
    .grpbxuploadlockinfobuttons {
      min-width: 30%;
      max-width: 30%;
      margin-right: 7.33%;
      .card{
        border: 1px solid #ccc;
        height: 120px;
        .lblUploadlockInfo {
          min-width: 100%;
        }
        .buttonwidget-container{
          min-width: 40%;
          max-width: 40%;
          margin-left: 5%;
          button{
            width: 90%;
          }
        }
      }
    }
    .grpbxLoadhistory {
      min-width: 30%;
      max-width: 30%;
      margin-right: 3.33%;
      .card{
        border: 1px solid #ccc;
        height: 120px;
        .lblLoadHistory{
          min-width: 100%;
        }
        .ddLoadHistory{
          padding-right: 0;
          .dropdown-toggle:empty::after {
            margin-left: auto;
          }
          button{
          height:30px;
          }
          #ddItem_{
            height: 25px;
          }
        }
      }
    }
    .radioViewResults{
      position: relative;
      right: 11%;
      .mb-3{
        display: flex;
        position: relative;
        top: 5px;
        .form-check{
          margin-left: 5%;
          input{
            bottom: 2px;
          }
        }
      }
    }
    .lblUploadLockInfo {
      max-width: 35%;
      min-width: 35%;
      top: 30px;
      label{
        width: 100%;
        text-align: center;
        font-weight: bold;
      }
    }
    .gridUploadLockInfo {
      padding-top: 1.5rem;
      .rdt_TableCol:nth-child(11),
      .rdt_TableCell:nth-child(11) {
        justify-content: left !important;
      }
      .rdt_TableCol:nth-child(1),
      .rdt_TableCol:nth-child(2),
      .rdt_TableCol:nth-child(9),
      .rdt_TableCol:nth-child(3),
      .rdt_TableCol:nth-child(10)
      {
      justify-content:center ;
      text-align: center;
      }
      div[id^="cell-txtcolWhseRcptNum"],div[id^="cell-txtcolBuyPtId"],div[id^="cell-txtcolOrgBuyPtId"],div[id^="cell-txtcolLoanRepayRate"],div[id^="cell-txtcolLoanLockInDate"]{
        background-color: #d4d3da;
      }
      div[id^="cell-txtcolAction"]{
        justify-content: left !important;
      }
    }
  }
  .grpbxAction {
    .lbladdedBy ,.lblChangedBy {
      min-width: 10%;
      max-width: 10%;
    }
    .lblAddedbyvalue ,.lblChangedbyvalue {
      min-width: 20%;
      max-width: 20%;
      padding-left: 0;
      .col{
        position: relative;
        bottom: 7px;
        right: 40px;
      }
    }
    .lblChangedBy,.lblChangedbyvalue{
      bottom: 10px;
    }
    .buttonwidget-container{
      min-width: 10%;
      max-width: 10%;
      top: 15px;
      button{
        width: 95%;
      }
    }
    
    .btnClose{
      margin-left: 15%;
      margin-right: 15%;
    }
    .btnExportToExcel {
      margin-right: 5%;
    }
    .btnPrintResult{
      margin-left: 5%;
    }
  }
  .lblUpload{
    // color: red;
    // text-align: center;
    margin-left: 180px;
    font-weight: bold;
    font-size: large;
    label{
      position: relative;
      top: 22px;
    }
  }
  .lblPPS{
    font-weight: bold;
    font-size: large;
    margin-left: 50px;
    label{
      position: relative;
      top: 22px;
    }
  }
  div[id^="cell-txtcolBuyPtId"], div[id^="cell-txtcolOrgBuyPtId"], div[id^="cell-txtcolAction"]{
    justify-content: start;
  }
  div[id^="column-txtcolAction"]{
    justify-content: start;
    min-width: 100%;
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
.dropdown-menu.show {
  overflow-y: scroll !important;
  max-height: 25vh !important;
  }