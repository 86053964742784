.SystemMaintenanceMasterManagement-WarehouseBinProfile,.ContractManagement-WarehouseBinProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333E48;
  background: transparent;
h1{
  display: none;
}
.grpbxWarehouseBinProfile{
  label{
    width: 260px;
    text-align: right;
  }
  .card {
    background-color: #c0bcbc;
  }
  .card-header{
    display: none;
  }
  .lblBuyingPoint{
    max-width: 23%;
    padding-right:0;
  }
  .lblBuyingPointValue{
    label{
     display: none;
    }
   .col{
    color: blue;
    div{
      position:relative;
      bottom:7px;
    }
   }
  }
  .txtWarehouse{
    max-width: 44%;
  }
  .txtBin{
    max-width: 29%;
    label{
      width: 40px;
    }
  }
  .ddPeanutVariety{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
  }
  .txtWarehouseBinDesc,.ddPeanutType,.ddOleicInd,.ddEdibleOilIndicator,.ddSegmentType,.ddSeedGeneration,.ddPeanutVariety,.txtStartupTons ,.txtGovCapacity{
    input,.dropdown{
      max-width: 64%;
    }
  }
.ddSeedGeneration{
  .dropdown-toggle:empty::after {
    margin-left: auto;
  }
  button {
    height: 30px;
    max-width: 110%;
    min-width: 100%;
  }
}
  .dropdownfieldwidget19,.ddOleicIndicator{
    button{
      max-width: 64%;
    }
  }

  .txtWarehouseBinDesc,.ddObligationAsignment,.ddSegmentType,.txtWarehouse,.txtBin{
    color: red;
  }
  .ddObligationAsignment{
    min-width: 73%;
  }
  
  .chkboxAllowWhsReceipts{
    min-width: 25%;
    max-width: 35%;
    left: 22.5%;
    .form-check{
      label{
        width: 300px;
        text-align: left;
      }
    }
  }
  .lblOrganic,.lblTransfersAllowed{
    max-width:23% ;
    padding-right: 0;
  }
  .chkboxOrganic,.chkboxTransfersAllowed{
    padding-left: 0;
    input{
      position:relative;
      left:2%;
    }
  }
}
.grpbxWarehouseBinProfile2{
  .card {
    background-color: #f3f0f0;
  }
  .card-header{
    display: none;
  }
  label{
    width: 70px;
    text-align: left;
  }
  .lblAddedBy{
    max-width: 8%;
  }
  .lblAddedByValue,.txtChangedByValue{
    min-width: 78%;
    padding-left:0;
    label{
      display:none;
    }
    .col{
      padding-left:0;
      div{
        position:relative;
        bottom:7px;
      }
    }
  }
  .btnOk{
    button{
      width: 90%;
     }
  }
  .btnOk,.btnCancel{
    max-width: 7%;
  }
  .txtChangedBy{
    max-width: 8.5%;
  }
}
  /*END_USER_CODE_FOR_.SCSS*/
}
