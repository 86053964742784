.SystemMaintenanceSpecialFunctions-SecurityFunctionInquiry,.ContractManagement-SecurityFunctionInquiry {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSecurityFunctionInquiry{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    margin-bottom: 0.2rem !important;
    .card{
      padding-top: 0.2rem;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none;
      }
      label{
        width: 125px;
        text-align: right;
      }
      .txtFunction,.txtSubFunction {
        min-width: 80%;
        max-width: 80%;
        margin-left: 10%;
        input{
          width: 30%;
        }
      }
      .txtFunctionDiscription {
        min-width: 80%;
        max-width: 80%;
        margin-left: 10%;
        input{
          width: 80%;
        }
      }
      .ddAccessLevel {
        min-width: 80%;
        max-width: 80%;
        margin-left: 10%;
        .dropdown{
          width: 30%;
        }
      }

      .checkboxwidget-container{
        min-width: 80%;
        max-width: 80%;
        margin-left: 10%;
        margin-top: 10px;
        .mb-3{
          padding-left: 125px;
          input{
            margin-top: 0.75rem !important;
          }
          .form-check-label{
            width: 200px;
            text-align: left;
          }
        }
      }
      .ddExtendBuyPtLevel ,.ddExtendCollPtLevel ,.ddExtendAreaLevel {
        min-width: 80%;
        max-width: 80%;
        margin-left: 10%;
        .col{
          padding-left: 140px;
          .dropdown{
            width: 50%;
          }
        }
        .dropdown-toggle:empty::after {
          margin-left: auto;
        }
        button{
        height:30px;
        }
      }
    }
  }
  .grpbxActions  {
      .card{
        .card-header{
          display:none
          }
      border: none;
      background-color: #f3f0f0;
      .lblChangedBy,.lblAddedBy {
        max-width: 10%;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .lblChangedByValue,.lblAddedByValue{
        min-width: 40%;
        margin-right: 30%;
        padding-left: 0;
        label{
          display: none;
        }
        .col{

          position: relative;

          right: 23px;

          bottom: 6.5px;

        }
      }
      .btnAdd,.btnCancel{
        max-width: 10%;
        top: 15px;
        button{
          width: 85%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
