.SystemMaintenanceMasterManagement-PeanutVarietyProfile ,.ContractManagement-PeanutVarietyProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
.grpbxPeanutVariety{
  .card-header{
    display: none;
  }
  .card{
    background-color: #c0bcbc;
  }
  label{
    width: 100px;
    text-align: right;
  }
  .lblPeanutType,.lblArea{
    max-width: 11%;
  }
  .lblPeanutTypeValue,.lblAreaValue{
    min-width: 80%;

    label{
      text-align: left;
      display: none;
    }
    .col{
      color: blue;
      text-align: left;
      padding-left: 0;
      bottom: 7px;
    }
  }
  .txtID{
    input{
      max-width: 10%;
    }
    label{
      color: red;
    }
  }
  .ddOptionalSymbol{
    max-width: 50%;
    min-width: 67.3%;
    .dropdown{
      max-width: 62%;
    }
    input{
      // max-width:62%;
    }
  }
  .ddDefaultOleic{
    max-width: 48.666667%;
  }
  .txtName{
    input{
      max-width: 62%;
    }
    label{
      color: red;
    }
  }
  .chkboxOleicAddendumAllowed{
    .form-check{
      label{
        width: 136px;
      }
    }
  }
}
.grpbxActions{
  .card-header{
    display: none;
  }
  .card{
    background-color: #f3f0f0;
  }
  .lblAddedBy,.lblChangedBy{
    max-width: 8%;
    padding: 0;
    label{
      width: 100%;
      text-align: right;
    }
  }
  .lblAddedByValue,.lblChangedByValue{
    min-width: 77%;
    label{
      display: none;
    }
    .col{
      padding: 0;
      bottom: 7px;
    }
  }
  .btnAddVP,.btnCancel{
     max-width: 7.5%;
     min-width: 7.5%;
     top: 10px;
     button{
       width: 90%;
     }
  }
}
  /*END_USER_CODE_FOR_.SCSS*/
}