.AccountPayment-ReportPreviewCheck {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
    // background-color: #333E48;
    background: transparent;
  }
  .grpbxHeader{
    padding: 0 !important;
  }
  h1 {
    display: none;
  }
  .grpbxPreviewActions {
    padding: 0%;
    // display: none;
    .card-header {
      display: none;
    }
    .btnPrintSetup,
    .btnSaveasPDF {
      display: none;
      max-width: 8%;
    }
    .btnPrint{
      margin-left: 66%;
    }
    .btnClose {
      margin-left:-5%;
    }
    .btnPrintSetup {
      display:none
    }
    .btnClose {
      min-width: 15%;
    }
    .btn1{
      display:none
    } 
    .btn2{
      display:none
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
