.WarehouseReceipts-1007GrpSearch,.ContractManagement-1007GrpSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbx1007GrpSearch{
    .card{
      background-color: #f3f0f0;
    }
    .card-header{
      display: none;
    }
    label{
      width: 80px;
      text-align: right;
    }
    .dropdown-menu.show{
      overflow-y:scroll!important;
      max-height:25vh!important;
      }
      
    .grpbxGrpSearch1{
      padding-right: 0;
      min-width: 37%;
      .card{
        border: none;
        
      }
      .txtGrouping,.txtSC95,.txt1007{
        input{
          max-width: 38%;
        }
      }
      .txtVendor{
        min-width: 54%;
      padding-right: 0;
      }
      .btnVendor{
        max-width: 9%;
      }
      .txtVndr{
        min-width: 57%;
      padding-left: 0;
      input{
        padding-left: 0;
        background: transparent;
        border: none;
        box-shadow: none;
        color: blue;
      }
      .col{
        position: relative;
        left: 40%;
      }
      }
    }
    .grpbxGrpSearch2{
      padding: 0;
      max-width: 31%;
      .card{
        border: none;
      }
      .dropdown{
        max-width: 80%;
      }
      .txtFarm{
        padding-right: 0;
        min-width: 55%;
      }
      .txtFarmSuffix{
        padding-left: 0.5%;
        max-width: 45%;
      }
      .btnSearch{
        display: flex;
        justify-content: end;
        position: relative;
        top: 40px;
      }
    }
    .gridGrpSearch{
      .card-header{
        display: block;
      }
      .popover-body{
        label{
          text-align: left;
          width: 100%;
        }
      }
      .rdt_TableCol:nth-child(1),.rdt_TableCol:nth-child(2),.rdt_TableCol:nth-child(10),
      .rdt_TableCol:nth-child(3),.rdt_TableCol:nth-child(11),
      .rdt_TableCol:nth-child(4),.rdt_TableCol:nth-child(12),
      .rdt_TableCol:nth-child(5),.rdt_TableCol:nth-child(6),
      .rdt_TableCol:nth-child(7),.rdt_TableCol:nth-child(8),
      .rdt_TableCol:nth-child(9)
      {
        position: relative;
        top: 15px;
      }
      .rdt_TableCell:nth-child(3),.rdt_TableCol:nth-child(3){
        min-width: 250px;
        max-width: 250px;
      }
      .rdt_TableCell:nth-child(4),.rdt_TableCol:nth-child(4){
        min-width: 250px;
        max-width: 250px;
      }
      .rdt_TableCell:nth-child(6),.rdt_TableCol:nth-child(6){
        min-width: 180px;
        max-width: 180px;
      }
      .rdt_TableCell:nth-child(11),.rdt_TableCol:nth-child(11){
        min-width: 180px;
        max-width: 180px;
      }
      .rdt_TableCell:nth-child(2),
      .rdt_TableCell:nth-child(3){
        justify-content: left !important;
      }
    }
    .grpbxgGrpSearch3{
      padding-left: 0;
      max-width: 32%;
      .card{
        border: none;
      }
    }
    .gpxbxGrpSearch4{
      .card{
        border: none;
      }
      .row{
        justify-content: end;
      }
      .btnOpen {
        max-width: 6%;
      }
      .btnCreate1007Grouping{
        max-width: 13%;
      }
    }
  }
  .cmmndCntnrActions{
    .row{
      background-color: #f3f0f0;
    }
    .btnExportToExcel{
      max-width: 9%;
      margin-left: 77%;
    }
    .btnPrintSearch{
      max-width: 8%;
    }
    .btnCancel{
      max-width: 6%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
