.ReportTool-ProcurementReporting,.ContractManagement-ProcurementReporting {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxHeader {
    padding-left: 0;
  }
  .grpbx1,.grpbx2,.grpbx3{  
      .card-header{
        display:none
      }
      .card{
        border: none;
        background-color: #f3f0f0;
      }
    }
    .grpbx11 {
      min-width: 40%;
      padding-right: 0;
      label{
        width: 30%;
        text-align: right;
      }
    }
    .grpbx12 {
      min-width: 45%;
      .btnBase {
        max-width: 11%;
      }
      .btnCustom ,.btnBase{
        margin-bottom: 0 !important;
      }
      
      .lstBase,.lstCustom{
        min-width: 100%;
        padding-left: 0;
        .col{
          padding-left: 0;
        }
      }
      .txtReportDescription,.txtReportMode  {
        min-width: 100%;
        padding-left: 0;
        label{
          font-weight: 700;
        }
        input{
          background: transparent;
          border: none;
          box-shadow: none;
        }
      }
    }
    .grpbx13{
      max-width: 15%;
      button{
        width: 75%;
      }
    }
    .grpbx21 {
      min-width: 60%;
      .ddField,.ddCondition  {
        padding-left: 0;
        .d-flex{
          display: grid !important;
        }
        .col{
          padding-left: 0;
        }
      }
      .btnAdd,.btnRemove,.btnClearAll {
        button{
          width: 80%;
        }
      }
      .lstAdd {
        padding-left: 0;
        .col{
          padding-left: 0;
        }
      }
    }
    .grpbx22{
      max-width: 15%;
      padding-right: 0;
      padding-left: 0;
      .lblValues {
        font-weight: 700;
      }
      .grpbx25{
        padding-right: 0%;
        padding-left: 0;
        .card-body{
          padding: 0;
        }
      }
    }
    .grpbx23{
      max-width: 15%;
      padding: 0;
      .card-body{
        padding: 0;
        padding-left: 10%;
      }
      .chkbxgrpSortBy,.ddGroupBy  {
        .d-flex{
          display: grid !important;
        }
        .col{
          padding-left: 0;
        }
      }
    }
    .grpbx24{
      max-width: 5%;
      padding-right: 0;
      .card-body{
        padding-top: 20px;
      }
    }
    .grpbx31{
      max-width: 40%;
      .lblReportColumns{
        padding-left: 0;
        max-width: 23%;
      }
      .btnCollapseHeader{
        max-width: 28%;
      }
      .lstReport{
        padding-left: 0;
        .col{
          padding-left: 0;
        }
      }
    }
    .grpbx32{
      max-width: 5%;
      padding-left: 0;
      .card-body{
        padding-top: 30px;
      }
    }
    .grpbx33{
      min-width: 40%;
      .lstSelected{
        padding-left: 0;
      }
    }
    .grpbx34{
      max-width: 15%;
      text-align: center;
      .lblColumnOrder {
        label{
          width: 100%;
        }
      }
      .btnOrderUp1 ,.btnOrderDown1,.btnFormulaEditor  {
        display: flex;
        justify-content: center;
      }
      .btnOrderDown1{
        border-bottom: 1px solid;
        padding-bottom: 3px;
      }
      .grpbx35 {
        padding: 0;
        .card-body{
          padding: 0;
        }
      }
    }
    .cmmndcntnrActions {
      .row{
        justify-content: center;
        background-color: #f3f0f0;
        width: 100%;
      }
    }
  /*END_USER_CODE_FOR_.SCSS*/
  }
