.SystemMaintenanceApplicationSupport-SpecFuncReprint,.ContractManagement-SpecFuncReprint {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSpecFuncReprint{
    
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none
      }
      .card-body{
        width: 70%;
        margin-left: 15%;
      }
      .radioReprintDocument{
        .d-flex{
          display: block !important;
          .form-label{
            font-weight: bold;
          }
          .col{
            padding-left: 5%;
            padding-right: 5%;
            .mb-3{
              display: flex;
              .form-check{
                width: 40%;
                input{
                  bottom: 2px;
                }
              }
            }
          }
        }
      }
      .grpbxSettlement{
        .card{
          border: 1px solid #f3f0f0;
          .card-body{
            width: 100%;
            margin: 0;
            .lblReprintSettlement {
              min-width: 100%;
              label{
                font-weight: bold;
              }
            }
            .txtInspection {
              min-width: 100%;
              label{
                width: 70px;
                text-align: right;
              }
              input{
                width: 30%;
              }
            }
          }
        }
      }

      .grpbxWarehouseReceipt{
        .card{
          border: 1px solid #f3f0f0;
          .card-body{
            width: 100%;
            margin: 0;
            .lblReprintWarehouseReceipt {
              min-width: 100%;
              label{
                font-weight: bold;
              }
            }
            .txtBuyingPoint {
              min-width: 100%;
              input{
                width: 20%;
              }
              label{
                width: 70px;
                text-align: right;
              }
            }
            .txtWarehouseReceiptNum {
              min-width: 100%;
              label{
                width: 70px;
                text-align: right;
              }
              input{
                width: 30%;
              }
            }
          }
        }
      }
    }
  }
  .cmmndCntnrActions{
    .row{
      padding-top: 0.25rem;
      background-color: #f3f0f0;
      .btnReprint{
        min-width: 10%;
        max-width: 10%;
        margin-left: 40%;
        .row{
          justify-content: center;
          button{
            width: 90%;
          }
        }
      }
      .btnCancel{
        min-width: 10%;
        max-width: 10%;
        .row{
          justify-content: center;
          button{
            width: 90%;
          }
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
