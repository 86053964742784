.SystemMaintenanceSpecialFunctions-SpotControlProfile,.ContractManagement-SpotControlProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSpotControlProfile   {
    margin-bottom: 0.2rem !important;
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #c0bcbc;
      max-height: 445px;
      
    }
    label{
      width: 200px;
      text-align: right;
    }
    input{
      width: 40%;
    }
    .textboxwidget-container {
      min-width: 100%;
    }
    .txtBuyingPoint,.txtPeanutType {
      .col{
        input{
          width: 60%;
          padding-left: 0;
          color: blue;
          background-color: #c0bcbc;
          border: none;
          box-shadow: none;
        }
      }
    }
    .txtEffectiveDate,.txtSeg1Spot{
      label{
        color: #c02020;
      }
    }
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 8%;
      padding: 0;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 77%;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 7px;
      }
    }
    .btnOk,.btnCancel{
       max-width: 7.5%;
       min-width: 7.5%;
       top: 10px;
       button{
         width: 90%;
       }
    }
  }
  
  /*END_USER_CODE_FOR_.SCSS*/
}
