.SystemMaintenanceMasterManagement-LeasedTrailerProfile,.ContractManagement-LeasedTrailerProfile {
 /*START_USER_CODE_FOR_.SCSS*/
 h1{
  display:none
}
// background-color: #333E48;
background: transparent;

.grpbxLeaseTrailerProfile{
  
  .card{
    padding-top: 0.5%;
    border: none;
    background-color: #c0bcbc;
    .card-header{
      display:none
    }
    label{
      width: 120px;
      text-align: right;
    }
    .dropdown{
      width: 50%;
    }
    input{
      width: 50%;
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .txtPercentage{
      max-width: 20%;
      padding-right: 0;
      input{
        width: 100%;
      }
    }

    .lblPercent{
      padding-left: 0;
      label{
      text-align: left;
      }
    }
  }
  .grpbxLeasedTrailerList{
    min-width: 100%;
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #c0bcbc;
     }
    .btnAdd,.btnDelete{
      max-width: 7.5%;
      button{
        width: 95%;
      }
    }
    .btnAdd{
      margin-left: auto;
    }
    .btnDelete{
      margin-right:0.5%;
    }
    .lblLeasedTrailerList{
      margin-bottom: 0;
    }
    .gridLeasedTrailerListData {
      .card{
        padding-top: 0;
      }
      .card-header{
        display:block;
      }
      .rdt_TableCell:nth-child(1),.rdt_TableCol:nth-child(1){
        justify-content: left !important;
      }
      .rdt_TableCell:nth-child(2),.rdt_TableCol:nth-child(2){
                justify-content: left !important;

      }
      .rdt_TableCell:nth-child(3),.rdt_TableCol:nth-child(3){
                justify-content: left !important;

      }
      .rdt_TableCell:nth-child(4),.rdt_TableCol:nth-child(4){
                justify-content: left !important;

      }
      .rdt_TableCell:nth-child(5),.rdt_TableCol:nth-child(5){
                justify-content: left !important;

      }
     
      .popover-body{
        input{
          width: auto;
        }
        label{
          text-align: left;
        }
      }
    }

    .btnClose{
      max-width: 10%;
      margin-left: 45%;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      button{
        width: 95%;
        height: 40px;
      }
    }
  }
  
}
.cmmndCntnrClose {
  .row{
    background-color: #f3f0f0;
    padding-top: 0.25rem;
    .btnClose{
      min-width: 10%;
      max-width: 10%;
      margin-left: 45%;
      button{
        width: 100%;
      }
    }
  }
}



/*END_USER_CODE_FOR_.SCSS*/
}
