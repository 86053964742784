.SystemMaintenanceMasterManagement-FuelSurchargeInquiry,.ContractManagement-FuelSurchargeInquiry {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;

  .grpbxFuelSurchargeInquiry{
    .card-header{
      display:none
    }
    // margin-top: 1rem;
    .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #c0bcbc;
    }
    label{
      width: 200px;
      text-align: right;
    }
    .txtCarrierAbbriviation ,.txtEffectiveDate {
      min-width: 100%;
      .col{
        max-width: 20%;
      }
    }

    .btnSearch{
      max-width: 10%;
      margin-left: 45%;
      button{
        width: 95%;
      }
    }
    .gridFarmList {
      .card-header{
        display:block;
      }
      
      .rdt_TableCol:nth-child(4),
      .rdt_TableCol:nth-child(6),
      .rdt_TableCol:nth-child(7),
      .rdt_TableCol:nth-child(8),
      .rdt_TableCol:nth-child(9),
      .rdt_TableCell:nth-child(4),
      .rdt_TableCell:nth-child(6),
      .rdt_TableCell:nth-child(7),
      .rdt_TableCell:nth-child(8),
      .rdt_TableCell:nth-child(9){
        min-width: 140px;
        max-width: 140px;
      }

      .rdt_TableCol:nth-child(2),
      .rdt_TableCell:nth-child(2){
        min-width: 200px;
        max-width: 200px;
      }
      .rdt_TableCol:nth-child(3),
      .rdt_TableCell:nth-child(3){
        min-width: 140px;
        max-width: 140px;
      }
      .rdt_TableCol:nth-child(10),
      .rdt_TableCol:nth-child(11),
      .rdt_TableCol:nth-child(12),
      .rdt_TableCol:nth-child(13),
      .rdt_TableCell:nth-child(10),
      .rdt_TableCell:nth-child(11),
      .rdt_TableCell:nth-child(12),
      .rdt_TableCell:nth-child(13){
        min-width: 170px;
        max-width: 170px;
      }
      .popover-body{
        label{
          text-align: left;
          width: 100%;
        }
      }
    }
  }
  .cmmndCntnrActions{
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
      .btnPrint{
        max-width: 10%;
        margin-left: 10%;
        button{
          width: 95%;
        }
      }
  
      .btnExportToExcel{
        max-width: 10%;
        // margin-left: 10%;
        button{
          width: 95%;
        }
      }
      .btn1{
        // max-width: 2.5%;
        // margin-left: 10%;
        // button{
        //   width: 95%;
        // }
        display: none;
      }
      .btnClose{
        max-width: 10%;
        margin-left: 45%;
        button{
          width: 95%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}

