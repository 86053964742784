.ContractManagement-VendorDisclosure {
  /*START_USER_CODE_FOR_.SCSS*/
  h1,.card-header{
    display: none;
  }
  background-color: #c0bcbc;
  .card{
    background-color: #c0bcbc;
  }
  .lblVeteran,.lblMinority,.lblWomen{
    font-size: 18px;
    font-weight: 700;
  }
  .lblOMCWomen,.lblOMCMinority,.lblOMCVeteran{
    font-weight: 500;
  }
  .radioWomen{
    .form-check{
      max-width: 20%;
    }
  }
  .chkboxMinority {
    .form-check-inline {
      display: inline-flex;
      min-width: 40%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
