.ContractManagement-ContractProfile {

  /*START_USER_CODE_FOR_.SCSS*/
  .grpbxStsDates {
    flex: 0 0 30%;
  }

  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  form.row {
    margin-left: -15px !important;
    // max-height: 1500px;
  }

  .grpbxContrctFarm {
    flex: 0 0 70%;
    padding-right: 10px;

    .card {
      background-color: #333E48;
      border: none;
      max-height: 441px;
    }

    .card-body {
      padding-left: 1.7%;
    }

    .card-header {
      display: none;
    }
  }

  .ddPeaNtVrty {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
  }


  h1 {
    display: none !important;
  }

  .card {
    background-color: #f3f0f0;

    .card-body {
      padding-top: 0;
    }
  }

  .lblFrmPlntIntn,
  .contrctControl,
  .grpbxHdingVndr,
  .grpbx,
  .txtarCmnnts {
    font-size: 20px;
    min-width: 32%;
    font-weight: bold;
  }
  .grpbxContrctControl {
    padding-top: 8px;
    padding-right: 0;
    min-width: 100%;
    .align-items-end {
      align-items: flex-start !important;
    }
    .align-self-end {
      align-self: flex-start !important;
    }
    .card{
      min-height: 173px;
      background-color: #f3f0f0;
    }
    .card-header {
      display: none;
    }

    .btnSetpExcPrm,
    .btnViewUpldCntrct,
    .btnEnblDate {
      button {
        max-height: 44px;
      }
    }

    .grpbxContrctCntrl01 {
      // min-width: 58%;
      flex: 0 0 50%;

      .card {
        border: none !important;
      }

      .card-header {
        display: none;
      }

      .card-body {
        padding-right: 8%;

        .row {

          .date,
          .txtContrctNo,
          .ddType,
          .ddLocation {
            text-align: right;

            label {
              min-width: 80px;
            }

          }

          .date {
            min-width: 55%;
            padding: 0px;
            align-items: end !important;

            .col {
              padding-right: 4%;
            }

            #dateParent {
              min-width: 100%;
            }

            .input-group-text {
              display: none;
            }
          }

          .mmddyy {
            padding: 0px;
            //right: 10px;
            max-width: 20%;
            align-items: end !important;
          }

          .btnEnblDate {
            padding: 0px;
            max-width: 24%;
            left: 4%;
          }

          .txtContrctNo {
            padding: 0;
            min-width: 53%;

            .col {
              padding-right: 0;
            }

            input {
              min-width: 100%;
            }
          }

          .txtCntrctno2 {
            padding: 0;
            max-width: 23%;
          }

          .ddType {
            padding: 0;
            max-width: 57.5%;
          }

          .ddLocation {
            padding: 0;

            button#ddLocation {
              width: 114%;
            }

            .dropdown-menu.show {
              overflow-y: scroll !important;
              max-height: 25vh !important;
            }
          }
        }
      }
    }

    .grpbxCntrctCntrl02 {
      // max-width: 42%;
      flex: 0 0 50%;

      .card {
        border: none !important;
      }

      .card-header {
        display: none;
      }

      .card-body {
        .btnSetpExcPrm {
          //padding: 0;
          left: 10px;
          padding-left: 5%;
        }

        .btnViewUpldCntrct {
          padding-left: 31px;
          left: 10px;
        }

        .txtarExcPrm.d-flex {
          padding: 0;
          display: grid !important;

          .d-flex {
            display: grid !important;
          }

          textarea {
            width: 140%;
            position: relative;
            right: 10%;
            min-height: 75px;

          }
        }

        .chckbxAlwRescn {
          padding-right: 0;

          .form-label {
            display: none;
          }

          .form-check {
            min-width: 105%;
          }
        }
      }
    }
  }

  .grpbxFarmPlntngIntn {
    min-width: 100%;
    padding-right: 0;
    .card {
      background-color: #f3f0f0;
      height: 206px;
    }
    .lblAcres {
      max-width: 10%;
    }

    .lblAvgYld {
      max-width: 16%;
    }

    .lblFrmPlntIntn {
      min-width: 73%;
    }

    .whtSpc03 {
      display: none;
    }

    .card-header {
      display: none;
    }

    .ddSegType,
    .ddPeanutType,
    .ddPeaNtVrty,
    .ddOleic {
      text-align: right;

      label {
        width: 105px;
      }
    }

    .ddSegType {
      min-width: 34%;

    }

    .ddPeanutType {
      min-width: 34%;

      .col {
        max-width: 62%;
      }
    }

    .ddPeaNtVrty {
      min-width: 34%;
      .col {
        max-width: 62%;
      }
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }

    .ddOleic {
      min-width: 34%;
    }

    .whtSpc01,
    .whtSpc02 {
      max-width: 16%;
    }
    
    .txtCntrPivtAvgYld,
    .txtOthrAvgYld,
    .txtDryLndAvgYld {
      text-align: right;
      padding-right: 0;
      min-width: 36%;

      label {
        width: 145px;
      }

      input {
        text-align: right;
      }
    }

    .txtDryLndAvgYld {
      min-width: 35%;

      label {
        width: 138px;
      }
    }

    .txtCntrPivtAcrs,
    .txtTotlAcrs,
    .txtOthrAcrs,
    .txtDryLndAcres {
      max-width: 14%;
      padding-right: 0;
      padding-left: 0;

      input {
        text-align: right;
      }
    }

    .chkBxSeedGrowr,
    .chkSeedGrower2010 {
      max-width: 17%;
      padding: 0;

      .form-label {
        display: none;
      }
    }

    .chckbxOrgnc {
      max-width: 16%;
      padding: 0;

      .form-label {
        display: none;
      }
    }

    .txtAvgYld {
      min-width: 35%;
      padding-right: 0;
      border-top: 1px solid black;
      padding-top: 3px;

      input {
        text-align: right;
      }

      label {
        width: 136px;
      }
    }

    .txtTotlAcrs {
      max-width: 14%;
      padding-right: 0;
      border-top: 1px solid black;
    }
  }

  .grpBxVendr {
    margin-bottom: 0 !important;
    // position: relative;
    // bottom: 198px;
    min-width: 100%;
    text-align: center;

    .card-header {
      display: none;
    }

    label {
      width: 55px;
      text-align: right;
    }

    .grpbxHdingVndr {
      label {
        width: 85px;
      }
    }

    .lblVndr {
      min-width: 20%;
      max-width: 32%;
    }

    .lblSignAdvnc,
    .lblCllcAdv {
      label {
        width: 75px;
      }
    }

    .lblRemit {
      min-width: 32%;
    }

    .txtVndr {
      max-width: 15%;
      padding-right: 0;
    }

    .btnPopUp {
      max-width: 3%;
      min-width: 7%;
    }

    .ddRemit {
      min-width: 41%;
    }

    .txtName {
      min-width: 82%;

      input {
        max-width: 74%;
        color: blue;
      }
    }

    .txtEmail {
      input {
        max-width: 72.6%;
      }
    }

    .btnClrAdvnc {
      max-width: 10%;
    }

    .txt211Gpc {
      max-width: 20%;

      input {
        color: blue;
      }
    }

    .txt211concordia {
      min-width: 24%;

      label {
        width: 90px;
      }

      input {
        color: blue;
      }
    }

    .txtMinDisclsr {
      min-width: 10%;
      padding-right: 0;

      label {
        width: 105px;
      }

      .col {
        display: none;
      }
    }

    .lnkMinDisclsr {
      padding-left: 0.2%;
      max-width: 15%;
    }

    .txtShare {
      min-width: 20%;
    }

    .btnSeedGrwrAgreemnt {
      margin-left: 56%;
      max-width: 15%;

      button {
        width: 80%;
      }
    }

    .btnChemiclAgreemnt {
      max-width: 13%;

      button {
        width: 80%;
      }
    }

    .btnFmAgreement {
      max-width: 11%;

      button {
        width: 72%;
      }
    }

    .lblVndr,
    .lblRemit,
    .lblSignAdvnc,
    .lblCllcAdv {
      border-bottom: 2px solid;
    }
  }

  // .grpBxVendr {
  //   position: relative;
  //   bottom: 198px;
  //   min-width: 100%;
  //   text-align: center;

  //   .card-header {
  //     display: none;
  //   }
  //   .lblVndr,
  //   .lblRemit,
  //   .lblSignAdvnc,
  //   .lblCllcAdv {
  //     border-bottom: 2px solid;
  //   }
  //   .lblVndr {
  //     max-width: 23%;
  //   }
  //   .lblRemit {
  //     min-width: 40%;
  //     label {
  //       position: relative;
  //       left: 45%;
  //     }
  //   }
  //   .lblSignAdvnc {
  //     label {
  //       position: relative;
  //       left: 21%;
  //     }
  //   }
  //   .ddVndr {
  //     min-width: 25%;
  //     right: 16px;
  //   }
  //   .btnPopUp {
  //     right: 34px;
  //     max-width: 7%;
  //     margin-bottom: 4px !important;
  //   }
  //   .ddRemit {
  //     min-width: 44%;
  //     position: relative;
  //     right: 55px;
  //   }
  //   .txtSignAdv {
  //     max-width: 14%;
  //     right: 83px;
  //     padding-right: 0;
  //     position: relative;
  //     input{
  //       text-align: right;
  //     }
  //   }
  //   .txtCllcAdv {
  //     padding-left: 0;
  //     max-width: 10%;
  //     min-width: 10%;
  //     right: 8%;
  //     position: relative;
  //     input {
  //       width: 195%;
  //       text-align: right;
  //     }
  //   }
  //   .txtName,.txtEmail {
  //     min-width: 86%;
  //     text-align: right;
  //     label{
  //       width: 65px;
  //     }
  //     input{
  //       width: 70%;
  //     }
  //   }
  //   .btnClrAdvnc{
  //     max-width: 10%;
  //   }
  //   .txt211Gpc {
  //     max-width: 15%;
  //     padding-right: 0;
  //     label{
  //       width: 65px;
  //     }
  //   }
  //   .txt211concordia {
  //     min-width: 15%;
  //     padding-right: 0;
  //   }
  //   .txtMinDisclsr {
  //     max-width: 13%;
  //     padding: 0;
  //     label{
  //       width:100%;
  //     }
  //     input{
  //       display: none;
  //     }
  //   }
  //   .lnkMinDisclsr{
  //     max-width: 10%;
  //     padding: 0;
  //     // bottom: 4px;
  //   }
  //   .lnkMinority {
  //     max-width: 12%;
  //     padding-right: 0;
  //   }
  //   .txtShare{
  //     padding-right: 0;
  //     max-width: 14%;
  //     min-width: 20%;
  //   }
  //   .btnSeedGrwrAgreemnt{
  //     max-width: 12%;
  //     margin-left: 63%;
  //     button{
  //       width: 80%;
  //     }
  //   }
  //   .btnChemiclAgreemnt{
  //     max-width: 11%;
  //     button{
  //       width: 80%;
  //     }
  //   }
  //   .btnFmAgreement{
  //     max-width: 10%;
  //     button{
  //       width: 80%;
  //     }
  //   }
  // }
  .grpBxDlvryPrdDesc {

    // position: relative;
    // bottom: 196px;
    // max-width: 67%;
    .d-flex {
      display: grid !important;
    }

    .card-header {
      display: none;
    }

    .ddDlvryPrdDesc {
      min-width: 100%;
    }
  }

  .grpBxContrctDetls {
    margin-bottom: 0 !important;
    .lblCntrctDetls {
      font-size: 20px;
      font-weight: bold;
    }

    .grpbxFlex {
      .card {
        border: none
      }
    }

    .d-flex {
      display: grid !important;
    }

    // position: relative;
    // bottom: 196px;

    .card-header {
      display: none;
    }

    .dtStrt2,
    .dtEnd2,
    .dtStart,
    .dtEnd {
      .date {
        width: 115%
      }
    }

    .ddPrice {
      min-width: 16.5%;
      max-width: 17%;
    }

    .chkbxHrvst2,
    .dtStrt2,
    .dtEnd2,
    .txtPounds2,
    .txtApp2,
    .ddPrice,
    .txtTntApp2,
    .txtTotlApp2,
    .txtTntOpen2,
    .lblFrm,
    .chckbxHrvst,
    .dtStart,
    .dtEnd,
    .txtPounds,
    .ddPrc,
    .txtApplied,
    .txtTntAppld,
    .txtTotlApp,
    .txtTntOpen,
    .lblBasis {
      padding: 0;
      text-align: center;

      input {
        text-align: right;
      }
    }

    .chkbxHrvst2,
    .chckbxHrvst {
      max-width: 3%;

      .form-label {
        display: none;
      }
    }

    .chckbxHrvst,
    .chkbxHrvst2 {
      label {
        position: relative;
        bottom: 20px;
        right: 70%;
      }
    }

    .lblFrm,
    .lblBasis {
      max-width: 5%;
    }

    .btnLookupFrm {
      max-width: 3%;
      margin-bottom: 4px !important;
    }

    .dtStrt2,
    .txtPounds2,
    .dtEnd2,
    .dtStart,
    .dtEnd,
    .txtPounds,
    .ddPrc {
      min-width: 12%;
    }

    .chkbxFreezeDmgRng {
      .form-label {
        display: none;
      }

      min-width: 20%;
    }

    .lblMin,
    .lblMax {
      min-width: 18%;
    }

    .txtApp2,
    .txtTntApp2,
    .txtTotlApp2,
    .txtTntOpen2,
    .txtApplied,
    .txtTntAppld,
    .txtTotlApp,
    .txtTntOpen {
      min-width: 8%;
    }

    .btnVndrLookupFrm2 {
      padding-right: 0;
      max-width: 5%;
      margin-bottom: 4px !important;
    }

    .txtDel {
      top: 25px;
      max-width: 35%;
    }

    .dtMtchEndDate {
      min-width: 45%;
      max-width: 46%;

      .input-group-append {
        display: none;
      }

      label {
        position: relative;
        left: 4%;
      }

      #dtMtchEndDateParent {
        max-width: 23%;
      }
    }

    .txtbxMin,
    .txtbxMax {
      min-width: 11%;

      label {
        left: 31%;
        max-width: 45%;
        position: relative;
      }
    }

    .lblFlexMarketPricing {
      max-width: 11%;
    }

    .lblMktPrcng {
      min-width: 76%
    }
  }

  .grpbxLienHldr {

    // position: relative;
    // bottom: 196px;
    .d-flex {
      display: grid !important;
    }

    .card-header {
      display: none;
    }

    .txtLnHldrNm,
    .txtAddr,
    .txtCity,
    .txtState,
    .txtZip,
    .txtNtrOfLiens,
    .lienHldr2,
    .txtAdd2,
    .txtCity2,
    .ddStat2,
    .txtZip2,
    .txtNature2 {
      padding: 0;
      text-align: center;
    }

    .txtState,
    .ddStat2 {
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }

    .txtLnHldrNm,
    .lienHldr2 {
      min-width: 21%;
    }

    .txtAddr,
    .txtAdd2 {
      max-width: 26%;
    }

    .txtNtrOfLiens,
    .txtNature2 {
      min-width: 22%;
    }

    .txtZip,
    .txtZip2 {
      min-width: 10%;
    }
  }

  .grpbxCmnts {
    // position: relative;
    // bottom: 196px;

    .card-header {
      display: none;
    }

    .d-flex {
      display: grid !important;
    }
  }

  .grpbxStsDates {
    padding-left: 0;
    text-align: right;

    // min-height: 400px;
    .card {
      // min-height:414px;
      padding-bottom: 4%;

      .card-header {
        display: none;
      }

      .card .lblStsDates {
        text-align: left !important;
      }

      label {
        min-width: 170px;
      }

      .chckbxMkFrmSpcfc {
        label {
          min-width: 100px;
        }
      }

      .grpbxGrpingNo {
        max-height: 35px;
        min-height: 0;

        .card {
          border: none;
          min-height: 0;

          .card-body {
            padding: 0rem;
          }
        }
      }

      .lblStsDates {
        font-size: 20px;
        font-weight: bold;
      }

      .txtGrpng {
        //right: 19px;
        padding-right: 0;
        min-width: 85%;

        .col {
          min-width: 50%;
        }
      }

      .btnLookup {
        max-width: 12%;
        left: 4%;
        margin-bottom: 4px !important;
      }

      .dtHrvstCmplt {
        .form-label {
          max-width: 48%;
        }
      }

      .chckbxMkFrmSpcfc {
        display: none !important;
        // margin-bottom: 45px !important;
        // .form-label {
        //   display: none;
        // }
      }
    }
  }

  @media(min-width:1281px) {
    .btnCntnr {
      position: sticky;

      bottom: 0;
      max-width: 100%;
      min-width: 97.5%;
    }
  }

  @media(max-width:1280px) {
    .btnCntnr {
      position: sticky !important;

      bottom: 0;

    }
  }

  .btnCntnr {
    // position: fixed-bottom;
    // // top: 85vh;
    // bottom: 170px;


    .card-header {
      display: none;
    }

    .card {
      min-width: 60%;
      max-width: 100%;
    }

    .lblAddedBy {
      max-width: 14%;
      min-width: 16%;

      .col {
        display: inline;
        min-width: 48%;

        div {
          // min-width: 50%;
          display: inline-flex;
        }
      }
    }

    .lblChngdBy {
      min-width: 48%;
    }

    .chckbxPrintCmmnt {
      max-width: 20%;

      .form-check {
        label {
          display: none;
        }
      }

    }

    .btnEmail {
      max-width: 6%;
      margin-left: 6%;
    }

    .btnRescan,
    .btnCreate {
      max-width: 7%;
    }

    .btnSign,
    .btnVoid,
    .btnPrint,
    .btnExit {
      max-width: 6%;
    }

    .btnDeactivate {
      min-width: 9%;
    }

    .lblCropYear2 {
      left: 10%;
      max-width: 20%;
      font-size: 14px;
      font-weight: 500;
      color: red;
    }

    // position: fixed;
    // top: 500px;
  }

  .txtStatus,
  .txtGrpingShare,
  .txtEntryDt,
  .txtDtSign,
  .txtDtApprv,
  .txtDtClose,
  .txtDtVoided,
  .txtDtCncl,
  .dtHrvstCmplt {
    padding-right: 9%;
  }

  .ddLocation,
  .ddType {
    .d-flex {
      margin-top: 1%;
    }
  }

  /*END_USER_CODE_FOR_.SCSS*/
}
#ContractManagement_ContractProfilePopUp {
  .close{
    display: none;
  }
}
