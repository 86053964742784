.SystemMaintenanceApplicationSupport-OpenAdjustmentEntry,.ContractManagement-OpenAdjustmentEntry {
/*START_USER_CODE_FOR_.SCSS*/
h1{
  display:none
}
// background-color: #333E48;
background: transparent;
.grpbxOpenAdjustmentEntry    {
  margin-top: 1rem;
  margin-bottom: 0.2rem !important;
  .card-header{
    display:none
  }
  .card{
    padding-top: 0.5rem;
    border: none;
    background-color: #c0bcbc;
  }
  label{
    width: 120px;
    text-align: right;
  }
  .txtEffectiveDate,.ddReceivablesType,.colAdjustmentAmount  {
    min-width: 100%;
    .col{
      max-width: 40%;
    }
    label{
      color: #c02020;
      font-weight: bold;
      
    }
  }
   .txtEffectiveDate{
   .dropdown-menu{
    top: -145px !important;
    left: 400px !important;
   }
 }
  .txtarComments {
    min-width: 100%;
    label{
      color: #c02020;
      font-weight: bold;
    }
  }
  .chkboxCreatePayables {
    max-width: 85%;
    margin-left: 120px;
    label{
      text-align: left;
      width: 100%;
    }
  }
}

.cmmndCntnrActions {
  .row{
    background-color: #f3f0f0;
    padding-top: 0.25rem;
  }

  .buttonwidget-container{
    max-width: 10%;
    button{
      width: 95%;
    }
  }
  .btnAdd{
    margin-left: auto;
  }
  .btnExit{
    margin-right: 3%;
  }

}

/*END_USER_CODE_FOR_.SCSS*/
}
