.Settlements-KCMOdataProfile,.ContractManagement-KCMOdataProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .groupboxwidget-container        {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #c0bcbc;
    }
  }
  .grpbxKCMOdataProfile  {

    .groupboxwidget1 {
      .card{
        padding-top: 0.25rem;
      }
      .textboxwidget-container,.datetimewidget-container{
        label{
          width: 100px;
          text-align: right;
        }
      }
      .txtFileNum {
        max-width: 10%;
        min-width: 10%;
        padding: 0;
        label{
          width: 35px;
        }
      }
      .txtDateTransmitted {
        min-width: 55%;
        max-width: 55%;
      }
      .txtLocation{
        min-width: 65%;
        max-width: 65%;
      }
      .txt1007Num,.txtSC95Num{
        max-width: 65%;
        min-width: 65%;
        input{
          width: 30%;
          text-align: center;
        }
      }
      .txtInspectionDate {
        min-width: 100%;
        input{
          max-width: 35%;
          min-width: 35%;
        }
      }
      .txtVoidReasonCode,.txtPeanutType,.txtSegType {
        max-width: 21.5%;
        min-width: 21.5%;
        margin-right: 78.5%;
        input{
          text-align: center;
        }
      }
      .txtFederalBuyingPoint ,.txtSeperseded1007Num ,.txtAuthCode {
        min-width: 35%;
        max-width: 35%;
        label{
          width: 120px;
        }
        input{
          text-align: center;
        }
      }
      .txtStateID {
        max-width: 21.5%;
        min-width: 21.5%;
        input{
          text-align: center;
        }
      }
      
      .txtFarmID{
        label{
          width: 50px;
        }
        max-width: 21.5%;
        min-width: 21.5%;
        input{
          text-align: center;
        }
      }
      .txtCountyID{
        max-width: 15%;
        min-width: 15%;
        padding: 0;
        label{
          width: 60px;
        }
       
        input{
          text-align: center;
        }
      }
      .txtFarmSuffixNum{
        max-width: 25%;
        min-width: 25%;
        label{
          width: 80px;
        }
        
        input{
          width: 60%;
          text-align: center;
        }
      }
      .txtHandlerNum1 {
        max-width: 25%;
        min-width: 25%;
        padding-right: 0;
        input{
          text-align: center;
        }
      }
      .txtHandlerNum2,.txtHandlerNum3{
        max-width: 12%;
        min-width: 12%;
        padding: 0;
        .col{
          padding-left: 0;
        }
        input{
          text-align: center;
        }
      }



    }
    .grpbxGrades {
      min-width: 60%;
      max-width: 60%;
      .card{
        border: 1px solid #ccc;
      }

      .lblGrades {
        label{
          width: 100%;
          text-align: left;
          font-weight: bold;
        }
      }
      .lblPct1,.lblPct2{
        max-width: 27%;
        // min-width: 20%;
        margin-left: 142px;
        label{
          width: 60%;
          text-align: center;
        }
      }
      .textboxwidget-container{
        min-width: 50%;
        max-width: 50%;
        label{
          width: 150px;
          text-align: right;
        }
        input{
          width: 60%;
          text-align: right;
        }
      }
      .txtFlavusDiscount {
        input{
          text-align: center;
        }
      }
    }
    .grpbxValues {
      min-width: 40%;
      max-width: 40%;
      padding-left: 0;
      .card{
        border: 1px solid #ccc;
      }
      
      .lblValues {
        label{
          width: 100%;
          text-align: left;
          font-weight: bold;
        }
      }
      .textboxwidget-container{
        min-width: 100%;
        max-width: 100%;
        label{
          width: 150px;
          text-align: right;
        }
        input{
          width: 70%;
          text-align: right;
        }
      }
    }
  }

  .commandcontainerwidget4 {
    // padding-top: 0.5rem;
    .row{
      background-color: #f3f0f0;
      padding-top: 0.1rem;
    }
    
    .buttonwidget5{
      min-width: 10%;
      max-width: 10%;
      margin-left: 45%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
