.ContractManagement-ContractGroupingSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  

  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
  .card {
    background-color: #f3f0f0;
  }
  .grpbxCntctGrpSrch {
    margin-bottom: 0 !important;
    label {
      width: 108px;
      text-align: right;
    }
    // .ddByngPt {
    //   min-width: 49%;
    // }
    .ddPnutVarty{
      max-width: 33.33%;
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
    }
    }
    .ddPenutType,
    .ddSeg,
    .ddOleic,
    .ddSeedGrwr,
    .ddOrgnc {
      max-width: 33.33%;
    }
    .ddSeedGrwr {
      margin-left: 66.66%;
    }
    .txtGrpng,
    .txtContrct,
    .ddByngPt {
      min-width: 66.66%;
      }
      input {
        width: 20%;
      }
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
    }
    .ddByngPt{
      .dropdown{
        max-width: 72% !important;
      }
    }
  
    .txtVndr {
      max-width: 23%;
      padding-right: 0;
      input{
        width:97.5%;
      }
    }
    .txtVndor{
     min-width: 40.3%;
      label{
        display: none;
      }
      input{
        max-width: 50%;
        min-width: 58.5%;
        color: blue;
        border: none;
        background: transparent;
        font-weight: 600;
        padding: 0;
      }
    }
    .btnPopUp {
      max-width: 3.37%;
      button{
        height: 28px;
      }
    }
    .btnSrch {
      margin-left: 33.33%;
      margin-bottom: 0 !important;
      button {
        left: 43%;
        position: relative;
      }
    }
    .btnCrtContrctGrpSrch {
      max-width: 15.9%;
      margin-left: 77.4%;
    }
    .btnOpen {
      max-width: 6%;
    }
    // .ddOleic {
    //   margin-left: 8%;
    //   label {
    //     width: 101px;
    //   }
    // }
    // .ddPenutType {
    //   margin-left: 15%;
    // }
    // .ddSeg,
    // .ddPnutVarty {
    //   margin-left: 41%;
    // }
    // .ddPnutVarty {
    //   max-width: 30%;
    // }
    // .ddSeedGrwr {
    //   margin-left: 64%;
    //   max-width: 30%;
    // }
    // .ddOrgnc {
    //   max-width: 30%;
    //   margin-left: 31%;
    //   label {
    //     width: 101px;
    //   }
    // }
    // .btnSrch {
    //   left: 40%;
    // }
    // .btnCrtContrctGrpSrch {
    //   left: 70%;
    //   max-width: 24%;
    //   top: 10px;
    // }
    // .btnOpen {
    //   max-width: 7%;
    //   left: 68%;
    //   top: 10px;
    // }
  }
  .gridContractGroupingSearchData{
    .extraRowCell_2,.extraRowCell_3,.extraRowCell_4,.extraRowCell_5,.extraRowCell_6,
    .extraRowCell_7,.extraRowCell_8,.extraRowCell_9,.extraRowCell_10,
    .extraRowCell_11,.extraRowCell_12{
      padding-top: 6px !important;
      text-align: center;
      font-size: 0.7rem;
      border-right: 1px solid #eeeede;
      min-height: 34px;
    }
    .rdt_TableRow{
      min-height: 35px !important;
    }
    .rdt_ExpanderRow{
      .py-3{
        padding-bottom: 0rem !important;
        padding-top: 0rem !important;
      }
    }  
    .rdt_TableCell{
      border-right: 1px solid #eeeede;
      padding-right: 16px;
      padding-left: 16px;
    }
    .ExpandRow{
      border-bottom: 1px solid #eeeede;
    }
    .extraRowCell_2{
      margin-left: 3.75%;
    }
    .rdt_TableCell:nth-child(2),.rdt_TableCol:nth-child(2),.extraRowCell_2{
      min-width: 8%;
      max-width: 8%;
    }
    .rdt_TableCell:nth-child(6),.rdt_TableCol:nth-child(6),.extraRowCell_6{
      min-width: 17%;
      max-width: 17%;
      padding: 0;
    }
    .rdt_TableCell:nth-child(3),.rdt_TableCol:nth-child(3),.extraRowCell_3{
      min-width: 9%;
      max-width: 9%;
      padding: 0;
    }
    .rdt_TableCell:nth-child(4),.rdt_TableCol:nth-child(4),.extraRowCell_4{
      min-width: 8%;
      max-width: 8%;
    }
    .rdt_TableCell:nth-child(8),.rdt_TableCol:nth-child(8),.extraRowCell_8{
      min-width: 10%;
      max-width: 10%;
      
    }
    .rdt_TableCell:nth-child(5),.rdt_TableCol:nth-child(5),.extraRowCell_5{
      min-width: 8%;
      max-width: 8%;
    }
    .rdt_TableCell:nth-child(9),.rdt_TableCol:nth-child(9),.extraRowCell_9,
    .rdt_TableCell:nth-child(10),.rdt_TableCol:nth-child(10),.extraRowCell_10,
    .rdt_TableCell:nth-child(12),.rdt_TableCol:nth-child(12),.extraRowCell_12
    {
      min-width: 7%;
      max-width: 7%;
    }
    .rdt_TableCell:nth-child(7),.rdt_TableCol:nth-child(7),.extraRowCell_7{
      min-width: 8%;
      max-width: 8%;
      padding: 0;
    }
    .rdt_TableCell:nth-child(11),.rdt_TableCol:nth-child(11),.extraRowCell_11{
      min-width: 7%;
      max-width: 7%;
      padding: 0;
    }
    .expandable-area{
      .row{
        cursor: pointer;
        color: rgba(0,0,0,0.87);;
      }
      .row:hover{
        color: rgba(0,0,0,0.87);;
        text-decoration: none;
      }
      .activeExpandRow{
        color: rgba(0,0,0,0.87);
        background-color: #e3f2fd;
        border-bottom-color: #FFFFFF;
      }
    }
  }
  .grpbxClose {
    .row{
      min-width: 100%;
      justify-content: center;
    }
    .btnClose {
      max-width: 5%;
    //  left: 45%;
    }
   .lstContGrp {
      min-width: 100%;
      select {
        min-height: 230px;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
