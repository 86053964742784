.SystemMaintenanceMasterManagement-FedWhouseLicenseProfile,.ContractManagement-FedWhouseLicenseProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .cmmndCntnrActions{
  .row{
    background-color: #f3f0f0;
    }
  }
  .grpbxFedWhouse{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
    .card-header{
      display: none;
    }
    .card{
      border: none;
      background-color: #c0bcbc;
    }
    label{
      width: 165px;
      text-align: right;
      color: rgb(235, 38, 38);
    }
    .txtCity,.txtState{
      min-width: 50%;
    }
    .txtCity{
      label{
        width: 40%;
      }
    }
    .txtState{
      label{
        width: 20%;
      }
      input{
        max-width: 40%;
      }
    }
    .grpbxBegEnd{
      
      label{
        width: 75px;
        text-align: right;
      }
      .lblEwhseRcpt,.lblDaco,.lblWhseRcpt{
        label{
          font-size: 12px;
          color: black;
        }
      }
      .lblDaco{
        min-width: 37%
      }
      .lblWhseRcpt{
        min-width: 33%;
      }
      .lblEwhseRcpt{
        max-width: 25%;
      }
    }
    .grpbxRateLayout{
      .txtStorgRate,.txtMnthlyStrgRate,.txtShrunkLoad,.txtUnshrunkLoad,.dtStrgPaid {
        .d-flex{
          display: grid !important;
        }
      }
    }
    .txtWhouseSign,.txtEwrEmail{
      .d-flex{
        display: grid !important;
      }
    }
    .gridLicProf{
      .card-header{
        display: block;
      }
    }
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .txtAddedBy,.textboxwidget40{
      min-width: 80%;
      max-width: 80%;
      label{
        width: 80px;
        text-align: right;
      }
      input{
        border: none;
        box-shadow: none;
        background: transparent;
      }
      .col{
        position: relative;
    bottom: 2px;
    right: 22px;
      }
    }
    .btnOk,.btnCancel{
      max-width: 10%;
      top: 15px;
      button{
        width: 90%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
