.SystemMaintenanceSpecialFunctions-VendorWireTransferSetup,.ContractManagement-VendorWireTransferSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxVendorWireTransferSetup{
    .card-header{
      display:none
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
    .card{
      border: none;
      background-color: #c0bcbc; 
      padding-top: 0.2rem;
      .btnAdd,.btnEdit,.btnDelete{
        min-width: 7.5%;
        max-width: 7.5%;
        margin-bottom: 0.2rem !important;
        .row{
          justify-content: end;
          button{
            width: 95%;
          }
        }
      }
      .btnAdd{
        margin-left: 52.5%;
      }
      .ddVendors {
        min-width: 75%;
        max-width: 75%;
        padding-right: 0;
        label{
          width: 70px;
          text-align: right;
        }
        .col{
          padding-right: 0;
        }
      }

      [class^="txtVendor"]{
        min-width: 100%;
        input{
          width: 80%;
          padding: 0;
          margin-left: 70px;
          box-shadow: none;
          border: none;
          background: transparent;
          color: blue;
        }
      }
      .grpbxWireTransferInfo {
        padding: 0;
        .card{
          border: 1px solid #f3f0f0;
          .textboxwidget-container{
            padding-right: 0;
            label{
              width: 70px;
              text-align: right;
              color: #c02020;
            }
          }
          .lblWireTransferInformation {
            min-width: 100%;
            label{
              font-weight: bold;
            }
          }
          .txtPayeeName ,.txtPayAddress1 ,.txtPayeeBank ,.txtBankAddress {
            min-width: 100%;
            .col{
              max-width: 60%;
            }
          }
          .txtPayAddress2 {
            min-width: 100%;
            .col{
              max-width: 60%;
              margin-left: 70px;
            }
          }
          .txtPayCity ,.txtBankCity {
            min-width: 50%;
            max-width: 50%;
          }
          .ddBankState ,.ddPayState{
            min-width: 35%;
            max-width: 35%;
            label{
              color: #c02020;
              width: 40px;
              text-align: right;
            }
          }
          .txtPayZip,.txtBankZip{
            min-width: 15%;
            max-width: 15%;
            label{
              color: #c02020;
              width: 30px;
            }
          }
          .txtBankABANum {
            min-width: 35%;
            max-width: 35%;
          }
          .txtBankAccountNum {
            min-width: 65%;
            max-width: 65%;
            label{
              width: 100px;
            }
          }
          .grpbxIntermediaryBank {
            padding: 0;
            .card{
              .card-body{
                padding-left: 0.85rem;
                padding-right: 0.85rem;
                .chkboxUseIMBank {
                  min-width: 100%;
                  padding-left: 0;
                  .form-check{
                    .form-check-label{
                      font-weight: bold;
                    }
                    input{
                      bottom: 2px;
                    }
                  }
                }
                .txtIMBank,.txtIMBankAddress {
                    min-width: 100%;
                    .col {
                      max-width: 60%;
                    }
                }
                .txtIMBankCity{
                  min-width: 50%;
                  max-width: 50%;
                }
                .ddIMBankState{
                  min-width: 35%;
                  max-width: 35%;
                  label{
                    color: #c02020;
                    width: 40px;
                    text-align: right;
                  }
                }
                .txtIMBankZip{
                  min-width: 15%;
                  max-width: 15%;
                  label{
                    color: #c02020;
                    width: 30px;
                  }
                }
              }
              .txtIMBankABANum {
                max-width: 35%;
                min-width: 35%;
              }
            }
          }
          .txtFAXNum {
            min-width: 100%;
            input{
              width: 30%;
            }
          }
          .txtFAXContact ,.txtEmail {
            min-width: 100%;
          }
        }
      }
    }
  }

  .grpbxActions {
    .card{
      background-color: #f3f0f0;
      .card-header{
        display: none;
      }
      .card-body{
        .lblAddedBy,.lblChangedBy{
          min-width: 10%;
          max-width: 10%;
          label{
            text-align: right;
            width: 100%;
          }
        }
        .lblAddedByValue,.lblChangedByValue{
          min-width: 30%;
          max-width: 30%;
          padding-left: 0;
          .form-label{
            display: none;
          }
          .col{
            position: relative;
            bottom: 7px;
            right: 25px;
          }
        }
        .btnAdd1{
          top: 15px;
          margin-left: 30%;
          max-width: 10%;
          min-width: 10%;
          button{
            width: 90%;
          }
        }
        .btnClose,.btnCancel{
          top: 15px;
          max-width: 10%;
          min-width: 10%;
          button{
            width: 90%;
          }
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
