.AccountPayment-SpecialPayablesProfile,.ContractManagement-SpecialPayablesProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  // background-color: #333E48;
h1,.card-header{
  display: none;
}
.card{
  background-color:#f3f0f0 ;
}
.grpbxSpcPayableProfile1{
  margin-bottom: 0 !important;
  .card{
    background-color: #f3f0f0;
  }
  
  label{
    width: 100px;
    text-align: right;
  }
  .ddSpoolLocationProf{
    min-width: 80%;
    max-width: 45%;
    padding-top: 0.5rem !important;
    color: red;
    .dropdown{
     
      max-width: 61.5%;
    }
  
  }
  .btnVendor{
    max-width: 3%;
    min-width: 3%;
  }
  .ddPayeeProf{
    padding-left: 0;
    .col{
      padding-left: 0%;
    }
  }
  .txtVndrName {
    max-width: 25%;
  }
  .ddSpoolLocationProf{
    label{
      width: 117px;
    }
    padding: 0;    
    button{
      height: 30px;
    }
    .dropdown-menu.show{
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
  }
  .ddItemCodeprof{
    padding: 0; 
    min-width: 18%;   
    .dropdown-menu.show{
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
  }
  
  .ddChargeLocationprof{
    min-width: 85%;
    max-width: 45%;
    color: red;
    .dropdown{
      max-width: 70%;
    }
    .col {
      max-width: 80%;
      padding: 0px;
    }
  }
  .ddChargeLocationprof{
    padding: 0;    
    .dropdown-menu.show{
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
  }
  .txtVendor{
    max-width: 20%;
    padding-right: 0;
    color: red;
  }
  .txtMemo{
    input{
      max-width: 67%;
    }
  }
  .lblRemarksComment{
    label{
      color: red !important;
    }
  }
  .ddPayeeProf{
    min-width: 30%;
    padding-right: 0;
  }
 
}
.grpbxDetails{
  min-width: 30%;
  .card{
    border:none;
    background-color:#f3f0f0 ;
    
  }
  .txtSeedGeneration{
    input{max-width: 33%;}
  }
}
.grpbxPendingEdit {
  max-width: 20%;
  .card{
    border:none;
    background-color:#f3f0f0 ;
  }
  .lblPending{
    display: none;
    font-size: 17px;
    font-weight: 500;
    color: red;
    position: relative;
    top: 15px;
    .form-label{
      display: none;
    }   
    .col{
      padding: 0;
    }
  }
  .lblEdite{
    position: relative;
    top: 10px;
    label{
      color: red;
      text-align: left;
      font-size: 17px;
      font-weight: 500;
    }
  }
  .lblAdd{
    position: relative;
    top: 10px;
    label{
      color: red;
      text-align: left;
      font-size: 17px;
      font-weight: 500;
    }
  }
}
.grpbxSpcPayableProfile2{
  .d-flex{
    display: grid !important;
  }
  .card{
    background-color:#f3f0f0 ;
  }
  label{
    width: 100px;
    text-align: left;
  }
  col{
    padding-left: 0;
  }
  .ddItemCodeprof {
    min-width: 15%;
    padding-right: 0;
    position:relative;
    color: red;
    button{
      width:120%;
    }
    label{
      text-align: left;
    }
    .col{
      padding-left: 0;
    }
  }
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 30vh !important;
    }
  .lblCropYear2{
    font-size: 20px;
    font-weight: 500;
    color: red !important;
  }
  .ddPeanutTypeprof{
    min-width: 11%;
    padding-left: 1.44%;
    padding-right: 0;
    color: red;
    .col{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .ddPeanutTypeprof ,.ddSeedGenerationprof ,.ddOleic ,.ddChargeLocationprof,.ddItemCodeprof {
    button{
      height: 30px;
    }
  }
  .ddPayeeProf{
    height: 30px;
  }
  .txtPayAmountprof {
    min-width: 8%;
    .col{
      padding-left: 5.5%;
    }
    color: red;
  }
  .ddChargeLocationprof {
    min-width: 15%;
    padding-right: 0.9%;
    color: red;
    .col{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .dropdown-menu.show{
    overflow-y:scroll!important;
    max-height:25vh!important;
    }
  .ddSeedGenerationprof{
    min-width: 10%;
    padding-left: 0.70;
    .col{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .ddOleic{
    min-width: 9%;
    padding-left: 0.5;
    .col{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .txtComment{
    min-width: 11%;
    .col{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .txtVndrName{
    min-width: 8% ;
    // padding-left: 0;
    .col{
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  .txtInvoiceNumber{
    min-width: 8%;
    // padding-right: 0;
    padding-left: 0;
    .col{
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  .txtDate{
    min-width: 12.2%;
    padding-right: 0;
    padding-left: 2%;
    color: red;
    
    .col{
      padding-left: 0;
      padding-right: 0;
      height: 32px;
    }
    
    input{
      max-width: 85%;
      height: 100%;
    }
  }
  
 
  .btnAdd,.btnEdit{
    max-width: 5%;
  }
  .btnAdd{
    margin-left: 8%;
  }
}
.gridSpecialPaymentEntry{
margin-bottom: 0 !important;
  .card-header{
    display: block;
  }
}
.grpbxremarks{
  padding-right: 0;
  .card{
    height: 100%;
  }
  .d-flex{
    display: grid !important;
  }
  .txtRemarks{
    .col{
      height: 85px;
    }
    textarea{
      height: 100%;
    }
  }
  .lblRemarksComment {
    label{
      color: red;
    }
  }
}
.grpbxCheckInfo{
  padding-left: 0;
  .txtValOfRTP,.txtValueOfCheck  {
    min-width: 100%;
    label{
      width: 83px;
      text-align: right;
    }
    input{
      max-width: 52%;
      text-align: right;
    }
  }
  .txtCollectSR,.txtCollectAR,.txtCollectCR   {
    max-width: 50%;
    min-width: 60%;
    padding-right: 0;
    text-align: right;
    label{
      width: 83px;
      text-align: right;
    }
    input{
      width:125%;
      text-align: right;
    }
  }
  .txtSRBalance,.txtARBalance ,.txtCRBalance  {
    max-width: 20%;
    min-width: 40%;
    padding-left: 0;
    text-align: left;
    input{
      width: 120%;
      text-align: right;
    }
  }
  // label{
  //   width: 81px;
  //   text-align: right;
  // }
  // padding-left: 0;
  // .txtValOfRTP,.txtValueOfCheck{
  //   max-width: 67%;
  //   min-width: 67%;
  // }
  // .txtSRBalance,.txtARBalance,.txtCRBalance{
  // margin-left: 33%;
  // }
}
.grpbxActions{
  .btnCreateHoldPay ,.btnScanDocuments,.btnViewDocuments{
    max-width: 12.5%;
    min-width: 12.5%;
    padding-top: 0.5rem;
    button{
      width: 90%;
    }

  }
  .btnCreateHoldPay{
    margin-left: auto;
  }
  // .btnScanDocuments{
  //   max-width: 150px;
  //   min-width: 150px;
  // }
  // .btnViewDocuments{
  //   min-width: 57%;
  //   padding-left: 0px;;
  // }
  .btnSaveToBatch,.btnPrintChecks{
    max-width: 10%; 
    min-width: 10%;
    padding-top: 0.5rem;
    button{
      width: 90%;
    }
  }
  // .btnPrintChecks{
  //   max-width: 12%;
  // }
  .btnClose{
    max-width:6%;
    min-width: 6%;
    padding-top: 0.5rem;
    button{
      width: 90%;
    }
  }
  .lblCropYear2{
    // left: 66%;
    max-width: 15%;
    min-width: 15%;
    margin-left: 61.5%;
    // max-width: 17%;
    // margin-left: 43%;
    background-color: lightgray;
    font-weight: 500;
    color: red;  
    font-size: 16px;
    .col{
      div{
        position: relative;
        top: 5px;
        right: 10px;
      }
    }
  }
}
  /*END_USER_CODE_FOR_.SCSS*/
}
