.SystemMaintenanceMasterManagement-AreaProfile,.ContractManagement-AreaProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxAreaProfile {
    .card {
      background-color: #f3f0f0;
    }
    .card-header {
      display: none;
    }
    label {
      width: 132px;
      text-align: right;
    }
    .lblCompany {
      max-width: 13%;
    }
    .lblCompanyValue {
      min-width: 70%;
      position: relative;
    bottom: 8px;
    right: 33px;
    color: blue;
    label{
      display: none;
    }
    }
    .lblCompanyValue,
    .lblID {
      label {
        text-align: left;
        display: none;
      }
    }
    .txtContractPendingMaxDays {
      label {
        width: 203px;
        color: rgb(224, 15, 15);
      }
    }
    .chkboxLandPlaster{
      margin-left: 136px;
      label{
        width: 172px;
      }
    }
    .chkboxForeignMaterial{
      margin-left: 96px;
      input{
        left: 40px;
        position: relative;
      }
      label{
        width: 182px;
      }
    }
    // .chkboxAreaProfile {
    //   label {
    //     width: 230px;
    //     text-align: left;
    //   }
    //   .form-check {
    //     left: 25%;
    //   }
    // }
    .txtID {
      max-width: 25%;
      label{
        color: rgb(224, 15, 15);
      }
    }
    .txtName{
      max-width: 60%;
      label{
        color: rgb(224, 15, 15);
      }
    }
    .txtHandlerLoc,
    .txtCCCHandler,
    .txtClearingAmount,
    .ddDisplay {
      input,
      .dropdown {
        max-width: 18%;
      }
      label{
        color: rgb(224, 15, 15);
      }
    }
    .txtClearingAmount,
    .txtContractPendingMaxDays,
    .txtLbsTons {
      input {
        max-width: 44%;
      }
    }
    .lblMin {
      max-width: 19%;
      label {
        width: 100%;
        text-align: end;
      }
    }
    .lblMax {
      max-width: 72%;
      min-width: 60%;
      padding-left: 0;
    }
    .txtKernalHullRangeMin {
      max-width: 22%;
      padding-right: 0;
      label{
        color: rgb(224, 15, 15);
      }
    }
    .txtKernalHullRangeMax {
      input {
        max-width: 20%;
      }
    }
    .gridAreaProfileData {
      .card-header {
        display: block;
      }
    }
    .cmmndCntnrActions {
      .mx-0 {
        justify-content: end;
      }
      .btnAdd,
      .btnEdit,
      .btnDelete {
        max-width: 6%;
      }
    }
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 8%;
      padding: 0;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 77%;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 7px;
      }
    }
    .btnOk,.btnCancel{
       max-width: 7.5%;
       min-width: 7.5%;
       top: 10px;
       button{
         width: 90%;
       }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
