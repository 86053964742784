.SystemMaintenanceMasterManagement-CountyProfile ,.ContractManagement-CountyProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .card-header {
    display: none;
  }
  .grpbxCountyProfile {
    .card {
      background-color: #c0bcbc;
    }
    label {
      width: 80px;
      text-align: right;
    }
    .lblState {
      max-width: 10%;
    }
    .lblStateValue {
      min-width: 80%;
      color: blue;
      .col{
        padding-left: 0;
      bottom: 7px;
      }
    label{
      text-align: left;
      display: none;
    }
    }
    .txtID {
      input {
        max-width: 12%;
      }
      label{
        color: red;
      }
    }
    .txtName {
      input {
        max-width: 25%;
      }
      label{
        color: red;
      }
    }
    .grpbxDetails {
      .card {
        background-color: #c0bcbc;
      }
      .txtFax {
        input {
          max-width: 20%;
        }
      }
      label {
        width: 60px;
      }
      .lblCCCWireTransferData {
        font-size: 15px;
        font-weight: 600;
        label {
          width: 250px;
          text-align: left;
        }
      }
    }
  }
  .grpbxActions {
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 8%;
      padding: 0;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 77%;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 7px;
      }
    }
    .btnOk,.btnCancel{
       max-width: 7.5%;
       min-width: 7.5%;
       top: 10px;
       button{
         width: 90%;
       }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
