.SystemMaintenanceMasterManagement-ContractLimitArea,.ContractManagement-ContractLimitArea {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxContractLimitArea {  
      .card-header{
        display:none
      }
      .card{
        border: none;
        background-color: #f3f0f0;
      }
  
      .dropdownfieldwidget-container,.textboxwidget-container{
        margin-left: 10%;
        max-width: 90%;
        label{
          width: 120px;
          text-align: right;
        }
        .dropdown{
          width: 30%;
          }
        input{
          width: 35%;
        }
      }
  
      .ddPeanutType {
        max-width: 30%;
        min-width: 30%;
        .dropdown{
          width: 100%;
        }
      }
      .ddArea
        .dropdown{
          width: 40%;
        }
      .txtPricePerTon  
        input{
        width: 10%;
        }
      
      .chkboxOrganicInd {
        max-width: 40%;
      }

      .chkboxSpecifyVendor{
        padding: 10px;
      }
  
      .chkboxSpecifyContract{
        padding: 10px;
        .d-flex{
          margin-left: 10%;
        }      
        [data-testid="chkboxSpecifyContract"]{
          display: flex;
          //flex-direction: row;
          flex-wrap: wrap;
        }
        .form-check{
          flex-basis: 50%;
          
          label{
            text-align: left;
          }
        }  
      }

      .txtContract{
        display:flex;
        margin-right: 60px;
        //max-width: 23%;
      }
      .txtVendor,.btnVendor ,.txtVendorName {
        max-width: 25%;
      }
    #txtVendor{
      width: 85%
    }
    .txtVendorName{
      margin-left: 20px;
    }
    #txtVendorName{
      max-width: fit-content;
      width: fit-content;
      color: blue;
    }
    .btnVendor{
      max-width: 0%;
    }

  
    .grpbxFloorControls  {
      min-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      .card-header{
        display:none
      }
      .card{
        border: 1px solid black;
      }
      .txtOptionPriceCap 
        input{
        width: 10%;
      }
      .txtPriceMatchEndDate {
        max-width: 30% !important;
      }
      .lblFloorControls{
        label{
          font-weight: bold;
        }
      }
      .lblDateFormat {
        max-width: 30% !important;
        margin-left: 0% !important;
        padding-left: 0;
        label{
          color: blue;
        }
      }
      .radioFloorControlsPriced{
        .d-flex{
          display: block !important;
        }
        
      }
    }
    .grpbxFlexMarketControls {
      .card{
        border: 1px solid black;
        .lblFlexMarketControls {
          min-width: 100%;
          label{
            font-weight: bold;
          }
        }
        .grpbxFlexOffers {
          padding: 0;
          min-width: 65%;
          max-width: 65%;
          .card{
            border: none;
            .grpbxRebateOffer {
              max-width: 50%;
              padding-left: 0;
              .card{
                border: 1px solid #c0bcbc;
                .chkboxRebateOffer {
                  min-width: 100%;
                }
                .textboxwidget-container{
                  min-width: 100%;
                  margin-left: 0;
                  label{
                    width: 100px;
                  }
                  input{
                    width: 70%;
                  }
                }
              }
            }
            .grpbxGainOffer{
              padding-left: 0;
              max-width: 50%;
              .card{
                border: 1px solid #c0bcbc;
                .chkboxGainOffer {
                  min-width: 100%;
                  .form-label{
                    display: none;
                  }
                }
                .txtGainSharePct {
                  min-width: 100%;
                  margin-left: 0;
                  label{
                    bottom: 5px;
                    position: relative;
                  }
                  input{
                    width: 70%;
                  }
                }
              }
            }
            .ddLoanRepayMethod {
              min-width: 100%;
              margin-left: 0;
              label{
                width: 120px;
              }
              .col{
                .dropdown{
                  width: 70%;
                }
              }
            }
            .txtEndMarketPeriod {
              min-width: 30%;
              max-width: 30%;
              margin-left: 0;
              padding-right: 0;
              label{
                width: 120px;
              }
              .col{
                padding-right: 0;
                input{
                  width: 100%;
                }
              }
            }
            .lblWeeks {
              min-width: 50%;
              max-width: 50%;
            }
            .chkboxMultiplePricings {
              min-width: 100%;
              .form-label{
                width: 120px;
                text-align: right;
              }
              .form-check-label{
                display: none;
              }
            }
            .radioPayRebate{
              min-width: 100%;
              border: 1px solid #c0bcbc;
              .form-label{
                width: 120px;
                text-align: right;
              }
            }
          }
        }
        .grpbxDates {
          min-width: 35%;
          max-width: 35%;
          padding: 0;
          .card{
            border: none;
            .lblPrcLockInDts {
              min-width: 100%;
              label{
                font-weight: bold;
                margin-bottom: 0;
              }
            }
            .gridLockInDates {
              .card-header{
                display: block;
              }
              min-width: 100%;
            }
            .txtTotal {
              max-width: 50%;
              margin-left: 30%;
              input{
                width: 100%;
                background: transparent;
              }
            }
          }
        }
      }
    }
    .chkboxRebateOffer {
      max-width: 32%;
    }
    .txtTonsAllowed{label{
      //width: 240px;
      //margin-left: -35%;
      text-align: right;
    }}

    .txtTonsAllowed {
      input{
        min-width: 60%;
        max-width: 60%;
      }
      margin-left: 11.2%;
    }
  
    .grpbxTonsDetails  {
      .card-header{
        display:none
      }
      .card{
        border: none;
      }
      // label{
      //   width: 200px;
      //   text-align: right;
      // }
      .dropdownfieldwidget-container,.textboxwidget-container{
        margin-left: 0;
        max-width: 100%;
        
        .dropdown{
          width: 100%;
          }
        input{
          width: 100%;
        }
      }
      // .txtTonsAllowed ~.form-group{
      //   min-width: 30%;
      // }
      //.txtTonsAllowed ,
      .txtTonsAssigned ,.txtAvailableTons,.txtAvailableTon  {
        label{
          width: 105px;
          text-align: right;
        }
      }
      .txtTonsContracted,.txtOpenTons {
        label{
          width: 120px;
          text-align: right;
        }
      }
      // .txtTonsAllowed {
      //   input{
      //     min-width: 23%;
      //     max-width: 23%;
      //   }
      // }
      .txtDryLandAcresContracted ,.txtIrrigatedAcresContracted{
        label{
          width: 150px;
        }
      }
      .btnImage1 {
        max-width: 5%;
      }
    }
    .txtVendorName{
      input{
        background-color: #f3f0f0;
        border: none;
        box-shadow: none;
      }
    }
  }
  

  .grpbxActions  {
    .card-header{
      display:none
      }
      .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #f3f0f0;
      }
      .lblChangedBy,.lblAddedBy {
        max-width: 10%;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .lblChangedByValue,.lblAddedByValue{
        min-width: 40%;
        margin-right: 30%;
        bottom: 7px;
      }
      
      .btnAdd,.btnExit{
        max-width: 10%;
        top: 15px;
        button{
          width: 85%;
          height: 40px;
        }
      }
  }

  .dropdown-menu.show {
    overflow-y: scroll !important;
     max-height: 25vh !important;
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
