.SystemMaintenanceSpecialFunctions-SecurityGroup,.ContractManagement-SecurityGroup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSecurityGroup  {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #c0bcbc; 
      padding-top: 0.2rem;

      .txtSecurityGroup {
        max-width: 50%;
        min-width: 50%;
        margin-right: 45%;
        label{
          width: 120px;
          text-align: right;
        }
      }
      .ddAuthorityLevel {
        max-width: 40%;
        min-width: 40%;
        margin-right: 55%;
        margin-bottom: 2rem !important;
        label{
          width: 120px;
          text-align: right;
        }
      }
      
      .grpbxUsers {
        min-width: 30%;
        max-width: 30%;
        padding: 0;
        .card{
          .card-body{
            padding-left: 0.5rem;
          }
          .lblUsers {
            min-width: 20%;
            label{
              margin-bottom: 0;
            }
          }
          .btnAddUser{
            margin-left: 21.33%;
          }
          .btnAddUser,.btnDeleteUser{
            min-width: 22.66%;
            max-width: 22.66%;
            margin-bottom: 0.2rem !important;
            .row{
              justify-content: end;
              button{
                width: 95%;
              }
            }
          }
          .lstUsers  {
            padding: 0;
            min-width: 100%;
            .col{
              padding-right: 0;
            }
            select{
              height: 197px;
            }
          }
        }
      }
      .grpbxFunctions {
        min-width: 40%;
        max-width: 40%;
        padding: 0;
        .card{
          .card-body{
            padding-left: 0.5rem;
          }
          .lblFunctions {
            min-width: 30%;
            label{
              margin-bottom: 0;
            }
          }
          .btnAddFunction{
            margin-left: 22%;
          }
          .btnAddFunction ,.btnEditFunction,.btnDeleteFunction{
            min-width: 16%;
            max-width: 16%;
            margin-bottom: 0.2rem !important;
            .row{
              justify-content: end;
              button{
                width: 95%;
              }
            }
          }
          .lstFunctions {
            padding: 0;
            min-width: 100%;
            .col{
              padding-right: 0;
            }
            select{
              height: 197px;
            }
          }
        }
      }
      .grpbxLocations {
        min-width: 30%;
        max-width: 30%;
        padding: 0;
        .card{
          .card-body{
            padding-left: 0.5rem;
          }
          .lblLocations {
            min-width: 20%;
            label{
              margin-bottom: 0;
            }
          }
          .btnAddLocation{
            margin-left: 7%;
          }
          .btnAddLocation,.btnEditLocation,.btnDeleteLocation{
            min-width: 22.66%;
            max-width: 22.66%;
            margin-bottom: 0.2rem !important;
            .row{
              justify-content: end;
              button{
                width: 95%;
              }
            }
          }
          .lstLocations {
            padding: 0;
            min-width: 100%;
            .col{
              padding-right: 0;
            }
            select{
              height: 197px;
            }
          }
        }
      }
    }
  }
  
  .grpbxActions {
    .card{
      background-color: #f3f0f0;
      .card-header{
        display: none;
      }
      .card-body{
        .lblAddedBy,.lblChangedBy{
          min-width: 10%;
          max-width: 10%;
          label{
            text-align: right;
            width: 100%;
          }
        }
        .lblAddedByValue,.lblChangedByValue{
          min-width: 30%;
          max-width: 30%;
          padding-left: 0;
          .form-label{
            display: none;
          }
          .col{
            position: relative;
            bottom: 7px;
            right: 21px;
          }
        }
        .btnCancel,.btnSGAdd{
          top: 15px;
          max-width: 10%;
          min-width: 10%;
          button{
            width: 90%;
          }
        }
        .btnSGAdd{
          margin-left: 40%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}


