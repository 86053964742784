.Settlements-NoSaleVoidFV95,.ContractManagement-NoSaleVoidFV95 {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
  }
  h1 {
    display: none;
  }
  .card {
    background-color: #f3f0f0;
    border: none;
  }
  .grpbxNoSaleVoidFV95 {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #f3f0f0;
      height: 210px;
    }
    .ddReason {
      .dropdown-menu.show {
        max-height: 15vh !important;
      }
    }
    label{
      width: 70px;
      text-align: right;
    }
    input{
      width: 40%;
    }
  }
  .commandcontainerwidget1{
    .row{
      border: none;
      background-color:  #f3f0f0;
      padding-top: 0.3rem;
    }
    .btnCancel{
      min-width: 10%;
      max-width: 10%;
      button{
        width: 90%;
        height: 40px;
      }
      margin-left: auto;
      
    }
    
    .btnUpdate {
      margin-left: auto;
      min-width: 10%;
      max-width: 10%;
      button{
        width: 90%;
        height: 40px;
      }
      margin-left: 2%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
