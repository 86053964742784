.Settlements-ReportPreviewTrade,.ContractManagement-ReportPreviewTrade {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
    // background-color: #333E48;
    background: transparent;
  }
  .grpbxHeader{
    padding: 0 !important;
  }
  h1{
    display: none;
  }
  .groupboxwidget0{
    padding: 0;
    .card-header{
      display:none
    }
    .btnPrintSetup,.btnSaveAsPdf{
      max-width: 9%;
  }
    .btnPrint,.btnClose{
      max-width: 6%;
    }
    .btnPrintSetup{
      margin-left: 38%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
