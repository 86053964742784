.ReportUsermanual-UserManual,.ContractManagement-UserManual {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-image: url(../../../../assets/img/BgLogo.png);
  .nav-pills{
    background-color: #f3f0f0;
  }

  .nav-item{
    background-color: #f3f0f0;
    border:1px solid #c0bcbc;
    border-radius: 7%
  }
  a{
    color: black;
  }
  .nav-link.active{
    background-color: #c0bcbc;
  }
 .card-header{
  display: none;
 }
 .lstGeneralManual,.lstCllctnManuals{
  min-width: 100%;
  background-color: #f3f0f0;
 }
 .cmmndCntnrClose{
  .row{
    background-color: #f3f0f0;
    padding-top: 0.4rem;
  }
  .btnClose{
    max-width: 50%;
    min-width: 50%;
  }
  .btnDownload{
    max-width: 50%;
    min-width: 50%;
      .row{
        margin-left: 85%;
      }
  }
 }
  /*END_USER_CODE_FOR_.SCSS*/
}
