.WarehouseReceipts-EWRSendRRI,.ContractManagement-EWRSendRRI {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxEWRSendRRI {
    .textboxwidget-container{
      min-width: 100%;
      label{
        width: 120px;
        text-align: right;
      }
      input{
        width: 15%;
      }
    }
    .lblFileNumber {
      min-width: 150px;
      max-width: 150px;
      label{
        width: 100%;
        text-align: right;
        color: blue;
      }
    }
    .lblFileNumberValue{
      padding-left: 0;
      color: blue;
      .col{
        position: relative;
        bottom: 7px;
        right: 17px;
      }
    }
  }
  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btnSend{
      min-width: 20%;
      margin-left: 40%;
      button{
        width: 40%;
      }
    }
    .btnClose{
      min-width: 15%;
      max-width: 15%;
      width: 90%;
      margin-left: auto;
      button{
        width: 60%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
