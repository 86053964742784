.StockTransfer-MultiStopProfile,.ContractManagement-MultiStopProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }

  .grpbxMultiStopProfile {
    .grpbxDetails {
      padding-left: 0;
      padding-right: 0;
      .card{
        padding-top: 0.25rem;
        border: 1px solid #ccc;
        .textboxwidget-container{
          min-width:35%;
          max-width: 35%;
          margin-left:10%;
        }
        .txtTripNum {
          margin-left: 40%;
          input{
            width: 50%;
            text-align: center;
          }
        }
        .txtCarrierAbbreviation ,.txtVendor {
          label{
            width: 150px;
            text-align: right;
          }
          input{
            text-align: center;
          }
        }
        .txtVehicleNum ,.txtPayFreightInd{
          label{
            width: 100px;
            text-align: right;
          }
          input{
            text-align: center;
          }
        }
      }
      
    }
    .grpbxGrid {
      padding-left: 0;
      padding-right: 0;
      .card{
        padding-top: 0.25rem;
        border: 1px solid #ccc;
        .groupboxwidget-container{
          padding-left: 0;
          padding-right: 0;
          .card{
            border: none;
          }
          label{
            margin-bottom: 0;
          }
        }
        [class^="txtLegNum"],.lblLegNum{
          min-width: 5%;
          max-width: 5%;
          padding: 0;
          .col{
            padding: 0.25rem;
          }
          label{
            width: 100%;
            text-align: center;
          }
        }
        [class^="txtTransferNum"],.lblTransferNum{
          min-width: 10%;
          max-width: 10%;
          padding: 0;
          .col{
            padding: 0.25rem;
          }
          label{
            width: 100%;
            text-align: center;
          }
        }
        [class^="ddType"],.lblType{
          min-width: 15%;
          max-width: 15%;
          padding: 0;
          .col{
            padding: 0.25rem;
          }
          label{
            width: 100%;
            text-align: center;
          }
          #ddItem_{
            height: 25px;
          }	  
          .dropdown-toggle:empty::after {
            margin-left: auto;
          }
          button{
           height:30px;
          }
        }
        [class^="ddShippingLoacation"],.lblShippingLocation{
          min-width: 30%;
          max-width: 30%;
          padding: 0;
          .col{
            padding: 0.25rem;
          }
          label{
            width: 100%;
            text-align: center;
          }
          #ddItem_{
            height: 25px;
          }	  
          .dropdown-toggle:empty::after {
            margin-left: auto;
          }
          button{
           height:30px;
          }
        }
        [class^="ddReceivingLocation"],.lblReceivingLocation{
          min-width: 30%;
          max-width: 30%;
          padding: 0;
          .col{
            padding: 0.25rem;
          }
          label{
            width: 100%;
            text-align: center;
          }
          #ddItem_{
            height: 25px;
          }	  
          .dropdown-toggle:empty::after {
            margin-left: auto;
          }
          button{
           height:30px;
          }
        }
        .lblMileageOverride {
          min-width: 10%;
          max-width: 10%;
          padding: 0;
          label{
            width: 100%;
            text-align: center;
          }
        }
        [class^="txtMileage"]{
          min-width: 5%;
          max-width: 5%;
          padding: 0;
          .col{
            padding: 0.25rem;
          }
        }
        [class^="txtOverride"]{
          min-width: 5%;
          max-width: 5%;
          padding: 0;
          .col{
            padding: 0.25rem;
          }
        }

      }
    }
    .cmmndCntnrSearch {
      
      border: 1px solid #ccc;
      .row{
        padding-top: 0.2rem;
      }
      .btnSearchForTransfers{
        button{
          width: 60%;
        }
      }
    }
  }
  .grpbxActions {
    .card-header{
      display:none
    }
    // margin-top: 1rem;
    
    .card{
      border: none;
      background-color:#f3f0f0;
      // padding-top: 1rem;
    }
    .lblChangedBy,.lblAddedBy {
      max-width: 9%;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblChangedByValue,.lblAddedByValue{
      min-width: 37%;
      label{
        display: none;
      }
      .col{
        position: relative;
        bottom: 6px;
        right: 40px;
      }
      // margin-right: 20%;
    }
    .buttonwidget-container{
      max-width: 11%;
      top: 15px;
      button{
        width: 90%;
        // height: 40px;
      }
    }
    .btnExit{
      margin-right: 10%;
    }
    
  }

  /*END_USER_CODE_FOR_.SCSS*/
}
