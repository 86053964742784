.SystemMaintenanceApplicationSupport-SpecFuncMisc,.ContractManagement-SpecFuncMisc {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSpecFuncMisc {  
      .card-header{
        display:none
      }
      .card{
        border: none;
        background-color: #c0bcbc;
        .groupboxwidget-container{
          min-width: 100%;
          .card{
            min-height: 150px;
            border: 1px solid #f3f0f0;
            .labelwidget-container {
              min-width: 100%;
              label{
                font-weight: bold;
              }
            }
            .textboxwidget-container,.dropdownfieldwidget-container,.datewidget-container{
              min-width: 35%;
              max-width: 35%;
              margin-right: 45%;
              margin-left: 20%;
              label{
                width: 200px;
                text-align: right;
              }
            }
          }
        }
        .grpbxChangeOleicInd{
          .ddOleicInd{
            .dropdown-toggle:empty::after {
              margin-left: auto;
            }
            button{
            height:30px;
            }
            #ddItem_{
              height: 20px;
            }
          }
        }
        .grpbxChgGrds{
          .txtSampleWt,.txtGr,.txtPct{
            input{
              text-align: right !important;
            }
          }
        }
        .grpbxChgDt{
         .txtNewContractDt{
          .dropdown-menu{
          top: -145px !important;
          left: 130px !important;
           }
         }
        }
        .grpbxSelectFunc {
          .card{
            border: none;
            .radioMisc {
              min-width: 100%;
              margin-bottom: 0 !important;
              .d-flex{
                display: block !important;
              }
              .form-label{
                font-weight: bold;
              }
              .col{
                padding: 0;
                .mb-3{
                  margin-bottom: 0 !important;
                  display: flex;
                  flex-wrap: wrap;
                  .form-check{
                    padding-bottom: 5px;
                    width: 33%;
                    input{
                      bottom: 7px;
                    }
                  }
                }
              }
            }
          }
        }
        .grpbxNewCY{
          .card{
            .radioSyncCYData {
              max-width: 50%;
              margin-left: 25%;
              margin-right: 20%;
              .form-check{
                input{
                  bottom: 3px;
                }
              }
            }
            .radioSyncFarmData{
              max-width: 16%;
              margin-left: 25%;
              padding-right: 0;
              .col{
                padding-right: 0;
              }
              .form-check{
                bottom: 3px;
                input{
                  bottom: 3px;
                }
              }
            }
            .txtBPForFarmSync {
              max-width: 22%;
              min-width: 22%;
              margin-left: 0;
              margin-right: 0;
              padding: 0;
              label{
                width: 120px;
              }
              .col{
                padding-right: 0;
              }
            }
            .lblOptional {
              max-width: 20%;
              min-width: 20%;
              label{
                font-weight: 400;
              }
            }
          }
        }
        .grpbxRequestARDownload {
          .card{
            // .radioBuyPt ,.radioCollectionPt,.radioArea{
            //   min-width: 200px;
            //   max-width: 200px;
            //   margin-left: 20%;
            //   padding-right: 0;
            //   .d-flex{
            //     justify-content: end;
            //     .col{
            //       max-width: 120px;
            //       padding-right: 0;
            //     }
            //   }
            // }
            .radioReqAR{
              min-width: 200px;
              max-width: 200px;
              margin-left: 20%;
              padding-right: 0;
              .d-flex{
                justify-content: end;
                .col{
                  max-width: 120px;
                  padding-right: 0;
                  .form-check{
                    margin-bottom: 1rem !important;
                    padding-left: 1.15rem !important;
                  }
                }
              }
              .form-check:nth-child(3),.form-check:nth-child(1),.form-check:nth-child(2){
                .form-check-label::after{
                  content: ':';
                }
              }
            }
            .grpbxAllign {
              max-width: 50%;
              min-width: 50%;
              max-height: 115px;
              overflow: hidden;
              .card{
                border: none;
                .card-body{
                  padding: 0;
                  .txtBuyPt ,.txtCollectionPt ,.txtArea {
                    min-width: 30%;
                    max-width: 30%;
                    margin-right: 70%;
                    margin-left: 0;
                    input{
                      width: 70px;
                    }
                  }
                }
              }
            }
            .txtCropYear {
              min-width: 40%;
              max-width: 40%;
              margin-right: 40%;
              input{
                width: 70px;
              }
            }
          }
        }
        .grpbxChangeVicamReading {
          .chkboxVicam {
            min-width: 200px;
            max-width: 200px;
            padding-right: 0;
            margin-left: 174px;
          //  left: 160px;
            .d-flex{
              justify-content: end;
              position: relative;
              bottom: 2px;
              .col{
                max-width: 27px;
                padding: 0.4rem;
                .mb-3{
                  margin-bottom: 0 !important;
                  .form-check{
                    .form-check-label{
                      display: none;
                    }
                    input{
                      top: -3px;
                    }
                  }
                }
              }
            }
          }
          .textboxwidget-container{
            margin-left: 205px !important;
            min-width: 400px !important;
            max-width: 400px !important;
            input{
              max-width: 128px;
              min-width: 128px;
            }
          }
          .txtVicamReading {
            margin-left: 0 !important;
            padding-left: 0;
            margin-right: 20% !important;
            label{
              width: 46px !important;
            }
          }
        }
        .grpbxDeleteStockTransfer {
          .card{
            .ddTransferType {
              min-width: 40%;
              max-width: 40%;
              margin-right: 40%;
            }
            .dropdown-toggle:empty::after {
              margin-left: auto;
            }
            button{
            height:30px;
            }
            #ddItem_{
             height: 25px;
           }
          }
        }
        .grpbxChange1007Unload {
          .card{
            .txtWhse {
              margin-right: 0;
            }
            .txtBin {
              margin-left: 0;
              margin-right: 0;
              padding-left: 0;
              min-width: 20%;
              max-width: 20%;
              label{
                width: 30px;
              }
              input{
                width: 80%;
              }
            }
          }
        }
        .txtReqKCMORecon {
          .card{
            .txtCropYearKCMO {
              margin-left: 0;
              min-width: 20%;
              max-width: 20%;
              label{
                width: 100px;
              }
            }
            .txtOptionalMail {
              min-width: 100%;
              max-width: 100%;
              margin: 0;
              label{
                width: 100px;
              }
            }
            .lblNOTE {
              padding-right: 0;
              max-width: 50px;
              min-width: 50px;
              color: #c02020;
              .d-flex{
                height: 100%;
              }
            }
            .lblNoteValue,.lblNote1 {
              min-width: 90%;
              max-width: 90%;
              color: #c02020;
              margin-bottom: 0 !important;
              label{
                margin-bottom: 0;
              }
            }
            .lblNote1{
              margin-left: 50px;
              label{
                bottom: 2px;
                position: relative;
              }
            }
          }
        }
        .grpbxResetUpload211 {
          .card{
            .radioReset{
              max-width: 40%;
              min-width: 40%;
              margin-left: 20%;
              margin-top: 0.2rem;
              padding-left: 150px;
              .col{
                input{
                  bottom: 2px;
                }
              }
            }
          }
        }
      }
    }
    .commandcontainerwidget1 {
      .row{
        background-color: #f3f0f0;
        padding-top: 0.2rem;
        .buttonwidget-container{
          min-width: 10%;
          max-width: 10%;
          button{
            width: 100%;
          }
        }
        .btnUpdate ,.btnRequest {
          margin-left: 1rem;
        }
        .btnCancel{
          margin-left: auto;
          margin-right: 1rem;
        }
      }
    }
  /*END_USER_CODE_FOR_.SCSS*/
}
