.WarehouseReceipts-Delivery,.ContractManagement-Delivery {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxDeliveryMenu {
    .row{
      margin-top: 2.2rem;
    }
    .btnDelAgreeProfile {
      min-width: 50%;
      max-width: 50%;
      position: relative;
      bottom: 30px;
      button{
        width: 37%;
        margin-left: 52%;
      }
    }
    .btnDelSettleProfile {
      min-width: 50%;
      max-width: 50%;
      position: relative;
      bottom: 30px;
      button{
        width: 37%;
        margin-left: 10%;
      }
    }
  }
  .cmmndCntnrClose {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btnClose{
      min-width: 10%;
      max-width: 10%;
      margin-left: 45%;
      button{
        width: 90%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
