.SystemMaintenanceSpecialFunctions-ReportUserSignatureTemplate,.ContractManagement-ReportUserSignatureTemplate {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxUserSignatureTemplate {
    .card{
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none
      }
    }
   
  }
  .grpbxHeader{
    padding: 0 !important;
  }
  .cmmndCntnrActions{
    padding:0;
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
      .buttonwidget-container{
        min-width: 10%;
        max-width: 10%;
        .row{
          justify-content: center;
          button{
            width: 90%; 
          }
        }
      }
      .btnPrintSetup{
        margin-left: 30%;
      }
      .btn1,.btn2{
        display: none;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
