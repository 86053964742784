.SystemMaintenanceSpecialFunctions-PriceScheduleSetup,.ContractManagement-PriceScheduleSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxPriceScheduleSetup{
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none
      }
      .lblPriceSchedules {
        min-width: 30%;
        max-width: 30%;
        margin-right: 60%;
        label{
          margin-bottom: 0;
        }
      }
      .gridPriceSchedules  {
        min-width: 100%;

        // label{
        //   display: none;
        // }
        .card-header {
          // display: block;
        }

        .rdt_TableRow {
          max-height: 50px;
          min-height: 50px;
          height: 50px;
        }
        .popover-body{
          // .columns-parent {
            // background-color: aliceblue;
            div:nth-child(0){
            display: none;
            }
          // }
        }
        .card {
          // border: 1px solid #f3f0f0;
          padding-top: 0 !important;
          .rdt_TableRow{
            border-bottom: none !important;
            .rdt_TableCell{
                border-bottom: 1px solid rgba(0,0,0,.12);
            }
         }
        }

        .rdt_TableCell:nth-child(16).rdt_TableCol:nth-child(16) {
          min-width: 300px !important;
          max-width: 300px;
        }
        .rdt_TableCell:nth-child(3),.rdt_TableCell:nth-child(4),.rdt_TableCell:nth-child(5),.rdt_TableCell:nth-child(6),
     .rdt_TableCell:nth-child(7),.rdt_TableCell:nth-child(8),.rdt_TableCell:nth-child(9),.rdt_TableCell:nth-child(10),.rdt_TableCell:nth-child(12){
      justify-content: right !important;
      input{
        text-align: right;
      }
    }
        .rdt_TableCol:nth-child(1),
        .rdt_TableCell:nth-child(1){
           background-color: #c0bcbc;
           max-width: 120px;
           min-width: 120px;
        }
        // .rdt_TableCol:nth-child(1),
        // .rdt_TableCol:nth-child(2),.rdt_TableCol:nth-child(5),
        // .rdt_TableCol:nth-child(6),.rdt_TableCol:nth-child(8){
        //   position: relative;
        //   top: 10px;
        // }
        .rdt_TableCell:nth-child(1){
          justify-content: left !important;
        }
        .rdt_TableCell:nth-child(2){
          justify-content: left !important;
        }
        .rdt_TableCol:nth-child(2),
        .rdt_TableCell:nth-child(2){
         background-color: #c0bcbc;
         max-width: 110px;
         min-width: 110px;
        }
        .rdt_TableCell:nth-child(1),.rdt_TableCell:nth-child(2){
          color: #080708;
       }
        .rdt_TableCol:nth-child(11),
        .rdt_TableCell:nth-child(11),.rdt_TableCol:nth-child(13),
        .rdt_TableCell:nth-child(13){
            button {
                display: block !important;
               }
           }
      }
    }
  }

  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .buttonwidget-container{
      max-width: 10%;
      min-width: 10%;
      .row{
        button{
          width: 90%;
        }
      }
    }
    .btnAuditHistory {
      margin-left: 1%;
    }
    .btnSave{
      margin-left: 69%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/

}
#SystemMaintenanceSpecialFunctions_PriceScheduleSetupPopUp {
  .close{
    display: none;
  }
}