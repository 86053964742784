.ContractManagement-PremiumDeductionTrade,.Settlements-PremiumDeductionTrade {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
  }
  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  h1,
  .card-header {
    display: none;
  }
  .grpbxPremDed {
    padding-right: 1.3%;
    padding-left: 1.3%;
    .card {
      background-color: #c0bcbc;
    }
    .txtSegValue {
      input {
        max-width: 25%;
      }
    }
    .txtNetProceeds {
      max-width: 30%;
      margin-left: 60%;
    }
    .lblIblhttps {
      max-width: 10%;
    }
    .gridPremDed {
      .rdt_TableCell:nth-child(3){
        justify-content: left;
      }
      .rdt_TableCell:nth-child(4){
        input{
          text-align: right;
        }
        justify-content: right;
      }
    }
  }
  .grpbxPremDedFooter {
     .card {
      border: none;
      background-color: #f3f0f0;
     }
     .row{
       justify-content: center;
     }
    .btnComplete {
      max-width: 9%;
    }
    .btnCancel{
      max-width: 7%;
    }
   }
  /*END_USER_CODE_FOR_.SCSS*/
}
