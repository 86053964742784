.ContractManagement-ContractInquiry {
  // background-color:#333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);;
  /*START_USER_CODE_FOR_.SCSS*/
  .pageHeader {
    // font-size: 1.15rem;
    // color: black;
    // font-weight: 326;
    // text-align: center;
    display: none;
  }
  // .GridWidget {
  //   .collapse {
  //     > div {
  //     height: 250px;
  //     }
  //   }
  // }

  .gridCntrctInq{
      // min-height: 300px;
      // max-height: 300px;
      // min-width: 100%;
      .expandable-area {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        background-color: #e7f9ff;
        min-height: 35px !important;
        .rdt_ExpanderRow{
          position: relative;
          top: 8px;
          .expandIconSpc{
            min-width: 30px;
            max-width: 30px;
          }
          .expndLbl{
            width:100%;
          }
          .extraRowCell_1,.extraRowCell_2,.extraRowCell_3,.extraRowCell_4,.extraRowCell_5,.extraRowCell_6,.extraRowCell_7,.extraRowCell_8,.extraRowCell_9,.extraRowCell_10,.extraRowCell_11,.extraRowCell_12,.extraRowCell_13,.extraRowCell_14,.extraRowCell_15,.extraRowCell_16,.extraRowCell_17,.extraRowCell_18,.extraRowCell_19,.extraRowCell_20,.extraRowCell_21,.extraRowCell_22,.extraRowCell_23,.extraRowCell_24,.extraRowCell_25,.extraRowCell_26,.extraRowCell_27,.extraRowCell_28,.extraRowCell_29,.extraRowCell_30,.extraRowCell_31,.extraRowCell_32,.extraRowCell_33,.extraRowCell_34{
            min-width: 100px;
            max-width: 100px;
            text-align: center;
          }
        }
    }
  }
  
  .gridCntrctInq{
    .rdt_TableCol,
    .rdt_TableCell  {
      min-width: 100px;
      max-width: 100px;
      padding: 0;
      //min-height: 30px;
  }
  .rdt_TableRow{
    min-height: 45px !important;
}
  //   // min-height: 300px;
  //   // max-height: 300px;
  //   // overflow-y: scroll;

  //   .rdt_TableCol:nth-child(2),
  //   .rdt_TableCell:nth-child(2)  {
  //     min-width: 65px;
  //     max-width: 65px;
  //     padding: 0;
  //   }
  //   .rdt_TableCol:nth-child(3),
  //   .rdt_TableCell:nth-child(3)  {
  //     min-width: 65px;
  //     max-width: 65px;
  //     padding: 0;
  //   }
  //   .rdt_TableCol:nth-child(4),
  //   .rdt_TableCell:nth-child(4)  {
  //     min-width: 65px;
  //     max-width: 65px;
  //     padding: 0;
  //   }
  //   .rdt_TableCol:nth-child(5),
  //   .rdt_TableCell:nth-child(5)  {
  //     min-width: 50px;
  //     max-width: 50px;
  //     padding: 0;
  //     text-align: center;
  //   }
    // .rdt_TableCol:nth-child(6),
    // .rdt_TableCell:nth-child(6)  {
    //   min-width: 200px;
    //   max-width: 200px;
    //   // padding: 0;
    //   justify-content: left;
     
    // }
  //   .rdt_TableCol:nth-child(7),
  //   .rdt_TableCell:nth-child(7)  {
  //     min-width: 45px;
  //     max-width: 45px;
  //     padding: 0;
  //   }
  //   .rdt_TableCol:nth-child(8),
  //   .rdt_TableCell:nth-child(8)  {
  //     min-width: 55px;
  //     max-width: 55px;
  //     padding: 0;
  //     text-align: center;
  //   }
  //   .rdt_TableCol:nth-child(9),
  //   .rdt_TableCell:nth-child(9)  {
  //     min-width: 55px;
  //     max-width: 55px;
  //     padding: 0;
  //     text-align: center;
  //   }
  //   .rdt_TableCol:nth-child(10),
  //   .rdt_TableCell:nth-child(10)  {
  //     min-width: 45px;
  //     max-width: 45px;
  //     padding: 0;
  //   }
  //   .rdt_TableCol:nth-child(11),
  //   .rdt_TableCell:nth-child(11)  {
  //     min-width: 45px;
  //     max-width: 45px;
  //     padding: 0;
  //   }
  //   .rdt_TableCol:nth-child(12),
  //   .rdt_TableCell:nth-child(12)  {
  //     min-width: 45px;
  //     max-width: 45px;
  //     padding: 0;
  //   }
  //   .rdt_TableCol:nth-child(13),
  //   .rdt_TableCell:nth-child(13)  {
  //     min-width: 65px;
  //     max-width: 65px;
  //     padding: 0;
  //   }
  //   .rdt_TableCol:nth-child(14),
  //   .rdt_TableCell:nth-child(14)  {
  //     min-width: 55px;
  //     max-width: 55px;
  //     padding: 0;
  //   }
  //   .rdt_TableCol:nth-child(15),
  //   .rdt_TableCell:nth-child(15)  {
  //     min-width: 60px;
  //     max-width: 60px;
  //     padding: 0;
  //     text-align: center;
  //   }
  //   .rdt_TableCol:nth-child(16),
  //   .rdt_TableCell:nth-child(16)  {
  //     min-width: 60px;
  //     max-width: 60px;
  //     padding: 0;
  //     text-align: center;
  //   }

  //   .rdt_TableCol:nth-child(17),
  //   .rdt_TableCell:nth-child(17)  {
  //     min-width: 60px;
  //     max-width: 60px;
  //     padding: 0;
  //     text-align: center;
  //   }
  //   .rdt_TableCol:nth-child(18),
  //   .rdt_TableCell:nth-child(18)  {
  //     min-width: 65px;
  //     max-width: 65px;
  //     padding: 0;
  //     text-align: center;
  //   }
  //   .rdt_TableCol:nth-child(19),
  //   .rdt_TableCell:nth-child(19)  {
  //     min-width: 65px;
  //     max-width: 65px;
  //     padding: 0;
  //   }
  //   .rdt_TableCol:nth-child(20),
  //   .rdt_TableCell:nth-child(20)  {
  //     min-width: 60px;
  //     max-width: 60px;
  //     padding: 0;
  //     text-align: center;
  //   }
  //   .rdt_TableCol:nth-child(21),
  //   .rdt_TableCell:nth-child(21)  {
  //     min-width: 60px;
  //     max-width: 60px;
  //     padding: 0;
  //     text-align: center;
  //   }
  //   .rdt_TableCol:nth-child(22),
  //   .rdt_TableCell:nth-child(22),.rdt_TableCol:nth-child(23),
  //   .rdt_TableCell:nth-child(23), .rdt_TableCol:nth-child(24),
  //   .rdt_TableCell:nth-child(24),.rdt_TableCol:nth-child(25),
  //   .rdt_TableCell:nth-child(25),.rdt_TableCol:nth-child(26),
  //   .rdt_TableCell:nth-child(26),.rdt_TableCol:nth-child(27),
  //   .rdt_TableCell:nth-child(27) {
  //     min-width: 60px;
  //     max-width: 60px;
  //     padding: 0;
  //     text-align: center;
  //   }
  //   .rdt_TableCol:nth-child(28),
  //   .rdt_TableCell:nth-child(28),.rdt_TableCol:nth-child(29),
  //   .rdt_TableCell:nth-child(29),.rdt_TableCol:nth-child(30),
  //   .rdt_TableCell:nth-child(30)  {
  //     min-width: 60px;
  //     max-width: 60px;
  //     padding: 0;
  //     text-align: center;
  //   }
  //   .rdt_TableCol:nth-child(31),
  //   .rdt_TableCell:nth-child(31),.rdt_TableCol:nth-child(32),
  //   .rdt_TableCell:nth-child(32),.rdt_TableCol:nth-child(33),
  //   .rdt_TableCell:nth-child(33),.rdt_TableCol:nth-child(34),
  //   .rdt_TableCell:nth-child(34)  {
  //     min-width: 55px;
  //     max-width: 55px;
  //     padding: 0;
  //     text-align: center;
  //   }
  //   .rdt_TableCol:nth-child(35),
  //   .rdt_TableCell:nth-child(35)  {
  //     min-width: 130px;
  //     max-width: 130px;
  //     padding: 0;
  //     text-align: center;
  //   }
  //   // .rdt_TableCol:nth-child(2),
  //   // .rdt_TableCell:nth-child(2)  {
  //   //   min-width: 2%;
  //   //   max-width: 2%;
  //   // }
  //   // .rdt_TableCol:nth-child(4),
  //   // .rdt_TableCell:nth-child(4),
  //   // .rdt_TableCol:nth-child(6),
  //   // .rdt_TableCell:nth-child(6),
  //   // .rdt_TableCol:nth-child(7),
  //   // .rdt_TableCell:nth-child(7),
  //   // .rdt_TableCol:nth-child(8),
  //   // .rdt_TableCell:nth-child(8),
  //   // .rdt_TableCol:nth-child(10),
  //   // .rdt_TableCell:nth-child(10),
  //   // .rdt_TableCol:nth-child(11),
  //   // .rdt_TableCell:nth-child(11)  {
  //   //   min-width: 2%;
  //   //   max-width: 2%;
  //   // }
  //   // .rdt_TableCol:nth-child(5),
  //   // .rdt_TableCell:nth-child(5)  {
  //   //   min-width: 6%;
  //   //   max-width: 6%;
  //   // }
  //   // .rdt_TableCol:nth-child(9),
  //   // .rdt_TableCell:nth-child(9)  {
  //   //   min-width: 4.5%;
  //   //   max-width: 4.5%;
  //   // }
  //   // .rdt_TableCol:nth-child(13),
  //   // .rdt_TableCell:nth-child(13),
  //   // .rdt_TableCol:nth-child(14),
  //   // .rdt_TableCell:nth-child(14),
  //   // .rdt_TableCol:nth-child(15),
  //   // .rdt_TableCell:nth-child(15),
  //   // .rdt_TableCol:nth-child(16),
  //   // .rdt_TableCell:nth-child(16),
  //   // .rdt_TableCol:nth-child(17),
  //   // .rdt_TableCell:nth-child(17) ,
  //   // .rdt_TableCol:nth-child(18),
  //   // .rdt_TableCell:nth-child(18) {
  //   //   min-width: 2.5%;
  //   //   max-width: 2.5%;
  //   // }
  //   // .rdt_TableCol:nth-child(34),
  //   // .rdt_TableCell:nth-child(34) {
  //   //   min-width: 5.5%;
  //   //   max-width: 5.5%;
  //   // }
  //   // .rdt_TableHeadRow,.rdt_TableRow{
  //   //   min-width: 111%
  //   // }
  //   // .rdt_TableHead{
  //   //   min-width: 162%;
  //   //   max-width: 162%;
  //   // }
   }
  .grpContractInquiry {
    .card {
      border: none;
      background-color: #f3f0f0;
      .card-header {
        display: none;
      }
      label {
        text-align: right;
      }
      .lblGeneral,
      .lblLocation,
      .lblPeanut {
        margin-bottom: 0;
        position: relative;
        left: 9.5%;
        label{
          font-weight: 800;
          text-align: center;
        }
      }
      .lblLocation{
        left: 7%;
      }
      .lblPeanut {
        max-width: 24.33%;
        // left: 9.5%;
      }
      .txtContract,
      .txtVendor {
        label {
          min-width: 20%;
        }
      }
      .drpStatus,
      .drpDisclosure,
      .drpContractType,
      .txtContract,
      .txtVendor,
      .txtGroup,
      .dtContractDate,
      .txtPrice {
        label {
          min-width: 100px;
        }
      }
      .drpType,
      .drpVariety,
      .drpSeg,
      .drpSeedGrower,
      .drpGeneration,
      .drpOleic,
      .drpOragnic {
        label {
          min-width: 100px;
        }
        .dropdown {
          width: 70%;
        }
      }
      .txtContract {
        input {
          width: 40%;
        }
      }
      .txtPrcnt{
        max-width: 10%;
        label{
          position: relative;
          left: 95%;
        }	
      }
      .txtGroup {
        input {
          width: 15.5%;
        }
      }
      .txtVendor {
        min-width: 19.6%;
        padding-right: 0;
      }
      .btnVenLookUp {
        max-width:3%;
        button{
          height: 28px;
        }
      }
      .txtVendorname{
        //right: 188px;
        min-width: 31.4%;
        input{
          max-width: 65%;
        }
      }
      .drpSeg {
        max-width: 33.33%;
        left: 12.7%;
        position: relative;
      }
      .drpStatus,
      .drpDisclosure,
      .drpContractType {
        .dropdown {
          max-width: 30%;
        }
      }
      .dtContractDate {
        min-width: 25%;
      }
      .dtThru {
        max-width: 41.66%;
        padding-left: 0;
        div#dtThruParent {
          width: 40%;
        }
      }
      .txtPrice {
        max-width: 24%;
        padding-right: 0;
      }
      .drpApplied {
        max-width: 25%;
        padding-left: 0;
      }
      .txtPrcnt {
        right: 4%;
      }
      .chkExpCollGri {
        //text-align: end;
        //margin-bottom: 0;
        // input#chkExpCollGrid0 {
        //   position: relative;
        //   right: 2.5%;
        // }
        .mb-3 {
          margin-bottom: 0 !important;
        }
        max-width: 13%;
        min-width: 15%;
        margin-left: 35%;
      }
      .chkPrintComm {
        .form-label {
          display: none;
        }
        .mb-3 {
          margin-bottom: 0 !important;
        }
        input{
          left: 90%;
        }
      }
      .btnOpen {
        max-width: 91%;
        min-width: 91%;
        display: flex;
        justify-content: end;
      }
      .btnPrint {
        max-width: 6%;
        padding-left: 30px;
      }
    }
  }
  .contractList {
    .card {
      background-color: lightgray;
      border: none;
      .card-header {
        background-color: #f3f0f0;
        padding: 0;
        .btn-link {
          color: #495057;
          i {
            display: none;
          }
        }
      }
    }
    #lstContractList {
      transition: none;
      background-color: lightgray;
      option {
        text-align: center;
      }
    }
  }
  .grpAction {
    .d-flex {
      display: grid !important;
    }
    .card {
      background-color: #f3f0f0;
      border: none;
      .card-header {
        display: none;
      }
    }
    .lblTotals {
      max-width: 5%;
      label {
        font-weight: 500;
        position: relative;
        bottom: 40px;
      }
    }
    // .txtContractPounds,
    // .txtAppliedPounds,
    // .txtTentativeApplied,
    // .txtTotalApp,
    // .txtOpen,
    // .txtDryLandAcres,
    // .txtIrrigatedAcres {
    //   label {
    //     text-align: center;
    //   }
    //   padding-right: 0;
    //   max-width: 7%;
    //  // max-width: 40%;
    //   input {
    //     //width: 100%;
    //     width: 200%;
    //   }
    // }
    .txtContractPounds,
    .txtAppliedPounds,
    .txtTentativeApplied,
    .txtTotalApp,
    .txtOpen,
    .txtDryLandAcres,
    .txtIrrigatedAcres {
      label {
        text-align: center;
      }
      padding-right: 0;
      max-width: 7%;
      .col{
        padding:0%;
      }
      input {
        width: 110%;
      }
    }
    .btnClose {
      margin-left: 5%;
      // margin-bottom: 1rem !important;
    }
    .btnExportExcel {
      margin-left: 3%;
      min-width: 9%;
  }
    .btnPrintSearch {
      padding-left: 2%;
    }
    .chkExp {
      max-width: 12%;
      margin-bottom: 0% !important;
    }
  }
  .form-control:focus {
    //border-color: transparent;
    box-shadow: none;
  }
  // .form-group {
  //   margin-bottom: 0rem;
  // }
  // .mb-3, .my-3 {
  //     margin-bottom: 0rem !important;
  // }
  /*END_USER_CODE_FOR_.SCSS*/
  // .rdt_TableCell:nth-child(4),.rdt_TableCol:nth-child(4){
  //   min-width: 8%;
  //   max-width: 8%;
  // }
  // .rdt_TableCell:nth-child(1),.rdt_TableCol:nth-child(1){
  //   min-width: 8%;
  //   max-width: 8%;
  // }
  .dropdown-menu.show {
   overflow-y: scroll !important;
    max-height: 25vh !important;
}
}
@media print {
  body, html, #wrapper {
      width: 100%;
  }
}
