.ContractManagement-ExceptPremRequestProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .card {
    background-color: #f3f0f0;
    border: none;
  }
  .gridExcPremReqProf{
    min-width: 100%;
    
  }
  .grpbxReqDetails {
    label {
      width: 111px;
      text-align: right;
    }
    .grpbxLabels {
      max-width: 36%;
      left: 63%;
      
    }
  }
  .txtRatebaseID {
    max-width: 53%;
    margin-left: 48%;
    input {
      max-width: 37%;
    }
  }
  .txtRate {
    max-width: 25%;
  }
  .ddRateBase {
    max-width: 46%;
  }
  .btnUpdateExceptPrems {
    left: 2%;
  }
  .txtTotalAmount{
    label{
      position: relative;
      left: 40%;
    }
    input{
      text-align: right;
      background: transparent;
      border: none;
    }
  }
  .grpbxSpoolDetails {
    .card {
      border: 1px solid rgba(0, 0, 0, 0.125);
    }
    label {
      width: 120px;
      text-align: right;
    }
    .txtSpoolLocID {
      max-width: 12%;
    }
    .txtarExplanation {
      textarea {
        height: 130px;
      }
    }
    .ddSpoolLocation{
      .dropdown-menu.show {      
        overflow-y: scroll !important;      
        max-height: 25vh !important;  
      }
    }
  }
  .grpbxActions {
    .btnUpdate,
    .btnExit,
    .btnReverse,
    .btnDelete,
    .btnReject,
    .btnApprove,
    .btnReprint,
    .btnCreatePayable {
      max-width: 12%;
      padding-right: 2%;
      button {
        width: 100%;
        height: 38px;
      }
    }
    .btnUpdate {
      margin-left: 2%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
