.SystemMaintenanceMasterManagement-FreightRateCopy,.ContractManagement-FreightRateCopy {
/*START_USER_CODE_FOR_.SCSS*/
h1{
  display:none
}
// background-color: #333E48;
background: transparent;
.grpbxFreightRateCopy {
  margin-bottom: 0.2rem !important;
  .card-header{
    display:none
  }
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
    }
  .card{
    padding-top: 0.5rem;
    border: none;
    background-color: #f3f0f0;

    .grpbxFreightDetails {
      max-width: 70%;
      min-width: 70%;
      margin-left: 15%;
      .card{
        label{
          width: 100px;
          text-align: right;
        }
        .txtFromBuyPt {
          min-width: 100%;
        }
        .txtFromCarrier,.txtToCarrier  {
          max-width: 50%;
          min-width: 50%;
        }
        .chkboxNoLoad {
          max-width: 50%;
          min-width: 50%;
          .form-check{
            input{
              bottom: 2px;
            }
            .form-check-label{
              text-align: left;
            }
          }
        }
      }
    }
    .lblCopyTo {
      min-width: 100%;
      label{
        margin-bottom: 0;
      }
    }
    .grpbxCopyTo {
      .card{
        background-color: #c0bcbc;
        border-radius: 0;
        height: 300px;
        overflow-y: auto;
        .dropdownfieldwidget-container{
          min-width: 100%;
        }
      }
    }
  }
}
.cmmndCntnrActions {
  .row{
    padding-top: 0.25rem;
    background-color: #f3f0f0;
    .btnCopy{
      min-width: 10%;
      max-width: 10%;
      margin-left: 45%;
      button{
        width: 100%;
      }
    }
    .btnClose{
      min-width: 10%;
      max-width: 10%;
      margin-left: auto;
      margin-right: 1.25rem;
      button{
        width: 100%;
      }
    }
  }
}

/*END_USER_CODE_FOR_.SCSS*/
}
