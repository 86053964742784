.SystemMaintenanceMasterManagement-CureCleanScheduleSetup,.ContractManagement-CureCleanScheduleSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxCureCleanSchedule {
    .card-header {
      display: none;
    }
    .card {
      background-color: #c0bcbc;
      // align-items: center;
    
      .card-body{
        max-width: 70%;
      margin-left: 15%;
      }
    }
    .lblCureCleanSchedule {
      min-width: 79.5%;
    }
    .btnDelete,
    .btnEdit,
    .btnAdd {
      max-width: 6%;
    }
    .ddBuyingPoint{
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
        }
    }
    .lstSchedule {
      bottom: 10px;
    }
  }
  .cmmndCntnrActions {
    // .row{
    // background-color: #f3f0f0;
    // }
  }
 .btnClose{
   margin-left: 62%;
  }
  /*END_USER_CODE_FOR_.SCSS*/
}

