.WarehouseReceipts-ViewWRApplications,.ContractManagement-ViewWRApplications {
   /*START_USER_CODE_FOR_.SCSS*/
  //  background-color: #333e48;
  background: transparent;
   h1 {
     display: none;
   }
   .card {
     background-color: #c0bcbc;
     border: none;
   }
   .grpbxViewWRApplications{
     .card-header{
       display: none;
     }
     .grpbxDetails{
       .d-flex{
         display: grid !important;
       }
       .txtObligationLocation,.txtUnloadLocation,.txtWarehouseReceiptNum{
         label{
           text-align: center;
         }
       }
        .txtWarehouseReceiptNum{
          input{
            text-align: center;
          }
        }
     
     }
     .grpbxContractApplication{
       .lblContractApplications{
         font-weight: 700;
       }
       .gridContractApplications{
         .card-header{
           display: block;
         }
       }
     }
     .grpbxSpotApplication{
       .lblSpotApplication{
         font-weight: 700;
       }
       .txtSpotPrice,.txtSpotPrice{
         padding: 0;
       }
     }
     .grpbxSeedGroverApplications{
       .lblSeedGrowerApplications{
         font-weight: 700;
       }
       .gridSeedGrowerApplications{
         .card-header{
           display: block;
         }
       }
     }
   }
   .gridContractApplications {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .rdt_TableCol:nth-child(1),
    .rdt_TableCell:nth-child(1){
      min-width: 40px;
      max-width: 40px;
    }
    .rdt_TableCol:nth-child(2),
    .rdt_TableCell:nth-child(2){
      min-width: 70px;
      max-width: 70px;
    }
    .rdt_TableCol:nth-child(3),
    .rdt_TableCell:nth-child(3){
      min-width: 55px;
      max-width: 55px;
    }
    .rdt_TableCol:nth-child(4),
    .rdt_TableCell:nth-child(4){
      min-width: 70px;
      max-width: 70px;
    }
    .rdt_TableCol:nth-child(5),
    .rdt_TableCell:nth-child(5){
      min-width: 94px;
    max-width: 94px;
    }
    .rdt_TableCol:nth-child(6),
    .rdt_TableCell:nth-child(6){
      min-width: 113px;
    max-width: 113px;
}
    .rdt_TableCol:nth-child(7),
    .rdt_TableCell:nth-child(7){
      min-width: 50px;
    max-width: 50px;
    }
    .rdt_TableCol:nth-child(8),
    .rdt_TableCell:nth-child(8){
      min-width: 50px;
    max-width: 50px;
    }
    .rdt_TableCol:nth-child(9),
    .rdt_TableCell:nth-child(9){
      min-width: 50px;
    max-width: 50px;
    }
    .rdt_TableCol:nth-child(10),
    .rdt_TableCell:nth-child(10){
      min-width: 100px;
    max-width: 100px;
    }
    .rdt_TableCol:nth-child(11),
    .rdt_TableCell:nth-child(11){
      min-width: 100px;
    max-width: 100px;
    }
    .rdt_TableCol:nth-child(12),
    .rdt_TableCell:nth-child(12){
      max-width: 100px;
      min-width: 100px;
      justify-content: right !important;
  }
    .rdt_TableCol:nth-child(13),
    .rdt_TableCell:nth-child(13){
      min-width: 100px;
    justify-content: right !important;
    max-width: 100px;
    }
    .rdt_TableCol:nth-child(14),
    .rdt_TableCell:nth-child(14){
      max-width: 107px;
      min-width: 107px;
      justify-content: right !important;
   }  
}
.gridSeedGrowerApplications{
  .rdt_TableCol:nth-child(1),
  .rdt_TableCell:nth-child(1){
    min-width: 40px;
    max-width: 40px;
  }
  .rdt_TableCol:nth-child(2),
  .rdt_TableCell:nth-child(2){
    min-width: 55px;
    max-width: 55px;
  }
  .rdt_TableCol:nth-child(3),
  .rdt_TableCell:nth-child(3){
    min-width: 75px;
    max-width: 75px;
  }
  .rdt_TableCol:nth-child(4),
  .rdt_TableCell:nth-child(4){
    min-width: 100px;
    max-width: 100px;
  }
  .rdt_TableCol:nth-child(5),
  .rdt_TableCell:nth-child(5){
    min-width: 115px;
    max-width: 115px;
  }
  .rdt_TableCol:nth-child(6),
  .rdt_TableCell:nth-child(6){
    min-width: 93px;
    max-width: 93px;
}
  .rdt_TableCol:nth-child(7),
  .rdt_TableCell:nth-child(7){
    min-width: 80px;
    max-width: 80px;
  }
  .rdt_TableCol:nth-child(8),
  .rdt_TableCell:nth-child(8){
    min-width: 60px;
    max-width: 60px;
    justify-content: right !important;
  }
  .rdt_TableCol:nth-child(9),
  .rdt_TableCell:nth-child(9){
    min-width: 70px;
    max-width: 70px;
    justify-content: right !important;
  }
  .rdt_TableCol:nth-child(10),
  .rdt_TableCell:nth-child(10){
    min-width: 60px;
    max-width: 60px;
    justify-content: right !important;
  }
}
   .cmmndCntnrActions{
     .row{
       justify-content: end;
       background-color: #f3f0f0;
       margin-right: 30px;
     }
   }
   /*END_USER_CODE_FOR_.SCSS*/
 }