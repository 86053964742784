.SystemMaintenanceApplicationSupport-OpenAccountsProfile,.ContractManagement-OpenAccountsProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxOpenAccountsProfile {
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none;
      }
      .card-body{
        margin-left: 15%;
        max-width: 70%;
        min-width: 70%;
      }
      .textboxwidget-container{
        label{
          width: 120px;
          text-align: right;
        }
      }
      .txtBuyingPoint,.ddAccountRemitTo {
        min-width: 100%;
        padding-right: 0;
        label{
          width: 120px;
          text-align: right;
        }
      }
      .txtBuyingPoint{
        input{
          padding-left: 0;
          background: transparent;
          border: none;
          box-shadow: none;
          color: blue;
        }
      }
      .txtAccountVendor {
        max-width: 32%;
        min-width: 32%;
        padding-right: 0;
        .col{
          padding-right: 0;
        }
        label{
          color: #c02020;
        }
      }
      .btnAccountVendor{
        max-width: 5%;
        min-width: 5%;
        margin-bottom: 0.3rem !important;
        .row{
          justify-content: center;
        }
      }
      .txtAcctVndr {
        max-width: 63%;
        min-width: 63%;
        padding-left: 0;
        .col{
          padding-left: 0;
        }
        input{
          background: transparent;
          border: none;
          box-shadow: none;
          color: blue;
          padding-left: 0;
        }
      }
      .btnViewAdj,.btnMakeAdj {
        max-width: 15%;
        justify-content: end;
        button{
          width: 95%;
        }
      }
      .labelwidget-container{
        max-width: 25%;
        min-height: 25%;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .lblBalances {
        max-width: 69%;
        min-width: 69%;
        label{
          font-weight: bold;
          margin-right: 5%;
        }
      }
      .lblCollectFormSettlement {
        max-width: 176px;
        min-width: 176px;
      }
      .chkboxCollectFormSettlement {
        padding: 0;
        .col{
          padding: 0;
          bottom: 4px;
        }
      }
      .lblBeginningBalance {
        min-width: 50%;
        max-width: 50%;
      }
      .lblOpenBalance {
        max-width: 150px;
        min-width: 150px;
      }
      .lblOpenBalanceBB ,.lblOpenBalanceAdj,.lblOpenBalanceCB ,.lblSeedReceivableAdj ,.lblAcctReceivableAdj ,
      .lblSeedReceivableCB ,.lblAcctReceivableCB ,.lblAcctReceivableCB ,.lblCommReceivableCB,.lblCommReceivableAdj {
        // margin-left: auto;
        label{
          display: none;
        }
        .col{
          position: relative;
          padding: 0;
          bottom: 7px;
          div{
            text-align: right;
          }
        }
      }
      .lblOpenBalanceBB{
        margin-left: auto;
      }
      .txtSeedReceivableBB,.txtAcctReceivableBB ,.txtCommReceivableBB {
        min-width: 50%;
        max-width: 50%;
        padding-right: 0;
       input{
          text-align: right;
        }
      }
      
      .btnDeductionTracking {
        max-width: 15%;
        min-width: 15%;
        margin-left: 84%;
        .row{
          justify-content: end;
          button{
            width: 95%;
          }
        }
      }
    }
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 8%;
      padding: 0;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 77%;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 7px;
      }
    }
    .btnOk,.btnCancel{
       max-width: 7.5%;
       min-width: 7.5%;
       top: 10px;
       button{
         width: 90%;
       }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
