.SystemMaintenanceSpecialFunctions-SecurityReport,.ContractManagement-SecurityReport {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSecurityReport  {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #f3f0f0; 
      padding-top: 0.2rem;
      .grpbxAddUser {
        min-width: 70%;
        max-width: 70%;
        margin-bottom: 0.75rem !important;
        .card{
          .card-body{
            padding: 0;
          }
          .ddAddUser {
            max-width: 60%;
            min-width: 60%;
            margin-bottom: 1rem !important;
            .d-flex{
              display: block !important;
              .col{
                padding: 0;
              }
            }
           #ddItem_{
             height:25px;
           }
           .dropdown-toggle:empty::after {
            margin-left: auto;
          }
          button{
          height:30px;
          }
          }
          .btnAdd {
            max-width: 10%;
            margin-right: 20%;
            margin-bottom: 1rem !important;
            button{
              width: 100%;
            }
          }
          .btnSelectAll ,.btnUnselectAll {
            max-width: 15%;
            min-width: 15%;
            .row{
              padding-left: 1rem;
              button{
                width: 100%;
                height: 35px;
              }
            }
          }
          .btnUpdatSelectedUser {
            max-width: 20%;
            min-width: 20%;
            margin-left: 30%;
            button{
              width: 100%;
              height: 45px;
            }
          }
          
        }
      }
      .grpbxCropYear {
        min-width: 30%;
        max-width: 30%;
        padding: 0;
        .card{
          .card-body{
            padding-left: 0.5rem;
            .radioCropYearsToView{
              padding: 0;
              margin-top: 1rem;
              max-width: 35%;
              min-width: 35%;
              .d-flex{
                display: block !important;
              }
              .form-check{
                margin-bottom: 7px;
                input{
                  bottom: 3px;
                }
              }
              .form-check:nth-child(3){
                .form-check-label::after{
                  content: ' :';
                }
              }
            }
            .txtOtherCropYearsToView {
              max-width: 20%;
              min-width: 20%;
              right: 20%;
              .col{
                padding-right: 0;
                input{
                  width: 100%;
                  padding: 0;
                  text-align: center;
                }
              }
            }
            .txtOtherCropYearsToView1 {
              max-width: 40%;
              min-width: 40%;
              right: 20%;
              padding: 0;
              .col{
                padding-left: 0;
                input{
                  background: transparent;
                  border: none;
                  padding-left: 0;
                  box-shadow: none;
                }
              }
            }
          }
        }
      }
      .gridUsers {
        min-width: 100%;
        label{
          display: none;
        }
        .card{
          border: 1px solid #c0bcbc;
          padding-top: 0 !important;
        }
        .rdt_TableCol:nth-child(1){
          input{
            display: none;
          }
        }
      }
    }
  }
  .cmmndCntnrActions {
    .row{
      padding-top: 0.2rem;
      background-color: #f3f0f0;
      .btnDeleteSelectedUsers{
        min-width: 20%;
        max-width: 20%;
        .row{
          padding-left: 1rem;
          button{
            width: 100%;
          }
        }
      }
      .btnRequestTheIBMUpdate {
        min-width: 20%;
        max-width: 20%;
        margin-left: 10%;
        .row{
          padding-left: 1rem;
          button{
            width: 100%;
          }
        }
      }
      .btnClose{
        min-width: 10%;
        max-width: 10%;
        margin-left: 40%;
        .row{
          padding-right: 1rem;
          button{
            width: 100%;
          }
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
