.SystemMaintenanceApplicationSupport-ManualRTPHistView,.ContractManagement-ManualRTPHistView {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxManualRTPHistView{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #f3f0f0;
      .card-header{
        display:none
      }
      .txtAction ,.txtUser ,.dtTime {
        min-width: 30%;
        max-width: 30%;
        label{
          width: 60px;
          text-align: right;
        }
      }
      .txtAction{
        margin-left: 5%;
      }
      .txtReason ,.txtLocation {
        min-width: 90%;
        max-width: 90%;
        margin-left: 5%;
        label{
          width: 60px;
          text-align: right;
        }
      }
    }
  }
  .grpbxBefore {
    padding-right: 0.125rem;
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #f3f0f0;
      .card-header{
        display:none
      }
      .lblBefore {
        min-width: 100%;
        max-width: 100%;
        label{
          margin-bottom: 0;
          width: 100%;
          font-weight: bold;
          text-align: center;
        }
      }
      .textboxwidget-container{
        padding: 0;
        label{
          width: 120px;
          text-align: right;
        }
        .col{
          padding-right: 0.5rem;
        }
      }
      .txtRemitTo,.txtWhseReceiptLoc{
        min-width: 100%;
      }
      .txtRTPProceeds ,.txtRTPDeliveryAgreement ,.txtRTPContract ,.txtRTPFreightMemo ,.txtRTPFarm
      .txtInspection ,.txtTradeInsp ,.txtSettlement ,.txtTradeSettle ,.txtwhseRcpt {
        min-width: 50%;
        max-width: 50%;
      }
      .txtRTPVendor {
        min-width: 40%;
        max-width: 40%;
        padding-right: 0;
      }
      .txtRTPProceeds{
        input{
          text-align: right;
        }
      }
      .txtVendorName{
        padding: 0;
        max-width: 60%;
        min-width: 60%;
        label{
          display: none;
        }
        input{
          box-shadow: none;
          border: none;
          background: transparent;
          padding-left: 0;
        }
        .col{
          padding-left: 0;
        }
      }
      .txtOption ,.txtShrinkPayment ,.txtLoanRecductionInd ,.txtStorageHandlingInd ,.txtStatus {
        min-width: 35%;
        max-width: 35%;
        margin-right: 65%;
      }
      .txtState,.txtFarmSettle{
        min-width: 35%;
        max-width: 35%;
      }
      .txtCounty {
        min-width: 22.5%;
        max-width: 22.5%;
        label{
          width: 50px;
        }
      }
    }
  }
  .grpbxAfter {
    padding-left: 0.125rem;
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #f3f0f0;
      .card-header{
        display:none
      }
      .lblAfter {
        min-width: 100%;
        max-width: 100%;
        label{
          margin-bottom: 0;
          width: 100%;
          font-weight: bold;
          text-align: center;
          color: blue;
        }
      }
      .textboxwidget-container{
        padding: 0;
        label{
          width: 120px;
          text-align: right;
          color: blue;
        }
        .col{
          padding-right: 0.5rem;
        }
      }
      .txtRemitTo1,.txtWhseReceiptLoc1{
        min-width: 100%;
      }
      .txtRTPProceeds1 ,.txtDeliveryAgreement1 ,.txtContract1 ,.txtFreightMemo1 ,.txtFarm1
      .txtInspection1 ,.txtTradeInsp1 ,.txtSettlement1 ,.txtTradeSettle1 ,.txtWhseRcpt1 {
        min-width: 50%;
        max-width: 50%;
      }
      .txtVendor1 {
        min-width: 40%;
        max-width: 40%;
        padding-right: 0;
      }
      .txtRTPProceeds1{
        input{
          text-align: right;
        }
      }
      .txtVendor1Name{
        padding: 0;
        max-width: 60%;
        min-width: 60%;
        label{
          display: none;
        }
        input{
          box-shadow: none;
          border: none;
          background: transparent;
          padding-left: 0;
        }
        .col{
          padding-left: 0;
        }
      }
      .txtOption1 ,.txtShrinkPayment1 ,.txtLoanReductionInd1 ,.txtStorageHandlingInd1 ,.txtStatus1 {
        min-width: 35%;
        max-width: 35%;
        margin-right: 65%;
      }
      .txtState1,.txtfarmSettle1{
        min-width: 35%;
        max-width: 35%;
      }
      .txtCounty1 {
        min-width: 22.5%;
        max-width: 22.5%;
        label{
          width: 50px;
        }
      }
    }
  }

  .cmmndCntnrExit{
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btnExit{
      max-width: 10%;
      min-width: 10%;
      margin-left: 45%;
      button{
        width: 100%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
