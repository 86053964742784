.SystemMaintenanceMasterManagement-Filters,.ContractManagement-Filters {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxFilter {
    margin-bottom: 0.2rem !important;
    .card-header{
      display:none
    }
    .card{
      
      padding-top: 0.5rem;
      border: none;
      background-color: #c0bcbc;
    }
    .radioFilter{
      max-width: 60%;
      min-width: 60%;
      margin-left: 20%;
      .mb-3{
        display: flex;
        flex-wrap: wrap;
      }
      .form-check{
        width: 50%;
        label{
          text-align: left;
        }
        input{
          bottom: 2px;
        }          
      }
    }
  }
  .grpbxPnutVrty  {
    // margin-top: 1rem;
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    margin-bottom: 0.2rem !important;
    .card-header{
      display:none
    }
    .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #c0bcbc;
    }
    .lblPnutVrtyFiltr {
      min-width: 100%;
    }
    .ddBuyingPt  ,.ddPnutType {
      max-width: 65%;
      margin-left: 15%;
      label{
        width: 100px;
        text-align: right;
      }
      padding-right: 0;
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      button{
      height:30px;
      }    
    }
    .ddPnutType{
      .dropdown-menu{
        min-width: 50%;
      }
    }

    .lblSelect {
      min-width: 20%;
      max-width: 20%;
      margin-left: 25%;
    }
    .btnSrch {
      margin-right: 20.5%;
      margin-left: auto;
      max-width: 7%;
      padding-top: 0.5rem;
      button{
        width:90%;
      }
    }
    .gridPnutFilter {
      max-width: 55%;
      min-width: 55%;
      margin-right: 20%;
      margin-left: auto;
      .card{
        padding-top: 0 !important;
      }
    }
    .btnSave{
      max-width: 10%;
      margin-left: 47%;
    button{
      width: 90%;
    }
  }
}
  .grpbxStateCounty{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    // margin-top: 1rem;
    margin-bottom: 0.2rem !important;
    .card-header{
      display:none
    }
    .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #c0bcbc;
    }
    .lblStateCounty {
      min-width: 100%;
    }
    .ddByngPt  {
      max-width: 65%;
      margin-left: 15%;
      label{
        width: 100px;
        text-align: right;
      }
      padding-right: 0;
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      button{
      height:30px;
      }    
    }
    .grpbxState {
      min-width: 50%;
      max-width: 50%;
      .card{
        border: 1px solid #f3f0f0;
        .lblStates {
          min-width: 100%;
        }
        .gridStates {
          min-width: 100%;
        }
        .btnSaveStates{
          max-width: 20%;
        margin-left: 40%;
        button{
          width: 90%;
        }
        }
      }
    }
    .grpbxCounty  {
      min-width: 50%;
      max-width: 50%;
      padding-left: 0;
      .card{
        border: 1px solid #f3f0f0;
        .lblAkkCounties {
          min-width: 100%;
          .col{
            padding-left: 0;
            margin-bottom: 0.7rem;
            top: 2px;
          }
        }
        .gridCounties  {
          min-width: 100%;
        }
        .btnSaveCounty  ,.btnCancel  {
          max-width: 20%;
          margin-left: 20%;
          button{
            width: 90%;
          }
        }
      }
      }
    }

    .gridStates,.gridCounties  {
      .card{
        padding-top: 0 !important;
      }
    }
  .commandcontainerwidget42  {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btnClose {
      max-width: 10%;
      min-width: 10%;
      button{
        width: 100%;
      }
      margin-left: 45%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
