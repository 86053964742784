.Settlements-ReviewCorrectionLog,.ContractManagement-ReviewCorrectionLog {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .grpbxReviewCorrectionLog    {
    .card-header{
      display:none
    }
    
    
    .card{
      border: none;
      background-color:  #f3f0f0;
      
    }
    .lblBuyPt{
      font-weight: bold;
      position: relative;
      left: 20px;
    }
    .lblInspNum{
      font-weight: bold;
      position: relative;
      right: 320px;  
    }
    .cmmndCntnrActions  {
      padding-right: 0;
      .row{
        border: none;
        background-color:  #f3f0f0;
        padding-top: 0.3rem;
      }
      .buttonwidget-container{
        min-width: 10%;
        max-width: 10%;
        button{
          width: 90%;
          height: 47px;
        }
        
      }
      .btn1{
        display: none;
      }
      .btnClose{
        margin-left: auto;
      }
      .btnClose{
        position: relative;
        right: 280px;
      }
    }
    
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
