.AccountPayment-NullifyCheck,.ContractManagement-NullifyCheck {
  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }
  .grpbxCheckDetails {
    .card-header {
      display: none;
    }
    .ddLocation{
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 31vh !important;
    }
    }
    .card {
      background-color: #c0bcbc;
    }
    .txtLocation {
      left: 19%;
      max-width: 40%;

      input {
        max-width: 40%;
        position: relative;
        left: 50%;
      }
    }
    .ddLocation {
      left: 17%;
      max-width: 61%;
      .d-flex {
        display: grid !important;
      }
    }
    .lblEnterCheckNumber {
    max-width: 60%;
    left: 37%;
    font-weight: 700;
    font-size: 14px;
    }
    .txtCheckNum1,
    .txtCheckNum2,
    .txtCheckNum3,
    .txtCheckNum4,
    .txtCheckNum5,
    .txtCheckNum6,
    .txtCheckNum7,
    .txtCheckNum8 {
      max-width: 34%;
    }
    .txtCheckNum1,
    .txtCheckNum2,
    .txtCheckNum3,
    .txtCheckNum4 {
      margin-left: 12%;
    }
  }
  .cmmndCntnrActions {
   .row{
      background-color: #f3f0f0;
   }
    .btnCancel {
      max-width: 8%;
    }
    .btnSpoilChecks {
      max-width: 10%;
      margin-left: 80%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
