.SystemMaintenanceSpecialFunctions-DiscountScheduleSetup,
.ContractManagement-DiscountScheduleSetup {

  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none
  }

  // background-color: #333E48;
  background: transparent;
  .grpbxDiscountSchedule {
    .card {
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;

      .card-header {
        display: block
      }

      .lblDiscountSchedule {
        min-width: 30%;
        max-width: 30%;

        label {
          margin-bottom: 0;
        }
      }

      .buttonwidget-container {
        max-width: 7.5%;
        margin-bottom: 0.3rem !important;

        .row {
          justify-content: center;

          button {
            width: 95%;
          }
        }
      }

      .btnAdd {
        margin-left: 54%;
        display: none;
      }

      .btnDelete {
        margin-right: 1%;
        display: none;
      }

      .gridDiscountScheduleSetup {
        min-width: 100%;

        label {
          display: none;
        }

        .card {
          border: 1px solid #f3f0f0;
          padding-top: 0 !important;
        }
      }
    }
  }

  .rdt_TableBody {
    min-height: 170px;
  }

  .cmmndCntnrActions {
    .row {
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }

    .btnOk,
    .btnCancel {
      max-width: 10%;
      min-width: 10%;

      button {
        width: 90%;
      }
    }

    .btnOk {
      margin-left: 80%;
    }
  }

  /*END_USER_CODE_FOR_.SCSS*/
}