.eamSideMenu {
  display: block;
  position: fixed;
  top: 55px;
  bottom: 0;
  left: 0;
  padding: 10px 0px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f5f5f5;
  border-right: 1px solid #eee;
  width: 15%;
  li {
    position: relative;
    display: block;
    a {
      position: relative;
      display: block;
      padding: 10px 15px;
      color: #04488a;
      text-decoration: none;
      background-color: transparent;
      span:first-child {
        width: 81%;
        white-space: normal;
        display: inline-block;
      }
      span:nth-child(2) {
        position: absolute;
        padding-top: 13.5px;
        font-size: 12px;
      }
    }
  }
  li.active {
    a,
    a:hover,
    a:focus {
      background-color: white !important;
      font-weight: bold;
      padding: none;
      border-left-color: #112a53;
      border-left: 5px solid !important;
      border-right: 0px solid !important;
    }
  }
}

/* reset our lists to remove bullet points and padding */
.mainmenu,
.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* make ALL links (main and submenu) have padding and background color */
.mainmenu {
  a {
    display: block;
    background-color: #ccc;
    text-decoration: none;
    padding: 10px;
    color: #000;
  }
  a:hover {
    background-color: #c5c5c5;
  }

  li:hover {
    .submenu {
      display: block;
      max-height: 200px;
    }
  }
}

.submenu {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.5s ease-out;
  a {
    background-color: #6e6f71;
    color: white;
    padding-left: 35px;
  }
  a:hover {
    background-color: #2fa4e7;
  }
}

@media (max-width: 991px) {
  .eamSideMenu {
    display: none;
  }
}
