.Settlements-ContractApplications2009,.ContractManagement-ContractApplications2009 {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .grpbxContractApplication2009   {
    .card-header{
      display:none
    }
    
    .card{
      border: none;
      background-color: #f3f0f0;
    }
    .txtSC95Num{
      label{
        // text-align: center;
        position: relative;
        left:18%;
      }
      input{
        width: 71%;
        text-align: center;
      }
    }
    .txt1007Num{
      margin-left: -4%;
      label{
        // text-align: center;
        position: relative;
        left:18%;
      }
      input{
        width: 68%;
        text-align: center;
      }
    }
    .txtFarm{
      position: relative;
    left: -4%;
    label{
      left: 118%;
      position: relative;
    }
    input{
      width: 250%;
      text-align: center;
    }
    }
    .txtApplyPounds{
      text-align: center;
      position:relative;
      left:21px;
      input{
        text-align: center;
      }
    }
    .txtPeanutType{
      position: relative;
    left: 13%;
    label{
      // text-align: center;
      margin-left: 6%;
    }
    input{
      width: 76%;
    text-align: center;
    }
    }
    .txtSeed{
      position: relative;
    left: 10%;
    label{
      // text-align: center;
      margin-left: 14%;
    }
    input{
      width: 68%;
    text-align: center;
    }
    }
    .txtSeedSpec{
      position: relative;
    left: 6%;
    label{
      // text-align: center;
      margin-left: 8%;
    }
    input{
      width: 69%;
    text-align: center;
    }
    }
    .txtTentPounds{
      position: relative;
      left: 2%;
      label{
        // text-align: center;
        margin-left: 20%;
      }
      input{
        width: 84%;
        text-align: center;
      }
    }
    .txtPrchsStrg{
      margin-left: 0%;
      position:relative;
      left:17px;
      input{
        width: 82%;
    text-align: center;
      }
    }
    .textboxwidget-container{
      max-width: 13%;
      padding-right: 0;
      label{
        width: 100%;
        // text-align: center;
      }
    }
    .lblContractApplications ,.gridViewContractApplication,.gridSeedGroverApp {
      .activeRow{
        background-color: transparent !important;
        color: black !important;
      }
      min-width: 100%;
    }

    .lblContractApplications,.lblSeedGroverApp  {
      font-weight: bold;
      min-width: 100%;
      label{
        margin-bottom: 0;
      }
      margin-top: 5px;
    }
    .lblUnappliedLbs {
      label{
        position: relative;
        right: 222px;
      }
    }
    .lblSpotApplications,.lblUnappliedLbs{
      margin-left: 0;
      padding-left: 0;
      max-width: 50%;
      min-width: 50%;
      label{
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    .txtUnappliedLbs{
      position: relative;
      right: 42%;
    }
    .txtLbsToApply,.txtUnappliedLbs ,.txtPrice {
      padding-bottom:10px;
      padding-top: 10px;
      padding-left: 0;
      min-width: 40%;
      max-width: 40%;
      div{
        display: flex;
      }
      .invalid-feedback{
        display: none;
      }
      label{
        text-align: right;
        width: 90%;
        margin-right: 5%;
      }
       }
    .txtPrice{
      position: relative;
    margin-left: 40%;
    bottom: 50px;
    }
    .grpbxSeedGroverApp{
      max-width: 60%;
      min-width: 60%;
      padding-left: 0;
      padding-right: 0;
    }
    .grpbxSpotApplication {
      max-width: 40%;
      min-width: 40%;
      
    }
  }
  .grpbxActions {
    .card-header{
      display:none
    }
    
    .card{
      border: none;
      background-color: #f3f0f0;
      padding-top: 0.5rem;
    }
    .lblChangedBy,.lblAddedBy {
      max-width: 10%;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblChangedByValue,.lblAddedByValue{
      min-width: 30%;
      max-width: 30%;
      // margin-right: 30%;
    }
    .lblChangedBy{
      margin-left: 22%;
      position: relative;
      right: 251px;
    }
    .lblChangedByValue{
      label{
        position: relative;
    right: 255px;
      }
    }
    .lblChangedBy,.lblChangedByValue{
      bottom: 10px;
    } 
    .lblAddedByValue,.lblAddedBy{
      bottom: 10px;
    }
    .btnPurchase,.btnCancel,.btnOk,.btnEditApp,.btnUndoEdit{
      max-width: 10%;
      top: 15px;
      button{
        width: 85%;
        height: 40px;
      }
    }
    .btnEditApp,.btnUndoEdit{
      padding: 0;
    }
    .btnOk{
      margin-left: auto;
    }
    .btnPurchase{
      margin-left: 10%;
      margin-right: 10%;
    }
  }
  .gridViewContractApplication{
    .rdt_TableCol:nth-child(1),
    .rdt_TableCell:nth-child(1){
      min-width: 37px;
    }
    .rdt_TableCol:nth-child(2),
    .rdt_TableCell:nth-child(2){
      min-width: 60px;
    }
    .rdt_TableCol:nth-child(3),
    .rdt_TableCell:nth-child(3){
      min-width: 131px;
      text-align: center;
    }
    .rdt_TableCol:nth-child(4),
    .rdt_TableCell:nth-child(4){
      min-width: 91px;
    }
    .rdt_TableCol:nth-child(5),
    .rdt_TableCell:nth-child(5){
      min-width: 70px;
    }
    .rdt_TableCol:nth-child(6),
    .rdt_TableCell:nth-child(6){
      min-width: 60px;
    }
    .rdt_TableCol:nth-child(7),
    .rdt_TableCell:nth-child(7){
      min-width: 58px;
    }
    .rdt_TableCol:nth-child(8),
    .rdt_TableCell:nth-child(8){
      min-width: 108px;
    }
    .rdt_TableCol:nth-child(9),
    .rdt_TableCell:nth-child(9){
      min-width: 62px;
    }
    .rdt_TableCol:nth-child(10),
    .rdt_TableCell:nth-child(10){
      min-width: 91px;
    }
    .rdt_TableCol:nth-child(11),
    .rdt_TableCell:nth-child(11){
      min-width: 91px;
    }
    .rdt_TableCol:nth-child(12),
    .rdt_TableCell:nth-child(12){
      min-width: 96px;
    }
    .rdt_TableCol:nth-child(13),
    .rdt_TableCell:nth-child(13){
      min-width: 90px;
    }
    .rdt_TableCol:nth-child(14),
    .rdt_TableCell:nth-child(14){
      min-width: 90px;
    }
    .rdt_TableCol:nth-child(15),
    .rdt_TableCell:nth-child(15){
      min-width: 90px;
    }.rdt_TableCol:nth-child(16),
    .rdt_TableCell:nth-child(16){
      min-width: 75px;
    }
    .rdt_TableCol:nth-child(17),
    .rdt_TableCell:nth-child(17){
      min-width: 90px;
    }
    .rdt_TableCol:nth-child(18),
    .rdt_TableCell:nth-child(18){
      min-width:80px;
    }

  }
  
  .gridSeedGroverApp{
    .rdt_TableCol:nth-child(1),
    .rdt_TableCell:nth-child(1){
      min-width: 70px;
      max-width: 70px;
    }
    .rdt_TableCol:nth-child(2),
    .rdt_TableCell:nth-child(2){
      min-width: 117px;
      max-width: 117px;
    }
    .rdt_TableCol:nth-child(3),
    .rdt_TableCell:nth-child(3){
      min-width: 91px;
      max-width: 91px;
    }
    .rdt_TableCol:nth-child(4),
    .rdt_TableCell:nth-child(4){
      min-width: 105px;
      max-width: 105px;
    }
    .rdt_TableCol:nth-child(5),
    .rdt_TableCell:nth-child(5){
      min-width: 80px;
      max-width: 80px;
    }
    .rdt_TableCol:nth-child(6),
    .rdt_TableCell:nth-child(6){
      min-width: 77px;
      max-width: 77px;
    }
    .rdt_TableCol:nth-child(7),
    .rdt_TableCell:nth-child(7){
      min-width: 72px;
      max-width: 72px;
    }
    .rdt_TableCol:nth-child(8),
    .rdt_TableCell:nth-child(8){
      min-width: 87px;
      max-width: 87px;
    }
    .rdt_TableCol:nth-child(9),
    .rdt_TableCell:nth-child(9){
      min-width: 61px;
      max-width: 61px;
    }
    .rdt_TableCol:nth-child(10),
    .rdt_TableCell:nth-child(10){
      min-width: 82px;
      max-width: 82px ;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
