.Settlements-ViewUploads,.ContractManagement-ViewUploads{
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .grpbxViewUploads        {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #f3f0f0;
    }
    
    
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 31vh !important;
      }

    .ddbuyingPoint ,.txtInspectionNum {
      min-width: 50%;
      max-width: 50%;
      label{
        width: 70px;
        text-align: right;
      }
      input{
        width: 50%;
      }
    }
    .txtState,.txtCounty,.txtFarm{
      margin-left: auto;
      label{
        width: 50px;
        text-align: right;
      }
    }
    .txtState,.txtCounty{
      min-width: 33.33%;
      input{
        width: 21%;
      }
    }
    .chkboxDisplayHidden ,.chkboxExcludeErrors {
      min-width: 50%;
      max-width: 50%;
      padding-left: 85px;
    }
    .chkboxResidueLoadsOnly {
      margin-left: auto;
      padding-left: 65px;
    }
    .btnSearch{
      min-width: 10%;
      button{
        width: 90%;
      }
      margin-left: 45%;
    }
    .txtStateVal,.txtCountyVal,.txtFarmVal,.txtFarmSuffixVal{
      min-width: 16.66%;
      max-width: 16.66%;
    }
    .chkboxOverride ,.chkboxChangetoResidueLoad {
      min-width: 16.66%;
      max-width: 16.66%;
      label{
        width: 150px;
      }
    }
  }
  .gridSearchResults{
    // max-height: 300px;
    // overflow-y: overlay;
    .rdt_TableCol:nth-child(1),
    .rdt_TableCell:nth-child(1){
    min-width: 37px;
    max-width: 50px;
    }
    .rdt_TableCol:nth-child(2),
    .rdt_TableCell:nth-child(2){
      min-width: 82px;
    max-width: 50px;
    }
    .rdt_TableCol:nth-child(3),
    .rdt_TableCell:nth-child(3){
      min-width: 85px;
    max-width: 50px;
    }
    .rdt_TableCol:nth-child(4),
    .rdt_TableCell:nth-child(4){
      min-width: 90px;
    max-width: 50px;
    }
    .rdt_TableCol:nth-child(5),
    .rdt_TableCell:nth-child(5){
      min-width: 80px;
    max-width: 50px;
    }
    .rdt_TableCol:nth-child(6),
    .rdt_TableCell:nth-child(6){
      min-width: 89px;
      max-width: 50px;
    }
    .rdt_TableCol:nth-child(7),
    .rdt_TableCell:nth-child(7){
      min-width: 280px;
      max-width: 50px;
    }
    .rdt_TableCol:nth-child(8),
    .rdt_TableCell:nth-child(8){
      min-width: 370px;
      max-width: 50px;
    }
    .rdt_TableCol:nth-child(9),
    .rdt_TableCell:nth-child(9){
      max-width: 652px;
      min-width: 280px;
    }
  }
  .cmmndCntnrActions  {
  
    .row{
      border: none;
      background-color:  #f3f0f0;
      padding-top: 0.3rem;
    }
    .buttonwidget-container{
      min-width: 10%;
      max-width: 10%;
      button{
        width: 90%;
      }  
    } 
    .btnClose {
      margin-left: auto;
    }
    .btnHide{
      margin-left: 1%;
  }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
