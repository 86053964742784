.Settlements-ScaleTickets,.ContractManagement-ScaleTickets {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  background-color: #333E48;


  .grpbxScaleTickets{
    .grpbxGreenTicket,.grpbxDryTicket{
      max-width: 50%;
      min-width: 50%;
      .txtDateDT ,.txtDateGT {
        min-width: 65%;
      }
    }
    .card-header{
      display:none
    }
    margin-top: 1rem;
    .card{
      border: none;
      background-color: #c0bcbc;
    }
    label{
      width: 170px;
      text-align: right;
    }

    input{
      width: 55%;
    }
    .lblBuyingPoint,.lblHMCIND,.lblState,.lblCounty,.lblFarm,.lblFarmSuffix,.lblTicketUpload,.lblUploadSelect,.lblFV95{
      label{
        display: none;
      }
    }
    // .txtDateDT,.txtDateGT{
    //   min-width: 33%;
    //   max-width: 33%;
    //   padding-right: 0;
    //   input{
    //     width: 75%;
    //   }
    // }
    .lblDateFormatDT ,.lblDateFormatGT{
      min-width: 17%;
      max-width: 17%;
      padding-left: 0;
      label{
        width: 90%;
        text-align: left;
        color: blue;
      }
    } 

    .txtCarrierDT,.txtCarrierGT{
      input{
        width: 70%;
      }
    }

    .lblDryTicket,.lblGreenTicket,.lblWeigher{
      label{
        font-weight: bold;
        text-align: left;
        padding-left: 5%;
      }
    }

    .grpbxWeigher{

      .lblState,.lblCounty,.lblFarm,.lblFarmSuffix{
        max-width: 10%;
        label{
          text-align: left;
          width: 100%;
        }
      }

      .lblState{
        margin-left: 20%;
      }

      .lblTicketUpload,.lblFV95{
        bottom: 10px;
      }
      .ddWeigher{
        .dropdown-toggle:empty::after {
          margin-left: auto;
        }
        button{
          height: 30px;
        }
      }

      .lblUploadSelect{
        margin-left: 58.3%;
        bottom: 25px;
      }

      .ddWeigher label{
        width: 200px;
      }
    }
  }

  .grpbxActions{
    .card-header{
    display:none
    }
    .card{
    border: none;
    background-color: #c0bcbc;
    }
    .lblChangedBy,.lblAddedBy {
      max-width: 10%;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue{
      max-width: 14%;
      min-width: 21%;
    }
    .lblChangedByValue{
      min-width: 40%;
    }
    .lblChangedBy,.lblChangedByValue{
      bottom: 10px;
    } 
    .lblChangedBy{
      position: relative;
      right: 230px;
    max-width: 30%;
    min-width: 30%;
    }
    .btnAdd1,.btnCancel,.btnSelectUpload, .btnUpdate{
      padding: 0.8%;
      max-width: 10%;
      top: 10px;
      button{
        width: 85%;
        height: 45px;
      }
    }
    .btnAdd1,.btnUpdate{
      margin-left: 10%;
    }

    .btnSelectUpload{
      margin-right: 20%;
    }
    
  }

  /*END_USER_CODE_FOR_.SCSS*/
}
