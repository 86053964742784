.SystemMaintenanceApplicationSupport-ManualRTPSearch,
.ContractManagement-ManualRTPSearch {

  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none
  }

  // background-color: #333E48;
  background: transparent;
  .grpbxManualRTPSearch {
    margin-bottom: 0.2rem !important;

    .card-header {
      display: none
    }

    .card {
      padding-top: 0.5rem;
      border: none;
      background-color: #f3f0f0;
    }

    label {
      width: 120px;
      text-align: right;
    }

    .ddLocation {
      min-width: 55%;
      max-width: 55%;
      padding-right: 0;
      margin-right: 5%;

      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }

    .txtVendor {
      max-width: 20% !important;
      min-width: 20% !important;
      padding-right: 0;

      .col {
        padding-right: 0;
      }

      input {
        width: 100% !important;
      }
    }

    .ddOBLLocation {
      min-width: 35%;
      padding: 0;
    }

    .btnVendor {
      max-width: 5%;
      margin-bottom: 0.3rem !important;
    }

    .txtContract {
      min-width: 25%;
      padding-right: 0;

      input {
        width: 100% !important;
      }
    }

    .txtVndr {
      max-width: 35%;
      min-width: 35%;
      padding: 0;

      input {
        color: blue;
        box-shadow: none;
        border: none;
        background: transparent;
      }
    }

    .txtSC95,
    .txtSettlement1007,
    .txtFreightMemo {
      max-width: 25%;
      min-width: 25%;
      margin-right: 35%;
      padding-right: 0;
    }

    .txtTradeInspection,
    .txtTradeSettlement,
    .txtWarehouseReceipt,
    .txtDeliveryAgreement,
    .txtProceeds {
      max-width: 25%;
      min-width: 25%;
      margin-right: 15%;
      padding-right: 0;
    }

    .txtProceeds {
      input {
        text-align: right;
      }
    }

    .txtFarm {
      max-width: 25%;
      min-width: 25%;
      padding-right: 0;
    }

    .txtFarmSuffix {
      max-width: 15%;
      min-width: 15%;
      padding: 0;

      label {
        width: 80px;
      }

      input {
        width: 100%;
      }
    }

    .cmmndCntnrSearchActions {
      .btnSearch {
        max-width: 10%;
        min-width: 10% !important;
        margin-left: 45%;

        button {
          width: 95%;
        }
      }

      .btnAdd,
      .btnChange,
      .btnDelete {
        max-width: 7%;
        min-width: 7%;
        margin-bottom: 0 !important;

        button {
          width: 95%;
        }
      }

      .btnAdd {
        margin-left: auto;
      }

      // .btnDelete{
      //   margin-right: 0.5rem;
      // }
    }

    .gridManualRTP {
      min-width: 100%;

      .card {
        padding-top: 0;
      }

      .card-header {
        display: block;
      }

      .popover-body {
        label {
          text-align: left;
          width: 100%;
        }
      }

      .rdt_TableCol:nth-child(2),
      .rdt_TableCell:nth-child(2) {
        min-width: 300px;
        max-width: 300px;
      }

      .rdt_TableCol:nth-child(11),
      .rdt_TableCell:nth-child(11) {
        min-width: 200px;
        max-width: 200px;

      }

      .rdt_TableCell:nth-child(11) {
        justify-content: right !important;
      }

      .rdt_TableCol:nth-child(9),
      .rdt_TableCell:nth-child(9) {
        min-width: 200px;
        max-width: 200px;
      }

      .rdt_TableCol:nth-child(10),
      .rdt_TableCell:nth-child(10) {
        min-width: 200px;
        max-width: 200px;
      }

      .rdt_TableCell:nth-child(2) {
        justify-content: left !important;
      }
    }

    .txtTotalProceeds {
      max-width: 30%;
      min-width: 30%;
      margin-left: 70%;

      input {
        width: 100%;
        text-align: right;
        color: blue;
      }

      label {
        color: blue;
      }

      padding-right: 0;
    }

  }

  .cmmndCntnrActions {
    .row {
      background-color: #f3f0f0;
      padding-top: 0.2rem;
      padding-bottom: 0%;
    }

    .btnClose {
      max-width: 10%;
      min-width: 10%;
      margin-left: 45%;

      button {
        width: 90%;
      }
    }

    .btnAuditHistory {
      margin-left: auto;
      max-width: 10%;
      min-width: 10%;
      margin-right: 0.5rem;

      button {
        width: 90%;
      }
    }
  }


  /*END_USER_CODE_FOR_.SCSS*/
}