.SystemMaintenanceSpecialFunctions-SpecialFunctions,.ContractManagement-SpecialFunctions {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSpecialFunctions {
    
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none
      }
      
      .buttonwidget-container{
        max-width: 33.33%;
       .row{
        justify-content: center;
        button{
          width: 55%;
          height: 45px;
        }
       }
      }
      
    }
    .btnErrorCodeFilterMntnce{
      button{
      display: none;
      }
    }
  }
  

  .cmmndCntnrClose{
    
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    
    .btnClose{
      max-width: 10%;
      min-width: 10%;
      margin-left: 45%;
      button{
        width: 70%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
