.AccountPayment-SpecialPayablesInquiry,.ContractManagement-SpecialPayablesInquiry {
  /*START_USER_CODE_FOR_.SCSS*/
  .card-header,h1{
    display: none;
  }
  .grpbxSpcPayableDetails{
    .card{
      background-color:#f3f0f0 ;
    }
    label{
      width:90px;
      text-align: right;

    }
    .txtMemo,.ddPayStatus,.txtCheck,.ddPeanutType,.ddSeedGeneration,.txtInvoice,.ddOleicInd {
      min-width: 25%;
      max-width: 30%;
    }
    .ddSpoolLocation,.ddChargeLocation,.ddItemCode{
      min-width: 30%;
      .dropdown-menu.show{
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }
    .txtSpoolLocation,.txtPayStatus,.txtChargeLocation,.txtPeanutType,.txtItemCode,.txtSeedGeneration,
    .txtOleicInd,.txtPayee{
      max-width: 10%;
    }
    .chkboxInclude{
      max-width: 25%;
      label{
        width: 111px;
        text-align: left;
      }
      .form-check{
        left: 35%;
      }
    }
    .txtStartDate{
      padding-right: 0;
      min-width: 19%;
    }
    .txtEndDate {
      min-width: 22.5%;
      label{
        width:8px
      }
      input{
        max-width: 50%;
      }
    }
    .txtVendor{
      padding-right: 0;
      min-width: 20%;
    }
    .txtVndrName{
      min-width: 21%;
    }
    .btnVendor{
      max-width: 3%;
    }
    .txtPayAmount{
      max-width: 30.3%;
      margin-left: 22.4%;
    }
    .ddRemitTo{
      min-width: 44%;
      padding-right: 0;
      margin-top: 0.5%;
      .col{
        max-width: 83%;
      }
    }
    .ddSeedGeneration{
      label{
        width: 91px;
      }
      .dropdown{
        right: 1%;
      }
    }
    
  }
  .grpbxActionsAndResults{
    // .row{
    //   justify-content: end;
    // }
    .card{
      background-color:#f3f0f0 ;
    }
    .btnSearch,.btnOpen{
      max-width: 6%;
    }
    .btnSearch{
      margin-left: 60%;
    }
    .lblCropYear2{
      max-width: 15%;
      font-size: 20px;
      font-weight: 500;
      color: red !important;
      .col{
        div{
          top: 4px;
          right: 10px;
          position: relative;
        }
      }
    }
    .btnCreateSpecialPayable{
      max-width: 13%;
    }
    .btnClose{
      display: flex;
    justify-content: center;
    }
    .gridSearchResults{
      .card-header{
        display: block;
      }
      .card{
        background-color:#c0bcbc ;
      }
      .rdt_TableBody{
        overflow-y: hidden;
      }
       .rdt_TableCell:nth-child(1), 
       .rdt_TableCell:nth-child(2),
       .rdt_TableCell:nth-child(3)
       {
         justify-content:left;
       }
       .expandable-area {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        background-color: #e7f9ff;
        min-height: 35px !important;
        .ExpandRow{
          
          border-bottom: 0.2px solid #b4d5e0 ;
        }
          .extraRowCell_1{
            min-width: 20%;
         }
         .extraRowCell_2,.extraRowCell_3,.extraRowCell_4,.extraRowCell_5,.extraRowCell_6,.extraRowCell_7{
            min-width: 16%;
            label{
              left: 5%;
              position: relative;
            }
         }
      }
       
      //  .rdt_TableCol:nth-child(5),
      //  .rdt_TableCell:nth-child(5)
      //  {
      //    justify-content:right;
      //  }
      // .extraRowCell_2,.extraRowCell_3,.extraRowCell_4,.extraRowCell_5,.extraRowCell_6,.extraRowCell_7{
      // min-width: 200px;
      // text-align: center;
      // }
      // overflow-y: overlay;
      // max-height: 300px;
      // min-height: 300px;
    }
  }
  .dtStart{
    margin-left: 1.7%;
    margin-top: 0.8%;
    .d-flex {
      .col{
        min-width: 62%;
        #dtStartParent{
          min-width: 159%;
        }
      }
    }
  }
  .dtEnd{
    margin-left: 0.8%;
    .d-flex {
      .form-label{
        max-width: 10%;
      }
    }
  }
  .ddOleicInd{
    margin-left: 5.7%;
    .d-flex{
      .col{
        min-width: 73%;
      }
    }
  }
  .txtCheck{
    margin-top: 0.7%;
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
