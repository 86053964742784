.StockTransfer-SelectFromList,.ContractManagement-SelectFromList {
    /*START_USER_CODE_FOR_.SCSS*/
    h1{
      display: none;
    }
    // background-color: #333E48;
    background: transparent;
    .groupboxwidget-container{
      .card{
        border: none;
        background-color:  #f3f0f0;
        .card-header{
          display: none;
        }
      }
    }
    .grpbxSelectFromList {
      min-width: 100%;
      .card{
        .gridList {
          min-width: 100%;
        .rdt_TableCol:nth-child(1),
        .rdt_TableCell:nth-child(1) {
          justify-content: left !important;
        }
        .rdt_TableCol:nth-child(2),
        .rdt_TableCell:nth-child(2) {
          justify-content: left !important;
         }
         .rdt_TableCol:nth-child(6),
         .rdt_TableCell:nth-child(6) {
          min-width: 140px;
          max-width: 140px;
           justify-content: left !important;
          }
          .rdt_TableCol:nth-child(7),
          .rdt_TableCell:nth-child(7) {
            min-width: 140px;
            max-width: 140px;
            justify-content: left !important;
           }
           .rdt_TableCol:nth-child(8),
           .rdt_TableCell:nth-child(8) {
            min-width: 140px;
            max-width: 140px;
             justify-content: left !important;
            }
            .rdt_TableCol:nth-child(10),
            .rdt_TableCell:nth-child(10) {
             min-width: 140px;
             max-width: 140px;
              justify-content: left !important;
             }
             .rdt_TableCol:nth-child(11),
             .rdt_TableCell:nth-child(11) {
              min-width: 100px;
              max-width: 100px;
               justify-content: right !important;
              }
              .rdt_TableCol:nth-child(12),
              .rdt_TableCell:nth-child(12) {
               min-width: 100px;
               max-width: 100px;
                justify-content: right !important;
               }
         .rdt_TableCol:nth-child(5),
         .rdt_TableCell:nth-child(5) {
          min-width: 180px;
          max-width: 180px;
          justify-content: left !important;
          }
      }
      }
      .lblLoad{
        max-width: 60%;
        min-width: 60%;
        position: relative;
        left: 33%;
        font-size: medium;
        font-weight: 600;
      }
    }
    .cmmndCntnrActions {
      .row{
        background-color: #f3f0f0;
        padding-top: 0.2rem;
      }
      .btnCancel{
        min-width: 10%;
        max-width: 10%;
        margin-left: 80%;
        button{
          width: 90%;
        }
      }
      .btnOk {
        min-width: 10%;
        max-width: 10%;
        button{
          width: 90%;
        }
      }
    }
    /*END_USER_CODE_FOR_.SCSS*/
  }
  