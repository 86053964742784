.SystemMaintenanceApplicationSupport-DeductionTrackingDetail,.ContractManagement-DeductionTrackingDetail {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color:  #333E48;
  background: transparent;
  .grpbxDeductionTrackingDetail {
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #f3f0f0;
      .card-header{
        display:none;
      }
      .txtBuyingPoint ,.txtAccountVendor {
        max-width: 51%;
        min-width: 51%;
        label{
          width: 100px;
          text-align: right;
        }
      }
      .lblDeductionCode {
        max-width: 100%;
        min-width: 100%;
        .col{
          div{
        position: relative;
        font-weight: bolder;
        top: 4px;
          }
        }
      }
      .gridDeductionTrackingDetail {
        min-width: 100%;
        .rdt_TableCell:nth-child(4),.rdt_TableCell:nth-child(4){
          justify-content: right;
        }
        .rdt_TableCell:nth-child(7),.rdt_TableCell:nth-child(7){
          justify-content: right;
        }
      }
      .lblTotal {
        max-width: 10%;
        min-width: 10%;
        label{
          font-weight: bold;
        }
      }
      .lblChargedTotal ,.lblCollectedTotal {
        max-width: 10%;
        min-width: 10%;
        font-weight: bolder;
        position: relative;
        bottom: 6px;
        label{
          width: 100%;
          text-align: left;
          display: none;
        }
      }
      .lblChargedTotal{
        margin-left: 39.1%;
      }
      .lblCollectedTotal{
        position: relative;
        left: 373px;
      }
    }
  }

  .cmmndCntnrActions {
    .row{
      padding-top: 0.25rem;
      background-color: #f3f0f0;
      .btnClose,.btnExportToExcel,.btnPrint{
        max-width: 10%;
        min-width: 10%;
        button{
          width: 100%;
        }
      }
      .btnClose{
        margin-left: 49%;
      }
    }
    .btnExportToExcel,.btnPrint{
      margin-left: 10%;
    }
    .btnExportToExcel{
      position: relative;
      left: 9%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
