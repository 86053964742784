.WarehouseReceipts-WhouseApplications,.ContractManagement-WhouseApplications {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333e48;
  background: transparent;
  h1 {
    display: none;
  }
  .card {
    background-color: #c0bcbc;
    border: none;
  }
  .grpbxWhouseApplications{
    .card-header{
      display: none;
    }
    .d-flex{
      display: grid !important;
    }
    .txtWarehouseReceiptNum,.txtUnloadLocation,.txtObligationLocation{
      min-width: 30.50%;
      padding: 0;
      label{
        text-align: center;
      }
    }
    .txtWarehouseReceiptNum{
      max-width: 12%;
      min-width: 12%;
    }
    .txtUnloadLocation,.txtObligationLocation{
       input{   
         text-align: left;
       }
    }
    .txtWarehouseReceiptNum,.txtPeanutType{
      input{
      text-align: center;
      }
    }
    .txtPounds{
     input{
       text-align: right;
       }
    }
    .txtPeanutType,.txtPounds,.txtOverideFarmNum{
      padding: 0;
      max-width: 11%;
      label{
        text-align: center;
      }
    }
    .grpbxContractApplication{
      .lblContractApplications{
        max-width: 15%;
        font-weight: 700;
      }
      .gridContractApplications{
       //  .card-header{
       //    display: block;
       //  }
        .rdt_TableCell:nth-child(10),
       .rdt_TableCell:nth-child(12),
       .rdt_TableCell:nth-child(14), .rdt_TableCell:nth-child(15),
       .rdt_TableCell:nth-child(16),.rdt_TableCell:nth-child(17),
       .rdt_TableCell:nth-child(18),.rdt_TableCell:nth-child(19)  {
         justify-content: right !important;
       }
       .rdt_TableCol:nth-child(1),
       .rdt_TableCell:nth-child(1) {
         min-width: 50px;
         max-width: 50px;
       }
       .rdt_TableCol:nth-child(19),
       .rdt_TableCell:nth-child(19) {
         min-width: 100px;
         max-width: 100px;
       }
       .rdt_TableCol:nth-child(12),
       .rdt_TableCol:nth-child(13),
       .rdt_TableCol:nth-child(14),
       .rdt_TableCol:nth-child(16),
       .rdt_TableCol:nth-child(17),
       .rdt_TableCol:nth-child(18),
       .rdt_TableCol:nth-child(15),
       .rdt_TableCol:nth-child(19),
       .rdt_TableCol:nth-child(20){
         justify-content: center;
         text-align: center;
       }
       .rdt_TableCol:nth-child(3),
       .rdt_TableCell:nth-child(3),
       .rdt_TableCol:nth-child(11),
       .rdt_TableCell:nth-child(11) {
         min-width: 80px;
         max-width: 80px;
         justify-content: center;
         text-align: center;
       }
       .rdt_TableCol:nth-child(7),
       .rdt_TableCell:nth-child(7) {
         min-width: 60px;
         max-width: 60px;
       }
       .rdt_TableCol:nth-child(5),
       .rdt_TableCell:nth-child(5) {
         min-width: 100px;
         max-width: 100px;
         justify-content: center;
         text-align: center;
       }
       .rdt_TableCol:nth-child(8),
       .rdt_TableCell:nth-child(8),
       .rdt_TableCol:nth-child(9),
       .rdt_TableCell:nth-child(9)
        {
         min-width: 70px;
         max-width: 70px;
         justify-content: center;
         text-align: center;
       }
       .rdt_TableCol:nth-child(10),
       .rdt_TableCell:nth-child(10) {
         min-width: 100px;
         max-width: 100px;
         justify-content: center;
         text-align: center;
       }
       .rdt_TableCol:nth-child(6),
       .rdt_TableCell:nth-child(6) {
         min-width: 100px;
         max-width: 100px;
         justify-content: center;
         text-align: center;
       }
      }
    }
    .grpbxSeedGroverApplications{
      .lblSeedGrowerApplications{
        font-weight: 700;
      }
      .txtSeedLbs{
        input{
          text-align: left;
        }
      }
      .d-flex{
        display: inline-flex !important;
      }
      
    }
    .grpbxSpotApplication{
      .d-flex{
        display: inline-flex !important;
      }
      .lblSpotApplication{
        font-weight: 700;
      }
      .txtPrice{
        padding: 0;
        min-width: 100%;
        label{
          width: 67.5%;
          text-align: right;
        }
        input{
         text-align: right;
        }
      }
      .txtLbsToApply{
        padding: 0;
        input{
          max-width: 92%;
        }
        .col{
         padding-left: 0;
         position: relative;
         left: 8px;
        }
      }
    }
  }
  .grpbxActions{
    .card{
      background-color: #f3f0f0;
    }
    .card-header{
      display: none;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 10%;
    }
    .lblAddedByValue{
      min-width: 77%;
    }
    .btnOk{
      max-width: 6%;
      button{
        width:90%;
      }
    }
    .btnCancel{
      max-width:7% ;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
} 