.SystemMaintenanceMasterManagement-CollectionPointProfile,.ContractManagement-CollectionPointProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .card-header {
    display: none;
  }
  .grpbxCollectionpointDetails {
    .card {
      background-color: #c0bcbc;
    }
    .lblCompany,
    .lblArea,
    .txtID {
      max-width: 30%;
      label {
        width: 100%;
        text-align: right;
      }
    }
    .txtID{
      label{
        color: red;
      }
    }

    .lblCompanyValue,.lblAreaValue{
      // min-width: 80%;

      label{
        text-align: left;
        display: none;
      }
      .col{
        color: blue;
        text-align: left;
        padding-left: 0;
        bottom: 7px;
        right:20px;
      }
    }
    .txtID {
      min-width: 50%;
      label {
        width: 58%;
      }
      input {
        max-width: 35%;
      }
    }
  }
  .grpbxActions {
    .card {
      background-color: #f3f0f0;
    }
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy,.lblChangedBy{
      max-width: 8%;
      padding: 0;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue,.lblChangedByValue{
      min-width: 77%;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 7px;
      }
    }
    .btnOk,.btnCancel{
       max-width: 7.5%;
       min-width: 7.5%;
       top: 10px;
       button{
         width: 90%;
       }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
  }
