.WarehouseReceipts-DelvSettleScaleTicket,.ContractManagement-DelvSettleScaleTicket {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #c0bcbc;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxDelvSettleScaleTicket {
    .card{
      .grpbxLoadInfo {
        .card{
          background-color:  #f3f0f0;
          .lblLoadInfo {
            min-width: 100%;
            label{
              width: 100%;
              text-align: center;
              font-weight: bold;
            }
          }
          .textboxwidget-container{
            min-width: 60%;
            max-width: 60%;
            margin-right: 20%;
            margin-left: 20%;
            label{
              width: 120px;
              text-align: right;
            }
          }
          .txtLoadNum {
            input{
              width: 30%;
            }
          }
          .txtVehicleNum {
            input{
              width: 70%;
            }
          }
        }
      }
      .grpbxShippedReceivedWt {
        .card{
          background-color:  #f3f0f0;
          .grpbxShippedWt {
            .card{
              .lblShippedWeight  {
                min-width: 80%;
                max-width: 80%;
                label{
                  width: 100%;
                  text-align: center;
                  font-weight: bold;
                }
              }
              .textboxwidget-container{
                label{
                  width: 120px;
                  text-align: right;
                }
                input{
                  width: 50%;
                }
              }
              .txtShippedDate {
                max-width: 60%;
                min-width: 60%;
                padding-right: 0;
                label{
                  width: 120px;
                  text-align: right;
                }
              }
              .lblDateFormatSW {
                max-width: 30%;
                padding-left: 0;
                label{
                  text-align: left;
                  color: blue;
                }
              }
              .txtWtIncldVehicleSW,.txtWtOfVehicleSW,.txtLbsOfPeanutsSW{
                input{
                  text-align: right;
                }
              }
              .chkboxSplitWtSW {
                min-width: 50%;
                max-width: 50%;
                margin-right: 50%;

                .form-label{
                  width: 120px;
                  text-align: right;
                  pointer-events: none;
                }
                .col{
                  .form-check-label{
                    display: none;
                  }
                  input{
                    top: 3px;
                  }
                }
              }
            }
          }
          .grpbxReceivedWt {
            .card{
              .lblReceivedWt  {
                min-width: 80%;
                max-width: 80%;
                label{
                  width: 100%;
                  text-align: center;
                  font-weight: bold;
                }
              }
              .textboxwidget-container{
                label{
                  width: 120px;
                  text-align: right;
                }
                input{
                  width: 50%;
                }
              }
              .txtReceivedDate {
                max-width: 60%;
                min-width: 60%;
                padding-right: 0;
                label{
                  width: 120px;
                  text-align: right;
                }
              }
              .lblDateFormatRW {
                max-width: 30%;
                padding-left: 0;
                label{
                  text-align: left;
                  color: blue;
                }
              }
              .txtWtIncldVehicleRW,.txtLbsOfPeanutsRW,.txtWtOfVehicleRW{
                input{
                  text-align: right;
                }
              }
              .chkboxSplitWtRW {
                min-width: 50%;
                max-width: 50%;
                margin-right: 50%;
                .form-label{
                  width: 120px;
                  text-align: right;
                  pointer-events: none;
                }
                .col{
                  .form-check-label{
                    display: none;
                  }
                  input{
                    top: 3px;
                  }
                }
              }
            }
          }
        }
      }
      .grpbxUpdateInventory  {
        padding-right: 0.1rem;
        .card{
          background-color:  #f3f0f0;
          .radioUpdateInventory{
            max-width: 60%;
            min-width: 60%;
            .form-label{
              font-weight: bold;
            }
            .form-check:nth-child(3){
              .form-check-label::after{
                content: ':';
              }
            }
            .col{
              padding-top: 0.5rem;
              .form-check{
                padding-bottom: 0.25rem;
                input{
                  bottom: 5px;
                }
              }
              
            }
          }
          .txtOtherWt {
            max-width: 30%;
            min-width: 30%;
            padding-left: 0;
            .col{
              padding: 0;
              // input{
              //   background-color: #f3f0f0;
              // }
            }
          }
        }
      }
      .grpbxWtVariance  {
        padding-left: 0.1rem;
        .card{
          background-color:  #f3f0f0;
          height: 100%;
          .txtWtVariance {
            max-width: 60%;
            min-width: 60%;
            padding-right: 0;
            padding-top: 1.5rem;
            label{
              width: 140px;
              text-align: right;
            }
            input{
              text-align: right;
            }
          }
          .lblLbs{
            max-width: 30%;
            padding-left: 0;
          }
        }
      }
    }
  }
  .grpbxActions {
    .card{
      background-color: #f3f0f0;
      .lblAddedBy,.lblChangedBy{
        max-width: 10%;
        min-width: 10%;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .lblAddedByValue,.lblChangedByValue{
        max-width: 30%;
        min-width: 30%;
        label{
          text-align: left;
        }
      }
      .lblChangedByValue,.lblChangedBy{
        bottom: 10px;
      }
      .btnOk{
        min-width: 10%;
        max-width: 10%;
        margin-left: 40%;
        top: 15px;
        button{
          width: 90%;
        }
      }
      .btnCancel{
        min-width: 10%;
        max-width: 10%;
        top: 15px;
        button{
          width: 90%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
