.SystemMaintenanceMasterManagement-FreightMenu,.ContractManagement-FreightMenu {
 /*START_USER_CODE_FOR_.SCSS*/
 h1{
  display:none
}
// background-color: #333E48;
background: transparent;
.grpbxFreightMenu{
  .card-header{
    display:none
  }
  // margin-top: 1rem;
  .card{
    padding-top: 0.5rem;
    border: none;
    background-color: #c0bcbc;
  }

  .buttonwidget-container{
    max-width: 33%;
    .row{
      justify-content: center;
      button{
        width: 95%;
        height: 75px;
      }
    }
    
  }
}

.cmmndCntnrClose{
  .row{
    background-color: #f3f0f0;
    padding-top: 0.2rem;
    .btnClose{
      max-width: 10%;
      margin-left: 45%;
      button{
        width: 100%;
      }
    }
  }
}
/*END_USER_CODE_FOR_.SCSS*/
}