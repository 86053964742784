.SystemMaintenanceSpecialFunctions-SecurityMenu,.ContractManagement-SecurityMenu {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSecurityMenu {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #c0bcbc; 
      padding-top: 0.2rem;
      .grpbxUserSearch {
        min-width: 70%;
        max-width: 70%;
        margin-left: 15%;
        margin-bottom: 0.75rem !important;
        .card{
          border: 1px solid #f3f0f0;
          .lblUserSearch {
            min-width: 100%;
          }
          .ddBuyingPoint {
            min-width: 85%;
            max-width: 85%;
            label{
              width: 100px;
              text-align: right;
            }
            .dropdown-menu.show {
              overflow-y: scroll !important;
              max-height: 25vh !important;
            }
          }
          .txtUserID {
            min-width: 85%;
            max-width: 85%;
            label{
              width: 100px;
              text-align: right;
            }
            input{
              width: 40%;
            }
          }
          .txtUserName {
            min-width: 85%;
            max-width: 85%;
            label{
              width: 100px;
              text-align: right;
            }
          }
          .btnSearch ,.btnClear{
            min-width: 15%;
            max-width: 15%;
            // bottom: 15px;
            button{
              width: 90%;
            }
          }
        }
      }
      .grpbxFunctions {
        min-width: 40%;
        max-width: 40%;
        padding: 0;
        .card{
          .card-body{
            padding-left: 0.5rem;
          }
          .lblFunctions {
            min-width: 30%;
            label{
              margin-bottom: 0;
            }
          }
          .btnAddFunction{
            margin-left: 22%;
          }
          .btnAddFunction ,.btnEditFunction,.btnDeleteFunction{
            min-width: 16%;
            max-width: 16%;
            margin-bottom: 0.2rem !important;
            .row{
              justify-content: end;
              button{
                width: 95%;
              }
            }
          }
          .lstFunctions {
            padding: 0;
            min-width: 100%;
            .col{
              padding-right: 0;
            }
            select{
              height: 197px;
            }
          }
        }
      }
      .grpbxUsers {
        min-width: 30%;
        max-width: 30%;
        padding: 0;
        .card{
          .card-body{
            padding-left: 0.5rem;
          }
          .lblUsers {
            min-width: 20%;
            label{
              margin-bottom: 0;
            }
          }
          .btnAddUser{
            margin-left: 7%;
          }
          .btnAddUser ,.btnEditUser,.btnDeleteUser{
            min-width: 22.66%;
            max-width: 22.66%;
            margin-bottom: 0.2rem !important;
            .row{
              justify-content: end;
              button{
                width: 95%;
              }
            }
          }
          .lstUsers {
            padding: 0;
            min-width: 100%;
            .col{
              padding-right: 0;
            }
            select{
              height: 197px;
            }
          }
        }
      }
      .grpbxGroups {
        min-width: 30%;
        max-width: 30%;
        padding: 0;
        .card{
          .card-body{
            padding-left: 0.5rem;
          }
          .lblGroups {
            min-width: 20%;
            label{
              margin-bottom: 0;
            }
          }
          .btnAddGroup{
            margin-left: 7%;
          }
          .btnAddGroup ,.btnEditGroup,.btnDeleteGroup{
            min-width: 22.66%;
            max-width: 22.66%;
            margin-bottom: 0.2rem !important;
            .row{
              justify-content: end;
              button{
                width: 95%;
              }
            }
          }
          .lstGroups {
            padding: 0;
            min-width: 100%;
            .col{
              padding-right: 0;
            }
            select{
              height: 197px;
            }
          }
        }
      }
    }
  }
  .cmmndCntnrActions {
    .row{
      padding-top: 0.2rem;
      background-color: #f3f0f0;
      .btnReports{
        min-width: 10%;
        max-width: 10%;
        margin-left: 1rem;
        button{
          width: 100%;
        }
      }
      .btnClose {
        min-width: 10%;
        max-width: 10%;
        margin-left: 34%;
        button{
          width: 100%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
