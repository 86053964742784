
.ContractManagement-ContractGrpSelect {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  // margin-bottom: 0;
  .card-header,
  h1 {
    display: none;
  }
  .card {
    border: none;
    background-color: #f3f0f0;
  }
  .ddBuyingPoint{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
  }
  }

  .txtOrganic,
  .txtSeedGrower,
  .txtOleic,
  .txtSeg,
  .txtPeanutVariety,
  .txtBuyingPoint {
    max-width: 10%;
  }
  .txtPeanutVariety {
    left: 0.3%;
  }
  .grpbxSrch {
    label {
      width: 100px;
      text-align: right;
    }
    .ddPeanutType {
      max-width: 30%;
      margin-left: 60%;
    }
    .txtPeanutType {
      max-width: 10%;
      // padding-left: 0;
    }
    .ddPeanutVariety {
      min-width: 30%;
      margin-left: 18%;
      label {
        width: 105px;
        text-align: right;
      }
      button {
        min-width: 102%;
      }
    }
    // .txtPeanutVariety{
    //   left: 2%;
    // }
    .txtContract {
      min-width: 60%;
      input {
        max-width: 20%;
      }
    }
    .ddSeg {
      min-width: 30%;
    }
    .txtVendor {
      min-width: 22%;
      padding-right: 0.3%;
    }
    .btnVendor {
      max-width: 4%;
      right: 1%;
      button{
        height:28px;
      }
    }
    .txtXx {
      min-width: 34%;
      padding:0;
      right:2%;
      input {
        max-width: 57%;
        color: blue;
        border: none;
        background: transparent;
        font-weight: 600;
      }
    }
    .ddOleic {
      min-width: 30%;
    }
    .ddOrganic,
    .ddSeedGrower {
      max-width: 30%;
      margin-left: 60%;
    }
    .btnSearch {
      left: 42%;
    }
  }
  .btnSelect {
    left: 92%;
    max-width: 7%;
  }
  .btnClose {
    left: 42%;
  }
  .dropdown-menu.show {
    overflow-y: scroll !important;
     max-height: 25vh !important;
 }
 .gridSelectCntrcts{
  //  max-height: 100px;
  .rdt_TableCol:nth-child(4),.rdt_TableCell:nth-child(4) {
          min-width: 95px;
          max-width: 95px;
    }
    .rdt_TableCol:nth-child(8),
          .rdt_TableCell:nth-child(8) {
            min-width: 150px;
            max-width: 150px;
          }
          .rdt_TableCol:nth-child(16),
                .rdt_TableCell:nth-child(16){
                  min-width: 200px;
                  max-width: 200px;
                }
                .rdt_TableCol:nth-child(17),
                .rdt_TableCell:nth-child(17){
                  min-width: 50px;
                  max-width: 50px;
                }
                .extraRowCell_1 {
                      margin-left: 625px;
                      min-width: 180px;
                     }
                     .extraRowCell_2 {
                      margin-left: 60px;
                     }
                     .rdt_TableCol:nth-child(8),
                           .rdt_TableCell:nth-child(8) {
                             min-width: 250px;
                             max-width: 250px;
                           }
}
//  .gridSelectCntrcts{
//   //  min-width: 1200px;
//   .extraRowCell_1 {
//     margin-left: 345px;
//     min-width: 180px;
//    }
//    .extraRowCell_2 {
//     margin-left: 30px;
//    }
//    .rdt_TableCol:nth-child(4),
//       .rdt_TableCell:nth-child(4) {
//         min-width: 95px;
//         max-width: 95px;
//       }
//       .rdt_TableCol:nth-child(8),
//       .rdt_TableCell:nth-child(8) {
//         min-width: 150px;
//         max-width: 150px;
//       }
//       .rdt_TableCol:nth-child(16),
//       .rdt_TableCell:nth-child(16){
//         min-width: 200px;
//         max-width: 200px;
//       }
//  }
 
  /*END_USER_CODE_FOR_.SCSS*/
}