.SystemMaintenanceSpecialFunctions-CmaDmaControlSetup,.ContractManagement-CmaDmaControlSetup {
 /*START_USER_CODE_FOR_.SCSS*/
 h1{
  display:none
}
// background-color: #333E48;
background: transparent;
.grpbxCmaDmaControlSetup {
  .card{
    padding-top: 0.2rem !important;
    border: none;
    background-color: #c0bcbc;
    .card-header{
      display:none
    }
    .buttonwidget-container{
      max-width: 7.5%;
      margin-bottom: 0.3rem !important;
     .row{
      justify-content: center;
      button{
        width: 95%;
      }
     }
    }
    .btnAdd{
      margin-left: 77.5%;
    }
    .gridCmaDmaControlSetup {
      min-width: 100%;
      label{
        display: none;
      }
      .card{
        border: 1px solid #f3f0f0;
        padding-top: 0 !important;
      }
      .rdt_TableCol:nth-child(4),
      .rdt_TableCell:nth-child(4){
        min-width: 220px;
        max-width: 220px;
      }
      .rdt_TableCell:nth-child(4){
        justify-content: left !important;
      }
      .rdt_TableCol:nth-child(1),
      .rdt_TableCol:nth-child(2),.rdt_TableCol:nth-child(3),
      .rdt_TableCol:nth-child(4),.rdt_TableCol:nth-child(5),
      .rdt_TableCol:nth-child(6),.rdt_TableCol:nth-child(7)
      {
        position: relative;
        top: 17px;
      }
      .rdt_TableCol:nth-child(8),.rdt_TableCol:nth-child(9)
      {
        position: relative;
        top: 12px;
      }
    }
  }
}

.cmmndCntnrClose{
  .row{
    background-color: #f3f0f0;
    padding-top: 0.2rem;
  }
  .btnClose{
    max-width: 10%;
    min-width: 10%;
    margin-left: 45%;
    button{
      width: 100%;
    }
  }
}
/*END_USER_CODE_FOR_.SCSS*/
}
