.WarehouseReceipts-ExtReceiptForfeituresProfile,.ContractManagement-ExtReceiptForfeituresProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1,.card-header{
    display: none;
  }
  .card{
    background-color: #c0bcbc;
  }
  .dropdown-toggle:empty::after {
    margin-left: auto;
  }
  button{
  height:30px;
  }
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
    }
  .grpbxReceiptInfo1{
    .card{
      border: none;
    }
    label{
      width: 113px;
      text-align: right;
    }
    .txtERecptNum,.ddSeg,.ddExtRcptStatus,.txtCCCCode,.ddStatus,.ddPeanutType{
      label{
        // color: red;
      }
    }
    .lblReceiptInfo{
      min-width: 65%;
      font-weight: 700;
    font-size: 15px;
      label{
        width: 80%;
        
      }
    }
    .ddExtRcptStatus {
      max-width: 35%;
      label{
        width: 137px;
      }
    }
    .ddPayoffLocation{
      min-width: 67%;
      .dropdown{
        width: 60%;
      }
    }
    .txtWhseBin,.ddStatus{
      max-width: 33%;
      min-width: 33%;
    }
    .txtRcptNum{
      max-width: 22.2%;
      min-width: 22.2%;
      padding-right: 0;
    }
    .txtERecptNum {
      padding-right: 0;
      max-width: 22.3%;
      min-width: 22.3%;
    }
    .txtCtrlNum{
      padding-right: 0;
      max-width: 22.5%;
      min-width: 22.5%;
    }
    .txtIssuedDate{
      min-width:27%;
      padding-right: 0;
    }
    .txtWtdAvgStorageDt{
      padding-right: 0;
      max-width: 35%;
      min-width: 31%;
    label{
      width: 49%;
    }
    }
    .txtLicenseNum{
      min-width: 20%;
    padding-right: 0;
    label{
      width: 54px;
    }
    }
    .txtCCCCode {
      min-width: 21%;
      padding-right: 0;
    }
    .ddPeanutType{
      min-width: 23%;
    padding-right: 0;
    }
    .ddSeg{
      max-width: 13%;
      min-width: 13%;
      padding: 0;
      label{
        width: 50px;
      }
    }
    .txtOriginalValue{
      min-width: 22%;
      padding: 0;
      label{
        width: 83px;
      }
    }
    .ddHowStored{
      padding: 0;
      min-width: 20%;
      label{
        width: 69px;
      }
    }
    .ddShelled{
      min-width: 22%;
      label{
        width: 60px;
      }
    }
    .txtCopiedToCropYear{
      min-width: 23%;
    padding-right: 0;
    }
    .txtShrinkPct{
      padding:0 ;
      max-width: 13%;
      min-width: 13%;
      label{
        width: 50px;
      }
    }
    .txtShrunkValue{
      min-width: 22%;
      padding: 0;
      label{
        width: 83px;
      }
    }
  }
  .grpbxReceiptInfo2{
    max-width: 60%;
    min-width: 60%;
    .card{
      border: none;
    }
    .txtLSKLbs,.txtTotalTons,.txtTotalLbs,.txtNetLbs,.txtLSKLbsShrunk,.txtTotalLbsShrunk,.txtTotalTonsShrunk,.txtNetLbsShrunk{
      min-width: 18%;
      max-width: 18%;

      padding: 0;
      .d-flex{
        display: grid !important;
        label{
          width: 100%;
          text-align: center;
        }
      }
      .col{
        padding-left: 0;
      }
    }
    .txtNetLbs{
      margin-left: 28%;
    }
    .txtShrnkPct{
      min-width: 28%;
      max-width: 28%;
      padding: 0;
      label{
        width: 100px;
      }
    }
    .grpbxReceiptInfo3{
      padding: 0;
      .card{
        border: none;
      }
      label{
        width: 83px;
        text-align: right;
      }
      .radioElectronicPaper{
        .mb-3{
          display: inline-flex;
          .form-check{
            padding-right: 1rem;
            label{
              width: auto;
            }
          }
        }
      }
    }
    
  }
  .grpbxPayoffDetails{
    max-width: 40%;
    min-width: 40%;
    .lblPayoffDetails{
      font-weight: 700;
    }
    .txtVendorNum{
      padding: 0;
      max-width: 27.5%;
      label{
        text-align: center;
        width: 100%;
      }
      .d-flex{
        display: grid !important;
      }
    }
    .txtVndrName{
      min-width: 62.5%;
      padding: 0;
    }
    .btnVendor{
      max-width: 10%;
    }
    .txtAmount{
      label{
        width: 150px;
        text-align: right;
      }
      input{text-align: right;}
    }
  }
  .grpbxFactorsForAllPeanuts{
    label{
      width: 95px;
      text-align: right;
    }
    .lblFactorsForAllPeanuts{
      label{
        width: 41%;
        text-align: center;
        font-size: 13px;
        font-weight: 700;
      }
    }
    .txtSMK,.txtTotalDamage ,.txtHullsBright,.txtELK,.txtCnclRMD,.txtSS,.txtTotalKernels,.txtLSK,.txtFancy,.txtFreezeDam,.txtTotalSMK,.txtHulls,.txtFM,.txtDisclrd,.txtJumbo,.txtOtherKernels,.txtMoist,.txtMoist,.txtCrackBroken,.txtTotalKernHulls{
      min-width: 18%;
      padding: 0;
    }
    .lblPct1,.lblPct2,.lblPct3,.lblPct4,.lblPct5,.lblPct6,.lblPct7,.lblPct8,.lblPct9,.lblPct10,.lblPct11,.lblPct12,.lblPct13,.lblPct14,.lblPct15,.lblPct16,.lblPct17,.lblPct18,.lblPct19{
      max-width: 1%;
      padding: 0;
      right: 1%;
    }
    .ddFlavus{
      min-width: 45%;
      label{
        width: 68%;
      }
    }
    .txtVICAM{
      min-width: 22%;
      padding-right: 0;
    }
    .lblPPB{
      padding: 0;
      label{
        text-align: left;

      }
    }
  }
  .grpbxStrgPrchs{
    min-width: 45%;
    padding: 0;
    .card{
      border: none;
    }
    .grpbxStorage{
      padding-left: 0.65rem;
      padding-right: 0;
      .card{
        border: 1px solid rgba(0, 0, 0, 0.125);
      }
      label{
        width: 140px;
        text-align: right;
      }
      .lblStorage{
        label{
          width: 100%;
          text-align: center;
          font-size: 13px;
          font-weight: 700;
        }
      }
      .datewidget-container{
        min-width: 57%;
        margin-left: 17%;
        padding: 0;
        .col{
          padding-right: 0;
        }
      }
    }
    .grpbxPurchase{
      .lblPurchaseInfo{
        label{
          width: 100%;
          text-align: center;
          font-size: 13px;
          font-weight: 700;
        }
      }
      .card{
        border: 1px solid rgba(0, 0, 0, 0.125);
      }
      label{
        width: 101px;
        text-align: right;
      }
      .grpbxPrchs1{
        padding: 0;
        label{
          text-align: left;
        }
        .card{
          border: none;
        }
        .radioRedemptionMethod{
          label{
            width: 100px;
          }
        }
      }
      .grpbxPrchs2{
        padding: 0;
        .card{
          border: none;
        }
        label{
          width: 96px;
        }
        .txtPremiumAmt,.txtDeductAmt,.txtProceedsAmt{
          padding-right: 10px;
        }
        .chkboxDeferredRedemption{
          label{
            width: 90%;
          }
          .form-check{
            label{
              display: none;
            }
          }
        }
      }
    }
  }
  .grpbxRepay{
    max-width: 55%;
    
    .grpbxRepayment{
      padding: 0;
      .lblRepayment{
        label{
          width: 100% ;
          text-align: center;
          font-size: 13px;
          font-weight: 700;
        }
      }
      label{
        width: 78px;
      }
      .card{
        border: none;
      }
    }
    .txtStrgCreditAmt,.txtLoanRepayAmt,.txtCalCNetRepayAmt{
      min-width: 59%;
      padding-right: 0;
      label{
        width: 157px;
        text-align: right;
      }
    }
    .txtInterestAmt{
      max-width: 99%;
      label{
        width: 157px;
        text-align: right;
      }
      input{
        margin-left: auto;
        width: 48.4%;
      }
    }
    .txtNetRepayAmt,.txtStrgCreditAmtOverride,.txtLoanRepayAmtOverride,.txtRepayVendor{
      max-width: 40%;
    }
    .txtRepayVendorNum{
      min-width: 40%;
      padding-right: 0;
    }
    .btnRepayVendor{
      max-width: 5%;
    }
    .txtCMAVendorNum{
      min-width: 40%;
      padding-right: 0;
    }
    .btnCMAVendor{
      max-width: 5%;
    }
    .txtCMAVendor,.txtRepayVendor{
      max-width: 54.5%;
      padding: 0;
      .col{
        padding-right: 0;
      }
    }
    .grpbxLoan{
      .card{
        border: none;
      }
      .txtMarketGain1{
        min-width: 48%;
        max-width: 48%;
        position: relative;
        left: 156px;
        label{
          width: 100px;
          text-align: right;
        }
        .col{
          padding-right: 0;
        }
      }
      .txtLnRpyLockInDt,.txtExpDate{
        min-width: 48%;
        max-width: 48%;
        position: relative;
        right: 50px;
        label{
          width: 100px;
          text-align: right;
        }
        .col{
          padding-right: 0;
        }
      }
      .txtLnRpyLockInDt {
        label{
          padding-left: 10%;
          margin-bottom: 0;
        }
        .col{
          padding-right: 0;
          .input-group{
            margin-top: 10px;
          }
        }
      }
      .txtMarketGain2{
        max-width: 52%;
        min-width: 52%;
        position: relative;
        left: 25px;
        label{
          width: 100px;
          text-align: right;
        }
        .col{
          padding-right: 0;
        }
      }
      .txtLoanAmt,.txtLoanDt{
        max-width: 52%;
        min-width: 52%; 
        position: relative;
        right: 110px;
        label{
          width: 100px;
          text-align: right;
        }
        .col{
          padding-right: 0;
        }
      }
      .txtMarketGain2{
        input{
          position: relative;
        }
        .col{
          padding-right: 0;
          padding-left: 115px;
        }
      }
      .grpbxLoanNum{
        padding: 0;
        min-width: 75%;
        max-width: 75%;
        .card{
          border: 1px solid rgba(0, 0, 0, 0.125);
        }
        .txtLnState,.txtLnCounty ,.txtLnNumber{
          padding: 0;
        }
        .txtLnState{
          min-width: 30%;
          max-width: 30%;
        }
        .txtLnCounty{
          min-width: 33%;
          max-width: 33%;
        }
        .txtLnNumber{
          min-width: 37%;
          max-width: 37%;
        }
      }
      .grpbxChkboxCntnr{
        padding: 0;
        max-width: 25%;
        .card-body{
          padding-right: 0;
        }
        label{
          width: 60px;
          text-align: right;
        }
        .form-check{
          label{
            display: none;
          }
        }
      }
    }
  }
  .grpbxActions{
    .card{
      background-color: #f3f0f0;
    }
    .btnCopyExternal{
      max-width: 11.5%;
    }
    .btnCreate{
      margin-left: 85%;
    }
    .btnClose,.btnCreate,.btnReversal,.btnRelease,.btnUpdate{
        max-width: 7%;
        margin-top: 0.25rem;
        button{
          width: 90%;
        }
    }
    .btnAcctDist{
      min-width: 7%;
      margin-right: 21%;
    }
    .btnDeliveryAgreement{
      max-width: 12%;
    }
    .lblChangedDate,.lblChangedBy{
      max-width: 15%;
      padding: 0;
      min-width: 15%;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblChangedDateValue,.lblChangedByValue{
      max-width: 35%;
      min-width: 35%;
      label{
        display: none;
      }
      .col{
        position: relative;
        bottom: 7px;
        padding-left: 0;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
