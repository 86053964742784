.SystemMaintenanceMasterManagement-FarmSetup,.ContractManagement-FarmSetup {
/*START_USER_CODE_FOR_.SCSS*/
h1{
  display:none
}
// background-color: #333E48;
background: transparent;
.grpbxFarmSetup {
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
  }
    
  .card-header{
    display:none
  }
  .card{
    border: none;
    background-color: #c0bcbc; 
    padding-top: 0.2rem;
    .card-body{
      width: 70%;
      margin-left: 15%;
    }
    
    .ddBuyingPoint {
      min-width: 100%;
      max-width: 100%;
      .d-flex{
        display: block !important;
        .col{
          padding: 0;
        }
      }
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      button{
      height:30px;
      }
    }
    
    
    .ddState,.ddCounty1  {
      min-width: 50%;
      max-width: 50%;
      .d-flex{
        display: block !important;
        .col{
          padding: 0;
        }
      }
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      button{
      height:30px;
      }
    }
    .ddFarms{
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      button{
      height:30px;
      }
    }

    
    .lblFarms,.lblVendors {
      min-width: 30%;
      label{
        margin-bottom: 0;
      }
    }
    .btnAddFarm,.btnAddVendor{
      margin-left: auto;
    }
    .btnDeleteFarm,.btnDeleteVendor{
      margin-right: 1rem;
    }
    .btnAddFarm ,.btnEditFarm,.btnDeleteFarm,
    .btnAddVendor ,.btnEditVendor,.btnDeleteVendor{
      min-width: 10%;
      max-width: 10%;
      margin-bottom: 0.2rem !important;
      margin-top: 1rem;
      .row{
        justify-content: end;
        button{
          width: 95%;
        }
      }
    }
    .btnUp,.btnDown{
      max-width: 5%;
      min-width: 5%;
      margin-bottom: 0.2rem !important;
      button{
        width: 95%;
      }
    }
    .ddFarms {
      .d-flex{
        .col{
          padding: 0;
        }
      }
    }
    .lstVendors {
      min-width: 100%;
      padding: 0;
    }
  }
}
.cmmndCntnrActions {
  .row{
    padding-top: 0.2rem;
    background-color: #f3f0f0;
    .btnSave,.btnClose{
      min-width: 10%;
      max-width: 10%;
      button{
        width: 90%;
      }
    }
    .btnSave{
      margin-left: 33%;
    }
    .btnGovFarmData {
      margin-left: 32%;
      min-width: 13%;
      max-width: 13%;
      .row{
        justify-content: end;
        button{
          width: 90%;
        }
      }
    }
  }
}
/*END_USER_CODE_FOR_.SCSS*/
}
