.SystemMaintenanceMasterManagement-FreightLocToLocProfile,.ContractManagement-FreightLocToLocProfile {
 /*START_USER_CODE_FOR_.SCSS*/
 h1{
  display:none
}
// background-color: #333E48;
background: transparent;
.grpbxFreightLocToLoc {
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
    }
    .dropdown-toggle:empty::after {
      margin-left: auto;
    }
    button{
    height:30px;
    }  
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #c0bcbc;
      .radioType{
        min-width: 50%;
        max-width: 50%;
        margin-left: 10%;
        border: 1px solid #f3f0f0;
        border-radius: 0;
        margin-bottom: 0.5rem !important;
        .d-flex{
          display: block !important;
        }
        .col{
          padding-left: 3rem;
          margin-bottom: 1rem;
          .mb-3{
            display: flex;
            flex-wrap: wrap;
            .form-check{
              width: 50%;
              input{
                bottom: 2px;
              }
            }
          }
        }
      }
      .ddOrigBuyPt ,.ddDestBuyPt {
        min-width: 60%;
        max-width: 60%;
        label{
          width: 150px;
          text-align: right;
          color: #c02020;
        }
      }
      .ddState ,.ddCounty,.ddFarm {
        min-width: 40%;
        max-width: 40%;
        label{
          width: 70px;
          text-align: right;
          color: #c02020;
        }
      }
      .ddState ,.ddCounty{
        .dropdown{
          width: 60%;
        }
      }
      .dtEffDate ,.txtMiles {
        min-width: 35%;
        max-width: 35%;
        margin-right: 25%;
        padding-right: 0;
        label{
          width: 150px;
          text-align: right;
          color: #c02020;
        }
      }
      .lblNote {
        min-width: 40%;
        max-width: 40%;
        label{
          padding-left: 85px;
          color: blue;
        }
      }
      .lblEntrInfo{
        min-width: 100%;
        label{
          padding-left: 165px;
          color: black;
          font-weight: bold;
        }
      }
      .txtCarrAbbr ,.ddRateBasis ,.txtRate ,.ddNoLoadRateBasis ,.txtNoLoadRate {
        min-width: 35%;
        max-width: 35%;
        margin-right: 60%;
        padding-right: 0;
        label{
          width: 150px;
          text-align: right;
        }
      }
      .ddRateBasis,.ddNoLoadRateBasis{
        #ddItem_{
          height: 17px;
        }
        .dropdown-menu.show {
          overflow-y: scroll !important;
          max-height: 15vh !important;
          }
      }
      .txtNoLoadWt ,.txtMinWt {
        min-width: 35%;
        max-width: 35%;
        padding-right: 0;
        label{
          width: 150px;
          text-align: right;
        }
      }
      .lblPounds ,.lblPounds2 {
        min-width: 60%;
        max-width: 60%;
        padding-left: 0;
        label{
          color: blue;
        }
      }
    }
}
.grpbxActions {
  .card{
    background-color: #f3f0f0;
    .card-header{
      display: none;
    }
    .txtAddedBy ,.txtChngdBy {
      min-width: 50%;
      max-width: 50%;
      label{
        width: 70px;
        text-align: right;
      }
      input{
        box-shadow: none;
        border: none;
        background: transparent;
      }
    }
    .btnOk{
      margin-left: 30%;
    }
    .btnCancel,.btnOk{
      min-width: 10%;
      max-width: 10%;
      top: 15px;
      button{
        width: 90%;
      }
    }
  }
}
/*END_USER_CODE_FOR_.SCSS*/
}
