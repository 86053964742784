.SystemMaintenanceApplicationSupport-InventoryInquiry,.ContractManagement-InventoryInquiry {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxInventoryInquiry     {
    .card-header{
      display:none
    }
    .dropdown-menu.show{
      overflow-y:scroll!important;
      max-height:25vh!important;
      }
    .card{
      border: none;
      background-color: #c0bcbc;
    }
    .grpbxLocation {
      min-width: 30%;
      
      .lblLocation{
        max-width: 30%;
        margin-bottom: 0;
        label{
          text-align: left;
          font-weight: bold;
          margin-bottom: 0;
        }
      }
      .ddBinNum{
        label{
        display: none;
        }
        button{
          display: none;
        }
      }
      .dropdownfieldwidget-container{
        min-width: 100%;
        label{
          width: 70px;
          text-align: right;
        }
        .dropdown{
          width: 80%;
        }
      }
      .chkboxGrpByBuyPt,.chkboxShowGrades  {
        min-width: 50%;
        max-width: 50%;
        padding-left: 0;
        .col{
          padding-left: 20%;
          input{
            bottom: 2px;
          }
        }
      }
    }
    .grpbxPeanutCriteria {
     
      min-width: 30%;
      label{
        width: 50px;
        text-align: right;
      }
      .lblPeanutCriteria   {
        padding-left: 0;
        min-width: 75%;
        label{
          width: 100%;
          text-align: left;
          font-weight: bold;
          margin-bottom: 0;
        }
      }
      .ddSeed,.ddGen,.ddOrganic{
        max-width: 50% ;
        min-width: 50% ;
      }
      .ddOleic,.ddSeg{
        min-width: 100%;
        .dropdown{
          width: 60%;
        }
      }
      .ddVariety ,.ddType {
        min-width: 100%;
        .dropdown{
          width: 80%;
        }
      }
      .chkboxEdibleCrushing {
        .col{
          padding-bottom: 0.5rem;
          .mb-3{
            display: flex;
            .form-check{
              width: 80px;
              label{
                text-align: left;
              }
              input{
                bottom: 2px;
              }
            }
          }
        }
      }
    }
    .grpbxSearchResults {
     
      .lblSearchResults{
        padding-left: 0;
        max-width: 10%;
        label{
          font-weight: bold;
          text-align: left;
          margin-bottom: 0 !important;
        }
      }
      .btnMakeAdjustments{
        max-width: 15%;
        margin-bottom: 0.3rem !important;
      }
      .btnSearch{
        max-width: 10%;
        margin-left: 20%;
        margin-bottom: 0.3rem !important;
      }
      .gridSearchResults {
        padding: 0;
        .card-header{
          display: block;
        }
        .rdt_TableCol:nth-child(1){
          min-width: 250px;
          max-width: 250px;
        //  background-color: #c9c9c9;
         font-weight: bold;
          justify-content: center;
        }
        .rdt_TableCell:nth-child(1),.rdt_TableCell:nth-child(2),.rdt_TableCell:nth-child(3){
          background-color: #c9c9c9;
        }
        .rdt_TableCell:nth-child(1){
          min-width: 250px;
          max-width: 250px;
        //  background-color: #c9c9c9;
         font-weight: bold;
          justify-content: left; 
        }
        .rdt_TableCol:nth-child(2),
        .rdt_TableCell:nth-child(2){
          min-width: 55px;
          max-width: 55px;
        //  background-color: #c9c9c9;
         font-weight: bold;
        }
        .rdt_TableCol:nth-child(3),
        .rdt_TableCell:nth-child(3){
          min-width: 48px;
          max-width: 48px;
        //  background-color: #c9c9c9;
         font-weight: bold;
        }
        .rdt_TableCol:nth-child(4){
          min-width: 88px;
          max-width: 88px;
          justify-content: center;
        }
        .rdt_TableCell:nth-child(4){
          min-width: 88px;
          max-width: 88px;
          justify-content: right; 
        }
        .rdt_TableCol:nth-child(5){
          min-width: 98px;
          max-width: 98px;
          justify-content: center;
        }
        .rdt_TableCell:nth-child(5){
          min-width: 98px;
          max-width: 98px;
          justify-content: right; 
        }
        .rdt_TableCol:nth-child(6),
        .rdt_TableCol:nth-child(7),
        .rdt_TableCol:nth-child(8),
        .rdt_TableCol:nth-child(9),
        .rdt_TableCol:nth-child(10),
        .rdt_TableCol:nth-child(11),
        .rdt_TableCol:nth-child(20),
        .rdt_TableCol:nth-child(21),
        .rdt_TableCol:nth-child(22),
        .rdt_TableCol:nth-child(23),
        .rdt_TableCol:nth-child(24),
        .rdt_TableCol:nth-child(25),
        .rdt_TableCol:nth-child(26),
        .rdt_TableCol:nth-child(27),
        .rdt_TableCol:nth-child(28),
        .rdt_TableCol:nth-child(29),
        .rdt_TableCol:nth-child(30),
        .rdt_TableCol:nth-child(31),
        .rdt_TableCol:nth-child(32),
        .rdt_TableCol:nth-child(33),
        .rdt_TableCol:nth-child(34),
        .rdt_TableCol:nth-child(35),
        .rdt_TableCol:nth-child(36),
        .rdt_TableCol:nth-child(37),
        .rdt_TableCol:nth-child(38)
        {
          justify-content: center;
        }
        .rdt_TableCell:nth-child(6){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(7){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(8){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(9){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(10){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(11){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(20){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(21){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(22){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(23){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(24){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(25){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(26){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(27){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(28){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(29){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(30){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(31){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(32){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(33){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(34){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(35){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(36){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(37){
          justify-content: right; 
        }
        .rdt_TableCell:nth-child(38){
          justify-content: right; 
        }
      }
    }
  }
  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top:0.25rem;
    }

    .buttonwidget-container{
      max-width: 10%;
      button{
        width: 95%;
      }
    }
    
    .btnClose{
      max-width: 10%;
      margin-left: 45%;
    }
    .btnExportToExcel {
      margin-left: 21%;
      max-width: 12%;
    }
    .btnPrint{
      margin-right: 2%;
      max-width: 10%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
