.WarehouseReceipts-ReportPreviewWR,.ContractManagement-ReportPreviewWR {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333e48;
  background: transparent;
  h1 {
    display: none;
  }
  .grpbxHeader{
    padding: 0 !important;
  }
  .card {
    background-color: #f3f0f0;
    border: none;
  }
  .card-header{
    display: none;
  }
  .cmmndCntnrActions{
    padding: 0;
    .row{
      justify-content: center;
    }
    .btnPrintSetup,.btnSaveAsPDF{
      max-width: 9%;
    }
    .btnPrint{
      max-width: 6%;
    }
    .btnClose {
      max-width: 7%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}