.SystemMaintenanceMasterManagement-VendorProfile,.ContractManagement-VendorProfile{
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxVendorDetails{
    .card-header{
      display: none;
    }
    .card{
      background-color:#c0bcbc ;
    }
    label{
      width: 80px;
      text-align: right;
    }
    .txtFarm{
      max-width: 75%;
    }
    .txt211GPC{
      display: flex !important;
      justify-content: right;
      .d-flex{
        max-width: 22%;
      }
    }
    .btnVendorMaintenance{
      max-width: 8%;
      top: 15px;
    }
    .txt211Concordia{
      min-width: 41%;
      input{
        max-width: 22%;
      }
    }
    .txtVendor1{
      
        position: relative;
        right: 36px;
      
    }
    .btnVendor{
      min-width: 31.6%;
      .row {
        position: relative;
        top: 4px;
      }
    }
    .txtRemitTo{
      max-width: 10%;
    }
    .ddRemitTo{
      max-width: 75%;
    }
    .txtSharePercent{
      input{
        max-width: 15%;
      }
    }
    .grpbxSuffix{
      max-width: 10%;
      .card{
        border: none;
      }
      .card-header{
        display: none;
      }
    }

  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color:#f3f0f0;
    }
    label{
      width: 70px;
      text-align: right;
    }
    .txtAddedBy{
      min-width: 85%;
      input{
        max-width: 30%;
        min-width: 30%;
      }
    }
    .btnOk{
      max-width: 8%;
    }
    .btnCancel{
      max-width: 6%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
