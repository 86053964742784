.SystemMaintenanceApplicationSupport-ManualDPRInvHistView,.ContractManagement-ManualDPRInvHistView {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
   display:none
 }
//  background-color: #333E48;
background: transparent;
 .grpbxManualDPR {
   .dropdown-menu.show {
     overflow-y: scroll !important;
     max-height: 25vh !important;
     }
   .card{
     padding-top: 0.2rem !important;
     border: none;
     background-color: #f3f0f0;
     .card-header{
       display:none
     }
     label{
       width: 80px;
       text-align: right;
     }
     .textboxwidget-container{
       padding-right: 0;
     }
     .txtAction{
       min-width: 25%;
       max-width: 25%;
     }
     .txtUser {
       min-width: 45%;
       max-width: 45%;
       label{
         width: 50px;
       }
     }
     .dtTime {
       min-width: 30%;
       max-width: 30%;
       padding-right: 0;
     }
     .txtBuyngPt{
       max-width: 50%;
       min-width: 50%;
       margin-right: 50%;
     }
     .txtReason {
       min-width: 100%;
       max-width: 100%;
     }
     .txtAudit ,.txtSourceTxt {
       max-width: 50%;
       min-width: 50%;
     }
   }
 }
 .grpbxBefore {
   padding-right: 0.125rem;
   .card{
     padding-top: 0.2rem !important;
     border: none;
     background-color: #f3f0f0;
     .card-header{
       display:none
     }
     .lblBefore {
       min-width: 100%;
       max-width: 100%;
       label{
         margin-bottom: 0;
         width: 100%;
         font-weight: bold;
         text-align: center;
       }
     }
     .textboxwidget-container{
       padding: 0;
       min-width: 100%;
       label{
         width: 120px;
         text-align: right;
       }
       .col{
         padding-right: 0.5rem;
         input{
           width: 70%;
         }
       }
     }
     .txtWhs {
       .col{
         input{
           width: 100%;
         }
       }
     }
   }
 }
 .grpbxBeforeuic0 {
   padding-left: 0.125rem;
   .card{
     padding-top: 0.2rem !important;
     border: none;
     background-color: #f3f0f0;
     .card-header{
       display:none
     }
     .lblAfter {
       min-width: 100%;
       max-width: 100%;
       label{
         margin-bottom: 0;
         width: 100%;
         font-weight: bold;
         text-align: center;
         color: blue;
       }
       input{
         color: blue;
       }
     }
     .textboxwidget-container{
       padding: 0;
       min-width: 100%;
       label{
         width: 120px;
         text-align: right;
         color: blue;
       }
       .col{
         padding-right: 0.5rem;
         input{
           width: 70%;
           color: blue;
         }
       }
     }
     .txtWhsAftr {
       .col{
         input{
           width: 100%;
           color: blue;
         }
       }
     }
   }
 }
 
 .cmndCntnrManDPR {
   .row{
     background-color: #f3f0f0;
     padding-top: 0.2rem;
   }
   .btnExit{
     max-width: 10%;
     min-width: 10%;
     margin-left: 45%;
     button{
       width: 100%;
     }
   }
 }
 /*END_USER_CODE_FOR_.SCSS*/
 }
 