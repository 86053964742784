.Settlements-ContApplEditAuthorize,.ContractManagement-ContApplEditAuthorize {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .grpbxContApplEditAuthorize       {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #f3f0f0;
    }
    label{
      width: 40%;
      text-align: right;
    }
   
  }
  .cmmndCntnrActions  {
  
    .row{
      border: none;
      background-color:  #f3f0f0;
      padding-top: 0.3rem;
    }
    .btnCancel{
      min-width: 10%;
      max-width: 10%;
      button{
        width: 90%;
      }
      
      
    }
    
    .btnCreateAuthorization {
      margin-left: auto;
      min-width: 20%;
      max-width: 20%;
      button{
        width: 90%;
      }
    }
  }
  .ddUserID,.ddBuyingPoint{
    button{
      width: 52%;
    }
  }
 .txtSC95{
  input{
    width: 35%;
  }
 }
  .ddUserID{   
    .dropdown-menu.show{
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
  }
  .ddBuyingPoint{   
    .dropdown-menu.show{
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
