.WarehouseReceipts-DelvSettlePaymentView,.ContractManagement-DelvSettlePaymentView {
    /*START_USER_CODE_FOR_.SCSS*/
    h1{
      display: none;
    }
    // background-color: #333E48;
  background: transparent;
    .groupboxwidget-container{
      .card{
        border: none;
        background-color:  #f3f0f0;
        .card-header{
          display: none;
        }
      }
    }
    .grpbxDelvSettlePaymentView  {
      .card{
        .lblPayments{
          max-width: 50%;
          min-width: 50%;
          margin-bottom: 0 !important;
          label{
            margin-bottom: 0 !important;
          }
        }
        
        .gridPayments {
          min-width: 100%;
          .rdt_TableCell:nth-child(1),.rdt_TableCell:nth-child(6),
          .rdt_TableCell:nth-child(2),.rdt_TableCell:nth-child(7),
          .rdt_TableCell:nth-child(3),.rdt_TableCell:nth-child(8),
          .rdt_TableCell:nth-child(4),.rdt_TableCell:nth-child(9),
          .rdt_TableCell:nth-child(5),.rdt_TableCell:nth-child(10),
          .rdt_TableCell:nth-child(11){
            justify-content: right !important;
          }
          .rdt_TableCol:nth-child(1),.rdt_TableCol:nth-child(2),.rdt_TableCol:nth-child(10),
          .rdt_TableCol:nth-child(3),.rdt_TableCol:nth-child(11),
          .rdt_TableCol:nth-child(4),
          .rdt_TableCol:nth-child(5),.rdt_TableCol:nth-child(6),
          .rdt_TableCol:nth-child(7),.rdt_TableCol:nth-child(8),
          .rdt_TableCol:nth-child(9)
          {
            position: relative;
            top: 15px;
          }
          .rdt_TableCol:nth-child(5),.rdt_TableCol:nth-child(6),.rdt_TableCol:nth-child(8){
            justify-content: center !important;
            text-align: center;
          }
          .rdt_TableCol:nth-child(6),
          .rdt_TableCell:nth-child(6) {
            min-width: 100px;
            max-width: 100px;
          }
          .rdt_TableCol:nth-child(11),
          .rdt_TableCell:nth-child(11) {
            min-width: 110px;
            max-width: 110px;
          }
          .rdt_TableCol:nth-child(10),
          .rdt_TableCell:nth-child(10) {
            min-width: 110px;
            max-width: 110px;
          }
        }
        
      }
    }
    .cmmndCntnrActions  {
      .row{
        padding-top: 0.25rem;
        background-color: #f3f0f0;
        .btnClose{
          min-width: 10%;
          max-width: 10%;
          margin-left: 35%;
          button{
            width: 90%;
          }
        }
        .btnExportToExcel{
          min-width: 12%;
          max-width: 12%;
          margin-left: 25%;
          button{
            width: 90%;
          }
        }
        .btnPrint{
          min-width: 10%;
          max-width: 10%;
          button{
            width: 90%;
          }
        }
      }
    }
    /*END_USER_CODE_FOR_.SCSS*/
  }
  