.SystemMaintenanceSpecialFunctions-DiscountScheduleAuditDetail,
.ContractManagement-DiscountScheduleAuditDetail {
  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }
  // background-color: #333e48;
  background: transparent;
  .grpbxDiscountScheduleAuditDetail {
    .card {
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header {
        display: none;
      }
      .gridDiscountScheduleAudit {
        min-width: 100%;
        label {
          display: none;
        }
        .card {
          border: 1px solid #f3f0f0;
          padding-top: 0 !important;
        }
        .rdt_TableCol:nth-child(1),
        .rdt_TableCell:nth-child(1) {
          min-width: 87px;
          max-width: 87px;
        }
        .rdt_TableCell:nth-child(2),
        .rdt_TableCell:nth-child(3),
        .rdt_TableCell:nth-child(4) {
          justify-content: right !important;
        }
        .rdt_TableCol:nth-child(8),
        .rdt_TableCell:nth-child(8) {
          min-width: 160px;
          max-width: 160px;
        }
        .rdt_TableCol:nth-child(7),
        .rdt_TableCell:nth-child(7) {
          min-width: 127px;
          max-width: 127px;
        }
        .rdt_TableCell:nth-child(5),
        .rdt_TableCell:nth-child(6),
        .rdt_TableCell:nth-child(7),
        .rdt_TableCell:nth-child(8) {
          color: black;
          // font-weight: 700;
        }
        .rdt_TableCell:nth-child(6),
        .rdt_TableCell:nth-child(7),
        .rdt_TableCell:nth-child(8) {
          justify-content: right !important;
          background-color: rgb(238, 221, 167);
        }
        .rdt_TableCell:nth-child(5) {
          background-color: rgb(238, 221, 167);
        }
        .rdt_TableCol:nth-child(1),
        .rdt_TableCol:nth-child(5) {
          position: relative;
          top: 15px;
        }

        // .rdt_TableCol:nth-child(2),
        // .rdt_TableCell:nth-child(2){
        //   min-width: 87px;
        //   max-width: 87px;
        //   text-align: center !important;
        // }
        // .rdt_TableCol:nth-child(3),
        // .rdt_TableCell:nth-child(3){
        //   min-width: 87px;
        //   max-width: 87px;
        //   text-align: center !important;
        // }
        // .rdt_TableCol:nth-child(4),
        // .rdt_TableCell:nth-child(4){
        //   min-width: 87px;
        //   max-width: 87px;
        //   text-align: center !important;
        // }
        // .rdt_TableCol:nth-child(5),
        // .rdt_TableCell:nth-child(5){
        //   min-width: 87px;
        //   max-width: 87px;
        //   text-align: center !important;
        // }
        // .rdt_TableCol:nth-child(6),
        // .rdt_TableCell:nth-child(6){
        //   min-width: 87px;
        //   max-width: 87px;
        //   text-align: center !important;
        // }
        // .rdt_TableCol:nth-child(7),
        // .rdt_TableCell:nth-child(7){
        //   min-width: 87px;
        //   max-width: 87px;
        //   text-align: center !important;
        // }
        // .rdt_TableCol:nth-child(8),
        // .rdt_TableCell:nth-child(8){
        //   min-width: 87px;
        //   max-width: 87px;
        //   text-align: center !important;
        // }
      }
    }
  }

  .cmmndCntnrClose {
    .row {
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btnClose {
      max-width: 10%;
      min-width: 10%;
      margin-left: 45%;
      button {
        width: 100%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
