.SystemMaintenanceSpecialFunctions-VendorWireTransfer,.ContractManagement-VendorWireTransfer {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxVendorWireTransfer {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #c0bcbc; 
      padding-top: 0.2rem;
      label{
        width: 70px;
        text-align: right;
      }
      .txtVendorNum {
        max-width: 30%;
        min-width: 30%;
        padding-right: 0;
        label{
          color: #c02020;
        }
        .col{
          padding-right: 0;
        }
      }
      .btnVendor{
        max-width: 5%;
        min-width: 5%;
        padding: 0;
        margin-bottom: 0.3rem !important;
        .row{
          justify-content: center;
        }
      }
      .txtVndr{
        padding: 0;
        max-width: 60%;
        min-width: 60%;
        .col{
          padding: 0;
        }
        input{
          color: blue;
          background: transparent;
          border: none;
          box-shadow: none;
        }
      }
      .txtName {
        min-width: 100%;
        input{
          width: 80%;
          background: transparent;
          color: blue;
        }
      }
    }
  }
  .cmmndCntnrActions {
    .row{
      padding-top: 0.2rem;
      background-color: #f3f0f0;
      .btnSelect,.btnCancel{
        min-width: 10%;
        max-width: 10%;
        .row{
          button{
            width: 90%;
          }
        }
      }
      .btnSelect{
        margin-left: 80%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
