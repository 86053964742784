@media print {
  body {
     -webkit-print-color-adjust: exact;
  }
}
.ContractManagement-AccountDistributionActivity {
  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  h1 {
    display: none;
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .card-header {
    display: none;
  }
  .grpbxAccntDstrbtn {
    .card {
      background-color: #c0bcbc;
    }
    .lblAccountDistribution {
      font-size: 14px;
      font-weight: 500;
      
    }
  }
  .gridAccountDistribution {
    
    .card {
      background-color: #777474;
    }
    .rdt_TableHeadRow {
      background-color: lightgray;
    }
    .rdt_TableCol {
      border: solid rgba(#777474, 0.1);
    }
    .rdt_TableCol:nth-child(7),
    .rdt_TableCell:nth-child(7),
    .rdt_TableCol:nth-child(8),
    .rdt_TableCell:nth-child(8) {
      min-width: 150px;
    }
    .rdt_TableCol:nth-child(1),
    .rdt_TableCell:nth-child(1) {
      min-width: 150px;
    }
    .rdt_TableCol:nth-child(2),
    .rdt_TableCell:nth-child(2),
    .rdt_TableCol:nth-child(3),
    .rdt_TableCell:nth-child(3),
    .rdt_TableCol:nth-child(4),
    .rdt_TableCell:nth-child(4),
    .rdt_TableCol:nth-child(9),
    .rdt_TableCell:nth-child(9) {
      min-width: 100px;
    }
    .rdt_TableCol:nth-child(9),
    .rdt_TableCell:nth-child(9)
    {
      justify-content: right !important;
    }
    .rdt_TableCol:nth-child(10),
    .rdt_TableCell:nth-child(10)
    {
      justify-content: right !important;
    }
    .rdt_TableCol:nth-child(14), 
    .rdt_TableCell:nth-child(14) { 
       min-width: 200px;
    }
    .rdt_TableCell:nth-child(1),
    .rdt_TableCell:nth-child(3),
    .rdt_TableCell:nth-child(4),
    .rdt_TableCell:nth-child(6)
     {
      justify-content: left !important;
    }
    .rdt_TableCell:nth-child(2),
    .rdt_TableCell:nth-child(4),
    .rdt_TableCell:nth-child(5),
    .rdt_TableCell:nth-child(7),
    .rdt_TableCell:nth-child(8),
    .rdt_TableCell:nth-child(11),
    .rdt_TableCell:nth-child(12) {
      justify-content: right !important;
    }
  }
  .grpbxClosePrint {
    .card {
      border: none !important;
      background-color: #f3f0f0;
    }
    .btnClose,.btnPrintScreen{
      .row{
        justify-content: end;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
