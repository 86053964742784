.SystemMaintenanceMasterManagement-FreightRateProfile,.ContractManagement-FreightRateProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .cmmndCntnrActions{
  .row{
    background-color: #f3f0f0;
    }
  }
  .grpbxFreightProf{
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card-header{
      display: none;
    }
    .card{
      background-color: #c0bcbc;
    }
    label{
      width: 18%;
      text-align: right;
    }
    .chkbxNoLoad{
      label{
        display: none;
      }
      .form-check{
        padding-left: 20%;
        label{
          display: block;
          width: 100%;
          text-align: left;
          color: rgb(224, 15, 15);
        }
      }
    }
    .ddDestByngPt{
      .dropdown{
        max-width: 71%;
      }
      label{
        color: rgb(224, 15, 15);
      }
    }
    .txtCarrAbbr,.dtEffective{
      input,div#dtEffectiveParent{
        max-width: 35%;
      }
    }
    .dtEffective{
      div#dtEffectiveParent{
        max-width: 86%;
      }
      label{
        color: rgb(224, 15, 15);
      }
    }
    
    .ddRateBs{
      .dropdown{
        max-width: 55%;
      }
    }
    .txtMinWght{
      min-width: 65%;
      label{
        width: 27.7%;
      }
      
    }
    .lblPounds{
      max-width: 25%;
      padding-left: 0;
      label{
        // width: 100%;
        text-align: left;
        position: relative;
        right:20px;
        color: blue;
      }
    }
    .gridFrghtRate{
      max-width: 60%;
    min-width: 60%;
    margin-left: 18.5%;
    .card-header{
      display: block;
    }
    }
    .btnPrint{
      max-width: 20%;
    }
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color: #f3f0f0;
    }
    .lblAddedBy{
      max-width: 8%;
    }
    .lblAddedByVal {
      min-width:52.5%;
      .col{
        position: relative;
        bottom: 7px;
        right: 8%;
      }
    }
    .btnCpyLoc{
      max-width: 10%;
    }
    .btnCpyCarr{
      max-width: 9%;
    }
    .btnCopyRate{
      max-width: 8%;
    }
    .btnOK,.btnClose{
      max-width: 6%;
    }
    .lblChangedByVal{
      max-width: 25%;
      min-width: 25%;
      .col{
        position: relative;
    bottom: 7px;
    right: 58%;
      }
    }
    .lblChangedBy{
      padding:0;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
