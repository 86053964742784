.SystemMaintenanceMasterManagement-PremiumDeductionScheduleProfile,.ContractManagement-PremiumDeductionScheduleProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
.grpbxPremiumDeductionScheduleProfile{
  
  .card{
    padding-top: 0.5rem;
    border: none;
    background-color: #c0bcbc;
    .card-header{
      display:none
    }
    .grpbxProfileDetails{
      border: 1px solid #f3f0f0;
      label{
        width: 200px;
        text-align: right;
        color: #c02020;
      }
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
        }
      .lblBuyingPoint{
        max-width: 13%;
        padding-right: 0;
      }
      .lblBuyingPointValue{
        padding-left: 0
        ;
        label{
          text-align: left;
        }
      }
      .chkboxEnableSEG {
        max-width: 30%;
        padding-right: 0;
        .col{
          max-width: 35%;
        }
        .form-check {
        label{
          text-align: left;
          color: #495057;
          width: 80px;
        }
      }
      }
      .ddApplyType{
        margin-left: 20%;
        align-items: start !important;
      }
      .textboxwidget-container{
        input{
          width: 30%;
        }
      }
      .txtBasePercentage ,.txtBaseRate ,.ddBaseWeight ,.txtBuyingPoint{
        label{
          color: #495057;
        }
      }
      .txtBuyingPoint{
        .col{
          padding: 0;
          input{
            box-shadow: none;
            border: none;
            background: transparent;
            color: blue;
          }
        }
      }
    }
    .grpbxPayee{
      border: 1px solid #f3f0f0;
      label{
        width: 120px;
        text-align: right;
      }
      .txtPayeeVendor{
        max-width: 25%;
        margin-left: 25%;
        padding-right: 0;
      }
      .btnPayeeVendor{
        max-width: 3%;
        margin-bottom: 0.3rem !important;
      }
      .txtVndr {
        margin-left: 25%;
        max-width: 50%;
        .col{
          padding-left: 122px;
          input{
            background:transparent;
            box-shadow: none;
            border: none;
            color: blue;
            padding-top: 0;
          }
        }
      }
      
      .ddPayeeRemitTo{
        max-width: 60%;
        margin-left: 25%;
      }
    }
    .grpbxVendors{
      border: 1px solid #f3f0f0;
      .lblVendors{
        margin-left: 2%;
        margin-bottom: 0 !important;
      }
      .btnAddVendor{
        margin-left: auto;
        max-width: 15%;
        min-width: 15%;
        button{
          width: 95%;
        }
      }
      .btnDeleteVendor{
        max-width: 15%;
        min-width: 15%;
        margin-right: 3%;
        button{
          width: 95%;
        }
      }
    }
  
    .grpbxContracts{      
      border: 1px solid #f3f0f0;
      .lblContracts{
        margin-left: 2%;
        margin-bottom: 0 !important;
      }
      .btnAddContract{
        margin-left: auto;
        max-width: 15%;
        button{
          width: 95%;
        }
      }
      .btnDeleteContract{
        max-width: 15%;
        margin-right: 3%;
        button{
          width: 95%;
        }
      }
    }
  }
  
  
}
.grpbxActions{
  .card-header{
    display: none;
  }
  .card{
    background-color: #f3f0f0;
  }
  .lblAddedBy,.lblChangedBy{
    max-width: 8%;
    padding: 0;
    label{
      width: 100%;
      text-align: right;
    }
  }
  .lblAddedByValue,.lblChangedByValue{
    min-width: 77%;
    label{
      display: none;
    }
    .col{
      padding: 0;
      bottom: 7px;
    }
  }
  .btncmdEnableDisableControls{
    display: none;
    button{
      height: 40px;
    }
    label{
      display: none;
    }
  }
  .btnOk,.btnCancel{
     max-width: 7.5%;
     min-width: 7.5%;
     top: 10px;
     button{
       width: 90%;
     }
  }
}

  /*END_USER_CODE_FOR_.SCSS*/
}
