.SystemMaintenanceMasterManagement-PeanutSpecificationSetup,.ContractManagement-PeanutSpecificationSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxPeanutSpecificationSetup {
    .dropdown-menu.show{
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
    .card{
      padding-top: 0.2rem !important;
      border: none;
      background-color: #c0bcbc;
      .card-header{
        display:none
      }
      .labelwidget-container{
        max-width: 15%;
        margin-bottom: 0;
        padding-right: 0;
        label{
          margin-bottom: 0;
        }
      }
      .gridSpecifications{
        .rdt_TableCell:nth-child(1),
        .rdt_TableCell:nth-child(2),
        .rdt_TableCell:nth-child(3),
        .rdt_TableCell:nth-child(4) {
          justify-content: right !important;
        }
      }
      .gridSpecificationExpectations{
        .rdt_TableCell:nth-child(1),
        .rdt_TableCell:nth-child(5),
        .rdt_TableCell:nth-child(3),
        .rdt_TableCell:nth-child(4) {
          justify-content: right !important;
        }
        .rdt_TableCol:nth-child(2),
        .rdt_TableCell:nth-child(2) {
          min-width: 145px;
          max-width: 145px;
        }
      }
      .dropdownfieldwidget-container{
        min-width: 50%;
        .d-flex{
          display: block !important;
          .col{
            padding: 0;
          }
        }
      }
      .buttonwidget-container{
        max-width: 7.5%;
        margin-bottom: 0.1rem !important;
       .row{
        justify-content: center;
        button{
          width: 95%;
        }
       }
      }
      .btnAddS,.btnAddSE{
        margin-left: 11.5%;
      }
      .btnDeleteS,.btnDeleteSE{
        margin-right: 1%;
      }
      .gridwidget-container{
        max-width: 50%;
        min-width: 50%;
      }
    }
    
  }
  

  .cmmndCntnrActions{
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btncmdEnableDisableControls {
      margin-left: 2%;
      min-width: 18%;
      max-width: 18%;
      button{
        width: 100%;
      }
      display: none;
    }
    .btnClose{
      max-width: 10%;
      min-width: 10%;
      margin-left: 45%;
      button{
        width: 60%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
