.SystemMaintenanceMasterManagement-PeanutTypeProfile ,.ContractManagement-PeanutTypeProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
.grpbxPeanutTypeProfile{
  .card-header{
    display: none;
  }
  .card{
    background-color: #c0bcbc;
  }
  label{
    width: 350px;
    text-align: right;
  }
  .txtID{
    label{
      color: red;
    }
    input{
      max-width: 57%;
    }
  }
  .txtAccountSubDepartment{
    label{
      color: red;
    }
  }
  .grpboxFlexMarketAllowed{
    label{
      text-align: left;
    }
  }
  .txtName{
    label{
      color: red;
    }
    input{
      max-width: 35%;
    }
  }
  .txtOkGuage,.txtSMK1Guage,.txtSMK2Guage,.txtSMK3Guage,.txtSMK4Guage,.txtLSKGuage,.txtAccountSubDepartment{
    input{
      max-width: 15%;
    }
  }
}
.grpbxActions{
  .card-header{
    display: none;
  }
  .card{
    background-color: #f3f0f0;
  }
  .lblAddedBy,.lblChangedBy{
    max-width: 8%;
    padding: 0;
    label{
      width: 100%;
      text-align: right;
    }
  }
  .lblAddedByValue,.lblChangedByValue{
    min-width: 77%;
    label{
      display: none;
    }
    .col{
      padding: 0;
      bottom: 7px;
    }
  }
  .btnOk,.btnCancel{
     max-width: 7.5%;
     min-width: 7.5%;
     top: 10px;
     button{
       width: 90%;
     }
  }
}
  /*END_USER_CODE_FOR_.SCSS*/
}

