.SystemMaintenanceSpecialFunctions-SpotControlSetup,.ContractManagement-SpotControlSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSpotControlSetup  {
    // margin-top: 1rem;
    margin-bottom: 0.2rem !important;
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #c0bcbc;
      // max-height: 445px;
      
    }
    .gridSpotPrices {
      .rdt_TableCell:nth-child(2),.rdt_TableCell:nth-child(3),.rdt_TableCell:nth-child(4),.rdt_TableCell:nth-child(5),.rdt_TableCell:nth-child(6),.rdt_TableCell:nth-child(7),.rdt_TableCell:nth-child(8){
        justify-content: right;
      }
    }
    .ddBuyingPoint,.ddPeanutType {
      max-width: 60%;
      min-width: 60%;
      margin-left: 20%;
      .d-flex{
        display: block !important;
      }
      .col{
        padding: 0;
      }
    }

    .buttonwidget-container{
      max-width: 7%;
      min-width: 7%;
      padding-top: 0.5rem;
      margin-bottom: 0.2rem !important;
      button{
        width: 95%;
      }
    }
    .btnAdd{
      margin-left: auto;
    }
    .btnDelete{
      margin-right: 0.5rem;
    }
    .lblSpotPrices {
      margin-bottom: 0;
      label{
        margin-bottom: 0;
      }
    }
    .gridSpotPrices {
      min-width: 100%;
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
    }
  }
  .cmmndCntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.25rem;
    }
    .btncmdEnableDisableControls  {
      max-width: 15%;
      padding-left: 2%;
      display: none;
      button{
        width: 100%;
      }
    }
    .btnClose  {
      max-width: 10%;
      min-width: 10%;
      margin-left: 45%;
      button{
        width: 100%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
