.Settlements-FV95Residue,.ContractManagement-FV95Residue {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .groupboxwidget-container        {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #c0bcbc;
    }
  }

  .grpbxFarmInformation {
    // margin-top: 1rem;
    padding-right: 0.2%;
    // margin-bottom: 0.2rem !important;
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #c0bcbc; 
    }
    
    
      label{
        width: 160px;
        text-align: right;
      }
      .lblFarmInformation{
        padding-left: 0;
        label{
          text-align: left;
        }
      }
      .txtLoadType,.txtN{
        padding: 0;
        min-width: 10%;
        max-width: 10%;
        .col{
          padding-right: 0;
        }
        input{
          display: none;
        }
      }
      .ddLocation{
        min-width: 90%;
        .dropdown{
          width: 70%;
        }
        .dropdown-menu.show {
          overflow-y: scroll !important;
          max-height: 25vh !important;
          }
        label{
          width: 160px;
        }
      }
      
      .txtInspectionDateTime ,.txtSettlementDate  {
        min-width: 50%;
        max-width: 50%;
        padding-right: 0;
      }
      .txtSettlementDate{
        .input-group-append{
          display: none;
        }
      }
      .lblDateTimeFormat {
        label{
          width: 100%;
          text-align: left;
          color: blue;
        }
        padding-left: 0;
      }
      .txtSC95{
        min-width: 27.5%;
        max-width: 27.5%;
        padding-right: 0;
        margin-right: 60%;
        label{
          width: 83px;
        }
        .col{
          padding-right: 0;
        }
      }
      .txtRevisionNumber{
        display: none !important;
        min-width: 14.5%;
        max-width: 14.5%;
        .col{
          padding-left: 0;
        }
      }
      .txt1007{
        min-width: 39.25%;
        max-width: 39.25%;
        padding-right: 0;
        margin-right: 60%;
      }
      
      .buttonwidget-container{
        max-width: 10%;
        buttom{
          width: 95%;
        }
      }
       
    }
    .grpbxCMAInformation {
      padding-left: 0;
      .card{
        height: 100%;
        .radioHighMoisture{
          min-width: 80%;
          margin-left: 15%;
          .form-check{
            display: inline;
            margin-right: 5%;
            label{
              top: 2px;
              position: relative;
            }
          }
        }
        .chkboxResale {
          .form-label{
            display: none;
          }
          .form-check{
            label{
              font-weight: bold;
            }
            input{
              bottom: 2px;
            }
          }
        }
        .txtSellerNumber,.txtBuyerNumber{
          padding-right: 0;
          min-width: 40%;
          max-width: 40%;
          .col{
            padding-right: 0;
          }
          label{
            width: 50px;
            text-align: right;
          }
        }
        .txtSellerName,.txtBuyerName{
          padding-left: 0;
          min-width: 60%;
        }
        .lblNumber{
          padding-left: 0;
          max-width: 20%;
          margin-left: 20%;
        }
        .lblName,.lblNumber{
          label{
            margin-bottom: 0;
          }
        }
      }
      
    }

  .grpbxWeightTickets {
    padding-right: 0.2%;
    .card{
      height: 100%;
      padding-top: 0.5rem;
    }
    label{
      width: 100px;
      text-align: right;
    }
    .lblEWeightTktValue ,.lblEVehicleNumValue ,.lblEWtInclVehValue ,.lblEWtOfVehValue ,.lblEWtOfPnutValue,.lblTicketsValue ,.lblTotalLBsValue ,.lblTotalDollarValue  {
      padding-left: 0;
      label{
        text-align: left !important;
      }
    }
    .lblWeightTickets {
      label{
        text-align: left;
        font-weight: bold;
      }
    }
    
    .btnInquiry{
      margin-left: auto;
      margin-right: 1%;
      max-width: 9%;
      button{
        width: 90%;
      }
    }
  }
  .grpbxPeanutInfo  {
    
    
    // bottom: 19px;
    padding-left: 0;
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #c0bcbc;
      // min-height: 684px;
    }
    label{
      width: 100px;
      text-align: right;
    }

    .lblPeanutInformation {
      margin-left: 28%;
      max-width: 70%;
      label{
        width: 100%;
        font-weight: bold;
        text-align: left !important;
      }
    }
    .dropdownfieldwidget-container{
      min-width: 100%;
      .dropdown{
        width: 70%;
      }
    }

    
  }

  .grpbxInspectorRemarks {
    // bottom: 21px;
    
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color: #c0bcbc;
      
    }
    .txtarInspectorRemarks ,.lblInspectorRemarks {
      label{
        font-weight: bold;
      }
      min-width: 100%;
    }
    
  }

  .grpbxValueCalculation  {
    // bottom: 23px;
    
    padding-left: 0;
    max-width: 35%;
    min-width: 35%;
    .card-header{
      display:none
    }
    .card{
      min-height: 942px;
      border: none;
      background-color: #c0bcbc;
    }
   
    

    .textboxwidget-container{
      label{
        width: 175px;
        text-align: right;
      }
      padding: 0;
    }

   .txtOI,.txtH,.txtTotalLoadVal{
     .col{
      padding-right: 0;
     }
   }
   .txtG{
       input{
        padding-right: 0px !important;
       }
   }
    .txtKernelValueTon  ,.txtELKPremiumTon ,
    .txtTotalTon ,.txtDamageTon ,.txtAccessFMTon ,.txtExcessSplitsTon ,.txtAFlavusTon ,.txtTotalDiscountsTon,.txtValPerPoundExclSLK ,.txtG 
    {input{
      padding-right: 2px;
      padding-left: 4px;
    }
    
      .col{
        padding-right: 0;
      }
    }
    
    .txtNetWtExclLSK ,.txtLSKvc ,.txtNetWeight ,.txtExcessMoisture ,.txtWtlessFM ,.txtForeignMaterial ,.txtGrossWeight ,.txtWtOfVehicle ,.txtWtIncludingVehicle,
    .txtOI ,.txtH ,.txtTotalLoadVal ,.txtNetValPerTonExclSLK ,.txtValPerPoundInclSLK  {
      min-width: 70%;
      max-width: 70%;
    }
    .txtValPerPoundInclSLK{
      input{
        display: none;
      }
    }
    .txtTKC {
      min-width: 30%;
      max-width: 30%;
      .col{
        padding-left: 0;
      }
      
    }

    .lblValueCalculation {
      
      max-width: 50%;
      label{
        text-align: left;
        font-weight: bold;
      }
    }

    .lblPerTon {
      max-width: 25%;
      margin-left: 50%;
      label{
        text-align: center;
      }
    }
    .lblPerLb {
      max-width: 25%;
      label{
        text-align: center;
      }
    }
    .btnCalculate{
      display: none;
    }
  }

  .grpbxGradeCalculation  {
    // bottom: 23px;
    padding-right: 0.2%;
    min-width: 50%;
    .card-header{
      display:none
    }
    .card{
      min-height: 942px;
      border: none;
      background-color: #c0bcbc;
    }
    
    .grpbxVirginiaGrading .card{
      min-height: 184px !important;
    }

    .lblGradeCalculation  {
      
      label{
        text-align: left;
        font-weight: bold;
      }
    }

    .txtFMSampleWt ,.txtProbingPattern ,.txtCleanSampleWt ,.txtValenciaCleanSampleWt {
      label{
        width: 145px;
        text-align: right;
      }
    }

    .lblCornFound {
      display: none !important;
    }
    .chkboxCornFound {
     display: none !important;
    }
    .lblGrams1 ,.lblGrams2{
      max-width: 15%;
      margin-left: 20%;
      label{
        width: 100%;
        text-align: center;
      }
    }
    .lblPct1 ,.lblPct2{
      max-width: 15%;
      label{
        width: 100%;
        text-align: center;
      }
    }

    .txtSMKRSGr ,.txtFreezeDamageGr ,.txtSMK1Gr ,.txtSMK3Gr ,.txtSMK2Gr ,.txtSMK4Gr ,.txtConcealedRMDGr ,.txtCrkOrBrkShellsGr ,.txtDiscoloredShellsGr ,.txtHullBrightness,
    .txtSoundSplitsGr ,.txtJumboGr ,.txtELKGr ,.txtOtherKernelsGr ,.txtForeignMaterialGr ,.txtTotalDamageGr ,.txtLSKGr ,
    .txtHullsGr ,.txtFancyGr {
      max-width: 35%;
      min-width: 35%;
      padding-right: 0;
      label{
        width: 101px;
        text-align: right;
      }
    } 
    .txtTotalSMK,.txtTotalKrnls,.txtTotalKernelsHulls{
      input{
        max-width: 50%;
        position: relative;
        left: 49%;
      }
    }
    .txtTotalSMK,.txtTotalKrnls ,.txtTotalKernelsHulls {
      max-width: 50%;
      min-width: 50%;
      padding-right: 0;
      label{
        width: 101px;
        text-align: right;
      }
      .d-flex{
        max-height: 33px;
      }
    }
    .txtSMKRSPct ,.txtFreezeDamagePct ,.txtSMK1Pct ,.txtSMK3Pct ,.txtSMK2Pct ,.txtSMK4Pct ,.txtConcealedRMDPct ,.txtCrkOrBrkShellsPct ,.txtDiscoloredShellsPct ,
    .txtHullBrightnessPct ,.txtSoundSplitsPct ,.txtJumboPct  ,.txtELKPct ,.txtOtherKernelsPct ,.txtForeignMaterialPct ,.txtTotalDamagePct ,
    .txtLSKPct ,.txtHullsPct ,.txtFancyPct   {
      max-width: 15%;
      min-width: 15%;
      padding: 0;
      .col{
        padding-left: 0;
      }
    }
    
    .txtDamageSplits ,.txtOtherForeignMaterials ,.txtKernelsREL ,.txtMoisture ,.txtKernelsRPS ,.txtMeterReading ,.txtDamagedKRS ,.txtELKDamage,.txtBluePanWt  {
      label{
        width: 101px;
        text-align: right;
      }
      .d-flex{
        max-height: 33px;
      }
      padding-right: 0;
    }
    .txtDamageSplits {
      input{
        max-width: 35%;
        min-width: 39%;
      }
    }
    .txtKernelsREL ,.txtMoisture ,.txtKernelsRPS ,.txtMeterReading ,.txtDamagedKRS ,.txtELKDamage,.txtBluePanWt{
      input{
        // width: 47.5%;
        // margin-left: auto;
        max-width: 50%;
        position: relative;
        left: 49%;
      }
    }

    .lblVirginiaGrading {
      label{
        text-align: left;
        font-weight: bold;
      }
    }
    .txtSampleWt ,.txtGrams ,.txtPercent {
      label{
        width: 65px;
        text-align: right;
      }
      padding: 0;
    }
    .lblFlavusFound {
      max-width: 19%;
     
      padding-right: 0;
      label{
        width: 150px;
        text-align: right;
      }
      
    }
    .chkboxFlavusFound{
      
        .mb-3{
          margin-bottom: 0.4rem !important;
        }
        .col{
          padding-left: 0;
        }
        .d-flex{
          height: 27px;
        }
    }
  }

  .grpbxHMC   {
    // bottom: 23px;
    padding-right: 0.2%;
    padding-left: 0;
    max-width: 15%;
    .card-header{
      display:none
    }
    .card{
      min-height: 942px;
      border: none;
      background-color: #c0bcbc;
    }

    .textboxwidget-container{
      padding-right: 0;
      
      label{
        width: 75px;
        text-align: right;
      }
    }
    .btnConvertHMC {
      max-width: 80%;
      margin-left: 10%;
      top: 50px;
      button{
        width: 100%;
      }
    }
    .lblHMCConvertedGrades {
      max-width: 100%;
      
      bottom: 40px;
      label{
        width: 80%;
        margin-left: 10%;
        text-align: center;
        font-weight: bold;
      }
    }
    
  }
  .grpbxSignature {
    .card{
      padding-top: 0.5rem;
      padding-bottom: 0.5rem !important;
    }
    // bottom: 24px;
    .d-flex{
      display: block !important;
    }
    .txtSignOfInspector ,.ddSignOfWeigher {
      min-width: 40%;
      max-width: 40%;
      margin-left: 5%;
      padding-left: 0;
      .col{
        padding-left: 0;
      }
    }
    .ddSignOfWeigher {
     button{
      height: 30px;
     }
     .dropdown-toggle:empty::after {
      margin-left: auto;
    }
    }
  }
  .grpbxActions {
    .card-header{
      display:none
    }
   
    // bottom: 25px;
    .card{
      border: none;
      background-color: #f3f0f0;
      padding-top: 1rem;
    }
    .lblChangedBy,.lblAddedBy {
      padding: 0;
      max-width: 6%;
      label{
        width: 100%;
        text-align: right;
      }
    }
    .lblAddedByValue{
      min-width: 31%;
      padding: 0;
     
    }
    .lblChangedBy{
      max-width: 38%;
    min-width: 21%;
    position: relative;
    right: 170px;
    }
    .lblChangedByValue{
      right: 173px;
    }
    .lblChangedByValue{
      min-width: 31%;
      // max-width: 22%;
      padding: 0;
    }
    .lblChangedBy,.lblChangedByValue{
      bottom: 5px;
    } 
    .lblAddedByValue,.lblAddedBy{
      bottom: 10px;
    }
    .chkboxSendBack {
      min-width: 25%;
      max-width: 25%;
      .form-label{
        display: none;
      }
      .form-check{
        label{
          text-align: left;
          width: 80%;
          margin-left: 5%;
        }
        input{
          bottom: 15px;
        }
      }
    }
    .buttonwidget-container{
      max-width: 9%;
      min-width: 9%;
      // top: 15px;
      // padding: 0;
      button{
        width: 79%;
        height: 40px;
      }
    }
    
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
