.modal-90w {
  width: 90%;
  max-width: none !important;
  
}
.fade.modal.show{
  padding-right: 0 !important;
}
form{
  min-height: auto;
  height: auto;
  max-height: calc(100vh - 230px);
}
.formHeight > .centerWidth{
  overflow: overlay;
  overflow-x: hidden;
}
.modal-content{
  background-image: url(../../../../assets/img/BgLogo.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}
.modal-90w .modal-header {
  border: none;
  padding: 0;
  form.row{
    height: calc(100vh - 250px);
  }
  .close{
    span{
      font-size: 30px;
      color: #403c54;
    }
  }
}
.modal-body{
  padding: 0rem !important;
  // height: 500px;
  // height: 500px; 
}
.newWidth{
  width: 99% !important;
  
  @media screen and (min-width:1281px){
    form.row{
      width: 79.3rem;
      // max-height: 1400px;
    }
  }
  @media screen and (max-width:1280px) {
    form.row{
      width:77rem  !important;
    } 
  }
}
.modal-dialog.modal-90w{
  
  box-shadow: 0 0 27px 270px rgb(226 218 218 / 70%);
}