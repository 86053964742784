.AccountPayment-PrintChecks,.ContractManagement-PrintChecks {
  /*START_USER_CODE_FOR_.SCSS*/
  .fa{
    font-size: 15px;
    color: dodgerblue;
    border: none;
  }
  .card-header,
  h1 {
    display: none;
  }
  .grpbxCheckDetails {
    .card {
      background-color: #c0bcbc;
      padding-top: 0.25rem;
    }
    .ddLocation,.ddRemitTo{
      .dropdown-menu.show {        
        overflow-y: scroll !important;
        max-height: 25vh !important;
    }
    }
    .ddLocation{
      min-width: 85%;
      max-width: 45%;
      .dropdown{
        max-width: 65%;
      }
      label{
        width: 55px;
        text-align: right;
        color: red;
      }
    }
    .ddRemitTo {
      min-width: 85%;
      max-width: 45%;
      .dropdown{
        max-width: 65%;
      }
      label{
        width: 55px;
        text-align: right;
      }
    }
    .txtVendor {
      max-width: 25%;
      padding-right: 0;
      label{
        width: 55px;
        text-align: right;
      }
    }
    .btnVendor{
      max-width: 3%;
      margin-bottom: 0.25% !important;
      button{
        width: 90%;
      }
    }
    .txtVndrName{
      padding-left: 0;
      max-width: 30.25%;
      min-width: 30.25%;
      padding-left: 0;
      input{
      color:blue;
      border: none;
      background: transparent;
      padding-left: 0;
      }
    }
   .btnSearch{
    min-width: 38%;
    margin-left: 38%;
   }
   .txtStartingCheck{
    max-width: 22%;
   }

   .gridBatchedReadyToPays {
     .card-header{
       display: none !important;
     }
     button{
       background-color: transparent;
       background: transparent;
       border: none;
     }
   }
   .column-chkbox1{
     display: none;
   }
    .txtEndingCheckNumber,.txtStartingReceivableNumber {
      max-width: 15%;
    }
    .lblBatchedReadyToPays {
      min-width: 35%;
    }
    
  }
  .grpbxActions {
    .card {
      border: none;
      background-color: #f3f0f0;
    }
    .lblChecksSelected {
      max-width: 10%;
      min-width: 10%;
      padding-right: 0%;
    }
    .lblChecksSelectedValue{
      min-width: 30%;
      padding-left: 0;
      .col{
        bottom: 0.4rem;
      }
      margin-right: 40%;
    }
    .btnPrintChecks,
    .btnCancel {
      max-width: 10%;
      button{
        width: 90%;
      }
    }
  
  }
  
  /*END_USER_CODE_FOR_.SCSS*/
}
