.SystemMaintenanceMasterManagement-FreightRateSetup,.ContractManagement-FreightRateSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;

  .grpbxFreightRateSetup{
    .card-header{
      display:none
    }
    /*margin-top: 1rem;*/
    .card{
      padding-top: 0.5rem;
      border: none;
      background-color: #c0bcbc;
    }
    label{
      width: 200px;
      text-align: right;
    }
    input{
      width: 30%;
    }
    .input-group{
      width: 30%;
    }
    .dropdown{
      width: 80%;
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .btnSearch{
      max-width: 10%;
      margin-left: 45%;
      button{
        width: 95%;
      }
    }
    .btnAdd,.btnEdit,.btnDelete{
      max-width: 7.5%;
      button{
        width: 95%;
      }
    }
    .btnAdd{
      margin-left: auto;
    }
.gridFreightRateSetupData {
  .card-header{
    display:block;
  }
  .rdt_TableCell:nth-child(1),.rdt_TableCol:nth-child(1){
    min-width: 260px;
    max-width: 260px;  
    justify-content: left !important; 
  }
  .rdt_TableCell:nth-child(5),.rdt_TableCol:nth-child(5){  
    min-width: 150px;
    max-width: 150px; 
  }
  .popover-body{
    input{
      width: auto;
    }
    label{
      text-align: left;
    }
  }
}
    .btnDelete{
      margin-right: 0.5%;
    }
  }

  .cmmndCntnrPrintClose{
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }

    .btnPrint{
      max-width: 10%;
      margin-left: auto;
      button{
        width: 95%;
      }
    }

    .btnClose{
      max-width: 10%;
      margin-right: 1%;
      button{
        width: 95%;
      }
    }
  }

  /*END_USER_CODE_FOR_.SCSS*/
}
