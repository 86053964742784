.ContractManagement-ViewPayments {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #f3f0f0;
  // background-color: #333e48;
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
  .card {
    // background-color: #c0bcbc;
  }
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxViewWRPayments{
    margin-bottom: 0 !important;
  .lblPayments{
    min-width: 100%;
    }
  }
  .btnContainer {
    .row{
      background-color: #f3f0f0;
    }
    .mx-0{
      justify-content: end;

    }
    .btnExport {
      max-width: 10%
    }
    .btnPrint {
      max-width: 6%
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
