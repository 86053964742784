.SystemMaintenanceApplicationSupport-Scan211Document,.ContractManagement-Scan211Document {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .card-header{
    display: none;
  }
  .card{
    background-color:#f3f0f0 ;
  }
  .lbl211DocumentInformation,.lblSaving211Options,.lblScanningOptions{
    font-size: 15px;
    font-weight: 500;
  }
.grpbx211DocumentInformation{
  .lbl211DocumentInformation{
    label{
      width: 190px;
    }
  }
  label{
    width:90px;
    text-align: right;
  }
  .txtVendor{
    max-width: 75%;
  }
  .txtActionType,.txtDocumentType{

    input{
      width: 40%;
    }
  }
}
.grpbxSavingOptions{
  .row{
    justify-content: center;
  }
  .btnScan211,.btnSaveFromExistingFile,.btnCancelSave{
    max-width: 15%;
    button{
      width: 90%;
    }
  }
}
.grpbxScanningOptions{
  .row{
    justify-content: center;
  }
  .btnScanUsingDocFeeder,.btnScanUsingFlatbed,.btnCancelScan{
    max-width: 15%;
    button{
      width: 90%;
    }
  }
  .txtarScanningStatus{
    .d-flex{
      display: grid !important;
    }
    label{
      font-size: 15px;
      font-weight: 500;
    }
  }
}
.grpbxVerificationResult{
  .txtarVerificationResult{
    .d-flex{
      display: grid !important;
    }
    label{
      font-size: 15px;
      font-weight: 500;
    }
  }
  .btnVerifynSave,.btnCancel{
    max-width: 15%;
    button{
      width: 90%;
    }
  }
  .btn1,.btn2,.btn3{
    max-width: 5%;
  }
}
  /*END_USER_CODE_FOR_.SCSS*/
}
