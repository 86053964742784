.ContractManagement-ContractGroupingProfile {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #f3f0f0;
  .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  h1 {
    display: none !important;
  }
  .card-header {
    display: none;
  }
  .card{
    background-color: #333E48 ;
    // border: none;
  }
  .gridCntrctShr{
    .card-header{
      display: block;
    }
  }
  .grpbxGrouping {
    .card {
      background-color: #f3f0f0;
    }
    .txtGroupingInput {
      margin-left: 40%;
      max-width: 21%;
    }
    .datewidget-container {
      margin-left: 40%;
      max-width: 19.5%;
    padding-right: 0;
      label {
        min-width: 54px;
        text-align: right;
      }
    }
    .dateFormat {
      padding: 0;
    }
  }
  .grpbxSearchCntrcts {
    .card {
      background-color: #f3f0f0;
    }
    .btnSearchforCntrcts {
      margin-left: 10%;
      min-width: 37%;
      button {
        min-width: 80%;
      }
    }
  }
  .grpbxActions {
    .card {
      background-color: #f3f0f0;
    }
    .lblAddedBy {
      min-width: 77%;
    }
    .btnUpdate {
      max-width: 7%;
      padding-left: 8px;
    }
    .btnInsert {
      max-width: 7%;
      padding-left: 8px;
    }
    .btnDelete {
      max-width: 7%;
    }
    .btnExit {
      max-width: 8%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
