.SystemMaintenanceSpecialFunctions-UserSignatureSetup,.ContractManagement-UserSignatureSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
   display: none;
 }
 .grpbxUserSignatureSetup {
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
    }
   .card {
     .card-header {
       display: none;
     }
     background-color: #c0bcbc;
     .txtName ,.txtTitle{
       label{
         margin-bottom: 0 !important;
         color: red;
       }
     }
     .picSignature{
       label{
         color: red;
       }
       .col{
        display: none;
       }
     }
     .grpbxPic {
      max-width: 90%;
      min-width: 90%;
      padding-left: 1rem;
      padding-right: 1rem;
      .card{
        background-color: #f0f0f0;
      }
     }
     .ddUsers,.txtName,.txtTitle{
       max-width: 90%;
       .d-flex{
         display: block !important;
         .col{
           padding: 0;
         }
       }
     }
   }
 }

 .grpbxPic {
  min-width: 100%;
  max-width: 100%;
  .card-header{
    display: none;
  }
  .card-body{
    min-height: 125px;
    border: 1px solid black;
  }
  .row{
    justify-content: center;
  }
  img{
    height: 225px;
    width: 600px;
  }
}

 .cmmndCntnrActions{
   .row{
     background-color: #f3f0f0;
     padding-top: 0.2rem;
     .buttonwidget-container{
       min-width: 20%;
       max-width: 20%;
       .row{
         justify-content: center;
         button{
           width: 90%;
         }
       }
     }
     .btnScan {
       margin-left: 20%;
     }
     .btnDelete{
       margin-right: 20%;
     }
     .btnSave{
       margin-left: 30%;
     }
     .btn1 {
       display: none;
     }
   }
 }
 /*END_USER_CODE_FOR_.SCSS*/
 }
 