.SystemMaintenanceMasterManagement-FarmerStockPriceSetup,.ContractManagement-FarmerStockPriceSetup {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .cmndCntnrFrmrStock {
  .row{
    background-color: #f3f0f0;
    }
  }
  .grpbxFrmrStockPrice{
    .card-header{
      display: none;
    }
    .card{
      background-color: #c0bcbc;
    }
    label{
      width: 85px;
      text-align: right;
    }
    .btnEdit,.btnAdd{
      max-width: 5%;
    }
    .btnDelete{
      max-width: 6%;
    }
    .lblFrmrStckPric{
      min-width: 83%;
      label{
        width: 100%;
        text-align: left;
      }
    }
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .lstbxFrmrStock {
      select{
        max-width: 93%;
        margin-left: 7%;
      }
    }
  }
  .cmndCntnrFrmrStock{
    .btnClose{
      min-width: 100%;
      justify-content: center;
      display: flex;

    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
