.WarehouseReceipts-DelvAgreeSearch,.ContractManagement-DelvAgreeSearch {
  /*START_USER_CODE_FOR_.SCSS*/
  // background-color: #333e48;
  background: transparent;
  h1{
    display: none;
  }
  .card-header{
    display: none;
  }
  .card{
    border: none;
  }
  .grpbxDelvAgreeSrch{
    .card{
      background-color: #f3f0f0;
    }
  }
  .gridDelvAgreeSearch {
    padding: 0;
    .card-header{
      display: block;
    }
    label {
      text-align: left;
    }
    
    div[id^="cell-txtcolPrice"],div[id^="cell-txtcolConversion"],div[id^="cell-txtcolReceiptValue"],div[id^="cell-txtcol100Applied"],div[id^="cell-txtcol100AdjustmentDoller"],div[id^="cell-txtcol100Open"],div[id^="cell-txtcolReceiptLbs"],div[id^="cell-txtcolAdjustmentlbs"],div[id^="cell-txtcolTotalAdjustedlbs"],div[id^="cell-txtcolAppliedLbs"],div[id^="cell-txtcol100Adjustment"]{
      .valRowSel{
        width: 75%;
        text-align: end;
      }
    }
    div[id^="cell-txtcolOpenlb"]{
      //justify-content: end;
      .valRowSel{
        width: 75%;
        text-align: end;
      }
    }
   
    .expandable-area {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      min-height: 35px !important;
      background-color: #e7f9ff;
      }
    .expandIconSpc{
      min-width: 30px;
      max-width: 30px;
    }
    .expndLbl{
      width:100%;
    }
    
    .extraRowCell_1,.extraRowCell_2,.extraRowCell_3,.extraRowCell_4,.extraRowCell_5,.extraRowCell_6,.extraRowCell_7,.extraRowCell_8,.extraRowCell_9,.extraRowCell_10,.extraRowCell_11,.extraRowCell_12,.extraRowCell_13,.extraRowCell_14,.extraRowCell_15,.extraRowCell_16,.extraRowCell_17,.extraRowCell_18,.extraRowCell_19,.extraRowCell_20,.extraRowCell_21,.extraRowCell_22{
      padding-top: 6px !important;
      font-size: 0.7rem;
      border-right: 1px solid #eeeede;
      min-height: 34px;
      text-align: center;
      min-width: 210px;
      max-width: 210px;
    }
    
    .rdt_TableCol,
    .rdt_TableCell  {
      min-width: 210px;
      max-width: 210px;
      padding: 0;
  }
    
   .extraRowCell_5{
    label{
      width: 100%;
      text-align: center;
    }
   }
  //  
  }
  .grpbxDelvAgreeSearch{
    min-width: 45%;
    padding: 0;
    .card{
      height: 100%;
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    label{
      width: 130px;
      text-align: right;
    }
    .txtAgreementNum{
      input{
        max-width: 40%;
      }
    }
    .ddAgreementType,.ddAgreementStatus{
      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
    }
    }
    .txtVendorNum{
      min-width: 50%;
      padding-right: 0%;
    }
    .txtVndr{
      min-width: 40%;
      padding: 0;
      input{
        color:blue;
        background: transparent;
        border: none;
        box-shadow: none;
      }
    }
    .btnVendor{
      max-width: 7%;
      margin-bottom: 0.3rem !important;
    }
  }
  .grpbxDelvAgreeSearch2{
    max-width: 55%;
    padding: 0;
    .card{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    label{
      width: 130px;
      text-align: right;
    }
    .ddSeedGeneration,.ddPeanutVariety,.ddPeanutType,.ddLocation{
      max-width: 70%;
    }
    .ddEdibleOil,.ddOleic,.ddSeg{
      max-width: 65%;
    }
    .ddEdibleOil{
      min-width: 65%;
    }
    .lblCropYear{
      max-width: 35%;
      background-color: rgb(184, 179, 179);
      font-weight: 700;
      color: red;
      label{
        width: 80px;
        text-align: left;
        font-weight: 700;
        color: red;
        font-size: 16px;
      }
      .col{
        div{
          position: relative;
          top: 5px;
          font-size: 16px;
        }
      }
    }
  }
  .grpbxDelvSearch3{
    bottom: 4px;
    padding: 0;
    
    .btnOpen{
      max-width: 6%;
    }
    .btnCreateAgreement{
      max-width: 11%;
    }
    .btnSearch{
      margin-left: 43%;
      min-width: 40%;
    }
    .txtOpenLbs ,.txt100AdjustmentLbs,.txtAppliedLbs,.txtTotalAdjustedLbs,.txtAdjustmentLbs,.txtReceiptLbs{
      max-width: 15%;
      min-width: 15%;
      label{
        text-align: right;
      }
      .col{
        padding: 0;
      }
      input{
        text-align: right;
        padding-right: 0;
        background: transparent;
        box-shadow: none;
        border: none;
      }
      .d-flex{
        display: grid !important;
      }
    }
    .txtReceiptLbs{
      margin-left: auto;
    }
  }
  .cmmndCntnrActions{
    .row{
    background-color: #f3f0f0;
    padding-top: 0.3rem
    }
    .btnExportToExcel{
      max-width: 10%;
    }
    .btnClose,.btnPrint{
      max-width: 6%;
    }
  }
  .dropdown-menu.show {
    overflow-y: scroll !important;
    max-height: 25vh !important;
}
  /*END_USER_CODE_FOR_.SCSS*/
}
