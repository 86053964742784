.ContractManagement-TradeSettleProfile,
.Settlements-TradeSettleProfile {

  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
  }

  // background-color: #333E48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .card-header,
  h1 {
    display: none;
  }

  // background-color: #c0bcbc;
  .card {
    background-color: #c0bcbc;
    padding-left: 0;
    padding-right: 0;
  }

  .grpbxTradeSettleProfile {

    .card,
    .row {
      min-height: 1470px;
      max-height: 1470px;
    }
  }

  .grpbxTradeDetails {
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    max-height: 678px;

    .card {
      background-color: #c0bcbc;
    }

    label {
      width: 130px;
      text-align: right;
    }

    .txtTradeInspectSC95,
    .dtInspectDateTime {
      min-width: 35%;

      input#txtTradeInspectSC95 {
        max-width: 70%;
      }
    }

    .txtSts,
    .radioTradeSaleTradePurchase {
      min-width: 30%;
    }

    .radioTradeSaleTradePurchase {
      label.form-check-label {
        text-align: left;
      }
      .col {
        max-width: 46%;
        left: 36%;
        top: 10px;
      }
    }

    .ddShipFromToLocation,
    .ddCategory {
      max-width: 35%;
      button{
        height: 30px;
      }
      .dropdown-toggle:empty::after {
        margin-left: auto;
    }
    }

    .txtTradeSettle1007,
    .dtSettleDateTime {
      min-width: 64.8%;

      div#dtSettleDateTimeParent {
        max-width: 40.4%;
        min-width: 30%;
      }

      input#txtTradeSettle1007 {
        max-width: 28%;
        
      }
    }

    .txt1007Handler,
    .txt1007HandlerName {
      max-width: 35.2%;
    }
  }

  .grpBxSetllPRof {
    .card {
      border: none;
    }
  }
  .grpbxVendorSplitRow5 {
    min-width: 100%;

    .ddRemitTo6 {
      max-width: 25%;
    }

    .txtSharePercentage6 {
      max-width: 20%;
    }
  }

  .grpbxVendorSplits {
    max-height: 350px;
    min-height: 300px;

    .card {
      padding-left: 0;
      padding-right: 0;
    }

    .lblVendorSplits {
      font-size: 15px;
      font-weight: 600;
    }

    .lblVndrSplts {
      font-size: 16px;
      font-weight: 700;
    }

    .lblVendor {
      min-width: 33%
    }

    .lblRemitTo {
      max-width: 35%;
      min-width: 42%;
    }

    .lblSharePercentage {
      max-width: 15%;
    }

    .whtspcVendorSplits {
      display: none;
    }

    .card-body{
      padding-top: 4px;
    }

    .grpbxVendorSplitSub {
      .card {
        border: none;
        background-color: #f3f0f0;
      }

      max-height: 265px;
      min-height: 265px;
      overflow: overlay;

      .txtVendor1,
      .txtVendor2,
      .txtVendor3,
      .txtVendor4,
      .txtVendor5,
      .txtVendor6,
      .txtVendor7,
      .txtVendor8,
      .txtVendor9,
      .txtVendor10,
      .txtVendor11,
      .txtVendor12,
      .txtVendor13,
      .txtVendor14,
      .txtVendor15,
      .txtVendor16,
      .txtVendor17,
      .txtVendor18,
      .txtVendor19,
      .txtVendor20 {
        min-width: 24%;
        max-width: 24%;
        padding-right: 0;
      }

      .btn1,
      .btn2,
      .btn3,
      .btn4,
      .btn5,
      .btn6,
      .btn7,
      .btn8,
      .btn9,
      .btn10,
      .btn11,
      .btn12,
      .btn13,
      .btn14,
      .btn15,
      .btn16,
      .btn17,
      .btn18,
      .btn19,
      .btn20 {
        max-width: 6%;
        min-width: 6%;
      }

      .ddRemitTo1,
      .ddRemitTo2,
      .ddRemitTo3,
      .ddRemitTo4,
      .ddRemitTo5,
      .ddRemitTo6,
      .ddRemitTo7,
      .ddRemitTo8,
      .ddRemitTo9,
      .ddRemitTo10,
      .ddRemitTo11,
      .ddRemitTo12,
      .ddRemitTo13,
      .ddRemitTo14,
      .ddRemitTo15,
      .ddRemitTo16,
      .ddRemitTo17,
      .ddRemitTo18,
      .ddRemitTo19,
      .ddRemitTo20 {
        min-width: 48%;
        max-width: 48%;
        padding-right: 0;
      }

      .lblXX1,.lblXX2,.lblXX3,.lblXX4,.lblXX5,.lblXX6,.lblXX7,.lblXX8,.lblXX9,.lblXX10,.lblXX11,.lblXX12,.lblXX13,.lblXX14,.lblXX15,.lblXX16,.lblXX17,.lblXX18,.lblXX19,.lblXX20 {
        label{
          display: block;
        }
      }
      .txtSharePercentage1,
      .txtSharePercentage2,
      .txtSharePercentage3,
      .txtSharePercentage4,
      .txtSharePercentage5,
      .txtSharePercentage6,
      .txtSharePercentage7,
      .txtSharePercentage8,
      .txtSharePercentage9,
      .txtSharePercentage10,
      .txtSharePercentage11,
      .txtSharePercentage12,
      .txtSharePercentage13,
      .txtSharePercentage14,
      .txtSharePercentage15,
      .txtSharePercentage16,
      .txtSharePercentage17,
      .txtSharePercentage18,
      .txtSharePercentage19,
      .txtSharePercentage20 {
        min-width: 20%;
        max-width: 20%;
        padding-left: 0;
        padding-right: 0;
      }

      .txtVendorID1,
      .txtRemitTo1,
      .txtSharePercent1,
      .txtVendorID2,
      .txtRemitTo2,
      .txtSharePercent2,
      .txtVendorID3,
      .txtRemitTo3,
      .txtSharePercent3,
      .txtVendorID4,
      .txtRemitTo4,
      .txtSharePercent4 {
        padding-left: 0;
        padding-right: 0;
      }

      .txtSharePercent1,
      .txtSharePercent2,
      .txtSharePercent3,
      .txtSharePercent4 {
        min-width: 20%;
      }

      .txtVendorID1,
      .txtRemitTo1,
      .txtVendorID2,
      .txtRemitTo2,
      .txtVendorID3,
      .txtRemitTo3,
      .txtVendorID4,
      .txtRemitTo4 {
        max-width: 20%;
      }

      .txtVendorID1,
      .txtVendorID2,
      .txtVendorID3,
      .txtVendorID4 {
        margin-left: 38%;
      }
    }
  }

  .grpbxPnutShippd {
    min-width: 38%;

    .card {
      border: none;
    }
  }

  .grpbxPeanutInfo {
    .lblPeanutInformation {
      label {
        width: 165px;
        text-align: center;
        font-weight: 700;
      }
    }

    .card {
      background-color: #f3f0f0;
    }

    label {
      width: 85px;
      text-align: right;
    }
.dropdown-menu.show{
  max-width: 140%;
  min-width: 140%;
}
.ddSeg,.ddOleic,.ddPeanutVariety{
  .dropdown-menu.show{
   max-width: 110%;
   min-width: 110%;
  }
  .dropdown-toggle:empty::after {
    margin-left: auto;
  }
}
.ddPeanutVariety{
  button{
    height: 30px;
  }
}
.ddGeneration{
  .dropdown-menu.show{
    max-width: 150%;
    min-width: 150%;

  }
  .dropdown-toggle:empty::after {
    margin-left: auto;
}
}
    .lblPnutInfo {
      font-size: 16px;
      font-weight: 600;

      label {
        width: 204px;
      }
    }

    .ddPeanutType,
    .ddEdibleOil,
    .ddGeneration
    {
      button {
        height: 30px;
        max-width: 110%;
        min-width: 117%;
      
      }
    }
    .ddGeneration{
      #ddItem_{
        height: 25px;
  
      }
    }
    .ddOleic,
    .ddPeanutVariety,
    .ddSeg {
      button{
        min-width: 117%;
      }

    }

    .txtPeanutType,
    .txtEdibleOil,
    .txtGeneration,
    .txtOleic,
    .txtPeanutVariety,
    .txtSeg {
      max-width: 33%;
      padding-left: 0;
    }
  }

  .grpbxDetails {
    max-width: 12%;
    padding-left: 0;
    padding-right: 0;

    .card {
      border: none
    }
  }

  .grpbxPntShippd {
    padding: 0;
  }

  .grpbxShippedTo {
    min-height: 110px;
    padding-top: 10px;
    padding-bottom: 10px;

    .card {
      background-color: #f3f0f0;
      height: 100%;
    }

    .lblShippedTo {
      font-weight: 600;
      font-size: 13px;
      min-width: 75%;

      label {
        width: 100%;
        text-align: left;
      }
    }

    .txtDTWhseBinNum {
      min-width: 100%;

      label {
        color: red;
        width: 68px;
      }

    }

  }

  .grpbxShippedFrom {
    min-height: 110px;
    padding-top: 10px;

    .card {
      background-color: #f3f0f0;
      height: 100%;
    }

    .lblShippedFrom {
      font-weight: 600;
      font-size: 13px;
      min-width: 75%;

      label {
        width: 100%;
        text-align: left;
      }
    }

    .ddWhseBinNum {
      label {
        color: red;
        width: 68px;
      }
      .dropdown-menu.show{
        max-width: 180%;
        min-width: 180%;
      }
    }

  }

  .grpbxInterDepartmentalTransfer {
    position: relative;
    bottom: 153px;

    .card {
      background-color: #f3f0f0;
    }

    .lblInterDeptTransfer {
      font-size: 13px;
      font-weight: 600;

      label {
        width: 200px;
        text-align: left;
      }
    }
    .ddIDTfromtoCompany {
      max-height: 40px;
    }
    ddIDTfromtoLocation{
      max-height: 20px;
    }
    .ddIDTfromtoCompany,
    .ddIDTfromtoLocation {
      min-width: 63%;
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
       button{
       height: 30px;
      }
    }
    .txtIDTfromtoVendor {
      max-width: 23%;
      top: 24px;

      label {
        text-align: center;
      }

      .d-flex {
        display: grid !important;
      }
    }

  }

  .grpbxScaleTickets {
    .card {
      background-color: #f3f0f0;
    }

    .lblScaleTickets {
      min-width: 83%;
      font-size: 15px;
      font-weight: 600;
    }

    .btnDeleteST,
    .btnEditST,
    .btnAddST {
      max-width: 5%;
    }
    .txtTotal{
      margin-left: 33%;
    }

    .gridScaleTickets {
      .card-header {
        display: none;
      }
    }

    .lblTckt {
      max-width: 6%;
    }

    .lblTcktVal {
      min-width: 75%;
    }

    .lblTotal {
      max-width: 5%;
    }

    .lblTotalVal {
      max-width: 14%;
    }
  }

  .grpbxReGrading {
    .card {
      background-color: #f3f0f0;
      min-height: 1190px;
      position: relative
    }
    .row {
      min-height: 840px;
    }
    .form-group {
      margin-bottom: 0.3rem !important;
      // padding: 5px;
    }
    .lblReGrading{
      font-size: 15px;
      font-weight: 600;

    }
    .radioGradePricingMethod {
      .d-flex {
        display: grid !important;
      }

      .col {
        justify-content: center;
        display: inline-flex;
      }

      label.form-check-label {
        text-align: left;
      }

      min-height: 40px;
      max-height: 40px;
    }
    .txtFMSampleWt{
      label{
        min-width: 96px;
        text-align: right;
      }
    }
    .radioGramsPercentage {
      max-width: 80%;
      margin-left: 20%;
      min-height: 30px;
      max-height: 30px;
      .mb-3{
        display: inline-flex;
      }
      .form-check-label {
        text-align: left;
      }
      .form-check{
        min-width: 133%;
      }
    }

    .grpbxDamage {
      min-width: 100%;
      padding-right: 0;

      .card,
      .row,.card-body {
        min-height: 150px !important;
        max-height: 150px !important;
        border: none;
      }

      .txtFreezeDamage,
      .txtPittingDamage,
      .txtConcealedRMD {
        max-width: 62%;
        padding-right: 0;
        padding-left: 0;
        label {
          min-width: 107px;
        }
      }
      .txtPittingDamagePct,.txtFreezeDamagePct,.txtConcealedRMDPct {
        max-width: 30%;
        min-width: 30%;
        padding: 0;
      }
    }

    .chkboxReGrading {
      max-width: 36%;
      min-width: 100%;
      max-height: 65px;
      padding-left: 0;
      .col{
        padding-left: 0;
      }
      .form-check{
        label{
          width: 100px;

        }
      }
      input {
        left: 52%;
      }
    }
    .grpbxRegrdRight ,.grpbxRegrdLeft{
      max-width: 50%;
      min-width: 50%;
      padding: 0;
      min-height: 824px;
      max-height:855px;
      .card{
        border: none;
       min-height: 700px;
      
      }   
      .card-body{
        padding-top: 0;
      }   
    }   
   .grpbxRegrdLeft{
    label{
      min-width: 115px;
      text-align: right;

    }
    .form-group{
      margin-bottom: 0 !important;
    }
    .txtTotalSMKRScreen,.txtSoundSplits{
      padding-right: 0;
    }
    .txtTotalSMKRScreenPct,.txtSMK1Pct,.txtSMK2Pct,.txtSMK3Pct,.txtSMK4Pct,.txtSoundSplitsPct,.txtTotalOKPct,.txtOKRidingPct,.txtTotalDamagePct,.txtHullsPct {
      min-width: 35%;
    }
    .txtSMK4Gr,.txtSMK3Gr,.txtSMK2Gr,.txtSMK1Gr,.txtTotalSMKRScreen,.txtSoundSplits,.txtTotalOK,.txtOKRidingGr,.txtTotalDamage,.txtHulls{
      min-width: 65%;
      padding-right: 0;
    }
    .txtSMK4Gr,.txtSMK3Gr,.txtSMK2Gr,.txtSMK1Gr,.txtOKRidingGr{
      label{
        min-width: 64px;
      }
      input{
        max-width: 60%;
      }
    }

    .txtOKFallThru,.txtTotalSMK,.txtOKDamageRiding,.txtDamageSplits,.txtTotalKernelsAndHulls,.txtTotalKernels,.txtDamagedKRS,.txtKernelsRPS,.txtKernelsREL,.txtProbingPattern{
      min-width: 100%;
    }
    .txtTotalSMK,.txtOKDamageRiding,.txtOKFallThru,.txtTotalKernels,.txtTotalKernelsAndHulls{
      input{
        max-width: 36%;
        left: 64%;
        position: relative;
      }
    }
    .txtDamageSplits,.txtDamagedKRS,.txtKernelsRPS,.txtKernelsREL,.txtProbingPattern{
      input{
        max-width: 42%;
      }
    }
    // .grpbxDamage {
    //   min-width: 57%;
    //   padding-right: 0;

    //   .card,
    //   .row {
    //     min-height: 150px;
    //     max-height: 150px;
    //     border: none;
    //   }

    //   .txtFreezeDamage,
    //   .txtPittingDamage,
    //   .txtConcealedRMD {
    //     max-width: 57%;
    //     padding-right: 0;

    //     label {
    //       width: 118px;
    //     }
    //   }
    //   .txtPittingDamagePct,.txtFreezeDamagePct,.txtConcealedRMDPct {
    //     max-width: 32.5%;
    //   }
    // }
   }
   .grpbxRegrdRight{
    label{
      min-width: 115px;
      text-align: right;
    }
    .form-group{
      margin-bottom: 0 !important;
    }
    .txtCrackedBroken,.txtDiscoloredShells,.txtHullBrightness,.txtJumbo,.txtKernRidingELK,.txtTotalELKDamage,.txtNetELK,.txtKernRidingPrescribe,.txtForeignMtrl,.txtTotalSLK,.txtLSKRidingGr,.txtFancy{
      min-width: 65%;
      padding-right: 0;
    }
    .txtCrackedBrokenPct,.txtDiscoloredShellsPct,.txtTotalELKDamagePct,.txtKernRidingELKPct,.txtJumboPct,.txtHullBrightnessPct,.txtNetELKPct,.txtKernRidingPrescribePct,.txtForeignMaterialPct,.txtTotalSLKPct,.txtLSKRidingPct,.txtFancyPct{
      min-width: 35%; 
      padding-left: 0;
    }
    
    .txtOtherFM,.txtLSKFallThru,.txtLSKDamageRiding,.txtMoisture,.txtVicamReading,.txtSeedMeetingSpec,.txtMeterReading,.txtBluePanWT,.txtELKDamaged{
      min-width: 100%;
    }
    .txtMeterReading,.txtELKDamaged,.txtBluePanWT{
      input{
        max-width: 50%;
      }
    }
    .txtVicamReading,.txtSeedMeetingSpec,.txtLSKFallThru,.txtLSKDamageRiding,.txtMoisture{
      input{
        max-width: 43%;
        left: 57%;
        position: relative
      }
    }
    .txtVicamReading{
      position: relative;
    bottom: 9px;
    }
    .txtSeedMeetingSpec{
      position: relative;
    bottom: 8px;
    }
    .txtLSKRidingGr{
      label{
        min-width: 60px;
      }
      input{
        max-width: 55%;
       
      }
    }
   }
   .grpbxRegrdLeftBottom,.grpbxRegrdRightBottom{
    padding: 0;
    min-width: 50%;
    .card,.row{
      border: none;
      padding: 0;
    }
    .card-body{
      padding-top: 0;
    }
   }
   .grpbxRegrdLeftBottom{
    .card,.row{
      min-height: 185px;
    }
    label{
      width: 110px;
      text-align: right;
    }
    .txtDamagedKRS,.txtKernelsRPS,.txtKernelsREL,.txtProbingPattern{
      min-width: 100%;
      // margin-bottom: 0  !important;
     max-height: 30px;
      input{
        max-width: 40%;
      }
    }
   }
   .grpbxRegrdRightBottom{
    .card,.row{
      min-height: 145px;
    }
    label{
      width: 110px;
      text-align: right;
    }
    .txtMeterReading,.txtELKDamaged,.txtBluePanWT{
      min-width: 100%;
      // margin-bottom: 0  !important;

      max-height: 30px;
      input{
        max-width: 40%;
      }
    }
   }
  }

  .grpbxValueCalculation {
    .form-group {
      margin-bottom: 0rem !important;
    }

    .card {
      min-height:72%
    }

    .row {
      margin-bottom: -6px;
      min-height: 0%
    }

    label {
      width: 173px;
      text-align: right;
      margin-bottom: 0.1rem !important;
    }
  input{
    text-align: right;
  }
    .lblPerTon {
      min-width: 68%;
      padding-right: 0;

      label {
        width: 100%;
        text-align: end;
      }
    }

    .lblPerLb {
      max-width: 22%;
    }

    .lblValueCalculation {
      font-size: 15px;
      font-weight: 600;

      label {
        width: 100%;
        text-align: left;
      }
    }

    .txtKernelValuePerTon,
    .txtELKPremiumPerTon,
    .txtTotalPerTon,
    .txtDamagePerTon,
    .txtAccessFMPerTon,
    .txtExcessSplitsPerTon,
    .txtAFlavusPerTon,
    .txtTotalDiscountsPerTon,
    .txtVicamReading {
      min-width: 72%;
      padding-right: 0;
input{
  max-width: 40%;
  min-width: 180%;
}
.col{
  padding-left: 10px;
}
    }

    .txtKernelValuePerPound,
    .txtELKPremiumPerPound,
    .txtTotalPerPound,
    .txtDamagePerPound,
    .txtAccessFMPerPound,
    .txtExcessSplitsPerPound,
    .txtAFlavusPerPound,
    .txtTotalDiscountsPerPound {
      max-width: 28%;
      padding-left: 0;
      padding-right: 0;
      input{
        padding-right: 10px;
      }
    }
    .txtNetValPerTonExclSLK{
      .col{
        padding-left: 10px;
      }
    }
    
    .txtValPerPoundExclSLK,.txtPerG  {
     min-width: 81%;
     max-width: 81%;
      padding-right: 0;
      .col{
        padding-right: 0;
        padding-left: 10px;
      }
      input{
        padding-right: 8px;
        max-width: 82px;
      }
    }
    .txtValPerPoundInclSLK,.txtOI,.txtH ,.txtTotalLoadVal,.txtNetValPerTonExclSLK  {
      max-width: 89%;
      input{
        padding-left: 8px;
        max-width: 82px;
      }
      .col{
        padding-left: 10px;
      }
    }

    .lblPerLb2,
    .lblPerLb3 {
      max-width: 9%;
      padding-left: 0;
    }

    .btnCalculate{
      padding-bottom: 0.1rem;
    }
  }

  .grpbxSettlementInformation {
    left: 66.6%;
    bottom:346px;
    label{
    width: 100%;
    text-align: right;
    }
    .txtNetWeight,.txtTotalLoadValue,.txtTKCLbs,.txtAgreementPrice,.txtSupportPercent,.txtSegValue,.txtPremiums,.txtDeductions,.txtProceeds{
      input{
        text-align: right;
      }
    }
    .lblSettlementInformation {
      font-size: 15px;
      font-weight: 700px;
      label {
        width: 100%;
        text-align: left;
      }
    }

    .card,
    .row {
      min-height: 0%
    }

    .form-group {
      margin-bottom: 0rem !important;
    }

    .row {
      margin-bottom: 6px;
    }

    label {
      width: 130px;
    }

    

    .txtProceeds {
      min-width: 100%;
    }
  }

  .grpbxSeg {
    bottom: 349px;
    position: relative;

    .card,
    .row {
      min-height: 0%
    }

    .lblPrintSeg {
      max-height: 41px;
      max-width: 7%;
      padding-right: 0;
    }

    .chkboxPrintSeg {
      max-height: 35px;
      max-width: 10%;
      padding-left: 0;
    }

    .txtLot {
      min-width: 28%;

      label {
        width: 14%;
      }
    }

    .dtStartDateTime {
      min-width: 37%;

      label {
        width: 43%;
        text-align: right;
      }
    }

    .txtWormDamage {
        min-width: 33.5%;
        max-width: 40%;
      label {
        text-align: right;
        width: 65.5%;   
      }
    }

    .txtSeal {
      min-width: 65.5%;

      label {
        width:5.9%;
      }
    }
  }

  .txtarInspectionRemarks {
    bottom: 345px;
    position: relative;

    .d-flex {
      display: grid !important;
    }
  }

  .txtarSettleRemarks {
    bottom: 340px;
    position: relative;
  }
  .grpbxActions {
    position: Sticky;
    bottom: 0;
    .card {
      background-color: #f3f0f0;
    }

    .lblAddedBy,
    .lblChangedBy {
      min-width: 6%;
    bottom: 20px;
    padding-right: 0;
    max-width: 7%;
    padding-left: 5px;
      text-align: right;
      label {
        width: 67px;
      }
    }

    .lblAddedByValue,
    .lblChangedByValue {
      padding: 0;
      min-width: 16%;
      max-width: 16%;
      bottom: 20px;
      label{
        display: none;
      }
      .col{
        padding: 0;
        bottom: 5px;
        text-align: left;
      }
      
    }

    .lblCropYear2 {
      label {
        // display: none;
      }
      .col{
        div{
          position:relative;
          top: 5px;
          right: 12px;
        }
      }
      .d-flex {
        max-width: 28%;
        background-color: lightgray;
        padding-left: 10px;
        position: relative;
        width: 100%;
        right: 240px;
        top: 10px;
      }
      font-weight: 500;
      color: red;
      font-size: 16px;
      max-width: 50%;
      margin-left: 45%;
     // background-color: lightgray;
    }

    .btnClose,
    .btnDeductTrack,
    .btnPayments,
    .btnPremsDeduct,
    .btnVoid,
    .btnAcctDist,
    .btnAgreeAppl,
    .btnDelete,
    .btnUnvoid {
      max-width: 9%;
      min-width: 10%;
      bottom: 20px;

      button {
        width: 90%;
      }
    }

    .btnPrintSettlement,
    .btnCreateSettlement,
    .btnReversal {
      margin-left: 4px;
      margin-right: 12px;
      top: 21px;

      button {
        height: 73px;
      }
    }

    .btnSave {
      top: 21px;
      margin-left: 4px;
      margin-right: 12px;
      min-width: 6%;
      max-width: 6%;
      button {
        height: 73px;
        width: 100%;
      }
    }

    .btnDelete {
      left: 7.3%;
      bottom: 20px;
    }

    .btnAgreeAppl {
      left: 26.9%;
      bottom: 20px;
    }

    .btnAcctDist {
      left: 27%;
      bottom: 20px;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}