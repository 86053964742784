.hamburger {
  top: 56px;
  right: 0px;
  position: absolute;
  padding: 15px;
  background: inherit;

  .navbar-nav {
    flex-direction: column;
  }
}
