.ReportUsermanual-FarmSummaryReportRequest,
.ContractManagement-FarmSummaryReportRequest {
  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }

  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .grpbxRadioFarmSummary {
    .card-header {
      display: none;
    }
    .card {
      background-color: #f3f0f0;
    }
    .radioFarmSummary {
      .mb-3 {
        display: flex;
      }
      .form-check {
        max-width: 15%;
        margin-right: 8%;
      }
    }
  }
  .grpbxFarmSummary {
    label {
      width: 105px;
      text-align: right;
    }
    .card-header {
      display: none;
    }
    .card {
      background-color: #f3f0f0;
    }
    .lblAllLocFarmSummaryReport {
      label {
        width: 100%;
        text-align: left;
      }
    }
    .txtCountyID,
    .txtStateAbbr {
      // label{
      //   width: 100px;
      //   text-align: right;
      // }
      input {
        max-width: 12%;
      }
    }
    .txtFarmNumber {
      max-width: 25%;
    }
    .txtFarmSuffix {
      input {
        max-width: 20%;
      }
    }
    .ddReprtFor {
      .dropdown {
        max-width: 35%;
      }
    }
    .txtRequestedVendor,
    .txtSpecificContrct {
      label {
        width: 265px;
        text-align: right;
      }
      input {
        width: 19%;
      }
    }
    .chkboxCombineAllVendors,
    .chkbxAllFarms,
    .chkbxPremDeduct,
    .chkbxAllVendors {
      .form-label {
        display: none;
      }
      .form-check {
        label {
          width: 300px;
          text-align: left;
        }
      }
    }
  }
  .commandcontainerwidget0 {
    .row{
    background-color: #f3f0f0;
    }
    .btnSubmit,
    .btnClose {
      max-width: 6%;
    }
    .btnSubmit {
      margin-left: 45%;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
