.ContractManagement-header, 
.Settlements-header {
  /*START_USER_CODE_FOR_.SCSS*/

  .grpbxHeader {
    top: 0.3px;
    .card-header {
      display: none;
    }
    form{
      height:auto !important;
    }
    .card {
      border-color: #FCB535 !important;
      background-color: #2B3246 !important;
      border: 2.5px solid #FCB535 !important;
    }
    .grpbxWithoutLogo {
      flex: 0 0 28%;
    }

    .LogoImage {
      flex: 0 0 71%;
      padding-bottom: 15px;
    }
    img {
      height: 100px;
      width: 249px;
      left: 5px;
      position: relative;
      top: 0px;
    }

    .card-body {

      .form-group,
      .mb-2 .my-2 {
        margin-bottom: 0.25rem !important;
      }

      .btn-link {
        text-decoration: none;
      }

      .lnkGoldnPnutCmpny {
        min-width: 100%;
        margin-bottom: 0 !important;
        max-height: 37px;
        padding-right: 0;
        button {
          padding-right: 0;
          font-size: 22px;
          padding-left: 0;
          color: white;
          font-weight: 600;
        }
      }

      .lblValueDiv {
        min-width: 100%;
        max-height: 20px;
        display: -webkit-inline-box;

        label {
          font-size: 12px;
          color: white;
        }

        button {
          font-size: 12px;
          color: rgb(252, 181, 35);
        }

        .lblLnkYear,
        .lblLnkScrNmbr {
          max-width: 25%;
          margin-bottom: 0 !important;
          padding-right: 0;
          label{
            margin-bottom: 0 !important;
          }
        }

        .lnkScrNmbr,
        .lnkYear {
          bottom: 4.5px;
          pointer-events: none;
          max-height: 28px;
          margin-bottom: 0 !important;
          padding-left: 0;
        }
      }

      .environment-title {
        width: 67%;
        text-align: center;
        min-width: 67%;
        max-width: 67%;
        text-align: center;
        color: rgb(246,138,78);
        font-size: 22px;
        font-weight: 600;
        top: 43px;
        display: inline-block;
        position: relative;
      }

      .txtScrName {
        min-width: 100%;
        padding-right: 0;
        div{
          display: flex;
        }
        label{
          min-width: 21%;
          margin-bottom: 0 !important;
          top: 5px;
          position: relative;
          color: white;
        }
        input{
          padding-left: 0;
          padding-right: 0;
          background: transparent;
          border: none;
          color:rgb(252, 181, 35);
          min-width: 80.5%;
        }
      }

      .lnkClose {
        max-width: 10px;
        position: fixed;
        left: 97%;
        top: 4%;
        min-width: 1%;
        padding: 0;
        button {
          font-size: 17px;
          width: 100%;
          color: rgb(151, 146, 146);
          font-weight: 900
        }
      }

    }

    /*END_USER_CODE_FOR_.SCSS*/
  }
}