.WarehouseReceipts-DelvPremiumDeductions,.ContractManagement-DelvPremiumDeductions {
    /*START_USER_CODE_FOR_.SCSS*/
    h1{
      display: none;
    }
    // background-color: #333E48;
  background: transparent;
    .groupboxwidget-container{
      .card{
        border: none;
        background-color:  #f3f0f0;
        .card-header{
          display: none;
        }
      }
    }
    .grpbxDelvPremiumDeductions {
      .card{
        .lblPremDeduct {
          max-width: 50%;
          min-width: 50%;
          margin-bottom: 0 !important;
          label{
            margin-bottom: 0 !important;
          }
        }
        .txtConvertedValue {
          max-width: 25%;
          min-width: 25%;
          margin-left: 15%;
          label{
            width: 100px;
            text-align: right;
          }
        }
        .gridPremiumDeductions {
          min-width: 100%;
        }
        .txtNetProceeds {
          min-width: 25%;
          max-width: 25%;
          margin-left: 65%;
          label{
            width: 100px;
            text-align: right;
          }
        }
      }
    }
    .cmmndCntnrActions  {
      .row{
        padding-top: 0.25rem;
        background-color: #f3f0f0;
        .btnOk{
          min-width: 10%;
          max-width: 10%;
          margin-left: 40%;
          button{
            width: 90%;
          }
        }
        .btnCancel{
          min-width: 10%;
          max-width: 10%;
          button{
            width: 90%;
          }
        }
      }
    }
    /*END_USER_CODE_FOR_.SCSS*/
  }
  