.SystemMaintenanceMasterManagement-FarmInquiry,.ContractManagement-FarmInquiry {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  .grpbxFarmDetails{
    margin-bottom: 0 !important;
    .dropdown-menu.show {
      overflow-y: scroll !important;
      max-height: 25vh !important;
      }
    .card-header{
      display: none;
    }
    .card{
      background-color:#f3f0f0;
    }
    label{
      width: 100px;
      text-align: right;
    }
    .ddBuyingPoint{
      // max-width: 30%;
      min-width: 65%;
      .dropdown{
        max-width: 55%;
      }
    }
   .txtBuyingPointID,.txtStateID,.txtCountyID,.txtFarmID{
     display: none !important;
   }
    .ddState,.ddCounty,.ddFarm{
      max-width: 35%;
      min-width: 35%;
    }
    .ddState,.ddCounty{
button{
  max-width: 60%;
  min-width: 60%;
}
    }
    
    .txtVendor{
      max-width: 20%;
      padding-right: 0;
    }
    .btnVendor{
      max-width: 3%;
    }
    .txtVendorName {
      min-width: 45.3%;
      padding-left: 0;
      input{
        max-width: 39%;
      }
    }
    .radioSortBy{
      max-width: 33%;
      .form-check {
        display: inline-flex;
        min-width: 25%;
        label{
          width: 110px;
          text-align: left;
        }
      }
    }
    
    .txtNameSearch{
      min-width:32% ;
    }
  }
  .grpbxFarmList{
    .card-header{
      display: none;
    }
    .card{
      background-color:#f3f0f0;
    }
    .lblFarmList{
      min-width: 37%
    }
    .radioExpandCollapseGrid{
      max-width: 12%;
    }
    .btnSearch{
      min-width: 32%;
    }
    .btnDelete,.btnEdit,.btnAdd{
      max-width: 6%;
    }
    .gridFarmList{
      .card-header{
        display: block;
      }
      .rdt_TableCell:nth-child(2),.rdt_TableCol:nth-child(2){
        min-width: 150px;
        max-width: 150px;
        justify-content: left !important;    
      }
      .rdt_TableCell:nth-child(6),.rdt_TableCol:nth-child(6) {
        justify-content: left !important;   
      }
      .rdt_TableCell:nth-child(9),.rdt_TableCol:nth-child(9),.extraRowCell_8 {
        min-width: 230px;
        max-width: 230px;
        justify-content: left !important;  
      }
      .rdt_TableCell:nth-child(12),.rdt_TableCol:nth-child(12),.extraRowCell_11 {
        min-width: 110px;
        max-width: 110px; 
        text-align: center; 
      }
      .expandable-area{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 8px !important;
      }
      .py-3{
        padding-bottom: 0rem !important;
        padding-top: 0rem !important;
      }
      .rdt_ExpanderRow{
        background-color: #e7f9ff;
      }
      .ExpandRow{
        padding-right: 2%;
      padding-left: 2%;
      border-bottom: 0.7px solid #eeeede;
      }
      .expandIconSpc{
        min-width: 30px;
        max-width: 30px;
      }
      .extraRowCell_1{
        // max-width: 7.1%;
        // min-width: 7.1%;
        max-width: 183px;
        min-width: 183px;
      }
      .extraRowCell_2,.extraRowCell_3,.extraRowCell_4,.extraRowCell_5,.extraRowCell_6,.extraRowCell_7,.extraRowCell_9,.extraRowCell_10{
        // min-width: 8.47%;
        // max-width: 8.47%;
        min-width: 100px;
        max-width: 100px;
        text-align: center;
        padding-top: 6px !important;
      font-size: 0.7rem;
      border-right: 1px solid #eeeede;
      min-height: 34px;
      }
    }
  }
  .grpbxActions{
    .card-header{
      display: none;
    }
    .card{
      background-color:#f3f0f0;
    }
    .btnFarmDataForAccess,.btnCountyDataForeNUTSImport,.btnFarmDataForeNUTSImport{
      max-width: 11%;
      padding-right: 2%;
      button{
        max-width: 85%;
        min-width: 85%;
      }
    }
    .btn1{
      max-width: 10%;
    margin-left: 12%;
    display: none;
    }
    .btnClose{
      max-width: 10%;
    margin-left: 26%;
    position: relative;
    margin-right: 12%;
    button{
      width: 90%;
    }
    }
    .btnExportToExcel,.btnPrintSearch{
      max-width: 10%;
      .row{
        button{
          width: 90%;
        }
      }
    }
    .radioAsShownExpandedCollapsed{
      max-width: 10%;
      padding: 0;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
