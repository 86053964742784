.WarehouseReceipts-EWRViewRRI,.ContractManagement-EWRViewRRI {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }
  .grpbxEWRViewRRI {
    padding-top: 0.25rem;
    .gridEWRViewRRI {
      min-width: 100%;
      .rdt_TableCell:nth-child(5),.rdt_TableCol:nth-child(5){
        min-width: 150px;
        max-width: 150px;
      }
    }
  }
  .cmmndCntnrClose {
    .row{
      padding-top: 0.25rem;
      background-color: #f3f0f0;
      .btnClose{
        min-width: 10%;
        max-width: 10%;
        margin-left: 45%;
        button{
          width: 100%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}