.Settlements-PremiumDeductionsVendorSplitsTrade,.ContractManagement-PremiumDeductionsVendorSplitsTrade {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  .groupboxwidget-container        {
    .card-header{
      display:none
    }
    .card{
      border: none;
      background-color:  #c0bcbc;
    }
  }

  .grpbxPremiumDeductionsVendorSplitsTrade {
    .grpbxPurchase {
      padding: 0;
      .groupboxwidget-container{
        padding: 0;
      }

      .lblPurchase{
        min-width: 100% !important;
        label{
          margin-left: 7%;
          margin-bottom: 0;
          width: 100%;
          text-align: left !important;
          font-weight: bold;
        }
      }
      .lblVendorPrchs {
        min-width: 60% !important;
        margin-left: 5%;
        label{
          margin-bottom: 0;
          width: 100%;
          text-align: center !important;
        }
      }
      .lblSharePctPrchs {
        min-width: 15% !important;
        label{
          margin-bottom: 0;
          width: 100%;
          text-align: center !important;
        }
      }
      .lblShareAmountPrchs {
        min-width: 20% !important;
        label{
          margin-bottom: 0;
          width: 100%;
          text-align: center !important;
        }
      }
      .labelwidget-container{
        padding: 0;
        label{
          width: 100%;
          text-align: right;
        }
        max-width: 5%;
        min-width: 5%;
      }
      .txtVendor1,.txtVendor2,.txtVendor3,.txtVendor4,.txtVendor5,.txtVendor6,.txtVendor7,.txtVendor8,.txtVendor9,.txtVendor10,.txtVendor11,.txtVendor12,.txtVendor13,.txtVendor14,.txtVendor15,.txtVendor16,.txtVendor17,.txtVendor18,.txtVendor19,.txtVendor20{
        input{
          color: blue;
        }
      }
      [class^="txtVendor"]{
        min-width: 60% !important;
        max-width: 60% !important;
        padding: 0;
        .col{
          padding-right: 0;
          padding-left: 0.5rem
        }
      }
      .txtShareAmount1,.txtShareAmount2,.txtShareAmount3,.txtShareAmount4,.txtShareAmount5,.txtShareAmount6,.txtShareAmount7,.txtShareAmount8,.txtShareAmount9,.txtShareAmount10,.txtShareAmount11,.txtShareAmount12,.txtShareAmount13,.txtShareAmount14,.txtShareAmount15,.txtShareAmount16,.txtShareAmount17,.txtShareAmount18,.txtShareAmount19,.txtShareAmount20{
        input{
          color: blue;
        }
      }
      [class^="txtSharePct"]{
        min-width: 15% !important;
        max-width: 15% !important;
        padding: 0;
        .col{
          padding-right: 0;
          padding-left: 0.5rem
        }
      }
      [class^="txtShareAmount"]{
        min-width: 20% !important;
        max-width: 20% !important;
        padding: 0;
        .col{
          padding-right: 0;
          padding-left: 0.5rem
        }
      }


      .txtTotalSharePct{
        input{
          color: blue;
        }
        min-width: 80%;
        max-width: 80%;
        padding: 0;
        .d-flex{
          margin-left: 75.5%;
        }
        .col{
          padding-right: 0;
          padding-left: 0.5rem
        }
      }
      .txtTotalShareAmount {
        input{
          color: blue;
        }
        max-width: 20%;
        min-width: 20%;
        padding: 0;
        .col{
          padding-right: 0;
          padding-left: 0.5rem
        }
      }
    }
    .grpbxStorage{
      padding: 0;
      .groupboxwidget-container{
        padding: 0;
      }

      .lblStorage{
        min-width: 100% !important;
        label{
          margin-left: 7%;
          margin-bottom: 0;
          width: 100%;
          text-align: left !important;
          font-weight: bold;
        }
      }
      .lblVendorStrg {
        min-width: 60% !important;
        margin-left: 5%;
        label{
          margin-bottom: 0;
          width: 100%;
          text-align: center !important;
        }
      }
      .lblSharePctStrg {
        min-width: 15% !important;
        label{
          margin-bottom: 0;
          width: 100%;
          text-align: center !important;
        }
      }
      .lblShareAmountStrg {
        min-width: 20% !important;
        label{
          margin-bottom: 0;
          width: 100%;
          text-align: center !important;
        }
      }
      .labelwidget-container{
        padding: 0;
        label{
          width: 100%;
          text-align: right;
        }
        max-width: 5%;
        min-width: 5%;
      }
      .txtVendor1s,.txtVendor2s,.txtVendor3s,.txtVendor4s,.txtVendor5s,.txtVendor6s,.txtVendor7s,.txtVendor8s,.txtVendor9s,.txtVendor10s,.txtVendor11s,.txtVendor12s,.txtVendor13s,.txtVendor14s,.txtVendor15s,.txtVendor16s,.txtVendor17s,.txtVendor18s,.txtVendor19s,.txtVendor20s{
        input{
          color: blue;
        }
      }
      [class^="txtVendor"]{
        min-width: 60% !important;
        max-width: 60% !important;
        padding: 0;
        .col{
          padding-right: 0;
          padding-left: 0.5rem;
        }
      }
      [class^="txtSharePct"]{
        min-width: 15% !important;
        max-width: 15% !important;
        padding: 0;
        .col{
          padding-right: 0;
          padding-left: 0.5rem
        }
      }
      .txtShareAmount1s,.txtShareAmount2s,.txtShareAmount3s,.txtShareAmount4s,.txtShareAmount5s,.txtShareAmount6s,.txtShareAmount7s,.txtShareAmount8s,.txtShareAmount9s,.txtShareAmount10s,.txtShareAmount11s,.txtShareAmount12s,.txtShareAmount13s,.txtShareAmount14s,.txtShareAmount15s,.txtShareAmount16s,.txtShareAmount17s,.txtShareAmount18s,.txtShareAmount19s,.txtShareAmount20s{
        input{
          color: blue;
        }
      }
      [class^="txtShareAmount"]{
        min-width: 20% !important;
        max-width: 20% !important;
        padding: 0;
        .col{
          padding-right: 0;
          padding-left: 0.5rem
        }
      }


      .txtTotalSharePcts{
        input{
          color: blue;
        }
        min-width: 80%;
        max-width: 80%;
        padding: 0;
        .d-flex{
          margin-left: 75.5%;
        }
        .col{
          padding-right: 0;
          padding-left: 0.5rem
        }
      }
      .txtTotalShareAmounts {
        input{
          color: blue;
        }
        max-width: 20%;
        min-width: 20%;
        padding: 0;
        .col{
          padding-right: 0;
          padding-left: 0.5rem
        }
      }
    }
  }
  .cmmndCntnrActions{
    // padding-top: 0.5rem;
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    
    .btnOk,.btnCancel{
      min-width: 10%;
      max-width: 10%;
      button{
        width: 90%;
      }
    }
    .btnOk{
      margin-left: auto;
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
