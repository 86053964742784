.SystemMaintenanceSpecialFunctions-SecurityLocationSelect,.ContractManagement-SecurityLocationSelect {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxSecurityLocationSelect{
    margin-bottom: 0.2rem !important;
    .card{
      padding-top: 0.2rem;
      border: none;
      background-color: #c0bcbc;
      height: 220px;
      .card-header{
        display:none;
      }
      label{
        width: 125px;
        text-align: right;
      }
      .txtKeyInfo{
        min-width: 100%;
        input{
          width: 60%;
          padding: 0;
          border: 0;
          box-shadow: none;
          color: blue;
          background: transparent;
        }
      }
      .ddBuyingPoint{
        min-width: 100%;
        .dropdown{
          width: 60%;
        }
        .dropdown-menu.show {
          overflow-y: scroll !important;
          max-height: 25vh !important;
          }
      }
      .ddAccessLevel{
        min-width: 100%;
        .dropdown{
          width: 30%;
        }
      }
    }
  }
  .grpbxActions  {
      .card{
        .card-header{
          display:none
          }
      border: none;
      background-color: #f3f0f0;
      .lblChangedBy,.lblAddedBy {
        max-width: 10%;
        label{
          width: 100%;
          text-align: right;
        }
      }
      .lblChangedByValue,.lblAddedByValue{
        min-width: 40%;
        margin-right: 30%;
        padding-left: 0;
        label{
          display: none;
        }
        .col{
          position: relative;
          right: 23px;
          bottom: 6.5px;
        }
      }
      .btnUpdate,.btnCancel{
        max-width: 10%;
        top: 15px;
        button{
          width: 85%;
        }
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
