.StockTransfer-MultiStopSelect,.ContractManagement-MultiStopSelect {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display: none;
  }
  // background-color: #333E48;
  background: transparent;
  .groupboxwidget-container{
    .card{
      border: none;
      background-color:  #f3f0f0;
      .card-header{
        display: none;
      }
    }
  }

  .grpbxDetails {
    .ddOriginalLocation ,.ddDestinationLocation {
      min-width: 60%;
      max-width: 60%;
      label{
        width: 150px;
        text-align: right;
      }
    }
    .ddTransferType {
      min-width: 100%;
      .dropdown{
        width: 25%;
      }
      #ddItem_{
        height: 25px;
      }
      label{
        width: 150px;
        text-align: right;
      }
      .dropdown-toggle:empty::after {
        margin-left: auto;
      }
      button{
      height:30px;
      }
    }
    .dropdown-menu.show{
      overflow-y:scroll!important;
      max-height:25vh!important;
      }
    .txtScacID ,.txtVendorNum ,.txtVehicleNum{
      max-width: 40%;
      min-width: 40%;
      label{
        width: 100px;
        text-align: right;
      }
    }
    .txtScacID ,.txtVendorNum{
      input{
        width: 60%;
      }
    }
    .txtTransferNum {
      min-width: 60%;
      max-width: 60%;
      label{
        width: 150px;
        text-align: right;
      }
      input{
        width: 50%;
      }
    }
    .btnSearch{
      min-width: 20%;
      max-width: 20%;
      margin-left: 40%;
      button{
        width: 90%;
      }
    }
  }

  .grpbxTransferList {
    .card-body{
      padding-left: 0;
      padding-right: 0;
    }
    .gridTransferList{
      .card-header{
        display: block;
      }
     .rdt_TableCol{
       input{
        display: none !important;
       }
     }
     .rdt_TableCol:nth-child(1),.rdt_TableCol:nth-child(2),
     .rdt_TableCol:nth-child(3),
     .rdt_TableCol:nth-child(4),
     .rdt_TableCol:nth-child(5),.rdt_TableCol:nth-child(6),
     .rdt_TableCol:nth-child(7),.rdt_TableCol:nth-child(8),
     .rdt_TableCol:nth-child(9)
     {
       position: relative;
       top: 15px;
     }
    }
    .lblTransferList {
      min-width: 20%;
      max-width: 20%;
      label{
        width: 100%;
        text-align: left;
      }
    }
    .btnSelect{
      min-width: 20%;
      max-width: 20%;
      margin-left: 60%;
      button{
        width: 90%;
      }
    }
  }

  .cmmndDntnrActions {
    .row{
      background-color: #f3f0f0;
      padding-top: 0.2rem;
    }
    .btnClose{
      min-width: 10%;
      max-width: 10%;
      margin-left: 45%;
      button{
        width: 90%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
