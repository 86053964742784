.SystemMaintenanceApplicationSupport-InventoryAdjustments,.ContractManagement-InventoryAdjustments {
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
  .grpbxInventoryAdjustments{  
      .card-header{
        display:none
      }
      .card{
        border: none;
        background-color: #f3f0f0;
        .ddBuyingPoint {
          min-width: 80%;
          max-width: 80%;
          margin-left: 10%;
          .d-flex{
            display: block !important;
            .col{
              padding: 0;
              max-width: 50%;
              .dropdown-menu{
                max-height: 200px;
                overflow: auto;
              }
            }
          }
        }
        .lblAdjustments {
          min-width: 100%;
          label{
            margin-bottom: 0;
          }
        }
        .gridAdjustments {
          min-width: 100%;
          .popover-body{
            button:nth-child(2){
              display: none;
            }
          }
          .card-header{
            display: block !important;
          }
          .card{
            border: 1px solid #c0bcbc !important;
            .dropdown-menu{
              max-height: 120px;
              overflow: auto;
            }
            .rdt_TableHead{
              position: relative;
           }
            #cell-ddcolWhseBin_ID-0,#cell-ddcolPeanutType_ID-0,#cell-ddcolPeanutVariety_ID-0,
            #cell-ddcolSeg_ID-0,#cell-ddcolSeedGen_ID-0,#cell-ddcolOleic_ID-0,#cell-ddcolOrganic_ID-0{
            .dropdown-item{
              height: 30px;
            }
          }
            .rdt_TableBody{
              min-height: 300px;
            }
            .rdt_TableCol:nth-child(1),
            .rdt_TableCell:nth-child(1){
              min-width: 180px;
              max-width: 180px;
              .dropdown-menu{
                max-height: 300px;
                overflow: auto;
              }
            }
          .bootstrap-datetimepicker-widget{
            min-width: 200%;
            max-width:200%;
          }
            .rdt_TableCell:nth-child(3),.rdt_TableCell:nth-child(4),.rdt_TableCell:nth-child(10){
              justify-content: left;
            }
            .rdt_TableCell:nth-child(9){
              justify-content: right;
            }
            .rdt_TableCol:nth-child(4),
            .rdt_TableCell:nth-child(4){
              min-width: 150px;
              max-width: 150px;
            }
            .rdt_TableCol:nth-child(10),
            .rdt_TableCell:nth-child(10){
              min-width: 250px;
              max-width: 250px;
            }
            .rdt_TableCol:nth-child(12),
            .rdt_TableCell:nth-child(12){
              min-width: 200px;
              max-width: 200px;
            }
          }
        }
      }
  }

  .cmmndCntnrActions {
    .row{
      padding-top: 0.25rem;
      background-color: #f3f0f0;
      .buttonwidget-container{
        min-width: 10%;
        max-width: 10%;
        button{
          width: 95%;
        }
      }
      .btnOk{
        margin-left: 58%;
      }
      .btnExporttoExcel {
        min-width: 11%;
        max-width: 11%;
      }
    }
  }
  /*END_USER_CODE_FOR_.SCSS*/
}
