.ContractManagement-TradeAgreeProfile,
.Settlements-TradeAgreeProfile {

  /*START_USER_CODE_FOR_.SCSS*/
  .ContractManagement-header {
    .LogoImage {
      // display: none;
    }
  }

  // background-color: #333e48;
  // background-image: url(../../../../assets/img/BgLogo.png);
  .card-header,
  h1 {
    display: none;
  }

  // background-color: #c0bcbc ;
  .card {
    border: none;
  }

  .lblVndrSplts,
  .lblPricingInfo {
    font-size: 16px;
    font-weight: 600;

    label {
      width: 142px !important;
    }
  }

  .grpbxPeanutInfo {
    min-width: 50%;

    .card {
      background-color: #f3f0f0 !important;
      height: 100%;
    }

    .lblPnutInfo {
      label {
        width: 141px !important;
      }

      font-size: 16px;
      font-weight: 600;
    }

    label {
      width: 80px;
      text-align: right;
    }

    .ddPeanutType,
    .ddPeanutVariety {
      min-width: 60%;

      .dropdown {
        width: 109%;
      }

      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }

    .ddGeneration {
      min-width: 60%;
      padding-right: 0;
      button {
        height: 30px;
      }
      .dropdown-toggle:empty::after {
        margin-left: auto;
    }
    #ddItem_{
      height: 25px;
    }
    }

    .ddSeg,
    .ddOleic,
    .ddEdibleOil {
      min-width: 40%;
      max-width: 40%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  // .grpbxPeanutInfo {
  //   min-width: 50%;
  //   .card {
  //     background-color: #f3f0f0 !important;
  //     height: 100%;
  //   }
  //   .lblPnutInfo {
  //     label {
  //       width: 141px !important;
  //     }
  //     font-size: 16px;
  //     font-weight: 600;
  //   }

  //   label {
  //     width: 80px;
  //     text-align: right;
  //   }
  //   .ddPeanutType,
  //   .ddPeanutVariety 
  //   // {
  //   // .dropdown-menu.show {      
  //   //   overflow-y: scroll !important;      
  //   //   max-height: 25vh !important;  
  //   // }
  //   // }
  //   .ddGeneration ,.ddPeanutVariety {
  //     min-width: 60%;
  //     padding-right: 0;
  //   }
  //   .ddSeg,
  //   .ddOleic,
  //   .ddEdibleOil {
  //     min-width: 40%;
  //     max-width: 40%;
  //     padding-left: 0;
  //     padding-right: 0;
  //   }
  // }
  .grpbxTrdSalePrchs {
    min-width: 50%;
    padding-right: 0;

    .card {
      border: none;
      background-color: #f3f0f0 !important;
      height: 100%;
    }

    // .radioGradePricingMethod{
    //   max-height: 42px;
    //   label{
    //     text-align: left;
    //   }
    //   .d-flex{
    //     display: grid !important;
    //   }
    // }
    .radioTradepricemethod {
      .mb-3 {
        display: flex;
      }

      label {
        text-align: left;
      }

      max-width: 62%;
      // left: 25%;
    }

    label {
      width: 134px;
      // text-align: right;
    }

    .radioTradeSaleTradePurchase {
      .mb-3 {
        display: flex;
      }

      label {
        text-align: left;
      }

      max-width: 62%;
      left: 25%;
      height: 46px !important;
    }

    label {
      width: 134px;
      text-align: right;
    }

    .ddLocation {
      min-width: 46%;

      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }

    .txtAgreement {
      input {
        max-width: 23%;
      }
    }

    .txtAgreementDate {
      input {
        max-width: 50%;
      }
    }

    .ddAgreementStatus {
      .dropdown {
        width: 60%;
      }

      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }
  }

  .grpbxTxt {
    max-width: 18%;
    padding-left: 0;

    .card {
      border: none;
      background-color: #f3f0f0 !important;
    }
  }

  .grpbxAgreementInfo {
    .card {
      background-color: #c0bcbc;
    }
  }

  .grpbxPricingInfo {
    max-width: 42%;

    .card {
      background-color: #f3f0f0 !important;
      height: 100%;
    }

    label {
      width: 120px;
      text-align: right;
    }

    .radioGradePricingMethod {
      max-height: 50px;

      .form-check {
        label {
          text-align: left;
        }
      }
    }

    .ddFrimBasisFlat {

      .dropdown-menu.show {
        overflow-y: scroll !important;
        max-height: 25vh !important;
      }
    }

    .txtPrice {

      // min-width: 59.5%;
      // padding-right: 0;
      input {
        max-width: 41%;
        text-align: right;
      }
    }

    .txtTypeID {
      max-width: 30%;
    }

    .txtAgreedPounds {
      input {
        max-width: 41%;
        text-align: right;
      }
    }

    .lblAppldPounds,
    .lblOpenBlnc {
      max-width: 30%;
    }

    .lblAppldPoundsVal {
      input {
        max-width: 41%;
        text-align: right;
      }
    }

    .lblOpenBlncVal {
      input {
        max-width: 41%;
        text-align: right;
      }
    }
  }

  .grpbxVndrPrcngInfo {
    margin-bottom: 0 !important;
    .card {
      background-color: #c0bcbc;
    }
  }

  .grpbxVendorSplitRow5 {
    min-width: 100%;

    .ddRemitTo6 {
      max-width: 25%;
    }

    .txtSharePercentage6 {
      max-width: 20%;
    }
  }

  .grpbxVendorSplits {
    min-width: 58%;

    .lblVendor {
      max-width: 35%;
      min-width: 32%;

      label {
        width: 100%;
        text-align: center;
      }
    }

    .lblRemitTo {
      max-width: 43%;

      label {
        width: 100%;
        text-align: center;
      }
    }

    .lblSharePercentage {
      max-width: 20%;

      label {
        width: 100%;
        text-align: center;
      }
    }
  }

  .grpbxVendorSplitSub {
    max-height: 250px;
    min-height: 250px;
    overflow-y: overlay;
    .groupboxwidget-container{
      padding: 0;
    }
    .card {
      border: none;
      background-color: #c0bcbc;
    }

    .txtVendor6 {
      min-width: 24%;
      max-width: 24%;
    }

    .txtVendor1,
    .txtVendor2,
    .txtVendor3,
    .txtVendor4,
    .txtVendor5,
    .txtVendor6,
    .txtVendor7,
    .txtVendor8,
    .txtVendor9,
    .txtVendor10,
    .txtVendor11,
    .txtVendor12,
    .txtVendor13,
    .txtVendor14,
    .txtVendor15,
    .txtVendor16,
    .txtVendor17,
    .txtVendor18,
    .txtVendor19,
    .txtVendor20 {
      min-width: 24%;
      max-width: 24%;
      padding-right: 0;
    }

    .btn1,
    .btn2,
    .btn3,
    .btn4,
    .btn5,
    .btn6,
    .btn7,
    .btn8,
    .btn9,
    .btn10,
    .btn11,
    .btn12,
    .btn13,
    .btn14,
    .btn15,
    .btn16,
    .btn17,
    .btn18,
    .btn19,
    .btn20 {
      max-width: 6%;
    }

    .ddRemitTo1,
    .ddRemitTo2,
    .ddRemitTo3,
    .ddRemitTo4,
    .ddRemitTo5,
    .ddRemitTo6,
    .ddRemitTo7,
    .ddRemitTo8,
    .ddRemitTo9,
    .ddRemitTo10,
    .ddRemitTo11,
    .ddRemitTo12,
    .ddRemitTo13,
    .ddRemitTo14,
    .ddRemitTo15,
    .ddRemitTo16,
    .ddRemitTo17,
    .ddRemitTo18,
    .ddRemitTo19,
    .ddRemitTo20 {
      min-width: 47.5%;
      padding-right: 0;

      button {
        height: 30px;
      }
    }

    .txtSharePercentage1,
    .txtSharePercentage2,
    .txtSharePercentage3,
    .txtSharePercentage4,
    .txtSharePercentage5,
    .txtSharePercentage6,
    .txtSharePercentage7,
    .txtSharePercentage8,
    .txtSharePercentage9,
    .txtSharePercentage10,
    .txtSharePercentage11,
    .txtSharePercentage12,
    .txtSharePercentage13,
    .txtSharePercentage14,
    .txtSharePercentage15,
    .txtSharePercentage16,
    .txtSharePercentage17,
    .txtSharePercentage18,
    .txtSharePercentage19,
    .txtSharePercentage20 {
      min-width: 20%;
      padding-left: 0;
      padding-right: 0;
    }
    [class^="lblXX"]{
      .col{
        color: blue;
      }
    }
    input {
      max-width: 87%;
    }

    .txtVendorID1,
    .txtRemitTo1,
    .txtSharePercent1,
    .txtVendorID2,
    .txtRemitTo2,
    .txtSharePercent2,
    .txtVendorID3,
    .txtRemitTo3,
    .txtSharePercent3,
    .txtVendorID4,
    .txtRemitTo4,
    .txtSharePercent4 {
      padding-left: 0;
      padding-right: 0;
    }

    .txtSharePercent1,
    .txtSharePercent2,
    .txtSharePercent3,
    .txtSharePercent4 {
      min-width: 17%;
    }

    .txtVendorID1,
    .txtRemitTo1,
    .txtVendorID2,
    .txtRemitTo2,
    .txtVendorID3,
    .txtRemitTo3,
    .txtVendorID4,
    .txtRemitTo4 {
      max-width: 20%;
    }

    .txtSplitRemit1,
    .txtSplitRemit2,
    .txtSplitRemit3,
    .txtSplitRemit4 {
      padding-right: 0;
      min-width: 20%;
      padding-left: 0.3%;
    }

    .lblXxx1,
    .lblXxx2,
    .lblXxx3,
    .lblXxx4 {
      min-width: 27%;
    }

    // .txtVendorID1,
    // .txtVendorID2,
    // .txtVendorID3,
    // .txtVendorID4 {
    //   margin-left: 38%;
    // }
  }

  .grpbxApplicationInfo {
    .card {
      background-color: #f3f0f0;
    }

    .lblApplicationInfo {
      min-width: 83%;
      font-size: 15px;
      font-weight: 600;
    }


    .gridApplicationInfo {

      // .card-header {
      //   display: none;
      // }
      .card {
        background-color: #f3f0f0;
      }

      .btn.btn-link {
        color: black;
      }
    }
  }


  .grpbxComments {
    margin-bottom: 0 !important;
    .card {
      background-color: #f3f0f0;
    }

    .txtarComments {
      .d-flex {
        display: grid !important;
      }
    }
  }

  .grpbxActions {
    .card {
      background-color: #f3f0f0;
    }

    .btn5 {
      // min-width: 45%;
      display: none;
    }

    .lblCropYear2 {
      // margin-left: 64%;
      max-width: 17%;
      margin-left: 43%;
      background-color: lightgray;
      font-weight: 500;
      color: red;
      font-size: 16px;
      // margin-right: 12px;
    }

    .txtAddedBy,
    .txtChangedBy {
      min-width: 41%;

      label {
        width: 68px;
        text-align: right;
      }
    }

    .btnUpdate,
    .btnDelete {
      max-width: 7%;
    }

    .btnClose,
    .btnPrint {
      max-width: 6%;
    }
  }

  /*END_USER_CODE_FOR_.SCSS*/
}