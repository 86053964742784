.SystemMaintenanceMasterManagement-PremiumDeductionVendorProfile,.ContractManagement-PremiumDeductionVendorProfile{
  /*START_USER_CODE_FOR_.SCSS*/
  h1{
    display:none
  }
  // background-color: #333E48;
  background: transparent;
.grpbxPremiumDeductionVendorProfile {
  
  .card{
    padding-top: 0.5rem;
    border: none;
    background-color: #c0bcbc;
    .card-header{
      display:none
    }
    label{
      width: 150px;
      text-align: right;
    }
  
    .txtBuyingPoint,.txtPremDeductCode {
      min-width: 80%;
      max-width: 80%;
      margin-left: 20%;
      padding-right: 0.2rem;
      label{
        text-align: right;
      }
      .col{
        input{
          padding-left: 0;
          color: blue;
          background:transparent;
          border: none;
          box-shadow: none;
          position: relative;
          bottom: 2px;
        }
      }
    }
  
    
  
    .txtVendor{
      padding-right: 0;
      max-width: 30%;
      margin-left: 20%;
      label{
        color: #c02020;
      }
    } 
    .txtVndr{
      max-width: 50%;
      min-width: 50%;
      margin-left: 10%;
      .col{
        padding-left: 269px;
        input{
          color: blue;
          background:transparent;
          border: none;
          box-shadow: none;
        }
      }
    }
    .btnVendor{
      max-width: 5%;
      margin-bottom: 0.3rem !important;
      button{
        width: 75%;
      }
    }
    .lblVendorID{
      margin-left: 35%;
      label{
        text-align: left;
      }
    }
    .ddRemitTo{
      max-width: 50%;
      margin-left: 20%;
    }
    // .txtContract{
    //   max-width: 80%;
    //   margin-left: 20%;
    //   input{
    //     width: 30%;
    //   }
    // }
  }
  
}

.grpbxActions{
  .card-header{
  display:none
  }
  .card{
  border: none;
  background-color: #f3f0f0;
  }
  .lblAddedBy,.lblAddedByValue,.lblChangedBy,.lblChangedByValue{
    label{
      display: none;
    }
  }
  .btnOk{
    max-width: 8%;
    margin-left: 34%;
    top: 5px;
  }
  .btnCancel{
    max-width: 7%;
    top: 5px;
  }
}
  /*END_USER_CODE_FOR_.SCSS*/
}
