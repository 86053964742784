.StockTransfer-StockTransfer,
.ContractManagement-StockTransfer {

  /*START_USER_CODE_FOR_.SCSS*/
  h1 {
    display: none;
  }

  // background-color: #333E48;
  background: transparent;
.dropdownfieldwidget-container{
     #ddItem_{
      height: 25px;
    }	  
    .dropdown-toggle:empty::after {
      margin-left: auto;
    }
      button{
      height:30px;
   }
}
  .groupboxwidget-container {
    .card {
      border: none;
      background-color: #f3f0f0;

      .card-header {
        display: none;
      }
    }
  }
  .textboxwidget-container{
    input:focus{
      background-color: yellow;
    }
  }

  .grpbxStockTransfer {
    .grpbxNewPending {
      .card-body {
        padding: 0;

        .lblNew {
          font-weight: bold;
          min-width: 10%;
          max-width: 10%;
          // border: 1px solid rgb(151, 146, 146);
          // background-color: gray;
          padding: 10px 13px;

          label {
            width: 100%;
            text-align: center;
            font-size: large;
          }

          .col {
            font-weight: bolder;
            font-size: 15px;
          }
        }

        .chkbxMnlOverRide {
          .form-label {
            display: none;
          }
        }

        .lblPending {
          font-weight: bold;
          min-width: 15%;
          max-width: 15%;
          border: 1px solid rgb(151, 146, 146);
          background-color: #fdf9c8;

          //padding: 1px 16px 1px 1px;
          // margin-left: 32.5%;
          label {
            // width: 100%;
            text-align: center;
            font-size: large;
          }

          .d-flex {
            text-align: center;
          }

          .col {
            font-weight: bold;
            font-size: 25px;
          //   position: relative;
          //  right: 30px;
          }
        }

        .ddType {
          max-width: 25%;
          margin-left: 15.5%;
        }
      }

      border: 1px solid #ccc;
    }

    .grpbxLocation {
      .card-body {
        padding: 0;

        .lblLocation {
          min-width: 100%;

          label {
            width: 100%;
            text-align: left;
            font-weight: bold;
          }
        }

        .ddShippingLoc,
        .ddDestinationLoc {
          min-width: 45%;
          max-width: 45%;

          label {
            width: 100px;
            text-align: right;
          }
        }

        .txtAddShip,
        .txtAddDest {
          min-width: 35%;
          max-width: 35%;
        }

        .txtPhnShip,
        .txtPhnDest {
          min-width: 20%;
          max-width: 20%;
          padding-right: 0;
        }
      }

      border: 1px solid #ccc;

    }

    .grpbxMid {
      .card-body {
        padding: 0;
      }

      .grpbxLoadShippedRcvd {
        min-width: 60%;
        margin-bottom: 0 !important;

        .grpbxLoad {
          border: 1px solid #ccc;

          .lblLoad {
            min-width: 10%;
            max-width: 10%;

            label {
              width: 100%;
              font-weight: bold;
            }
          }

          .txtDispatch {
            max-width: 35%;
            min-width: 35%;
            padding: 0;

            label {
              width: 60px;
              text-align: right;
            }
          }
          .txtDispatch,.txtTruckLine,.txtVehicle,.txxtDriver,.txt1007{
            input:focus{
              background-color: yellow;
            }
          }
          .txt1007,
          .txtVehicle {
            max-width: 35%;
            min-width: 35%;
            margin-left: 10%;
            padding: 0;

            label {
              width: 60px;
              text-align: right;
            }
          }

          .txtTruckLine,
          .txxtDriver,
          .ddPayFreight {
            max-width: 55%;
            min-width: 55%;
            padding: 0;

            label {
              width: 62px;
              text-align: right;
            }
          }

          .txtOtherWt {
            max-width: 42%;
            min-width: 42%;
            margin-left: 28%;

            label {
              width: 85px;
              text-align: right;
            }
          }

          #txtOtherWt {
            text-align: right;
          }

          .lblLeasedTrailer {
            max-width: 30%;
            min-width: 30%;
            font-size: medium;
            font-weight: bold;
            padding: 0;
          }
        }

        .grpbxShippd {
          border: 1px solid #ccc;

          .lblShipped {
            min-width: 100%;

            label {
              width: 100%;
              text-align: left;
              font-weight: bold;
            }
          }
          .dtShipDate,.txtWtVehicleShip,.txtSclTcktShip,.txtWtVehclShip,
          .txtPeanutsShipm{
            input:focus{
              background-color: yellow;
            }
          }
          .textboxwidget-container,
          .dtShipDate,
          .chkbxSplitWtShip {
            min-width: 50%;
            padding: 0;

            label {
              width: 120px;
              text-align: right;
            }
          }

          .txtWtVehicleShip,
          .txtWtVehclShip,
          .txtPeanutsShip {
            input {
              text-align: right;
            }
          }

          .chkbxSplitWtShip {
            input {
              margin-top: 0.5rem;
            }

            .form-check-label {
              display: none;
            }
          }
        }

        .grpbxRecvd {
          border: 1px solid #ccc;
          .dtRcvdDate,.txtWtInclVehclRecvd,.txtSclTcktRcvd,.txtWtVhclRcvd,.txtPeanutsRecvd{
            input:focus{
              background-color: yellow;
            }
          }
          .lblReceived {
            min-width: 60%;
            max-width: 60%;

            label {
              width: 100%;
              text-align: left;
              font-weight: bold;
            }
          }

          .txtWtInclVehclRecvd,
          .txtWtVhclRcvd,
          .txtPeanutsRecvd {
            input {
              text-align: right;
            }
          }

          .lblRecvdMsg {
            min-width: 40%;
            max-width: 40%;

            label {
              color: red;
              font-weight: bold;
              font-size: 16px;
            }
          }

          .textboxwidget-container,
          .dtRcvdDate,
          .chkbxSplitWtRcvd {
            min-width: 50%;
            padding: 0;

            label {
              width: 120px;
              text-align: right;
            }
          }

          .chkbxSplitWtRcvd {
            input {
              margin-top: 0.5rem;
            }

            .form-check-label {
              display: none;
            }
          }
        }

        .grpbxFreight {
          border: 1px solid #ccc;
          .txtLegMsg,.txtInvoice,.txtMileageOvrrd,.txtCarrAbbr
            {
              input:focus{
                background-color: yellow;
              }
            }
          .lblFrghtAccrual {
            min-width: 33%;
            max-width: 33%;

            label {
              width: 100%;
              text-align: left;
              font-weight: bold;
            }
          }

          .lblCompleted {
            max-width: 21.5%;
            min-width: 21.5%;
            border: 1px solid rgb(151, 146, 146);
            background-color: #fdf9c8;

            label {
              font-weight: bold;
              font-size: 20px;
            }
          }

          .txtInvoice {
            min-width: 55%;
            max-width: 55%;
            padding: 0;

            label {
              width: 110px;
              text-align: right;
            }
          }

          .txtCarrAbbr {
            min-width: 50%;
            max-width: 50%;
            padding: 0;
            bottom: 17px;

            label {
              width: 110px;
              text-align: right;
            }
          }

          .txtVndrId {
            min-width: 45%;
            max-width: 45%;
            padding-left: 0;
            bottom: 17px;

            label {
              width: 110px;
              text-align: right;
            }
          }

          .btnPopup {
            min-width: 5%;
            max-width: 5%;
            padding: 0;
            bottom: 17px;
            margin-bottom: 0.4rem !important;
          }

          .txtarCarrAbbr {
            min-width: 50%;
            max-width: 50%;
            padding: 0;

            .col {
              padding-left: 0;
            }
          }

          .txtLegMsg,
          .txtMileageOvrrd {
            min-width: 42%;
            max-width: 42%;
            padding: 0;

            label {
              width: 100px;
              text-align: right;
            }

            input {
              text-align: right;
            }
          }
        }

        .grpbxUpdateInvnt {
          border: 1px solid #ccc;
          margin-bottom: 0 !important;

          .ddUpdtInvent {
            padding-top: 0.25rem;
            max-width: 62%;
            min-width: 62%;

            label {
              text-align: right;
              font-weight: bold;
            }

            button {
              width: 200%;
            }

            .dropdown {
              width: 50%;
            }
          }

          .txtUpdtInvent {
            max-width: 38%;
            min-width: 38%;
          }
        }
      }

      .grpbxGridCorn {
        max-width: 40%;
        padding-left: 1.25rem;

        .grpbxShippdFrmTo {
          border: 1px solid #ccc !important;

          .labelwidget-container {
            min-width: 30%;
            max-width: 30%;
            border: 1px solid #ccc;
            margin-bottom: 0 !important;
            label {
              width: 100%;
              text-align: right;
            }
          }

          .lblShippdFrm,
          .lblShippdTo {
            min-width: 35%;
            max-width: 35%;

            label {
              width: 100%;
              text-align: center;
            }
          }

          .lblShippdFrm {
            margin-left: 30%;
          }

          .textboxwidget-container {
            min-width: 35%;
            max-width: 35%;
            padding: 0;
            .col{
              padding: 0;
            }
          }

          .txtPnutShippdFrm,
          .txtSegShppdFrm {
            padding-left: 0;
            padding-right: 0;
            min-width: 69%;
          }

          .txtPnutShippdFrm {
            .col {
              padding: 0;
            }
          }
          .txtPnutShippdFrm,.ddVarietyShppdFrm,.ddVarietyShppdTo,.ddGenerationShippdFrm,
          .ddGenerationShippdTo,.ddOleicShippdFrm,.ddOleicShippdTo{
            #ddItem_{
              height: 25px;
            }	  
             .dropdown-toggle:empty::after {
                 margin-left: auto;
              }
             button{
               height:30px;
              }
          }
          .ddVarietyShppdFrm,
          .ddGenerationShippdFrm,
          .ddOleicShippdFrm {
            min-width: 34%;
            left: 2px;
            padding-right: 0;
            padding-left: 0;

            .col {
              padding: 0;
            }
          }

          .ddVarietyShppdTo,
          .ddGenerationShippdTo,
          .ddOleicShippdTo {
            min-width: 34.5%;
            left: 5px;
            padding-left: 0;
            padding-right: 0;
            .col {
              padding: 0;
            }
          }
        }

        .grpbxFromToBin {
          border: 1px solid #ccc;

          .chkbxCornFnd {
            min-width: 35%;
            max-width: 35%;
            margin-right: 5%;
            padding-right: 0;

            .form-label {
              display: none;
            }

            .d-flex {
              margin-bottom: 5%;
            }
          }

          .btnFrmBin,
          .btnToBin {
            max-width: 20%;
            min-width: 20%;

            button {
              width: 100%;
            }
          }

          .btnArrow {
            max-width: 10%;
            min-width: 10%;
            margin: 1%;
            background-color: lightgrey;
            button {
              width: 100%;
              border-radius: 50%;
              background-color:#4aa94a;;
              color: white;
            }
          }

        }

        .grpbxWtVariance {
          border: 1px solid #ccc;

          .txtWeightVariance {
            padding: 0;

            label {
              font-weight: bold;
              width: 120px;
              text-align: right;
            }

            input {
              background-color: #f3f0f0;
              border: none;
              box-shadow: none;
              text-align: right;
              font-weight: bold;
            }
          }

          .lblLb {
            label {
              position: relative;
              right: 25px;
            }
          }

        }

        .grpbxFarmFreight {
          border: 1px solid #ccc;

          .lblFarmFrgtInfo {
            min-width: 100%;

            label {
              width: 100%;
              font-weight: bold;
              text-align: left;
            }
          }

          .dropdownfieldwidget-container {
            min-width: 100%;

            label {
              width: 70px;
              text-align: right;
            }
          }
          .ddCountry,.ddFarm,.ddState{
            #ddItem_{
              height: 25px;
            }	  
           .dropdown-toggle:empty::after {
            margin-left: auto;
            }
            button{
            height:30px;
            }
          }

        }
      }
    }

    .grpbxRegrdSettlement {
      margin-bottom: 0 !important;

      .card-body {
        padding: 0;

        .grpbxRegrd {
          min-width: 70%;
          max-width: 70%;
          margin-bottom: 0 !important;

          .grpbxRegrading {
            border: 1px solid #ccc;

            .lblRegrading {
              max-width: 12%;
              padding-right: 0;

              label {
                width: 100%;
                font-weight: bold;
                text-align: left;
              }
            }

            .chkbxManualGrd {
              min-width: 88%;
              padding: 0;

              .form-label {
                display: none;
              }

              .form-check {
                label {
                  font-size: 14px;
                  font-weight: 600;
                }
              }
            }

            .lblGradeSource,
            .btnOverRide {
              min-width: 32%;

              .d-flex,
              .row {
                justify-content: end;
                text-align: end;
              }
            }

            .lblGradeSource {
              .col {
                bottom: 10px;
                font-weight: 700;
              }
            }

            .txtFmSample,
            .txtClnSmplWt {
              min-width: 62%;
              padding-right: 0;

              label {
                width: 67%;
                text-align: right
              }

              .col {
                padding-right: 0;

                input {
                  text-align: right;
                }
              }
            }

            .lblg,
            .lblG {
              max-width: 2%;
              padding-left: 6px;
            }

          }

          .grpbxReGrading {
            border: 1px solid #ccc;

            .lblPct {
              max-width: 10%;
              padding-left: 25px;
              min-height: 30px;
              background: whitesmoke;
              color: deepskyblue;
              left: 2%;

              .col {
                bottom: 10px;
                text-align: center;
              }
            }

            .lblPct:after {
              content: '';
              flex: auto;
              margin-left: 1rem;
              border-top: 1px solid #000;
            }

            .lblGrams {
              min-width: 12.5%;
              max-width: 12.5%;
              margin-left: 9.5%;
              margin-bottom: 0 !important;
              label{
                margin-bottom: 0;
                width: 100%;
                text-align: center;
              }
            }
            .lblGrams2{
              min-width: 12.5%;
              max-width: 12.5%;
              margin-left: 22%;
              margin-bottom: 0 !important;
              label{
                margin-bottom: 0;
                width: 100%;
                text-align: center;
              }
            }
            .lblPrcnt,.lblPrcnt2 {
              max-width: 12.5%;
              min-width: 12.5%;
              margin-bottom: 0 !important;
              label{
                margin-bottom: 0;
                width: 100%;
                text-align: center;
              }
            }
            .txtOtherFM{
              min-width: 47% !important;
              max-width: 47% !important;
            }
            .textboxwidget-container{
              min-width: 34.5%;
              max-width: 34.5%;
              padding-right: 0;
              .col{
                padding-right: 0.25rem;
                padding-left: 0.25rem;
              }
              input{
                text-align: right;
              }
              label{
                width: 170px;
                text-align: right;
              }
            }
            [class*="Pct"],
            .txtTotalSMK2,
            .txtOKFallThru2,
            .txtOKDamageRiding2,
            .txtDamageSplits2,
            .txtLSKFallThru2,
            .txtTotalKernels2,
            .txtLSKDamageRiding2,
            .txtMoisture2,
            .txtTotalKernelsAndHulls2 {
              max-width: 12.5%;
              min-width: 12.5%;
              padding: 0;
            }

            [class*="Gr2"] {
              max-width: 12.5%;
              min-width: 12.5%;
              padding: 0;

              input {
                background-color: grey;
              }
            }

            .txtDamageSplits2,
            .txtTotalKernels,
            .txtTotalKernelsAndHulls,
            .txtTotalSMK,
            .txtOKFallThru,
            .txtOKDamageRiding,
            .txtLSKFallThru,
            .txtLSKDamageRiding,
            .txtMoisture {
              input {
                background-color: grey;
              }
            }

            .txtTotalSMK2,
            .txtTotalKernelsAndHulls2,
            .txtTotalKernels2 {
              input {
                border: none;
                background: transparent;
                font-weight: bold;
                box-shadow: none;
              }
            }

            .txtSMK1Gr,
            .txtSMK2Gr,
            .txtSMK3Gr,
            .txtSMK4Gr,
            .txtOKRidingGr,
            .txtLSKRidingGr {
              min-width: 22%;
              max-width: 22%;
              padding: 0;

              label {
                width: 120px;
              }

              .col {
                padding-left: 0px;
              }
            }
            .txtOKRidingGr{
              label{
                position: relative;
                width: 50%;
                left: 25px;
                top: 8px;
              }
              .col{
                position: relative;
                top: 16px;
                left: 29px;
              }
              input{
                min-width: 70%;
                max-width: 70%;
              }
            }

            .grpbxDamage {
              .textboxwidget-container {
                min-width: 69%;
                max-width: 69%;
                padding-right: 0;
              }

              [class*="Pct"] {
                max-width: 25.5%;
                min-width: 25.5%;
              }
            }
          }

          .grpbxCheckbxViacam {
            .chkboxReGrading {
              max-height: 45px;

              .form-check {
                input {
                  margin-left: 45%;
                  bottom: 1px;
                }

                label {
                  width: 45%;
                  text-align: right;
                }
              }
            }

            .chkboxVicam,
            .chkboxFlavus {
              margin-left: 16%;

              .form-check-label {
                display: none;
              }
            }

            .chkboxFlavus {
              .col {
                position: relative;
                top: 2px;
                padding: 0;
                left: 6px;
              }
            }

            .chkboxVicam {
              margin-left: 26%;
              position: relative;
              bottom: 12px;

              .col {
                position: relative;
                top: 2px;
                padding: 0;
                left: 8px;
              }
            }

            .txtVicamReading {
              min-width: 56%;
              max-width: 56%;
              margin-left: 40%;

              label {
                width: 102px;
              }
            }
          }
        }

        .grpbxSettlementFreight {
          min-width: 30%;
          max-width: 30%;
          padding-left: 1.25rem;

          .grpbxSettlement {
            border: 1px solid #ccc;

            .lblSettlementInfo {
              font-weight: bold;
              min-width: 100%;
              padding-left: 1rem !important;
            }

            .textboxwidget-container {
              min-width: 90%;
              padding-right: 0;

              label {
                width: 120px;
                text-align: right;
              }

              input {
                text-align: right;
              }
            }

            .lblGrossWt,
            .lblFm,
            .lblLessFm,
            .lblMoistr,
            .lblNetwt,
            .lblLsk,
            .lblLessLsk {
              label {
                position: relative;
                right: 12px;
              }
            }

            .labelwidget-container {
              max-width: 10%;
              padding-left: 0;
            }

            .btnDollarVal {
              max-width: 30%;
              min-width: 30%;
              margin-left: 5%;
              padding-right: 0;

              button {
                width: 90%;
              }
            }

            .lblDollar {
              min-width: 55%;
              max-width: 55%;

              label {
                display: none;
              }

              input {
                border: none;
                background: transparent;
                box-shadow: none;
                text-align: right;
                font-weight: bold;
                padding: 0;
              }
            }
          }

          .grpbxMultiStop {
            min-height: 195px;
            border: 1px solid #ccc;

            .lblMultiStop {
              min-width: 55%;
              max-width: 55%;

              label {
                width: 100%;
                text-align: left;
                font-weight: bold;
              }
            }

            label {
              width: 110px;
              text-align: right;
            }

            #txtTripId, #txtLeg, #txtLegOrg, #txtLegDest{
              text-align: end;
            }
          }

          .grpbxFreightAcc {
            border: 1px solid #ccc;

            .lblFrghtAccrl {
              font-weight: bold;
            }

            .textboxwidget-container {
              label {
                width: 120px;
                text-align: right;
              }

              input {
                text-align: right;
              }
            }

            .txtFreightRate {
              min-width: 80%;
              padding-right: 0;
            }

            .lblPerTon {
              max-width: 20%;
              padding-left: 0;
            }

            .txtProceedAmt {
              input {
                border: none;
                background: transparent;
                box-shadow: none;
                text-align: right;
                font-weight: bold;
                padding: 0;
              }
            }
          }
        }
      }
    }

    .grpbxRemrks {
      border: 1px solid #ccc;
      padding: 0;

      .txtarRemarks {
        padding-right: 0;
        padding-top: 0.25rem;
        textarea:focus,textarea:active{
          background-color: yellow;
        }
      }
    }
  }

  .grpbxActions {
    .grpbxEditInfo {
      min-width: 35%;
      padding-top: 0.5rem;
      padding-left: 0;
      padding-right: 0;
    }

    .txtAddedBy,
    .txtChngdBy {
      min-width: 60%;
      max-width: 60%;
      padding: 0;

      label {
        width: 80px;
        text-align: right;
      }

      input {
        background: transparent;
        border: none;
      }
    }

    .lblAddedDate,
    .lblChngdDate {
      max-width: 40%;
      min-width: 40%;

      label {
        display: none;
      }

      .col {
        position: relative;
        right: 70%;
        bottom: 6px;
      }
    }

    .buttonwidget-container {
      min-width: 9%;
      max-width: 9%;

      button {
        width: 95%;
        height: 100px;
      }
    }

    .grpbxBtns {
      padding: 0;
      min-width: 11%;
      max-width: 11%;

      .buttonwidget-container {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 0.2rem !important;

        button {
          width: 95%;
          height: 33px;
        }
      }
    }
  }

  /*END_USER_CODE_FOR_.SCSS*/
}

//for hiding original cross button as custom cross button from header file is visible on this page
#StockTransfer_StockTransferPopUp{
  .close {
    display: none;
  }
}
