.ContractManagement-FlexMktSign {
  /*START_USER_CODE_FOR_.SCSS*/
   // background-color: #c0bcbc;
   .ContractManagement-header{
    .LogoImage{
      // display: none;
    }
  }
  h1 {
    display: none;
  }
  .card-header {
    display: none;
  }
  .card {
    background-color: #f3f0f0;
  }
  .lblSavingCntrctOpt,
  .lblScanningOptions {
    font-size: 18px;
    font-weight: 500;
  }
  .grpbxSavingCntrctOpt {
    padding-top: 10px;
    .btnScanCntrct,
    .btnSaveFrmExFile,
    .btnCancel,
    .btnSignCntrctWOScng {
      max-width: 16%;
      button {
        width: 80%;
        height: 40px;
      }
    }
    .btnScanCntrct {
      margin-left: 3%;
    }
  }
  .grpbxScanningOptions {
    .btnCancleScan,
    .btnScanFlatbed,
    .btnScanDocFeeder {
      max-width: 16%;
      button {
        width: 80%;
        height: 40px;
      }
    }
    .btnScanDocFeeder {
      margin-left: 3%;
    }
    .txtarScanningStatus {
      label {
        font-size: 18px;
        font-weight: 500;
      }
      textarea {
        height: 185px;
      }
    }
  }
  .grpbxVerResultActs {
    .txtarVerResult {
      label {
        font-size: 18px;
        font-weight: 500;
      }
      textarea {
        height: 185px;
      }
    }
    .btnVerSave,
    .btnCncl {
      max-width: 16%;
      button {
        width: 80%;
        height: 40px;
      }
    }
    .btn1,
    .btn2,
    .btnAct3 {
      max-width: 10%;
      button {
        height: 49px;
        width: 45%;
      }
    }
  }
  .txtCntrctPrcNum, .lblCntrctPrcInfo{
    label, input {
      font-weight: bold;
    }
  }
  
  /*END_USER_CODE_FOR_.SCSS*/
}
 
